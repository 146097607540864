/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as mui from "../../../../shared/mui";

import axios from "axios";
import * as myConstClass from "../../../../constants/constants";
import * as $ from "jquery";
import setting from "./setting.png";
import moment from "moment";
import { hasAccess } from "../../../../shared/HasAccess";
import { Collapse } from "reactstrap";
import * as actions from "../../../../store/actions/index";
import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styles from "./OrganisationMain.module.css";
import Payments from "./Modules/Payments"
import edit from "../../../../assets/edit.png";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Dropdown from "../../../../components/Select/Select";

import { connect } from "react-redux";
import InventoryTable from "../../../../components/InventoryTable/InventoryTable";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

//VerifyDocument
import FormControlLabel from "@material-ui/core/FormControlLabel";
const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

const mobileValid = /^[0]?[789]\d{9}$/; // MATCH MOBILE NUMBER
const api_url = myConstClass.apiUrl;
// VALIDATE FORM HERE
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <mui.Box p={3}>
          <mui.Typography>{children}</mui.Typography>
        </mui.Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AntTabs = mui.withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#3B86FF",
  },
})(mui.Tabs);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData("Frozen yoghurt", 159, 6.0, 24, 4)];

const AntTab = mui.withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(3),
    outline: "none",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#43425D",
      opacity: 1,
      outline: "none",
    },
    "& .collapsing": {
      right: "0px",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },

    "&$selected": {
      color: "#43425D",
      fontWeight: theme.typography.fontWeightMedium,
      outline: "none",
    },
    "&:focus": {
      color: "#43425D",
      outline: "none",
    },
  },
  selected: {},
}))((props) => <mui.Tab disableRipple {...props} />);

const useStyles = mui.makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
  },
  table: {
    minWidth: 620,
    borderRadius: 0,
  },
  select: {
    minWidth: "19vw",
    "@media (min-width: 320px) and (max-width: 375px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 376px) and (max-width: 425px)": {
      minWidth: "25vw",
    },
    background: "white",
    color: "#43425D",
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "13px",
    "&:hover": {
      borderColor: mui.grey[400],
    },

    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: mui.blue[200],
    },
  },
  icon: {
    color: "#43425D",
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
  input: {
    "&::placeholder": {
      color: "#43425D",
      fontSize: "smaller",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginBottom: 20,
  },
}));

const BorderSelect = (props1) => {
  const [val, setVal] = React.useState(0);

  const handleChange = (event) => {
    setVal(event.target.value);
  };

  const borderSelectClasses = mui.useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const classes = useStyles();

  const iconComponent = (props) => {
    return (
      <mui.ExpandMoreIcon
        className={
          props.className + " " + borderSelectClasses.icon + " " + classes.icon
        }
      />
    );
  };

  return (
    <mui.FormControl>
      <mui.Select
        disableUnderline
        labelId="inputLabel"
        placeholder="Role"
        IconComponent={iconComponent}
        className={classes.select}
        MenuProps={menuProps}
        value={val}
        onChange={handleChange}
        style={{
          marginRight: "2%",
        }}
      >
        <mui.MenuItem value={0}> {props1.holder} </mui.MenuItem>{" "}
        <mui.MenuItem value={1}> One </mui.MenuItem>{" "}
        <mui.MenuItem value={2}> Two </mui.MenuItem>{" "}
        <mui.MenuItem value={3}> Three </mui.MenuItem>{" "}
      </mui.Select>
    </mui.FormControl>
  );
};

export function Organisation(props) {
  const [t] = useTranslation("common");
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [isDynamic, setDynmic] = useState(true);
  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [places, setPlaces] = useState([]);
  const [loader, setLoader] = useState(false);

  const [state, setState] = useState({
    orgData: {},
    prfileImage: [],
  });
  const [fileSrc, setFile] = useState(
    "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Chapultepec_Zoo_-_Jaguar_%2802%29.jpg/2560px-Chapultepec_Zoo_-_Jaguar_%2802%29.jpg"
  );
  // upload  file image
  const uploadFile = (e, type, i) => {
    if (e.target.files && e.target.files.length > 0) {
      var validExtensions = ["jpeg", "png", "jpg", "PNG", "JPG"];

      var isValid = true;
      for (let img of e.target.files) {
        if (
          $.inArray(
            img.name.substr(img.name.lastIndexOf(".") + 1),
            validExtensions
          ) === -1
        ) {
          e.target.value = "";
          isValid = false;
          toast.error(
            "Invalid file format, only png, jpeg file format allowed"
          );
        }
        break;
      }

      if (isValid) {
        let reader = new FileReader();
        setState({
          ...state,
          prfileImage: e.target.files[0],
          fileName: e.target.files[0].name,
          fileSrc: reader.result,
        });

        reader.onloadend = (e) => {
          setFile(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        uploadOrgImage(e.target.files[0]);
      }
    } else {
      e.preventDefault();

      return;
    }
  };

  // UPLOAD PROFILE IMAGE
  const uploadOrgImage = (data) => {
    let url = `organisation/orgImage-upload`;
    var formData = new FormData();
    formData.append("image", data);
    formData.append("organisationId", localStorage.getItem("orgId"));

    // dispatch(authStart());
    setLoading(true);
    axios
      .post(api_url + url, formData, {
        headers: {
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);

        toast.success(response.data.message);
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
  if (loading) {
    setTimeout(() => {
    setLoading(false);
  }, 2000);
  }
}, [loading]);

  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading}>
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  // get org detailsv
  useEffect(() => {
    if (props.orgData.image !== undefined && isDynamic) {
      setFile(props.orgData.image);
      setDynmic(false);
    }
  });

  return (
    <div className={styles.organisationDiv}>
      {backDrop}
      <div className={styles.organisationFormDiv}>
        <span
          style={{
            color: "#707070",
            marginLeft: "3%",
            marginTop: "3%",
            marginBottom: "5%",
            fontSize: "22px",
          }}
        >
          {t("organisation.org_profile")}
        </span>
        <div className={styles.formRowDiv}>
          <span
            style={{
              color: "#43425D",
              paddingBottom: "2%",
            }}
          >
            {t("organisation.org_display_name")}
          </span>
          <mui.TextField
            value={props.orgData.orgName}
            variant="outlined"
            style={{ width: "90%", outline: "none" }}
            disabled
          ></mui.TextField>
        </div>
        <div className={styles.formRowDiv}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("organisation.domain")}
          </span>
          <mui.TextField
            variant="outlined"
            value={props.orgData.subDomain}
            style={{ width: "90%", outline: "none" }}
            disabled
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("organisation.prefix")}
          </span>
          <mui.TextField
            variant="outlined"
            value={props.orgData.prefix}
            style={{
              width: "90%",
              outline: "none",
            }}
            disabled
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("city.postal_code")}
          </span>
          <mui.TextField
            variant="outlined"
            value={props.orgData.postalCodes}
            style={{
              width: "90%",
              outline: "none",
            }}
            disabled
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("city.district1")}
          </span>
          <mui.TextField
            variant="outlined"
            value={props.orgData.district}
            style={{
              width: "90%",
              outline: "none",
            }}
            disabled
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("city.state1")}
          </span>
          <mui.TextField
            variant="outlined"
            value={props.orgData.state}
            style={{
              width: "90%",
              outline: "none",
            }}
            disabled
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("city.country_name1")}
          </span>
          <mui.TextField
            variant="outlined"
            value={props.orgData.country}
            style={{
              width: "90%",
              outline: "none",
            }}
            disabled
          ></mui.TextField>
        </div>
      </div>
      <div className={styles.organisationProfileDiv}>
        <span
          style={{
            color: "#707070",
            fontSize: "large",
            font: "Source Sans Pro",
            fontWeight: "bold",
          }}
        >
          {t("organisation.profile_pic")}
        </span>
        <div className={styles.container}>
          <div className={styles.circle}>
            <img src={fileSrc} alt="some pic" />
          </div>
        </div>
        <label className = "uploadLabel">
          <input
            onChange={uploadFile}
            style={{ display: "none" }}
            type="file"
          />
          {t("organisation.upload_pic")}
        </label>
      </div>
    </div>
  );
}

export function Inventory() {
  const classes = useStyles();

  let icon = <mui.SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;
  return (
    <div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <mui.TextField
                  id="standard-search"
                  size="small"
                  type="search"
                  variant="outlined"
                  style={{
                    border: "1px solid #F5F6FA",
                    borderRadius: "4px",
                  }}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: "Search...",
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
            </div>
            <div className={styles.dropDownDiv}>
              <Dropdown holder="User" />
              <mui.Button
                variant="contained"
                style={{
                  color: "white",
                  borderRadius: "20px",
                  textTransform: "none",
                  backgroundColor: "#43425D",
                  width: "40%",
                  marginLeft: "2%",
                }}
              >
                Search
              </mui.Button>
            </div>
          </div>
          <div className={styles.buttonDiv}>
            <mui.Button
              variant="contained"
              color="secondary"
              style={{
                backgroundColor: "#F2134F",
                textTransform: "none",
                width: "100%",
              }}
            >
              Add
            </mui.Button>
          </div>
        </div>
        <div className={styles.tableDiv}>
          <InventoryTable />
        </div>
      </div>
      <div className={styles.paginationDiv}>
        <mui.Pagination
          count={5}
          shape="rounded"
          color="primary"
          variant="outlined"
        />
      </div>
    </div>
  );
}

/// Role

export function Role(props) {
  const classes = useStyles();
  const [t] = useTranslation("common");

  const [state, setState] = React.useState({
    role: "",

    search: "",
    id: "",
    btnTitle: "Save",
    title: "Add",
    isAdd: false,
    isUpdate: false,
    isDelete: false,
    selectedModule: [],
    editModuleList: [],
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErros] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setErros({});
  };
  const [modal, setModal] = useState(false);
  const [modalEdit, setEditModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);
  /// FETCH Role LIST HERE
  useEffect(() => {
    props.getRoleList(1, props.limit, state.search);
    setTimeout(() => {
      props.getOrgModuleList(localStorage.getItem("orgId"));
    }, 1000);
  }, []);
  let icon = <mui.SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;
  // OPEN ADD COUNTRY MODAL
  const toggleModal = (e) => {
    setModal(!modal);
    setState({
      ...state,
      role: "",
      cbtnTitle: t("role.save"),
      title: t("role.add_role"),
      isAdd: true,
      isDelete: false,
      isUpdate: false,
      selectedModule: props.moduleList,
    });
    setErros({});
    setCurrentStatus(false);
  };

  const toggleModalClose = (e) => {
    state.selectedModule.forEach((mdl, i) => {
      state.selectedModule[i].moduleId.mod_enableStatus = false;
      if (mdl.submoduleId.length > 0) {
        mdl.submoduleId.forEach((mdl, ix) => {
          state.selectedModule[i].submoduleId[ix].mod_enableStatus = false;
        });
      }
    });
    setState({ ...state, selectedModule: state.selectedModule });
    setModal(false);
  };

  // EDIT ROLE
  const editRole = (data) => {
    setLoading(true);

    getRoleById(data, data._id);
  };
  // close edit modal
  const closeEditModal = () => {
    setEditModal(false);
    setState({ ...state, selectedModule: [] });
  };

  // get role by id
  const getRoleById = (data, id) => {
    axios
      .get(api_url + `role/Role_getbyId-organisation?roleId=${id}`, {
        method: "get",
        headers: {
          "content-type": "Application/json",
          accept: "Application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        response.data.data.module.forEach((el, i) => {
          response.data.data.module[i].moduleId.mod_enableStatus =
            el.mod_enableStatus;
        });

        setState({
          ...state,
          editModuleList: response.data.data.module,
          id: data._id,
          selectedModule: response.data.data.module,
          role: data.role,
          isAdd: false,
          isDelete: false,
          isUpdate: true,
        });
        setEditModal(true);
        setLoading(false);
      })
      .catch((er) => {
        toast.error(er.response.data.message);
        setLoading(false);
      });
  };
  // DELETE  role
  const deleteRole = (data) => {
    setState({
      ...state,
      role: data.role,
      id: data._id,
      btnTitle: t("role.delete"),
      title: t("role.delete_role"),
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });
    setErros({});
    setModal(true);
  };

  // VAIDAATE FORM
  const validateForm = () => {
    var valid = true;
    let filter = state.selectedModule.filter(
      (x) => x.moduleId.mod_enableStatus === true
    );
    if (state.role.trim() === "") {
      errors.role = t("role.role_error");
      valid = false;
    } else if (filter.length === 0) {
      toast.error(t("role.module_error"));
      valid = false;
    } else {
      errors.module = "";
      errors.role = "";
      valid = true;
    }
    setErros((errors) => ({ ...errors }));
    return valid;
  };
  /// handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.isDelete && !validateForm()) {
      return;
    } else {
      var module = [];
      state.selectedModule.forEach((el, index) => {
        var ar = {};
        if (el.moduleId.mod_enableStatus) {
          ar.mod_enableStatus = el.moduleId.mod_enableStatus;
          ar.moduleId = el.moduleId._id;
        }

        let subLength = el.submoduleId.filter(
          (x) => x.mod_enableStatus === true
        );
        if (el.submoduleId.length > 0 && subLength.length > 0) {
          var arr1 = [];
          subLength.forEach((sub, i) => {
            arr1.push(sub._id);
          });
          ar.submoduleId = arr1;
          module.push(ar);
        } else {
          if (el.moduleId.mod_enableStatus) {
            ar.submoduleId = [];
            module.push(ar);
          }
        }
      });
      setCurrentStatus(true);
      state.module = module;
      props.manageRole(state);
    }
  };
  // ON SEACH COMPANY
  const onSearchRole = (e) => {
    props.getRoleList(props.page, props.limit, state.search);
  };

  useEffect(() => {
    if (props.isRoleAdded && currentStatus) {
      setModal(false);
      setCurrentStatus(false);
      toggleModalClose();
      closeEditModal();
    }
  });

  const checkedOption = (id, i, type) => {
    if (id !== undefined && type === "parent") {
      let arr = state.selectedModule;
      var ch = arr.find((x) => x.moduleId._id === id);
      if (ch && ch.moduleId.mod_enableStatus) {
        return true;
      } else {
        return false;
      }
    } else if (id !== undefined && type === "child") {
      let arr = state.selectedModule;
      ch =
        arr.length > 0 && arr[i].submoduleId !== undefined
          ? arr[i].submoduleId.find((x) => x._id === id)
          : undefined;
      if (ch && ch.mod_enableStatus) {
        return true;
      } else {
        return false;
      }
    }
  };
  const handleCheckChange = (event, data, idx, type, parentIndex) => {
    if (type === "parent") {
      if (event.target.checked) {
        state.selectedModule[idx].moduleId.mod_enableStatus = true;
        state.selectedModule[idx].submoduleId.length > 0 &&
          state.selectedModule[idx].submoduleId.map(
            (x) => (x.mod_enableStatus = true)
          );
      } else {
        let dt =
          state.selectedModule[idx].submoduleId.length > 0 &&
          state.selectedModule[idx].submoduleId.filter(
            (x) => x.mod_enableStatus === true
          );
        if (dt.length) {
          dt.forEach((status, index) => {
            state.selectedModule[idx].submoduleId[
              state.selectedModule[idx].submoduleId.indexOf(status)
            ].mod_enableStatus = false;
          });
          //setState({...state, selectedModule:state.selectedModule})
        }
        state.selectedModule[idx].moduleId.mod_enableStatus = false;
      }

      setState({
        ...state,
        selectedModule: state.selectedModule,
      });
    } else if (type === "child") {
      if (event.target.checked) {
        state.selectedModule[parentIndex].submoduleId[
          idx
        ].mod_enableStatus = true;
      } else {
        state.selectedModule[parentIndex].submoduleId[
          idx
        ].mod_enableStatus = false;
      }
      setState({
        ...state,
        selectedModule: state.selectedModule,
      });
    }
  };

  // disable child module if parent is not selected
  const disabledChildModule = (id, i) => {
    if (id !== undefined && state.selectedModule.length > 0) {
      let disable = state.selectedModule.find((x) => x.moduleId._id === id);
      if (disable && disable.moduleId.mod_enableStatus) {
        return false;
      } else {
        return true;
      }
    }
  };

  useEffect(() => {
    // if(id==undefined){

    setState({
      ...state,
      selectedModule: props.moduleList,
    });
    // }
  }, [props.moduleList.length]);

  const handleChangePage = (event, page) => {
    props.getRoleList(page, props.limit, state.search);
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <mui.Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };
  // dwonload module
  const downloadExcel = () => {
    props.downlaodExcelFile("org_role", state.search);
  };

  // ENABEL LAODING
  const backDrop = props.loading ? (
    <mui.Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;
  // ENABEL LAODING 1 for edit role
  const backDrop_edit = loading ? (
    <mui.Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={loading}
    >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  return (
    <div>
      <div className={styles.tableDiv}>
        {backDrop}
        {backDrop_edit}
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <mui.TextField
                  id="standard-search"
                  size="small"
                  type="search"
                  name="search"
                  value={state.search}
                  onChange={handleChange}
                  variant="outlined"
                  style={{
                    border: "1px solid #F5F6FA",
                    borderRadius: "4px",
                  }}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: t("role.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
            </div>
            <div className={styles.dropDownDiv}>
              {/* <Dropdown holder="User" /> */}
              <mui.Button
                variant="contained"
                name="search"
                value={state.search}
                onChange={handleChange}
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  borderRadius: "20px",
                  textTransform: "none",
                  width: "40%",
                  marginLeft: "2%",
                }}
                onClick={onSearchRole}
              >
                {t("role.search_btn")}
              </mui.Button>
            </div>
          </div>
          <div className={styles.buttonAndFilterRole} >
            <mui.Button
              onClick={downloadExcel}
              variant="contained"
              color="secondary"
              style={{
                textTransform: "none",
                backgroundColor: "#3B86FF",
                textAlign: "center",
                whiteSpace: "nowrap",
                outline: "none",
                fontSize: "smaller",
              }}
            >
              {t("role.download")}
              <mui.GetAppIcon style={{ marginLeft: "20%" }} />
            </mui.Button>
            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "role_add_organisation",
                props.accessList.module
              ) && (
                <mui.Button
                  variant="contained"
                  color="secondary"
                  style={{
                    backgroundColor: "#F2134F",
                    textTransform: "none",
                  }}
                  onClick={toggleModal}
                >
                  {t("role.add_role")}
                </mui.Button>
              )}
          </div>
        </div>
        <mui.Modal
          size="lg"
          isOpen={modal}
          toggle={toggleModalClose}
          backdrop="static"
          scrollable={true}
          centered={true}
        >
          <mui.ModalHeader toggle={toggleModal}>{state.title}</mui.ModalHeader>
          <mui.ModalBody className={styles.modalContainer}>
            {state.isDelete && (
              <p>
                {" "}
                {t("role.delete_msg")} <strong>{state.role}</strong> ?{" "}
              </p>
            )}
            {!state.isDelete && (
              <form className={classes.root}>
                <mui.TextField
                  helperText={errors.role}
                  error={errors.role}
                  className={classes.root}
                  variant="outlined"
                  label={t("role.role")}
                  name="role"
                  style={{ width: "80%" }}
                  value={state.role}
                  onChange={handleChange}
                />

                {props.moduleList.length > 0 &&
                  props.moduleList.map((module, idx) => (
                    <div style={{ width: "100%", marginLeft: "40px" }}>
                      <FormControlLabel
                        control={
                          <mui.Checkbox
                            checked={checkedOption(
                              module.moduleId._id,
                              idx,
                              "parent"
                            )}
                            onChange={(e) =>
                              handleCheckChange(e, module, idx, "parent")
                            }
                            name="parent"
                            color="primary"
                            key={idx}
                          />
                        }
                        label={module.moduleId.name}
                      />
                      <div style={{ marginLeft: "50px" }}>
                        {module.submoduleId.length > 0 &&
                          module.submoduleId.map((sub, i) => (
                            <div style={{ marginLeft: "80px" }}>
                              {" "}
                              <FormControlLabel
                                control={
                                  <mui.Checkbox
                                    style={{ display: "inline-block" }}
                                    checked={checkedOption(
                                      sub._id,
                                      idx,
                                      "child"
                                    )}
                                    onChange={(e) => {
                                      handleCheckChange(
                                        e,
                                        sub,
                                        i,
                                        "child",
                                        idx
                                      );
                                    }}
                                    name="child"
                                    color="primary"
                                    display="inline-block"
                                    disabled={disabledChildModule(
                                      module.moduleId._id,
                                      idx
                                    )}
                                    key={i}
                                  />
                                }
                                label={sub.name}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </form>
            )}
          </mui.ModalBody>
          <mui.ModalFooter>
            <mui.Button
              variant="contained"
              color="primary"
              onClick={toggleModalClose}
              style={{ marginRight: "2%" }}
            >
              {t("lang.cancel")}
            </mui.Button>
            <mui.Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </mui.Button>
          </mui.ModalFooter>
        </mui.Modal>
        {/* 
				// edit modal for role */}

        <mui.Modal
          size="lg"
          isOpen={modalEdit}
          toggle={closeEditModal}
          backdrop="static"
          scrollable={true}
          style={{
            maxHeight: "25vw",
          }}
          centered={true}
        >
          <mui.ModalHeader toggle={closeEditModal}>
            {t("role.update_role")}
          </mui.ModalHeader>
          <mui.ModalBody className={styles.modalContainer}>
            {!state.isDelete && (
              <form className={classes.root}>
                <mui.TextField
                  helperText={errors.role}
                  error={errors.role}
                  className={classes.root}
                  variant="outlined"
                  label={t("role.role")}
                  name="role"
                  style={{ width: "80%" }}
                  value={state.role}
                  onChange={handleChange}
                />

                {state.editModuleList.length > 0 &&
                  state.editModuleList.map((module, idx) => (
                    <div style={{ width: "100%", marginLeft: "40px" }}>
                      <FormControlLabel
                        control={
                          <mui.Checkbox
                            checked={checkedOption(
                              module.moduleId._id,
                              idx,
                              "parent"
                            )}
                            onChange={(e) =>
                              handleCheckChange(e, module, idx, "parent")
                            }
                            name="parent"
                            color="primary"
                            key={idx}
                          />
                        }
                        label={module.moduleId.name}
                      />
                      <div style={{ marginLeft: "50px" }}>
                        {module.submoduleId.length > 0 &&
                          module.submoduleId.map((sub, i) => (
                            <div style={{ marginLeft: "80px" }}>
                              {" "}
                              <FormControlLabel
                                control={
                                  <mui.Checkbox
                                    style={{ display: "inline-block" }}
                                    checked={checkedOption(
                                      sub._id,
                                      idx,
                                      "child"
                                    )}
                                    onChange={(e) => {
                                      handleCheckChange(
                                        e,
                                        sub,
                                        i,
                                        "child",
                                        idx
                                      );
                                    }}
                                    name="child"
                                    color="primary"
                                    display="inline-block"
                                    disabled={disabledChildModule(
                                      module.moduleId._id,
                                      idx
                                    )}
                                    key={i}
                                  />
                                }
                                label={sub.name}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </form>
            )}
          </mui.ModalBody>
          <mui.ModalFooter>
            <mui.Button
              variant="contained"
              color="primary"
              onClick={closeEditModal}
              style={{ marginRight: "2%" }}
            >
              {t("lang.cancel")}
            </mui.Button>
            <mui.Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {t("role.update")}
            </mui.Button>
          </mui.ModalFooter>
        </mui.Modal>
        <div className={styles.tableDiv}>
          <mui.TableContainer component={mui.Paper}>
            <mui.Table className={classes.table} aria-label="simple table">
              <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <mui.TableRow>
                  <mui.TableCell
                    align="center"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("role.role")}
                  </mui.TableCell>

                  <mui.TableCell></mui.TableCell>
                  <mui.TableCell></mui.TableCell>
                  <mui.TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("lang.status")}
                  </mui.TableCell>

                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>

                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                </mui.TableRow>
              </mui.TableHead>
              <mui.TableBody>
                {props.roleList.length > 0 &&
                  props.roleList.map((role, index) => (
                    <mui.TableRow key={index}>
                      <mui.TableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {role.role}
                      </mui.TableCell>

                      <mui.TableCell></mui.TableCell>
                      <mui.TableCell></mui.TableCell>
                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {role.status ? "Active" : "InActive"}
                      </mui.TableCell>

                      <mui.TableCell align="center"></mui.TableCell>
                      <mui.TableCell align="center"></mui.TableCell>
                      <mui.TableCell align="center"></mui.TableCell>
                      <mui.TableCell align="center"></mui.TableCell>

                      <mui.TableCell align="center"></mui.TableCell>
                      <mui.TableCell align="center"></mui.TableCell>
                      <mui.TableCell align="justify" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "role_delete_organisation",
                            props.accessList.module
                          ) && (
                            <mui.DeleteForeverIcon
                              onClick={() => {
                                deleteRole(role);
                              }}
                              style={{
                                padding: "none",
                                cursor: "pointer",
                                color: "#43425D",
                              }}
                            />
                          )}
                      </mui.TableCell>
                      <mui.TableCell align="left" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "role_update_organisation",
                            props.accessList.module
                          ) && (
                            <img
                              src={edit}
                              alt="Edit"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => editRole(role)}
                            />
                          )}
                      </mui.TableCell>
                    </mui.TableRow>
                  ))}
              </mui.TableBody>
            </mui.Table>
            {props.roleList.length === 0 && (
              <p style={{ textAlign: "center" }}>{t("lang.no_record_found")}</p>
            )}
          </mui.TableContainer>
        </div>
      </div>
      <div style={{ float: "right" }} className={styles.paginationDiv}>
        {props.roleList.length > 0 && setPage()}
      </div>
      <style>
        {
          `
          @media (max-width:768px){
            .OrganisationMain_tableDiv__3Fz4Z {
              
              width: 320px;
          }

          .MuiButton-label {
            font-size: x-small;
        }

        .MuiButton-label .MuiSvgIcon-root{
          display:none;
        }

        .MuiButton-root{
          padding:4px 4px;
        }
      }
          `
        }
      </style>
    
    </div>
  );
}

export function Billing() {
  return (
    <div className={styles.billingFormDiv}>
      <span
        style={{
          color: "#707070",
          marginLeft: "3%",
          marginTop: "3%",
          marginBottom: "5%",
          fontSize: "22px",
        }}
      >
        Billing
      </span>
      <div className={styles.formRowDiv}>
        <span
          style={{
            color: "#43425D",
            paddingBottom: "2%",
          }}
        >
          Billing name
        </span>
        <mui.TextField
          variant="outlined"
          style={{ width: "90%", outline: "none" }}
        ></mui.TextField>
      </div>
      <div className={styles.formRowDiv} style={{ marginBottom: "10%" }}>
        <span style={{ color: "#43425D", paddingBottom: "2%" }}>
          Billing email
        </span>
        <mui.TextField
          variant="outlined"
          style={{ width: "90%", outline: "none" }}
        ></mui.TextField>
      </div>
    </div>
  );
}

export function Account() {
  const [t] = useTranslation("common");

  const classes = useStyles();
  return (
    <div>
      <mui.TableContainer
        component={mui.Paper}
        style={{
          marginLeft: "-1%",
        }}
      >
        <mui.Table className={classes.table} aria-label="simple table">
          <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
            <mui.TableRow>
              <mui.TableCell
                style={{
                  color: "#A3A6B4",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
              >
                {t("request_access.username")}
              </mui.TableCell>
              <mui.TableCell
                style={{
                  color: "#A3A6B4",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
              >
                {t("request_access.module")}
              </mui.TableCell>
              <mui.TableCell
                style={{
                  color: "#A3A6B4",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                }}
              >
                {t("request_access.date")}
              </mui.TableCell>
            </mui.TableRow>
          </mui.TableHead>
          <mui.TableBody>
            {rows.map((row, index) => (
              <mui.TableRow key={index}>
                <mui.TableCell
                  component="th"
                  scope="row"
                  style={{
                    color: "#4D4F5C",
                    fontFamily: "Regular 13px/20px Source Sans Pro",
                  }}
                >
                  Dhule Organisation
                </mui.TableCell>
                <mui.TableCell
                  style={{
                    color: "#4D4F5C",
                    fontFamily: "Regular 13px/20px Source Sans Pro",
                  }}
                >
                  1000
                </mui.TableCell>
                <mui.TableCell
                  style={{
                    color: "#4D4F5C",
                    fontFamily: "Regular 13px/20px Source Sans Pro",
                  }}
                >
                  {t("request_access.give_access")}
                </mui.TableCell>
              </mui.TableRow>
            ))}
          </mui.TableBody>
        </mui.Table>
      </mui.TableContainer>
      <div className={styles.paginationDiv}>
        <mui.Pagination
          count={5}
          shape="rounded"
          color="primary"
          variant="outlined"
        />
      </div>
    </div>
  );
}

export function Account2() {
  return (
    <div>
      <div className={styles.acc2mainDiv}>
        <span className={styles.spanDiv}>
          This screen is not accessible to your Organisation
        </span>
      </div>
      <div className={styles.paginationDiv}>
        <mui.Pagination
          count={5}
          shape="rounded"
          color="primary"
          variant="outlined"
        />
      </div>
    </div>
  );
}

const AntSwitch = mui.withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(mui.Switch);

const useBackupStyles = mui.makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(5),
  },
}));

export function Settings() {
  const [t] = useTranslation("common");
  const backupClasses = useBackupStyles();
  const [encryption, setEncryption] = useState(false);
  const [backupMonths, setBackupMonths] = useState("");
  const handleChange = (event) => {
    setEncryption(!encryption);
  };

  const handleSelectChange = (event) => {
    setBackupMonths(event.target.value);
  };

  const [loading, setLoading] = useState(true)


  useEffect(() => {
  if (loading) {
    setTimeout(() => {
    setLoading(false);
  }, 2000);
  }
}, [loading]);
  

  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading}>
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  return (
    <div className={styles.billingFormDiv}>
      {backDrop}
      <span
        style={{
          color: "#707070",
          marginLeft: "3%",
          marginTop: "3%",
          marginBottom: "5%",
          fontSize: "22px",
        }}
      >
        {t("settings.settings")}
      </span>

      <div className={styles.formRowDiv}>
        <span style={{ color: "#43425D", paddingBottom: "2%" }}>
          {t("settings.encryption")}
        </span>
        <mui.FormGroup>
          <mui.Typography component="div">
            <mui.Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
            >
              <mui.Grid item>{t("settings.off")}</mui.Grid>
              <mui.Grid item>
                <AntSwitch
                  checked={encryption}
                  onChange={handleChange}
                  name="checkedC"
                />
              </mui.Grid>
              <mui.Grid item>{t("settings.on")}</mui.Grid>
            </mui.Grid>
          </mui.Typography>
        </mui.FormGroup>
      </div>
      <div className={styles.formRowDiv}>
        <span style={{ color: "#43425D", paddingBottom: "2%" }}>{t("settings.backup")}</span>
        <mui.FormControl
          variant="outlined"
          className={backupClasses.formControl}
        >
          <mui.InputLabel id="demo-simple-select-outlined-label">
            {t("settings.months")}
          </mui.InputLabel>
          <mui.Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={backupMonths}
            onChange={handleSelectChange}
            label="Months"
          >
            <mui.MenuItem value="">
              <em>None</em>
            </mui.MenuItem>
            <mui.MenuItem value={3}>{t("settings.3months")}</mui.MenuItem>
            <mui.MenuItem value={6}>{t("settings.6months")}</mui.MenuItem>
            <mui.MenuItem value={9}>{t("settings.9months")}</mui.MenuItem>
            <mui.MenuItem value={12}>{t("settings.12months")}</mui.MenuItem>
          </mui.Select>
        </mui.FormControl>
      </div>
    </div>
  );
}

export function Settings1() {
  return (
    <div className={styles.billingFormDiv}>
      <span
        style={{
          color: "#707070",
          marginLeft: "3%",
          marginTop: "3%",
          marginBottom: "5%",
          fontSize: "22px",
        }}
      >
        Settings
      </span>
      <div className={styles.formRowDiv}>
        <span
          style={{
            color: "#43425D",
            paddingBottom: "2%",
          }}
        >
          Encrytion
        </span>
        <mui.TextField
          variant="outlined"
          style={{ width: "90%", outline: "none" }}
        ></mui.TextField>
      </div>
      <div className={styles.formRowDiv}>
        <span style={{ color: "#43425D", paddingBottom: "2%" }}>
          Frequency Of Backups
        </span>
        <mui.TextField
          variant="outlined"
          style={{ width: "90%", outline: "none" }}
        ></mui.TextField>
      </div>
    </div>
  );
}

const SearchBox = () => {
  const styles = mui.useBorderedInputBaseStyles();
  return (
    <div>
      <mui.InputBase
        classes={styles}
        placeholder={"Find a repository they have access to.."}
        startAdornment={<mui.Search />}
        style={{
          backgroundColor: "#FFFFFF",
          border: "none",
          fontSize: "12px",
          borderRadius: "5px",
          boxShadow: "0px 3px 3px #00000014",
          minWidth: "20vw",
          padding: "5px",
        }}
      />
    </div>
  );
};
export function Module(props) {
  const [state, setState] = useState({
    search: "",
  });
  const [loading, setLoading] = useState(true);

  // Find Module
  const findModule = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  // Get Module List
  useEffect(() => {
    props.getAccessControl();
  }, []);

  useEffect(() => {
  if (loading) {
    setTimeout(() => {
    setLoading(false);
  }, 2000);
  }
}, [loading]);
  

  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading} >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  return (
    <div className={styles.moduleDiv}>
      {backDrop}
      <div className={styles.table2div}>
        <mui.TableContainer component={mui.Paper}>
          <mui.Table aria-label="simple table" style={{ tableLayout: "auto" }}>
            <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
              <mui.TableRow>
                <mui.TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#43425D",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    width: "70%",
                  }}
                >
                  List of Modules
                </mui.TableCell>
                <mui.TableCell
                  style={{
                    color: "#43425D",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                  }}
                >
                  <mui.InputBase
                    classes={styles}
                    name="search"
                    value={state.search}
                    onChange={findModule}
                    placeholder={"Find a repository they have access to..."}
                    startAdornment={<mui.Search />}
                  />
                </mui.TableCell>
              </mui.TableRow>
            </mui.TableHead>
            <mui.TableBody>
              {props.accessList.module !== undefined &&
                props.accessList.module.map((module, index) => (
                  <mui.TableRow key={index}>
                    <mui.TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: "#43425D",
                        font: "Semibold 18px/25px Source Sans Pro",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {module.moduleId.name}
                    </mui.TableCell>
                    <mui.TableCell
                      component="th"
                      scope="row"
                      style={{
                        textAlign: "right",
                        color: "#03C903",
                        font: "Semibold 13px/20px Source Sans Pro",
                        paddingRight: "3vw",
                        fontWeight: "bold",
                      }}
                    >
                      ACTIVE
                    </mui.TableCell>
                  </mui.TableRow>
                ))}
            </mui.TableBody>
          </mui.Table>
        </mui.TableContainer>
      </div>
    </div>
  );
}

export function Teams(props) {
  const classes = useStyles();
  const [t] = useTranslation("common");

  const [modal, setModal] = React.useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);

  const [team, setTeam] = useState({
    teamname: "",
    search: "",
    description: "",
    btnTitle: "Create new team",
    title: "Add Team",
    isAdd: false,
    isUpdate: false,
    isDelete: false,
    id: "",
  });
  const toggleModal = () => {
    setCurrentStatus(false);
    setModal(!modal);
    setTeam({
      ...team,
      teamname: "",
      isAdd: true,
      isDelete: false,
      isUpdate: false,
      description: "",
      btnTitle: t("team.create_new_team"),
      title: t("team.add_team"),
    });
  };

  // dwonload team
  const downloadExcel = () => {
    props.downlaodExcelFile("org_team", team.search);
  };
  // const handle chage
  const handleChange = (e) => {
    setTeam({ ...team, [e.target.name]: e.target.value });
  };

  // EDIT team
  const editTeam = (data) => {
    setTeam({
      ...team,
      teamname: data.name,
      id: data._id,
      btnTitle: t("team.update"),
      title: t("team.update_team"),
      isAdd: false,
      isDelete: false,
      isUpdate: true,
    });

    setModal(true);
  };

  // DELETE team
  const deleteTeam = (data) => {
    setTeam({
      ...team,
      teamname: data.name,
      id: data._id,
      btnTitle: t("team.delete"),
      title: t("team.delete"),
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });
    setModal(true);
  };
  // handle submit team
  const handleSubmitTeam = (e) => {
    e.preventDefault();
    if (!team.isDelete && team.teamname.trim() === "") {
      return toast.error(t("team.name_error"), {});
    }

    setCurrentStatus(true);
    team.orgId = localStorage.getItem("orgId");
    team.type = "ORGANISATION";
    props.addTeam(team);
  };
  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    } else {
    }
  });

  const handleChangePage = (event, page) => {
    props.getTeamList(page, props.limit, team.search);
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <mui.Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          //  marginTop: "2%",
          float: "right",
        }}
      />
    );
  };
  useEffect(() => {
    props.getTeam(1, props.limit, team.search);
  }, []);
  // ENABEL LAODING
  const backDrop = (
    <mui.Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  );

  return (
    <div className={styles.teamDiv}>
      {backDrop}

      <div className={styles.teamTableDiv}>
        <mui.TableContainer component={mui.Paper}>
          <mui.Table aria-label="simple table">
            <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
              <mui.TableRow>
                <mui.TableCell
                  align="center"
                  style={{ textAlign: "justify", color: "#A3A6B4" }}
                >
                  {t("team.team")}
                </mui.TableCell>

                <mui.TableCell></mui.TableCell>
                <mui.TableCell></mui.TableCell>
                <mui.TableCell
                  align="center"
                  style={{ textAlign: "justify", color: "#A3A6B4" }}
                >
                  {t("lang.status")}
                </mui.TableCell>

                <mui.TableCell align="center"></mui.TableCell>
                <mui.TableCell align="center"></mui.TableCell>
                <mui.TableCell align="center"></mui.TableCell>
                <mui.TableCell align="center"></mui.TableCell>
                <mui.TableCell align="center"></mui.TableCell>
                <mui.TableCell align="center"></mui.TableCell>

                <mui.TableCell align="center"></mui.TableCell>
                <mui.TableCell align="center"></mui.TableCell>
                <mui.TableCell align="center"></mui.TableCell>
                <mui.TableCell align="center"></mui.TableCell>
              </mui.TableRow>
            </mui.TableHead>
            <mui.TableBody>
              {props.teamList.length > 0 &&
                props.teamList.map((team, index) => (
                  <mui.TableRow key={index}>
                    <mui.TableCell
                      align="center"
                      component="th"
                      scope="row"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {team.name}
                    </mui.TableCell>
                    <mui.TableCell align="center"></mui.TableCell>
                    <mui.TableCell align="center"></mui.TableCell>
                    <mui.TableCell
                      align="left"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {team.status ? "Active" : "InActive"}
                    </mui.TableCell>

                    <mui.TableCell></mui.TableCell>
                    <mui.TableCell></mui.TableCell>

                    <mui.TableCell align="center"></mui.TableCell>
                    <mui.TableCell align="center"></mui.TableCell>
                    <mui.TableCell align="center"></mui.TableCell>
                    <mui.TableCell align="center"></mui.TableCell>

                    <mui.TableCell align="center"></mui.TableCell>
                    <mui.TableCell align="center"></mui.TableCell>
                    <mui.TableCell align="justify" padding="checkbox">
                      {props.accessList.module !== undefined &&
                        hasAccess(
                          "submoduleId",
                          "team_delete",
                          props.accessList.module
                        ) && (
                          <mui.DeleteForeverIcon
                            onClick={() => {
                              deleteTeam(team);
                            }}
                            style={{
                              padding: "none",
                              cursor: "pointer",
                              color: "#43425D",
                            }}
                          />
                        )}
                    </mui.TableCell>
                    <mui.TableCell align="left" padding="checkbox">
                      {props.accessList.module !== undefined &&
                        hasAccess(
                          "submoduleId",
                          "team_update",
                          props.accessList.module
                        ) && (
                          <img
                            src={edit}
                            alt="Edit"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => editTeam(team)}
                          />
                        )}
                    </mui.TableCell>
                  </mui.TableRow>
                ))}
            </mui.TableBody>
          </mui.Table>
        </mui.TableContainer>
        <div className={styles.vdpaginationDiv}>
          {props.teamList.length > 0 && setPage()}
        </div>
      </div>

      <div className={styles.teamButtonDiv}>
        {props.accessList.module !== undefined &&
          hasAccess(
            "submoduleId",
            "team_add_organisation",
            props.accessList.module
          ) && (
            <mui.Button
              variant="contained"
              style={{
                backgroundColor: "#0CD241",
                color: "white",
                textTransform: "none",
                outline: "none",
              }}
              onClick={toggleModal}
            >
              {t("team.new_team")}
            </mui.Button>
          )}
        <mui.Button
          onClick={downloadExcel}
          variant="contained"
          style={{
            marginLeft: "4%",
            backgroundColor: "#FFFFFF",
            color: "#3B86FF",
            textTransform: "none",
            outline: "none",
          }}
        >
          {t("team.download")}
          <mui.GetAppIcon style={{ marginLeft: "20%" }} />
        </mui.Button>
      </div>
      <mui.Modal isOpen={modal} toggle={toggleModal} centered={true}>
        <mui.ModalHeader
          style={{
            font: "Source Sans Pro",
            color: "#43425D",
            fontWeight: "normal",
          }}
        >
          {team.title}
        </mui.ModalHeader>
        <mui.ModalBody className={styles.modalContainer}>
          {team.isDelete && (
            <p>
              {t("team.delete_msg")} <strong>{team.teamname}</strong> ?{" "}
            </p>
          )}

          {!team.isDelete && (
            <form>
              <div
                style={{
                  marginBottom: "5%",
                  marginTop: "2%",
                }}
              >
                <mui.TextField
                  variant="outlined"
                  label={t("team.team_name")}
                  style={{ width: "95%" }}
                  name="teamname"
                  value={team.teamname}
                  onChange={handleChange}
                />
                <span
                  style={{
                    color: "#64686D",
                    marginLeft: "2%",
                    font: "SF Pro Text",
                    fontSize: "15px",
                  }}
                >
                  {t("team.title1")}
                </span>
              </div>
              <div
                style={{
                  marginBottom: "5%",
                }}
              ></div>
            </form>
          )}
        </mui.ModalBody>
        <mui.ModalFooter>
          <mui.Button
            variant="contained"
            color="primary"
            onClick={toggleModal}
            style={{
              backgroundColor: "#43425D",
              textTransform: "none",
              width: "20%",
              marginRight: "5%",
            }}
          >
            {t("team.cancel")}
          </mui.Button>
          <mui.Button
            variant="contained"
            color="secondary"
            onClick={toggleModal}
            style={{
              textTransform: "none",
            }}
            onClick={handleSubmitTeam}
          >
            {team.btnTitle}
          </mui.Button>
        </mui.ModalFooter>
      </mui.Modal>
    
    <style>
      {
        `
          @media (max-width:768px){

            .OrganisationMain_teamDiv__1QIEO {
           
              width: 320px;
          }
          }
        `
      }
    </style>
    </div>
  );
}

export function ManageAccess() {
  return (
    <div className={styles.moduleDiv}>
      <div className={styles.table1div}>
        <mui.TableContainer
          component={mui.Paper}
          style={{ boxShadow: "0px 2px 3px #0000000A" }}
        >
          <mui.Table aria-label="simple table">
            <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
              <mui.TableRow>
                <mui.TableCell
                  align="center"
                  style={{
                    color: "#43425D",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    width: "50%",
                    padding: "10px",
                  }}
                >
                  Organisation Details
                </mui.TableCell>
              </mui.TableRow>
            </mui.TableHead>
            <mui.TableBody>
              <mui.TableRow>
                <mui.TableCell component="th" scope="row">
                  <BorderSelect holder="Role:Member" />
                </mui.TableCell>
              </mui.TableRow>
              <mui.Divider />
              <mui.TableRow>
                <mui.TableCell
                  component="th"
                  scope="row"
                  style={{
                    color: "#4D4F5C",
                    fontFamily: "Regular 13px/20px Source Sans Pro",
                  }}
                >
                  1 modules
                </mui.TableCell>
              </mui.TableRow>
              <mui.TableRow>
                <mui.TableCell
                  component="th"
                  scope="row"
                  style={{
                    color: "#4D4F5C",
                    fontFamily: "Regular 13px/20px Source Sans Pro",
                  }}
                >
                  0 teams
                </mui.TableCell>
              </mui.TableRow>
              <mui.TableRow>
                <mui.TableCell
                  component="th"
                  scope="row"
                  style={{
                    color: "#4D4F5C",
                    fontFamily: "Regular 13px/20px Source Sans Pro",
                  }}
                >
                  Membership Private
                </mui.TableCell>
              </mui.TableRow>
            </mui.TableBody>
          </mui.Table>
        </mui.TableContainer>
        <mui.Button
          variant="filled"
          style={{
            backgroundColor: "#DF2020",
            color: "white",
            fontSize: "14px",
            width: "100%",
            textTransform: "none",
            marginTop: "10%",
            outline: "none",
          }}
        >
          Remove from organisation
        </mui.Button>
      </div>
      <div className={styles.table2div}>
        <mui.TableContainer component={mui.Paper}>
          <mui.Table aria-label="simple table" style={{ tableLayout: "auto" }}>
            <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
              <mui.TableRow>
                <mui.TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#A3A6B4",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    width: "50%",
                  }}
                >
                  <span style={{ color: "#43425D" }}>priyanshiSamadhan</span>
                  &nbsp;has access to 1 module
                </mui.TableCell>
                <mui.TableCell style={{ width: "60%" }}>&nbsp;</mui.TableCell>
                <mui.TableCell
                  style={{
                    color: "#43425D",
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                  }}
                >
                  <SearchBox />
                </mui.TableCell>
              </mui.TableRow>
            </mui.TableHead>
            <mui.TableBody>
              {rows.map((row, index) => (
                <mui.TableRow key={index}>
                  <mui.TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "#3B86FF",
                      font: "Semibold 18px/25px Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    Samadhan-Technologies/Dhule/Property
                  </mui.TableCell>
                  <mui.TableCell
                    style={{
                      color: "#6C6C6C",
                      fontSize: "14px",
                    }}
                  >
                    Read on this module
                  </mui.TableCell>
                  <mui.TableCell component="th" scope="row" align="center">
                    <mui.Button
                      style={{
                        backgroundColor: "#43425D",
                        color: "white",
                        textTransform: "none",
                        fontWeight: "lighter",
                        fontSize: "smaller",
                      }}
                    >
                      Manage access
                    </mui.Button>
                  </mui.TableCell>
                </mui.TableRow>
              ))}
            </mui.TableBody>
          </mui.Table>
        </mui.TableContainer>
      </div>
      
    </div>
  );
}

export function People(props) {
  const [t] = useTranslation("common");
  const classes = useStyles();

  const [index, setTab] = useState(1);

  // onchange tab

  const OnChangeTab = (e, index) => {
    setTab(index);
  };

  // GET ALL TEAM LIST
  useEffect(() => {
    props.getVerifyDocList(1, 10, localStorage.getItem("orgId"), "");
    props.getPendingDocList(1, 10, localStorage.getItem("orgId"), "");
    props.getMemberList(1, 10, localStorage.getItem("orgId"), "");
  }, []);

  // ENABLE LOADING
  const backDrop = (
    <mui.Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  );

  return (
    <div className={styles.moduleDiv}>
      {backDrop}
      <div className={styles.table1div}>
        <mui.TableContainer
          component={mui.Paper}
          style={{ boxShadow: "0px 2px 3px #0000000A" }}
        >
          <mui.Table aria-label="simple table">
            <mui.TableHead
              style={{ backgroundColor: "#F5F6FA" }}
            ></mui.TableHead>
            <mui.TableBody>
              {props.accessList.module !== undefined &&
                hasAccess(
                  "submoduleId",
                  "userlist_doc_approved",
                  props.accessList.module
                ) && (
                  <mui.TableRow
                    onClick={(e) => {
                      OnChangeTab(e, "1");
                    }}
                  >
                    <mui.TableCell component="th" scope="row">
                      <span
                        className={index === "1" ? styles.selected : ""}
                        style={{ color: "#707070", cursor: "pointer" }}
                      >
                        {t("people.member")}
                      </span>
                      <span
                        style={{
                          color: "#707070",
                          marginLeft: "40%",
                        }}
                      >
                        {props.totalMember}
                      </span>
                    </mui.TableCell>
                  </mui.TableRow>
                )}
              <mui.Divider />

              {props.accessList.module !== undefined &&
                hasAccess(
                  "submoduleId",
                  "userlist_doc_pending",
                  props.accessList.module
                ) && (
                  <mui.TableRow
                    onClick={(e) => {
                      OnChangeTab(e, "2");
                    }}
                  >
                    <mui.TableCell component="th" scope="row">
                      <span
                        className={index === "2" ? styles.selected : ""}
                        style={{ color: "#707070", cursor: "pointer" }}
                      >
                        {t("people.pending_invitation")}
                      </span>
                      <span
                        style={{
                          color: "#707070",
                          marginLeft: "40%",
                        }}
                      >
                        {props.pending_invitation}
                      </span>
                    </mui.TableCell>
                  </mui.TableRow>
                )}

              {props.accessList.module !== undefined &&
                hasAccess(
                  "submoduleId",
                  "user_doc_verify_list",
                  props.accessList.module
                ) && (
                  <mui.TableRow
                    onClick={(e) => {
                      OnChangeTab(e, "3");
                    }}
                  >
                    <mui.TableCell component="th" scope="row">
                      <span
                        className={index === "3" ? styles.selected : ""}
                        style={{ color: "#707070", cursor: "pointer" }}
                      >
                        {t("people.verify_doc")}
                      </span>
                      <span
                        style={{
                          color: "#707070",
                          marginLeft: "40%",
                        }}
                      >
                        {props.verified_docs}
                      </span>
                    </mui.TableCell>
                  </mui.TableRow>
                )}
            </mui.TableBody>
          </mui.Table>
        </mui.TableContainer>
      </div>
      {index === "1" && <Member {...props} />}
      {index === "2" && <PendingInvitation {...props} />}
      {index === "3" && <VerifyDocument {...props} />}

      <style>
        {
         `
         .MuiInputBase-root{
         background-color: rgb(255, 255, 255);
         border: none;
         font-size: 12px;
         border-radius: 5px;
         box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 3px;
         padding: 5px;
         }

         @media only screen and (max-width: 768px) {
          /* For mobile phones: */
          .OrganisationMain_table1div__3aJYC {
            width: 20%;
            }

          .OrganisationMain_moduleDiv__2gUJ7 {
            flex-direction:column;
            width: 320px;
            margin:0px;
          }

          .OrganisationMain_vdsearchBarDiv__6mExy {
            padding: 5% 0;
          } 

          .MuiInputBase-root {
            min-width:30vw;
          }

          .MuiTableContainer-root {
            width: 320px;
            overflow-x: auto;
        }

        .MuiBox-root-41 {
          padding: 5px;
      }

  

      .MuiInputBase-root{
        min-width: 30vw;
      }
        
        }
     
         
         `
        }

      </style>
    </div>
  );
}

export function VerifyDocument(props) {
  const borderSelectClasses = mui.useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const iconComponent = (props) => {
    return (
      <mui.ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };
  const [t] = useTranslation("common");

  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [InviteModal, setInviteModal] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const [state, setState] = React.useState({
    search: "",
    checkedModule3: "false",
    checkedModule2: "false",
    checkedModule1: "false",
    selectedModule: [],
    selectedTeams: [],
    document: [],
    userId: "",
    value: "",
    role: "0",
  });

  const closeModal = () => {
    state.selectedModule.forEach((mdl, i) => {
      state.selectedModule[i].mod_enableStatus = false;
    });
    setState({ ...state, selectedModule: state.selectedModule });
    setInviteModal(false);
  };
  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.name === "role" && event.target.value === "0") {
      return;
    }
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  // open
  const toggleVerifyDoc = (e, data) => {
    setModal(!modal);
    setState({ ...state, document: data.document, userId: data._id });
  };

  // close doc verfy modal
  const closeVerifyModal = () => {
    setModal(!modal);
  };
  /// VERIFY DOCUMENT
  const verfiyDocument = (e) => {
    e.preventDefault();
    state.orgId = localStorage.getItem("orgId");
    setCurrentStatus(true);
    props.verifyDocument(state);
  };
  // GET ALL TEAM LIST
  useEffect(() => {
    props.getVerifyDocList(1, 10, localStorage.getItem("orgId"), state.search);
    setTimeout(() => {
      //	props.getAllTeams()
    }, 2000);

    setTimeout(() => {
      //	props.getOrgRoleList()
    }, 2000);
  }, []);

  // on select team
  const onSelectTeam = (e, id) => {
    if (e.target.checked) {
      state.selectedTeams.push(id);
    } else {
      state.selectedTeams.splice(state.selectedTeams.indexOf(id), 1);
    }
    setState({ ...state, selectedTeams: state.selectedTeams });
  };

  let icon = <mui.SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  // close invite modal
  useEffect(() => {
    //  if(props.isUserInvited){
    // 	props.getVerifyDocList(1, 10, localStorage.getItem('orgId'), state.search)
    // 	closeModal()
    //  }
    if (props.docVerified && currentStatus) {
      props.getVerifyDocList(
        1,
        10,
        localStorage.getItem("orgId"),
        state.search
      );
      closeVerifyModal();
      setCurrentStatus(false);
    }
  });

  // send invite users
  const inviteUser = (e) => {
    e.preventDefault();

    if (state.value.trim() === "") {
      return toast.error(t("user_list.invite_error"));
    } else if (
      !validEmailRegex.test(state.value) &&
      !mobileValid.test(state.value)
    ) {
      return toast.error(t("user_list.invite_error"));
    } else if (state.role === "0") {
      return toast.error("Please select role");
    } else if (state.selectedTeams.length === "0") {
      return toast.error("Please select at least a team");
    }
    setCurrentStatus(true);

    props.inviteUser(state);
  };

  //ON SEARCH USER
  // on search user
  const onSearchUser = () => {
    props.getVerifyDocList(
      1,
      props.limit,
      localStorage.getItem("orgId"),
      state.search
    );
  };
  /// ON CHANGE PAGE NUMBER
  const handleChangePage = (event, page) => {
    props.getOrgUserList(page, 10, localStorage.getItem("orgId"), state.search);
  };
  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <mui.Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  return (
    <div className={styles.vdtable2div}>
      <div className={styles.vdsearchBarDiv}>
        <div className={styles.vdsearchAndDrop}>
          <div className={styles.vdsearchBar}>
            <mui.InputBase
              classes={styles}
              placeholder={t("people.search")}
              startAdornment={<mui.Search />}
              name="search"
              value={state.search}
              onChange={handleChange}
            />
          </div>
          <div className={styles.vddropDownDiv}>
            <mui.Button
              variant="contained"
              style={{
                backgroundColor: "#43425D",
                color: "white",
                borderRadius: "20px",
                width: "35%",
                textTransform: "none",
                outline: "none",
              }}
              onClick={onSearchUser}
            >
              {t("people.search_btn")}
            </mui.Button>
          </div>
        </div>
      </div>

      <mui.TableContainer component={mui.Paper}>
        <mui.Table className={classes.table} aria-label="simple table">
          <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
            <mui.TableRow>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {t("people.username")}
              </mui.TableCell>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {t("people.firstname")}
              </mui.TableCell>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {t("people.lastname")}
              </mui.TableCell>
              <mui.TableCell></mui.TableCell>
              <mui.TableCell></mui.TableCell>
              <mui.TableCell align="right">
                <mui.Checkbox />
              </mui.TableCell>
              <mui.TableCell></mui.TableCell>
              <mui.TableCell
                align="center"
                style={{ color: "#A3A6B4" }}
              ></mui.TableCell>
            </mui.TableRow>
          </mui.TableHead>
          <mui.TableBody>
            {props.docVerifyList.length > 0 &&
              props.docVerifyList.map((users, index) => (
                <mui.TableRow key={index}>
                  <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                    {users.username}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                    {users.name}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                    {users.lastName ? users.lastName : "-"}
                  </mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="right">
                    <mui.Checkbox />
                  </mui.TableCell>
                  <mui.TableCell></mui.TableCell>
                  <mui.TableCell align="center">
                    {props.accessList.module !== undefined &&
                      hasAccess(
                        "submoduleId",
                        "approve_doc",
                        props.accessList.module
                      ) && (
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#3B86FF",
                          }}
                          onClick={(e) => {
                            toggleVerifyDoc(e, users);
                          }}
                        >
                          {t("people.view")}
                        </span>
                      )}
                  </mui.TableCell>
                </mui.TableRow>
              ))}
          </mui.TableBody>

          <mui.Modal
            isOpen={InviteModal}
            style={{ overflowX: "hidden", top: "10rem" }}
            scrollable={true}
            backdrop="static"
            toggle={closeModal}
            centered={true}
          >
            <mui.ModalBody className={styles.modalContainer}>
              <div className={styles.innerDiv}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div style={{ flex: "1", padding: "0.5vw" }}>
                    <mui.TextField
                      id="standard-search1"
                      size="small"
                      variant="outlined"
                      name="value"
                      value={state.value}
                      onChange={handleChange}
                      style={{
                        borderColor: "#F5F6FA",
                        borderRadius: "4px",
                        minWidth: "10.5vw",
                      }}
                      InputProps={{
                        startAdornment: icon,
                        placeholder: "Eneter email or phone",
                        classes: { input: classes.input },
                        color: "#4D4F5C",
                        focused: classes.focused,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "1",
                      padding: "0.5vw",
                    }}
                  >
                    <mui.FormControl>
                      <mui.Select
                        disableUnderline
                        labelId="inputLabel"
                        placeholder={"Role"}
                        IconComponent={iconComponent}
                        className={classes.select}
                        MenuProps={menuProps}
                        name="role"
                        value={state.role}
                        onChange={handleChange}
                        style={{
                          marginRight: "2%",
                          minWidth: "14vw",
                        }}
                      >
                        <mui.MenuItem value={0}> {"Role"} </mui.MenuItem>{" "}
                        {props.allRoleList.length > 0 &&
                          props.allRoleList.map((role) => (
                            <mui.MenuItem value={role._id}>
                              {" "}
                              {role.role}{" "}
                            </mui.MenuItem>
                          ))}
                      </mui.Select>
                    </mui.FormControl>
                  </div>
                </div>
              </div>

              {props.allOrgTeamList.length > 0 &&
                props.allOrgTeamList.map((team, idx) => (
                  <div style={{ width: "100%", marginLeft: "40px" }}>
                    <FormControlLabel
                      control={
                        <mui.Checkbox
                          // checked={checkedOption(module._id, idx,"parent") }
                          onChange={(e) => onSelectTeam(e, team._id)}
                          name="team"
                          color="primary"
                          key={idx}
                        />
                      }
                      label={team.name}
                    />
                  </div>
                ))}
            </mui.ModalBody>
            <mui.ModalFooter>
              <mui.Button
                variant="contained"
                onClick={closeModal}
                style={{
                  marginRight: "4%",
                  backgroundColor: "#43425D",
                  color: "white",
                  textTransform: "none",
                  width: "25%",
                }}
              >
                Cancel
              </mui.Button>
              <mui.Button
                variant="contained"
                style={{
                  backgroundColor: "#F2134F",
                  color: "white",
                  textTransform: "none",
                  marginRight: "4%",
                  width: "25%",
                }}
                onClick={inviteUser}
              >
                Invite
              </mui.Button>
            </mui.ModalFooter>
          </mui.Modal>

          <mui.Modal
            isOpen={modal}
            toggle={toggleModal}
            centered={true}
            backdrop="static"
          >
            <mui.ModalBody className={styles.vdmodalContainer}>
              <div className={styles.vdinnerDiv}>
                {state.document.map((doc) => (
                  <div
                    title="Click here to view document"
                    style={{
                      wordBreak: "break-all",
                      // color: "#3b86ff",
                      // textDecoration: "underline",
                      background: "white",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      title={`${doc.fileUrl}, Click here to view document`}
                      style={{
                        textDecoration: "underline",
                        background: "white",
                        cursor: "pointer",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href={doc.fileUrl}
                    >
                      {doc.fileUrl.length > 50
                        ? doc.fileUrl.substr(0, 40) + ".."
                        : doc.fileUrl}
                    </a>
                    <span style={{ marginLeft: "4px" }}>{doc.title},</span>
                    <span span style={{ marginLeft: "4px" }}>
                      {doc.date ? moment(doc.date).format("DD, MMM, YY") : "-"}
                    </span>
                  </div>
                ))}
              </div>
              <FormControlLabel
                control={
                  <mui.Checkbox
                    checked={state.checkedA}
                    onChange={handleChange}
                    name="checkedModule1"
                    style={{
                      color: "#3B86FF",
                    }}
                  />
                }
                style={{ color: "#43425D" }}
                label="Aadhaar Card"
              />
              <FormControlLabel
                control={
                  <mui.Checkbox
                    checked={state.checkedB}
                    onChange={handleChange}
                    name="checkedModule2"
                    style={{
                      color: "#3B86FF",
                    }}
                  />
                }
                style={{ color: "#43425D" }}
                label="Pan Card"
              />
              <FormControlLabel
                control={
                  <mui.Checkbox
                    checked={state.checkedC}
                    onChange={handleChange}
                    name="checkedModule3"
                    style={{
                      color: "#3B86FF",
                    }}
                  />
                }
                style={{ color: "#43425D" }}
                label="Others"
              />
            </mui.ModalBody>
            <mui.ModalFooter
              style={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-evenly",
              }}
            >
              <mui.Button
                variant="contained"
                onClick={closeVerifyModal}
                style={{
                  // backgroundColor: "#FF0000",
                  backgroundColor: "rgb(67 66 93)",
                  marginRight: "12px",
                  color: "white",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  fontSize: "small",
                  outline: "none",
                }}
              >
                {t("people.cancel")}
              </mui.Button>
              <mui.Button
                variant="contained"
                onClick={verfiyDocument}
                style={{
                  backgroundColor: "#0CD241",
                  color: "white",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  fontSize: "small",
                  outline: "none",
                }}
              >
                {t("people.verify")}
              </mui.Button>
            </mui.ModalFooter>
          </mui.Modal>
        </mui.Table>
        {props.docVerifyList.length === 0 && (
          <p style={{ textAlign: "center" }}>
            {t("property_list.no_record_found")}
          </p>
        )}
      </mui.TableContainer>
      <div className={styles.vdpaginationDiv}>
        {props.docVerifyList.length > 0 && setPage()}
      </div>
    </div>
  );
}

// Member section
export function Member(props) {
  const [t] = useTranslation("common");

  const borderSelectClasses = mui.useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const iconComponent = (props) => {
    return (
      <mui.ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };

  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [InviteModal, setInviteModal] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState(false);
  const [collapse, setCollapse] = useState("1");
  const [updateRoleMdl, setUpdateRole] = useState(false);
  const [updateUserModal, setUpdateUser] = useState(false);
  const [index, setTab] = useState(1);
  const [change, setChange] = useState(false);
  const [state, setState] = React.useState({
    search: "",
    checkedModule3: "false",
    checkedModule2: "false",
    checkedModule1: "false",
    selectedModule: [],
    selectedTeams: [],
    document: [],
    userId: "",
    value: "",
    role: "0",
    selectedRole: "",
    userName: "",
    contractor: "",
    memberList: [],
    people: [],
  });

  const toggleModal = () => {
    setModal(!modal);
  };
  // toggle setting iocn
  // toggle Profile
  const toggle = (e, id) => {
    collapse === id ? setCollapse("1") : setCollapse(id);
  };
  const toggleInviteModal = () => {
    props.getAllTeams();
    setTimeout(() => {
      props.getOrgRoleList();
    }, 2000);
    setInviteModal(true);
    setState({ ...state, value: "", role: "0", selectedTeams: [] });
  };

  // open update role modal
  const openUpdateRoleModal = (e, data) => {
    setUpdateRole(!updateRoleMdl);
    setCollapse("1"); // set radom value to hide collapse
    setState({
      ...state,
      userName: data.username,
      userId: data._id,
      selectedRole: data.role.id,
    });

    props.getOrgRoleList();
  };

  //User Activation
  const handleActivation = (e, users) => {
    props.changeUserActive(props.memberList, users);
    setCollapse("1");
    setChange(!change);
  };

  // close role modal
  const closeRoleMdl = () => {
    setUpdateRole(!updateRoleMdl);
  };

  // OPEN UPDATE USER MODAL
  const openUpdateUserMdl = (e, data) => {
    setUpdateUser(!updateUserModal);
    setState({ ...state, userName: data.username, userId: data._id });
  };
  // close UPDATE USER MODAL
  const closeUpdateUserMdl = () => {
    setUpdateUser(!updateUserModal);
  };

  ///Change user role
  const handleChangeRole = (e, id) => {
    setState({ ...state, selectedRole: id });
  };

  const closeModal = () => {
    state.selectedModule.forEach((mdl, i) => {
      state.selectedModule[i].mod_enableStatus = false;
    });
    setState({ ...state, selectedModule: state.selectedModule });
    setInviteModal(false);
  };
  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.name === "role" && event.target.value === "0") {
      return;
    }
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  // close doc verfy modal
  const closeVerifyModal = () => {
    setModal(!modal);
  };
  /// VERIFY DOCUMENT

  // GET ALL TEAM LIST
  useEffect(() => {
    props.getMemberList(1, 10, localStorage.getItem("orgId"), state.search);
    setTimeout(() => {}, 2000);
  }, []);

  // on select team
  const onSelectTeam = (e, id) => {
    if (e.target.checked) {
      state.selectedTeams.push(id);
    } else {
      state.selectedTeams.splice(state.selectedTeams.indexOf(id), 1);
    }
    setState({ ...state, selectedTeams: state.selectedTeams });
  };

  let icon = <mui.SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  useEffect(() => {
    if (props.isUserInvited) {
      if (InviteModal) {
        closeModal();
      }
    }
    if (props.isRoleChange) {
      if (updateRoleMdl) {
        closeRoleMdl();
      }
    }
    if (props.isContractorUpdate) {
      if (updateUserModal) {
        closeUpdateUserMdl();
        props.getMemberList(
          1,
          props.limit,
          localStorage.getItem("orgId"),
          state.search
        );
      }
    }
  });

  //SET PEOPLE
  useEffect(() => {
    const page = 1,
      limit = 1000;
    const definedPeople = [];
    axios
      .get(api_url + `user/email-list/${page}/${limit}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.code === 200)
          response.data.data.docs.forEach((people) =>
            people.email
              ? definedPeople.push(
                  people.phone !== undefined
                    ? people.email + "_" + people.phone
                    : people.email
                )
              : null
          );
        setState({ ...state, people: definedPeople });
      })
      .catch((err) => {});
  }, []);
  // send invite users //latest
  const inviteUser = (e) => {
    const ids = state.value.split("_");
    let tempValue = "";
    if (ids.length === 2) {
      const [email, phone] = ids;
      if (phone.trim !== "" && mobileValid.test(phone)) {
        tempValue = phone;
      } else if (email.trim !== "" && validEmailRegex.test(email)) {
        tempValue = email;
      } else {
        return toast.error(t("user_list.invite_error"));
      }
    } else if (ids.length === 1) {
      if (ids[0].trim !== "") {
        if (validEmailRegex.test(ids[0]) || mobileValid.test(ids[0])) {
          tempValue = ids[0];
        } else {
         
          return toast.error(t("user_list.invite_error"));
        }
      } else {
       
        return toast.error(t("user_list.invite_error"));
      }
    }

    if (tempValue !== "") {
      e.preventDefault();
      if (state.role === "0") {
        return toast.error(t("people.role_error"));
      } else if (state.selectedTeams.length === "0") {
        return toast.error(t("people.team_error"));
      } else {
        setCurrentStatus(true);
        props.inviteUser(state, tempValue);
      }
    }
  };

  // CHANGE USER ROLE
  const onSubmitChangeRole = () => {
    props.changeRole(state);
  };

  const checkText = (event) => {
    console.log(event.target, "click check");
  };
  // on update controcator name
  const onUpdateContractorName = async (e) => {
    e.preventDefault();
    if (state.contractor.trim() === "") {
      return toast.error(t("peopple.update_user_error"));
    } else props.updateUserContractor(state);
  };

  // show team
  const showTeam = (data) => {
    var team = "";
    // eslint-disable-next-line array-callback-return
    data.team.map((el, i) => {
      team =
        data.team.length - 1 === i ? team + "" + el : team + "" + el + ",  ";
    });
    return <span>{team}</span>;
  };

  //ON SEARCH USER

  const onSearchUser = () => {
    props.getMemberList(
      1,
      props.limit,
      localStorage.getItem("orgId"),
      state.search
    );
  };
  /// ON CHANGE PAGE NUMBER
  const handleChangePage = (event, page) => {
    props.getMemberList(page, 10, localStorage.getItem("orgId"), state.search);
  };
  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <mui.Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  return (
    <div className={styles.vdtable2div}>
      <div className={styles.vdsearchBarDiv}>
        <div className={styles.vdsearchAndDrop}>
          <div className={styles.vdsearchBar}>
            <mui.InputBase
              classes={styles}
              placeholder={t("people.search")}
              startAdornment={<mui.Search />}
              name="search"
              value={state.search}
              onChange={handleChange}
            />
          </div>
          <div className={styles.vddropDownDiv}>
            <mui.Button
              variant="contained"
              style={{
                backgroundColor: "#43425D",
                color: "white",
                borderRadius: "20px",
                width: "35%",
                textTransform: "none",
                outline: "none",
              }}
              onClick={onSearchUser}
            >
              {t("people.search_btn")}
            </mui.Button>
          </div>
          <div
            className={styles.vddropDownDiv}
            style={{ justifyContent: "end" }}
          >
            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "invite_user",
                props.accessList.module
              ) && (
                <mui.Button
                  variant="contained"
                  onClick={toggleInviteModal}
                  style={{
                    backgroundColor: "#0CD241",
                    color: "white",
                    height: "45px",
                    width: "55%",
                    alignSelf: "left",
                    textTransform: "none",
                    outline: "none",
                    whiteSpace: "nowrap",
                    marginLeft: "58px",
                  }}
                >
                  {t("people.invite_user")}
                </mui.Button>
              )}
          </div>
        </div>
      </div>

      <mui.TableContainer component={mui.Paper}>
        <mui.Table className={classes.table} aria-label="simple table">
          <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
            <mui.TableRow>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {t("people.username")}
              </mui.TableCell>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {t("people.firstname")}
              </mui.TableCell>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {t("people.email")}
              </mui.TableCell>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {" "}
                {t("people.phone")}
              </mui.TableCell>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {" "}
                {t("people.team")}
              </mui.TableCell>

              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {" "}
                {t("people.role")}
              </mui.TableCell>

              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {" "}
                {"USER STATUS"}
              </mui.TableCell>

              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
              {" "}
                {"USER PROFILE"}
              </mui.TableCell>

              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {" "}
                {"USER SETTINGS"}
              </mui.TableCell>

              <mui.TableCell
                align="center"
                style={{ color: "#A3A6B4" }}
              ></mui.TableCell>
            </mui.TableRow>
          </mui.TableHead>
          <mui.TableBody>
            {props.memberList.length > 0 &&
              props.memberList.map((users, idx) => (
                <mui.TableRow key={`${idx}${users.organisation.userOrgStatus}`}>
                  <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                    {users.username}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                    {users.name}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                    {users.email}
                  </mui.TableCell>
                  <mui.TableCell align="center"> {users.phone}</mui.TableCell>
                  <mui.TableCell align="center">
                    {showTeam(users)}
                  </mui.TableCell>
                  <mui.TableCell align="center">
                    {users.role.role}
                  </mui.TableCell>
                  <mui.TableCell
                    align="center"
                    key={users.organisation.userOrgStatus}
                  >
                    {!users.organisation.userOrgStatus ? "Active" : "Inactive"}
                  </mui.TableCell>

                  <mui.TableCell align="center" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "property_version_list",
                            props.accessList.module
                          ) && (
                            <Link to={`/people-profile/${users._id}`}>
                              <VisibilityIcon
                                style={{
                                  color: "eeefff",
                                }}
                              />
                            </Link>
                          )}
                      </mui.TableCell>

                  <mui.TableCell align="center">
                    <div
                      style={{ marginLeft: "0px" }}
                      className={styles.profile}
                    >
                      <button
                        key={idx}
                        className={styles.avatar}
                        onClick={(e) => {
                          toggle(e, users._id);
                        }}
                      >
                        <p className={styles.avatarName}>
                          <img
                            title={users._id}
                            style={{ width: "27px", height: " 27px" }}
                            alt="logout"
                            src={setting}
                          />
                        </p>
                        <svg
                          className="mdi-icon topbar__icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                        </svg>
                      </button>
                      <Collapse
                        key={users._id}
                        isOpen={collapse === users._id}
                        className={
                          styles.topBarMenuWrap + " " + styles.collapsing
                        }
                      >
                        <div className={styles.topBarMenu}>
                          <Link
                            style={{ textDecoration: "none" }}
                            className={styles.tobBarLinik}
                            onClick={(e) => {
                              openUpdateRoleModal(e, users);
                            }}
                          >
                            <p className={styles.tobBarLinkTitle}>
                              {t("people.change_role")}
                            </p>
                          </Link>
                          <Link
                            style={{ textDecoration: "none" }}
                            className={styles.tobBarLinik}
                            onClick={(e) => {
                              openUpdateUserMdl(e, users);
                            }}
                          >
                            <p className={styles.tobBarLinkTitle}>
                              {t("people.update_user")}
                            </p>
                          </Link>

                          <Link
                            style={{ textDecoration: "none" }}
                            className={styles.tobBarLinik}
                            onClick={(e) => {
                              handleActivation(e, users);
                            }}
                          >
                            <p className={styles.tobBarLinkTitle}>
                              {!users.organisation.userOrgStatus
                                ? "Deactivate"
                                : "Activate"}
                            </p>
                          </Link>
                        </div>
                      </Collapse>
                    </div>
                  </mui.TableCell>
                </mui.TableRow>
              ))}
          </mui.TableBody>

          <mui.Modal
            isOpen={InviteModal}
            style={{ overflowX: "hidden", top: "10rem" }}
            scrollable={true}
            backdrop="static"
            toggle={closeModal}
          >
            <mui.ModalBody className={styles.modalContainer}>
              <div className={styles.innerDiv}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div style={{ flex: "1", padding: "0.5vw" }}>
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      onChange={(event, value) =>
                        setState({ ...state, value: value })
                      }
                      options={state.people.map((person) => `${person}`)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search..."
                          variant="outlined"
                          onChange={(event) =>
                            setState({ ...state, value: event.target.value })
                          }
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </div>

                  <div
                    style={{
                      flex: "1",
                      padding: "0.5vw",
                    }}
                  >
                    <mui.FormControl>
                      <mui.Select
                        disableUnderline
                        labelId="inputLabel"
                        placeholder={t("people.role1")}
                        IconComponent={iconComponent}
                        className={classes.select}
                        MenuProps={menuProps}
                        name="role"
                        value={state.role}
                        onChange={handleChange}
                        style={{
                          marginRight: "2%",
                          minWidth: "14vw",
                        }}
                      >
                        <mui.MenuItem value={0}>
                          {t("people.role1")}{" "}
                        </mui.MenuItem>{" "}
                        {props.allRoleList.length > 0 &&
                          props.allRoleList.map((role) => (
                            <mui.MenuItem value={role._id}>
                              {" "}
                              {role.role}{" "}
                            </mui.MenuItem>
                          ))}
                      </mui.Select>
                    </mui.FormControl>
                  </div>
                </div>
              </div>

              {props.allOrgTeamList.length > 0 &&
                props.allOrgTeamList.map((team, idx) => (
                  <div style={{ width: "100%", marginLeft: "40px" }}>
                    <FormControlLabel
                      control={
                        <mui.Checkbox
                          onChange={(e) => onSelectTeam(e, team._id)}
                          name="team"
                          color="primary"
                          key={idx}
                        />
                      }
                      label={team.name}
                    />
                  </div>
                ))}
            </mui.ModalBody>
            <mui.ModalFooter>
              <mui.Button
                variant="contained"
                onClick={closeModal}
                style={{
                  marginRight: "4%",
                  backgroundColor: "#43425D",
                  color: "white",
                  textTransform: "none",
                  width: "25%",
                }}
              >
                {t("people.cancel")}
              </mui.Button>
              <mui.Button
                variant="contained"
                style={{
                  backgroundColor: "#F2134F",
                  color: "white",
                  textTransform: "none",
                  marginRight: "4%",
                  width: "25%",
                }}
                onClick={inviteUser}
              >
                {t("people.invite")}
              </mui.Button>
            </mui.ModalFooter>
          </mui.Modal>

          <mui.Modal
            isOpen={modal}
            toggle={toggleModal}
            centered={true}
            scrollable={true}
          >
            <mui.ModalBody className={styles.vdmodalContainer}>
              <div className={styles.vdinnerDiv}>
                {state.document.map((doc) => (
                  <a
                    target="_blank"
                    href={doc.fileUrl}
                    style={{
                      wordBreak: "break-all",
                      color: "#3b86ff",
                      textDecoration: "underline",
                      background: "white",
                      cursor: "pointer",
                    }}
                  >
                    {doc.fileUrl}
                  </a>
                ))}
              </div>
              <FormControlLabel
                control={
                  <mui.Checkbox
                    checked={state.checkedA}
                    onChange={handleChange}
                    name="checkedModule1"
                    style={{
                      color: "#3B86FF",
                    }}
                  />
                }
                style={{ color: "#43425D" }}
                label="Aadhaar Card"
              />
              <FormControlLabel
                control={
                  <mui.Checkbox
                    checked={state.checkedB}
                    onChange={handleChange}
                    name="checkedModule2"
                    style={{
                      color: "#3B86FF",
                    }}
                  />
                }
                style={{ color: "#43425D" }}
                label="Pan Card"
              />
              <FormControlLabel
                control={
                  <mui.Checkbox
                    checked={state.checkedC}
                    onChange={handleChange}
                    name="checkedModule3"
                    style={{
                      color: "#3B86FF",
                    }}
                  />
                }
                style={{ color: "#43425D" }}
                label="Others"
              />
            </mui.ModalBody>
            <mui.ModalFooter
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <mui.Button
                variant="contained"
                onClick={closeVerifyModal}
                style={{
                  // backgroundColor: "#FF0000",
                  backgroundColor: "rgb(67 66 93)",
                  marginRight: "12px",
                  color: "white",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  fontSize: "small",
                  outline: "none",
                }}
              >
                Cancel
              </mui.Button>
            </mui.ModalFooter>
          </mui.Modal>
        </mui.Table>
        {props.memberList.length === 0 && (
          <p style={{ textAlign: "center" }}>
            {t("property_list.no_record_found")}
          </p>
        )}
      </mui.TableContainer>
      <div className={styles.vdpaginationDiv}>
        {props.memberList.length > 0 && setPage()}
      </div>
      {/* update role modal */}
      <mui.Modal isOpen={updateRoleMdl} centered={true} backdrop="static">
        <mui.ModalHeader style={{ color: "#43425D" }}>
          Change the role of {state.userName} ?
        </mui.ModalHeader>
        <mui.ModalBody className={styles.vdmodalContainer}>
          <span
            style={{
              color: "#43425D",
              paddingBottom: "5%",
              fontSize: "20px",
            }}
          >
            Select a new role
          </span>
          <mui.FormControl component="fieldset">
            <mui.RadioGroup
              aria-label="gender"
              name="gender1"
              value={state.checked}
            >
              {props.allRoleList.length > 0 &&
                props.allRoleList.map((role, i) => (
                  <FormControlLabel
                    value="admin"
                    onChange={(e) => {
                      handleChangeRole(e, role._id);
                    }}
                    control={
                      <mui.Radio
                        checked={state.selectedRole === role._id}
                        style={{
                          color: "#43425D",
                        }}
                      />
                    }
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#43425D",
                          }}
                        >
                          {role.role}
                        </span>
                      </div>
                    }
                  />
                ))}
            </mui.RadioGroup>
          </mui.FormControl>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5%",
              width: "33%",
            }}
          ></div>
        </mui.ModalBody>
        <mui.ModalFooter>
          <mui.Button
            variant="contained"
            onClick={closeRoleMdl}
            style={{
              backgroundColor: "#43425D",
              color: "white",
              textTransform: "none",
              whiteSpace: "nowrap",
              outline: "none",
              marginRight: "3%",
            }}
          >
            Cancel
          </mui.Button>

          <mui.Button
            variant="contained"
            onClick={onSubmitChangeRole}
            disabled={state.selectedRole === ""}
            style={{
              color: "white",
              backgroundColor: "rgb(242, 19, 79)",
              textTransform: "none",
              whiteSpace: "nowrap",
              outline: "none",
            }}
          >
            Change Role
          </mui.Button>
        </mui.ModalFooter>
      </mui.Modal>

      {/* UPDATE USER CONTRACTOR NAME */}
      <mui.Modal
        isOpen={updateUserModal}
        // toggle={toggleModal}
        centered={true}
        backdrop="static"
      >
        <mui.ModalHeader style={{ color: "#43425D" }}>
          {t("people.update_title")} {state.userName} ?
        </mui.ModalHeader>
        <mui.ModalBody
          style={{ display: "inline-block" }}
          className={styles.vdmodalContainer}
        >
          <div
            style={{
              marginBottom: "5%",
              marginTop: "2%",
            }}
          >
            <mui.TextField
              className={classes.root}
              variant="outlined"
              label={t("people.contractor")}
              name="contractor"
              style={{ width: "95%" }}
              value={state.contractor}
              onChange={handleChange}
            />
          </div>
        </mui.ModalBody>
        <mui.ModalFooter>
          <mui.Button
            variant="contained"
            onClick={closeUpdateUserMdl}
            style={{
              backgroundColor: "#43425D",
              color: "white",
              textTransform: "none",
              whiteSpace: "nowrap",
              outline: "none",
              marginRight: "3%",
            }}
          >
            {t("people.cancel")}
          </mui.Button>

          <mui.Button
            variant="contained"
            onClick={onUpdateContractorName}
            disabled={state.contractor.trim() === ""}
            style={{
              color: "white",
              backgroundColor: "rgb(242, 19, 79)",
              textTransform: "none",
              whiteSpace: "nowrap",
              outline: "none",
            }}
          >
            {t("people.update")}
          </mui.Button>
        </mui.ModalFooter>
      </mui.Modal>
    </div>
  );
}

export function PendingInvitation(props) {
  const borderSelectClasses = mui.useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  const iconComponent = (props) => {
    return (
      <mui.ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };
  const [t] = useTranslation("common");

  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [InviteModal, setInviteModal] = React.useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [state, setState] = React.useState({
    search: "",
    checkedModule3: "false",
    checkedModule2: "false",
    checkedModule1: "false",
    selectedModule: [],
    selectedTeams: [],
    document: [],
    userId: "",
    value: "",
    role: "0",
  });

  const closeModal = () => {
    state.selectedModule.forEach((mdl, i) => {
      state.selectedModule[i].mod_enableStatus = false;
    });
    setState({ ...state, selectedModule: state.selectedModule });
    setInviteModal(false);
  };
  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.name === "role" && event.target.value === "0") {
      return;
    }
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  // close doc verfy modal
  const closeVerifyModal = () => {
    setModal(!modal);
  };
  /// VERIFY DOCUMENT
  const verfiyDocument = (e) => {
    e.preventDefault();
    state.orgId = localStorage.getItem("orgId");
    props.verifyDocument(state);
  };
  // GET ALL TEAM LIST
  useEffect(() => {
    props.getPendingDocList(1, 10, localStorage.getItem("orgId"), state.search);
    setTimeout(() => {
      //props.getAllTeams()
    }, 2000);

    setTimeout(() => {
      //props.getOrgRoleList()
    }, 2000);
  }, []);

  // on select team
  const onSelectTeam = (e, id) => {
    if (e.target.checked) {
      state.selectedTeams.push(id);
    } else {
      state.selectedTeams.splice(state.selectedTeams.indexOf(id), 1);
    }
    setState({ ...state, selectedTeams: state.selectedTeams });
  };

  let icon = <mui.SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  //ON SEARCH USER
  // on search user
  const onSearchUser = () => {
    props.getPendingDocList(
      1,
      props.limit,
      localStorage.getItem("orgId"),
      state.search
    );
  };
  /// ON CHANGE PAGE NUMBER
  const handleChangePage = (event, page) => {
    props.getPendingDocList(
      page,
      10,
      localStorage.getItem("orgId"),
      state.search
    );
  };
  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <mui.Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
        }}
      />
    );
  };

  return (
    <div className={styles.vdtable2div}>
      <div className={styles.vdsearchBarDiv}>
        <div className={styles.vdsearchAndDrop}>
          <div className={styles.vdsearchBar}>
            <mui.InputBase
              classes={styles}
              placeholder={t("people.search")}
              startAdornment={<mui.Search />}
              name="search"
              value={state.search}
              onChange={handleChange}
              style={{
                backgroundColor: "#FFFFFF",
                border: "none",
                fontSize: "12px",
                borderRadius: "5px",
                boxShadow: "0px 3px 3px #00000014",
                minWidth: "11vw",
                padding: "5px",
              }}
            />
          </div>
          <div className={styles.vddropDownDiv}>
            {/* <Dropdown holder="User" /> */}
            <mui.Button
              variant="contained"
              style={{
                backgroundColor: "#43425D",
                color: "white",
                borderRadius: "20px",
                width: "35%",
                textTransform: "none",
                outline: "none",
              }}
              onClick={onSearchUser}
            >
              {t("people.search_btn")}
            </mui.Button>
          </div>
        </div>
      </div>

      <mui.TableContainer component={mui.Paper}>
        <mui.Table className={classes.table} aria-label="simple table">
          <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
            <mui.TableRow>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {t("people.username")}
              </mui.TableCell>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {t("people.firstname")}
              </mui.TableCell>
              <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                {t("people.email")}
              </mui.TableCell>
              <mui.TableCell></mui.TableCell>
              <mui.TableCell></mui.TableCell>

              <mui.TableCell></mui.TableCell>
            </mui.TableRow>
          </mui.TableHead>
          <mui.TableBody>
            {props.pendingDocList.length > 0 &&
              props.pendingDocList.map((users, idx) => (
                <mui.TableRow key={idx}>
                  <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                    {users.username}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                    {users.name}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                    {users.email}
                  </mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>

                  <mui.TableCell></mui.TableCell>
                </mui.TableRow>
              ))}
          </mui.TableBody>

          <mui.Modal
            isOpen={InviteModal}
            style={{ overflowX: "hidden", top: "10rem" }}
            scrollable={true}
            backdrop="static"
            toggle={closeModal}
            centered={true}
          >
            <mui.ModalBody className={styles.modalContainer}>
              <div className={styles.innerDiv}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div style={{ flex: "1", padding: "0.5vw" }}>
                    <mui.TextField
                      id="standard-search1"
                      size="small"
                      variant="outlined"
                      name="value"
                      value={state.value}
                      onChange={handleChange}
                      style={{
                        borderColor: "#F5F6FA",
                        borderRadius: "4px",
                        minWidth: "10.5vw",
                      }}
                      InputProps={{
                        startAdornment: icon,
                        placeholder: "Enter email",
                        classes: { input: classes.input },
                        color: "#4D4F5C",
                        focused: classes.focused,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      flex: "1",
                      padding: "0.5vw",
                    }}
                  >
                    <mui.FormControl>
                      <mui.Select
                        disableUnderline
                        labelId="inputLabel"
                        placeholder={"Role"}
                        IconComponent={iconComponent}
                        className={classes.select}
                        MenuProps={menuProps}
                        name="role"
                        value={state.role}
                        onChange={handleChange}
                        style={{
                          marginRight: "2%",
                          minWidth: "14vw",
                        }}
                      >
                        <mui.MenuItem value={0}> {"Role"} </mui.MenuItem>{" "}
                        {props.allRoleList.length > 0 &&
                          props.allRoleList.map((role) => (
                            <mui.MenuItem value={role._id}>
                              {" "}
                              {role.role}{" "}
                            </mui.MenuItem>
                          ))}
                      </mui.Select>
                    </mui.FormControl>
                  </div>
                </div>
              </div>

              {props.allOrgTeamList.length > 0 &&
                props.allOrgTeamList.map((team, idx) => (
                  <div style={{ width: "100%", marginLeft: "40px" }}>
                    <FormControlLabel
                      control={
                        <mui.Checkbox
                          onChange={(e) => onSelectTeam(e, team._id)}
                          name="team"
                          color="primary"
                          key={idx}
                        />
                      }
                      label={team.name}
                    />
                  </div>
                ))}
            </mui.ModalBody>
            <mui.ModalFooter>
              <mui.Button
                variant="contained"
                onClick={closeModal}
                style={{
                  marginRight: "4%",
                  backgroundColor: "#43425D",
                  color: "white",
                  textTransform: "none",
                  width: "25%",
                }}
              >
                Cancel
              </mui.Button>
              <mui.Button
                variant="contained"
                style={{
                  backgroundColor: "#F2134F",
                  color: "white",
                  textTransform: "none",
                  marginRight: "4%",
                  width: "25%",
                }}
                //    onClick ={inviteUser}
              >
                Invite
              </mui.Button>
            </mui.ModalFooter>
          </mui.Modal>

          <mui.Modal isOpen={modal} toggle={toggleModal} centered={true}>
            <mui.ModalBody className={styles.vdmodalContainer}>
              <div className={styles.vdinnerDiv}>
                {state.document.map((doc) => (
                  <div
                    style={{
                      wordBreak: "break-all",
                      color: "#3b86ff",
                      textDecoration: "underline",
                      background: "white",
                      cursor: "pointer",
                    }}
                  >
                    <span>AADhar</span>
                  </div>
                ))}
              </div>
              <FormControlLabel
                control={
                  <mui.Checkbox
                    checked={state.checkedA}
                    onChange={handleChange}
                    name="checkedModule1"
                    style={{
                      color: "#3B86FF",
                    }}
                  />
                }
                style={{ color: "#43425D" }}
                label="Aadhaar Card"
              />
              <FormControlLabel
                control={
                  <mui.Checkbox
                    checked={state.checkedB}
                    onChange={handleChange}
                    name="checkedModule2"
                    style={{
                      color: "#3B86FF",
                    }}
                  />
                }
                style={{ color: "#43425D" }}
                label="Pan Card"
              />
              <FormControlLabel
                control={
                  <mui.Checkbox
                    checked={state.checkedC}
                    onChange={handleChange}
                    name="checkedModule3"
                    style={{
                      color: "#3B86FF",
                    }}
                  />
                }
                style={{ color: "#43425D" }}
                label="Others"
              />
            </mui.ModalBody>
            <mui.ModalFooter
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <mui.Button
                variant="contained"
                onClick={closeVerifyModal}
                style={{
                  backgroundColor: "rgb(67 66 93)",
                  marginRight: "12px",
                  color: "white",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  fontSize: "small",
                  outline: "none",
                }}
              >
                Cancel
              </mui.Button>
              <mui.Button
                variant="contained"
                onClick={verfiyDocument}
                style={{
                  backgroundColor: "#0CD241",
                  color: "white",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  fontSize: "small",
                  outline: "none",
                }}
              >
                Verify
              </mui.Button>
            </mui.ModalFooter>
          </mui.Modal>
        </mui.Table>
        {props.pendingDocList.length === 0 && (
          <p style={{ textAlign: "center" }}>
            {t("property_list.no_record_found")}
          </p>
        )}
      </mui.TableContainer>
      <div className={styles.vdpaginationDiv}>
        {props.pendingDocList.length > 0 && setPage()}
      </div>
    </div>

    // {/* </div> */}
  );
}
export function Security() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: "false",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  return (
    <div className={styles.secMainDiv}>
      <div className={styles.secHeader}>
        <span className={styles.secTitle}>Security</span>
      </div>
      <div className={styles.secHeader2}>
        <span style={{ marginLeft: "3%" }} className={styles.secTitle2}>
          DELETE
        </span>
        <span style={{ marginLeft: "10%" }} className={styles.secTitle2}>
          ROLE DESCRIPTION
        </span>
        <span style={{ marginLeft: "30%" }} className={styles.secTitle2}>
          PERMISSIONS
        </span>
      </div>
      <div className={styles.cellDiv}>
        <FormControlLabel
          control={
            <mui.Checkbox
              checked={state.checkedA}
              onChange={handleChange}
              name="checkedA"
              style={{ color: "#43425D" }}
            />
          }
        />
        <mui.InputBase
          className={classes.select}
          style={{ marginLeft: "15%" }}
          placeholder="Admin"
          InputProps={{
            input: classes.input,
          }}
        />
        {/* Custom minWidth:"19vw" */}
        <div className={styles.innerCellDiv}>
          <FormControlLabel
            control={
              <mui.Checkbox
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                style={{ color: "#43425D" }}
              />
            }
            label="addUser"
            style={{ color: "#43425D", fontWeight: "bolder" }}
          />
          <FormControlLabel
            control={
              <mui.Checkbox
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                style={{ color: "#43425D" }}
              />
            }
            label="editRoles"
            style={{ color: "#43425D", fontWeight: "bolder" }}
          />
        </div>
        <div className={styles.innerCellDiv}>
          <FormControlLabel
            control={
              <mui.Checkbox
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                style={{ color: "#43425D" }}
              />
            }
            label="editUser"
            style={{ color: "#43425D", fontWeight: "bolder" }}
          />
          <FormControlLabel
            control={
              <mui.Checkbox
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                style={{ color: "#43425D" }}
              />
            }
            label="deleteUser"
            style={{ color: "#43425D", fontWeight: "bolder" }}
          />
        </div>
      </div>
      <div className={styles.cellDiv} style={{ border: "none" }}>
        <FormControlLabel
          control={
            <mui.Checkbox
              checked={state.checkedA}
              onChange={handleChange}
              name="checkedA"
              style={{ color: "#43425D" }}
            />
          }
        />
        <mui.InputBase
          className={classes.select}
          style={{ marginLeft: "15%" }}
        />
        {/* Custom minWidth:"19vw" */}
        <div className={styles.innerCellDiv}>
          <FormControlLabel
            control={
              <mui.Checkbox
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                style={{ color: "#43425D" }}
              />
            }
            label="addUser"
            style={{ color: "#43425D", fontWeight: "bolder" }}
          />
          <FormControlLabel
            control={
              <mui.Checkbox
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                style={{ color: "#43425D" }}
              />
            }
            label="editRoles"
            style={{ color: "#43425D", fontWeight: "bolder" }}
          />
        </div>
        <div className={styles.innerCellDiv}>
          <FormControlLabel
            control={
              <mui.Checkbox
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                style={{ color: "#43425D" }}
              />
            }
            label="editUser"
            style={{ color: "#43425D", fontWeight: "bolder" }}
          />
          <FormControlLabel
            control={
              <mui.Checkbox
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                style={{ color: "#43425D" }}
              />
            }
            label="deleteUser"
            style={{ color: "#43425D", fontWeight: "bolder" }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: "5%",
          marginTop: "2%",
          marginBottom: "10%",
        }}
      >
        <mui.Button
          variant="outlined"
          style={{
            border: "1.5px solid #43425D",
            textTransform: "none",
            outline: "none",
            color: "#43425D",
            fontWeight: "bold",
          }}
        >
          Update Roles
        </mui.Button>
      </div>
    </div>
  );
}

export function Main(props) {
  const classes = useStyles();
  const [t] = useTranslation("common");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <span style={{ textTransform: "capitalize" }}>
          {props.orgData.orgName} Organisation
        </span>
      </div>
      <div className={styles.tabsDiv}>
        <div className={classes.root}>
          <div style={{ marginLeft: "-1%" }}>
            <AntTabs
              value={value}
              onChange={handleChange}
              scrollButtons="auto"
              aria-label="ant example"
              centered={true}
            >
              <AntTab label={t("organisation.organisation")} />

              <AntTab label={t("people.people")} />

              {props.accessList.module !== undefined &&
                hasAccess(
                  "submoduleId",
                  "team_organisation-list",
                  props.accessList.module
                ) && <AntTab label={t("team.team")} />}

              <AntTab label={t("people.module")} />

              <AntTab label={t("role.role")} />
                  
              <AntTab label={t("payments.payments")} />

              <AntTab label={t("settings.SETTINGS")} />

            </AntTabs>
            <TabPanel value={value} index={0}>
              <Organisation {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <People {...props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Teams {...props} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Module {...props} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Role {...props} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Payments />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Settings />
            </TabPanel>

           
          </div>
        </div>
      </div>
      <style>
        {`

      
        .uploadLabel{
          background-color: rgb(59, 134, 255);
          color: white;
          text-align: center;
          height: 3em;
          margin-top: 10%;
          padding: 5px;
          cursor: pointer;
        }
        
        @media only screen and (max-width: 768px) {
          /* For mobile phones: */
          .MuiTabs-centered {
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .OrganisationMain_organisationDiv__2t6KP{
          flex-direction: column-reverse;
          align-items:center;
        }

        .OrganisationMain_organisationFormDiv__2Sl8b{
          width:100%;
        }

        .OrganisationMain_container__2QiO9 {
          width: 200px;
      }
        
        }

        
        `}
      </style>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    docVerifyList: state.Organization.verifyDocList,
    moduleList: state.Organization.orgModuleList,
    docVerified: state.Organization.docVerified,
    teamList: state.Organization.teamList,
    allRoleList: state.Organization.allOrgRoleList,
    allOrgTeamList: state.Organization.allOrgTeamList,
    success: state.Organization.success,
    loading: state.Organization.loading,
    roleList: state.Organization.roleList,
    isUserInvited: state.Organization.isUserInvited,
    totalUser: state.Users.total,
    pageUser: state.Users.page,
    limitUser: state.Users.limit,
    isRoleAdded: state.Organization.isRoleAdded,
    orgData: state.Organization.orgData,
    verified_docs: state.Organization.verify_docs,
    total: state.Organization.total,
    page: state.Organization.page,
    limit: state.Organization.limit,
    message: state.Organization.message,
    accessList: state.Organization.accessList,
    pendingDocList: state.Organization.pendingDocList,
    memberList: state.Organization.memberList,
    totalMember: state.Organization.totalMember,
    isRoleChange: state.Organization.isRoleChange,
    isContractorUpdate: state.Organization.isContractorUpdate,
    pending_invitation: state.Organization.pending_invitation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVerifyDocList: (page, limit, orgId, search) =>
      dispatch(actions.getVerifyDocList(page, limit, orgId, search)),

    getPendingDocList: (page, limit, orgId, search) =>
      dispatch(actions.getUserPenddingDoclist(page, limit, orgId, search)),

    getMemberList: (page, limit, orgId, search) =>
      dispatch(actions.getMemberList(page, limit, orgId, search)),

    changeUserActive: (memberList, user) =>
      dispatch(actions.changeUserActive(memberList, user)),

    manageRole: (data) => dispatch(actions.addUpdateDeleteRole(data)),

    verifyDocument: (data) => dispatch(actions.verifyDocument(data)),

    getRoleList: (page, limit, search) =>
      dispatch(actions.getRoleList(page, limit, search)),

    getAccessControl: (data) => dispatch(actions.getAccess(data)),

    getUserorgList: (data) => dispatch(actions.getUserOrgList(data)),

    getTeam: (page, limit, search) =>
      dispatch(actions.getTeam(page, limit, search)),

    getAllTeams: () => dispatch(actions.getAllOrgTeamList()),

    getOrgRoleList: () => dispatch(actions.getAllOrgRole()),

    getOrgModuleList: (orgid) => dispatch(actions.getOrgModuleList(orgid)),

    inviteUser: (data, tempValue) =>
      dispatch(actions.inviteOrgUser(data, tempValue)),

    addTeam: (data) => dispatch(actions.addTeam(data)),

    changeRole: (data) => dispatch(actions.changeUserRole(data)),

    downlaodExcelFile: (type, search) =>
      dispatch(actions.downloadExcelFile(type, search)),
    updateUserContractor: (data) =>
      dispatch(actions.updateContractorName(data)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Main);
