/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import * as mui from "../../shared/mui";
import styles from "./Stepper.module.css";
import { toast } from "react-toastify";
import { withTranslation, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../store/actions/index";
import axios from "axios";
import * as myConstClass from "../../constants/constants";
const api_url = myConstClass.apiUrl;

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);


const useStyles = mui.makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#3B86FF",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export function OrgStepper(props) {
  // const classes = useStyles();

  const [t] = useTranslation("common");

  const history = useHistory();
  const { id } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    t("add_org.basics_details"),
    t("add_org.add_head"),
    t("add_org.module_and_permission"),
    t("add_org.done"),
  ];

  const classes = useStyles();
  const [state, setState] = React.useState({
    name: "",
    subDomain: "",
    countryId: "",
    stateId: "",
    districtId: "",
    cityId: "1234",
    postalCode: "",
    prefix: "",
    email: "",
    role: "",
    country: "",
    state: "",
    district: "",
    city: "",
    moduelList: [],
    selectedModule: [],
  });

  /// FETCH STATE LIST HERE
  useEffect(() => {
    if (id) {
      getData();
    }
    props.getAllCountry();
    setTimeout(() => {
      props.getAllModule("add_org");
    }, 1000);
  }, []);

  const getData = (page) => {
    axios
      .get(api_url + `organisation/getbyId?organisationId=${id}`, {
        method: "GET",
        headers: {
          "content-type": "Application/json",
          accept: "Application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        response.data.module.forEach((el, i) => {
          response.data.module[i]._id = el.moduleId;
          response.data.module[i].subModule = el.submoduleId;
        });
        setState({
          ...state,
          name: response.data.data.name,
          stateId: response.data.data.stateId,
          subDomain: response.data.data.subDomain,
          districtId: response.data.data.districtId,
          postalCode: response.data.data.postalCodes,
          prefix: response.data.data.prefix,
          email: response.data.data.email,
          selectedModule: response.data.module,
          countryId: response.data.data.countryId,
          cityId: response.data.data.cityId,
        });
        props.getStateByCountry(response.data.data.countryId);
        props.getDistrictByState(response.data.data.stateId);
        props.getCityByDistrict(response.data.data.districtId);
      })
      .catch((error) => {
        setState({ ...state });
      });
  };

  const handleChange = (event) => {
    if (event.target.value !== "0") {
      const name = event.target.name;
      setState({
        ...state,
        [name]: event.target.value,
      });
      if (event.target.name === "countryId") {
        props.getStateByCountry(event.target.value);
        let find = props.countryList.find((x) => x._id === event.target.value);
        setState({
          ...state,
          country: find.country,
          [name]: event.target.value,
        });
      } else if (event.target.name === "stateId") {
        let find = props.stateList.find((x) => x._id === event.target.value);
        setState({ ...state, state: find.state, [name]: event.target.value });
        props.getDistrictByState(event.target.value);
      } else if (event.target.name === "districtId") {
        let find = props.districtList.find((x) => x._id === event.target.value);
        setState({
          ...state,
          district: find.district,
          [name]: event.target.value,
        });
        props.getCityByDistrict(event.target.value);
      } else if (event.target.name === "cityId") {
        let find = props.cityList.find((x) => x._id === event.target.value);
        setState({ ...state, city: find.city, [name]: event.target.value });
      }
    }
  };
  const handleCheckChange = (event, data, idx, type, parentId) => {
    if (type === "parent") {
      if (event.target.checked) {
        state.selectedModule.push({ _id: data._id, mod_enableStatus: true });

        const subMod = data.subModule.map((submodule)=> { 
          return {_id: submodule._id, mod_enableStatus: true}
        })
        
        state.selectedModule[
          state.selectedModule.length-1
        ].subModule = subMod;

  
      } else {
        let mod = state.selectedModule.find((x) => x._id === data._id);
        state.selectedModule.splice(state.selectedModule.indexOf(mod), 1);
      }

      setState({
        ...state,
        selectedModule: state.selectedModule,
      });
    } else if (type === "child") {
      const mod = state.selectedModule.find((x) => x._id === parentId);
      if (event.target.checked) {
        if (
          state.selectedModule[state.selectedModule.indexOf(mod)].subModule ===
          undefined
        ) {
          var sub = [];
          sub.push({ _id: data._id, mod_enableStatus: true });
          state.selectedModule[
            state.selectedModule.indexOf(mod)
          ].subModule = sub;
        } else {
          state.selectedModule[
            state.selectedModule.indexOf(mod)
          ].subModule.push({ _id: data._id, mod_enableStatus: true });
        }
      } else {
        let sub = state.selectedModule[
          state.selectedModule.indexOf(mod)
        ].subModule.find((x) => x._id === data._id);
        let sbIdx = state.selectedModule[
          state.selectedModule.indexOf(mod)
        ].subModule.indexOf(sub);
        state.selectedModule[
          state.selectedModule.indexOf(mod)
        ].subModule.splice(sbIdx, 1);
      }
      setState({
        ...state,
        selectedModule: state.selectedModule,
      });
    }
  };

  // disable child module if parent is not selected
  const disabledChildModule = (id, i) => {
    if (id !== undefined && state.selectedModule.length > 0) {
      let disable = state.selectedModule.find((x) => x._id === id);
      if (disable && disable.mod_enableStatus) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (id === undefined) {
      setState({
        ...state,
        // selectedModule: props.moduleList
      });
    }
  }, [props.moduleList.length]);

  // get checked item
  const checkedOption = (id, i, type, parentId) => {
    var ch = null;
    if (id !== undefined && type === "parent") {
      let arr = state.selectedModule;
      ch = arr.find((x) => x._id === id);
      if (ch && ch.mod_enableStatus) {
        return true;
      } else {
        return false;
      }
    } else if (id !== undefined && type === "child") {
      let arr = state.selectedModule;
      let parentModule = state.selectedModule.find((x) => x._id === parentId);
      let indexx = parentModule && state.selectedModule.indexOf(parentModule);
      let mdl = arr[indexx];
      if (mdl) {
        ch = arr[indexx].subModule && arr[indexx].subModule.find((x) => x._id === id);

        // var ch =  arr.length>0&&i<=arr.length? arr[i].subModule.find(x=>x._id==id):  undefined;
        if (ch && ch.mod_enableStatus) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  /// Display country
  const fetchCountry = (type) => {
    if (
      type === "country" &&
      state.countryId !== "0" &&
      props.countryList.length > 0
    ) {
      let country = props.countryList.find((x) => x._id === state.countryId);
      return <span>{country.country}</span>;
    } else if (
      type === "state" &&
      state.stateId !== "0" &&
      props.stateList.length > 0
    ) {
      let states = props.stateList.find((x) => x._id === state.stateId);
      return <span>{states.state}</span>;
    } else if (
      type === "district" &&
      state.districtId !== "0" &&
      props.districtList.length > 0
    ) {
      let dist = props.districtList.find((x) => x._id === state.districtId);
      return <span>{dist.district}</span>;
    } else if (
      type === "city" &&
      state.cityId !== "0" &&
      props.cityList.length > 0
    ) {
      let dist = props.cityList.find((x) => x._id === state.cityId);
      return <span>{dist.city}</span>;
    } else {
      return "-";
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (state.name.trim() === "") {
        return toast.error(t("add_org.org_error"), {});
      } else if (state.subDomain.trim() === "") {
        return toast.error(t("add_org.domain_error"), {});
      } else if (state.countryId.trim() === "") {
        return toast.error(t("add_org.country_error"), {});
      } else if (state.stateId.trim() === "") {
        return toast.error(t("add_org.state_error"), {});
      } else if (state.districtId.trim() === "") {
        return toast.error(t("add_org.district_error"), {});
      } else if (state.cityId.trim() === "") {
        return toast.error(t("add_org.city_error"), {});
      } else if (state.postalCode.trim() === "") {
        return toast.error(t("add_org.postal_error"), {});
      } else if (state.prefix.trim() === "") {
        return toast.error(t("add_org.prefix_error"), {});
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (id === undefined && state.email.trim() === "") {
        return toast.error(t("add_org.email_error"), {});
      } else if (id === undefined && !validEmailRegex.test(state.email)) {
        return toast.error(t("add_org.email_vaild_er"), {});
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 2) {
      let result = state.selectedModule.filter(
        (x) => x.mod_enableStatus === true
      );
      if (result.length === 0) {
        return toast.error(t("add_org.module_error"), {});
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 3) {
      var module = [];
      state.selectedModule.forEach((el, index) => {
        var ar = {};
        if (el.mod_enableStatus) {
          ar.mod_enableStatus = el.mod_enableStatus;
          ar.moduleId = el._id;
        }
        if (el.subModule !== undefined) {
          var arr1 = [];
          el.subModule.forEach((sub, i) => {
            arr1.push(sub._id);
          });
          ar.submoduleId = arr1;
          module.push(ar);
        } else {
          if (el.mod_enableStatus) {
            ar.submoduleId = [];
            module.push(ar);
          }
        }
      });
      if (id) {
        state.module = state.selectedModule;
        state.id = id;
        props.updateOrg(state);
      } else {
        state.module = module;
        props.addOrg(state);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // redierct to the orgnisation page
  useEffect(() => {
    if (props.success) {
      // alert("dfd")
      history.push("/organization");
    }
  });
  const forms = [
    <>
      <div className={styles.row1}>
        <mui.TextField
          id="outlined-basic"
          name="name"
          value={state.name}
          onChange={handleChange}
          label={
            <span className={styles.label}>
              {t("add_org.organisation_name")}
            </span>
          }
          variant="outlined"
          size="normal"
          style={{ width: "40%" }}
        />

        <mui.TextField
          id="outlined-basic"
          name="subDomain"
          value={state.subDomain}
          onChange={handleChange}
          label={
            <span className={styles.label}>{t("add_org.sub_domain_name")}</span>
          }
          variant="outlined"
          size="normal"
          style={{ width: "40%" }}
        />
      </div>

      <div className={styles.row1}>
        <mui.FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "40%" }}
        >
          <mui.InputLabel htmlFor="outlined-age-native-simple">
            {
              <span className={styles.label}>
                {t("add_org.select_country")}
              </span>
            }
          </mui.InputLabel>
          <mui.Select
            native
            value={state.countryId}
            onChange={handleChange}
            label={t("add_org.select_country")}
            inputProps={{
              name: "countryId",
              id: "outlined-age-native-simple",
            }}
          >
            <option aria-label="None" value={"0"}>
              {t("add_org.select_country")}
            </option>
            {props.countryList.length > 0 &&
              props.countryList.map((country, i) => (
                <option aria-label="None" value={country._id}>
                  {country.country}
                </option>
              ))}
          </mui.Select>
        </mui.FormControl>
        <mui.FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "40%" }}
        >
          <mui.InputLabel htmlFor="outlined-age-native-simple">
            {<span className={styles.label}>{t("add_org.select_state")}</span>}
          </mui.InputLabel>
          <mui.Select
            native
            value={state.stateId}
            onChange={handleChange}
            label={t("add_org.select_state")}
            inputProps={{
              name: "stateId",
              id: "outlined-age-native-simple",
            }}
          >
            <option aria-label="None" value={"0"}>
              {t("add_org.select_state")}
            </option>
            {props.stateList.length > 0 &&
              props.stateList.map((state, i) => (
                <option aria-label="None" value={state._id}>
                  {state.state}
                </option>
              ))}
          </mui.Select>
        </mui.FormControl>
        {/* <TextField id="outlined-basic" name="district" value={state.district} onChange={handleChange} label={<span className={styles.label}>District Name</span>} variant="outlined" size='normal' style={{ width: '40%' }} /> */}
      </div>
      <div className={styles.row1}>
        <mui.FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "40%" }}
        >
          <mui.InputLabel htmlFor="outlined-age-native-simple">
            {
              <span className={styles.label}>
                {t("add_org.select_district")}
              </span>
            }
          </mui.InputLabel>
          <mui.Select
            native
            value={state.districtId}
            onChange={handleChange}
            label={t("add_org.select_district")}
            inputProps={{
              name: "districtId",
              id: "outlined-age-native-simple",
            }}
          >
            <option aria-label="None" value={"0"}>
              -{t("add_org.select_district")}-
            </option>
            {props.districtList.length > 0 &&
              props.districtList.map((district, i) => (
                <option aria-label="None" value={district._id}>
                  {district.district}
                </option>
              ))}
          </mui.Select>
        </mui.FormControl>

        <mui.FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "40%" }}
        >
          <mui.InputLabel htmlFor="outlined-age-native-simple">
            {<span className={styles.label}>{t("add_org.select_city")}</span>}
          </mui.InputLabel>
          <mui.Select
            native
            value={state.cityId}
            onChange={handleChange}
            label={t("add_org.select_city")}
            inputProps={{
              name: "cityId",
              id: "outlined-age-native-simple",
            }}
          >
            <option aria-label="None" value={"0"}>
              -{t("add_org.select_city")}-
            </option>
            {props.cityList.length > 0 &&
              props.cityList.map((city, i) => (
                <option aria-label="None" value={city._id}>
                  {city.city}
                </option>
              ))}
          </mui.Select>
        </mui.FormControl>
      </div>
      <div className={styles.row1}>
        <mui.TextField
          id="outlined-basic"
          name="postalCode"
          value={state.postalCode}
          onChange={handleChange}
          label={
            <span className={styles.label}>{t("add_org.postal_code")}</span>
          }
          variant="outlined"
          size="normal"
          style={{ width: "40%" }}
        />
        <mui.TextField
          id="outlined-basic"
          name="prefix"
          onChange={handleChange}
          value={state.prefix}
          label={<span className={styles.label}>{t("add_org.prefix")}</span>}
          variant="outlined"
          size="normal"
          style={{ width: "40%" }}
        />
      </div>
    </>,
    <>
      <div className={styles.row1}>
        {id === undefined && (
          <mui.TextField
            id="outlined-basic"
            name="email"
            value={state.email}
            onChange={handleChange}
            label={
              <span className={styles.label}>{t("add_org.search_name")}</span>
            }
            variant="outlined"
            size="normal"
            style={{ width: "40%" }}
          />
        )}
        <mui.FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "40%" }}
        >
          <mui.InputLabel htmlFor="outlined-age-native-simple">
            {<span className={styles.label}>{t("add_org.role")}</span>}
          </mui.InputLabel>
          <mui.Select
            native
            value={state.age}
            onChange={handleChange}
            label="Select District"
            inputProps={{
              name: "age",
              id: "outlined-age-native-simple",
            }}
          >
            <option aria-label="None" value="" />
            <option value={10}>Admin</option>
            <option value={20}>User</option>
            {/* <option value={30}>Thirty</option> */}
          </mui.Select>
        </mui.FormControl>
      </div>
    </>,
    <div className={styles.checked}>
      {props.moduleList.length > 0 &&
        props.moduleList.map((module, idx) => (
          <div style={{ width: "100%", marginLeft: "40px" }}>
            <mui.FormControlLabel
              control={
                <mui.Checkbox
                  checked={checkedOption(module._id, idx, "parent")}
                  onChange={(e) => handleCheckChange(e, module, idx, "parent")}
                  name="parent"
                  color="primary"
                  key={module._id}
                />
              }
              label={module.name}
            />
            {/* <div style={{marginLeft:"50px"}}> */}
            {module.subModule.length > 0 &&
              module.subModule.map((sub, i) => (
                <div style={{ marginLeft: "80px" }}>
                  {" "}
                  <mui.FormControlLabel
                    control={
                      <mui.Checkbox
                        style={{ display: "inline-block" }}
                        checked={checkedOption(
                          sub._id,
                          idx,
                          "child",
                          module._id
                        )}
                        onChange={(e) => {
                          handleCheckChange(e, sub, i, "child", module._id);
                        }}
                        name="child"
                        color="primary"
                        display="inline-block"
                        disabled={disabledChildModule(module._id, idx)}
                        key={sub._id}
                      />
                    }
                    label={sub.name}
                  />
                </div>
              ))}
            {/* </div> */}
          </div>
        ))}

      
    </div>,
    <>
      <div className={styles.review}>
        <ul className={styles.ul}>
          <li className={styles.li}>
            <b style={{ fontWeight: "normal" }} className={styles.b}>
              {t("add_org.organisationname")}
            </b>
            : {state.name}
          </li>
          <li className={styles.li}>
            <b style={{ fontWeight: "normal" }} className={styles.b}>
              {t("add_org.domain")}
            </b>
            : {state.subDomain}{" "}
          </li>
          <li className={styles.li}>
            <b style={{ fontWeight: "normal" }} className={styles.b}>
              {t("add_org.country")}
            </b>
            :{activeStep === 3 && fetchCountry("country")}
          </li>
          <li className={styles.li}>
            <b style={{ fontWeight: "normal" }} className={styles.b}>
              {t("add_org.state")}
            </b>
            : {activeStep === 3 && fetchCountry("state")}
          </li>
          <li className={styles.li}>
            <b style={{ fontWeight: "normal" }} className={styles.b}>
              {t("add_org.district")}{" "}
            </b>
            :{activeStep === 3 && fetchCountry("district")}
          </li>
          <li className={styles.li}>
            <b style={{ fontWeight: "normal" }} className={styles.b}>
              {t("add_org.city")}
            </b>
            :{activeStep === 3 && fetchCountry("city")}
          </li>
          <li className={styles.li}>
            <b style={{ fontWeight: "normal" }} className={styles.b}>
              {t("add_org.postal_code")}
            </b>
            :{state.postalCode}
          </li>
          <li className={styles.li}>
            <b style={{ fontWeight: "normal" }} className={styles.b}>
              {t("add_org.search_name")}
            </b>
            :{state.email}
          </li>
          <li className={styles.li}>
            <b style={{ fontWeight: "normal" }} className={styles.b}>
              {t("add_org.role")}
            </b>
            : User
          </li>
          <li className={styles.li}>
            <b style={{ fontWeight: "normal" }} className={styles.b}>
              {t("add_org.modules")}
            </b>
            : {state.selectedModule.length}
          </li>
        </ul>
      </div>
    </>,
  ];
  // ENABEL LAODING
  const backDrop = props.loading ? (
    <mui.Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <span>{t("add_org.title")}</span>
      </div>
      <div className={styles.container}>
        <div className={classes.root}>
          <mui.Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <mui.Step key={label} style={{ color: "#3B86FF" }}>
                <mui.StepLabel StepIconProps={{ iconColor: "#3B86FF" }}>
                  {label}
                </mui.StepLabel>
              </mui.Step>
            ))}
          </mui.Stepper>
          <div className={styles.formDiv}>
            <form className={classes.root} noValidate autoComplete="off">
              {forms[activeStep]}
            </form>
          </div>
          {/* <AddOrganisation  formNumber={activeStep} /> */}
          <div>
            {activeStep === steps.length ? null : (
              <div className={styles.stepperButtons}>
                <mui.Button
                  hidden={activeStep === 0}
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  style={{
                    backgroundColor: "#43425D",
                    outline: "none",
                  }}
                  className={classes.backButton}
                >
                  {t("add_org.previous")}
                </mui.Button>
                <mui.Button
                  variant="contained"
                  style={{
                    backgroundColor: "#F2134F",
                    color: "white",
                    outline: "none",
                  }}
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1
                    ? id
                      ? t("add_org.update")
                      : t("add_org.submit")
                    : t("add_org.next_step")}
                </mui.Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.Organization.loading,
    error: state.Organization.error,
    message: state.Organization.message,
    countryList: state.State.state.countryList,
    stateList: state.District.district.stateList,
    districtList: state.City.city.districtList,
    cityList: state.City.city.allCityList,
    moduleList: state.Organization.moduleList,
    success: state.Organization.success,
    data: state.Organization.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addOrg: (data) => dispatch(actions.addOrg(data)),
    updateOrg: (data) => dispatch(actions.updateOrg(data)),
    getAllCountry: () => dispatch(actions.getAllCountry()),
    getAllModule: (type) => dispatch(actions.getAllModule(type)),
    getStateByCountry: (stateId) =>
      dispatch(actions.getStateByCountry(stateId)),
    getDistrictByState: (stateId) =>
      dispatch(actions.getDistrictByState(stateId)),
    getCityByDistrict: (distId) => dispatch(actions.getCityByDistrict(distId)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(OrgStepper);