/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import  {useHistory } from "react-router-dom";

import * as $ from 'jquery';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./UploadProperty.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { toast } from 'react-toastify';

import download from "./download.png";
import upload from "./upload.png";
import * as actions  from '../../../../../store/actions/index'
import {compose} from 'redux'
import {connect} from 'react-redux'
import { withTranslation } from "react-i18next";
import * as myConstClass from '../../../../../constants/constants';

const propertyTemplateUrl = myConstClass.propertyTemplateUrl;

const useStyles = makeStyles((theme) => ({
	onDrop:{
		outline:' 2px dashed black',
		outlineOffset: '-10px',
	
	},
}));

export  function UploadProperty(props) {
	const [state, setState] = React.useState({
		categoryId:"0",
		subcategoryId:"0",
		propertyFile:[],
		fileName:""
	});
	const history =useHistory()

	const classes = useStyles();

	useEffect(()=>{
		
		props.getAllCategory()
	},[])
	// handle submit form
	const handleSubmit =(e)=>{
		e.preventDefault();
		if(state.propertyFile.length===0){
			return toast.error('Please select file')
		}
		else{
		// call api 
		state.orgId=localStorage.getItem('orgId')
		props.uploadBulkProperty(state)	
		}
	}

	// upload audio file
const uploadFile=(e, type, i)=>{
    
    if (e.target.files && e.target.files.length > 0 ) {
       var validExtensions = ['csv', 'xlsx'];
       
       var isValid = true;
       for(let img of e.target.files){
           
        if($.inArray(img.name.substr(img.name.lastIndexOf('.') + 1), validExtensions) === -1){
            e.target.value = "";
            isValid = false;
            toast.error("Invalid file format, only csv xlsx file format allowed")
              
        }
         break;
         }
       
    if(isValid){
        let reader = new FileReader();
      setState({...state,
		  propertyFile:(e.target.files[0]),
            fileName:e.target.files[0].name
         
          })

            reader.onloadend = () => {
              
              
            }
          reader.readAsDataURL(e.target.files[0]);
       }
    
    
      }  
      else{
         
          e.preventDefault()
       
          return 
      } 
}

useEffect(()=>{
		
	if(props.success){
	history.push('/property-list')
	}
	})
	    // ENABEL LAODING
		const  backDrop =props.loading? (
			<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={props.loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		):null


		const handleDragEnd = event => {
		  };
		
		  const handleDragOver = event => {
			event.preventDefault();
			if(event.dataTransfer.types[0] === "Files"){
			event.dataTransfer.dropEffect = "move";
			$("#container").addClass(classes.onDrop);
			$("#icon").addClass('text-96 font-900');
			$("#content").addClass('text-16');
		  };}
		
		  const handleDrop = event => {
			 event.preventDefault();
			 if(event.dataTransfer.types[0] === "Files"){
			var files = [...event.dataTransfer.files];
				var isValid = true;
				var validExtensions = ['csv', 'xlsx'];
				   
			 for(let img of files){
				if ($.inArray(img.name.substr(img.name.lastIndexOf('.') + 1), validExtensions) === -1) {
					event.target.value = "";
					isValid = false;
					toast.error("Invalid file format, only csv xlsx file format allowed")
					$("#container").removeClass(classes.onDrop);
					$("#icon").removeClass('text-96 font-900');
					$("#content").removeClass('text-16');
		  
					 break;
				}
				
			 
				
			  }
			  if(isValid){
				setState({...state,
					propertyFile:(event.dataTransfer.files[0]),
					  fileName:event.dataTransfer.files[0].name
				   
					})
			     	$("#container").removeClass(classes.onDrop);
		}
	}
		};
		
		const handleDragEnter = event => {
			event.preventDefault();
			if(event.dataTransfer.types[0] === "Files"){
				// setDragging(drag)
				event.dataTransfer.dropEffect = "move";
				$("#container").addClass(classes.onDrop);
				$("#icon").addClass('text-96 font-900');
				$("#content").addClass('text-16');
			}
			
		  }
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<span style={{ fontWeight: "lighter" }}>Property List /</span>
				<span style={{ color: "#a9a9a9", fontWeight: "lighter" }}>
					&nbsp;Upload Property
				</span>
			</div>
			<div className={styles.formDiv}>
				<form noValidate autoComplete="off">
					
					<div className={styles.row1}>
						<Button
							variant="outlined"
							style={{
								color: "#3B86FF",
								borderColor: "#3B86FF",
								textTransform: "none",
								fontWeight: "lighter",
								outline: "none",
								marginRight: "60%",
								// width: "30%",
								padding: "1%",
							}}
						>
							<span
								style={{
									alignSelf: "left",
									marginRight: "15%",
								}}
							>
								Download Template
							</span>
							<div style={{ marginLeft: "5%" }}>
								<a href={propertyTemplateUrl} download><img src={download} alt="download" /></a>
							</div>
						</Button>
					</div>
					<div
						onDrop={event => handleDrop(event)}
						onDragEnter={event => handleDragEnter(event)}
						onDragOver={event => handleDragOver(event)}
						onDragLeave={event => handleDragEnd(event)}
						className={styles.innerDiv} id="container">
						<img
							src={upload}
							alt="upload"
							style={{
								alignSelf: "center",
								paddingTop: "10%",
								paddingBottom: "4%",
							}}
						/>
						<span
							style={{
								color: "#4D4F5C",
								textAlign: "center",
								opacity: "50%",
							}}
						>
							Drag and drop a file here
						</span>
						<span
							style={{
								color: "#4D4F5C",
								textAlign: "center",
								opacity: "50%",
								padding: "2%",
							}}
						>
							or
						</span>
						<label
					 
							variant="contained"
							style={{
								backgroundColor: "#3B86FF",
								color: "white",
								textTransform: "none",
								fontWeight: "lighter",
								outline: "none",
								// width: "30%",
								alignSelf: "center",
								// marginBottom: "10%",
								padding: "9px 29px",
								cursor: "pointer",
								/* padding-left: 57px; */
								boxShadow: " 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
								borderRadius: "3px"
							}}
						>

							<input type="file" onChange={uploadFile} style={{ display: "none" }} />Choose File
							</label>
						<a style={{
							textAlign: "center",
							color: "#007bff",
							textDecoration: "underline",
							marginBottom: "10%"
						}}>{state.fileName}</a>

					</div>
			
					<div className={styles.buttonDiv}>
						<div className={styles.buttons}>
							<Button
								variant="contained"
								style={{
									backgroundColor: "#43425D",
									color: "white",
									textTransform: "none",
									fontWeight: "lighter",
									outline: "none",
								}}
							>
								Cancel
						</Button>
						</div>
						<div className={styles.buttons}>
							<Button
								variant="contained"
								style={{
									backgroundColor: "#F2134F",
									color: "white",
									textTransform: "none",
									fontWeight: "lighter",
									outline: "none",
								}}
								onClick={handleSubmit}
							>
								Submit
						</Button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}


const mapStateToProps =(state)=>{
	return{
	allCategoryList:state.Category.category.allCategoryList,
	subcategoryList:state.Property.subcategoryList,
	success:state.Property.success,
	loading:state.Property.loading,
	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
	 getAllCategory:()=>
	 dispatch(actions.getAllCategory()),
	 getSubCategoryByCategory:(id)=>
	 dispatch(actions.getSubCategoryByCategory(id)),
	 uploadBulkProperty :(data)=>
	 dispatch(actions.uploadProperty(data))
	}
}


export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(UploadProperty)