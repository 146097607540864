/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import  { useParams } from "react-router-dom";
import * as acitons  from '../../../../../store/actions/index'
import {compose} from 'redux'
import { withTranslation,useTranslation } from 'react-i18next';
import moment from 'moment';


import {connect} from 'react-redux'
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import styles from "./JobDetails.module.css";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "25ch",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
        },
	},
}));

export  function AllProperties(props) {
	const classes = useStyles();
	const { id, title,description, categoryId, subcategoryId, name, startDate, endDate} = useParams();
	const [state, setState] = React.useState({
		status: "",
		search: "",
		title:"",
		name:"",
		id:"",
		isDelete:true,isAdd:false, isUpdate:false,
	});
	const [modal, setModal] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(true);
	const [t] = useTranslation('common');

	
	useEffect(()=>{
		let orgId = localStorage.getItem('orgId')
	props.getPropertyByJob(1, props.limit, orgId, state.search, state.status, id )
	},[])
	const handleChange = (event) => {
		
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
	};

	
	let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

	// ON SEACH state
const onSearchProperty=(e)=>{
	props.getPropertyByJob(props.page, props.limit, localStorage.getItem('orgId'), state.search, state.status, id)
}

const handleChangePage = (event, page) => {
	props.getPropertyByJob(page, props.limit, localStorage.getItem('orgId'), state.search, state.status, id)
	}
	// OPEN DELETE
	const toggleModal =( data)=>{
		
		setModal(!modal)
		 setState({...state, name: data.ownerName, btnTitle:(t("property_list.delete")), search:"", id:data._id,
		 title:(t("property_list.delete_title")), isAdd:false, isDelete:true, isUpdate:false});

		 setCurrentStatus(false)
   
	   }

	/// handle submit 
	const handleSubmit = (e) => {
		e.preventDefault();
		setCurrentStatus(true)
		state.orgId = localStorage.getItem('orgId')
		props.addUpdateDeleteProperty(state)
	}


	  
			
	useEffect(() => {
		
		if (props.message&&currentStatus) {
			setModal(!modal)
			setCurrentStatus(false)
		
			
		} else {
		
		}
	});
	// apply pagination
	const setPage = () => {
	
		let total =Math.ceil(props.total / props.limit)
		return(
		<Pagination
		className={classes.root}
		onChange={handleChangePage}
		count={total}
		 shape="rounded"
		 color="primary"
		 variant="outlined"
			  style={{
			 marginTop: "2%",
			 float: "right",
			 }}
		  />
		  )
	
	  };
	    // ENABEL LAODING
  const  backDrop =props.loading? (
	<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={props.loading}>
		<CircularProgress color="inherit" />
	</Backdrop>
):null
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<span>{t("property_list.job_details")}</span>
			</div>
			<div className={styles.details}>
				<p style={{ marginRight: "8px" }}>{t("create_job.title")}: {title},</p>
				<p style={{ marginRight: "8px" }}>{t("create_job.description")}: {description},</p>
				<p style={{ marginRight: "8px" }}>{t("create_job.start_date")}: {moment(startDate).format('DD-MM-YYY')},</p>

				<p style={{ marginRight: "8px" }}>{t("create_job.end_date")}:{moment(endDate).format('DD-MM-YYY')},</p>
				<p style={{ marginRight: "8px" }}>{t("create_job.category1")}: {categoryId},</p>
				<p style={{ marginRight: "8px" }}>{t("create_job.subcategory1")}: {subcategoryId},</p>
				<p style={{ marginRight: "8px" }}>{t("create_job.assignee1")}: {name},</p>


			</div>
			<div className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div>
							<div className={styles.searchBar}>
								<TextField
									id="standard-search"
									size="small"
									name="search"
									value={state.search}
									onChange={handleChange}
									type="search"
									variant="outlined"
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
										marginBottom: "-35%",
									}}
									InputProps={{
										startAdornment: icon,
										placeholder: (t("property_list.search")),
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/>
							</div>
						</div>
						<div className={styles.dropDownDiv}>
							<FormControl
								variant="outlined"
								style={{
									width: "40%",
									maxHeight: "70%",
									alignContent: "center",
								}}
							>
								<InputLabel
									htmlFor="outlined-age-native-simple"
									style={{
										top: "-7px",
										marginBottom: "100%",
									}}
								>
									{t("property_list.status")}
								</InputLabel>
								<Select
									native
									value={state.status}
									name="status"
									onChange={handleChange}
									style={{
										width: "160%",
										maxHeight: "5%",
										minHeight: "5%",
									}}
									label="Status"
									inputProps={{
										name: "status",
										id: "outlined-age-native-simple",
									}}
								>
									<option aria-label="None" value="" />
									<option value={'MAPPED'}>MAPPED</option>
									<option value={'UNMAPPED'}>UNMAPPED</option>
									<option value={'ABORTED'}>ABORTED</option>
									<option value={'FAILED'}>FAILED</option>
								</Select>
							</FormControl>
						</div>
						<div className={styles.dropDownDiv}>
							<Button
								variant="contained"
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "20px",

									textTransform: "none",
									width: "60%",
									fontWeight: "lighter",
									// marginBottom: "3%",
									height: "70%",
								}}
								onClick={onSearchProperty}
							>
								{t("property_list.search_btn")}
							</Button>
						</div>
					</div>
				</div>
				<Modal isOpen={modal} toggle={toggleModal} centered={true}>
					<ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
					<ModalBody className={styles.modalContainer}>
						<p>{t("property_list.delete_msg")}  <strong>{state.name}</strong> ? </p>
						
					</ModalBody>
					<ModalFooter>
						<Button
							variant="contained"
							color="primary"
							onClick={toggleModal}
							style={{ marginRight: "2%" }}
						>
							{t("property_list.cancel")}
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={(e) => { handleSubmit(e) }}
						>
							{state.btnTitle}
						</Button>
					</ModalFooter>
				</Modal>
				<div className={styles.table}>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead style={{ backgroundColor: "#F5F6FA" }}>
								<TableRow>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("property_list.owner_name")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("property_list.contact")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("property_list.postal_code")}
										{/* </TableCell>
						<TableCell align="left" style={{ color: "#A3A6B4" }}>
						{t("property_list.samadhan_id")} */}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("property_list.gov_id")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("property_list.status1")}
									</TableCell>
									<TableCell></TableCell>
									{/* <TableCell></TableCell>
						<TableCell></TableCell> */}
								</TableRow>
							</TableHead>
							<TableBody>
								{props.propertyList.length > 0 && props.propertyList.map((property) => (
									<TableRow key={property._id}>
										<TableCell align="left">{property.properties_data.ownerName}</TableCell>
										<TableCell align="left">
											{property.phone ? (property.properties_data.phone) : "-"}
										</TableCell>
										<TableCell align="left">{property.properties_data.postalCode}</TableCell>
										{/* <TableCell align="left">{property.properties_data.samadhan_org_Id?(property.samadhan_org_Id.samadhanId):("-")}</TableCell> */}
										<TableCell align="left">{property.properties_data.govt_property_id}</TableCell>
										<TableCell align="left">
											<span
												style={{
													color:
														property.propertyStatus === "MAPPED"
															? "#099AEE"
															: property.propertyStatus === "UNMAPPED"
																? "#02AA2F"
																: property.propertyStatus === "FAILED"
																	? "#F2134F"
																	: property.propertyStatus === "ABORTED"
																		? "#790A28"
																		: "#02AA2F",
													textTransform: "uppercase"
												}}
											// style={{  }}
											>
												{property.properties_data.propertyStatus}
											</span>
										</TableCell>
							
									</TableRow>
								))}
							</TableBody>
						</Table>
						{props.propertyList.length === 0 && <p style={{ textAlign: 'center' }}>{t("property_list.no_record_found")}</p>}

					</TableContainer>

				</div>
			</div>
			<div>
				{props.propertyList.length > 0 && setPage()}
			</div>
		</div>
	);
}
const mapStateToProps =(state)=>{
	
	return{
	propertyList:state.Property.jobPropertyList,
	success:state.Property.success,
	loading:state.Property.loading,
	page:state.Property.page,
	limit:state.Property.limit,
	total:state.Property.total,
	message:state.Property.message,
	accessList: state.Organization.accessList,

	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
	 getPropertyByJob:(page, limit, orgId, search, status, jobId)=>
	 dispatch(acitons.getProperytByJobId(page, limit, orgId, search, status, jobId)),
	
	}
}

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(AllProperties)
