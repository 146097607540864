/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import GetAppIcon from "@material-ui/icons/GetApp";

import VisibilityIcon from "@material-ui/icons/Visibility";

import styles from "./Module.module.css";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit from "./edit.png";
import * as actions from "../../../../store/actions/index";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    backdrop: {
      zIndex: 1205,
      // color: '#fff',
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export function Module(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    modueleId: "",
    module: "",
    key: "",
    keyName: "",
    search: "",
    id: "",
    btnTitle: "Save",
    title: "Add Module",
    isAdd: false,
    isUpdate: false,
    isDelete: false,
    isActive: true,
  });
  const [errors, setErros] = useState({});

  const [t] = useTranslation("common");

  /// hass accessible
  const hasAccess = (type, name) => {
    if (props.accessList.module !== undefined) {
      if (type === "module") {
        let authObj = props.accessList.module.find(
          (x) => x.moduleId.keyName === name
        );
        if (authObj) {
          if (authObj.mod_enableStatus === "true") {
            return true;
          } else {
            return false;
          }
        }
      } else if (type === "submoduleId") {
        let authObj = props.accessList.module.find((x) =>
          x.submoduleId.find((d) => d.keyName === name)
        );
        if (authObj && authObj.mod_enableStatus === "true") {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  /// FETCH STATE LIST HERE
  useEffect(() => {
    props.getModuleList(1, props.limit, state.search, state.countryId);
    setTimeout(() => {
      props.getAllParentModule();
    }, 1000);
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "0") {
      const name = e.target.name;
      setState({
        ...state,
        [name]: e.target.value,
      });
      setErros({});
    }
  };
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);

  // const toggleModal = () => setModal(!modal);
  let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  // OPEN ADD COUNTRY MODAL
  const toggleModal = (e) => {
    setModal(!modal);
    setState({
      modueleId: "",
      module: "",
      btnTitle: t("module.save"),
      keyName: "",
      isActive: true,
      title: t("module.add_module"),
      isAdd: true,
      isDelete: false,
      isUpdate: false,
    });
    setErros({});
    setCurrentStatus(false);
  };

  // EDIT MODULE
  const editModule = (data) => {
    setState({
      moduleId: data.parentModuleId ? data.parentModuleId?._id : "",
      keyName: data.keyName,
      module: data.name,
      id: data._id,
      btnTitle: t("module.update"),
      key: data.keyName,
      title: t("module.update_module"),
      isAdd: false,
      isDelete: false,
      isUpdate: true,
    });
    setErros({});
    setModal(true);
  };

  // DELETE MODULE
  const deleteModule = (data) => {
    setState({
      module: data.name,
      id: data._id,
      btnTitle: t("module.delete"),
      title: t("module.delete_module"),
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });
    setErros({});
    setModal(true);
  };
  // VAILDATE FORM
  const validateForm = () => {
    var valid = true;

    if (state.module.trim() === "") {
      errors.module = t("module.module_error");
      errors.modueleId = "";
      errors.keyName = "";
      valid = false;
    } else {
      errors.modueleId = "";
      errors.module = "";
      errors.keyName = "";
      valid = true;
    }
    setErros((errors) => ({ ...errors }));
    return valid;
  };
  /// handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.isDelete && !validateForm()) {
      return;
    } else {
      setCurrentStatus(true);
      if (state.isAdd) {
        props.addModule(state);
      } else if (state.isUpdate) {
        props.updateModule(state);
      } else {
        props.deleteModule(state);
      }
    }
  };
  // ON SEARCH state
  const onSearchModule = (e) => {
    props.getModuleList(props.page, props.limit, state.search, state.modueleId);
  };

  // Download module
  const downloadExcel = () => {
    props.downlaodExcelFile("module", state.search);
  };

  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    }
  });

  const handleChangePage = (event, page) => {
    props.getModuleList(page, props.limit, state.search, state.modueleId);
  };

  // Apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  // ENABLE LOADING
  const backDrop = props.loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;

  return (
    <div>
      {backDrop}
      <div className={styles.main}>
        <div className={styles.title}>
          <span style={{ fontWeight: "lighter" }}>{t("module.master")} /</span>
          <span style={{ fontWeight: "lighter", color: "#43425D" }}>
            &nbsp;{t("module.module_list")}
          </span>
        </div>
        <div className={styles.tableDiv}>
          <div className={styles.searchBarDiv}>
            <div className={styles.searchAndDrop}>
              <div>
                <div className={styles.searchBar}>
                  <TextField
                    id="standard-search"
                    size="small"
                    name="search"
                    value={state.search}
                    type="search"
                    variant="outlined"
                    onChange={handleChange}
                    style={{
                      borderColor: "#F5F6FA",
                      borderRadius: "4px",
                    }}
                    InputProps={{
                      startAdornment: icon,
                      placeholder: t("module.search"),
                      classes: { input: classes.input },
                      color: "#4D4F5C",
                      focused: classes.focused,
                    }}
                  />
                </div>
              </div>
              <div className={styles.dropDownDiv}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#43425D",
                    color: "white",
                    borderRadius: "17px",
                    textTransform: "none",
                    width: "45%",
                    fontWeight: "lighter",
                    // marginBottom: "3%",
                    height: "90%",
                    outline: "none",
                  }}
                  onClick={(e) => {
                    onSearchModule(e);
                  }}
                >
                  {t("module.search_btn")}
                </Button>
              </div>
            </div>
            <div className={styles.buttonAndFilter}>
              <Button
                onClick={downloadExcel}
                variant="contained"
                color="secondary"
                style={{
                  textTransform: "none",
                  backgroundColor: "#3B86FF",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  outline: "none",
                  marginLeft: "46%",
                  fontSize: "smaller",
                }}
              >
                {t("job_reporting.download")}
                <GetAppIcon style={{ marginLeft: "20%" }} />
              </Button>
              {props.accessList.module !== undefined &&
                hasAccess("submoduleId", "module_add") && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={toggleModal}
                    style={{
                      textTransform: "none",
                      textAlign: "center",
                      width: "25%",
                      outline: "none",
                    }}
                  >
                    {t("module.add")}
                  </Button>
                )}
            </div>
          </div>
          <Modal isOpen={modal} toggle={toggleModal} centered={true}>
            <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
            <ModalBody className={styles.modalContainer}>
              {state.isDelete && (
                <p>
                  {t("module.delete_msg")} <strong>{state.module}</strong> ?{" "}
                </p>
              )}
              {!state.isDelete && (
                <form className={classes.root}>
                  <FormControl
                    style={{ marginLeft: "17px" }}
                    variant="outlined"
                  >
                    <InputLabel
                      htmlFor="outlined-age-native-simple"
                      style={{ alignText: "center" }}
                    >
                      {t("module.module")}
                    </InputLabel>
                    <Select
                      native
                      value={state.moduleId}
                      onChange={handleChange}
                      style={{
                        maxHeight: "80%",
                        marginBottom: "5%",
                        width: "80%",
                      }}
                      label="Filter"
                      inputProps={{
                        name: "moduleId",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value={"0"}>
                        -{t("module.select_parent")} -
                      </option>
                      {props.parentModuleList.length > 0 &&
                        props.parentModuleList.map((parent, i) => (
                          <option aria-label="None" value={parent._id}>
                            {parent.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.moduleId && (
                    <small className="error" style={{ color: "red" }}>
                      {errors.moduleId}
                    </small>
                  )}
                  <TextField
                    className={classes.root}
                    variant="outlined"
                    name="module"
                    label={t("module.module_name1")}
                    value={state.module}
                    onChange={handleChange}
                    style={{ width: "80%" }}
                  />
                  {errors.module && (
                    <small className="error" style={{ color: "red" }}>
                      {errors.module}
                    </small>
                  )}
                </form>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleModal}
                style={{ marginRight: "2%" }}
              >
                {t("module.cancel")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {state.btnTitle}
              </Button>
            </ModalFooter>
          </Modal>
          <div className={styles.table}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#F5F6FA" }}>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{ textAlign: "left", color: "#A3A6B4" }}
                    >
                      {t("module.module_name")}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="left"
                      style={{ textAlign: "left", color: "#A3A6B4" }}
                    >
                      {t("module.parent_module")}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="center"
                      style={{ textAlign: "center", color: "#A3A6B4" }}
                    >
                      {t("module.status")}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.moduleList.map((module) => (
                    <TableRow key={module.name}>
                      <TableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {module.name}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {module.parentModuleId
                          ? module.parentModuleId.name
                          : "-"}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center" style={{ color: "#3BB209" }}>
                        {module.status ? "Active" : "In-Active"}
                      </TableCell>
                      <TableCell align="justify" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess("submoduleId", "module_view") && (
                            <VisibilityIcon
                              style={{
                                color: "3b86ff",
                              }}
                            />
                          )}
                      </TableCell>
                      <TableCell align="justify" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess("submoduleId", "module_delete") && (
                            <DeleteForeverIcon
                              onClick={() => deleteModule(module)}
                              style={{
                                padding: "none",
                              }}
                            />
                          )}
                      </TableCell>
                      <TableCell
                        onClick={() => editModule(module)}
                        align="left"
                        padding="checkbox"
                      >
                        {props.accessList.module !== undefined &&
                          hasAccess("submoduleId", "module_update") && (
                            <img src={edit} alt="Edit" />
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {props.moduleList.length === 0 && (
                <p style={{ textAlign: "center" }}>
                  {" "}
                  {t("module.no_record_found")}{" "}
                </p>
              )}
            </TableContainer>
          </div>

          <div className={classes.root}></div>
        </div>
        {props.moduleList.length > 0 && setPage()}
      </div>
    </div>
  );
}

Module.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    loading: state.Module.loading,
    error: state.Module.module.error,
    message: state.Module.module.message,
    moduleList: state.Module.module.moduleList,
    parentModuleList: state.Module.module.parentModuleList,
    page: state.Module.module.page,
    limit: state.Module.module.limit,
    total: state.Module.module.total,
    accessList: state.Organization.accessList,
    //    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addModule: (data) => dispatch(actions.addModule(data)),
    updateModule: (data) => dispatch(actions.updateModule(data)),
    deleteModule: (data) => dispatch(actions.deleteModule(data)),
    getModuleList: (page, limit, search, parentId) =>
      dispatch(actions.getModuleList(page, limit, search, parentId)),
    getAllParentModule: () => dispatch(actions.getAllParentModule()),

    // onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Module);
