
import * as actionTypes from "../actions/actionTypes";
import {
    updateObject
} from "../../shared/utility";


const initialState = {
    usersList:[],
    orgUsersList:[],
    subcategoryList:[],
     page:1,
    limit:10, 
    total:'',
    message: null,
    // loading: true,
    error:null,
    success:false,
    authRedirectPath: "/dashboard",
    loading:false,
    docVerified:false

};

const authStart = (states, action) => {


    return updateObject(states, {
        // message:states,
        loading: true
    });
};


const inviteUsersSuccess = (state, action) => {
    
    let message ='success';
    let success =true;
    return updateObject(state, {
        message: message,
        error: null,
        // loading: false,
        success:success
    });
};


const inviateUsersFail = (state, action) => {
    let message = null
    return updateObject(state, {
        message: message,
        error: null,
        // success:success,
        loading: false,
    });
};





const getUsersSuccess = (state, action) => {
    
        return updateObject(state, {
        usersList:action.data.data.docs,
        page:action.data.data.page,
        limit:action.data.data.limit,
        total:action.data.data.total,
        success:false,
        loading: false,
        message:null
    });
};

const getOrgUsersSuccess = (state, action) => {
    
        return updateObject(state, {
        orgUsersList:action.data.data.docs,
        page:action.data.data.page,
        limit:action.data.data.limit,
        total:action.data.data.total,
        success:false,
        loading: false,
        message:null,
        docVerified:false

    });
};




const updateUserStatuSuccess = (state, action) => {
    
    return updateObject(state, {
      loading:false
});
};


// UPDATE USE STATU SUCCESS

// const getSubCategoryByCatSuccess = (state, action) => {
//     
//         return updateObject(state, {
//         subcategoryList:action.data.data,
       
//     });
// };
// Users reducer
export const Users = (state = initialState, action) => {
    
    switch (action.type) {
        // case actionTypes.AUTH_START:
        //     return authStart(state, action);

            case actionTypes.FETCH_USER_START:
            return authStart(state, action);

        case actionTypes.GET_USERS_SUCCESS:
            return getUsersSuccess(state, action);


            
        case actionTypes.GET_ORG_USERS_SUCCESS:
            return getOrgUsersSuccess(state, action);


        case actionTypes.INVITE_USERS_SUCCESS:
            return inviteUsersSuccess(state, action);

            case actionTypes.INVITE_USERS_FAIL:
                return inviateUsersFail(state, action);

        // case actionTypes.VERIFY_DOCUMENT_SUCCESS:
        //     return verifyDocSuccess(state, action);

        //     case actionTypes.VERIFY_DOCUMENT_FAIL:
        //         return verifyDocFail(state, action);

                case actionTypes.UPDATE_USER_SUCCESS:
                    return updateUserStatuSuccess(state, action);
                
                case actionTypes.AUTH_LOGOUT:
               
                    return initialState;
    
           
               default:
            return state;
    }
};

export default Users;