import React, { useEffect, useState } from "react";
import styles from "../Modules/Payments.module.css";
import * as mui from "../../../../../shared/mui";
import { apiUrl } from "../../../../../constants/constants";
import { toast } from "react-toastify";
import axios from "axios";

import { withTranslation, useTranslation } from "react-i18next";


const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "content-type": "application/json",
    Accept: "Application/json",
    Authorization: `${localStorage.getItem("token")}`,
  },
});

export function Payments() {

  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState("")
  const [email,setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [holder, setHolder] = useState("")
  const [account, setAccount] = useState("")
  const [ifsc, setIfsc] = useState("")
  const [aadhar, setAadhar] = useState("")
  const [pan, setPan] = useState("")
  const [gstin,setGstin] = useState("")
  const [canUpdate,setCanUpdate] = useState(false)
  useEffect(() => {
    const accountApi = `payment/organisation-account`
    setLoading(true)
    instance
      .request({
        method: "GET",
        url: accountApi,
        params: {
          organisationId: localStorage.getItem("orgId"),
        },
      })
      .then(function (response) {
        if (response.status === 200 && response.data.status) {
          const data = response.data.organisation

          toast.success(response.data.message)
          setName(data.name)
          setAadhar(data.aadharNo)
          setHolder(data.accountHolder)
          setAccount(data.bankAccount)
          setEmail(data.email)
          setGstin(data.gstin)
          setIfsc(data.ifsc)
          setPan(data.panNo)
          setPhone(data.phone)
          setCanUpdate(false)
          setLoading(false)
        }
        else {
          setLoading(false)
      
          setCanUpdate(true)
          toast.success("Set Up the Payment")
        }
      })
      .catch(function (error) {
        setLoading(false)
        toast.error(error)
      }); //axios-end
  }, []);


const handleChange = (event)=>{
  const name = event.target.name
  const value = event.target.value
  if(name === "name")
  setName(value)
  else if(name === "phone")
  setPhone(value)
  else if(name === "email")
  setEmail(value)
  else if(name === "account")
  setAccount(value)
  else if(name === "holder")
  setHolder(value)
  else if(name === "ifsc")
  setIfsc(value)
  else if(name === "aadhar")
  setAadhar(value)
  else if(name === "pan")
  setPan(value)
  else if(name === "gstin")
  setGstin(value)
}

const handleClick = (event)=>{
  const addAccount = `payment/add-organisation-account`

  const data = {
    organisationId: localStorage.getItem("orgId"),
    name,
    email,
    phone,
    bankAccount:account,
    accountHolder:holder,
    ifsc,
  }

  if(gstin!==null && gstin!=="" )
  data.gstin = gstin
  if(pan!==null && pan!=="")
  data.panNo = pan
  if(aadhar!==null && aadhar!=="")
  data.aadharNo = aadhar

  
    instance
    .request({
      method: "POST",
      url: addAccount,
      data
    })
    .then(function (response) {
      if (response.status === 200 && response.data.status) {
        toast.success(response.data.message)
      } 
      else{
        toast.error(response.data.message)
      }
    })
    .catch(function (error) {
      toast.error(error)
    }); //axios-end
  }
  
  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading}>
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;


  return (
    <div className="details-container" style={{ display: "flex", flexDirection: "column" }}>
      {backDrop}
    <div className = "details-boxes">
      <div className={styles.billingFormDiv}>
        <span
          style={{
            color: "#707070",
            marginLeft: "3%",
            marginTop: "3%",
            marginBottom: "5%",
            fontSize: "22px",
          }}
        >
         {t("payments.personal_details")}
        </span>

        <div className={styles.formRowDiv} style={{ marginBottom: "10%" }}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("payments.name")}
          </span>
          <mui.TextField
            variant="outlined"
            style={{ width: "90%", outline: "none" }}
            value={name}
            onChange={(event)=>handleChange(event)}
            name = "name"
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv} style={{ marginBottom: "10%" }}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("payments.email")}
          </span>
          <mui.TextField
            variant="outlined"
            style={{ width: "90%", outline: "none" }}
            value={email}
            onChange={(event)=>handleChange(event)}
            name = "email"
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv} style={{ marginBottom: "10%" }}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>{t("payments.phone")}</span>
          <mui.TextField
            variant="outlined"
            style={{ width: "90%", outline: "none" }}
            value={phone}
            onChange={(event)=>handleChange(event)}
            name = "phone"
          >
            
          </mui.TextField>
        </div>


      </div>

      <div className={styles.billingFormDiv}>
        <span
          style={{
            color: "#707070",
            marginLeft: "3%",
            marginTop: "3%",
            marginBottom: "5%",
            fontSize: "22px",
          }}
        >
         {t("payments.account_details")}
        </span>

        <div className={styles.formRowDiv} style={{ marginBottom: "10%" }}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("payments.account_holder")}
          </span>
          <mui.TextField
            variant="outlined"
            style={{ width: "90%", outline: "none" }}
            value={holder}
            disabled={!canUpdate}
            name="holder"
            onChange={(event)=>handleChange(event)}
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv} style={{ marginBottom: "10%" }}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("payments.account_no")}
          </span>
          <mui.TextField
            variant="outlined"
            style={{ width: "90%", outline: "none" }}
            value={account}
            disabled={!canUpdate}
            name = "account"
            onChange={(event)=>handleChange(event)}
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv} style={{ marginBottom: "10%" }}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>{t("payments.ifsc")}</span>
          <mui.TextField
            variant="outlined"
            style={{ width: "90%", outline: "none" }}
            value={ifsc}
            disabled={!canUpdate}
            name = "ifsc"
            onChange={(event)=>handleChange(event)}
          >
            IFSC
          </mui.TextField>
        </div>


      </div>

      <div className={styles.billingFormDiv}>
        <span
          style={{
            color: "#707070",
            marginLeft: "3%",
            marginTop: "3%",
            marginBottom: "5%",
            fontSize: "22px",
          }}
        >
          {t("payments.optional_details")}
        </span>

        <div className={styles.formRowDiv} style={{ marginBottom: "10%" }}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("payments.aadhar_no")}
          </span>
          <mui.TextField
            variant="outlined"
            style={{ width: "90%", outline: "none" }}
            value={aadhar}
            onChange={(event)=>handleChange(event)}
            name = "aadhar"
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv} style={{ marginBottom: "10%" }}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("payments.pan_no")}
            </span>
          <mui.TextField
            variant="outlined"
            style={{ width: "90%", outline: "none" }}
            value={pan}
            onChange={(event)=>handleChange(event)}
            name = "pan"
          ></mui.TextField>
        </div>

        <div className={styles.formRowDiv} style={{ marginBottom: "10%" }}>
          <span style={{ color: "#43425D", paddingBottom: "2%" }}>
            {t("payments.gstin")}
            </span>
          <mui.TextField
            variant="outlined"
            style={{ width: "90%", outline: "none" }}
            value={gstin}
            onChange={(event)=>handleChange(event)}
            name = "gstin"
          ></mui.TextField>
        </div>
      </div>
    </div>
      <div
          style={{
            display: "flex",
            padding: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <mui.Button
            variant="contained"
            style={{
              color: "white",
              borderRadius: "20px",
              textTransform: "none",
              backgroundColor: "#43425D",
              width: "20%",
              marginLeft: "2%",
            }}
            name={!canUpdate ? `Update`:'Submit'}
            onClick = {(event)=>handleClick(event)}
          >
             {!canUpdate ? `Update`:'Submit'}
          </mui.Button>
        </div>
        <style>
          {
            `
            .details-boxes{
              display:flex;
            }

            @media only screen and (max-width: 768px) {
              .details-boxes{
                flex-direction:column;
                
              }
            }

            .Payments_billingFormDiv__1KRsL {
              width:100%;
              margin-bottom:1rem;
            }

            `
          }
        </style>
    </div>
  );
}

export default withTranslation()(Payments);
