/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { Collapse } from "reactstrap";
import * as mui from "../../../shared/mui"
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { Row, Col } from "reactstrap";
import styles from "./Login.module.css";
import unnamed from "./images/unnamed-6@2x.png";
import path from "./images/Path-361.png";
import Logo2x from "./images/Logo@2x.png";
import Logo from "./images/Logo.png";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../../store/actions/index";
import "react-toastify/dist/ReactToastify.css";

const GbLng = () => (
  <span className={styles.topBarLngBtnTitle}>
    <span>English</span>
  </span>
);

const HiLng = () => (
  <span className={styles.topBarLngBtnTitle}>
    <span>हिंदी</span>
  </span>
);

const MrLng = () => (
  <span className={styles.topBarLngBtnTitle}>
    <span>मराठी</span>
  </span>
);
const useStyles = mui.makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "20em",
      display: "block",
      "@media (max-width:320px)": {
        width: "15em",
      },
      "@media (min-width:321px) and (max-width:410px)": {
        width: "18em",
      },
    },
  },
  label: {
    color: "red",
    "@media (max-width:320px)": {},
  },
}));

export function Login(props) {
  // const { t, i18n } = useTranslation();
  // or const [t, i18n] = useTranslation();
  const [t, i18n] = useTranslation("common");
  const history = useHistory();

  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [username_ErMsg, setusername_ErMsg] = useState("");
  const [password_ErMsg, setpassword_ErMsg] = useState("");
  const [displaytext, setdisplaytext] = useState("hideBlock");
  const [isLoading, setIsLoading] = useState(false);
  const [collapseLng, setLngCollapse] = useState(false);
  const [mainButtonContent, setLng] = useState(<GbLng />);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setusername_ErMsg("");
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setpassword_ErMsg("");
  };
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (event) => {
    if (!event.target.checked) {
      Cookies.remove("username");
      Cookies.remove("password");
      Cookies.remove("checked");
    }
    setChecked(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username.trim() === "") {
      setusername_ErMsg(t("login.username_error"));
      setdisplaytext("showBlock");
      return;
    } else if (password.trim() === "") {
      setpassword_ErMsg(t("login.password_error"));
      setdisplaytext("showBlock");
      return;
    }
    if (checked) {
      Cookies.remove("username");
      Cookies.remove("password");
      Cookies.remove("checked");
      Cookies.set("username", username, { expires: 7 });
      Cookies.set("password", password, { expires: 7 });
      Cookies.set("checked", true, { expires: 7 });
    }

    setIsLoading(true);
    props.onAuth(username, password);
  };

  if (props.isAuthenticated) {
    // setIsLoading(false);
    //	history.push('/password-reset-successful')
    // authRedirect = <Redirect to={props.authRedirectPath} />;
  } else {
    //const [isLoading, setisLoading] = useState(true);
  }

  const backDrop = props.loading ? (
    <mui.Backdrop className={classes.backdrop} open={props.loading}>
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;
  const toggleLng = () => {
    setLngCollapse(!collapseLng);
    scrollToBottom();
  };
  const changeLanguage = (lng, type) => {
    localStorage.removeItem("lng");
    localStorage.setItem("lng", lng);

    i18n.changeLanguage(lng);
    switch (lng) {
      case "en":
        setLng(<GbLng />);
        break;
      case "hi":
        setLng(<HiLng />);
        break;
      case "mr":
        setLng(<MrLng />);
        break;
      default:
        setLng(<GbLng />);
        break;
    }
    if (type) {
      toggleLng();
    }
  };

  // call  only one time
  useEffect(() => {
    if(localStorage.getItem("token"))
    history.replace("/dashboard")
    else{
    props.updateSignup();
    localStorage.removeItem("token");
    localStorage.removeItem("orgId");
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("userId");
    localStorage.removeItem("status");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    localStorage.removeItem("smdRole");
    localStorage.removeItem("firstname");
    localStorage.removeItem("lastname");
    localStorage.removeItem("image");
    localStorage.removeItem("orgName")
    }
    
  }, []);

  useEffect(() => {
    if (props.isAuthenticated) {
      if (localStorage.getItem("status") === "false") {
        history.push("/update");
      } else {
        if (localStorage.getItem("status")) {
          history.push("/dashboard");
        }
      }

      setIsLoading(false);
    } else {
      if (props.error) {
        setpassword_ErMsg(props.error);
        setdisplaytext("showBlock");
      }
    }
  });

  // get username and passowrod
  useEffect(() => {
    let checked = Cookies.get("checked");
    if (checked) {
      let email = Cookies.get("username");
      let password = Cookies.get("password");
      setUsername(email);
      setPassword(password);
      setChecked(true);
    }
    let lng = localStorage.getItem("lng");
    if (lng !== null && lng !== undefined) {
      changeLanguage(lng, false);
    }
  }, []);

  const scrollToBottom = () => {
    //messagesEnd.current.scrollIntoView({ behavior: 'smooth' })
  };

  return (
    <div>
      {/* {authRedirect} */}

      <Container fluid={true}>
        {backDrop}
        <Row>
          <Col xs="7" className={styles.left}>
            <div className={styles.left_main}>
              <picture className="logo-picture">
                <source srcSet={Logo} media="(max-width: 767px)" />
                <img className="logo-img" src={Logo2x} alt="logo2x" />
              </picture>
              <div className={styles.login_text}>
                <h1>{t("login.login_account")}</h1>
              </div>
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onSubmit={handleSubmit}
              >
                <mui.TextField
                  required
                  id="username"
                  label={
                    <span
                    >
                      {t("login.username")}
                    </span>
                  }
                  variant="outlined"
                  fullWidth={true}
                  value={username}
                  onChange={handleUsernameChange}
                />
                <small className={styles.textDanger + " " + displaytext}>
                  {username_ErMsg}
                </small>

                <mui.TextField
                  required
                  id="password"
                  label={
                    <span className={styles.usernamePassowrdLabel}>
                      {t("login.password")}
                    </span>
                  }
                  type={values.showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  variant="outlined"
                  fullWidth={true}
                  onChange={handlePasswordChange}
                  value={password}
                  InputProps={{
                    endAdornment: (
                      <mui.InputAdornment position="end">
                        <mui.IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <mui.Visibility />
                          ) : (
                            <mui.VisibilityOff />
                          )}
                        </mui.IconButton>
                      </mui.InputAdornment>
                    ),
                  }}
                />
                <small className={styles.textDanger + " " + displaytext}>
                  {password_ErMsg}
                </small>
                <div className={styles.forgotPwdDiv}>
                  <mui.FormControlLabel
                    className={classes.label}
                    control={
                      <mui.Checkbox
                        className={styles.checkBox}
                        checked={checked}
                        onChange={handleChange}
                        name="remember me"
                      />
                    }
                    label={
                      <span className={styles.checkBoxLabel}>
                        {t("login.remember_me")}
                      </span>
                    }
                  />
                  <div style={{ paddingBottom: "10px" }}>
                    <a href="#" className={styles.forgotPassword}>
                      <Link to="/forgot-password" style={{ color: "red" }}>
                        <span>{t("login.forgot_password")}</span>
                      </Link>
                    </a>
                  </div>
                </div>
                <mui.Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className={styles.button}
                  onClick={handleSubmit}
                >
                  {t("login.btn_sign")}
                </mui.Button>
              </form>
              <div style={{ margin: "20px" }}>
                <span className={styles.signUpText}>
                  {t("login.dont_have_ac")}
                </span>{" "}
                <a href="#" style={{ color: "red", fontWeight: "500" }}>
                  <Link to="/signup" style={{ color: "red" }}>
                    {t("login.sign_up")}
                  </Link>
                </a>
              </div>
              <mui.Typography variant="caption"> {t("login.language")}</mui.Typography>

              <div
                className={`${styles.topBarCollapse} ${styles.topBarCollapseLng}`}
              >
                <button
                  className={styles.tobBarButton}
                  type="button"
                  onClick={toggleLng}
                >
                  {mainButtonContent}
                  <svg
                    className="mdi-icon topbar__icon"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                  </svg>
                </button>
                {collapseLng && (
                  <button
                    className={styles.topBarBack}
                    type="button"
                    onClick={toggleLng}
                  />
                )}
                <Collapse
                  isOpen={collapseLng}
                  className={`${styles.tobBarContentCollapse}  ${styles.tobBarClsContentLgn}`}
                >
                  <button
                    className={styles.topBarLngBtn}
                    type="button"
                    onClick={() => changeLanguage("en", true)}
                  >
                    <GbLng />
                  </button>
                  <button
                    className={styles.topBarLngBtn}
                    type="button"
                    onClick={() => changeLanguage("hi", true)}
                  >
                    <HiLng />
                  </button>

                  <button
                    className={styles.topBarLngBtn}
                    type="button"
                    onClick={() => changeLanguage("mr", true)}
                  >
                    <MrLng />
                  </button>
                </Collapse>
              </div>
            </div>
          </Col>
          <Col xs="5" className={styles.parent}>
            <img src={unnamed} alt="" className={styles.image1} />
            <img src={path} alt="" className={styles.image2} />
            <div className={styles.links}>
            </div>
          </Col>
        </Row>
        {/* <div
            ref={messagesEnd} > </div> */}
      </Container>

      <style jsx global>{`
       
       @media only screen and (max-width: 768px) {
        /* For mobile phones: */
          .logo-img{
            width:300px;
          }
        }
      `}</style>
    </div>
  );
}
Login.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) => dispatch(actions.auth(username, password)),
    updateSignup: () => dispatch(actions.updateSingupFlag()),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
