/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as acitons  from '../../../../../store/actions/index'
import {compose} from 'redux'
import { withTranslation,useTranslation } from 'react-i18next';
import moment from 'moment';
import  { useParams } from "react-router-dom";

import {connect} from 'react-redux'
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,

} from "reactstrap";
import styles from "./JobDetailsInitQc.module.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "25ch",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
        },
	},
}));

export  function AllProperties(props) {
	const classes = useStyles();
	const { id, title,description, name, startDate,nfc, endDate} = useParams();
	const [state, setState] = React.useState({
		status: "",
		search: "",
		title:"",
		name:"",
		id:"",
		isDelete:true,isAdd:false, isUpdate:false,
	});
	const [modal, setModal] = useState(false);
	const [t] = useTranslation('common');

	useEffect(()=>{
		let orgId = localStorage.getItem('orgId')
	props.getJobDetails(1, props.limit, orgId, state.search, state.status, id )
	},[])
	const handleChange = (event) => {
		
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
	};

	
	let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

	// ON SEACH state
const onSearchProperty=(e)=>{
	props.getJobDetails(props.page, props.limit, localStorage.getItem('orgId'), state.search, state.status, id)
}

const handleChangePage = (event, page) => {
	props.getJobDetails(page, props.limit, localStorage.getItem('orgId'), state.search, state.status, id)
	}
	// OPEN DELETE
	const toggleModal =( data)=>{
		
		setModal(!modal)
		 setState({...state, name: data.ownerName, btnTitle:(t("property_list.delete")), search:"", id:data._id,
		 title:(t("property_list.delete_title")), isAdd:false, isDelete:true, isUpdate:false});
	   }

	/// handle submit 
	const handleSubmit = (e) => {
		e.preventDefault();
		
		state.orgId = localStorage.getItem('orgId')
		props.addUpdateDeleteProperty(state)
	}


	  // DOWNLAOD EXCEL FILE
	 
	const setPage = () => {
	
		let total =Math.ceil(props.total / props.limit)
		return(
		<Pagination
		className={classes.root}
		onChange={handleChangePage}
		count={total}
		 shape="rounded"
		 color="primary"
		 variant="outlined"
			  style={{
			 marginTop: "2%",
			 float: "right",
			 }}
		  />
		  )
	
	  };
	    // ENABEL LAODING
  const  backDrop =props.loading? (
	<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={props.loading}>
		<CircularProgress color="inherit" />
	</Backdrop>
):null
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<span>{t("property_list.job_details")}</span>
			</div>
			<div className={styles.details}>
				<p style={{ marginRight: "8px" }}>{t("create_job.title")}: {title},</p>
				<p style={{ marginRight: "8px" }}>{t("create_job.description")}: {description},</p>
				<p style={{ marginRight: "8px" }}>{t("create_job.start_date")}: {moment(startDate).format('DD-MM-YYY')},</p>

				<p style={{ marginRight: "8px" }}>{t("create_job.end_date")}:{moment(endDate).format('DD-MM-YYY')},</p>
				<p style={{ marginRight: "8px" }}>NFC: {nfc},</p>
				<p style={{ marginRight: "8px" }}>{t("create_job.assignee1")}: {name},</p>


			</div>
			<div className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div>
							<div className={styles.searchBar}>
								<TextField
									id="standard-search"
									size="small"
									name="search"
									value={state.search}
									onChange={handleChange}
									type="search"
									variant="outlined"
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
										marginBottom: "-35%",
									}}
									InputProps={{
										startAdornment: icon,
										placeholder: (t("property_list.search")),
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/>
							</div>
						</div>
						<div className={styles.dropDownDiv}>
							<Button
								variant="contained"
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "20px",

									textTransform: "none",
									width: "60%",
									fontWeight: "lighter",
									height: "100%",
								}}
								onClick={onSearchProperty}
							>
								{t("property_list.search_btn")}
							</Button>
						</div>
					</div>
				</div>
				<Modal isOpen={modal} toggle={toggleModal} centered={true}>
					<ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
					<ModalBody className={styles.modalContainer}>
						<p>{t("property_list.delete_msg")}  <strong>{state.name}</strong> ? </p>
						
					</ModalBody>
					<ModalFooter>
						<Button
							variant="contained"
							color="primary"
							onClick={toggleModal}
							style={{ marginRight: "2%" }}
						>
							{t("property_list.cancel")}
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={(e) => { handleSubmit(e) }}
						>
							{state.btnTitle}
						</Button>
					</ModalFooter>
				</Modal>
				<div className={styles.table}>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead style={{ backgroundColor: "#F5F6FA" }}>
								<TableRow>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("job_reporting.smd_org_id")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("job_reporting.smd_id")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("job_reporting.init_status")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("job_reporting.init_qc_status")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("job_reporting.insta_status")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("job_reporting.init_qc_status")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>{t("job_reporting.mapped_to_property")}</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>NFC TAG</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.initQcCardList.length > 0 && props.initQcCardList.map((job) => (
									<TableRow key={job._id}>
										<TableCell align="left">{job.cards.samadhan_org_id}</TableCell>
										<TableCell align="left">
											{job.cards.samadhanId}
										</TableCell>
										<TableCell align="left">{job.cards.initializationStatus ? "Active" : "In-Active"}</TableCell>
										<TableCell align="left">{job.cards.initialization_qc_status ? "Active" : "In-Active"}</TableCell>
										<TableCell align="left">{job.cards.installationstatus ? "Active" : "In-Active"}</TableCell>
										<TableCell align="left">{job.cards.installation_qc_status ? "Active" : "In-Active"}</TableCell>

										<TableCell align="left">{job.cards.mapped_to_property ? "Active" : "In-Active"}</TableCell>
										<TableCell align="left">{job.cards.nfc_tag === '' ? "-" : (job.cards.nfc_tag)}</TableCell>

										<TableCell align="left" padding="checkbox">
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{props.initQcCardList.length === 0 && <p style={{ textAlign: 'center' }}>{t("property_list.no_record_found")}</p>}

					</TableContainer>

				</div>
			</div>
			<div>
				{props.initQcCardList.length > 0 && setPage()}
			</div>
		</div>
	);
}
const mapStateToProps =(state)=>{
	
	return{
		initQcCardList:state.Operation.initQcCardList,
	success:state.Operation.success,
	loading:state.Operation.loading,
	page:state.Operation.page,
	limit:state.Operation.limit,
	total:state.Operation.total,
	message:state.Operation.message,
	accessList: state.Organization.accessList,

	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
		getJobDetails:(page, limit, orgId, search, status, jobId)=>
	 dispatch(acitons.getJobDetails(page, limit, orgId, search, status, jobId)),
	
	}
}

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(AllProperties)