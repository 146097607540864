/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import GetAppIcon from "@material-ui/icons/GetApp";

import { hasAccess } from "../../../../shared/HasAccess";

import styles from "./Blood.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit from "./edit.png";
import * as actions from "../../../../store/actions/index";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export function Blood(props) {
  const [t] = useTranslation("common");

  const classes = useStyles();
  const [state, setState] = React.useState({
    name: "",

    search: "",
    id: "",
    btnTitle: "Save",
    title: "Add",
    isAdd: false,
    isUpdate: false,
    isDelete: false,
  });
  const [errors, setErros] = useState({});

  /// FETCH COUNTRY LIST HERE
  useEffect(() => {
    props.getBloodGrouList(1, props.limit, state.search);
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setErros({});
  };
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);

  // const toggleModal = () => setModal(!modal);
  let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  // OPEN ADD blood MODAL
  const toggleModal = (e) => {
    setModal(!modal);
    setState({
      ...state,
      country: "",
      code: "",
      btnTitle: t("blood_group.save"),
      title: t("blood_group.add_blood_grp"),
      isAdd: true,
      isDelete: false,
      isUpdate: false,
    });
    setErros({});
    setCurrentStatus(false);
  };

  // EDIT BLOOD
  const editBlood = (data) => {
    setState({
      name: data.bloodGroup,
      id: data._id,
      btnTitle: t("blood_group.update"),
      title: t("blood_group.update_blood"),
      isAdd: false,
      isDelete: false,
      isUpdate: true,
    });
    setErros({});
    setModal(true);
  };

  // DELETE blood group
  const deleteBloodGroup = (data) => {
    setState({
      name: data.bloodGroup,
      id: data._id,
      btnTitle: t("blood_group.delete"),
      title: t("blood_group.delete_blood"),
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });
    setErros({});
    setModal(true);
  };
  // VAIDAATE FORM
  const validateForm = () => {
    var valid = true;
    if (state.name.trim() === "") {
      errors.name = t("blood_group.blood_grp_error");
      valid = false;
    } else {
      errors.name = "";
      valid = true;
    }
    setErros((errors) => ({ ...errors }));
    return valid;
  };
  /// handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.isDelete && !validateForm()) {
      return;
    } else {
      setCurrentStatus(true);
      props.manageBloodGroup(state);
    }
  };
  // ON SEACH blood group
  const onSearchBloodGroup = (e) => {
    props.getBloodGrouList(props.page, props.limit, state.search);
  };

  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    } else {
      // if(props.error){
      // 	setpassword_ErMsg(props.error)
      // 	setdisplaytext('showBlock')
      // }
    }
  });

  const handleChangePage = (event, page) => {
    props.getBloodGrouList(page, props.limit, state.search);
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  // dwonload module
  const downloadExcel = () => {
    props.downlaodExcelFile("blood", state.search);
  };

  // ENABEL LAODING
  const backDrop = props.loading ? (
    <Backdrop style={{ zIndex: 1204 }} className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <span style={{ fontWeight: "lighter" }}>{t("module.master")} /</span>
        <span style={{ fontWeight: "lighter", color: "#43425D" }}>
          &nbsp;{t("blood_group.title")}
        </span>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <TextField
                  id="standard-search"
                  size="small"
                  name="search"
                  value={state.search}
                  type="search"
                  variant="outlined"
                  onChange={handleChange}
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    // marginBottom: "5%",
                  }}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: t("blood_group.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
            </div>
            <div className={styles.dropDownDiv}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  borderRadius: "17px",
                  textTransform: "none",
                  width: "45%",
                  fontWeight: "lighter",
                  // marginBottom: "3%",
                  height: "90%",
                  outline: "none",
                }}
                onClick={(e) => {
                  onSearchBloodGroup(e);
                }}
              >
                {t("blood_group.search")}
              </Button>
            </div>
          </div>
          <div className={styles.buttonAndFilter}>
            {/* <FormControl variant="outlined">
							<InputLabel
								htmlFor="outlined-age-native-simple"
								style={{ alignText: "center" }}
							>
								Filter
							</InputLabel>
							<Select
								native
								value={state.age}
								onChange={handleChange}
								style={{
									width: "120%",
									maxHeight: "80%",
									marginBottom: "5%",
								}}
								label="Filter"
								inputProps={{
									name: "Filter",
									id: "outlined-age-native-simple",
								}}
							>
								<option aria-label="None" value="" />
								<option value={10}>Ten</option>
								<option value={20}>Twenty</option>
								<option value={30}>Thirty</option>
							</Select>
						</FormControl> */}
            <Button
              onClick={downloadExcel}
              variant="contained"
              color="secondary"
              style={{
                textTransform: "none",
                backgroundColor: "#3B86FF",
                textAlign: "center",
                whiteSpace: "nowrap",
                outline: "none",
                marginLeft: "49%",
                fontSize: "smaller",
              }}
            >
              {t("job_reporting.download")}
              <GetAppIcon style={{ marginLeft: "20%" }} />
            </Button>
            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "bloodgroup_add",
                props.accessList.module
              ) && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={toggleModal}
                  style={{
                    textTransform: "none",
                    textAlign: "center",
                    width: "25%",
                    // height: "95%",
                    // marginBottom: "3.2%",
                    outline: "none",
                    // marginLeft: "50%",
                  }}
                >
                  {t("blood_group.add")}
                </Button>
              )}
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            {state.isDelete && (
              <p>
                {t("blood_group.delete_msg")} <strong>{state.name}</strong> ?{" "}
              </p>
            )}
            {!state.isDelete && (
              <form className={classes.root}>
                <TextField
                  error={errors.name}
                  className={classes.root}
                  variant="outlined"
                  label={t("blood_group.group_name1")}
                  name="name"
                  helperText={errors.name}
                  style={{ width: "80%" }}
                  value={state.name}
                  onChange={handleChange}
                />
                {/* {errors.name && (
										<small
											className="error"
											style={{ color: "red" }}
										>
											{errors.name}
										</small>
									)} */}
              </form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              style={{ marginRight: "2%" }}
            >
              {t("blood_group.cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </Button>
          </ModalFooter>
        </Modal>
        <div className={styles.table}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("blood_group.group_name")}
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("blood_group.status")}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>

                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.bloodGroupList.length > 0 &&
                  props.bloodGroupList.map((blood) => (
                    <TableRow key={blood._id}>
                      <TableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {blood.bloodGroup}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {blood.status ? "Active" : "In-Active"}
                      </TableCell>

                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>

                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="justify" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "bloodgroup_delete",
                            props.accessList.module
                          ) && (
                            <DeleteForeverIcon
                              onClick={() => {
                                deleteBloodGroup(blood);
                              }}
                              style={{
                                padding: "none",
                                cursor: "pointer",
                                color: "#43425D",
                              }}
                            />
                          )}
                      </TableCell>
                      <TableCell align="left" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "bloodgroup_update",
                            props.accessList.module
                          ) && (
                            <img
                              src={edit}
                              alt="Edit"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => editBlood(blood)}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {props.bloodGroupList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {t("blood_group.no_record_found")}
              </p>
            )}
          </TableContainer>
        </div>

        <div className={classes.root}></div>
      </div>
      {props.bloodGroupList.length > 0 && setPage()}
    </div>
  );
}

Blood.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    loading: state.Blood.loading,
    error: state.Blood.blood.error,
    message: state.Blood.blood.message,
    bloodGroupList: state.Blood.blood.bloodGroupList,
    page: state.Blood.blood.page,
    limit: state.Blood.blood.limit,
    total: state.Blood.blood.total,
    accessList: state.Organization.accessList,

    //    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    manageBloodGroup: (data) => dispatch(actions.addUpdateDeleteBlood(data)),
    getBloodGrouList: (page, limit, search) =>
      dispatch(actions.getBloodGroupList(page, limit, search)),
    downlaodExcelFile: (type, search) =>
      dispatch(actions.downloadExcelMaster(type, search)),
    // onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Blood);
