import { connect } from "react-redux";
import { compose } from "redux";

export function hasAccess(type, name, list) {
  console.log(list,"list")
  if (list.length > 0) {
    if (type === "module") {
      let authObj = list.find((x) => x.moduleId.keyName === name);
      if (authObj) {
        if (authObj.mod_enableStatus === "true") {
          return true;
        } else {
          return false;
        }
      }
    } else if (type === "submoduleId") {
      let authObj = list.find((x) =>
        x.submoduleId.find((d) => d.keyName === name)
      );
      if (authObj && authObj.mod_enableStatus === "true") {
        return true;
      } else {
        return false;
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(hasAccess);
