import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styles from "./JobCreation.module.css";
import JobCreationCard from "../../../../components/JobCreationCard/JobCreationCard";
import first from "./investor.png";
import second from "./maintenance.png";
import third from "./qr-code.png";
import fourth from "./audit.png";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import * as mui from "../../../../shared/mui";

import { hasAccess } from "../../../../shared/HasAccess";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';


export function OperationTab() {
  //Tabs
  const [tabIndex, setTabIndex] = React.useState(0);
  const tabsStyles = mui.plainTabsStylesHook.useTabs();
  const tabItemStyles = mui.plainTabsStylesHook.useTabItem();
  const [condition, setCondition] = useState(false);
  const [t] = useTranslation("common");

  const style1 = {
    color: "#3B86FF",
    outline: "none",
    // width: "50px",
    borderRadius: "5px",
    background: "#f7f7f7",
  };
  const style2 = {
    color: "#BBBBBB",
    outline: "none",
    // width: "50px",
    borderRadius: "5px",
    backgroundColor: "white",
    borderRight: "none",
    borderTop: "none",
    borderBottom: "none",
  };

  return (
    <div
      style={{
        // marginLeft: "21%",
        marginBottom: "0px;",
        marginTop: "1.1%",
        display: "flex",
        flex: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <mui.Tabs
        classes={tabsStyles}
        value={tabIndex}
        onChange={(e, index) => setTabIndex(index)}
      >
        <Link to="/operations/jobcreation" style={{ textDecoration: "none" }}>
          <mui.Tab
            label={t("sidebar.job_creation")}
            onClick={() => setCondition(!condition)}
            disableRipple={true}
            style={condition ? style2 : style1}
          />
        </Link>

        <Link
          to="/operations/jobinitialisation"
          style={{ textDecoration: "none" }}
        >
          <mui.Tab
            classes={tabItemStyles}
            label={t("sidebar.job_reporting")}
            onClick={() => setCondition(!condition)}
            disableRipple={true}
            style={condition ? style1 : style2}
          />
        </Link>
      </mui.Tabs>
    </div>
  );
}


export function JobCreation(props) {
  const [t] = useTranslation("common");
  const url = useRouteMatch();
  // const path = url.path.split("/")[1];
  const [loading, setLoading] = useState(true);


  useEffect(() => {
  if (loading) {
    setTimeout(() => {
    setLoading(false);
  }, 2000);
  }
}, [loading]);
  

  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading} >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;



  return (
    <div className={styles.main}>
      {backDrop}
      {url.path.split("/")[1] === "operations" && <OperationTab />}
      <div className={styles.title}>
        <Breadcrumbs seperator="›" aria-label="breadcrumb">
          <Typography color="textPrimary">{t("job_creation.operation")}</Typography>
    <Typography color="textSecondary">{t("job_creation.job_creation")}</Typography>
</Breadcrumbs>
      </div>
      <div className={styles.grid}>
        <Link
          style={{ textDecoration: "none", margin: "auto" }}
          to="/operations/initialization"
        >
          <JobCreationCard
            img={first}
            color={"#58D8FE"}
            details={t("job_creation.initialisation")}
          />
        </Link>
        <Link
          style={{ textDecoration: "none", margin: "auto" }}
          to="/operations/installation"
        >
          {" "}
          <JobCreationCard
            img={second}
            color={"#6BE4A7"}
            details={t("job_creation.installation")}
          />
        </Link>
        <Link
          style={{ textDecoration: "none", margin: "auto" }}
          to="/operations/qc"
        >
          <JobCreationCard
            img={third}
            color={"#E36398"}
            details={t("job_creation.qc")}
          />
        </Link>
        {props.accessList.module !== undefined &&
          hasAccess(
            "submoduleId",
            "job_auditlist",
            props.accessList.module
          ) && (
            <Link
              style={{ textDecoration: "none", margin: "auto" }}
              to="/operations/audit"
            >
              <JobCreationCard
                img={fourth}
                color={"#A5A2FB"}
                details={t("job_creation.aduit")}
              />
            </Link>
          )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(JobCreation);
