/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import styles from "./InitialisationTable.module.css";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import download from "./upload1.png";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as acitons from '../../../../../../store/actions/index'
import { useTranslation } from 'react-i18next';
import MenuItem from "@material-ui/core/MenuItem";

import { compose } from 'redux'
import { connect } from 'react-redux'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue, grey } from "@material-ui/core/colors";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "30ch",
		},
		"& .MuiPagination-root": {
			margin: theme.spacing(3),
			color: "standard",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
        },
		root1: {
			flexGrow: 1,
			maxHeight: "60%",
		},
		
		table: {
			minWidth: 750,
			borderRadius: 0,
			overflowY: "auto",
			overflowX: "auto",
			maxHeight: 10,
			overflow: "scroll",
		},
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
	select: {
		minWidth: "8.5vw",
		"@media (min-width: 320px) and (max-width: 375px)": {
			minWidth: "25vw",
		},
		"@media (min-width: 376px) and (max-width: 425px)": {
			minWidth: "25vw",
		},
		background: "white",
		color: grey[700],
		borderColor: "#D7DAE2",
		borderStyle: "solid",
		borderWidth: "2px",
		borderRadius: "4px",
		paddingLeft: "5px",
		paddingTop: "2px",
		paddingBottom: "2px",
		fontSize: "13px",
		"&:hover": {
			borderColor: grey[400],
		},
		"&:focus": {
			borderRadius: "4px",
			background: "white",
			borderColor: blue[200],
		},
	},
	icon: {
		color: grey[500],
		right: 12,
		position: "absolute",
		userSelect: "none",
		pointerEvents: "none",
	},
	list: {
		paddingTop: 0,
		paddingBottom: 0,
		background: "white",
		color: "#4d4f5c",
		fontSize: "smaller",
		"& li.Mui-selected": {
			fontWeight: 400,
		},
	},
	input: {
		color: "#4D4F5C",
		fontSize: "smaller",
	},
}));


export  function InitTable(props) {
	const [t] = useTranslation('common');

	const borderSelectClasses = useBorderSelectStyles();
	const menuProps = {
		classes: {
			list: borderSelectClasses.list,
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left",
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left",
		},
		getContentAnchorEl: null,
	};


	const iconComponent = (props) => {
		return (
			<ExpandMoreIcon
				className={props.className + " " + borderSelectClasses.icon}
			/>
		);
	};

	const classes = useStyles();
	const [state, setState] = React.useState({
		startDate: new Date().toISOString().slice(0, 10),
		endDate: new Date().toISOString().slice(0, 10),
		endDate1:new Date().toISOString().split('T')[0],
		search:"",
		day:"today",
	});
// handle change
const handleChange = (e) => {
	
	if(e.target.value==='last_week'){
	let lastDate = addDays(new Date(), -7).toISOString().slice(0, 10);
	let newDate = new Date().toISOString().slice(0, 10);

	setState({ ...state, [e.target.name]: e.target.value,startDate:lastDate, endDate:newDate })
	props.getInitTableList(1, props.limit, state.search, lastDate, newDate)
	}
	else if(e.target.value==='last_month'){
		
		let lastDate = addDays(new Date(), -31).toISOString().slice(0, 10);
		let newDate = new Date().toISOString().slice(0, 10);
	setState({ ...state, [e.target.name]: e.target.value,startDate:lastDate, endDate:newDate })
	props.getInitTableList(1, props.limit, state.search, lastDate, newDate)

	}
	else if(e.target.value==='today'){
		
		let newDate = new Date().toISOString().slice(0, 10);
	setState({ ...state, [e.target.name]: e.target.value,startDate:newDate, endDate:newDate })
	props.getInitTableList(1, props.limit,  state.search,newDate, newDate)

	}
	else if(e.target.name==='endDate'){
		// let newDate = new Date().toISOString().slice(0, 10);
	setState({ ...state, [e.target.name]: e.target.value, endDate:e.target.value })
	props.getInitTableList(1, props.limit, state.search, state.startDate,  e.target.value)

	}
	else if(e.target.name==='startDate'){
		// let newDate = new Date().toISOString().slice(0, 10);
	setState({ ...state, [e.target.name]: e.target.value,})
	props.getInitTableList(1, props.limit, state.search, e.target.value, state.endDate)

	}
	else{
		setState({ ...state, [e.target.name]: e.target.value })

	}


}
// add and minus date
const addDays=(theDate, days)=> {
	return new Date(theDate.getTime() + days*24*60*60*1000);
}


// ON SEARCH
const onSearch =()=>{
	props.getInitTableList(1, 10, state.search, state.startDate, state.endDate)

}

	
	const [modal, setModal] = useState(false);
	const toggleModal = () => setModal(!modal);
	let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;


// get init table data
	 useEffect(()=>{
		var newDate = new Date().toISOString().slice(0, 10);
		 props.getInitTableList(1, 10, state.search, newDate, newDate)
	 },[])

	const handleChangePage = (event, page) => {

		props.getInitTableList(page, props.limit, state.search, state.startDate, state.endDate)
	}
	// apply pagination
	const setPage = () => {

		let total = Math.ceil(props.total / props.limit)
		return (
			<Pagination
				className={classes.root}
				onChange={handleChangePage}
				count={total}
				shape="rounded"
				color="primary"
				variant="outlined"
				style={{
					marginTop: "2%",
					float: "right",
				}}
			/>
		)

	};

	 	// ENABEL LAODING
		 const backDrop = props.loading ? (
			<Backdrop style={{ zIndex: 1204 }} className={classes.backdrop} open={props.loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		) : null
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<span style={{ fontWeight: "lighter" }}>
					Operations/ Job Reporting /
				</span>
				<span style={{ fontWeight: "lighter", color: "#BBBBBB" }}>
					&nbsp;Initialisation
				</span>
			</div>
			<div className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div>
							<div className={styles.searchBar}>
								<TextField
									id="standard-search"
									size="small"
									type="search"
									variant="outlined"
									name="search"
									value={state.value}
									onChange={handleChange}				
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
										marginRight: "2%",
									}}
									InputProps={{
										startAdornment: icon,
										placeholder: "Search..",
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/>
								{/* <Dropdown
									holder="Filter"
									style={{ marginLeft: "2%" }}
								/> */}
							</div>
						</div>
						<div className={styles.dropDownDiv}>
							<Button
								variant="contained"
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "20px",

									textTransform: "none",
									width: "110px",
									fontWeight: "lighter",
									marginLeft: "5%",
									marginRight: "5%",
								}}
								onClick={onSearch}
							>
								Search
							</Button>
							<FormControl>
								<Select
									disableUnderline
									labelId="inputLabel"
									placeholder={t('job_audit.today')}
									name="day"
									IconComponent={iconComponent}
									className={classes.select}
									MenuProps={menuProps}
									value={state.day}
									onChange={handleChange}
									style={{
										marginRight: "2%",
									}}
								>
									<MenuItem value={'today'}> {t('job_audit.today')}</MenuItem>{" "}
									<MenuItem value={'last_week'}> {t('job_audit.last_week')}</MenuItem>{" "}
									<MenuItem value={"last_month"}> {t('job_audit.last_month')} </MenuItem>{" "}
								</Select>
							</FormControl>
						</div>
					</div>
					<div className={styles.buttonAndFilter}>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-evenly",
							}}
						>
							<span
								style={{
									textAlign: "center",
									alignSelf: "center",
									fontSize: "medium",
									color: "#43425D",
								}}
							>
								From Date
							</span>
							<TextField
								id="standard-search"
								size="small"
								name="startDate"
								value={state.startDate}
								onChange={handleChange}
								type="date"
								variant="outlined"
								style={{
									borderColor: "#F5F6FA",
									borderRadius: "4px",
									marginLeft: "2%",
									width: "33%",
								}}
								InputProps={{
									classes: { input: classes.input },
									color: "#4D4F5C",
									focused: classes.focused,
								}}
							/>
							<span
								style={{
									textAlign: "center",
									fontSize: "medium",
									alignSelf: "center",
									color: "#43425D",
								}}
							>
								To Date
							</span>
							<TextField
								id="standard-search"
								size="small"
								type="date"
								name="endDate"
								value={state.endDate}
								onChange={handleChange}
								variant="outlined"
								style={{
									borderColor: "#F5F6FA",
									borderRadius: "4px",
									marginLeft: "2%",
									width: "33%",
								}}
								InputProps={{
									classes: { input: classes.input },
									color: "#4D4F5C",
									focused: classes.focused,
								}}
							/>
						</div>
						<Button
							variant="contained"
							onClick={toggleModal}
							style={{
								textTransform: "none",
								textAlign: "center",
								backgroundColor: "#3B86FF",
								color: "white",
							}}
						>
							Download
							<img
								src={download}
								style={{ transform: "rotate(180deg)" }}
								alt ="download"
							/>
						</Button>
					</div>
				</div>

				<div className={styles.table}>
					<div style={{ overflow: "auto" }}>
						<Table aria-label="simple table">
							<TableHead
								style={{
									backgroundColor: "#F5F6FA",
								}}
							>
								<TableRow>
									<TableCell
										align="left"
										style={{
											textAlign: "justify",
											color: "#A3A6B4",
										}}
									>
										S.NO
									</TableCell>
									<TableCell
										style={{
											textAlign: "center",
											color: "#A3A6B4",
										}}
									>
										NAME OF THE JOB
									</TableCell>
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
										}}
									>
										NUMBER OF CARDS INITIALISED
									</TableCell>
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
										}}
									>
										NUMBER OF CARDS QUARANTINED
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{props.initTableList.length>0&&props.initTableList.map((result, i) => (
									<TableRow key={result._id}>
										<TableCell
											align="center"
											component="th"
											scope="row"
											style={{
												color: "#4D4F5C",
												textAlign:"center",
												fontFamily:
													"Regular 13px/20px Source Sans Pro",
											}}
										>
											{i+1}
										</TableCell>
										<TableCell
											align="left"
											component="th"
											scope="row"
											style={{
												color: "#4D4F5C",
												textAlign:"center",
												fontFamily:
													"Regular 13px/20px Source Sans Pro",
											}}
										>
										{result.name_of_job}
										</TableCell>
										<TableCell
											align="center"
											component="th"
											scope="row"
											style={{
												color: "#4D4F5C",
												textAlign:"center",
												fontFamily:
													"Regular 13px/20px Source Sans Pro",
											}}
										>
											{result.card_initialised}
										</TableCell>
										<TableCell
											align="center"
											component="th"
											scope="row"
											style={{
												color: "#4D4F5C",
												textAlign:"center",
												fontFamily:
													"Regular 13px/20px Source Sans Pro",
											}}
										>
											{result.card_initialised}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{props.initTableList.length===0&&<p style={{textAlign:'center'}}>	{t("job_reporting.no_record_found")}</p>}

					</div>
				</div>

				<div className={classes.root}></div>
			</div>
			<div className={styles.paginationDiv}>
			{props.initTableList.length>0&&setPage()}

			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	
	return {
		
		initTableList: state.Operation.initTableList,
		loading: state.Operation.loading,
		page: state.Operation.page,
		limit: state.Operation.limit,
		total: state.Operation.total,


	}
}

const mapDispatchToProps = (dispatch) => {
	
	return {
	
		getInitTableList: (page, limit, search, startDate, endDate) =>
			dispatch(acitons.getInitTableList(page, limit, search, startDate,  endDate)),
		downloadExcelFile: (orgId, jobType, search) =>
			dispatch(acitons.downloadExcel(orgId, jobType, search)),
		// getSubCategoryByCategory: (id) =>
		// 	dispatch(acitons.getSubCategoryByCategory(id)),
		// getOrgUser: (id) =>
		// 	dispatch(acitons.getOrgUser(id)),
		// addJob: (data) =>
		// 	dispatch(acitons.addJob(data))
	}
}


export default compose( connect(mapStateToProps, mapDispatchToProps))(InitTable)