import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as mui from "../../../../../shared/mui";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./UWH.module.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import back from "./back.png";
import Typography from "@material-ui/core/Typography";
import { blue, grey } from "@material-ui/core/colors";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";
import { Breadcrumbs, TextField } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: "18%",
  },
  title: {
    fontSize: 14,
  },
  select: {
    minWidth: "11.5vw",
    "@media (min-width: 320px) and (max-width: 375px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 376px) and (max-width: 425px)": {
      minWidth: "25vw",
    },
    background: "white",
    color: grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "13px",
    "&:hover": {
      borderColor: grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: blue[200],
    },
  },
  icon: {
    color: grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
});

const Dropdown = (props1) => {
  const [val, setVal] = React.useState(0);

  const handleChange = (event) => {
    setVal(event.target.value);
  };

  const borderSelectClasses = useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const classes = useStyles();

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };

  return (
    <FormControl>
      <Select
        disableUnderline
        labelId="inputLabel"
        placeholder={props1.holder}
        IconComponent={iconComponent}
        className={classes.select}
        MenuProps={menuProps}
        value={val}
        onChange={handleChange}
        style={{
          marginRight: "2%",
        }}
      >
        <MenuItem value={0}> {props1.holder} </MenuItem>{" "}
        <MenuItem value={1}> One </MenuItem>{" "}
        <MenuItem value={2}> Two </MenuItem>{" "}
        <MenuItem value={3}> Three </MenuItem>{" "}
      </Select>
    </FormControl>
  );
};

export default function Initialisation() {
  const [t] = useTranslation('common');
  const [ loading, setLoading] = useState(true)
  const history = useHistory();

  const borderSelectClasses = useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };

  const classes = useStyles();
  const [state, setState] = React.useState({
    value: "user_work_hisotry",
    name: "hai",
  });
  // handle change job reporting type
  const handleChange = (event) => {
    history.push(`${event.target.value}`);
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  useEffect(() => {
  if (loading) {
    setTimeout(() => {
    setLoading(false);
  }, 2000);
  }
}, [loading]);
  

  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading} >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.header}>
        <div className={styles.title}>
          <Breadcrumbs seperator="›" aria-label="breadcrumb">
            <Typography color="textPrimary">{t("init_end_day.operation")}</Typography>
            <Typography color="textSecondary">{t("init_end_day.job_reporting")}</Typography>
          </Breadcrumbs>
        </div>
        <div className={styles.dropdownMain}>
          <FormControl>
            <Select
              disableUnderline
              labelId="inputLabel"
              placeholder={"User Work Hisotory"}
              IconComponent={iconComponent}
              className={classes.select}
              MenuProps={menuProps}
              value={state.value}
              onChange={handleChange}
              style={{
                marginRight: "2%",
              }}
            >
              <MenuItem value={"user_work_hisotry"}>
                {"User work History"}
              </MenuItem>
              <MenuItem value={"/operations/jobinitialisation"}>
                Initialisation
              </MenuItem>
              <MenuItem value={"/operations/jobinstallation"}>
                Installation
              </MenuItem>
              <MenuItem value={"/operations/jobaudit"}>
                Audit
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={styles.header2}>
        <span className={styles.subTitle}>{t("init_end_day.user_work_history")}</span>
        <div className={styles.dropdown2}>
          <Dropdown holder="Today" />
        </div>
        <div className={styles.date}>
          <span className={styles.label}>{t("init_end_day.from_date")}</span>
          <TextField
            id="standard-search"
            size="small"
            type="date"
            variant="outlined"
            name='startDate'
            value={state.startDate}
            // onChange={handleInputs}
            className={styles.date_picker}
            InputProps={{
              classes: { input: classes.input },
              color: "#4D4F5C",
              focused: classes.focused,
            }}
          />
          <span className={styles.label}>{t("init_end_day.to_date")}</span>
          <TextField
            id="standard-search"
            size="small"
            type="date"
            name='endDate'
            value={state.endDate}
            // onChange={handleInputs}
            variant="outlined"
            className={styles.date_picker}
            InputProps={{
              classes: { input: classes.input },
              color: "#4D4F5C",
              focused: classes.focused,
            }}
          />
        </div>
      </div>
      <div className={styles.cards}>
        <Card
          className={styles.card}
          style={{ boxShadow: "0px 2px 6px #0000000A" }}
        >
          <CardContent
            style={{
              background: "linear-gradient(#FFFFFF 55%,#FFCD54 45%)",
            }}
          >
            <div className={styles.div1}>
              <span className={styles.cardTitle}>{t("init_end_day.ind_act_users")}</span>
            </div>
            <div>
              <Typography
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "10%",
                  marginBottom: "-10%",
                }}
              >
                30
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className={styles.card}
          style={{ boxShadow: "0px 2px 6px #0000000A" }}
        >
          <CardContent
            style={{
              background: "linear-gradient(#FFFFFF 55%,#0BD878 45%)",
            }}
          >
            <div className={styles.div1}>
              <span className={styles.cardTitle}>{t("init_end_day.card_inst")}</span>
            </div>
            <div>
              <Typography
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "10%",
                  marginBottom: "-10%",
                }}
              >
                30
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className={styles.card}
          style={{ boxShadow: "0px 2px 6px #0000000A" }}
        >
          <CardContent
            style={{
              background: "linear-gradient(#FFFFFF 55%,#56BBFE 45%)",
            }}
          >
            <div className={styles.div1}>
              <span className={styles.cardTitle}>{t("init_end_day.avg_time_out")}</span>
            </div>
            <div>
              <Typography
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "10%",
                  marginBottom: "-10%",
                }}
              >
                10.30 am
              </Typography>
            </div>
          </CardContent>
        </Card>
        <Card
          className={styles.card}
          style={{ boxShadow: "0px 2px 6px #0000000A" }}
        >
          <CardContent
            style={{
              background: "linear-gradient(#FFFFFF 55%,#F7617D 45%)",
            }}
          >
            <div className={styles.div1}>
              <span className={styles.cardTitle}>{t("init_end_day.avg_time_out")}</span>
            </div>
            <div>
              <Typography
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "10%",
                  marginBottom: "-10%",
                }}
              >
                7.30 pm
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className={styles.final}>
        <Link
          style={{ textDecoration: "none" }}
          to="/operations/work-history-report-table"
        >
          {" "}
          <span
            className={styles.label}
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              fontSize: "18px",
            }}
          >
            {t("audit_report.view_details")}&nbsp;
          </span>
        </Link>
        <img src={back} alt="back" />
      </div>
    </div>
  );
}
