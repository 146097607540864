/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { Row, Col } from "reactstrap";
import styles from "./SignUp.module.css";
import CancelIcon from "@material-ui/icons/Cancel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withTranslation, useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import unnamed from "./images/unnamed-6@2x.png";
import path from "./images/Path-361.png";
import Logo2x from "./images/Logo@2x.png";
import Logo from "./images/Logo.png";
import { connect } from "react-redux";
import { compose } from "redux";

import * as actions from "../../../store/actions/index";
import * as myConstClass from "../../../constants/constants";

const api_url = myConstClass.apiUrl;
const useStyles = makeStyles((theme) => ({
  root1: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      display: "flex",
      "@media (min-width:426px) and (max-width:767px)": {
        width: "7em",
      },
      "@media (min-width:376px) and (max-width:425px)": {
        width: "7em",
      },
      "@media (min-width: 320px) and (max-width: 375px)": {
        width: "7em",
      },
      "@media (min-width: 768px) and (max-width: 1023px)": {
        width: "7em",
      },
      "@media (min-width:1024px) and (max-width:1439px)": {
        width: "10em",
      },
      "@media (min-width:1440px) and (max-width:2559px)": {
        width: "10em",
      },
      "@media (min-width:2560)": {
        width: "10em",
      },
    },
  },
  root2:  {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      display: "flex",
      "@media (min-width:426px) and (max-width:767px)": {
        width: "15em",
      },
      "@media (min-width:376px) and (max-width:425px)": {
        width: "15em",
      },
      "@media (min-width: 320px) and (max-width: 375px)": {
        width: "15em",
      },
      "@media (min-width: 768px) and (max-width: 1023px)": {
        width: "15em",
      },
      "@media (min-width:1024px) and (max-width:1439px)": {
        width: "21em",
      },
      "@media (min-width:1440px) and (max-width:2559px)": {
        width: "21em",
      },
      "@media (min-width:2560)": {
        width: "21em",
      },
    },
  },
  label: {
    color: "red",
  },
}));

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);
const mobileValid = /^[0]?[789]\d{9}$/; // MATCH MOBILE NUMBER
// VALIDATE FORM HERE

export function SignUp(props) {
  const [t] = useTranslation("common");

  const classes = useStyles();
  const history = useHistory();
  const inputRef1 = useRef("input_1");
  const inputRef2 = useRef("input_2");
  const inputRef3 = useRef("input_3");
  const inputRef4 = useRef("input_4");
  const [checked, setChecked] = useState(false);
  const [tcModal, setTc] = useState(false);

  const [userForm, setuserForm] = useState({
    isExpired: false,
    firstName: "",
    lastName: "",
    userName: "",
    mobile: "",
    email: "",
    password: "",
    cPassword: "",
    showPassword: false,
    showConfirmPas: false,
    time: {},
    breakRemainingSeconds: 10,
  });
  const [timeForm, setTimerForm] = useState({
    time: {},
    breakRemainingSeconds: 0,
  });
  const [otpForm, setotpForm] = useState({
    text1: "",
    text2: "",
    text3: "",
    text4: "",
  });
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentStatus, setCurrentStatus] = useState(false);

  const [errors, setErrors] = useState({});
  // const [checkedEr, setCheckedEr] = useState("");
  const [text, setText] = useState(t("signup.verify_mobile"));
  const [otp, setOTP] = useState("");

  const createTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let timeObject = {
      h: hours,
      m: minutes.toString().length > 1 ? minutes : "0" + minutes,
      s: seconds.toString().length > 1 ? seconds : "0" + seconds,
    };
    return timeObject;
  };

  // show and hide password
  const handleShowConfirmPassword = () => {
    setuserForm({ ...userForm, showConfirmPas: !userForm.showConfirmPas });
  };

  const handleShowPassword = () => {
    setuserForm({ ...userForm, showPassword: !userForm.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case "firstName":
        errors.firstName =
          value.trim().length < 3 || value.length > 30
            ? t("signup.firstname_error")
            : "";
        break;
      case "lastName":
        errors.lastName =
          value.trim().length < 3 || value.length > 30
            ? t("signup.lastname_error")
            : "";
        break;
      case "userName":
        errors.userName =
          value.trim().length < 3 || value.length > 10
            ? t("signup.username_error")
            : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : t("signup.invalid_email");
        break;
      case "mobile":
        errors.mobile = mobileValid.test(value)
          ? ""
          : t("signup.invalid_mobile");
        break;
      case "password":
        errors.password = value.length < 8 ? t("signup.password_error") : "";
        break;
      case "cPassword":
        errors.cPassword =
          value !== userForm.password ? t("signup.confirm_ps_error") : "";
        break;
      default:
        break;
    }
    setuserForm((userForm) => ({
      ...userForm,
      [name]:
        name === "password" || name === "cPassword" ? value.trim() : value,
    }));
  };

  const handleOTP = (event) => {
    event.preventDefault();
    const name = event.target.name;
    setotpForm({
      ...otpForm,
      [name]: event.target.value.trim(),
    });
    if (event.target.name === "text1" && event.target.value.trim() !== "") {
      inputRef2.current.focus();
    } else if (
      event.target.name === "text2" &&
      event.target.value.trim() !== ""
    ) {
      inputRef3.current.focus();
    } else if (
      event.target.name === "text3" &&
      event.target.value.trim() !== ""
    ) {
      inputRef4.current.focus();
    }

    //this.refs.input_2.focus();
  };
  //Prevent to eneter alphabets
  const onKeyPress = (event) => {
    const pattern = /[0-9-+]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  };
  const onKeyDown = (e) => {
    if (e.key === "Backspace") {
      if (e.target.name === "text1") {
        //inputRef2.current.focus();
      } else if (e.target.name === "text2") {
        inputRef1.current.focus();
      } else if (e.target.name === "text3") {
        inputRef2.current.focus();
      } else if (e.target.name === "text4") {
        inputRef3.current.focus();
      }
      setotpForm({
        ...otpForm,
        [e.target.name]: "",
      });
      e.preventDefault();
    }
  };
  const validateForm = (data) => {
    var valid = true;
    Object.keys(data).forEach((val) => {
      const name = val;

      switch (name) {
        case "firstName":
          errors.firstName =
            data.firstName.trim().length < 3 || data.firstName.length > 30
              ? t("signup.firstname_error")
              : "";
          break;

        case "lastName":
          errors.lastName =
            data.lastName.trim().length < 3 || data.lastName.length > 30
              ? t("signup.lastname_error")
              : "";
          break;

        case "userName":
          errors.userName =
            data.userName.trim().length < 3 || data.userName.length > 10
              ? t("signup.username_error")
              : "";
          break;

        case "email":
          errors.email = validEmailRegex.test(data.email)
            ? ""
            : t("signup.invalid_email");
          break;

        case "mobile":
          errors.mobile = mobileValid.test(data.mobile)
            ? ""
            : t("signup.invalid_mobile");
          break;

        case "password":
          errors.password = data.password < 8 ? t("signup.password_error") : "";
          break;

        case "cPassword":
          errors.cPassword =
            data.cPassword !== userForm.password || data.cPassword === ""
              ? t("signup.confirm_ps_error")
              : "";
          break;

        default:
      }
    });
    errors.checked = !checked ? t("signup.terms_and_condition_error") : "";
    setErrors((errors) => ({ ...errors }));
    Object.values(errors).forEach((val) => {
      if (val.length > 0 || !valid) {
        return (valid = false);
      } else {
        return (valid = true);
      }
    }); // retun false is form is not valid
    return valid;
  };

  const toggleModal = (e) => {
    setModal(!modal);
  };

  // SEND OTP
  const sendOTP = (e, type) => {
    // setModal(true);

    e.preventDefault();
    if (type === "re-send" && timeLeft > 0) {
      return;
    }
    setLoading(true);
    const otp = Math.floor(1000 + Math.random() * 9000);
    setOTP(otp);
    var data = {
      number: userForm.mobile,
    };

    // setModal(true)
    axios
      .post(api_url + `user/mobile-verify`, data, {
        method: "POST",
        headers: {
          "content-type": "Application/json",
          accept: "Application/json",
          otp: otp,
        },
      })
      .then((response) => {
        toast.success(response.data.message);

        setotpForm({ text1: "", text2: "", text3: "", text4: "" });
        setuserForm({ ...userForm, isExpired: false });
        let timeLeft = createTime(300 - 1);
        setTimerForm({ time: timeLeft });

        setTimeLeft(300 - 1);

        setModal(true);
        setLoading(false);
      })
      .catch((er) => {
        toast.error(er.response.data.message);
        setLoading(false);
      });
  };

  useEffect(()=>{
    setLoading(false)
  },[])

  // verify OTP
  const verifyOTP = (e) => {
    var userotp = otpForm.text1 + otpForm.text2 + otpForm.text3 + otpForm.text4;

    if (userForm.isExpired && userotp === otp.toString()) {
      return toast.error(t("signup.otp_expired_error"));
    }
    if (userotp !== otp.toString()) {
      return toast.error(t("signup.Invalid_otp"));
    } else {
      setModal(false);
      setText(t("signup.verified"));
      toast.success(t("signup.otp_verified"));
      setTimerForm({ breakRemainingSeconds: 0 });
      setTimeLeft(0);
    }
  };

  const backDrop = props.loading ? (
    <Backdrop className={classes.backdrop} open={props.loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;

  const backDrop1 = loading ? (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm(userForm)) {
      return;
    } else if (text !== "verified") {
      return toast.error(t("signup.verify_error"));
    } else {
      setCurrentStatus(true);
      props.registerUser(userForm);
    }
  };

  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
      setuserForm({ ...userForm, isExpired: true });
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      let time = createTime(timeLeft);
      setTimeLeft(timeLeft - 1);
      setTimerForm({ time: time });
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  useEffect(() => {
    //	getExcel()
    if (props.isSignup && currentStatus) {
      setCurrentStatus(false);
      history.push("/");
    } else {
    }
  });
  /// OPEN TERMS AND CONDITION
  const openTC = () => {
    setTc(!tcModal);
  };

  return (
    <div style={{height:"100%"}}>
      {backDrop}
      {backDrop1}
      <Container fluid={true} style ={{height:"100vh"}}>
        <Row style ={{height:"100vh"}}>
          <Col xs="3" className={styles.parent} style = {{padding:0,margin:0, height:"100vh"}}>
            <img src={unnamed} alt="" className={styles.image1Flip} style ={{height:"100vh"}}/>
            <img src={path} alt="" className={styles.image2Flip} style ={{height:"100vh"}} />
            <div className={styles.links}></div>
          </Col>

          <Col xs="6" className={styles.left} style ={{height:"100vh", maxWidth:"100vw"}}>          
            <div className={styles.left_main} style ={{height:"100vh"}}>
              <picture style={{marginTop:"2em"}}>
                <source srcSet={Logo} media="(max-width: 767px)" />
                <img src={Logo2x} alt="Some logo" />
              </picture>
              <div
                className={styles.signUp_desc}
                style={{ margin: 2, padding: 0 }}
              >
                <h3>{t("signup.create_account")}</h3>
              </div>
              <form
                className={classes.root1}
                Validate
                autoComplete="off"
                id="form1"
              >
                <div className={styles.row1}>
                  <TextField
                    id="outlined-basic"
                    label={t("signup.first_name")}
                    variant="outlined"
                    name="firstName"
                    value={userForm.firstName}
                    onChange={handleChange}
                    helperText={errors.firstName}
                    error={errors.firstName}
                    className={
                      errors.lastName && !errors.firstName ? styles.mBottom : ""
                    }
                  />

                  <TextField
                    id="outlined-basic"
                    label={t("signup.last_name")}
                    variant="outlined"
                    name="lastName"
                    onChange={handleChange}
                    helperText={errors.lastName}
                    error={errors.lastName}
                    className={
                      errors.firstName && !errors.lastName ? styles.mBottom : ""
                    }
                  />
                </div>
              </form>
              <form
                className={classes.root2}
                Validate
                autoComplete="off"
                id="form2"
                style={{
                  width: "100%",
                  display: "grid",
                  justifyContent: "center",
                  padding: "0%",
                }}
              >
                <div className={styles.row2}>
                  <TextField
                    id="outlined-basic"
                    label={t("signup.username")}
                    variant="outlined"
                    fullWidth={true}
                    name="userName"
                    helperText={errors.userName}
                    error={errors.userName}
                    value={userForm.userName}
                    onChange={handleChange}
                  />
                </div>
                <div className={styles.row2}>
                  <TextField
                    id="outlined-basic"
                    type="email"
                    name="email"
                    label={t("signup.email")}
                    variant="outlined"
                    fullWidth={true}
                    helperText={errors.email}
                    error={errors.email}
                    value={userForm.email}
                    onChange={handleChange}
                  />
                </div>
                <div className={styles.row2}>
                  <TextField
                    id="outlined-basic"
                    label={t("signup.mobile")}
                    variant="outlined"
                    name="mobile"
                    helperText={errors.mobile}
                    error={errors.mobile}
                    value={userForm.mobile}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: (
                        <div>
                          <span
                            onClick={(e) => {
                              sendOTP(e, "send");
                            }}
                            className={
                              mobileValid.test(userForm.mobile)
                                ? styles.verify
                                : styles.verifyDisabled
                            }
                          >
                            {text}
                          </span>
                        </div>
                      ),
                    }}
                  />
                  <Modal
                    isOpen={modal}
                    backdrop="static"
                    toggle={toggleModal}
                    centered={true}
                    style={{width:"80%"}}
                  >
                    <ModalBody className={styles.modalContainer}>
                      {/* <div className={styles.logoDiv}>
							<img src={Logo} alt="logo" />
						</div> */}
                    
                      <div className={styles.div1}>
                        <CancelIcon
                          style={{
                            color: "#F2134F",
                            position: "absolute",
                            top: "-10",
                            right: "-30",
                            cursor: "pointer",
                          }}
                          onClick={toggleModal}
                        />
                        <div className={styles.div1text}>
                          {t("signup.otp_vefification")}
                        </div>
                        <div className={styles.textField}>
                          <form noValidate autoComplete="off">
                            <input
                              autoFocus
                              value={otpForm.text1}
                              onChange={handleOTP}
                              onKeyPress={onKeyPress}
                              ref={inputRef1}
                              name="text1"
                              maxLength="1"
                              style={{
                                width: "10%",
                                height: "100%",
                                border: "2px solid #1A73E8",
                                borderRadius: "5px",
                                marginRight: "5%",
                                marginLeft: "23%",
                                textAlign: "center",
                              }}
                            />
                            <input
                              value={otpForm.text2}
                              onChange={handleOTP}
                              onKeyPress={onKeyPress}
                              onKeyDown={onKeyDown}
                              maxLength="1"
                              ref={inputRef2}
                              name="text2"
                              style={{
                                width: "10%",
                                height: "100%",
                                border: "2px solid #1A73E8",
                                borderRadius: "5px",
                                marginRight: "5%",
                                textAlign: "center",
                              }}
                            />
                            <input
                              value={otpForm.text3}
                              onChange={handleOTP}
                              onKeyPress={onKeyPress}
                              onKeyDown={onKeyDown}
                              ref={inputRef3}
                              maxLength="1"
                              name="text3"
                              style={{
                                width: "10%",
                                height: "100%",
                                padding: "2%",
                                border: "2px solid #1A73E8",
                                borderRadius: "5px",
                                marginRight: "5%",
                                textAlign: "center",
                              }}
                            />
                            <input
                              value={otpForm.text4}
                              onChange={handleOTP}
                              onKeyPress={onKeyPress}
                              onKeyDown={onKeyDown}
                              maxLength="1"
                              ref={inputRef4}
                              name="text4"
                              style={{
                                width: "10%",
                                height: "100%",
                                border: "2px solid #1A73E8",
                                borderRadius: "5px",
                                marginRight: "5%",
                                textAlign: "center",
                              }}
                            />
                          </form>
                        </div>
                        <div className={styles.resSend}>
                          {timeLeft > 0 && (
                            <p className={styles.ex}>
                              OTP Expires in {timeForm.time.m}:{timeForm.time.s}{" "}
                              Minutes
                            </p>
                          )}
                          <p
                            onClick={(e) => {
                              sendOTP(e, "re-send");
                            }}
                            className={
                              timeLeft == null
                                ? styles.send
                                : styles.sendDisable
                            }
                          >
                            {" "}
                            {t("signup.resend_opt")}
                          </p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "3%",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{
                              textTransform: "none",
                            }}
                            onClick={(e) => {
                              verifyOTP(e);
                            }}
                          >
                            {t("signup.verify_mobile")}
                          </Button>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
                <div className={styles.row2}>
                  <TextField
                    id="outlined-basic"
                    helperText={errors.password}
                    error={errors.password}
                    type={userForm.showPassword ? "text" : "password"}
                    name="password"
                    label={t("signup.password")}
                    variant="outlined"
                    value={userForm.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {userForm.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth={true}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
                <div className={styles.row2}>
                  <TextField
                    id="outlined-basic"
                    helperText={errors.cPassword}
                    error={errors.cPassword}
                    type={userForm.showConfirmPas ? "text" : "password"}
                    label={t("signup.confirm_password")}
                    variant="outlined"
                    fullWidth={true}
                    name="cPassword"
                    value={userForm.cPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {userForm.showConfirmPas ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                  />
                </div>
                <div className={styles.termsCondition}>
                  <FormControlLabel
                    onClick={openTC}
                    className={classes.label}
                    style={{ textDecoration: "underline" }}
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={() => {
                          setChecked(!checked);
                        }}
                        name="checkedA"
                      />
                    }
                    label={""}
                  />
                  <span
                    onClick={openTC}
                    style={{
                      cursor: "pointer",
                      fontWeight: "400",
                      fontFamily: " sans-serif",
                      textDecoration: "underline",
                      color: "#f50057",
                    }}
                  >
                    {t("signup.terms_and_condition")}
                  </span>
                </div>
                {errors.checked && (
                  <small style={{ color: "red" }} className="error">
                    {errors.checked}
                  </small>
                )}
              </form>
              <div className={styles.buttonDiv}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: "54ch", textTransform: "none" }}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {t("signup.signUp")}
                </Button>
              </div>
              <div className={styles.signInDiv}>
                {t("signup.already_signin")}{" "}
                <a href="#" style={{ color: "red" }}>
                  <Link to="/login" style={{ color: "red" }}>
                    <span style={{ fontWeight: "500" }}>
                      {t("signup.signIn")}
                    </span>
                  </Link>
                </a>
              </div>
            </div>
          </Col>
          
          <Col xs="3" className={styles.parent} style = {{padding:0,margin:0}}>
            <img src={unnamed} alt="" className={styles.image1}  style ={{height:"100vh"}} />
            <img src={path} alt="" className={styles.image2}  style ={{height:"100vh"}} />
            <div className={styles.links}></div>
          </Col>
        </Row>
      </Container>
      {/* // terms and condtion modal */}
      <Modal isOpen={tcModal} backdrop="static" toggle={openTC} centered={true} style={{width:"80%"}}>
        <ModalBody className={styles.modalContainer}>
          <div className={styles.div1}>
            <CancelIcon
              style={{
                color: "#F2134F",
                position: "absolute",
                top: "-10",
                right: "-30",
                cursor: "pointer",
              }}
              onClick={openTC}
            />
            <div className={styles.tc}>{t("signup.privacy")}</div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

SignUp.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    isSignup: state.auth.isSignup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (data) => dispatch(actions.registerUser(data)),

    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(SignUp);
