import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import training from "../components/training.png"
import mapping from "../components/mapping.png"
import collection from "../components/collection.png"
import analytics from "../components/analytics.png"
import reporting from "../components/reporting.png"
import realtime from "../components/realtime.png"

export default () => (
  <section className="s3">
    <Container>
      <div className="s3__titles">
        <h1> Amazing Features.</h1>
        {/* <h4>Integer Cursus Bibendum Augue Ac Cursus .</h4> */}
      </div>
      <Row className="s3__box-wrapper">
        <Col md="6" lg="4" className="s3__box-item">
          <img
            src={mapping}
            alt=""
          />
          <h3>Mapping of properties & Assets</h3>
          <p>
            Triple Identifier feature of Property Aadhar makes the mapping future-ready
          </p>
        </Col>
        <Col md="6" lg="4" className="s3__box-item">
          <img
            src={training}
            alt=""
          />
          <h3>Training the administration</h3>
          <p>
            Intuitive application for administration; requiring very low training of staff
          </p>
        </Col>
        <Col md="6" lg="4" className="s3__box-item">
          <img
            src={collection}
            alt=""
          />
          <h3>Collection</h3>
          <p>
            One platform for all survey needs
          </p>
          <p>
            Support for offline data integration
          </p>
        </Col>
        <Col md="6" lg="4" className="s3__box-item">
          <img
            src={analytics}
            alt=""
          />
          <h3>Analytics</h3>
          <p>
            Scoring based on AI engines
          </p>

          <p>
            Register complaints digitally
          </p>
        </Col>
        <Col md="6" lg="4" className="s3__box-item">
          <img
            src={reporting}
            alt=""
          />
          <h3>Reporting (Actionable Visual Dashboard)</h3>
          <p>
            Local language support
          </p>

          <p>
            Communication for transaction & documents
          </p>
        </Col>
        <Col md="6" lg="4" className="s3__box-item">
          <img
            src={realtime}
            alt=""
          />
          <h3>Export</h3>
          <p>
            Realtime Online Updates
          </p>
        </Col>
      </Row>
    </Container>
    <style>{`
    .s3 {
        font-size: 16px;
        margin-top: 10em;
    }
    .s3__titles {
        margin-bottom: 2em;
    }
    .s3__titles h1 {
        font-size: 2.6em;
        font-weight: 100;
        text-align: center;
    }
    .s3__titles h4 {
        font-size: .9em;
        font-weight: 100;
        text-align: center;
        margin-top: 1.8em;
        color: hsl(0, 2%, 48%);
    }
    .s3__box-wrapper {

    }
    .s3__box-wrapper {
        text-align: center;
    }
    .s3__box-item {
        padding: 2em;
        transition: all .5s;
    }
    .s3__box-item:hover {
        box-shadow: #ece9e9 1px 1px 15px;
    }
    .s3__box-item img {
        width: 100%;
    }
    .s3__box-item h3 {
        font-size: .9em;
        line-height: 2;
        font-weight: 700;
        letter-spacing: 1.5px;
        margin: 1.6em 0;
        text-transform: uppercase;
    }
    .s3__box-item p {
        font-weight: 100;
        font-size: .95em;
        line-height: 2;
        color: dimgray;
    }
    
    `}</style>
  </section>
)
