import React from "react";
import styles from "./DashBoard.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as images from "../../../components/Drawer/CustomDrawer_images";

import Card from "./Card";

export default function Dashboard() {
  const [t] = useTranslation("common");

  return (
    <>
      <div className={styles.title}>{t("dashboard.dashboard")}</div>
      <div className={styles.grid}>
        <Link
          to="/survey_operation"
          style={{ textDecoration: "none", margin: "0.5rem" }}
        >
          <Card title={t("sidebar.survey")}  icon = {images.survey} />
        </Link>
        <Link
          to="/organization_main"
          style={{ textDecoration: "none", margin: "0.5rem" }}
        >
          <Card title={t("sidebar.organisation")} icon = {images.organisation} />
        </Link>
        <Link
          to="/histories"
          style={{ textDecoration: "none", margin: "0.5rem" }}
        >
          <Card title={t("sidebar.operation")}  icon = {images.operation}  />
        </Link>
        <Link
          to="/property-list"
          style={{ textDecoration: "none", margin: "0.5rem" }}
        >
          <Card title={t("sidebar.properties")}  icon = {images.properties}  />
        </Link>
        <Link
          to="/visualise"
          style={{ textDecoration: "none", margin: "0.5rem" }}
        >
          <Card title={t("sidebar.visualise")}  icon = {images.visualise} />
        </Link>
      </div>
    </>
  );
}
