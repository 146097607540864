/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./QC.module.css";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import back from "./back.png";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue, grey } from "@material-ui/core/colors";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import * as acitons from "../../../../../store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
const useStyles = makeStyles({
  root: {
    maxWidth: "24%",
    minWidth: "24%",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: "12",
  },
  input: {
    color: "#4D4F5C",
    fontSize: "smaller",
  },
  select: {
    minWidth: "7.5vw",
    "@media (min-width: 320px) and (max-width: 375px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 376px) and (max-width: 425px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 426px) and (max-width: 768px)": {
      minWidth: "12vw",
    },
    "@media (min-width: 769px) and (max-width: 1024px)": {
      minWidth: "10vw",
    },
    background: "white",
    color: grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "13px",
    "&:hover": {
      borderColor: grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: blue[200],
    },
  },
  icon: {
    color: grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
});

export function QC(props) {
  const [t] = useTranslation("common");

  const borderSelectClasses = useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    value: "qc",
    name: "hai",
    day: "today",
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    endDate1: new Date().toISOString().split("T")[0],
  });
  const today = new Date().toISOString().split("T")[0];

  // handle change job reporting type
  const handleChange = (event) => {
    history.push(`${event.target.value}`);
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  // handle change input type
  // handle inputs
  const handleInputs = (event) => {
    const name = event.target.name;

    const addDays = (theDate, days) => {
      return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
    };
    var startDate = "";
    var endDate = "";
    if (event.target.name === "day" && event.target.value === "yesterday") {
      var newDate = addDays(new Date(), -1).toISOString().slice(0, 10);
      startDate = newDate;
      endDate = newDate;
      setState({
        ...state,
        [name]: event.target.value,
        startDate: newDate,
        endDate: newDate,
      });
    }
    if (event.target.name === "day" && event.target.value === "today") {
      newDate = new Date().toISOString().slice(0, 10);
      startDate = newDate;
      endDate = newDate;
      setState({
        ...state,
        [name]: event.target.value,
        startDate: newDate,
        endDate: newDate,
      });
    }
    if (event.target.name === "startDate") {
      newDate = event.target.value;
      startDate = event.target.value;
      endDate = state.endDate === "" ? event.target.value : state.endDate;
      setState({
        ...state,
        [name]: event.target.value,
        startDate: startDate,
        endDate: endDate,
      });
    }
    if (event.target.name === "endDate") {
      newDate = event.target.value;
      startDate = state.startDate === "" ? event.target.value : state.startDate;
      endDate = event.target.value;

      setState({
        ...state,
        [name]: event.target.value,
        startDate: startDate,
        endDate: endDate,
      });
    }
    props.getQcCardList(localStorage.getItem("orgId"), startDate, endDate);
  };

  useEffect(() => {
    let today = new Date().toISOString().slice(0, 10);
    props.getQcCardList(localStorage.getItem("orgId"), today, today);
  }, []);

  const backDrop = props.loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;
  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.header}>
        <div className={styles.title}>
          <Breadcrumbs seperator="›" aria-label="breadcrumb">
						<Typography color="textPrimary">{t("init_end_day.operation")}</Typography>
						<Typography color="textSecondary">{t("init_end_day.job_reporting")}</Typography>
					</Breadcrumbs>
        </div>
        <div className={styles.dropdownMain}>
          <FormControl>
            <Select
              disableUnderline
              labelId="inputLabel"
              placeholder={"QC"}
              IconComponent={iconComponent}
              className={classes.select}
              MenuProps={menuProps}
              value={state.value}
              onChange={handleChange}
              style={{
                marginRight: "2%",
              }}
            >
              <MenuItem value={"qc"}> {t("init_end_day.qc")} </MenuItem>{" "}
              <MenuItem value={"/operations/jobinitialisation"}>
                {" "}
                {t("init_end_day.initialisation")}
              </MenuItem>{" "}
              <MenuItem value={"/operations/jobinstallation"}>
                {" "}
                {t("init_end_day.installation")}{" "}
              </MenuItem>{" "}
              <MenuItem value={"/operations/jobaudit"}>
                {" "}
                {t("init_end_day.audit")}{" "}
              </MenuItem>{" "}
              <MenuItem value={"/operations/work-history"}>
                {" "}
                {t("init_end_day.user_work_history")}{" "}
              </MenuItem>{" "}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={styles.header2}>
        <span className={styles.subTitle}> {t("job_reporting.qc")}</span>
        <div className={styles.dropdown2}>
          <FormControl>
            <Select
              disableUnderline
              labelId="inputLabel"
              placeholder={"Today"}
              IconComponent={iconComponent}
              className={classes.select}
              MenuProps={menuProps}
              value={state.day}
              onChange={handleInputs}
              name="day"
              style={{
                marginRight: "2%",
              }}
            >
              <MenuItem value={"today"}> {t("init_end_day.today")} </MenuItem>{" "}
              <MenuItem value={"yesterday"}>
                {" "}
                {t("init_end_day.yesterday")}{" "}
              </MenuItem>{" "}
            </Select>
          </FormControl>
        </div>
        <div className={styles.date}>
          <span className={styles.label}>{t("init_end_day.from_date")}</span>
          <TextField
            id="standard-search"
            size="small"
            type="date"
            variant="outlined"
            name="startDate"
            value={state.startDate}
            onChange={handleInputs}
            className={styles.date_picker}
            // inputProps={{
            // 	max: today
            // 	 }}
            InputProps={{
              classes: { input: classes.input, max: today },
              color: "#4D4F5C",
              focused: classes.focused,
            }}
          />
          <span className={styles.label}> {t("init_end_day.to_date")}</span>
          <TextField
            id="standard-search"
            size="small"
            type="date"
            variant="outlined"
            name="endDate"
            value={state.endDate}
            onChange={handleInputs}
            className={styles.date_picker}
            // inputProps={{
            // 	max: state.endDate1
            // 	 }}
            InputProps={{
              max: state.endDate1,
              classes: { input: classes.input },
              color: "#4D4F5C",
              focused: classes.focused,
            }}
          />
        </div>
      </div>
      <div className={styles.cards}>
        <Card className={styles.card}>
          <CardContent>
            <span
              title={props.qcCardList.job_count}
              className={styles.circle}
              style={{
                background:
                  "transparent radial-gradient(#FFD856D9 0%, #F6B604 100%)",
                fontSize: "xx-large",
                fontWeight: "bold",
              }}
            >
              {props.qcCardList.job_count &&
              props.qcCardList.job_count.toString().length > 4
                ? props.qcCardList.job_count.toString().substring(0, 2) + "K+"
                : props.qcCardList.job_count}
            </span>
          </CardContent>
          <Typography
            style={{ color: "#BBBBBB", textAlign: "center" }}
            gutterBottom
          >
            {t("qc_end_day.number_of_job_created")}
          </Typography>
        </Card>
        <Card className={styles.card}>
          <CardContent>
            <span
              title={props.qcCardList.qc_completed}
              className={styles.circle}
              style={{
                background:
                  "transparent radial-gradient(#56FF9FD9 0%,#03E565 100%)",
                fontSize: "xx-large",
                fontWeight: "bold",
              }}
            >
              {props.qcCardList.qc_completed &&
              props.qcCardList.qc_completed.toString().length > 4
                ? props.qcCardList.qc_completed.toString().substring(0, 2) +
                  "K+"
                : props.qcCardList.qc_completed}
            </span>
          </CardContent>
          <Typography
            style={{
              color: "#BBBBBB",
              textAlign: "center",
              fontSize: "normal",
            }}
            gutterBottom
          >
            {t("qc_end_day.number_of_card_completed")}
          </Typography>
        </Card>
        <Card className={styles.card}>
          <CardContent>
            <span
              title={props.qcCardList.initialisation_needed}
              className={styles.circle}
              style={{
                background:
                  "transparent radial-gradient(#60BFFFC2 0%, #34ACFC 100%)",
                fontSize: "xx-large",
                fontWeight: "bold",
              }}
            >
              {props.qcCardList.initialisation_needed &&
              props.qcCardList.initialisation_needed.toString().length > 4
                ? props.qcCardList.initialisation_needed
                    .toString()
                    .substring(0, 2) + "K+"
                : props.qcCardList.initialisation_needed}
            </span>
          </CardContent>
          <Typography
            style={{ color: "#BBBBBB", textAlign: "center" }}
            gutterBottom
          >
            {t("qc_end_day.number_of_init")}
          </Typography>
        </Card>
        <Card className={styles.card}>
          <CardContent>
            <span
              title={props.qcCardList.installation_needed}
              className={styles.circle}
              style={{
                background:
                  "transparent radial-gradient(#FF7E7E 0%,#FF6060 100%)",
                fontSize: "xx-large",
                fontWeight: "bold",
              }}
            >
              {props.qcCardList.installation_needed &&
              props.qcCardList.installation_needed.toString().length > 4
                ? props.qcCardList.installation_needed
                    .toString()
                    .substring(0, 2) + "K+"
                : props.qcCardList.installation_needed}
            </span>
          </CardContent>
          <Typography
            style={{ color: "#BBBBBB", textAlign: "center" }}
            gutterBottom
          >
            {t("qc_end_day.number_of_uninstallation")}
          </Typography>
        </Card>
      </div>
      <div className={styles.final}>
        <Link
          style={{ textDecoration: "none" }}
          // to ='/operations/qc-report-table'>
          to="/operations/qc-end-day"
        >
          <span
            className={styles.label}
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              fontSize: "large",
            }}
          >
            {t("qc_end_day.view_details")}&nbsp;
          </span>
        </Link>
        <img src={back} alt="back" />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    qcCardList: state.Operation.qcCardList,
    loading: state.Operation.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQcCardList: (orgId, startDate, endDate) =>
      dispatch(acitons.getQcCardList(orgId, startDate, endDate)),
    // downloadExcelFile :(orgId, jobType)=>
    // dispatch(acitons.downloadExcel(orgId, jobType)),
    // getSubCategoryByCategory: (id) =>
    // 	dispatch(acitons.getSubCategoryByCategory(id)),
    // getOrgUser: (id) =>
    // 	dispatch(acitons.getOrgUser(id)),
    // addJob: (data) =>
    // 	dispatch(acitons.addJob(data))
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(QC);
