import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import styles from "./RequestAccess.module.css";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue, grey } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import RequestAccessTable from "../../../../components/RequestAccessTable/RequestAccessTable";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  select: {
    minWidth: "8.5vw",
    "@media (min-width: 320px) and (max-width: 375px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 376px) and (max-width: 425px)": {
      minWidth: "25vw",
    },
    background: "white",
    color: grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "13px",
    "&:hover": {
      borderColor: grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: blue[200],
    },
  },
  icon: {
    color: grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
}));

const Dropdown = (props1) => {
  const [val, setVal] = useState(0);

  const handleChange = (event) => {
    setVal(event.target.value);
  };

  const borderSelectClasses = useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const classes = useStyles();

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };

  return (
    <FormControl>
      <Select
        disableUnderline
        labelId="inputLabel"
        placeholder={props1.holder}
        IconComponent={iconComponent}
        className={classes.select}
        MenuProps={menuProps}
        value={val}
        onChange={handleChange}
        style={{
          marginRight: "2%",
        }}
      >
        <MenuItem value={0}> {props1.holder} </MenuItem>{" "}
        <MenuItem value={1}> One </MenuItem>{" "}
        <MenuItem value={2}> Two </MenuItem>{" "}
        <MenuItem value={3}> Three </MenuItem>{" "}
      </Select>
    </FormControl>
  );
};

export default function Language() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    checkedModule3: "false",
    checkedModule2: "false",
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <span>Request Access</span>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.dropDownDiv}>
            <Dropdown holder="Username" />
            <Dropdown holder="Module" />
            <Dropdown holder="Sub Module" />
          </div>
          <div className={styles.buttonDiv}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#43425D",
                color: "white",
                borderRadius: "20px",
                textTransform: "none",
                outline: "none",
                width: "120%",
                marginLeft: "6%",
              }}
            >
              Search
            </Button>
          </div>
        </div>

        <div className={styles.table}>
          <RequestAccessTable togglingModal={toggleModal} />
        </div>
        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>Add City</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            <div className={styles.innerDiv}>
              <span
                style={{
                  color: "#4D4F5C",
                  opacity: "0.5",
                  padding: "10% 20% 20% 40%",
                }}
              >
                Document
              </span>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checkedA}
                  onChange={handleChange}
                  name="checkedModule2"
                  style={{
                    color: "#3B86FF",
                  }}
                />
              }
              style={{ color: "#43425D" }}
              label="Module 2"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checkedA}
                  onChange={handleChange}
                  name="checkedModule3"
                  style={{
                    color: "#3B86FF",
                  }}
                />
              }
              style={{ color: "#43425D" }}
              label="Module 3"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              onClick={toggleModal}
              style={{
                marginRight: "2%",
                backgroundColor: "#43425D",
                color: "white",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={toggleModal}
              style={{
                backgroundColor: "#F2134F",
                color: "white",
              }}
            >
              Verify
            </Button>
          </ModalFooter>
        </Modal>
        <div className={classes.root}></div>
      </div>
      <div className={styles.paginationDiv}>
        <Pagination
          count={5}
          shape="rounded"
          color="primary"
          variant="outlined"
        />
      </div>
    </div>
  );
}
