import React, { useEffect } from "react";
import SelectSearch from "react-select";

import styles from "./UserHistory.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Pagination from "@material-ui/lab/Pagination";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import moment from "moment";
import { useTranslation, withTranslation } from "react-i18next";
import * as acitons from "../../../store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";
import { hasAccess } from "../../../shared/HasAccess";

import FormControl from "@material-ui/core/FormControl";
import { blue, grey } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
  },
  select: {
    minWidth: "8.5vw",
    "@media (min-width: 320px) and (max-width: 375px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 376px) and (max-width: 425px)": {
      minWidth: "25vw",
    },
    background: "white",
    color: grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "13px",
    "&:hover": {
      borderColor: grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: blue[200],
    },
  },
  icon: {
    color: grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
  input: {
    color: "#4D4F5C",
    fontSize: "smaller",
  },
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
}));

export function Attendance(props) {

  const classes = useStyles();
  const [t] = useTranslation("common");

  const [state, setState] = React.useState({
    date: new Date().toISOString().slice(0, 10),
    userId: "0",
    authType: false,
  });

  // handle change
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  // handle change user
  const handleChangeUser = (e, name) => {
    setState({ ...state, userId: e });
  };

  useEffect(() => {
    let authType =
      props.accessList.module !== undefined &&
      hasAccess(
        "submoduleId",
        "User_WorkHistory_head",
        props.accessList.module
      );
    setAuthType(authType); //
    props.getUserHistoryList(
      1,
      10,
      state.userId._id ? state.userId._id : "",
      state.date,
      authType
    );
    setTimeout(() => {
		props.getOrgUser(localStorage.getItem("orgId"));
    }, 1000);

	props.getOrgUser(localStorage.getItem("orgId"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set data
  const setAuthType = (type) => {
    setState({ ...state, authType: type });
  };
  /// handle  change page

  const handleChangePage = (event, page) => {
    props.getUserHistoryList(
      page,
      props.limit,
      state.userId._id ? state.userId._id : "",
      state.date,
      state.authType
    );
  };

  // on search attendacen
  const onSearchAttendance = () => {
    props.getUserHistoryList(
      1,
      props.limit,
      state.userId._id ? state.userId._id : "",
      state.date,
      state.authType
    );
  };

  // dWONNLAOD WORK HISTORY
  const downloadWorkHistory = () => {
    props.downloadWorkHistory(
      state.userId._id ? state.userId._id : "",
      state.date
    );
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  // ENABLE LOADING
  const backDrop = props.loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;
  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <span style={{ fontWeight: "light" }}>
          {localStorage.getItem("endMyDay")
            ? t("user_history.title")
            : t("end_my_day.end_my_day")}
        </span>
      </div>
      <div className={styles.tableDiv}>
        <div
          className={
            props.accessList.module !== undefined &&
            hasAccess(
              "submoduleId",
              "User_WorkHistory_head",
              props.accessList.module
            )
              ? styles.searchBarDiv1 + " " + styles.searchBarDiv
              : styles.searchBarDiv
          }
        >
          {props.accessList.module !== undefined &&
            hasAccess(
              "submoduleId",
              "User_WorkHistory_head",
              props.accessList.module
            ) && (
              <div className={styles.searchAndDrop}>
                <div>
                  {props.accessList.module !== undefined &&
                    hasAccess(
                      "submoduleId",
                      "User_WorkHistory_head",
                      props.accessList.module
                    ) && (
                      <div className={styles.searchBar}>
                        <FormControl>
                          <SelectSearch
                            className={styles.seaarchSelect}
                            value={state.userId}
                            onChange={(e) => {
                              handleChangeUser(e, "userId");
                            }}
                            options={props.orgUserList}
                            name={"userId"}
                            placeholder={t("user_history.user")}
                          ></SelectSearch>
                        </FormControl>
                      </div>
                    )}
                </div>
                <div
                  className={
                    props.accessList.module !== undefined &&
                    hasAccess(
                      "submoduleId",
                      "User_WorkHistory_head",
                      props.accessList.module
                    )
                      ? styles.dropDownDiv
                      : styles.dropDownDiv + "" + styles.M_L
                  }
                >
                  {localStorage.getItem("endMyDay") && (
                    <TextField
                      id="standard-search"
                      size="small"
                      type="date"
                      name="date"
                      variant="outlined"
                      onChange={handleChange}
                      value={state.date}
                      style={{
                        borderColor: "#F5F6FA",
                        borderRadius: "4px",
                        marginLeft: "2%",
                      }}
                      InputProps={{
                        classes: { input: classes.input },
                        color: "#4D4F5C",
                        focused: classes.focused,
                      }}
                      inputProps={{
                        max: new Date().toISOString().slice(0, 10),
                      }}
                    />
                  )}

                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#43425D",
                      color: "white",
                      borderRadius: "17px",
                      textTransform: "none",
                      width: "109px",
                      outline: "none",
                      marginLeft: "5%",
                    }}
                    onClick={onSearchAttendance}
                  >
                    {t("user_history.search")}
                  </Button>
                </div>
              </div>
            )}
          <div className={styles.buttonAndFilter}>
            <Button
              onClick={downloadWorkHistory}
              variant="contained"
              color="secondary"
              style={{
                textTransform: "none",
                backgroundColor: "#3B86FF",
                textAlign: "center",
                whiteSpace: "nowrap",
                outline: "none",
                marginLeft: "2%",
                fontSize: "smaller",
              }}
            >
              {t("user_history.download")}
              <GetAppIcon style={{ marginLeft: "20%" }} />
            </Button>
          </div>
        </div>
        <div className={styles.table}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <TableRow>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("user_history.user")}
                  </TableCell>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("user_history.attempted_property")}
                  </TableCell>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("user_history.mapped_property")}
                  </TableCell>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("user_history.failed_property")}
                  </TableCell>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("user_history.aborted_property")}
                  </TableCell>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("user_history.date")}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ color: "#A3A6B4" }}
                  ></TableCell>
                  <TableCell
                    align="left"
                    style={{ color: "#A3A6B4" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.userHistoryList.length > 0 &&
                  props.userHistoryList.map((history) => (
                    <TableRow key={history._id}>
                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {history.userId.name}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {history.total_properties_attempted}
                      </TableCell>

                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {history.total_properties_mapped}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {history.total_properties_failed}{" "}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {history.total_properties_aborted}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {moment(history.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {props.userHistoryList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {" "}
                {t("job_reporting.no_record_found")}
              </p>
            )}
          </TableContainer>
        </div>
      </div>
      <div className={styles.paginationDiv}>
        {props.userHistoryList.length > 0 && setPage()}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    userHistoryList: state.Operation.userHistoryList,
    loading: state.Operation.loading,
    page: state.Operation.page,
    limit: state.Operation.limit,
    total: state.Operation.total,
    orgUserList: state.Operation.orgUserList,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserHistoryList: (page, limit, userId, date, authType) =>
      dispatch(
        acitons.getUserHisotoryList(page, limit, userId, date, authType)
      ),
    getOrgUser: (id) => dispatch(acitons.getOrgUser(id)),

    downloadWorkHistory: (userid, search) =>
      dispatch(acitons.downloadWorkHistory(userid, search)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Attendance);
