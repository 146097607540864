/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import download from "../../../../assets/upload1.png";
import {hasAccess} from '../../../../shared/HasAccess'

import { blue, grey } from "@material-ui/core/colors";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./SubCategory.module.css";
import  { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,

} from "reactstrap";

import edit from "./edit.png";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useTranslation } from 'react-i18next';

import * as actions  from '../../../../store/actions/index'
import {compose} from 'redux'
import {connect} from 'react-redux'
import { withTranslation } from "react-i18next";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "25ch",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
		},
		
	},
	select: {
		minWidth: "9.5vw",
		background: "white",
		color: grey[700],
		borderColor: "#D7DAE2",
		borderStyle: "solid",
		borderWidth: "2px",
		borderRadius: "4px",
		paddingLeft: "5px",
		paddingTop: "2px",
		paddingBottom: "2px",
		"&:hover": {
			borderColor: grey[400],
		},
		"&:focus": {
			borderRadius: "4px",
			background: "white",
			borderColor: blue[200],
		},
	},
	icon: {
		color: grey[500],
		right: 12,
		position: "absolute",
		userSelect: "none",
		pointerEvents: "none",
	},
	list: {
		paddingTop: 0,
		paddingBottom: 0,
		background: "white",
		color: "#4d4f5c",
		fontSize: "smaller",
		"& li.Mui-selected": {
			fontWeight: 400,
		},
	},
}));

export   function SubCategory(props) {
	const classes = useStyles();
	const borderSelectClasses = useBorderSelectStyles();
	const menuProps = {
		classes: {
			list: borderSelectClasses.list,
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left",
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left",
		},
		getContentAnchorEl: null,
	};
	const [t] = useTranslation('common');

	const [state, setState] = React.useState({
		search:"",
		categoryId:"0",
		title:"",
		name:"",
		id:"",
		isDelete:true,isAdd:false, isUpdate:false,
	});
	const [modal, setModal] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(true);

	useEffect(()=>{
		props.getSubCategoryList(1, props.limit,  state.search,   state.categoryId==='0'?"":state.categoryId, );
		props.getAllCategory()
	},[])

	const iconComponent = (props) => {
		return (
			<ExpandMoreIcon
				className={props.className + " " + borderSelectClasses.icon}
			/>
		);
	};
	const handleChange = (event) => {
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
	};
	// OPEN DELETE
	const toggleModal =( data)=>{
		
		setModal(!modal)
		 setState({...state, name: data.subCategoryName, btnTitle:"Delete", search:"", id:data._id,
		 title:"Delete  Sub Category ", isAdd:false, isDelete:true, isUpdate:false});

		 setCurrentStatus(false)
   
	   }
	let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;
// handle change page number 
const handleChangePage = (event, page) => {
	props.getSubCategoryList(page, props.limit, state.search, state.cateId)
	}

	/// handle submit 
	const handleSubmit = (e) => {
		e.preventDefault();
		setCurrentStatus(true)
		state.orgId = localStorage.getItem('orgId')
		props.managSubcategory (state)
	}	
	  // DOWNLAOD EXCEL FILE
	  const downloadFile =()=>{
		props.downloadExcelCatSubCate("subcategory", localStorage.getItem('orgId'),  state.search, state.categoryId==='0'?"":state.categoryId)
	}

	useEffect(() => {
		
		if (props.message&&currentStatus) {
			setModal(!modal)
			setCurrentStatus(false)
		
			
		} else {
		
		}
	});
	
// apply pagination
const setPage = () => {
	
		let total =Math.ceil(props.total / props.limit)
		return(
		<Pagination
		className={classes.root}
		onChange={handleChangePage}
		count={total}
		 shape="rounded"
		 color="primary"
		 variant="outlined"
			  style={{
			 marginTop: "2%",
			 float: "right",
			 }}
		  />
		  )
	
	  };

	  // ON SEACH sub cateogry
const onSearchSubcategory=(e)=>{

	props.getSubCategoryList(1, props.limit, state.search , state.categoryId===0?'': state.categoryId,)
}

	// ENABEL LAODING
	const backDrop = props.loading ? (
		<Backdrop style={{ zIndex: 1204 }} className={classes.backdrop} open={props.loading}>
			<CircularProgress color="inherit" />
		</Backdrop>
	) : null
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<Breadcrumbs seperator="›" aria-label="breadcrumb">
					<Typography color="textPrimary">{t("subcategory.properties")}</Typography>
					<Typography color="textSecondary">{t("subcategory.title")}</Typography>
				</Breadcrumbs>
			</div>
			<div className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div>
							<div className={styles.searchBar}>
								<TextField
									id="standard-search"
									size="small"
									type="search"
									name="search"
									variant="outlined"
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
									}}
									value={state.search}
									onChange={handleChange}
									InputProps={{
										startAdornment: icon,
										placeholder: (t("subcategory.search")),
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/>
							</div>
						</div>
						<div className={styles.dropDownDiv}>
							{props.accessList.module !== undefined && hasAccess('submoduleId', 'category_user_getall', props.accessList.module) && <FormControl>
								<Select
									inputProps={{ 'aria-label': 'categoryId' }}
									disableUnderline
									labelId="inputLabel"
									placeholder="Category"
									IconComponent={iconComponent}
									className={classes.select}
									MenuProps={menuProps}
									value={state.categoryId}
									name="categoryId"
									onChange={handleChange}
									style={{
										marginRight: "2%",
									}}
								>
									<MenuItem selected value={"0"}> {t("subcategory.category")} </MenuItem>{" "}
									{props.allCategoryList.length > 0 && props.allCategoryList.map(cate => <MenuItem value={cate._id}> {cate.categoryName} </MenuItem>)}

								</Select>
							</FormControl>}
							<Button
								variant="contained"
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "20px",
									textTransform: "none",
									marginLeft: "3%",
								}}
								onClick={(e) => { onSearchSubcategory(e) }}
							>
								{t("subcategory.search_btn")}
							</Button>
						</div>
					</div>
					<div className={styles.buttonContainer}>
						{props.accessList.module !== undefined && hasAccess('submoduleId', 'subcategory_download', props.accessList.module) && <Button
					
							variant="contained"
							color="secondary"
							style={{
								textTransform: "none",
								textAlign: "center",
								width: "50%",
								alignSelf: "right",
								outline: "none",
								backgroundColor: "#3B86FF",
								fontSize: "10px",
							}}
							onClick={downloadFile}
						>
							{t("subcategory.download")}
							<img
								src={download}
								style={{
									transform: "rotate(180deg)",
									marginLeft: "30%",
								}}
								alt="download"
						
							/>
					
						</Button>}
						{props.accessList.module !== undefined && hasAccess('submoduleId', 'subcategory_add', props.accessList.module) && <Link style={{ textDecoration: "none" }} to='/add-subcategory'><Button
							variant="contained"
							style={{
								backgroundColor: "#F2134F",
								color: "white",
								textTransform: "none",
								width: "95%",
								outline: "none",
							}}
						>
							{t("subcategory.add")}
						</Button>
						</Link>}
					</div>
				</div>
				<Modal isOpen={modal} toggle={toggleModal} centered={true}>
					<ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
					<ModalBody className={styles.modalContainer}>
						<p>{t("subcategory.delete_msg")} <strong>{state.name}</strong> ? </p>
						
					</ModalBody>
					<ModalFooter>
						<Button
							variant="contained"
							color="primary"
							onClick={toggleModal}
							style={{ marginRight: "2%" }}
						>
							{t("subcategory.cancel")}
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={(e) => { handleSubmit(e) }}
						>
							{state.btnTitle}
						</Button>
					</ModalFooter>
				</Modal>
				<div className={styles.table}>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead style={{ backgroundColor: "#F5F6FA" }}>
								<TableRow>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("subcategory.sub_category_name")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("subcategory.category_name")}
									</TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell align="center" style={{ color: "#A3A6B4" }}>
										{t("subcategory.is_active")}
									</TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.subCategoryList.length > 0 && props.subCategoryList.map((subcate) => (
									<TableRow key={subcate._d}>
										<TableCell
											align="left"
											style={{ color: "#4D4F5C" }}
										>
											{subcate.subCategoryName}
										</TableCell>
										<TableCell align="left">{subcate.categoryId.categoryName}</TableCell>
										<TableCell align="left"></TableCell>
										<TableCell align="left"></TableCell>
										<TableCell align="left"></TableCell>
										<TableCell align="center">
											<span
												style={{
													color:
														subcate.status === "YES"
															? "#1CB10B"
															: "#F80808",
												}}
											>
												{subcate.status ? "YES" : "NO"}
											</span>
										</TableCell>
										<TableCell align="justify" padding="checkbox">
											{props.accessList.module !== undefined && hasAccess('submoduleId', 'subcategory_delete', props.accessList.module) && <DeleteForeverIcon
												onClick={() => { toggleModal(subcate) }}
												style={{
													padding: "none",
												}}
											/>}
										</TableCell>
										<TableCell align="left" padding="checkbox">
											{props.accessList.module !== undefined && hasAccess('submoduleId', 'subcategory_update', props.accessList.module) && <Link to={`/edit-subcategory/${subcate._id}`}><img src={edit} alt="Edit" /></Link>}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{props.subCategoryList.length === 0 && <p style={{ textAlign: 'center' }}>{t("subcategory.no_record_found")}</p>}

					</TableContainer>
				</div>
			</div>
			<div className={styles.paginationDiv}>
				{props.subCategoryList.length > 0 && setPage()}
			</div>
		</div>
	);
}
const mapStateToProps =(state)=>{
	
	return{
	allCategoryList:state.Category.category.allCategoryList,
	subCategoryList:state.SubCategory.subcategory.subCategoryList,
	
	loading:state.SubCategory.loading,
	limit:state.SubCategory.subcategory.limit,
	total:state.SubCategory.subcategory.total,
	message:state.SubCategory.subcategory.message,
	accessList: state.Organization.accessList,


	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
		getAllCategory:()=>
		dispatch(actions.getAllCategory()),
	 managSubcategory:(data)=>
	 dispatch(actions.addUpdateDeleteSubCat(data)),
	 downloadExcelCatSubCate:(type, orgId, search, categoryId)=>
	 dispatch(actions.downloadExcelCatSubCate(type, orgId, search, categoryId)),
	 getSubCategoryList:(page, limit, search, cateId)=>
	 dispatch(actions.getSubCategoryList(page, limit, search, cateId)),
	}
}


export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(SubCategory)