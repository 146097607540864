import React from "react";
import "./Footer.css"
import brandLogo from "../../../shared/images/brand_logo.png"

export default function Footer()
{
  return(
  <footer class="footer">

<div class="content-left">
      <div class="legal__links">
        <img src={brandLogo} style={{ height: "50px", width: "265px" }} alt="brand logo" />
        <h6>Made with <span class="heart">♥</span> love in Bharat</h6>
        <p>&copy; 2020. All rights reserved.</p>
      </div>
    </div>

    <div class="content-right">
      <div class="footer__addr">
        <h2>Contact</h2>
        <a href="tel:+91-9891494804">9891494804</a>
        <address>
            Address: 9/2 Malharganj,<br/> Indore - 452002<br />
          <a class="footer__btn" href="mailto:varun@samadhan.tech">Email Us</a>
        </address>
        
      </div>

    </div>

   
  </footer>
)}
