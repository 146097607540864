import * as actionTypes from "../actions/actionTypes";
import {
    updateObject
} from "../../shared/utility";
// import { Country } from "../../screens/mainApp/Master/Country/Country";

const initialState = {
    blankSamadhan: {
        blankSamadhanList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: true,
        error: null,
        samadhanViewList: [],
        authRedirectPath: "/dashboard",
    },
    orgSamadhan: {
        orgSamadhanList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: true,
        error: null,
        samadhanViewList: [],
        orgList: [],
        authRedirectPath: "/dashboard",
    },

    loading: false,
    message: null,

};

const authStart = (state, action) => {

    let orgSamadhan = state.orgSamadhan;
    orgSamadhan.message = null
    let blankSamadhan = state.blankSamadhan
    blankSamadhan.message = null;
    // var country=state.country;
    // country.loading=true
    return updateObject(state, {
        orgSamadhan: orgSamadhan,
        loading: true,
        message: null,
    });
};

const addBlankSamadhanSuccess = (state, action) => {

    let blankSamadhan = state.blankSamadhan;
    blankSamadhan.loading = false;
    blankSamadhan.message = "success"
    blankSamadhan.message = action.data;
    return updateObject(state, {
        blankSamadhan: blankSamadhan,
        error: null,
        loading: false,
    });
};


const addBlankSamadhanFail = (state, action) => {
    let blankSamadhan = state.blankSamadhan;
    blankSamadhan.loading = true;
    blankSamadhan.message = state.message;
    return updateObject(state, {
        blankSamadhan: blankSamadhan,
        error: null,
        loading: false,
    });
};

const getBlankSamadhanSuccess = (state, action) => {

    var blankSamadhan = state.blankSamadhan;
    blankSamadhan.blankSamadhanList = action.data.data.docs;
    blankSamadhan.page = action.data.data.page;
    blankSamadhan.limit = action.data.data.limit;
    blankSamadhan.total = action.data.data.total

    return updateObject(state, {
        blankSamadhan: blankSamadhan,
        loading: false,
    });
};
const authFail = (state, action) => {

    return updateObject(state, {
        error: action.error.error.message,
        loading: false,
    });
};

const getBlankSamadhanFail = (state, action) => {

    var blankSamadhan = state.blankSamadhan;
    blankSamadhan.blankSamadhanList = [];
    return updateObject(state, {
        blankSamadhan: blankSamadhan,
        loading: false,
    });
};


const getSamadhanViewAllSuccess = (state, action) => {

    var blankSamadhan = state.blankSamadhan;
    blankSamadhan.blankSamadhanList = action.data.data.docs;
    blankSamadhan.page = action.data.data.page;
    blankSamadhan.limit = action.data.data.limit;
    blankSamadhan.total = action.data.data.total

    return updateObject(state, {
        blankSamadhan: blankSamadhan,
        loading: false,
    });
};


const getSamadhanViewAllFail = (state, action) => {

    var blankSamadhan = state.blankSamadhan;
    blankSamadhan.blankSamadhanList = [];
    return updateObject(state, {
        blankSamadhan: blankSamadhan,
        loading: false,
    });
};


const addOrgSamadhanSuccess = (state, action) => {

    let orgSamadhan = state.orgSamadhan;
    orgSamadhan.loading = false;
    orgSamadhan.message = "success"
    orgSamadhan.message = action.data;
    return updateObject(state, {
        orgSamadhan: orgSamadhan,
        error: null,
        // loading: false,
    });
};

const addOrgSamadhanFail = (state, action) => {
    let orgSamadhan = state.orgSamadhan;
    orgSamadhan.loading = true;
    orgSamadhan.message = state.message;
    return updateObject(state, {
        orgSamadhan: orgSamadhan,
        error: null,
        loading: false,
    });
};


const getOrgSamadhanSuccess = (state, action) => {

    var orgSamadhan = state.orgSamadhan;
    orgSamadhan.orgSamadhanList = action.data.data.docs;
    orgSamadhan.page = action.data.data.page;
    orgSamadhan.limit = action.data.data.limit;
    orgSamadhan.total = action.data.data.total

    return updateObject(state, {

        orgSamadhan: orgSamadhan,
        loading: false,
    });
};


const getAllOrgSuccess = (state, action) => {

    var orgSamadhan = state.orgSamadhan;
    orgSamadhan.orgList = action.data.data;
    return updateObject(state, {
        orgSamadhan: orgSamadhan,
        loading: false,
    });
};

// DOWNLOAD BLANK AND ORG SMD
export const downloadSmdIdSuccss =(state, action)=>{
    
    return updateObject(state,{
        loading:false
    })
}

export const BlankSamadhan = (state = initialState, action) => {
    

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.ADD_BLANK_SMD_SUCCESS:
            return addBlankSamadhanSuccess(state, action);
        case actionTypes.ADD_BLANK_SMD_FAIL:
            return addBlankSamadhanFail(state, action);

        case actionTypes.GET_BLANK_SMD_SUCCESS:
            return getBlankSamadhanSuccess(state, action);

        case actionTypes.AUTH_FAIL:
            return authFail(state, action);

        case actionTypes.GET_BLANK_SMD_FAIL:
            return getBlankSamadhanFail(state, action);

        case actionTypes.GET_SMD_VIEW_ALL_SUCCESS:
            return getSamadhanViewAllSuccess(state, action);


        case actionTypes.GET_SMD_VIEW_FAIL:
            return getSamadhanViewAllFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return initialState;
        case actionTypes.DOWNLOAD_BLANK_SMD_SUCCESS:
            return downloadSmdIdSuccss(state, action); 

            
        default:
            return state;
    }
};




export const OrgSamadhan = (state = initialState, action) => {
    

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);

        case actionTypes.ADD_ORG_SMD_SUCCESS:
            return addOrgSamadhanSuccess(state, action);

        case actionTypes.ADD_ORG_SMD_FAIL:
            return addOrgSamadhanFail(state, action);

        case actionTypes.GET_ORG_SMD_SUCCESS:
            return getOrgSamadhanSuccess(state, action);

        case actionTypes.GET_All_ORG_SUCCESS:
            return getAllOrgSuccess(state, action);

            case actionTypes.DOWNLOAD_ORG_SMD_SUCCESS:
                return downloadSmdIdSuccss(state, action); 
    
        // case actionTypes.GET_SMD_VIEW_ALL_SUCCESS:
        //     return getSamadhanViewAllSuccess(state, action);


        // case actionTypes.GET_SMD_VIEW_FAIL:
        //     return getSamadhanViewAllFail(state, action); 
        default:
            return state;
    }
};

//
