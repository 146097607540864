/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
// React Imports
import React, { useState, useEffect } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
//Redux imports
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../store/actions/index";
//Translation imports
import { withTranslation, useTranslation } from "react-i18next";
//Images imports
import * as images from "./CustomDrawer_images";
//STYLING IMPORTS
import styles from "./CustomDrawer.module.css";
//reactstrap import
import { Collapse } from "reactstrap";
// Material-ui related imports
import * as mui from "../../shared/mui";
import { Box } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const GbLng = () => (
  <span className={styles.topBarLngBtnTitle}>
    <span>English</span>
  </span>
);

const HiLng = () => (
  <span className={styles.topBarLngBtnTitle}>
    <span>हिंदी</span>
  </span>
);

const MrLng = () => (
  <span className={styles.topBarLngBtnTitle}>
    <span>मराठी</span>
  </span>
);

const drawerWidth = 252;

const useStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    "@media only screen and (max-width:800px)": {
      overflowX: "auto",
    },
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiListItem-button": {
      height: "53px",
    },
  },

  appBar: {
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  margin: {
    margin: theme.spacing(1),
    width: "100%",
    boxShadow: "0px 2px 3px #0000000D",
    border: "1px solid #D7DAE2",
    borderRadius: " 4px",
  },
  m_l_21: {
    marginLeft: "21%",
  },
  m_l_60: {
    marginLeft: "60%",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: "260px",
    flexShrink: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    margin: 0,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "@media only screen and (max-width:800px)": {
      display: "none",
      margin: 0,
    },
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    outline: "none",
    "@media only screen and (max-width:800px)": {
      marginBottom: "20%",
    },
  },

  rightMenu: {
    display: "none",
    "@media only screen and (max-width:800px)": {
      display: "block",
    },
  },
  orange: {
    color: theme.palette.getContrastText(mui.deepOrange[500]),
    backgroundColor: mui.deepOrange[500],
  },
  tabs: {
    flexGrow: 1,
    alignSelf: "center",
    marginLeft: "15%",
    borderColor: "white",
    bottom: "0",
    paddingBottom: "0%",
  },
  link: {
    textDecoration: "none",
    color: "#43425D",
  },
  active: {
    color: "#F2134F",
    borderLeft: "2px solid",
  },
  in_active: {
    color: "#43425D",
  },
}));

const style = {
  notClick: { color: "#43425D" },
  onClicked: { color: "red" },
};

export function MiniDrawer(props) {
  const [t, i18n] = useTranslation("common");

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [samadhanSubMenu, setSamadhanSubmenu] = useState(false);
  const [surveySubMenu, setSurveySubmenu] = useState(false);
  const [payTaxSubMenu, setPayTaxSubMenu] = useState(false);
  const [masterSubMenu, setMasterSubmenu] = useState(false);
  const [propertiesSubMenu, setPropertiesSubmenu] = useState(false);
  const [OperationSubMenu, setOperationSubmenu] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [collapseLng, setLngCollapse] = useState(false);
  const [mainButtonContent, setLng] = useState(<GbLng />);
  const [collapseOrg, setOrg] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
    clicked: "false",
  });

  const [org, setORgId] = React.useState("1");
  const [showTabs, setShowTabs] = useState(false);
  const [selectedMenu, setMenu] = useState("dashboard");
  const url = useRouteMatch();
  const path = url.path.split("/")[1];

  const history = useHistory();

  const handleClick = () => {
    setState({ clicked: true });
  };
  const onTab = () => setShowTabs(true);

  //To collapse or Expand the entire Drawer
  const toggleDrawer = () => {
    setOpen(!open);
    setOperationSubmenu(false);
    setSamadhanSubmenu(false);
    setMasterSubmenu(false);
    setPropertiesSubmenu(false);
    setSurveySubmenu(false);
    setPayTaxSubMenu(false);
  };

  //To expand the drawer
  const toggleOpen = () => {
    setOpen(true);
  };

  const toggleClose = () => {
    setOpen(false);
    setOperationSubmenu(false);
    setSamadhanSubmenu(false);
    setMasterSubmenu(false);
    setPropertiesSubmenu(false);
    setSurveySubmenu(false);
    setSamadhanSubmenu(false);
    setPayTaxSubMenu(false);
  };

  // On change menu
  const onChangeMenu = (e, type) => {
    setMenu(type);
  };
  /// is accessbale
  const hasAccess = (type, name) => {
    if (props.accessList.module !== undefined) {
      if (type === "module") {
        let authObj = props.accessList.module.find(
          (x) => x.moduleId.keyName === name
        );
        if (authObj) {
          if (authObj.mod_enableStatus === "true") {
            return true;
          } else {
            return false;
          }
        }
      } else if (type === "submoduleId") {
        let authObj = props.accessList.module.find((x) =>
          x.submoduleId.find((d) => d.keyName === name)
        );
        if (authObj && authObj.mod_enableStatus === "true") {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userId") !== "5f53a7e1ca653b1af4a1624d") {
      props.getUserorgList();
    }
    props.getAccessControl();
  }, []);
  // toggle
  const toggleOrg = () => {
    setOrg(!collapseOrg);
  };
  // on change orgnisation
  const onChangeOrg = (e, org) => {
    toggleOrg();
    localStorage.setItem("orgName", org.orgName);
    localStorage.setItem("orgId", org.orgId);
    props.getUserorgList();
    props.getAccessControl();
    history.push("/organization_main");
    window.location.reload();
  };
  // toggle Profile
  const toggle = () => {
    // setCollapse(prevState => ({ collapse: !prevState.collapse }));
    setCollapse(!collapse);
  };
  const toggleLng = () => {
    setLngCollapse(!collapseLng);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    switch (lng) {
      case "en":
        setLng(<GbLng />);
        break;
      case "hi":
        setLng(<HiLng />);
        break;
      case "mr":
        setLng(<MrLng />);
        break;
      default:
        setLng(<GbLng />);
        break;
    }
    toggleLng();
  };
  // handle change org
  const handleChangeOrg = (e) => {
    localStorage.removeItem("orgId");
    localStorage.setItem("orgId", e.target.value);

    setORgId(e.target.value);
    window.location.reload();
  };

  // logout user
  const logoutUser = (e) => {
    props.logout();
    localStorage.setItem("token", "");
    history.push("/survey");
    history.push("/");
  };
  const toggleIcon = open ? <mui.CancelOutlinedIcon /> : <mui.MenuIcon />;

  // SAMADHAN DRAWER MENU STARTS HERE//


    // Dashboard or Home
    const Dashboard = props.accessList.module !== undefined &&(
      <Link
        to={
          "/dashboard"
        }
        onClick={() => {
          toggleClose();
        }}
        style={{ textDecoration: "none", color: "#43425D" }}
      >
        <mui.ListItem
          onClick={(e) => {
            onChangeMenu(e, "Organisation");
          }}
          className={
            path === "dashboard"
              ? styles.active
              : styles.inactive
          }
          button
          key="dashboard"
          title={t("sidebar.dashboard")}
        >
          <mui.ListItemIcon>
            <img src={images.Home} alt="Dashboard" />
          </mui.ListItemIcon>
          <mui.ListItemText primary={t("sidebar.dashboard")} />
        </mui.ListItem>
      </Link>
    );
  //0. Survey Menu
  const surveyMenu = props.accessList.module !== undefined && (
    <mui.ListItem
      onClick={() => {
        setSurveySubmenu(!surveySubMenu);
        toggleOpen();
      }}
      button
      key="Survey"
      className={
        path === "survey_reporting" || path === "survey_operation"
          ? styles.active
          : styles.inactive
      }
      title={t("sidebar.survey")}
    >
      <mui.ListItemIcon>
        <img src={images.survey} alt="Survey" />
      </mui.ListItemIcon>
      <mui.ListItemText primary={t("sidebar.survey")} />
      <mui.IconButton style={{ outline: "none" }}>
        {samadhanSubMenu ? (
          <mui.KeyboardArrowDownIcon />
        ) : (
          <mui.ChevronRightIcon />
        )}
      </mui.IconButton>
    </mui.ListItem>
  );

  // 0.1 Survey Menu Items

  const surveySubMenuListItems = surveySubMenu ? (
    <mui.List>
      {props.accessList.module !== undefined && (
        <Link
          to="/survey_operation"
          onClick={() => {
            toggleClose();
            setSurveySubmenu(!surveySubMenu);
          }}
          style={{ textDecoration: "none", color: "#43425D" }}
        >
          <mui.ListItem
            onClick={(e) => {
              onChangeMenu(e, "survey_operation");
            }}
            className={
              path === "survey_operation" ? styles.active : styles.inactive
            }
            button
            key="Survey Operation"
          >
            <div className={styles.subMenuItems}>
              <mui.ListItemText primary={t("sidebar.survey_operation")} />
            </div>
          </mui.ListItem>
        </Link>
      )}
      {props.accessList.module !== undefined && (
        <Link
          to="/survey_reporting"
          onClick={() => {
            toggleClose();
            setSurveySubmenu(!surveySubMenu);
          }}
          style={{ textDecoration: "none", color: "#43425D" }}
        >
          <mui.ListItem
            onClick={(e) => {
              onChangeMenu(e, "survey_reporting");
            }}
            className={
              path === "survey_reporting" ? styles.active : styles.inactive
            }
            button
            key="Survey Reporting"
          >
            <div className={styles.subMenuItems}>
              <mui.ListItemText primary={t("sidebar.survey_reporting")} />
            </div>
          </mui.ListItem>
        </Link>
      )}
    </mui.List>
  ) : null;

  //1. SamadhanId Menu
  const samadhanIdMenu = props.accessList.module !== undefined &&
    hasAccess("module", "Organization") && (
      <mui.ListItem
        onClick={() => {
          setSamadhanSubmenu(!samadhanSubMenu);
          toggleOpen();
        }}
        button
        key="Samadhan ID"
        title={t("sidebar.samadhanId")}
        className={
          path === "organization-samadhanid" ? styles.active : styles.inactive
        }
      >
        <mui.ListItemIcon>
          <img src={images.id} alt="press" />
        </mui.ListItemIcon>
        <mui.ListItemText primary={t("sidebar.samadhanId")} />
        <mui.IconButton style={{ outline: "none" }}>
          {samadhanSubMenu ? (
            <mui.KeyboardArrowDownIcon />
          ) : (
            <mui.ChevronRightIcon />
          )}
        </mui.IconButton>
      </mui.ListItem>
    );

  // 1.1 SamadhanId Menu Items
  const samadhanSubMenuListItems = samadhanSubMenu ? (
    <mui.List>
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "samadhan_blank_id_list") && (
          <Link
            to="/blank-samadhanid"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "blank_smd");
              }}
              className={
                path === "blank-samadhanid" ? styles.active : styles.inactive
              }
              button
              key="SamadhanSubMenuItem1"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.blank_smd_id")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "samadhan_org_list") && (
          <Link
            to="/organization-samadhanid"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "org_smd");
              }}
              className={
                path === "organization-samadhanid"
                  ? styles.active
                  : styles.inactive
              }
              button
              key="SamadhanSubMenuItem1"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.org_smd_id")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
    </mui.List>
  ) : null;

  // 2. Master Menmu
  const masterMenu = localStorage.getItem("smdRole") === "admin" && (
    <mui.ListItem
      onClick={() => {
        setMasterSubmenu(!masterSubMenu);
        toggleOpen();
      }}
      button
      key="Master"
      title={t("sidebar.master")}
    >
      <mui.ListItemIcon>
        <img src={images.master} alt="User List" />
      </mui.ListItemIcon>
      <mui.ListItemText primary={t("sidebar.master")} />
      <mui.IconButton style={{ outline: "none" }}>
        {masterSubMenu ? (
          <mui.KeyboardArrowDownIcon />
        ) : (
          <mui.ChevronRightIcon />
        )}
      </mui.IconButton>
    </mui.ListItem>
  );
  // 2.1 Master Sub-Menu
  const masterSubMenuListItems = masterSubMenu ? (
    <mui.List>
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "module_list") && (
          <Link
            to="/module"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "module");
              }}
              className={path === "module" ? styles.active : styles.inactive}
              button
              key="MasterSubMenuItem1"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.module")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "countries_list") && (
          <Link
            to="/country"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "country");
              }}
              className={path === "country" ? styles.active : styles.inactive}
              button
              key="MasterSubMenuItem2"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.country")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "states_list") && (
          <Link
            to="/state"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "state");
              }}
              className={path === "state" ? styles.active : styles.inactive}
              button
              key="MasterSubMenuItem3"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.state")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "district_list") && (
          <Link
            to="/district"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "district");
              }}
              className={path === "district" ? styles.active : styles.inactive}
              button
              key="MasterSubMenuItem4"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.district")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "cities_list") && (
          <Link
            to="/city"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "city");
              }}
              className={path === "city" ? styles.active : styles.inactive}
              button
              key="MasterSubMenuItem4"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.city")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "language_list") && (
          <Link
            to="/language"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "language");
              }}
              className={path === "language" ? styles.active : styles.inactive}
              button
              key="MasterSubMenuItem5"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.language")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "bloodgroup_list") && (
          <Link
            to="/blood-group"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "blood");
              }}
              className={
                path === "blood-group" ? styles.active : styles.inactive
              }
              button
              key="MasterSubMenuItem5"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.blood_group")} />
              </div>
            </mui.ListItem>
          </Link>
        )}

      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "role_list_admin") && (
          <Link
            to="/role"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "role");
              }}
              className={path === "role" ? styles.active : styles.inactive}
              button
              key="MasterSubMenuItem5"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.role")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "team_list") && (
          <Link
            to="/team"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "team");
              }}
              className={path === "team" ? styles.active : styles.inactive}
              button
              key="MasterSubMenuItem5"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.team")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
    </mui.List>
  ) : null;

  // 3. User List Menu
  const userListMenu = props.accessList.module !== undefined &&
    hasAccess("module", "User") &&
    localStorage.getItem("smdRole") === "admin" && (
      <Link
        to="/user-list"
        onClick={() => {
          toggleClose();
        }}
        style={{ textDecoration: "none", color: "#43425D" }}
      >
        <mui.ListItem
          onClick={(e) => {
            onChangeMenu(e, "user-list");
          }}
          className={path === "user-list" ? styles.active : styles.inactive}
          button
          key="User List"
          style={{ color: "#43425D" }}
        >
          <mui.ListItemIcon>
            <img src={images.userList} alt="User List" />
          </mui.ListItemIcon>
          <mui.ListItemText primary={t("sidebar.user_list")} />
        </mui.ListItem>
      </Link>
    );

  // 4. Organisation Menu
  const organisationMenu = props.accessList.module !== undefined &&
    hasAccess("module", "Organization") && (
      <Link
        to={
          localStorage.getItem("smdRole") === "admin"
            ? "/organization"
            : "/organization_main"
        }
        onClick={() => {
          toggleClose();
        }}
        style={{ textDecoration: "none", color: "#43425D" }}
      >
        <mui.ListItem
          onClick={(e) => {
            onChangeMenu(e, "Organisation");
          }}
          className={
            path === "organization" || path === "organization_main"
              ? styles.active
              : styles.inactive
          }
          button
          key="Organisation"
          title={t("sidebar.organisation")}
        >
          <mui.ListItemIcon>
            <img src={images.organisation_new} alt="Organisation" />
          </mui.ListItemIcon>
          <mui.ListItemText primary={t("sidebar.organisation")} />
        </mui.ListItem>
      </Link>
    );
  // 5. Properties
  const propertiesSubMenuListItems = propertiesSubMenu ? (
    <mui.List
      onClick={() => {
        toggleClose();
      }}
    >
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "org_propertyList") && (
          <Link
            to="/property-list"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "property");
              }}
              className={
                path === "property-list" ? styles.active : styles.inactive
              }
              button
              key="Organisation"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.property_list")} />
              </div>
            </mui.ListItem>
          </Link>
        )}

      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "category_list") && (
          <Link
            to="/category"
            onClick={() => {
              toggleClose();
            }}
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "category");
              }}
              className={path === "category" ? styles.active : styles.inactive}
              button
              key="Organisation"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.category_list")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "subcategory_list") && (
          <Link
            to="/subcategory"
            style={{ textDecoration: "none", color: "#43425D" }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "subcategory");
              }}
              className={
                path === "subcategory" ? styles.active : styles.inactive
              }
              button
              key="Organisation"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.sub_category_list")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
    </mui.List>
  ) : null;

  const propertiesMenu = props.accessList.module !== undefined &&
    hasAccess("module", "Operation") && (
      <mui.ListItem
        onClick={() => {
          toggleOpen();
          setPropertiesSubmenu(!propertiesSubMenu);
        }}
        button
        key="Properties"
        title={t("sidebar.properties")}
        className={
          path === "property-list" ||
          path === "category" ||
          path === "subcategory"
            ? styles.active
            : styles.inactive
        }
      >
        <mui.ListItemIcon>
          <img src={images.properties} alt="Properties" />
        </mui.ListItemIcon>
        <mui.ListItemText primary={t("sidebar.properties")} />
        <mui.IconButton style={{ outline: "none" }}>
          {propertiesSubMenu ? (
            <mui.KeyboardArrowDownIcon />
          ) : (
            <mui.ChevronRightIcon />
          )}
        </mui.IconButton>
      </mui.ListItem>
    );

  // 6. Operations Menu
  const operationSubMenuListItems = OperationSubMenu ? (
    <mui.List>
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "User_WorkHistory_head") && (
          <Link
            to="/histories"
            style={{ textDecoration: "none", color: "#43425D" }}
            onClick={() => {
              toggleClose();
            }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "user_history");
              }}
              className={path === "histories" ? styles.active : styles.inactive}
              button
              key="histories"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.history")} />
              </div>
            </mui.ListItem>
          </Link>
        )}

      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "job_list") && (
          <Link
            to="/operations/jobcreation"
            style={{ textDecoration: "none", color: "#43425D" }}
            onClick={() => {
              toggleClose();
            }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "job");
              }}
              className={
                path === "operations" ? styles.active : styles.inactive
              }
              button
              key="job"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.job")} />
              </div>
            </mui.ListItem>
          </Link>
        )}
      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "attendance_list") && (
          <Link
            to="/attendance"
            style={{ textDecoration: "none", color: "#43425D" }}
            onClick={() => {
              toggleClose();
            }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "attendance");
              }}
              className={
                path === "attendance" ? styles.active : styles.inactive
              }
              button
              key="attendance"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.attendance")} />
              </div>
            </mui.ListItem>
          </Link>
        )}

      {props.accessList.module !== undefined &&
        hasAccess("submoduleId", "package_list") && (
          <Link
            to="/package"
            style={{ textDecoration: "none", color: "#43425D" }}
            onClick={() => {
              toggleClose();
            }}
          >
            <mui.ListItem
              onClick={(e) => {
                onChangeMenu(e, "package");
              }}
              className={path === "package" ? styles.active : styles.inactive}
              button
              key="package"
            >
              <div className={styles.subMenuItems}>
                <mui.ListItemText primary={t("sidebar.package")} />
              </div>
            </mui.ListItem>
          </Link>
        )}

      <Link
        to="/inventory"
        style={{ textDecoration: "none", color: "#43425D" }}
        onClick={() => {
          toggleClose();
        }}
      >
        <mui.ListItem
          onClick={(e) => {
            onChangeMenu(e, "inventory");
          }}
          className={path === "inventory" ? styles.active : styles.inactive}
          button
          key="inventory"
        >
          <div className={styles.subMenuItems}>
            <mui.ListItemText primary={t("sidebar.inventory")} />
          </div>
        </mui.ListItem>
      </Link>
    </mui.List>
  ) : null;

  const operationsMenu = props.accessList.module !== undefined &&
    hasAccess("module", "Operation") && (
      <Link
        title={t("sidebar.operation")}
        style={{ textDecoration: "none", color: "#43425D" }}
      >
        <mui.ListItem
          onClick={onTab}
          button
          key="Operations"
          style={state.clicked ? style.notClick : style.onClicked}
          onClick={handleClick}
          onClick={() => {
            toggleOpen();
            setOperationSubmenu(!OperationSubMenu);
          }}
          className={
            path === "histories" ||
            path.includes("operations") ||
            path === "attendance" ||
            path === "package" ||
            path === "inventory"
              ? styles.active
              : styles.inactive
          }
        >
          <mui.ListItemIcon>
            <img src={images.operation} alt="Operations" />
          </mui.ListItemIcon>
          <mui.ListItemText primary={t("sidebar.operation")} />
          <mui.IconButton style={{ outline: "none" }}>
            {OperationSubMenu ? (
              <mui.KeyboardArrowDownIcon />
            ) : (
              <mui.ChevronRightIcon />
            )}
          </mui.IconButton>
        </mui.ListItem>
        {operationSubMenuListItems}
      </Link>
    );

  //7. Visualise Menu
  const visualiseMenu = props.accessList.module !== undefined && (
    // (hasAccess("submoduleId", "Property Visualise") || hasAccess("submoduleId", "Survey Visualize/Export") || hasAccess("submoduleId", "Dustbin Visualise") )&&

    <Link
      to="/visualise"
      onClick={() => {
        toggleClose();
      }}
      style={{ textDecoration: "none", color: "#43425D" }}
    >
      <mui.ListItem
        button
        key="Visualise"
        title={t("sidebar.visualise")}
        className={path === "visualise" ? styles.active : styles.inactive}
      >
        <mui.ListItemIcon>
          <img src={images.visualise} alt="Visualise" />
        </mui.ListItemIcon>
        <mui.ListItemText primary={t("sidebar.visualise")} />
      </mui.ListItem>
    </Link>
  );

  // 8.1 Payments&Taxation Menu Items
  const payTaxMenu = props.accessList.module !== undefined && (
    <mui.ListItem
      onClick={() => {
        setPayTaxSubMenu(!payTaxSubMenu);
        toggleOpen();
      }}
      button
      key="PayandTax"
      title={`Payments-Taxation`}
      className={
        path === "payments" || path === "taxation"
          ? styles.active
          : styles.inactive
      }
    >
      <mui.ListItemIcon>
        <img src={images.payments_taxation} alt="Payments and Taxation" />
      </mui.ListItemIcon>
      <mui.ListItemText primary={t("sidebar.tax_payment")} />
      <mui.IconButton style={{ outline: "none" }}>
        {payTaxSubMenu ? (
          <mui.KeyboardArrowDownIcon />
        ) : (
          <mui.ChevronRightIcon />
        )}
      </mui.IconButton>
    </mui.ListItem>
  );

  // 8.1 Payments&Taxation Menu Items

  const payTaxSubMenuListItems = payTaxSubMenu ? (
    <mui.List>
      {/* PAYMENTS */}
      {props.accessList.module !== undefined && (
        <Link
          to="/payments"
          onClick={() => {
            toggleClose();
            setPayTaxSubMenu(!payTaxSubMenu);
          }}
          style={{ textDecoration: "none", color: "#43425D" }}
        >
          <mui.ListItem
            onClick={(e) => {
              onChangeMenu(e, "/payments");
            }}
            className={path === "/payments" ? styles.active : styles.inactive}
            button
            key="Payments"
          >
            <div className={styles.subMenuItems}>
              <mui.ListItemText primary={t("sidebar.payments")} />
            </div>
          </mui.ListItem>
        </Link>
      )}

      {/* TAXATION */}
      {props.accessList.module !== undefined && (
        <Link
          to="/taxation"
          onClick={() => {
            toggleClose();
            setPayTaxSubMenu(!payTaxSubMenu);
          }}
          style={{ textDecoration: "none", color: "#43425D" }}
        >
          <mui.ListItem
            onClick={(e) => {
              onChangeMenu(e, "taxation");
            }}
            className={path === "/taxation" ? styles.active : styles.inactive}
            button
            key="Taxation"
          >
            <div className={styles.subMenuItems}>
              <mui.ListItemText primary={t("sidebar.taxations")} />
            </div>
          </mui.ListItem>
        </Link>
      )}
    </mui.List>
  ) : null;

  //9.0 Complain Management System
  const CMSMenu = (
    <Link
      to="/complain_system"
      onClick={() => {
        toggleClose();
      }}
      style={{ textDecoration: "none", color: "#43425D" }}
    >
      <mui.ListItem
        button
        key="complainSystem"
        title={`Complain Management System`}
        className={path === "complain_system" ? styles.active : styles.inactive}
      >
        <mui.ListItemIcon>
          <img src={images.CMS} alt="Complain Management System" />
        </mui.ListItemIcon>
        <mui.ListItemText primary={"Complain System"} />
      </mui.ListItem>
    </Link>
  );

  let currentOrg = localStorage.getItem("orgName");

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;

  //Component Return
  return (
    <div className={classes.root}>
      <mui.CssBaseline />
      <mui.AppBar position="fixed" className={mui.clsx(classes.appBar, {})}>
        <mui.Toolbar style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
          <Link to ="/dashboard" style={{textDecoration:"none"}}>
            <div className={styles.tagLine}>
              <img
                class="logo-img"
                src={images.Logo}
                alt="logo"
                style={{
                  marginRight: "10%",
                  align: "left",
                  marginLeft: "-5%",
                }}
              />
              <mui.Typography
                style={{ color: "black", fontSize: "0.55rem" }}
                variant="caption"
              >
                Made with Love in Bharat
              </mui.Typography>
            </div>
          </Link>

            <mui.IconButton
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              className={mui.clsx(classes.menuButton)}
              style={{ outline: "none" }}
            >
              {toggleIcon}
            </mui.IconButton>
          </div>
          {/* {url.path.split("/")[1] === "operations" && <OperationTab />} */}
          <Box
            display={{
              xs: "block",
              sm: "block",
              md: "block",
              lg: "none",
              xl: "none",
            }}
            className={[styles.topbarRightIcon]}
          >
            <mui.Button
              aria-label="more"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenMenu}
            >
              <MoreVertIcon />
            </mui.Button>
            <Menu
              className={classes.rightMenu}
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem>
                <mui.Badge
                  title="Notifications"
                  variant="dot"
                  style={{
                    color: "#000",
                    display: "inherit",
                  }}
                >
                  <mui.NotificationsNoneIcon
                    style={{
                      color: "#BCBCCB",
                      cursor: "pointer",
                      outline: "none",
                      margin: "auto",
                      // borderRight: "2px solid rgb(239, 238, 238)",
                      height: "20px",
                    }}
                    title="Notifications"
                  />
                  Notifications
                </mui.Badge>
              </MenuItem>
              <MenuItem style={{ overflow: "unset" }}>
                {localStorage.getItem("smdRole") !== "admin" &&
                  localStorage.getItem("role") !== "undefined" && (
                    <div
                      style={{ marginLeft: "0px" }}
                      className={styles.profile}
                    >
                      <button
                        className={styles.avatar}
                        onClick={toggleOrg}
                        onChange={handleChangeOrg}
                      >
                        {localStorage.getItem(currentOrg) !== "undefined" ? (
                          <mui.Avatar
                            src={localStorage.getItem(currentOrg)}
                            className={classes.orange}
                            style={{
                              margin: "auto",
                              textTransform: "capitalize",
                            }}
                          ></mui.Avatar>
                        ) : (
                          <mui.Avatar
                            className={classes.orange}
                            style={{
                              margin: "auto",
                              textTransform: "capitalize",
                            }}
                          >
                            {currentOrg.split("")[0].toUpperCase()}
                          </mui.Avatar>
                        )}
                        <p className={styles.avatarName}>
                          {props.allOrgList.length > 0 &&
                            localStorage.getItem("orgName")}
                        </p>
                        <svg
                          className="mdi-icon topbar__icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                        </svg>
                        {/* <span className={styles.hr}></span> */}
                      </button>
                      {collapseOrg && (
                        <button
                          className={styles.topBarBack}
                          type="button"
                          onClick={toggleOrg}
                        />
                      )}
                      <Collapse
                        isOpen={collapseOrg}
                        className={styles.topBarMenuWrap}
                      >
                        <div className={styles.topBarMenu}>
                          {props.allOrgList.length > 0 &&
                            props.allOrgList.map((org, idx) => (
                              <Link
                                onClick={(e) => {
                                  onChangeOrg(e, org);
                                }}
                                key={idx}
                                title={org.orgName}
                                style={{ textDecoration: "none" }}
                                className={`${styles.tobBarLinik} ${styles.noHover}`}
                              >
                                <p className={style.tobBarLinkTitle}>
                                  {org.orgName.length > 7
                                    ? org.orgName.substring(0, 7) + ".."
                                    : org.orgName}
                                </p>
                              </Link>
                            ))}
                        </div>
                      </Collapse>
                    </div>
                  )}
              </MenuItem>
              <MenuItem style={{ overflow: "unset" }}>
                <div style={{ marginLeft: "0px" }} className={styles.profile}>
                  <button className={styles.avatar} onClick={toggle}>
                    {localStorage.getItem("image") !== "undefined" ? (
                      <mui.Avatar
                        src={localStorage.getItem("image")}
                        className={classes.orange}
                        style={{ margin: "auto", textTransform: "capitalize" }}
                      ></mui.Avatar>
                    ) : (
                      <mui.Avatar
                        className={classes.orange}
                        style={{ margin: "auto", textTransform: "capitalize" }}
                      >
                        {localStorage
                          .getItem("firstname")
                          .split("")[0]
                          .toUpperCase()}
                        {localStorage
                          .getItem("lastname")
                          .trim()
                          .split("")[0]
                          .toUpperCase()}
                      </mui.Avatar>
                    )}
                    <p className={styles.avatarName}>
                      {localStorage.getItem("firstname")}{" "}
                      {localStorage.getItem("lastname")}
                    </p>
                    <svg
                      className="mdi-icon topbar__icon"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      style={{ borderRight: "2px solid #efeeee" }}
                    >
                      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                    </svg>
                  </button>
                  {collapse && (
                    <button
                      className={styles.topBarBack}
                      type="button"
                      onClick={toggle}
                    />
                  )}
                  <Collapse isOpen={collapse} className={styles.topBarMenuWrap}>
                    <div className={styles.topBarMenu}>
                      <Link
                        style={{
                          textDecoration: "none",
                          borderBottom: "2px solid #f1f1f1",
                        }}
                        className={styles.tobBarLinik}
                        onClick={toggle}
                        to="/profile"
                      >
                        <span className={styles.tobBarLinkIcon}>
                          {" "}
                          <mui.PersonOutlinedIcon
                            style={{ color: "#707070", marginTop: "-18%" }}
                          />
                        </span>
                        <p className={styles.tobBarLinkTitle}>
                          {t("sidebar.profile")}
                        </p>
                      </Link>
                      <Link
                        style={{ textDecoration: "none" }}
                        className={styles.tobBarLinik}
                        onClick={(e) => {
                          logoutUser();
                        }}
                      >
                        <span
                          className={styles.tobBarLinkIcon}
                          style={{ marginRight: "13px" }}
                        >
                          <img
                            src={images.logout}
                            style={{ paddingLeft: "24%" }}
                            alt="logout"
                          />
                        </span>
                        <p className={styles.tobBarLinkTitle}>
                          {t("sidebar.log_out")}
                        </p>
                      </Link>
                    </div>
                  </Collapse>
                </div>
              </MenuItem>

              <MenuItem style={{ overflow: "unset" }}>
                <div
                  className={`${styles.topBarCollapse} ${styles.topBarCollapseLng}`}
                >
                  <button
                    className={styles.tobBarButton}
                    type="button"
                    onClick={toggleLng}
                  >
                    {mainButtonContent}
                    <svg
                      className="mdi-icon topbar__icon"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                    </svg>
                  </button>
                  {collapseLng && (
                    <button
                      className={styles.topBarBack}
                      type="button"
                      onClick={toggleLng}
                    />
                  )}
                  <Collapse
                    isOpen={collapseLng}
                    className={`${styles.tobBarContentCollapse}  ${styles.tobBarClsContentLgn}`}
                  >
                    <button
                      className={styles.topBarLngBtn}
                      type="button"
                      onClick={() => changeLanguage("en")}
                      style={{ marginBottom: "5px" }}
                    >
                      <GbLng />
                    </button>
                    <button
                      className={styles.topBarLngBtn}
                      type="button"
                      onClick={() => changeLanguage("hi")}
                      style={{ marginBottom: "5px" }}
                    >
                      <HiLng />
                    </button>

                    <button
                      className={styles.topBarLngBtn}
                      type="button"
                      onClick={() => changeLanguage("mr")}
                      style={{ marginBottom: "5px" }}
                    >
                      <MrLng />
                    </button>
                  </Collapse>
                </div>
              </MenuItem>
              {/* <MenuItem onClick={handleCloseMenu}></MenuItem> */}
            </Menu>
          </Box>
          <Box
            display={{ xs: "none", sm: "none", md: "none", lg: "flex" }}
            className={styles.topbarRight}
          >
            <mui.Badge
              title="Notifications"
              variant="dot"
              style={{
                color: "#000",
              }}
            >
              <mui.NotificationsNoneIcon
                style={{
                  color: "#BCBCCB",
                  cursor: "pointer",
                  outline: "none",
                  margin: "auto",
                  // borderRight: "2px solid rgb(239, 238, 238)",
                  height: "20px",
                }}
                title="Notifications"
              />
              <span className={styles.hr}></span>
            </mui.Badge>

            {localStorage.getItem("smdRole") !== "admin" &&
              localStorage.getItem("role") !== "undefined" && (
                <div style={{ marginLeft: "0px" }} className={styles.profile}>
                  <button
                    className={styles.avatar}
                    onClick={toggleOrg}
                    onChange={handleChangeOrg}
                  >
                    {localStorage.getItem(currentOrg) !== "undefined" ? (
                      <mui.Avatar
                        src={localStorage.getItem(currentOrg)}
                        className={classes.orange}
                        style={{ margin: "auto", textTransform: "capitalize" }}
                      ></mui.Avatar>
                    ) : (
                      <mui.Avatar
                        className={classes.orange}
                        style={{ margin: "auto", textTransform: "capitalize" }}
                      >
                        {currentOrg.split("")[0].toUpperCase()}
                      </mui.Avatar>
                    )}
                    <p className={styles.avatarName}>
                      {props.allOrgList.length > 0 &&
                        localStorage.getItem("orgName")}
                    </p>
                    <svg
                      className="mdi-icon topbar__icon"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                    </svg>
                  </button>
                  {collapseOrg && (
                    <button
                      className={styles.topBarBack}
                      type="button"
                      onClick={toggleOrg}
                    />
                  )}
                  <Collapse
                    isOpen={collapseOrg}
                    className={styles.topBarMenuWrap}
                  >
                    <div className={styles.topBarMenu}>
                      {props.allOrgList.length > 0 &&
                        props.allOrgList.map((org, idx) => (
                          <Link
                            onClick={(e) => {
                              onChangeOrg(e, org);
                            }}
                            key={idx}
                            title={org.orgName}
                            style={{ textDecoration: "none" }}
                            className={`${styles.tobBarLinik} ${styles.noHover}`}
                          >
                            <p className={style.tobBarLinkTitle}>
                              {org.orgName.length > 7
                                ? org.orgName.substring(0, 7) + ".."
                                : org.orgName}
                            </p>
                          </Link>
                        ))}
                    </div>
                  </Collapse>
                </div>
              )}

            <div style={{ marginLeft: "0px" }} className={styles.profile}>
              <button className={styles.avatar} onClick={toggle}>
                {localStorage.getItem("image") !== "undefined" ? (
                  <mui.Avatar
                    src={localStorage.getItem("image")}
                    className={classes.orange}
                    style={{ margin: "auto", textTransform: "capitalize" }}
                  ></mui.Avatar>
                ) : (
                  <mui.Avatar
                    className={classes.orange}
                    style={{ margin: "auto", textTransform: "capitalize" }}
                  >
                    {localStorage
                      .getItem("firstname")
                      .split("")[0]
                      .toUpperCase()}
                    {localStorage
                      .getItem("lastname")
                      .trim()
                      .split("")[0]
                      .toUpperCase()}
                  </mui.Avatar>
                )}
                <p className={styles.avatarName}>
                  {localStorage.getItem("firstname")}{" "}
                  {localStorage.getItem("lastname")}
                </p>
                <svg
                  className="mdi-icon topbar__icon"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  style={{ borderRight: "2px solid #efeeee" }}
                >
                  <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                </svg>
              </button>
              {collapse && (
                <button
                  className={styles.topBarBack}
                  type="button"
                  onClick={toggle}
                />
              )}
              <Collapse isOpen={collapse} className={styles.topBarMenuWrap}>
                <div className={styles.topBarMenu}>
                  <Link
                    style={{
                      textDecoration: "none",
                      borderBottom: "2px solid #f1f1f1",
                    }}
                    className={styles.tobBarLinik}
                    onClick={toggle}
                    to="/profile"
                  >
                    <span className={styles.tobBarLinkIcon}>
                      {" "}
                      <mui.PersonOutlinedIcon
                        style={{ color: "#707070", marginTop: "-18%" }}
                      />
                    </span>
                    <p className={styles.tobBarLinkTitle}>
                      {t("sidebar.profile")}
                    </p>
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    className={styles.tobBarLinik}
                    onClick={(e) => {
                      logoutUser();
                    }}
                  >
                    <span
                      className={styles.tobBarLinkIcon}
                      style={{ marginRight: "13px" }}
                    >
                      <img
                        src={images.logout}
                        style={{ paddingLeft: "24%" }}
                        alt="logout"
                      />
                    </span>
                    <p className={styles.tobBarLinkTitle}>
                      {t("sidebar.log_out")}
                    </p>
                  </Link>
                </div>
              </Collapse>
            </div>

            {/* lang section */}

            <div
              className={`${styles.topBarCollapse} ${styles.topBarCollapseLng}`}
            >
              <button
                className={styles.tobBarButton}
                type="button"
                onClick={toggleLng}
              >
                {mainButtonContent}
                <svg
                  className="mdi-icon topbar__icon"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
                </svg>
              </button>
              {collapseLng && (
                <button
                  className={styles.topBarBack}
                  type="button"
                  onClick={toggleLng}
                />
              )}
              <Collapse
                isOpen={collapseLng}
                className={`${styles.tobBarContentCollapse}  ${styles.tobBarClsContentLgn}`}
              >
                <button
                  className={styles.topBarLngBtn}
                  type="button"
                  onClick={() => changeLanguage("en")}
                >
                  <GbLng />
                </button>
                <button
                  className={styles.topBarLngBtn}
                  type="button"
                  onClick={() => changeLanguage("hi")}
                >
                  <HiLng />
                </button>

                <button
                  className={styles.topBarLngBtn}
                  type="button"
                  onClick={() => changeLanguage("mr")}
                >
                  <MrLng />
                </button>
              </Collapse>
            </div>
          </Box>
        </mui.Toolbar>
      </mui.AppBar>
      <mui.Drawer
        style={{
          boxShadow: "8px -8px 15px #00000024",
        }}
        variant="permanent"
        className={mui.clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: mui.clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}></div>

        <mui.List>
          {/* ORGANIZATION MENU */}
          {Dashboard}
          {surveyMenu}
          {surveySubMenuListItems}

          {/* {samadhanIdMenu} */}
          {samadhanSubMenuListItems}

          {masterMenu}

          {masterSubMenuListItems}

          {userListMenu}

          {organisationMenu}

          {operationsMenu}

          {propertiesMenu}
          {propertiesSubMenuListItems}

          {visualiseMenu}

          {/* {payTaxMenu} */}
          {/* {payTaxSubMenuListItems} */}

          {/* {CMSMenu} */}
        </mui.List>
      </mui.Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.page}
      </main>
    </div>
  );
}

const mapStateToPorps = (state) => {
  return {
    allOrgList: state.Organization.allOrgList,
    accessList: state.Organization.accessList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserorgList: (data) => dispatch(actions.getUserOrgList(data)),
    getAccessControl: (data) => dispatch(actions.getAccess(data)),
    logout: () => dispatch(actions.logout()),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToPorps, mapDispatchToProps)
)(MiniDrawer);
