/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {Link, useHistory} from "react-router-dom";

import styles from "./AuditJob.module.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import back from "./back.png";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import { blue, grey } from "@material-ui/core/colors";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import * as acitons from '../../../../../store/actions/index'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation, useTranslation } from "react-i18next";
import { Breadcrumbs } from "@material-ui/core";
const useStyles = makeStyles({
	root: {
		maxWidth: "45%",
		minWidth: "40%",
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: "10",
	},
	select: {
		minWidth: "7.5vw",
		"@media (min-width: 320px) and (max-width: 375px)": {
			minWidth: "25vw",
		},
		"@media (min-width: 376px) and (max-width: 425px)": {
			minWidth: "25vw",
		},
		"@media (min-width: 426px) and (max-width: 768px)": {
			minWidth: "12vw",
		},
		"@media (min-width: 769px) and (max-width: 1024px)": {
			minWidth: "10vw",
		},
		background: "white",
		color: grey[700],
		borderColor: "#D7DAE2",
		borderStyle: "solid",
		borderWidth: "2px",
		borderRadius: "4px",
		paddingLeft: "5px",
		paddingTop: "2px",
		paddingBottom: "2px",
		fontSize: "13px",
		"&:hover": {
			borderColor: grey[400],
		},
		"&:focus": {
			borderRadius: "4px",
			background: "white",
			borderColor: blue[200],
		},
	},
	icon: {
		color: grey[500],
		right: 12,
		position: "absolute",
		userSelect: "none",
		pointerEvents: "none",
	},
	list: {
		paddingTop: 0,
		paddingBottom: 0,
		background: "white",
		color: "#4d4f5c",
		fontSize: "smaller",
		"& li.Mui-selected": {
			fontWeight: 400,
		},
	},
	input: {
		color: "#4D4F5C",
		fontSize: "smaller",
	},
});


export  function Audit(props) {
	const  history  = useHistory()
	const [t] = useTranslation('common');

	const borderSelectClasses = useBorderSelectStyles();
	const menuProps = {
		classes: {
			list: borderSelectClasses.list,
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left",
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left",
		},
		getContentAnchorEl: null,
	};


	const iconComponent = (props) => {
		return (
			<ExpandMoreIcon
				className={props.className + " " + borderSelectClasses.icon}
			/>
		);
	};
	const classes = useStyles();
	const [state, setState] = React.useState({
		value: 'audit',
		name: "hai",
		day:"today",
		startDate: new Date().toISOString().slice(0, 10),
		endDate: new Date().toISOString().slice(0, 10),
		endDate1:new Date().toISOString().split('T')[0],

	});
	// handle change job reporting type
	const handleChange = (event) => {
		history.push(`${event.target.value}`)
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
	};

const handleInputs = (event) => {
		
	const name = event.target.name;
	
	const addDays=(theDate, days)=> {
		return new Date(theDate.getTime() + days*24*60*60*1000);
	}
	var startDate =''; var endDate='';
	if(event.target.name==='day' &&event.target.value==='yesterday'){
	var newDate = addDays(new Date(), -1).toISOString().slice(0, 10);
	 startDate=newDate;
	 endDate=newDate;
	 setState({
		...state,
		[name]: event.target.value, startDate:newDate, endDate:newDate,

	});
	 }
	if (event.target.name === 'day' && event.target.value === 'today') {
		newDate = new Date().toISOString().slice(0, 10);
		startDate = newDate;
		endDate = newDate;
		setState({
			...state,
			[name]: event.target.value, startDate:newDate, endDate:newDate,
	
		});
	}
	if (event.target.name ==='startDate') {
		newDate =event.target.value;
		startDate = event.target.value;
		endDate = state.endDate===''?event.target.value:state.endDate
		setState({
			...state,
			[name]: event.target.value, startDate:startDate, endDate:endDate,
	
		});
	}
	if (event.target.name ==='endDate') {
		newDate =event.target.value;
		startDate =state.startDate===''?event.target.value:state.startDate
		endDate =event.target.value;

		setState({
			...state,
			[name]: event.target.value, startDate:startDate, endDate:endDate,
	
		});
	}
	props.getAuditCardList(localStorage.getItem('orgId',), startDate, endDate)
};


useEffect(() => {
	let today=new Date().toISOString().slice(0, 10);
props.getAuditCardList(localStorage.getItem('orgId'), today, today,)

}, [])

const backDrop = props.loading ? (
	<Backdrop style={{ zIndex: 1204 }} className={classes.backdrop} open={props.loading}>
		<CircularProgress color="inherit" />
	</Backdrop>
) : null
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.header}>
				<div className={styles.title}>
					<Breadcrumbs seperator="›" aria-label="breadcrumb">
						<Typography color="textPrimary">{t("init_end_day.operation")}</Typography>
						<Typography color="textSecondary">{t("init_end_day.job_reporting")}</Typography>
					</Breadcrumbs>
				</div>
				<div className={styles.dropdownMain}>
				<FormControl>
			<Select
				disableUnderline
				labelId="inputLabel"
				placeholder={'QC'}
				IconComponent={iconComponent}
				className={classes.select}
				MenuProps={menuProps}
				value={state.value}
				onChange={handleChange}
				style={{
					marginRight: "2%",
				}}
			>
				<MenuItem value={'audit'}> {t("init_end_day.audit")} </MenuItem>{" "}
				<MenuItem value={'/operations/jobinitialisation'}> {t("init_end_day.initialisation")} </MenuItem>{" "}
				<MenuItem value={'/operations/jobinstallation'} >   {t("init_end_day.installation")} </MenuItem>{" "}
				<MenuItem value={'/operations/jobqc'}>  {t("init_end_day.qc")}  </MenuItem>{" "}
				<MenuItem value={'/operations/work-history'} >  {t("init_end_day.user_work_history")} </MenuItem>{" "}

			</Select>
		</FormControl>
				</div>
			</div>
		
			
			<div className={styles.header2}>
				<span className={styles.subTitle}>{t("audit_report.audit")}</span>
				<div className={styles.dropdown2}>
				<FormControl>
			<Select
				disableUnderline
				labelId="inputLabel"
				placeholder={'Today'}
				IconComponent={iconComponent}
				className={classes.select}
				MenuProps={menuProps}
				value={state.day}
				onChange={handleInputs}
				name='day'
				style={{
					marginRight: "2%",
				}}
			>
				<MenuItem value={'today'}>  {t("init_end_day.today")} </MenuItem>{" "}
				<MenuItem value={'yesterday'}> {t("init_end_day.yesterday")} </MenuItem>{" "}
		
			</Select>
		</FormControl>
				</div>
				<div className={styles.date}>
					<span className={styles.label}>{t("init_end_day.from_date")}</span>
					<TextField
						id="standard-search"
						size="small"
						type="date"
						variant="outlined"
						name='startDate'
						value={state.startDate}
						onChange={handleInputs}
						className={styles.date_picker}
						InputProps={{
							classes: { input: classes.input },
							color: "#4D4F5C",
							focused: classes.focused,
						}}
					/>
					<span className={styles.label}>{t("init_end_day.to_date")}</span>
					<TextField
						id="standard-search"
						size="small"
						type="date"
						name='endDate'
						value={state.endDate}
						onChange={handleInputs}
						variant="outlined"
						className={styles.date_picker}
						InputProps={{
							classes: { input: classes.input },
							color: "#4D4F5C",
							focused: classes.focused,
						}}
					/>
				</div>
			</div>
			<div className={styles.cards}>
				<Card className={styles.card}>
					<CardContent>
						<Typography
							className={classes.pos}
							style={{
								color: "#03E565",
								textAlign: "center",
								fontSize: "xx-large",
								fontWeight: "bold",
								paddingBottom: "5%",
							}}
						>
					 {props.auditCardList.reattempt_pending&&props.auditCardList.reattempt_pending.toString().length>4?(props.auditCardList.reattempt_pending.toString().substring(0,2)+"K+"):(props.auditCardList.reattempt_pending)}

						</Typography>
					</CardContent>
					<Typography
						style={{
							color: "#BBBBBB",
							textAlign: "center",
							fontSize: "110%",
						}}
						gutterBottom
					>
						{t("audit_report.reattempt_property")}
					</Typography>
				</Card>
				<Card className={styles.card}>
					<CardContent>
						<Typography
							className={classes.pos}
							style={{
								color: "#FF6060",
								textAlign: "center",
								fontSize: "xx-large",
								fontWeight: "bold",
								paddingBottom: "5%",
							}}
						>
						{props.auditCardList.property_sent_for_reattempt&&props.auditCardList.property_sent_for_reattempt.toString().length>4?(props.auditCardList.property_sent_for_reattempt.toString().substring(0,2)+"K+"):(props.auditCardList.property_sent_for_reattempt)}

						</Typography>
					</CardContent>
					<Typography
						style={{
							color: "#BBBBBB",
							textAlign: "center",
							alignItems: "center",
							fontSize: "110%",
						}}
						gutterBottom
					>
							{t("audit_report.total_pending")}
					</Typography>
				</Card>
			</div>
			<div className={styles.final}>
			<Link style={{textDecoration:"none"}} to ='/operations/audit-report-table'>	<span
					className={styles.label}
					style={{
						fontWeight: "bold",
						cursor: "pointer",
						fontSize: "large",
					}}
				>
					{t("audit_report.view_details")}&nbsp;
				</span></Link>
				<img src={back} alt ="back" />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	
	return {
		
		auditCardList:state.Operation.auditcardList,
		loading: state.Operation.loading,
		


	}
}

const mapDispatchToProps = (dispatch) => {
	
	return {
		getAuditCardList: (orgId, startDate, endDate) =>
			dispatch(acitons.getAuditCardList(orgId, startDate, endDate)),
			// downloadExcelFile :(orgId, jobType)=>
			// dispatch(acitons.downloadExcel(orgId, jobType)),
		// getSubCategoryByCategory: (id) =>
		// 	dispatch(acitons.getSubCategoryByCategory(id)),
		// getOrgUser: (id) =>
		// 	dispatch(acitons.getOrgUser(id)),
		// addJob: (data) =>
		// 	dispatch(acitons.addJob(data))
	}
}


export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(Audit)

