/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as actions from "../../../../../store/actions/index";
import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";

import * as mui from "../../../../../shared/mui";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./TaxHistory.module.css";

// import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

import { apiUrl } from "../../../../../constants/constants";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
  },
}));

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "content-type": "application/json",
    Accept: "Application/json",
    Authorization: `${localStorage.getItem("token")}`,
  },
});



export function TaxHistory(props) {
  const classes = useStyles();
  const [isBulkAddAsset, setBulkAddAsset] = useState(false);

  const [state, setState] = useState({
    status: "",
    search: "",
    title: "",
    name: "",
    id: "",
    isDelete: true,
    isAdd: false,
    isUpdate: false,
  });
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);
  const [t] = useTranslation("common");
  const [assetAddModalOpen, setAssetAddModalOpen] = useState(false);

  const [activeTaxation, setActiveTaxation] = useState("")

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [samadhanId, setSamadhanId] = useState("");
  const [govtPropId, setGovtPropId] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [remainingAmount, setRemainingAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const toggle = (id) => {
    setActiveTaxation(id);
    setDropdownOpen(!dropdownOpen);
  };

  console.log(govtPropId,paidAmount,remainingAmount,totalAmount)

  useEffect(() => {
    let orgId = localStorage.getItem("orgId");
    props.getTaxation(1, props.limit, orgId, search, samadhanId, state.propertyId, state.govtPropertyId, state.paidAmount, state.totalAmount, state.gtLtPaid, state.gtLtTotal);
  }, [search]);
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    console.log(name, value)

    if (name === "search") {
      setSearch(value)
    }
    else if (name === "samadhanId") {
      setSamadhanId(value)
    }
    else if (name === "govtPropId") {
      setGovtPropId(value)
    }
    else if (name === "propertyId") {
      setPropertyId(value)
    }
    else if (name === "paidAmount") {
      setPaidAmount(value)
    }
    else if (name === "remainingAmount") {
      setRemainingAmount(value)
    }
    else if (name === "totalAmount") {
      setTotalAmount(value)
    } else {
      setState({
        ...state,
        [name]: event.target.value,
      });
    }
  };

  // let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  const onSearchTaxation = (e) => {
    props.getTaxation(
      props.page,
      props.limit,
      localStorage.getItem("orgId"),
      search,
      samadhanId,
      propertyId,
      state.govtPropertyId,
      state.totalAmount,
      state.paidAmount,
      state.gtLtPaid,
      state.gtLtTotal,
    );
  };

  const handleChangePage = (event, page) => {
    props.getTaxation(
      page,
      props.limit,
      localStorage.getItem("orgId"),
      search,
      state.samadhanId,
      state.propertyId,
      state.govtPropertyId,
      state.totalAmount,
      state.paidAmount,
      state.gtLtPaid,
      state.gtLtTotal,
    );
  };
  // OPEN DELETE
  const toggleModal = (data) => {
    setModal(!modal);
    setState({
      ...state,
      name: data.ownerName,
      btnTitle: t("property_list.delete"),
      search: "",
      id: data._id,
      title: t("property_list.delete_title"),
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });

    setCurrentStatus(false);
  };

  const toggleBulkAddAsset = () => {
    setBulkAddAsset(!isBulkAddAsset);
  };
  /// handle submit
  const handleSubmit = (e) => {
    const name = e.currentTarget.name;
    e.preventDefault();
    const assetApi = apiUrl + "asset/add";
    if (name === "bulkAssetSubmit") {
      instance
        .request({
          method: "POST",
          url: assetApi,
          data: {
            organisationId: localStorage.getItem("orgId"),
          },
        })
        .then(function (response) {
          if (response.status === 200 && response.data.code === 200) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })

        .catch(function (error) {
          console.log(error, "errror");
        }); //axios-end
      toggleBulkAddAsset();
    } else if (name === "singleAssetSubmit") {
      setAssetAddModalOpen(!assetAddModalOpen);
      instance
        .request({
          method: "POST",
          url: assetApi,
          data: {
            organisationId: localStorage.getItem("orgId"),
          },
        })
        .then(function (response) {
          if (response.status === 200 && response.data.code === 200) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })

        .catch(function (error) {
          console.log(error, "errror");
        }); //axios-end
    } else {
      setCurrentStatus(true);
      state.orgId = localStorage.getItem("orgId");
      props.addUpdateDeleteProperty(state);
    }
  };


  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    } else {
    }
  }); 

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <mui.Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };
  // ENABLE LOADING
  const backDrop = props.loading ? (
    <mui.Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;


  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <Breadcrumbs seperator="›" aria-label="breadcrumb">
            <Typography color="textPrimary">{t("tax_hist.pay_tax")}</Typography>
          <Link to="/taxation">
            <Typography color="textPrimary">{t("tax_hist.taxations")}</Typography>
            </Link>
            <Typography color="textSecondary">{t("tax_hist.tax_hist")}</Typography>
          </Breadcrumbs>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <mui.TextField
                  id="standard-search"
                  size="small"
                  name="search"
                  value={state.search}
                  onChange={handleChange}
                  type="search"
                  variant="outlined"
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    // marginBottom: "-35%",
                  }}
                  InputProps={{
                    // startAdornment: icon,
                    placeholder: t("property_list.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
            </div>

            <div className={styles.dropDownDiv}>
              <mui.Button
                variant="contained"
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  borderRadius: "20px",

                  textTransform: "none",
                  width: "60%",
                  fontWeight: "lighter",
                  marginBottom: "3%",
                  height: "70%",
                }}
                onClick={onSearchTaxation}
              >
                {t("tax_hist.search")}
              </mui.Button>
            </div>
          </div>

        </div>

        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            <p>
              {t("property_list.delete_msg")} <strong>{state.name}</strong> ?{" "}
            </p>
          </ModalBody>
          <ModalFooter>
            <mui.Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              style={{ marginRight: "2%" }}
            >
              {t("property_list.cancel")}
            </mui.Button>
            <mui.Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </mui.Button>
          </ModalFooter>
        </Modal>
        <div className={styles.table}>
          <mui.TableContainer component={mui.Paper}>
            <mui.Table className={classes.table} aria-label="simple table">
            <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <mui.TableRow>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                  <input className = {styles.searchbox} name="search" onChange={handleChange} placeholder={t("tax_hist.search_owner")} title ="Search owner name"/>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    <input className = {styles.searchbox} name="samadhanId" onChange={handleChange} type="text" placeholder={t("tax_hist.search_smd_id")} title = "Search Samadhan Id"/>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                  <input className = {styles.searchbox} name="govtPropId" onChange={handleChange} placeholder={t("tax_hist.search_govtProp_id")} title ="Search Govt. Property Id"/>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    <input className = {styles.searchbox} name="propertyId" onChange={handleChange} placeholder={t("tax_hist.search_prop_id")} title ="search property Id"/>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                  <input type ="number" className = {styles.searchbox} name="paidAmount" onChange={handleChange} placeholder={t("tax_hist.enter_paid_amt")} title = "search paid amount"/>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                  <input type ="number" className = {styles.searchbox} name="remainingAmount" onChange={handleChange} placeholder={t("tax_hist.enter_rem_amt")} title="search remaining amount"/>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                  <input  type ="number" className = {styles.searchbox} name="totalAmount" onChange={handleChange} placeholder={t("tax_hist.enter_tot_amt")} title="search total amount"/>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    <input type ="date" className = {styles.searchbox} title="search by createdAt date"/>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                  <input type ="date" className = {styles.searchbox} title = "search by updatedAt date"/>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.tax_op")}
                  </mui.TableCell>
                </mui.TableRow>
                <mui.TableRow>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.prop_owner")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.smd_id")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.govt_prop_id")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.prop_id")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.paid_amt")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.rem_amt")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.tot_amt")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.create_date")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.last_update_at")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("tax_hist.tax_op")}
                  </mui.TableCell>
                </mui.TableRow>
             
              </mui.TableHead>
              <mui.TableBody>
                {props.taxationList && props.taxationList.length > 0 &&
                  props.taxationList.map((tax) => (
                    <mui.TableRow key={tax._id}>
                      <mui.TableCell align="center">
                        {`${tax.ownerName}`}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {`${tax.samadhanId}`}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {`${tax.govtPropertyId}`}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {`${tax.propertyId}`}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {`${tax.paidAmount}`}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {`${tax.remainingAmount}`}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {`${tax.totalAmount}`}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {`${new Date().toJSON().slice(0,10)}`}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {`${new Date().toJSON().slice(0,10)}`}
                      </mui.TableCell>

                      <mui.TableCell>
                        <mui.Dropdown
                          isOpen={
                            dropdownOpen && tax._id === activeTaxation
                          }
                          size="sm"
                          toggle={() => toggle(tax._id)}
                          id={tax._id}
                        >
                          <mui.DropdownToggle caret>Dropdown</mui.DropdownToggle>
                          <mui.DropdownMenu>


                            <mui.DropdownItem
                              onClick={() => {
                                toggle(tax._id)
                              }}
                            >
                              <Link
                                to={{
                                  pathname: "/make_payments",
                                  state: {
                                    samadhanId: tax.samadhanId,
                                    propertyId: tax.propertyId,
                                  }
                                }}
                                style={{ textDecoration: "none", color: "#43425D" }}
                              >
                                {t("tax_hist.make_pay")}
                                </Link>
                            </mui.DropdownItem>


                            <mui.DropdownItem
                              onClick={() => {
                                toggle(tax._id)
                              }}
                            >
                              <Link
                                to={{
                                  pathname: "/more_details/tax_history",
                                  state: tax,
                                }}
                                style={{ textDecoration: "none", color: "#43425D" }}
                              >
                                {t("tax_hist.more_det")}
                                </Link>
                            </mui.DropdownItem>

                          </mui.DropdownMenu>
                        </mui.Dropdown>
                      </mui.TableCell>
                    </mui.TableRow>
                  ))}
              </mui.TableBody>
            </mui.Table>
            {props.taxationList && props.taxationList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {t("property_list.no_record_found")}
              </p>
            )}
          </mui.TableContainer>

        </div>
      </div>
      <div>{props.taxationList && props.taxationList.length > 0 && setPage()}</div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    taxationList: state.Taxation.taxationList,
    loading: state.Taxation.loading,
    page: state.Taxation.page,
    limit: state.Taxation.limit,
    total: state.Taxation.total,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTaxation: (page, limit, orgId, search, samadhanId, propertyId, govtPropertyId, paidAmount, totalAmount, gtLtPaid, gtLtTotal) =>
      dispatch(actions.getTaxation(page, limit, orgId, search, samadhanId, propertyId, govtPropertyId, paidAmount, totalAmount, gtLtPaid, gtLtTotal)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(TaxHistory);
