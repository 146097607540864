/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as actions from "../../../../../store/actions/index";
import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";

import * as mui from "../../../../../shared/mui";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./PayHistory.module.css";

import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { makeStyles } from "@material-ui/core/styles";

import { apiUrl } from "../../../../../constants/constants";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment"
import * as helper from "../../../../../shared/helper"
import { Link } from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
  },
}));

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "content-type": "application/json",
    Accept: "Application/json",
    Authorization: `${localStorage.getItem("token")}`,
  },
});


const selectStyles = mui.makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function PayHistory(props) {
  const classes = useStyles();
  const selectClasses = selectStyles();
  // const [addOpen, setAddOpen] = useState(false);
  // const [downloadOpen, setDownloadOpen] = useState(false);
  const [noOfCards, setCards] = useState(0);
  const [assetType, setAssetType] = useState("solid_waste_dustbin");
  const [isBulkAddAsset, setBulkAddAsset] = useState(false);
  const [search, setSearch] = useState("")
  const [payStatus, setPayStatus] = useState("")
  const [payMode, setPayMode] = useState("")
  const [collectorName, setCollectorName] = useState("")
  const [amountPaid, setAmountPaid] = useState("")
  const [totalAmount, setTotalAmount] = useState("")
  const [state, setState] = useState({
    status: "",
    search: "",
    title: "",
    name: "",
    id: "",
    isDelete: true,
    isAdd: false,
    isUpdate: false,
  });
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);
  const [t] = useTranslation("common");
  // const [activeAssetDropdown, setActiveAssetDropdown] = useState("");
  const [assetAddModalOpen, setAssetAddModalOpen] = useState(false);
  // const [downloadDrop, setDownloadDrop] = useState(false);
  // const [addDrop, setAddDrop] = useState(false);
  const [startPaymentDate, setStartPaymentDate] = useState(moment().format('L'))
  // const [startDate, setStartDate] = useState(new Date())
  const [endPaymentDate, setEndPaymentDate] = useState(moment().format('L'))


  useEffect(() => {
    let orgId = localStorage.getItem("orgId");
    props.getPayments(1, props.limit, orgId, search,  payMode, payStatus, startPaymentDate, endPaymentDate, collectorName, amountPaid, totalAmount)
  }, [search]);


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;


    if (name === "search") {
      setSearch(value)
    }
    else if (name === "amountPaid") {
      setAmountPaid(value)
    }
    else if (name === "totalAmount") {
      setTotalAmount(value)
    }
    else if (name === "mode") {
      setPayMode(value)
    }
    else if (name === "status") {
      setPayStatus(value)
    }
    else if (name === "collectorName") {
      setCollectorName(value)
    }
    else if (name === "addSelect") {
      // setAddOpen(false);
      if (value === 1) {
        toggleBulkAddAsset();
      }
    } else if (name === "downloadSelect") {
      // setDownloadOpen(false);
      if (value === "assetsDownload") {
        handleBulkAssetDownload();
      }
    } else if (name === "cards") {
      setCards(value);
    } else if (name === "asset_type") {
      setAssetType(value);
    } else {
      setState({
        ...state,
        [name]: event.target.value,
      });
    }
  };

  let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  // ON SEACH state
  const onSearchPayment = (e) => {
    props.getPayments(
      props.page,
      props.limit,
      localStorage.getItem("orgId"),
      search,
      payMode,
      payStatus,
      startPaymentDate,
      endPaymentDate,
      collectorName,
      amountPaid,
      totalAmount,
    );      
  };

  const handleChangePage = (event, page) => {
    props.getPayments(
      page,
      props.limit,
      localStorage.getItem("orgId"),
      search,
      payMode,
      payStatus,
      startPaymentDate,
      endPaymentDate,
      collectorName,
      amountPaid,
      totalAmount,
    );
  };
  // OPEN DELETE
  const toggleModal = (data) => {
    setModal(!modal);
    setState({
      ...state,
      name: data.ownerName,
      btnTitle: t("property_list.delete"),
      search: "",
      id: data._id,
      title: t("property_list.delete_title"),
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });

    setCurrentStatus(false);
  };

  const toggleBulkAddAsset = () => {
    setBulkAddAsset(!isBulkAddAsset);
  };
  /// handle submit
  const handleSubmit = (e) => {
    const name = e.currentTarget.name;
    e.preventDefault();
    const assetApi = apiUrl + "asset/add";
    if (name === "bulkAssetSubmit") {
      instance
        .request({
          method: "POST",
          url: assetApi,
          data: {
            organisationId: localStorage.getItem("orgId"),
            numberOfCards: noOfCards,
            asset_type: assetType,
          },
        })
        .then(function (response) {
          if (response.status === 200 && response.data.code === 200) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })

        .catch(function (error) {
          console.log(error, "errror");
        }); //axios-end
      toggleBulkAddAsset();
    } else if (name === "singleAssetSubmit") {
      setAssetAddModalOpen(!assetAddModalOpen);
      instance
        .request({
          method: "POST",
          url: assetApi,
          data: {
            organisationId: localStorage.getItem("orgId"),
            numberOfCards: noOfCards,
            asset_type: assetType,
            // propertyId: activeAssetDropdown,
          },
        })
        .then(function (response) {
          if (response.status === 200 && response.data.code === 200) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })

        .catch(function (error) {
          console.log(error, "errror");
        }); //axios-end
    } else {
      setCurrentStatus(true);
      state.orgId = localStorage.getItem("orgId");
      props.addUpdateDeleteProperty(state);
    }
  };

  //handle bulk asset download
  const handleBulkAssetDownload = () => {
    const date = new Date().toLocaleDateString();
    const sDate = date.split("/");
    const startDate = `${sDate[2]}-${sDate[0]}-${sDate[1]}`;
    const assetApi = apiUrl + `asset/download-asset-pdf`;

    axios
      .get(assetApi, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
          Authorization: `${localStorage.getItem("token")}`,
        },
        params: {
          startDate,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          toast.success("File Downloading");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Assets.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
          toast.error("File Download Failed");
        }
      })

      .catch(function (error) {
        console.log(error, "errror");
      }); //axios-end
  };


  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    } else {
    }
  });
  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total);
    return (
      <mui.Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };
  // ENABLE LAODING
  const backDrop = props.loading ? (
    <mui.Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <Breadcrumbs seperator="›" aria-label="breadcrumb">
          <Typography color="textPrimary">{t("pay_hist.pay_tax")}</Typography>
          <Link to="/payments">
            <Typography color="textPrimary">{t("pay_hist.payments")}</Typography>
          </Link>
          <Typography color="textSecondary">{t("pay_hist.pay_hist")}</Typography>
        </Breadcrumbs>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <mui.TextField
                  id="standard-search"
                  size="small"
                  name="search"
                  value={search}
                  onChange={handleChange}
                  type="search"
                  variant="outlined"
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    // marginBottom: "-30%",
                  }}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: t("property_list.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
            </div>
            <div class="dates" style={{ display: "flex" }}>
              <mui.MuiPickersUtilsProvider utils={DateFnsUtils}>
                <mui.KeyboardDatePicker
                  margin="normal"
                  id="start-date"
                  label="Start Date"
                  format="MM/dd/yyyy"
                  value={startPaymentDate}
                  onChange={(date) => setStartPaymentDate(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </mui.MuiPickersUtilsProvider>

              <mui.MuiPickersUtilsProvider utils={DateFnsUtils}>
                <mui.KeyboardDatePicker
                  margin="normal"
                  marginRight="10px"
                  id="start-date"
                  label="End Date"
                  format="MM/dd/yyyy"
                  value={endPaymentDate}
                  onChange={(date) => setEndPaymentDate(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />

              </mui.MuiPickersUtilsProvider>
            </div>

            <div className={styles.dropDownDiv}>
              <mui.Button
                variant="contained"
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  borderRadius: "20px",

                  textTransform: "none",
                  width: "60%",
                  fontWeight: "lighter",
                  // marginBottom: "3%",
                  height: "70%",
                }}
                onClick={onSearchPayment}
              >
                {t("pay_hist.search")}
              </mui.Button>
            </div>
          </div>

          
          <div className={styles.buttonContainer}>

            <Modal
              isOpen={isBulkAddAsset}
              toggle={toggleBulkAddAsset}
              centered={true}
            >
              <ModalHeader
                toggle={toggleBulkAddAsset}
              >{`Add Bulk Assets`}</ModalHeader>
              <ModalBody className={styles.modalContainer}>
                <div style={{ margin: 10 }}>
                  <mui.TextField
                    id="outlined-basic"
                    label="No of Cards"
                    variant="outlined"
                    placeholder="No of cards"
                    type="number"
                    name="cards"
                    value={noOfCards}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
                <mui.FormControl
                  variant="outlined"
                  className={selectClasses.formControl}
                >
                  <div>
                    <mui.InputLabel id="demo-simple-select-outlined-label">
                      {`Select Asset Type`}
                    </mui.InputLabel>
                    <mui.Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={assetType}
                      onChange={handleChange}
                      label="Select Asset Type"
                      name="asset_type"
                    >
                      <mui.MenuItem value={"solid_waste_dustbin"}>
                        Solid Waste Dustbin
                      </mui.MenuItem>
                      <mui.MenuItem value={"wet_waste_dustbin"}>
                        Wet Waste Dustbin
                      </mui.MenuItem>
                    </mui.Select>
                  </div>
                </mui.FormControl>
              </ModalBody>
              <ModalFooter>
                <mui.Button
                  variant="contained"
                  color="primary"
                  name="close"
                  onClick={toggleBulkAddAsset}
                  style={{ marginRight: "2%" }}
                >
                  {t("property_list.cancel")}
                </mui.Button>
                <mui.Button
                  variant="contained"
                  color="secondary"
                  name="bulkAssetSubmit"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {`Add Asset`}
                </mui.Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            <p>
              {t("property_list.delete_msg")} <strong>{state.name}</strong> ?{" "}
            </p>
          </ModalBody>
          <ModalFooter>
            <mui.Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              style={{ marginRight: "2%" }}
            >
              {t("property_list.cancel")}
            </mui.Button>
            <mui.Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </mui.Button>
          </ModalFooter>
        </Modal>
        <div className={styles.table}>
          <mui.TableContainer component={mui.Paper}>
            <mui.Table className={classes.table} aria-label="simple table">
              <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <mui.TableRow>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    <input className={styles.searchbox} name="search" value={search} onChange={(event) => handleChange(event)} placeholder={t("pay_hist.search_owner")} title="Search owner name" />
                  </mui.TableCell>
                  <mui.TableCell style={{ color: "#A3A6B4" }} >
                    <input className={styles.searchbox} name="totalAmount" value={totalAmount} onChange={(event) => handleChange(event)} type="number" placeholder={t("pay_hist.tot_amount")} title="Search total amount" />
                  </mui.TableCell>
                  <mui.TableCell style={{ color: "#A3A6B4" }} >
                    <input className={styles.searchbox} name="amountPaid" value={amountPaid} onChange={(event) => handleChange(event)} type="number" placeholder={t("pay_hist.amount_paid")} title="Search amount paid" />
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    <select name="mode" value={payMode} onChange={handleChange} inp>
                      <option aria-label="None" value="">{t("pay_hist.all")}</option>
                      <option value={"CASH"}>{t("pay_hist.cash")}</option>
                      <option value={"CASHFREE"}>{t("pay_hist.cashfree")}</option>
                      <option value={"WALLET"}>{t("pay_hist.wallet")}</option>
                    </select>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    <select name="status" value={payStatus} onChange={handleChange} inp>
                      <option aria-label="None" value="">{t("pay_hist.all")}</option>
                      <option value={"PENDING"}>{t("pay_hist.pending")}</option>
                      <option value={"RECEIVED"}>{t("pay_hist.recieved")}</option>
                      <option value={"CANCELED"}>{t("pay_hist.rejected")}</option>
                      <option value={"FAILED"}>{t("pay_hist.failed")}</option>
                    </select>
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    <input type="text" name="collectorName" value={collectorName} onChange={(event) => handleChange(event)} className={styles.searchbox} placeholder={t("pay_hist.collector_name")} title="Search by Payment collector name" />
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                   
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("pay_hist.pay_op")}
                  </mui.TableCell>
                </mui.TableRow>
              
                <mui.TableRow>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("pay_hist.prop_owner")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("pay_hist.tot_amount")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("pay_hist.amount_paid")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("pay_hist.pay_mode")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("pay_hist.pay_status")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("pay_hist.pay_coll")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("pay_hist.created_at")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("pay_hist.updated_at")}
                  </mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("pay_hist.more_det")}
                  </mui.TableCell>
                </mui.TableRow>
              </mui.TableHead>
              <mui.TableBody>
                {props?.paymentsList?.length > 0 &&
                  props?.paymentsList?.map((payments) => (
                    <mui.TableRow key={payments._id}>
                      <mui.TableCell align="center">
                        {payments.ownerName}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {payments.totalAmount}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {payments.amountPaid}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {payments.paymentMode}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {payments.paymentStatus}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {payments.collectorId.name}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {helper.getHyphonDate(payments.createdAt)}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {helper.getHyphonDate(payments.updatedAt)}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        <Link to={{
                          pathname: "/more_details/pay_history",
                          state: payments
                        }}
                          style={{ textDecoration: "none", color: "black" }}>
                          <VisibilityIcon />
                        </Link>
                      </mui.TableCell>
                      


                    </mui.TableRow>
                  ))}
              </mui.TableBody>
            </mui.Table>
            {props?.paymentsList?.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {t("property_list.no_record_found")}
              </p>
            )}
          </mui.TableContainer>

        </div>
      </div>
      <div>{props?.paymentsList?.length > 0 && setPage()}</div>
      <style>
        {
          `
          .MuiFormControl-marginNormal {
            margin-right: 10px;
        }
          `
        }
      </style>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    paymentsList: state.Payments.paymentsList,
    loading: state.Payments.loading,
    page: state.Payments.page,
    limit: state.Payments.limit,
    total: state.Payments.total,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {

  return {
    getPayments: (page, limit, orgId, search, status, mode, startPaymentDate, endPaymentDate, collectorName, amountPaid, totalAmount) =>
      dispatch(actions.getPayments(page, limit, orgId, search, mode, status, startPaymentDate, endPaymentDate, collectorName, amountPaid, totalAmount)),
    manageProperty: (data) => dispatch(actions.addUpdateDeleteProperty(data)),
    addUpdateDeleteProperty: (data) =>
      dispatch(actions.addUpdateDeleteProperty(data)),
    
  };
  
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(PayHistory);
