import axios from "axios";
import { toast } from "react-toastify";

import * as actionTypes from "./actionTypes";
import * as myConstClass from "../../constants/constants";

const api_url = myConstClass.apiUrl;
export const authStart = () => {
  return {
    type: actionTypes.FETCH_USER_START,
  };
};

export const getUserSuccess = (data) => {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    data: data,
  };
};

export const inviteUserSuccess = (data) => {
  return {
    type: actionTypes.INVITE_USERS_SUCCESS,
    data: data,
  };
};

export const inviteUserFail = (data) => {
  return {
    type: actionTypes.INVITE_USERS_FAIL,
  };
};

export const getOrgUsersSuccess = (data) => {
  return {
    type: actionTypes.GET_ORG_USERS_SUCCESS,
    data: data,
  };
};

// invite users
export const inviteUser = (value) => {
  let url = "user/master-invite_user";
  var data = {
    value: value.value,
  };

  return (dispatch) => {
    dispatch(authStart());

    axios
      .post(api_url + url, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(inviteUserSuccess(response.data.message));
        dispatch(getUserList(1, 10, ""));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(inviteUserFail(err.response.data.message));
      });
  };
};

/// FETCH users list list LIST

export const getUserList = (page, limit, search, orgId) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(api_url + `user/list/${page}/${limit}?search_value=${search}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
          //  "otp":98988
        },
      })
      .then((response) => {
        dispatch(getUserSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getUserSuccess(data));
      });
  };
};

export const getOrgUsersList = (page, limit, orgId, search) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `organisation/org-userlist/${orgId}/${page}/${limit}?username=${search}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
            //  "otp":98988
          },
        }
      )
      .then((response) => {
        dispatch(getOrgUsersSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getOrgUsersSuccess(data));
      });
  };
};

//  users
export const userUpdateStatus = (userId, status) => {
  let url = `user/block-status`;
  var data = {
    userId: userId,
    isBlock: status,
  };

  return (dispatch) => {
    dispatch(authStart());

    axios
      .post(api_url + url, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(updateStatusSuccess(response.data.message));
        toast.success(response.data.message);
        dispatch(getUserList(1, 10, "", ""));
      })
      .catch((err) => {
        dispatch(updateStatusSuccess(err.response.data.message));
        ///dispatch(verifyDocumentFail(err.response.data.message, ));
      });
  };
};
/// UPDATE USE STATUS
export const updateStatusSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_USER_STATUS,
  };
};
