import axios from "axios";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

import * as actionTypes from "./actionTypes";
import * as myConstClass from "../../constants/constants";

const api_url = myConstClass.apiUrl;

const orgId = localStorage.getItem("orgId");

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const addCountrySuccess = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_COUNTRY_SUCCESS,
    data: data,
  };
};
export const addCountryFail = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_COUNTRY_FAIL,
    data: data,
  };
};

export const getCountrySuccess = (data) => {
  return {
    type: actionTypes.GET_COUNTRY_SUCCESS,
    data: data,
  };
};

export const fetchCountryFail = (error) => {
  return {
    type: actionTypes.GET_COUNTRY_FAIL,
    error: error,
  };
};

/// STATE ACTION TYPE
export const addStateSuccess = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_STATE_SUCCESS,
    data: data,
  };
};
export const addStateFail = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_STATE_FAIL,
    data: data,
  };
};

export const getStateSuccess = (data) => {
  return {
    type: actionTypes.GET_STATE_SUCCESS,
    data: data,
  };
};

export const fetStateFail = (error) => {
  return {
    type: actionTypes.GET_STATE_FAIL,
    error: error,
  };
};

export const getAllCountrySuccess = (data) => {
  return {
    type: actionTypes.GET_ALL_COUNTRY_SUCCESS,
    data: data,
  };
};

export const fetAllCountryFail = (error) => {
  return {
    type: actionTypes.GET_ALL_COUNTRY_FAIL,
    error: error,
  };
};

// CITY ACTION TYPE

export const addCitySuccess = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_CITY_SUCCESS,
    data: data,
  };
};
export const addCityFail = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_CITY_FAIL,
    data: data,
  };
};

export const getCitySuccess = (data) => {
  return {
    type: actionTypes.GET_CITY_SUCCESS,
    data: data,
  };
};

export const fetchCityFail = (error) => {
  return {
    type: actionTypes.GET_CITY_FAIL,
    error: error,
  };
};

export const getStateByCountrySuccess = (data) => {
  return {
    type: actionTypes.GET_STATE_BY_COUNTRY_SUCCESS,
    data: data,
  };
};

export const getStateByCountryFail = (error) => {
  return {
    type: actionTypes.GET_STATE_BY_COUNTRY_FAIL,
    error: error,
  };
};

// LANGUAGE ACTION TYPE

export const addLanguageSuccess = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_LANGUAGE_SUCCESS,
    data: data,
  };
};
export const addLanguageFail = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_LANGUAGE_FAIL,
    data: data,
  };
};

export const getLanguageSuccess = (data) => {
  return {
    type: actionTypes.GET_LANGUAGE_SUCCESS,
    data: data,
  };
};

export const fetchLanguageFail = (error) => {
  return {
    type: actionTypes.GET_LANGUAGE_FAIL,
    error: error,
  };
};

// LANGUAGE ACTION TYPE

export const addModuleSuccess = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_MODULE_SUCCESS,
    data: data,
  };
};
export const addModuleFail = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_MODULE_FAIL,
    data: data,
  };
};

export const getModuleSuccess = (data) => {
  return {
    type: actionTypes.GET_MODULE_SUCCESS,
    data: data,
  };
};

export const fetchModuleFail = (error) => {
  return {
    type: actionTypes.GET_MODULE_FAIL,
    error: error,
  };
};

export const getParentModuleSuccess = (data) => {
  return {
    type: actionTypes.GET_PARENT_MODULE_SUCCESS,
    data: data,
  };
};

export const fetchParentModuleFail = (error) => {
  return {
    type: actionTypes.GET_PARENT_MODULE_FAIL,
    error: error,
  };
};
// blood gruop  actiion
export const addUpdateBloodSuccess = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_BLOOD_SUCCESS,
    data: data,
  };
};

export const addUpdateBloodFail = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_BLOOD_FAIL,
    data: data,
  };
};

export const getBloodSuccess = (data) => {
  return {
    type: actionTypes.GET_BLOOD_SUCCESS,
    data: data,
  };
};
// get city by district
// get district by state action
export const getCityByDistSuccess = (data) => {
  return {
    type: actionTypes.GET_CITY_BY_DIST_SUCCESS,
    data: data,
  };
};
// file downlaod category and subcategory
export const DownloadSuccessCatSubCate = (data) => {
  //  let type = data=='category'?
  return {
    type:
      data === "category"
        ? actionTypes.DOWNLOAD_CAT_SUCCESS
        : actionTypes.DOWNLOAD_SUB_SUCCESS,
    page: data,
  };
};

// export const DownloadSuccessCatSubCateFail=(data)=>{
//     return{
//         type:data==='category'?actionTypes.DOWNLOAD_CAT_FAIL:DOWNLOAD_SUB_FAIL,
//         page:data,

//     }
// }

/// ALL COUNTR ACTION

// add country
export const addCountry = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      country: value.country,
      countryCode: value.code,
    };
    axios
      .post(api_url + "country/add", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addCountrySuccess(response.data.message));
        dispatch(getCountryList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addCountryFail({
            error: err.response,
          })
        );
      });
  };
};

/// UPDATE COUNTRY

export const updateCountry = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      country: value.country,
      countryCode: value.code,
      countryId: value.id,
    };
    axios
      .post(api_url + "country/update", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addCountrySuccess(response.data.message));
        dispatch(getCountryList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addCountryFail({
            error: err.response,
          })
        );
      });
  };
};

// DELETE COUNTRY
export const deleteCountry = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      countryId: value.id,
    };
    axios
      .post(api_url + "country/delete", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addCountrySuccess(response.data.message));
        dispatch(getCountryList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addCountryFail({
            error: err.response,
          })
        );
      });
  };
};
/// FETCH COUNTRY LIST

export const getCountryList = (page, limit, search) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url + `country/master_getall/${page}/${limit}?country=${search}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getCountrySuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          fetchCountryFail({
            error: err.response,
          })
        );
      });
  };
};

/// ALL STATE ACTION

export const addState = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      countryId: value.countryId,
      state: value.state,
      stateCode: value.code,
    };
    axios
      .post(api_url + "state/add", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(getStateList(1, 10, " "));

        dispatch(addStateSuccess(response.data.message));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addStateFail({
            error: err.response,
          })
        );
      });
  };
};

/// UPDATE STATE

export const updateState = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      countryId: value.countryId,
      stateCode: value.code,
      stateId: value.id,
      state: value.state,
    };
    axios
      .post(api_url + "state/update", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(getStateList(1, 10, " "));
        dispatch(addStateSuccess(response.data.message));
        dispatch(getStateList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addStateFail({
            error: err.response,
          })
        );
      });
  };
};

// DELETE STATE
export const deleteState = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      stateId: value.id,
    };
    axios
      .post(api_url + "state/delete", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addStateSuccess(response.data.message));
        dispatch(getStateList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addStateFail({
            error: err.response,
          })
        );
      });
  };
};
/// FETCH STATE LIST

export const getStateList = (page, limit, search) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(api_url + `state/master_getall/${page}/${limit}?state=${search}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getStateSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          fetStateFail({
            error: err.response,
          })
        );
      });
  };
};

// GET COUNTYR ALL COUNTYR LIST

export const getAllCountry = () => {
  return (dispatch) => {
    // dispatch(authStart());
    axios
      .get(api_url + `country/user_getall`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getAllCountrySuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          fetAllCountryFail({
            error: err.response,
          })
        );
      });
  };
};

// CITY ACTION

export const addCity = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      countryId: value.countryId,
      stateId: value.stateId,
      city: value.city,
      postalCode: value.postalCode,
      districtId: value.districtId,
    };
    axios
      .post(api_url + "city/add", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(getCityList(1, 10, " ", " "));

        dispatch(addCitySuccess(response.data.message));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addCityFail({
            error: err.response,
          })
        );
      });
  };
};

/// UPDATE CITY

export const updateCity = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      countryId: value.countryId,
      stateId: value.stateId,
      postalCode: value.postalCode,

      cityId: value.id,
      city: value.city,
      districtId: value.districtId,
    };
    axios
      .post(api_url + "city/update", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(getCityList(1, 10, " ", " ", " "));
        dispatch(addCitySuccess(response.data.message));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addCityFail({
            error: err.response,
          })
        );
      });
  };
};

// DELETE cITY
export const deleteCity = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      cityId: value.id,
    };
    axios
      .post(api_url + "city/delete", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addCitySuccess(response.data.message));
        dispatch(getCityList(1, 10, " ", " ", " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addCityFail({
            error: err.response,
          })
        );
      });
  };
};
/// FETCH STATE BY COUNTYR ID LIST

export const getStateByCountry = (countryId) => {
  return (dispatch) => {
    // dispatch(authStart());
    axios
      .get(api_url + `state/state-bycountryId/${countryId}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getStateByCountrySuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          getStateByCountryFail({
            error: err.response,
          })
        );
      });
  };
};

// GET CITY LIST

export const getCityList = (page, limit, search, countryId, stateId) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `city/master_getall/${page}/${limit}?city=${search}&countryId=${countryId}&stateId=${stateId}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getCitySuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          fetchCityFail({
            error: err.response,
          })
        );
      });
  };
};

// language action
// add language
export const addLanguage = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      language: value.language,
      languageCode: value.code,
      languageNative: value.langNative,
      language_single_character: value.langSingleChar,
    };
    axios
      .post(api_url + "language/add", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addLanguageSuccess(response.data.message));
        dispatch(getLanguageList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addLanguageFail({
            error: err.response,
          })
        );
      });
  };
};

/// UPDATE lanague

export const updateLanguage = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      language: value.language,
      languageCode: value.code,
      languageId: value.id,
      languageNative: value.langNative,
      language_single_character: value.langSingleChar,
    };
    axios
      .post(api_url + "language/update", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addLanguageSuccess(response.data.message));
        dispatch(getLanguageList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addLanguageFail({
            error: err.response,
          })
        );
      });
  };
};

// DELETE COUNTRY
export const deleteLanguage = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      languageId: value.id,
    };
    axios
      .post(api_url + "language/delete", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addLanguageSuccess(response.data.message));
        dispatch(getLanguageList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addLanguageFail({
            error: err.response,
          })
        );
      });
  };
};
/// FETCH COUNTRY LIST

export const getLanguageList = (page, limit, search) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url + `language/master_getall/${page}/${limit}?language=${search}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getLanguageSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          fetchLanguageFail({
            error: err.response,
          })
        );
      });
  };
};

// MODULE ACTION
// ADD MODUE
export const addModule = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      name: value.module,
      keyName: value.module,
      parentModuleId: value.moduleId,
    };
    axios
      .post(api_url + "module/add", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addModuleSuccess(response.data.message));
        dispatch(getModuleList(1, 10, " ", " "));
        dispatch(getAllParentModule());
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addModuleFail({
            error: err.response,
          })
        );
      });
  };
};

/// UPDATE MODULE

export const updateModule = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      name: value.module,
      keyName: value.keyName,

      moduleId: value.id,
    };
    if (value.moduleId) {
      data.parentModuleId = value.moduleId;
    }
    axios
      .post(api_url + "module/update", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addModuleSuccess(response.data.message));
        dispatch(getModuleList(1, 10, " ", " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addModuleFail({
            error: err.response,
          })
        );
      });
  };
};

// DELETE COUNTRY
export const deleteModule = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      moduleId: value.id,
    };
    axios
      .post(api_url + "module/delete", data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addModuleSuccess(response.data.message));
        dispatch(getModuleList(1, 10, " ", " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addModuleFail({
            error: err.response,
          })
        );
      });
  };
};
/// FETCH COUNTRY LIST

export const getModuleList = (page, limit, search) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(api_url + `module/master_getall/${page}/${limit}?name=${search}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getModuleSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          fetchModuleFail({
            error: err.response,
          })
        );
      });
  };
};

export const getAllParentModule = () => {
  return (dispatch) => {
    //dispatch(authStart());
    axios
      .get(api_url + `module/parentModule_getall`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getParentModuleSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        //dispatch(getParentModuleSuccess(err.data.data));

        dispatch(
          fetchParentModuleFail({
            error: "network issue",
          })
        );
      });
  };
};

/// CATEGORY ACTION TYPE AND METHODE

/// add update delete category
export const addUpdateDeleteCategory = (value) => {
  let url = value.isAdd
    ? `category/add`
    : value.isUpdate
    ? `category/update`
    : `category/delete?organisationId=${orgId}`;
  var data = {};
  if (value.isAdd) {
    data.categoryName = value.category;
    data.organisationId = localStorage.getItem("orgId");
  } else if (value.isUpdate) {
    data.categoryName = value.category;
    data.categoryId = value.id;
    data.organisationId = localStorage.getItem("orgId");
  } else {
    data.categoryId = value.id;
  }
  return (dispatch) => {
    dispatch(authStart());

    axios
      .post(api_url + url, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addUpdateCatSuccess(response.data.message));
        dispatch(getCategoryList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addUpdateCatFail({
            error: err.response,
          })
        );
      });
  };
};

/// FETCH category LIST

export const getCategoryList = (page, limit, search) => {
  const orgId = localStorage.getItem("orgId");
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `category/master_getall/${page}/${limit}?organisationId=${orgId}&search_value=${search}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getCatSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getCatSuccess(data));
      });
  };
};

// category action

export const addUpdateCatSuccess = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_CATEGORY_SUCCESS,
    data: data,
  };
};

export const addUpdateCatFail = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_CATEGORY_FAIL,
    data: data,
  };
};

export const getCatSuccess = (data) => {
  return {
    type: actionTypes.GET_CATEGORY_SUCCESS,
    data: data,
  };
};

// subcategory action

/// add update delete subcateory
export const addUpdateDeleteSubCat = (value) => {
  let url = value.isAdd
    ? `subcategory/add`
    : value.isUpdate
    ? `subcategory/update`
    : `subcategory/delete`;
  var data = {};
  if (value.isAdd || value.isUpdate) {
    data.subCategoryName = value.subcategory;
    data.categoryId = value.categoryId;
    data.propertyId = value.selectedProperty;
    data.organisationId = value.orgId;
  }
  if (value.isUpdate) {
    data.subCategoryId = value.id;
  } else {
    data.subCategoryId = value.id;
  }
  return (dispatch) => {
    dispatch(authStart());

    axios
      .post(api_url + url, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let type = value.isDelete ? "delete" : "add";

        toast.success(response.data.message);
        dispatch(addUpdateSubCateSuccess(response.data.message, type));
        if (value.isDelete) {
          dispatch(getSubCategoryList(1, 10, "", ""));
        }
      })
      .catch((err) => {
        let type = value.isDelete ? "delete" : "add";

        toast.error(err.response.data.message);
        dispatch(addUpdateSubCatFail(err.response.data.message, type));
      });
  };
};

/// FETCH sub category  LIST

export const getSubCategoryList = (page, limit, search, id) => {
  const orgId = localStorage.getItem("orgId");

  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `subcategory/master_getall/${page}/${limit}?organisationId=${orgId}&categoryId=${id}&search_value=${search}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getSubCatSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getSubCatSuccess(data));
      });
  };
};

// subcategiry action

export const addUpdateSubCateSuccess = (data, method) => {
  return {
    type: actionTypes.ADD_UPDATE_SUBCAT_SUCCESS,
    data: data,
    method: method,
  };
};

export const addUpdateSubCatFail = (data, method) => {
  return {
    type: actionTypes.ADD_UPDATE_SUBCAT_FAIL,
    data: data,
    method: method,
  };
};

export const getSubCatSuccess = (data) => {
  return {
    type: actionTypes.GET_SUBCAT_SUCCESS,
    data: data,
  };
};

/// DISTRCIT ACITON
/// DISTRCIT ACTION TYPE AND METHODE

/// add update delete DISTRCIT
export const addUpdateDeleteDistrict = (value) => {
  let url = value.isAdd
    ? `district/add`
    : value.isUpdate
    ? `district/update`
    : `district/delete`;
  var data = {};
  if (value.isAdd) {
    data.district = value.district;
    data.countryId = value.countryId;
    data.stateId = value.stateId;
    data.districtCode = value.code;
  } else if (value.isUpdate) {
    data.district = value.district;
    data.countryId = value.countryId;
    data.stateId = value.stateId;
    data.districtCode = value.code;

    data.districtId = value.id;
  } else {
    data.districtId = value.id;
  }
  return (dispatch) => {
    dispatch(authStart());

    axios
      .post(api_url + url, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addUpdateDistrictSuccess(response.data.message));
        dispatch(getDistrictList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addUpdateDistrictFail({
            error: err.response,
          })
        );
      });
  };
};

/// FETCH distct LIST

export const getDistrictList = (page, limit, search) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url + `district/master_getall/${page}/${limit}?district=${search}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getDistrictSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getDistrictSuccess(data));
      });
  };
};

// GET DISTRICT by  STATE

export const getDistrictByState = (stateId) => {
  return (dispatch) => {
    // dispatch(authStart());
    axios
      .get(api_url + `district/district-bystateId/${stateId}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getDistrictByStateSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [] },
        };
        dispatch(getDistrictByStateSuccess(data));
      });
  };
};
// DISTRICT action

export const addUpdateDistrictSuccess = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_DISTRICT_SUCCESS,
    data: data,
  };
};

export const addUpdateDistrictFail = (data) => {
  return {
    type: actionTypes.ADD_UPDATE_DISTRICT_FAIL,
    data: data,
  };
};

export const getDistrictSuccess = (data) => {
  return {
    type: actionTypes.GET_DISTRICT_SUCCESS,
    data: data,
  };
};
// get district by state action
export const getDistrictByStateSuccess = (data) => {
  return {
    type: actionTypes.GET_DISTRICT_BY_STATE_SUCCESS,
    data: data,
  };
};

// get all category
export const getAllCatSuccess = (data) => {
  return {
    type: actionTypes.GET_ALL_CAT_SUCCESS,
    data: data,
  };
};
// blood group action type

// add update delete
export const addUpdateDeleteBlood = (value) => {
  let url = value.isAdd
    ? `bloodGroup/add`
    : value.isUpdate
    ? `bloodGroup/update`
    : `bloodGroup/delete`;
  var data = {};
  if (value.isAdd) {
    data.bloodGroup = value.name;
  } else if (value.isUpdate) {
    data.bloodGroup = value.name;
    data.bloodGroupId = value.id;
  } else {
    data.bloodGroupId = value.id;
  }
  return (dispatch) => {
    dispatch(authStart());

    axios
      .post(api_url + url, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addUpdateBloodSuccess(response.data.message));
        dispatch(getBloodGroupList(1, 10, " "));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addUpdateBloodFail({
            error: err.response,
          })
        );
      });
  };
};

/// FETCH blood  LIST

export const getBloodGroupList = (page, limit, search) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `bloodGroup/master_getall/${page}/${limit}?bloodGroup=${search}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getBloodSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getBloodSuccess(data));
      });
  };
};

// GET ALLL CATEOGYR

export const getAllCategory = () => {
  const orgId = localStorage.getItem("orgId");

  return (dispatch) => {
    // dispatch(authStart());
    axios
      .get(api_url + `category/user_getall/?organisationId=${orgId}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getAllCatSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [] },
        };
        dispatch(getAllCatSuccess(data));
      });
  };
};

// get city by district

export const getCityByDistrict = (districtId) => {
  return (dispatch) => {
    // dispatch(authStart());
    axios
      .get(api_url + `city/city-bydistrictId/${districtId}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getCityByDistSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [] },
        };
        dispatch(getCityByDistSuccess(data));
      });
  };
};

// downlaod excel file
export const downloadExcelCatSubCate = (type, orgId, search, categoryId) => {
  return (dispatch) => {
    let url =
      type === "category"
        ? `category/download-category?organisationId=${orgId}&search_value=${search}`
        : `subcategory/download-subcategory?organisationId=${orgId}&search_value=${search}`;
    dispatch(authStart());
    try {
      axios
        .get(api_url + url, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          var blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(
            blob,
            `${type.toUpperCase()}${new Date().toISOString().slice(0, 10)}.xlsx`
          );
          toast.success("File downloaded successfully");
          dispatch(DownloadSuccessCatSubCate(type));
        });
    } catch (error) {
      dispatch(DownloadSuccessCatSubCate(type));

      toast.error(error.response.data.message);
    }
  };
};

// get unmppaed property
/// FETCH propery list LIST

export const getPendingProperty = (page, limit) => {
  const orgId = localStorage.getItem("orgId");
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `subcategory/org-propertyList-subcat/${page}/${limit}?orgId=${orgId}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
            //  "otp":98988
          },
        }
      )
      .then((response) => {
        dispatch(getPendingPropertySuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getPendingPropertySuccess(data));
      });
  };
};

// GET PENDING PROPERTY SUCCESS
export const getPendingPropertySuccess = (data) => {
  return {
    type: actionTypes.GET_PENDING_PROPERTY_SUCCES,
    data: data,
  };
};

export const downloadExcelMaster = (type, search) => {
  let url =
    type === "country"
      ? `country/download-countries?country=${search}`
      : type === "state"
      ? `state/download-states?state=${search}`
      : type === "district"
      ? `district/download-district?district=${search}`
      : type === "city"
      ? `city/download-cities?city=${search}`
      : type === "module"
      ? `module/download-module?module=${search}`
      : type === "blood"
      ? `bloodGroup/download-bloodgroup?blood=${search}`
      : type === "language"
      ? `language/download-languages?language=${search}`
      : type === "role"
      ? `role/download-role_list_admin?role=${search}`
      : `/dfd`;
  return (dispatch) => {
    dispatch(authStart());

    axios
      .get(api_url + url, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        //  let filename= type
        saveAs(blob, `${type}${new Date().toISOString().slice(0, 10)}.xlsx`);
        toast.success("File downloaded successfully");
        dispatch(downloadExcelMasterSuccess(type));
      })
      .catch((error) => {
        dispatch(downloadExcelMasterSuccess(type));
        toast.error("No Record Found");
      });
  };
};

export const downloadExcelMasterSuccess = (masterType) => {
  return {
    type:
      masterType === "country"
        ? actionTypes.DOWNLOAD_MASTER_EXCEL_SUCCESS
        : masterType === "state"
        ? actionTypes.DOWNLOAD_STATE_SUCCESS
        : masterType === "district"
        ? actionTypes.DOWNLOAD_DISTRICT_SUCCESS
        : masterType === "city"
        ? actionTypes.DOWNLOAD_CITY_SUCCESS
        : masterType === "module"
        ? actionTypes.DOWNLOAD_MODULE_SUCCESS
        : masterType === "blood"
        ? actionTypes.DOWNLOAD_BLOOD_SUCCESS
        : masterType === "language"
        ? actionTypes.DOWNLOAD_LANGUAGE_SUCCESS
        : actionTypes.DOWNLOAD_LANGUAGE_SUCCESS,
  };
};
