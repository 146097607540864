import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  propertyList: [],
  subcategoryList: [],
  page: 1,
  limit: 10,
  total: "",
  message: null,
  error: null,
  success: false,
  authRedirectPath: "/dashboard",
  loading: false,
  jobPropertyList: [],
  propertyVersionList: [],
  propertyProfile: null,
};

const authStart = (states, action) => {
  return updateObject(states, {
    // message:states,
    loading: true,
  });
};

/// category reducer type

const addUpdateDeletePropertySuccess = (state, action) => {
  let message = action.method === "add" ? null : "success";
  let success = action.method === "add" ? true : false;
  return updateObject(state, {
    message: message,
    error: null,
    // loading: false,
    success: success,
  });
};

const addUpdateDeletePropertyFail = (state, action) => {
  let message = action.method === "add" ? null : "success";
  return updateObject(state, {
    message: message,
    error: null,
    // success:success,
    loading: false,
  });
};

const uploadPropertySuccess = (state, action) => {
  // let message = action.method==='add' ?null:'success';
  let success = true;
  return updateObject(state, {
    // message: message,
    error: null,
    // loading: false,
    success: success,
  });
};

const getPropertySuccess = (state, action) => {
  return updateObject(state, {
    propertyList: action.data.data.docs,
    page: action.data.data.page,
    limit: action.data.data.limit,
    total: action.data.data.total,
    success: false,
    loading: false,
    message: null,
  });
};
const getPropertyByJobIdSuccess = (state, action) => {
  return updateObject(state, {
    jobPropertyList: action.data.data.docs,
    page: action.data.data.page,
    limit: action.data.data.limit,
    total: action.data.data.totalDocs,
    success: false,
    loading: false,
  });
};

const getSubCategoryByCatSuccess = (state, action) => {
  return updateObject(state, {
    subcategoryList: action.data.data,
  });
};

// get properyt versin
// downlaod properyt

const getPropertyVersionSuccess = (state, action) => {
  return updateObject(state, {
    propertyVersionList: action.data.data.docs,
    page: action.data.data.page,
    limit: action.data.data.limit,
    total: action.data.data.totalDocs,
    success: false,
    loading: false,
  });
};

const getPropertyProfileSuccess = (state, action) => {
  return updateObject(state, {propertyProfile:action.data});
};
const downloadPropertySucces = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};
// properyt reducer
export const Property = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);


    case actionTypes.GET_PROPERTY_PROFILE_SUCCESS:
      return getPropertyProfileSuccess(state,action)

    case actionTypes.ADD_UPDATE_PROPERTY_SUCCESS:
      return addUpdateDeletePropertySuccess(state, action);

    case actionTypes.ADD_UPDATE_PROPERTY_FAIL:
      return addUpdateDeletePropertyFail(state, action);

    case actionTypes.UPLOAD_PROPERTY_SUCCESS:
      return uploadPropertySuccess(state, action);

    case actionTypes.GET_PROPERTY_SUCCESS:
      return getPropertySuccess(state, action);

    case actionTypes.GET_PROPERTY_BY_JOB_SUCCESS:
      return getPropertyByJobIdSuccess(state, action);

    case actionTypes.GET_SUBCAT_BY_CAT_SUCCESS:
      return getSubCategoryByCatSuccess(state, action);

    case actionTypes.DOWNLOAD_PROPERYT_SUCCESS:
      return downloadPropertySucces(state, action);

    case actionTypes.GET_PROPERTY_VERSION_SUCCESS:
      return getPropertyVersionSuccess(state, action);

    case actionTypes.AUTH_LOGOUT:
      return initialState;

    // case actionTypes.GET__SUCCESS:
    //     return getAllCatSuccess(state, action);
    default:
      return state;
  }
};

export default Property;
