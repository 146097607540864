import React, { useEffect, useState } from "react";
import styles from "./payments.module.css";
import * as mui from "../../../../shared/mui";

import { Link } from "react-router-dom";
import DashboardCard from "../../../../components/JobCreationCard/JobCreationCard";

import first from "./investor.png";
import second from "./maintenance.png";
import Card from "../../../../components/Card/Card";
import { apiUrl } from './../../../../constants/constants';
import axios from "axios";
import { toast } from "react-toastify";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';


const drawerWidth = 252;

const useStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "content-type": "application/json",
    Accept: "Application/json",
    Authorization: `${localStorage.getItem("token")}`,
  },
});

export default function Empty() {
  const [t] = useTranslation("common");
  const classes = useStyles();
  const [payDetails, setPayDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const showTaxData = (e) => {

    let orgId = localStorage.getItem("orgId")
    
    const payApi = apiUrl + `payment/dashboard?organisationId=${orgId}`;

    setLoading(true);

    instance.request({
      method: 'GET',
      url: payApi,
    })
      .then(function (response) {
        if (response.status === 200 && response.data.code === 200) {
          setPayDetails(response.data.response);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      
        console.log('error', error);
      });
  }

  useEffect(() => {
    showTaxData();
  }, []);

  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading}>
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      <mui.CssBaseline />
      <main
        className={mui.clsx(classes.content, {
          [classes.contentShift]: true,
        })}
      >
        <div className={styles.title}>
          <Breadcrumbs seperator="›" aria-label="breadcrumb">
            <Typography color="textPrimary">{t("payment_dash.pay_tax")}</Typography>
            <Typography color="textSecondary">{t("payment_dash.payments")}</Typography>
          </Breadcrumbs>
        </div>
        <div className={styles.grid}>
          <Link
            style={{ textDecoration: "none", margin: "2%", }}
            to="/payments_history"
          >
            <DashboardCard img={first} color={"#58D8FE"} details={t("payment_dash.hist")} />
          </Link>

          <Link
            style={{ textDecoration: "none", margin: "2%", }}
            to="/end_of_day"
          >
            <DashboardCard
              img={second}
              color={"#6BE4A7"}
              details={t("payment_dash.eod")}
              style={{ textDecoration: "none", margin: "2%", }}
            />
          </Link>

        </div>

        <div className={styles.title}>{t("payment_dash.pay_dash")}</div>
        <div className={styles.grid}>
          <Card title={t("payment_dash.total_pay")} number={`₹${payDetails.totalPayment}`} />
          <Card title={t("payment_dash.pay_rec")} number={`₹${payDetails.paymentReceived}`} />
          <Card title={t("payment_dash.pay_pen")} number={`₹${payDetails.paymentPending}`} />
          <Card title={t("payment_dash.onln_pay")} number={`₹${payDetails.onlinePayment}`} />
          <Card title={t("payment_dash.cash_pay")} number={`₹${payDetails.cashPayment}`} />
        </div>
      </main>
    </div>
  );
}
