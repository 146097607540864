/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as myConstClass from "../../../../constants/constants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { hasAccess } from "../../../../shared/HasAccess";

import styles from "./Role.module.css";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import GetAppIcon from "@material-ui/icons/GetApp";

import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit from "./edit.png";

import * as actions from "../../../../store/actions/index";
import { toast } from "react-toastify";
const api_url = myConstClass.apiUrl;

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export function Role(props) {
  const classes = useStyles();
  const [t] = useTranslation("common");

  const [state, setState] = React.useState({
    role: "",

    search: "",
    id: "",
    btnTitle: "Save",
    title: "Add",
    isAdd: false,
    isUpdate: false,
    isDelete: false,
    selectedModule: [],
    editModuleList: [],
  });
  const [errors, setErros] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalEdit, setEditModal] = useState(false);

  /// FETCH Role LIST HERE
  useEffect(() => {
    props.getRoleList(1, props.limit, state.search);
    setTimeout(() => {
      props.getAllModule();
    }, 1000);
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setErros({});
  };
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);

  let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  // OPEN ADD COUNTRY MODAL
  const toggleModal = (e) => {
    setModal(!modal);
    setState({
      ...state,
      role: "",
      cbtnTitle: "Save",
      title: "Add Role",
      isAdd: true,
      isDelete: false,
      isUpdate: false,
      selectedModule: props.moduleList,
    });
    setErros({});
    setCurrentStatus(false);
  };

  const toggleModalClose = (e) => {
    state.selectedModule.forEach((mdl, i) => {
      state.selectedModule[i].mod_enableStatus = false;
      if (mdl.subModule.length > 0) {
        mdl.subModule.forEach((mdl, ix) => {
          state.selectedModule[i].subModule[ix].mod_enableStatus = false;
        });
      }
    });
    setState({ ...state, selectedModule: state.selectedModule });
    setModal(false);
  };
  // dwonload module
  const downloadExcel = () => {
    props.downlaodExcelFile("role", state.search);
  };

  // EDIT ROLE
  const editRole = (data) => {
    setLoading(true);

    getRoleById(data, data._id);
  };
  // close edit modal
  const closeEditModal = () => {
    setEditModal(false);
    setState({ ...state, selectedModule: [] });
  };

  // get role by id
  const getRoleById = (data, id) => {
    axios
      .get(api_url + `role/Role_getbyId-admin?roleId=${id}`, {
        method: "get",
        headers: {
          "content-type": "Application/json",
          accept: "Application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        response.data.data.module.forEach((el, i) => {
          response.data.data.module[i].mod_enableStatus = el.mod_enableStatus;
          response.data.data.module[i]._id = el.moduleId._id;
          response.data.data.module[i].name = el.moduleId.name;
          response.data.data.module[i].subModule = el.submoduleId;
        });

        setState({
          ...state,
          editModuleList: response.data.data.module,
          id: data._id,
          selectedModule: response.data.data.module,
          role: data.role,
          isAdd: false,
          isDelete: false,
          isUpdate: true,
        });
        setEditModal(true);
        setLoading(false);
      })
      .catch((er) => {
        setLoading(false);
      });
  };

  // DELETE  role
  const deleteRole = (data) => {
    setState({
      ...state,
      role: data.role,
      id: data._id,
      btnTitle: "Delete",
      title: "Delete Role",
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });
    setErros({});
    setModal(true);
  };
  // VAIDAATE FORM
  const validateForm = () => {
    var valid = true;
    let filter = state.selectedModule.filter(
      (x) => x.mod_enableStatus === true
    );
    if (state.role.trim() === "") {
      errors.role = "Role name is required";
      valid = false;
    } else if (filter.length === 0) {
      toast.error("Please select at least module", {});
      valid = false;
    } else {
      errors.module = "";
      errors.role = "";
      valid = true;
    }
    setErros((errors) => ({ ...errors }));
    return valid;
  };
  /// handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.isDelete && !validateForm()) {
      return;
    } else {
      var module = [];
      state.selectedModule.forEach((el, index) => {
        var ar = {};
        if (el.mod_enableStatus) {
          ar.mod_enableStatus = el.mod_enableStatus;
          ar.moduleId = el._id;
        }

        let subLength = el.subModule.filter((x) => x.mod_enableStatus === true);
        if (el.subModule.length > 0 && subLength.length > 0) {
          var arr1 = [];
          subLength.forEach((sub, i) => {
            arr1.push(sub._id);

            //   if(sub.mod_enableStatus){
            // // arr1.mod_enableStatus=sub.mod_enableStatus;
            // //   arr1.moduleId=sub._id

            //   }
          });
          ar.submoduleId = arr1;
          module.push(ar);
        } else {
          if (el.mod_enableStatus) {
            ar.submoduleId = [];
            module.push(ar);
          }
        }
      });
      setCurrentStatus(true);
      state.module = module;
      props.manageRole(state);
    }
  };
  // ON SEACH COMPANY
  const onSearchRole = (e) => {
    props.getRoleList(props.page, props.limit, state.search);
  };

  useEffect(() => {
    if (props.isRoleAdded && currentStatus) {
      setModal(false);
      setCurrentStatus(false);
      toggleModalClose();
      closeEditModal();
    } else {
      // if(props.error){
      // 	setpassword_ErMsg(props.error)
      // 	setdisplaytext('showBlock')
      // }
    }
  });

  const checkedOption = (id, i, type) => {
    // if(id!==undefined){
    // 	let arr = state.selectedModule;
    // 	var ch =  arr.find(x=>x.moduleId==id)
    // 	if(ch&&ch.mod_enabldeStatus){
    // 		return true
    // 	} else {
    // 		return false
    // 	}
    // }
    if (id !== undefined && type === "parent") {
      let arr = state.selectedModule;
      var ch = arr.find((x) => x._id === id);
      if (ch && ch.mod_enableStatus) {
        return true;
      } else {
        return false;
      }
    } else if (id !== undefined && type === "child") {
      let arr = state.selectedModule;
      ch =
        arr.length > 0 && arr[i] !== undefined && arr[i].subModule !== undefined
          ? arr[i].subModule.find((x) => x._id === id)
          : undefined;
      if (ch && ch.mod_enableStatus) {
        return true;
      } else {
        return false;
      }
    }
  };
  const handleCheckChange = (event, data, idx, type, parentIndex) => {
    // find date in selected list
    // let fndData = state.selectedModule.find(x => x.moduleId == data._id)
    // if (fndData) {
    // 	if (event.target.checked) {
    // 		state.selectedModule[state.selectedModule.indexOf(fndData)].mod_enableStatus = true
    // 	}
    // 	else {
    // 		state.selectedModule[state.selectedModule.indexOf(fndData)].mod_enableStatus = false

    // 	}
    // }
    // else {
    // 	state.selectedModule.push({ mod_enableStatus: true, moduleId: data._id })
    // }
    // setState({
    // 	...state,
    // 	selectedModule: state.selectedModule
    // });

    if (type === "parent") {
      if (event.target.checked) {
        state.selectedModule[idx].mod_enableStatus = true;
      } else {
        let dt =
          state.selectedModule[idx].subModule.length > 0 &&
          state.selectedModule[idx].subModule.filter(
            (x) => x.mod_enableStatus === true
          );
        if (dt.length) {
          dt.forEach((status, index) => {
            state.selectedModule[idx].subModule[
              state.selectedModule[idx].subModule.indexOf(status)
            ].mod_enableStatus = false;
          });
          //setState({...state, selectedModule:state.selectedModule})
        }
        state.selectedModule[idx].mod_enableStatus = false;
      }

      setState({
        ...state,
        selectedModule: state.selectedModule,
      });
    } else if (type === "child") {
      if (event.target.checked) {
        state.selectedModule[parentIndex].subModule[
          idx
        ].mod_enableStatus = true;
      } else {
        state.selectedModule[parentIndex].subModule[
          idx
        ].mod_enableStatus = false;
      }
      setState({
        ...state,
        selectedModule: state.selectedModule,
      });
    }
  };

  // disable child module if parent is not selected
  const disabledChildModule = (id, i) => {
    if (id !== undefined && state.selectedModule.length > 0) {
      let disable = state.selectedModule.find((x) => x._id === id);
      if (disable && disable.mod_enableStatus) {
        return false;
      } else {
        return true;
      }
    }
  };

  useEffect(() => {
    // if(id==undefined){

    setState({
      ...state,
      selectedModule: props.moduleList,
    });
    // }
  }, [props.moduleList.length]);

  const handleChangePage = (event, page) => {
    props.getRoleList(page, props.limit, state.search);
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  // ENABEL LAODING
  const backDrop = props.loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;

  // ENABEL LAODING 1 for edit role
  const backDrop_edit = loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      {backDrop_edit}
      <div className={styles.title}>
        <span style={{ fontWeight: "lighter" }}>{t("module.master")} /</span>
        <span style={{ fontWeight: "lighter", color: "#43425D" }}>
          &nbsp;Role
        </span>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <TextField
                  id="standard-search"
                  size="small"
                  name="search"
                  value={state.search}
                  type="search"
                  variant="outlined"
                  onChange={handleChange}
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    // marginBottom: "5%",
                  }}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: t("lang.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
            </div>
            <div className={styles.dropDownDiv}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  borderRadius: "17px",
                  textTransform: "none",
                  width: "45%",
                  fontWeight: "lighter",
                  // marginBottom: "3%",
                  height: "90%",
                  outline: "none",
                }}
                onClick={(e) => {
                  onSearchRole(e);
                }}
              >
                {t("lang.search")}
              </Button>
            </div>
          </div>
          <div className={styles.buttonAndFilter}>
            {/* <FormControl variant="outlined">
							<InputLabel
								htmlFor="outlined-age-native-simple"
								style={{ alignText: "center" }}
							>
								Filter
							</InputLabel>
							<Select
								native
								value={state.age}
								onChange={handleChange}
								style={{
									width: "120%",
									maxHeight: "80%",
									marginBottom: "5%",
								}}
								label="Filter"
								inputProps={{
									name: "Filter",
									id: "outlined-age-native-simple",
								}}
							>
								<option aria-label="None" value="" />
								<option value={10}>Ten</option>
								<option value={20}>Twenty</option>
								<option value={30}>Thirty</option>
							</Select>
						</FormControl> */}
            <Button
              onClick={downloadExcel}
              variant="contained"
              color="secondary"
              style={{
                textTransform: "none",
                backgroundColor: "#3B86FF",
                textAlign: "center",
                whiteSpace: "nowrap",
                outline: "none",
                marginLeft: "45%",
                width: "45%",
                fontSize: "smaller",
              }}
            >
              {t("job_reporting.download")}
              <GetAppIcon style={{ marginLeft: "20%" }} />
            </Button>
            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "role_add_admin",
                props.accessList.module
              ) && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={toggleModal}
                  style={{
                    textTransform: "none",
                    textAlign: "center",
                    width: "45%",
                    // height: "95%",
                    // marginBottom: "3.2%",
                    outline: "none",
                    marginLeft: "8%",
                  }}
                >
                  Add
                </Button>
              )}
          </div>
        </div>
        <Modal
          isOpen={modal}
          toggle={toggleModalClose}
          scrollable={true}
          style={{
            maxHeight: "10vw",
          }}
          centered={true}
        >
          <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            {state.isDelete && (
              <p>
                {" "}
                Are you sure want to delete <strong>{state.role}</strong> ?{" "}
              </p>
            )}
            {!state.isDelete && (
              <form className={classes.root}>
                <TextField
                  helperText={errors.role}
                  error={errors.role}
                  className={classes.root}
                  variant="outlined"
                  label={"Role"}
                  name="role"
                  style={{ width: "80%" }}
                  value={state.role}
                  onChange={handleChange}
                />

                {props.moduleList.length > 0 &&
                  props.moduleList.map((module, idx) => (
                    <div style={{ width: "100%", marginLeft: "40px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedOption(module._id, idx, "parent")}
                            onChange={(e) =>
                              handleCheckChange(e, module, idx, "parent")
                            }
                            name="parent"
                            color="primary"
                            key={module._id}
                          />
                        }
                        label={module.name}
                      />
                      <div style={{ marginLeft: "50px" }}>
                        {module.subModule.length > 0 &&
                          module.subModule.map((sub, i) => (
                            <div style={{ marginLeft: "80px" }}>
                              {" "}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    style={{ display: "inline-block" }}
                                    checked={checkedOption(
                                      sub._id,
                                      idx,
                                      "child"
                                    )}
                                    onChange={(e) => {
                                      handleCheckChange(
                                        e,
                                        sub,
                                        i,
                                        "child",
                                        idx
                                      );
                                    }}
                                    name="child"
                                    color="primary"
                                    display="inline-block"
                                    disabled={
                                      state.editModuleList.length > 0 &&
                                      disabledChildModule(module._id, idx)
                                    }
                                    key={sub._id}
                                  />
                                }
                                label={sub.name}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleModalClose}
              style={{ marginRight: "2%" }}
            >
              {t("lang.cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalEdit}
          toggle={closeEditModal}
          backdrop="static"
          scrollable={true}
          style={{
            maxHeight: "10vw",
          }}
          centered={true}
        >
          <ModalHeader toggle={closeEditModal}>Update Role</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            {!state.isDelete && (
              <form className={classes.root}>
                <TextField
                  helperText={errors.role}
                  error={errors.role}
                  className={classes.root}
                  variant="outlined"
                  label={"Role"}
                  name="role"
                  style={{ width: "80%" }}
                  value={state.role}
                  onChange={handleChange}
                />

                {state.editModuleList.length > 0 &&
                  state.editModuleList.map((module, idx) => (
                    <div style={{ width: "100%", marginLeft: "40px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedOption(
                              module.moduleId._id,
                              idx,
                              "parent"
                            )}
                            onChange={(e) =>
                              handleCheckChange(e, module, idx, "parent")
                            }
                            name="parent"
                            color="primary"
                            key={module.moduleId._id}
                          />
                        }
                        label={module.moduleId.name}
                      />
                      <div style={{ marginLeft: "50px" }}>
                        {module.submoduleId.length > 0 &&
                          module.submoduleId.map((sub, i) => (
                            <div style={{ marginLeft: "80px" }}>
                              {" "}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    style={{ display: "inline-block" }}
                                    checked={checkedOption(
                                      sub._id,
                                      idx,
                                      "child"
                                    )}
                                    onChange={(e) => {
                                      handleCheckChange(
                                        e,
                                        sub,
                                        i,
                                        "child",
                                        idx
                                      );
                                    }}
                                    name="child"
                                    color="primary"
                                    display="inline-block"
                                    disabled={disabledChildModule(
                                      module.moduleId._id,
                                      idx
                                    )}
                                    key={sub._id}
                                  />
                                }
                                label={sub.name}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={closeEditModal}
              style={{ marginRight: "2%" }}
            >
              {t("lang.cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>

        <div className={styles.table}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    ROLE
                  </TableCell>
                  {/* <TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
									Status
						</TableCell> */}
                  {/* <TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
					      {(t("lang.lang_native"))}
						</TableCell> */}
                  {/* <TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
						{(t("lang.lnag_s_char"))}
						</TableCell> */}

                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("lang.status")}
                  </TableCell>

                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>

                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.roleList.length > 0 &&
                  props.roleList.map((role) => (
                    <TableRow key={role._id}>
                      <TableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {role.role}
                      </TableCell>

                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {role.status ? "Active" : "InActive"}
                      </TableCell>

                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>

                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="justify" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "role_delete",
                            props.accessList.module
                          ) && (
                            <DeleteForeverIcon
                              onClick={() => {
                                deleteRole(role);
                              }}
                              style={{
                                padding: "none",
                                cursor: "pointer",
                                color: "#43425D",
                              }}
                            />
                          )}
                      </TableCell>
                      <TableCell align="left" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "role_update_admin",
                            props.accessList.module
                          ) && (
                            <img
                              src={edit}
                              alt="Edit"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => editRole(role)}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {props.roleList.length === 0 && (
              <p style={{ textAlign: "center" }}>{t("lang.no_record_found")}</p>
            )}
          </TableContainer>
        </div>

        <div className={classes.root}></div>
      </div>
      {props.roleList.length > 0 && setPage()}
    </div>
  );
}

Role.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    loading: state.Organization.loading,
    message: state.Language.language.message,
    moduleList: state.Organization.moduleList,
    roleList: state.Organization.roleList,
    isRoleAdded: state.Organization.isRoleAdded,

    page: state.Organization.page,
    limit: state.Organization.limit,
    total: state.Organization.total,
    accessList: state.Organization.accessList,

    //    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    manageRole: (data) => dispatch(actions.addUpdateDeleteRole(data)),

    getRoleList: (page, limit, search) =>
      dispatch(actions.getRoleList(page, limit, search)),
    getAllModule: () => dispatch(actions.getAllModule()),
    downlaodExcelFile: (type, search) =>
      dispatch(actions.downloadExcelFile(type, search)),
    // onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Role);
