import React from "react"
import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"

export default function Frame(props){

    return (
        <div>
            <Navbar/>
            {props.page?props.page: <div style = {{height:"55vh"}}/>}
            <Footer/>
        </div>
    )
}
