/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from "./OTP.module.css";
import { useHistory, Link } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Logo from "./images/Logo.jpg";
import CancelIcon from "@material-ui/icons/Cancel";
import {useTranslation } from 'react-i18next';

import * as myConstClass from '../../../constants/constants';


const api_url = myConstClass.apiUrl;
const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
			width: "40ch",
		},
		"& .MuiIcon-colorSecondary": {
			width: "2em",
			height: "2em",
		},
	},
}));

export default function ForgotPassword() {
	const classes = useStyles();
	const [t] = useTranslation('common');
	const inputRef1 = useRef('input_1');
	const inputRef2 = useRef('input_2');
	const inputRef3 = useRef('input_3');
	const inputRef4 = useRef('input_4');
	const history = useHistory()
	const [timeLeft, setTimeLeft] = useState(null);

	const [loading, setLoading] = useState(false);

	const [otpForm, setotpForm] = useState(
		{
			text1: "", text2: "", text3: "", text4: "", isExpired: false, time: {},
			breakRemainingSeconds: 10
		},

	);


	const [timeForm, setTimerForm] = useState(
		{

			time: {},
			breakRemainingSeconds: 0
		},

	);
	useEffect(()=>{
		let value = localStorage.getItem('value')
		if(!value){
			return  history.push('/')
		}
		inputRef1.current.focus();
		iniTimer()
	},[])

	const iniTimer = () => {
		let timeLeft = createTime(300 - 1);
		setTimerForm({ time: timeLeft, })
		setTimeLeft(300 - 1)
	}
	useEffect(() => {

		if (timeLeft === 0) {
			setTimeLeft(null)
			setotpForm({ ...otpForm, isExpired: true })
		}

		// exit early when we reach 0
		if (!timeLeft) return;

		// save intervalId to clear the interval when the
		// component re-renders
		const intervalId = setInterval(() => {
			let time = createTime(timeLeft);
			setTimeLeft(timeLeft - 1);
			setTimerForm({ time: time })
		}, 1000);

		// clear interval on re-render to avoid memory leaks
		return () => clearInterval(intervalId);
		// add timeLeft as a dependency to re-rerun the effect
		// when we update it
	}, [timeLeft]);

	const handleOTP = (event) => {
		
		event.preventDefault();
		const name = event.target.name;
		setotpForm({
			...otpForm,
			[name]: event.target.value.trim(),
		});
	
			if (event.target.name === 'text1' && event.target.value.trim() !== '') {
				inputRef2.current.focus();
			}
			else if (event.target.name === 'text2' && event.target.value.trim() !== '') {
				inputRef3.current.focus();
			}
			else if (event.target.name === 'text3' && event.target.value.trim() !== '') {
				inputRef4.current.focus();
			
		}
	

		//this.refs.input_2.focus();
	};

	const createTime = (secs) => {

		let hours = Math.floor(secs / (60 * 60));
		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);
		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);
		let timeObject = {
			"h": hours,
			"m": minutes.toString().length > 1 ? minutes : "0" + minutes,
			"s": seconds.toString().length > 1 ? seconds : "0" + seconds,
		};
		return timeObject;
	}

	//Prevent to enter alphabets 
	const onKeyPress = (event) => {
	
		const pattern = /[0-9-+ /b]/;
		let inputChar = String.fromCharCode(event.charCode);
		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}
	const onKeyDown = (e) => {

		if (e.key === 'Backspace') {
			if (e.target.name === 'text1') {
				//inputRef2.current.focus();
			}
			else if (e.target.name === 'text2') {
				inputRef1.current.focus();
			}
			else if (e.target.name === 'text3') {
				inputRef2.current.focus();

			}
			else if (e.target.name === 'text4') {
				inputRef3.current.focus();

			}
			setotpForm({
				...otpForm,
				[e.target.name]: '',
			});
			e.preventDefault();
		}
	}

	// verify OTP
	const verifyOTP = (e) => {

		e.preventDefault()
		var userotp = otpForm.text1 + otpForm.text2 + otpForm.text3 + otpForm.text4
		let otp = localStorage.getItem('otp')

		if (userotp !== otp.toString()) {
			return toast.error(t("singup.Invalid_otp"))
		}
		else {

			toast.success("OTP Verified")
			history.push('/reset-password')
		}
	}
	// SEND OTP
	const sendOTP =(e, type)=>{
		// setModal(true);

		e.preventDefault()
		if(type==='re-send'&&timeLeft>0){
			return
		}
		setLoading(true);
		const otp=  (Math.floor(1000 + Math.random() * 9000));
		localStorage.setItem('otp',otp)
		var data ={
			value:localStorage.getItem('value')

			
		}
			// setModal(true)
		axios.post(api_url+`user/forgot-password`,data,{
		
		 method:"POST",
		 headers :{
			 'content-type':"Application/json",
			 "accept":"Application/json",
			 'otp': otp
		 },
	 
		})
		.then(response=>{
			
		toast.success(response.data.message)
		
		setotpForm({...otpForm, isExpired:false, text1:"", text2:"", text3:"", text4:""})
		let timeLeft = createTime(300-1);
		setTimerForm({time:timeLeft, })

		setTimeLeft(300-1)
		
		// setuserForm({...userForm, isExpired:false})
	
	
		// let timeLeft = createTime(10);
		// setTimerForm({time:timeLeft, breakRemainingSeconds:300-1 })
		// setModal(true);
		setLoading(false);
		
		})
		.catch(er=>{
			
			toast.error(er.response.data.message)
			setLoading(false)
		})
		
	}
	const backDrop = loading ? (
		<Backdrop style={{ zIndex: 1204 }} className={classes.backdrop} open={loading}>
			<CircularProgress color="inherit" />
		</Backdrop>
	) : null;

	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				objectFit: "fit",
				overflow: "hidden",
				position: "absolute",
				top: "0",
				left: "0",
			}}

			className={styles.background}
		>
			{backDrop}
			<div className={styles.layer}></div>
			<div className={styles.div1}>
			<Link to="/forgot-password"><CancelIcon
					style={{
						color: "#F2134F",
						position: "absolute",
						top: "-13",
						right: "-18",
						height: "10%",
						width: "10%",
						cursor: "pointer",
					}}
				/>
				</Link>
				<div className={styles.logoDiv}>
					<img src={Logo} alt="logo" />
				</div>
				<div className={styles.div1text}>{t("signup.otp_vefification")}</div>
				<div className={styles.textField}>
					<form
						className={classes.root}
						noValidate
						autoComplete="off"
					>
						<input
							value={otpForm.text1}
							onChange={handleOTP}
							onKeyPress={onKeyPress}
							onKeyDown={onKeyDown}
							name="text1"
							maxLength="1"
							ref={inputRef1}

							style={{
								width: "10%",
								height: "100%",
								border:
									"2px solid #1A73E8",
								borderRadius: "5px",
								marginRight: "5%",
								marginLeft: "23%",
								textAlign: "center"
							}}
						/>
						<input
							value={otpForm.text2}
							onChange={handleOTP}
							onKeyPress={onKeyPress}
							onKeyDown={onKeyDown}
							maxLength="1"
							name="text2"
							ref={inputRef2}
							style={{
								width: "10%",
								height: "100%",
								border:
									"2px solid #1A73E8",
								borderRadius: "5px",
								marginRight: "5%",
								textAlign: "center"
							}}
						/>
						<input
							value={otpForm.text3}
							onChange={handleOTP}
							onKeyPress={onKeyPress}
							onKeyDown={onKeyDown}
							maxLength="1"
							name="text3"
							ref={inputRef3}
							style={{
								width: "10%",
								height: "100%",
								padding: "2%",
								border:
									"2px solid #1A73E8",
								borderRadius: "5px",
								marginRight: "5%",
								textAlign: "center"
							}}
						/>
						<input
							value={otpForm.text4}
							onChange={handleOTP}
							onKeyPress={onKeyPress}
							onKeyDown={onKeyDown}
							maxLength="1"
							ref={inputRef4}
							name="text4"
							style={{
								width: "10%",
								height: "100%",
								border:
									"2px solid #1A73E8",
								borderRadius: "5px",
								marginRight: "5%",
								textAlign: "center"
							}}
						/>
					</form>
				</div>
				<div className={styles.resSend}>
					{timeLeft > 0 && <p className={styles.ex}>OTP Expires in {timeForm.time.m}:{timeForm.time.s} Minutes</p>}
					<p onClick={(e) => { sendOTP(e, 're-send') }} className={timeLeft == null ? styles.send : styles.sendDisable}>	{t("signup.resend_opt")}</p>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						padding: "3%",
					}}
				>
					<Link style={{ textDecoration: "none" }} to='reset-password'><Button
						variant="contained"
						color="secondary"
						style={{
							width: "43ch",
							textTransform: "none",
							height: "4em",
						}}
						onClick={(e) => { verifyOTP(e) }}
					>
						{t("signup.verify_mobile")}
					</Button>
					</Link>
				</div>
			</div>
		</div>
	);
}


