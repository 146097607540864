/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as actions from "../../../../store/actions/index";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import styles from "./PropertyVersion.module.css";

import * as mui from "../../../../shared/mui";

const drawerWidth = 252;

const useStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

// FUNCTIONAL COMPONENT STARTS FROM HERE

export function PropertyProfile(props) {
  const classes = useStyles();
  const { id } = useParams();

  useState(() => {
    props.getPropertyProfile(id);
    console.log(props, "props");
  });
  // const [t] = useTranslation("common");
  return (
    <div className={classes.root}>
      <mui.CssBaseline />
      <main
        className={mui.clsx(classes.content, {
          [classes.contentShift]: true,
        })}
      >
        <div className={styles.title}>{"Property Profile"}</div>
        {props.propertyProfile !== null ? (
          <div>
            <mui.Paper elevation={3} style={{ padding: "50px" }}>
              {/* .................CONTENT BOX START.............. */}
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <mui.Card
                    style={{
                      width: "70vh",
                      marginRight: "15%",
                      minHeight: "70vh",
                    }}
                  >
                    <mui.CardActionArea>
                      <mui.CardMedia
                        component="img"
                        alt="NO IMAGE FOUND"
                        height="280"
                        image={
                          props.propertyProfile.owner_image
                            ? props.propertyProfile.owner_image
                            : props.propertyProfile.property_image
                        }
                        title="Property/Owner Image"
                      />
                      <mui.CardContent>
                        <mui.Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <p>{`Govt. ID:${props.propertyProfile.govt_property_id}`}</p>
                          <p>{`Owner Name:${props.propertyProfile.ownerName}`}</p>

                          <p>{`Category Group: ${props.propertyProfile.categorygroup?props.propertyProfile.categorygroup:"No Data"}`}</p>
                          <p>{`Ledger Number: ${props.propertyProfile.ledger_number?props.propertyProfile.ledger_number:"No Data"}`}</p>
                          {props.propertyProfile.phone ? (
                            <p>{`Phone: ${props.propertyProfile.phone?props.propertyProfile.phone:"No Data"}`}</p>
                          ) : null}

                          <p>{`Property Occupied: ${props.propertyProfile.property_occupied?props.propertyProfile.property_occupied:"No Data"}`}</p>
                          <p>{`Property Atikraman: ${props.propertyProfile.property_on_atikraman?props.propertyProfile.property_on_atikraman:"No Data"}`}</p>
                          <p>{`Address: ${props.propertyProfile.address_line_1?props.propertyProfile.address_line_1:"No Data"} ${props.propertyProfile.address_line_2?props.propertyProfile.address_line_2:""} ${props.propertyProfile.address_line_3?props.propertyProfile.address_line_3:""}`}</p>
                          <p>{`Status: ${props.propertyProfile.status!==undefined?props.propertyProfile.status:"No Data"}`}</p>
                          <p>{`Lat: ${props.propertyProfile.latitude?props.propertyProfile.latitude:"No Data"}`}</p>
                          <p>{`Long: ${props.propertyProfile.longitude?props.propertyProfile.longitude:"No Data"}`}</p>
                          {props.propertyProfile.property_type ? (
                            <p>{`${props.propertyProfile.property_type}`}</p>
                          ) : null}
                          <p>{`Audit Status: ${props.propertyProfile.auditStatus?props.propertyProfile.auditStatus:"No Data"}`}</p>
                          <p>{`Property Status: ${props.propertyProfile.propertyStatus?props.propertyProfile.propertyStatus:"No Data"}`}</p>
                        </mui.Typography>
                      </mui.CardContent>
                    </mui.CardActionArea>
                  </mui.Card>
                </div>
              </div>
              {/* ................CONTENT BOX END................... */}
            </mui.Paper>
          </div>
        ) : (
          `No Data Found`
        )}
      </main>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    propertyList: state.Property.propertyVersionList,
    propertyProfile: state.Property.propertyProfile,
    success: state.Property.success,
    loading: state.Property.loading,
    page: state.Property.page,
    limit: state.Property.limit,
    total: state.Property.total,
    message: state.Property.message,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  console.log(actions, "actions");
  return {
    getPropertyProfile: (id) => dispatch(actions.getPropertyProfile(id)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(PropertyProfile);
