
import * as actionTypes from "../actions/actionTypes";
import {
    updateObject
} from "../../shared/utility";


const initialState = {
    jobList: [],
    orgUserList: [],
    initCardList: [],
    allPackageList: [],
    inventoryList: [],
    attendanceList: [],
    page: 1,
    limit: 10,
    total: '',
    message: null,
    loading: true,
    error: null,
    success: false,
    authRedirectPath: "/dashboard",
    // loading: false,
    isPackageAdded: false,
    packageList: [],
    jobAuditList: [],
    qcCardList: [],
    statusUpdate: false,
    auditcardList: [],
    instacardList: [],
    initTableList: [],
    qcTableList: [],
    initEndDayReportList: [],
    qcEndDayReportList: [],
    isDeleted: false,
    initQcCardList: [],
    userHistoryList: [],
    pendingDocList: [],
    instaEndDayReportList:[],
    graphList:[]


};

const authStart = (states, action) => {


    return updateObject(states, {
        // message:states,
        loading: true
    });
};

/// category reducer type

const addJobSuccess = (state, action) => {

    let message = action.method === 'add' ? null : 'success';
    let success = true
    return updateObject(state, {
        message: message,
        error: null,
        // loading: false,
        success: success
    });
};


const addJobFail = (state, action) => {
    let message = action.method === 'add' ? null : 'success';
    // let success = action.message === 'add' ? true : false
    return updateObject(state, {
        message: message,
        error: null,
        // success:success,
        loading: false,
    });
};

;

const getJobSuccess = (state, action) => {

    return updateObject(state, {
        jobList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.totalDocs,
        success: false,
        loading: false,
    });
};

const getOrgUserSuccess = (state, action) => {

    return updateObject(state, {
        orgUserList: action.data.data,
    });
};
// file downlaod
const getFileSuccess = (state) => {
    return updateObject(state, {
        loading: false,
    })
}

// file downlaod
const downloadAttendanceSuccess = (state) => {
    return updateObject(state, {
        loading: false,
    })
}

// file downlaod
const downloadInventrySuccess = (state) => {
    return updateObject(state, {
        loading: false,
    })
}

// file downlaod for work history
const downloadWorkHistory = (state) => {
    return updateObject(state, {
        loading: false,
    })
}

/// downlaod package sucess
// file downlaod for work history
const downloadPkgSuccess = (state) => {
    return updateObject(state, {
        loading: false,
    })
}

// get init card succes
const getInitCardSuccess = (state, action) => {

    return updateObject(state, {
        initCardList: action.data,
        loading: false
    })
}
// get init card succes
const getQcCardSuccess = (state, action) => {


    return updateObject(state, {
        qcCardList: action.data.data,
        loading: false
    })
}

// get audiot card 
// get init card succes
const getAuditCardSuccess = (state, action) => {

    return updateObject(state, {
        auditcardList: action.data.data,
        loading: false
    })
}

// GET INSTA CARD SUCESS
const getInstaCardSuccess = (state, action) => {

    return updateObject(state, {
        instacardList: action.data.data[0],
        loading: false
    })
}


// Package Reducer Actions
const addPackageSuccess = (state, action) => {


    return updateObject(state, {
        loading: false,
        isPackageAdded: true,
        isDeleted: action.data ? true : false,
    })
}
const addPackageFail = (states) => {
    return updateObject(states, {
        loading: false,
        isPackageAdded: false,
    })
}

const getPackageSuccess = (states, action) => {
    return updateObject(states, {
        packageList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.total,
        // success:false,
        loading: false,
        isPackageAdded: false,
        isDeleted: false,
    })
}
// GET ALL PACKAGE SUCCESS
const getAllPkgSuccess = (states, action) => {
    return updateObject(states, {
        allPackageList: action.data.data
    })
}

// Inventory Reducer type action


const addInventorySuccess = (states) => {
    return updateObject(states, {
        loading: false,
        isInventoryAdded: true,
    })
}
const addInventoryFail = (states) => {
    return updateObject(states, {
        loading: false,
        isInventoryAdded: false,
    })
}

const getInventorySuccess = (states, action) => {

    return updateObject(states, {
        inventoryList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.totalDocs,
        // success:false,
        loading: false,
        isInventoryAdded: false,
    })
}

// get audio succss
const getAuditJobSuccess = (states, action) => {

    return updateObject(states, {
        jobAuditList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.totalDocs,
        // success:false,
        loading: false,
        statusUpdate: false,

    })
}

// get audio succss
const updatePropertyStatusSuccess = (states, action) => {
    return updateObject(states, {
        statusUpdate: true,
        loading: false,

    })
}

// get audio succss
const updatePropertyStatusFail = (states, action) => {
    return updateObject(states, {
        statusUpdate: false,
        loading: false,

    })
}

const getAttendanceSuccess = (states, action) => {
    return updateObject(states, {
        attendanceList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.totalDocs,
        // success:false,
        loading: false,

    })
}

// get init table list success
const getInitTableSuccess = (states, action) => {
    return updateObject(states, {
        initTableList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.totalDocs,
        // success:false,
        loading: false,

    })
}

// get init table list success
const getQcTableSuccess = (states, action) => {
    return updateObject(states, {
        qcTableList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.totalDocs,
        // success:false,
        loading: false,

    })
}

// get Job detials /card list
const getInitQcJobDetailsSuccess = (states, action) => {
    return updateObject(states, {
        initQcCardList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.totalDocs,
        // success:false,
        loading: false,

    })
}


// get user  work history
const getUserHistorySuccess = (states, action) => {
    return updateObject(states, {
        userHistoryList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.total,
        // success:false,
        loading: false,

    })
}

// GET QC END DAY REPORT 
// get user  work history
const getQcEndDayReportSuccss = (states, action) => {

    return updateObject(states, {
        qcEndDayReportList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.totalDocs,
        // success:false,
        loading: false,

    })
}
// GET INIT END DAY REPORT
const geInitEndDayReportSuccess = (states, action) => {
    return updateObject(states, {
        initEndDayReportList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.totalDocs,
        // success:false,
        loading: false,

    })
}


// GET INSTAL END DAY REPORT
const geInstaEndDayReportSuccess = (states, action) => {
    return updateObject(states, {
        instaEndDayReportList: action.data.data.docs,
        page: action.data.data.page,
        limit: action.data.data.limit,
        total: action.data.data.totalDocs,
        // success:false,
        loading: false,

    })
}
// downlaod report success
const reportDownloadSuccess = (states, action) => {
    
    return updateObject(states, {
      loading: false,

    })
}

const getGraphDataSccess = (state, action) => {
    
        return updateObject(state, {
        graphList: action.data.data,
        loading: false,

    })
};

const downloadAuditSuccess = (state, action) => {
    return updateObject(state, {
    loading: false,

})
};

// properyt reducer
export const Operation = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);

        case actionTypes.ADD_JOB_SUCCESS:
            return addJobSuccess(state, action);

        case actionTypes.ADD_JOB_FAIL:
            return addJobFail(state, action);

        case actionTypes.GET_JOB_SUCCESS:
            return getJobSuccess(state, action);


        case actionTypes.FILE_DOWNLOAD_SUCCESS:
            return getFileSuccess(state, action);


        case actionTypes.DOWNLOAD_ATTENDANCE_SUCCESS:
            return downloadAttendanceSuccess(state, action);


        case actionTypes.GET_INIT_CARD_SUCCESS:
            return getInitCardSuccess(state, action);

        case actionTypes.GET_AUDIT_CARD_SUCCESS:
            return getAuditCardSuccess(state, action);


        case actionTypes.GET_QC_CARD_SUCCESS:
            return getQcCardSuccess(state, action);

        case actionTypes.GET_INSTA_CARD_SUCCESS:
            return getInstaCardSuccess(state, action);

        case actionTypes.GET_ORG_USER_SUCCESS:
            return getOrgUserSuccess(state, action);

        case actionTypes.ADD_PACKAGE_SUCCESS:
            return addPackageSuccess(state, action);

        case actionTypes.ADD_PACKAGE_FAIL:
            return addPackageFail(state, action);

        case actionTypes.GET_PACKAGE_SUCCESS:
            return getPackageSuccess(state, action);


        case actionTypes.GET_ALL_PACKAGE_SUCCESS:
            return getAllPkgSuccess(state, action);

        case actionTypes.ADD_INVENTORY_SUCCESS:
            return addInventorySuccess(state, action);

        case actionTypes.ADD_INVENTORY_FAIL:
            return addInventoryFail(state, action);


        case actionTypes.GET_INVENTORY_SUCCESS:
            return getInventorySuccess(state, action);


        case actionTypes.GET_AUDIO_JOB_SUCCESS:
            return getAuditJobSuccess(state, action);

        case actionTypes.UPDATE_PROPERTY_STATUS_FAIL:
            return updatePropertyStatusFail(state, action);

        case actionTypes.UPDATE_PROPERTY_STATUS_SUCCESS:
            return updatePropertyStatusSuccess(state, action);

        case actionTypes.GET_INIT_TABLE_SUCCESS:
            return getInitTableSuccess(state, action);

        case actionTypes.GET_QC_TABLE_SUCCESS:
            return getQcTableSuccess(state, action);

        case actionTypes.GET_JOB_DETAILS_SUCCESS:
            return getInitQcJobDetailsSuccess(state, action);

        case actionTypes.GET_ATTENDANCE_SUCCESS:
            return getAttendanceSuccess(state, action);


        case actionTypes.GET_USER_HISOTORY_SUCCESS:
            return getUserHistorySuccess(state, action);

        case actionTypes.DOWNLOAD_WORK_HISTORY_SUCCESS:
            return downloadWorkHistory(state, action);


        case actionTypes.DOWNLOAD_PKG_SUCCESS:
            return downloadPkgSuccess(state, action);


        case actionTypes.DOWNLOAD_INVENTORY_SUCCESS:
            return downloadInventrySuccess(state, action);


        case actionTypes.GET_QC_DAY_END_REPORT_SUCCESS:
            return getQcEndDayReportSuccss(state, action);


        case actionTypes.GET_INIT_DAY_END_REPORT_SUCCESS:
            return geInitEndDayReportSuccess(state, action);

            case actionTypes.GET_INSTA_DAY_END_REPORT_SUCCESS:
                return geInstaEndDayReportSuccess(state, action);
    

            case actionTypes.REPORT_DOWNLOAD_SUCCESS:
                return reportDownloadSuccess(state, action);

                case actionTypes.GET_GRAPH_DATA_SUCCESS:
                    return getGraphDataSccess(state, action);

                    case actionTypes.DOWNLOAD_AUDIT_SUCCESS:
                        return downloadAuditSuccess(state, action);

        case actionTypes.AUTH_LOGOUT:

            return initialState;
        default:
            return state;
    }
};

export default Operation;