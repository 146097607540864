/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import GetAppIcon from "@material-ui/icons/GetApp";

import styles from "./District.module.css";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import { connect } from "react-redux";
import { compose } from 'redux';
import { withTranslation,useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit from "./edit.png";


import * as actions from "../../../../store/actions/index";
import {hasAccess} from '../../../../shared/HasAccess'




const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
		borderRadius: 0,
	},
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "30ch",
		},
		"& .MuiPagination-root": {
			margin: theme.spacing(3),
			color: "standard",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
        },
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
}));


export  function District(props) {
	const classes = useStyles();
	const [t] = useTranslation('common');

	const [state, setState] = React.useState({
		 countryId: "",
		 stateId: "",
		 district:"",
		 code:'',
		 search: "",
		  id: "",
		 btnTitle:"Save",
		 title:"Add District",
		 isAdd:false,
		 isUpdate:false,
		 isDelete:false,

	});
	const [errors , setErros]= useState({})


	/// FETCH STATE LIST HERE
	useEffect(() => {
    
		props.getDistrictList(1, props.limit, state.search, state.countryId, state.stateId)
		setTimeout(()=>{
		props.getAllCountry()
			},1000)
	  }, []);
	
//Prevent to enter alphabets 
const  onKeyPress=(event)=> {
	const pattern = /[0-9-+]/;
	let inputChar = String.fromCharCode(event.charCode);
	if (!pattern.test(inputChar)) {
	  event.preventDefault();
	}
}
	

	const handleChange = (e) => {
		
		if(e.target.value!=='0'){
		const name = e.target.name;
		setState({
			...state,
			[name]: e.target.value,
		});
		setErros({})

		if(e.target.name==='countryId'){
	 props.getStateByCountry(e.target.value)
		}
	}
	};
	const [modal, setModal] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(true);

	let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

	// OPEN ADD COUNTRY MODAL
	const toggleModal =(e)=>{
		
	 setModal(!modal)
      setState({...state,countryId:"", code:"", stateId:"",btnTitle:(t("district.save")), district:"",
	  title:(t("district.add_district")), isAdd:true, isDelete:false, isUpdate:false});
	  setErros({})
	  setCurrentStatus(false)

	}

	 // Download District
	 const downloadExcel = () => {
		props.downlaodExcelFile( 'district',state.search)
	}

   // EDIT DISTRICT
   const editDistrict =(data)=>{
	setState({countryId:data.countryId._id, stateId:data.stateId._id, code:data.districtCode, district:data.district, id:data._id,  btnTitle:(t("district.update")),
	title:(t("district.update_district")),isAdd:false, isDelete:false, isUpdate:true});
	setErros({})
	setModal(true)
	props.getStateByCountry(data.countryId._id)
   }

   // DELETE DISTRICT
    const deleteDistrict=(data)=>{
		setState({district:data.district,id:data._id,  btnTitle:(t("district.delete")),
		title:(t("district.delete_district")),isAdd:false, isDelete:true, isUpdate:false});
		setErros({})
		setModal(true)
	}
// VAIDAATE FORM
  const  validateForm =()=>{
	  
	  var valid =true
	if(state.countryId.trim()===''){
		errors.countryId=(t("district.country_error"));  errors.stateId=''; errors.district="";   errors.code=""; 
		valid=false;
	  }
	  else if(state.stateId.trim()===''){
		 errors.stateId=(t("district.state_error")); errors.countryId=''; errors.district="";  errors.code=""; 
		 valid=false;
	 }
	 else if(state.district.trim()===''){
		errors.district=(t("district.district_error")); errors.countryId=''; errors.stateId='';  errors.code=""; 
		valid=false;
	}
	else if(state.code.trim()===''){
		errors.code=(t("district.code_error")); errors.countryId=''; errors.stateId='';  errors.district=""; 
		valid=false;
	}
	 else{
		 errors.countryId=''; errors.stateId='';  errors.district='';   errors.code=""; 
		 valid=true;
	 }
	 setErros(errors => ({ ...errors, }));
	 return valid
  }
  /// handle submit 
  const handleSubmit = (e)=>{
	
	e.preventDefault();
	if(!state.isDelete&&!validateForm()){
	 return;
   }
   else{
	  setCurrentStatus(true)
	  props.manageDistrict(state)	
}


  
}
// ON SEARCH state
const onSearchDistrict=(e)=>{
	props.getDistrictList(props.page, props.limit, state.search, state.countryId, state.stateId,)
}

useEffect(() => {

	
	if (props.message&&currentStatus) {
		setModal(!modal)
		setCurrentStatus(false)	
	} 
});
// On chnage page
const handleChangePage =(event, page)=>{

props.getDistrictList(page, props.limit, state.search, state.countryId, state.stateId)
}

// apply pagination
const setPage = () => {
	
	let total =Math.ceil(props.total / props.limit)
	return(
	<Pagination
	className={classes.root}
	onChange={handleChangePage}
	count={total}
	 shape="rounded"
	 color="primary"
	 variant="outlined"
		  style={{
		 marginTop: "2%",
		 float: "right",
		 }}
	  />
	  )

  };

  // ENABEL LAODING
  const  backDrop =props.loading?(
	<Backdrop style={{zIndex:1205}} className={classes.backdrop} open={props.loading}>
		<CircularProgress color="inherit" />
	</Backdrop>
):null


	return (
		
		<div className={styles.main}>
           {backDrop}
	
			<div className={styles.title}>
				<span style={{ fontWeight: "lighter" }}>{t("module.master")}/</span>
				<span style={{ fontWeight: "lighter", color: "#43425D" }}>
					&nbsp;{t("district.title")}
				</span>
			</div>
			<div  className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div>
							<div className={styles.searchBar}>
								<TextField
									id="standard-search"
									size="small"
									name='search'
									value={state.search}
									type="search"
									variant="outlined"
									onChange={handleChange}
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
										// marginBottom: "5%",
									}}
									InputProps={{
										startAdornment: icon,
										placeholder: (t("district.search")),
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/>
							</div>
						</div>
						<div className={styles.dropDownDiv}>
							<Button
								variant="contained"
							
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "17px",
									textTransform: "none",
									width: "45%",
									fontWeight: "lighter",
									// marginBottom: "3%",
									height: "90%",
									outline: "none",
								}}
								onClick={(e)=>{onSearchDistrict(e)}}
							>

							{t("district.search_btn")}
							</Button>
						</div>
					</div>
					<div className={styles.buttonAndFilter}>
						{/* <FormControl variant="outlined">
							<InputLabel
								htmlFor="outlined-age-native-simple"
								style={{ alignText: "center" }}
							>
								Filter
							</InputLabel>
							<Select
								native
								value={state.age}
								onChange={handleChange}
								style={{
									width: "120%",
									maxHeight: "80%",
									marginBottom: "5%",
								}}
								label="Filter"
								inputProps={{
									name: "Filter",
									id: "outlined-age-native-simple",
								}}
							>
								<option aria-label="None" value="" />
								<option value={10}>Ten</option>
								<option value={20}>Twenty</option>
								<option value={30}>Thirty</option>
							</Select>
						</FormControl> */}
						<Button
						onClick={downloadExcel}
							variant="contained"
							color="secondary"
							style={{
								textTransform: "none",
								backgroundColor: "#3B86FF",
								textAlign: "center",
								whiteSpace: "nowrap",
								outline: "none",
								marginLeft: "52%",
								fontSize: "smaller",
							}}
						>
						{t("job_reporting.download")}
							<GetAppIcon style={{ marginLeft: "20%" }} />
						</Button>
						{props.accessList.module!==undefined&&hasAccess('submoduleId', 'district_add',props.accessList.module) && <Button
							variant="contained"
							color="secondary"
							onClick={toggleModal}
							style={{
								textTransform: "none",
								textAlign: "center",
								// width: "45%",
								// height: "95%",
								// marginBottom: "3.2%",
								outline: "none",
								// marginLeft: "50%",
							}}
						>
							{t("district.add_district")}
						</Button>}
					</div>
				</div>
				<Modal isOpen={modal} toggle={toggleModal} centered={true}>
					<ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
					<ModalBody className={styles.modalContainer}>
					{state.isDelete&&<p>{t("district.delete_msg")} <strong>{state.district}</strong> ? </p>}
						{!state.isDelete&&<form className={classes.root}>
						<FormControl style={{marginLeft:"17px"}} variant="outlined">
								<InputLabel
									htmlFor="outlined-age-native-simple"
									style={{ alignText: "center" }}
								>
									{t("district.country")}
								</InputLabel>
								<Select
									native
									value={state.countryId}
									onChange={handleChange}
									style={{
										maxHeight: "80%",
										marginBottom: "5%",
										width:"80%"
									}}
									label="Filter"
									inputProps={{
										name: "countryId",
										id: "outlined-age-native-simple",
									}}
								>
									<option aria-label="None" value={'0'} >-{t("district.select_country")}-</option>
									{props.countryList.length>0&&props.countryList.map((country, i)=><option aria-label="None" value={country._id} >{country.country}</option>)}</Select>
							</FormControl>
							{errors.countryId && (
										<small
											className="error"
											style={{ color: "red" }}
										>
											{errors.countryId}
										</small>
									)}
									<FormControl style={{marginLeft:"17px"}} variant="outlined">
								<InputLabel
									htmlFor="outlined-age-native-simple"
									style={{ alignText: "center" }}
								>
									{t("district.state1")}
								</InputLabel>
								<Select
									native
									value={state.stateId}
									onChange={handleChange}
									style={{
										maxHeight: "80%",
										marginBottom: "5%",
										width:"80%"
									}}
									label="Filter"
									inputProps={{
										name: "stateId",
										id: "outlined-age-native-simple",
									}}
								>
									<option aria-label="None" value={'0'} >-{t("district.select_state")}-</option>
									{props.stateList.length>0&&props.stateList.map((state, i)=><option aria-label="None" value={state._id} >{state.state}</option>)}</Select>
							</FormControl>
							{errors.stateId && (
										<small
											className="error"
											style={{ color: "red" }}
										>
											{errors.stateId}
										</small>
									)}
								
							<TextField
								className={classes.root}
								variant="outlined"
                                name="district"
								label=	{t("district.district1")}
								value={state.district}
								onChange ={handleChange}
								style={{ width: "80%" }}
			
							/>
							{errors.district && (
								<small
									className="error"
									style={{ color: "red" }}
								>
									{errors.district}
								</small>
							)}
                        <TextField
								className={classes.root}
								variant="outlined"
                                name="code"
								label=	{t("district.district_code")}
								value={state.code}
								onChange ={handleChange}
								onKeyPress={onKeyPress}
								style={{ width: "80%" }}
			
							/>
							{errors.code && (
								<small
									className="error"
									style={{ color: "red" }}
								>
									{errors.code}
								</small>
							)}

						</form>}
					</ModalBody>
					<ModalFooter>
						<Button
							variant="contained"
							color="primary"
							onClick={toggleModal}
							style={{ marginRight: "2%" }}
						>
							{t("district.cancel")}
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={(e) => { handleSubmit(e) }}
						>
							{state.btnTitle}
						</Button>
					</ModalFooter>
				</Modal>
				<div className={styles.table}>
				<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead style={{ backgroundColor: "#F5F6FA" }}>
					<TableRow>
						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
							{t("district.country_name")}
						</TableCell>
						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
							{t("district.state")} 
						</TableCell>
						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
								{t("district.district")}
						</TableCell>
						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
								{t("district.code")}
						</TableCell>
						

						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
							{t("district.status")}
						</TableCell>
						<TableCell align="center"></TableCell>

						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
					
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.districtList.length>0&& props.districtList.map((district) => (
						<TableRow key={district._id}>
							<TableCell
								align="left"
								component="th"
								scope="row"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
							{district.countryId.country}
							</TableCell>
							<TableCell
								align="left"
								component="th"
								scope="row"
								style={{
									color: "#4D4F5C",
									fontFamily:
								"Regular 13px/20px Source Sans Pro",
								}}
							>
							{district.stateId.state}
							</TableCell>
							<TableCell
								align="left"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
								{district.district}
							</TableCell>
							<TableCell
								align="left"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
								{district.districtCode}
							</TableCell>
							<TableCell
								align="left"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
								{district.status?"Active":"In-Active"}
							</TableCell>
							{/* <TableCell
								align="left"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
								{state.stateCode}
							</TableCell> */}
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>

							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
					
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
							
							<TableCell align="justify" padding="checkbox">
							{props.accessList.module!==undefined&&hasAccess('submoduleId', 'district_delete',props.accessList.module)&&<DeleteForeverIcon
								onClick ={()=>{deleteDistrict(district)}}
									style={{
										padding: "none",
										cursor: "pointer",
										color: "#43425D",
									}}
								/>}
							</TableCell>
							<TableCell align="left" padding="checkbox">
							{props.accessList.module!==undefined&&hasAccess('submoduleId', 'district_update',props.accessList.module)&&<img
									src={edit}
									alt="Edit"
									style={{
										cursor: "pointer",
									}}
									onClick={()=>editDistrict(district)}
								/>}
							</TableCell>
				
						</TableRow>
					))}
				</TableBody>
			 
			</Table>
			{props.districtList.length===0&&<p style={{textAlign:'center'}}>{t("district.no_record_found")}</p>}
		</TableContainer>

				</div>

				<div className={classes.root}></div>
			</div>
			{props.districtList.length>0&&setPage()}
			
		</div>
	);
}

District.propTypes = {
	t: PropTypes.func.isRequired,
  };
const mapStateToProps = (state) => {
	
	return {
		loading: state.District.loading,
		error: state.District.district.error,
	   message:state.District.district.message,
	   districtList:state.District.district.districtList,
	   stateList:state.District.district.stateList,
	   countryList:state.State.state.countryList,
	   page:state.District.district.page,
	   limit:state.District.district.limit,
	   total:state.District.district.total,
	   accessList: state.Organization.accessList,
	//    isAuthenticated: state.auth.token !== null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		manageDistrict: (data) =>
			dispatch(actions.addUpdateDeleteDistrict(data)),
	//      updateCity: (data) =>
	// 		dispatch(actions.updateCity(data)),
	//  deleteCity: (data) =>
	// 		dispatch(actions.deleteCity(data)),	
		getDistrictList: (page, limit, search, countryId, stateId) =>
			dispatch(actions.getDistrictList(page, limit, search, countryId,stateId)),
	getAllCountry: () =>
			dispatch(actions.getAllCountry()),
	 getStateByCountry: (countryId) =>
			dispatch(actions.getStateByCountry(countryId)),
			downlaodExcelFile:  ( type, search) =>
			dispatch(actions.downloadExcelMaster(type, search)),
			
		// onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
	};
};

export default compose(withTranslation('common'), connect(mapStateToProps,  mapDispatchToProps))(District);