/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as acitons from "../../../../../store/actions/index";
import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";
import { hasAccess } from "../../../../../shared/HasAccess";

import * as mui from "../../../../../shared/mui";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./AllProperties.module.css";
import "./AllProperties.module.css";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import VisibilityIcon from "@material-ui/icons/Visibility";
import edit from "./edit.png";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import Pagination from "@material-ui/lab/Pagination";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiUrl } from "../../../../../constants/constants";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
  },
}));

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "content-type": "application/json",
    Accept: "Application/json",
    Authorization: `${localStorage.getItem("token")}`,
  },
});

const selectStyles = mui.makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function AllProperties(props) {
  const classes = useStyles();
  const selectClasses = selectStyles();
  // const [addOpen, setAddOpen] = useState(false);
  // const [downloadOpen, setDownloadOpen] = useState(false);
  const [noOfCards, setCards] = useState(0);
  const [assetType, setAssetType] = useState("solid_waste_dustbin");
  const [isBulkAddAsset, setBulkAddAsset] = useState(false);
  const [tempPropertyStatus, setTempPropertyStatus] = useState(null)
  const [state, setState] = React.useState({
    status: "",
    search: "",
    title: "",
    name: "",
    id: "",
    isDelete: true,
    isAdd: false,
    isUpdate: false,
  });
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);
  const [t] = useTranslation("common");
  const [activeAssetDropdown, setActiveAssetDropdown] = useState("");
  const [assetAddModalOpen, setAssetAddModalOpen] = useState(false);
  const [downloadDrop, setDownloadDrop] = useState(false)
  const [addDrop, setAddDrop] = useState(false)
  useEffect(() => {
    let orgId = localStorage.getItem("orgId");
    props.getProperty(1, props.limit, orgId, state.search, state.status);
  }, []);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "addSelect") {
      // setAddOpen(false);
      if (value === 1) {
        toggleBulkAddAsset();
      }
    } else if (name === "downloadSelect") {
      // setDownloadOpen(false);
      if (value === "assetsDownload") {
        handleBulkAssetDownload();
      }
    } else if (name === "cards") {
      setCards(value);
    } else if (name === "asset_type") {
      setAssetType(value);
    } else {
      setState({
        ...state,
        [name]: event.target.value,
      });
    }
  };

  let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;


  // ON SEACH state
  const onSearchProperty = (e) => {
    props.getProperty(
      props.page,
      props.limit,
      localStorage.getItem("orgId"),
      state.search,
      state.status
    );
  };

  const handleChangePage = (event, page) => {
    props.getProperty(
      page,
      props.limit,
      localStorage.getItem("orgId"),
      state.search,
      state.status
    );
  };
  // OPEN DELETE
  const toggleModal = (data) => {
    setModal(!modal);
    setState({
      ...state,
      name: data.ownerName,
      btnTitle: t("property_list.delete"),
      search: "",
      id: data._id,
      title: t("property_list.delete_title"),
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });

    setCurrentStatus(false);
  };

  const toggleBulkAddAsset = () => {
    setBulkAddAsset(!isBulkAddAsset);
  };
  /// handle submit
  const handleSubmit = (e) => {
    const name = e.currentTarget.name;
    e.preventDefault();
    const assetApi = apiUrl + "asset/add";
    if (name === "bulkAssetSubmit") {
      instance
        .request({
          method: "POST",
          url: assetApi,
          data: {
            organisationId: localStorage.getItem("orgId"),
            numberOfCards: noOfCards,
            asset_type: assetType,
          },
        })
        .then(function (response) {
          if (response.status === 200 && response.data.code === 200) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })

        .catch(function (error) {
          console.log(error, "errror");
        }); //axios-end
      toggleBulkAddAsset();
    } else if (name === "singleAssetSubmit") {
      setAssetAddModalOpen(!assetAddModalOpen);
      instance
        .request({
          method: "POST",
          url: assetApi,
          data: {
            organisationId: localStorage.getItem("orgId"),
            numberOfCards: noOfCards,
            asset_type: assetType,
            propertyId: activeAssetDropdown,
          },
        })
        .then(function (response) {
          if (response.status === 200 && response.data.code === 200) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })

        .catch(function (error) {
          console.log(error, "errror");
        }); //axios-end
    } else {
      setCurrentStatus(true);
      state.orgId = localStorage.getItem("orgId");
      props.addUpdateDeleteProperty(state);
    }
  };

  //handle bulk asset download
  const handleBulkAssetDownload = () => {
    const date = new Date().toLocaleDateString();
    const sDate = date.split("/");
    const startDate = `${sDate[2]}-${sDate[0]}-${sDate[1]}`;
    const assetApi = apiUrl + `asset/download-asset-pdf`;

    axios
      .get(assetApi, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
          Authorization: `${localStorage.getItem("token")}`,
        },
        params: {
          startDate,
        },
      })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          toast.success("File Downloading");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Assets.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
          toast.error("File Download Failed");
        }
      })

      .catch(function (error) {
        console.log(error, "errror");
      }); //axios-end
  };

  //handle Taxation
  const handleTaxation = ()=>{

    toast.success("Generating Tax")
    const taxationApi = apiUrl + `taxation/generate-for-mapped-property`
    instance
        .request({
          method: "POST",
          url: taxationApi,
          data: {
            organisationId: localStorage.getItem("orgId"),
          },
        })
        .then(function (response) {
          console.log(response)
          if (response.status === 200 && response.data.code === 200) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })

        .catch(function (error) {
          console.log(error, "errror");
        }); //axios-end
  }

  //handle single asset download
  const handleSingleAssetDownload = (propertyId, propertyStatus) => {
    if (propertyStatus === "MAPPPED") {
      const assetApi = apiUrl + `asset/download-asset-pdf`;

      axios
        .get(assetApi, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
            Authorization: `${localStorage.getItem("token")}`,
          },
          params: {
            propertyId,
          },
        })
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            toast.success("File Downloading");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${propertyId}_Assets.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
          } else {
            toast.error("File Download Failed");
          }
        })

        .catch(function (error) {
          console.log(error, "errror");
        }); //axios-end
    } else {
      toast.error("Operation not available for Unmapped properties!");
    }
  };

  //handle single asset add

  const handleSingleAssetAdd = (propertyId, propertyStatus) => {
    if (propertyStatus === "MAPPED") {
      setAssetAddModalOpen(!assetAddModalOpen);
    } else {
      toast.error("Operation not available for Unmapped properties!");
    }
  };

  //Handle Single Taxation
  const handleSingleTaxation = (propertyId, propertyStatus) => {
    if (propertyStatus === "MAPPED") {

      toast.success("Generating Tax")
      const taxationApi = apiUrl + `taxation/generate-for-mapped-property`
      instance
          .request({
            method: "POST",
            url: taxationApi,
            data: {
              organisationId: localStorage.getItem("orgId"),
              propertyId: propertyId
            },
          })
          .then(function (response) {
            console.log(response)
            if (response.status === 200 && response.data.code === 200) {
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          })
  
          .catch(function (error) {
            console.log(error, "errror");
          }); //axios-end
    } else {
      toast.error("Operation not available for Unmapped properties!");
    }
  };

  // DOWNLOAD EXCEL FILE
  const downloadFile = () => {
    props.downloadProperty(
      localStorage.getItem("orgId"),
      state.search,
      state.status
    );
  };

  //Handle Property Activate Deactivate

  const handlePropertyActivation = (id)=>{

    // toast.success("Generating Tax")
    const taxationApi = apiUrl + `property/activate_deactivate`
    instance
        .request({
          method: "POST",
          url: taxationApi,
          data: {
            organisationId: localStorage.getItem("orgId"),
            govt_property_id: id,
          },
        })
        .then(function (response) {
          console.log(response)
          if (response.status === 200 && response.data.code === 200) {
            setTempPropertyStatus(
              {
                govt_property_id: response.data.property.govt_property_id,
                propertyStatus:response.data.property.propertyStatus,

              }
              )
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })

        .catch(function (error) {
          console.log(error, "errror");
        }); //axios-end
  }


  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    } else {
    }
  });
  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };
  // ENABLE LAODING
  const backDrop = props.loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = (id) => {
    setActiveAssetDropdown(id);
    if(!dropdownOpen){
    setDownloadDrop(false)
    setAddDrop(false)
    }
    setDropdownOpen(!dropdownOpen);
    
  };

  const toggleDropDowns = (name)=>{
    setDropdownOpen(!dropdownOpen)
    if(name==="download")
    {setDownloadDrop(true)
    setAddDrop(false)
    
  }
    else if(name==="add")
    {setAddDrop(true)
    setDownloadDrop(false)}
  }
  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <Breadcrumbs seperator="›" aria-label="breadcrumb">
            <Typography color="textPrimary">{t("property_list.properties")}</Typography>
            <Typography color="textSecondary">{t("property_list.title")}</Typography>
          </Breadcrumbs>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <TextField
                  id="standard-search"
                  size="small"
                  name="search"
                  value={state.search}
                  onChange={handleChange}
                  type="search"
                  variant="outlined"
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    // marginBottom: "-35%",
                  }}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: t("property_list.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
            </div>
            <div className={styles.dropDownDiv}>
              <FormControl
                variant="outlined"
                style={{
                  width: "50%",
                  maxHeight: "70%",
                  alignContent: "center",
                }}
              >
                <InputLabel
                  htmlFor="outlined-age-native-simple"
                  style={{
                    top: "-7px",
                    marginBottom: "100%",
                  }}
                >
                  {t("property_list.status")}
                </InputLabel>
                <Select
                  native
                  value={state.status}
                  name="status"
                  onChange={handleChange}
                  style={{
                    width: "160%",
                    maxHeight: "5%",
                    minHeight: "5%",
                  }}
                  label="Status"
                  inputProps={{
                    name: "status",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={"MAPPED"}>MAPPED</option>
                  <option value={"UNMAPPED"}>UNMAPPED</option>
                  <option value={"ABORTED"}>ABORTED</option>
                  <option value={"FAILED"}>FAILED</option>
                  <option value={"NEW"}>NEW</option>
                  <option value={"DEACTIVATED"}>DEACTIVATED</option>
                </Select>
              </FormControl>
            </div>
            <div className={styles.dropDownDiv}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  borderRadius: "20px",

                  textTransform: "none",
                  width: "60%",
                  fontWeight: "lighter",
                  // marginBottom: "3%",
                  height: "70%",
                }}
                onClick={onSearchProperty}
              >
                {t("property_list.search_btn")}
              </Button>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Dropdown
              isOpen={dropdownOpen && addDrop}
              backgroundColor="#0a0"
              toggle={() => toggleDropDowns("add")}
            >
              <DropdownToggle color="primary" caret>
                {t("property_list.add")}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link
                    to="/add-property"
                    style={{ textDecoration: "none", marginRight: 10 }}
                  >
                    {t("property_list.property")}
                  </Link>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    toggleBulkAddAsset();
                  }}
                >
                  {t("property_list.asset")}
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    handleTaxation();
                  }}
                >
                  {t("property_list.taxation")}
                </DropdownItem>

              </DropdownMenu>
            </Dropdown>

            <Dropdown
              isOpen={dropdownOpen && downloadDrop}
              backgroundColor="#0a0"
              toggle={() => toggleDropDowns("download")}
            >
              <DropdownToggle color="success" caret>
                {t("property_list.download")}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                onClick={() => {
                  downloadFile();
                }}>
                  {t("property_list.property")}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    handleBulkAssetDownload();
                  }}
                >
                  {t("property_list.asset")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>


            <Modal
              isOpen={isBulkAddAsset}
              toggle={toggleBulkAddAsset}
              centered={true}
            >
              <ModalHeader
                toggle={toggleBulkAddAsset}
              >{`Add Bulk Assets`}</ModalHeader>
              <ModalBody className={styles.modalContainer}>
                <div style={{ margin: 10 }}>
                  <mui.TextField
                    id="outlined-basic"
                    label="No of Cards"
                    variant="outlined"
                    placeholder="No of cards"
                    type="number"
                    name="cards"
                    value={noOfCards}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
                <mui.FormControl
                  variant="outlined"
                  className={selectClasses.formControl}
                >
                  <div>
                    <mui.InputLabel id="demo-simple-select-outlined-label">
                      {t("property_list.select_ass_type")}
                    </mui.InputLabel>
                    <mui.Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={assetType}
                      onChange={handleChange}
                      label="Select Asset Type"
                      name="asset_type"
                    >
                      <mui.MenuItem value={"solid_waste_dustbin"}>
                        {t("property_list.solid_waste_dust")}
                      </mui.MenuItem>
                      <mui.MenuItem value={"wet_waste_dustbin"}>
                        {t("property_list.wet_waste_dust")}
                      </mui.MenuItem>
                    </mui.Select>
                  </div>
                </mui.FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="contained"
                  color="primary"
                  name="close"
                  onClick={toggleBulkAddAsset}
                  style={{ marginRight: "2%" }}
                >
                  {t("property_list.cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  name="bulkAssetSubmit"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {t("property_list.add_asset")}
                </Button>
              </ModalFooter>
            </Modal>


            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "properties_bulk_add",
                props.accessList.module
              ) && (
                <Link to="/upload-property" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#3B86FF",
                      color: "white",
                    }}
                  >
                    {t("property_list.upload")}
                    <div style={{ paddingBottom: "5%" }}>
                      <PublishOutlinedIcon />
                    </div>
                  </Button>
                </Link>
              )}
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            <p>
              {t("property_list.delete_msg")} <strong>{state.name}</strong> ?{" "}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              style={{ marginRight: "2%" }}
            >
              {t("property_list.cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </Button>
          </ModalFooter>
        </Modal>
        <div className={styles.table}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <TableRow>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.owner_name")}
                  </TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.contact")}
                  </TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.postal_code")}
                  </TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.samadhan_id")}
                  </TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.gov_id")}
                  </TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.status1")}
                  </TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.prop_ver")}
                  </TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.prop_prof")}
                  </TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.operations")}
                  </TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.delete")}
                  </TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("property_list.edit")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.propertyList.length > 0 &&
                  props.propertyList.map((property) => (
                    <TableRow key={property._id}>
                      <TableCell align="center">{property.ownerName}</TableCell>
                      <TableCell align="center">
                        {property.phone ? property.phone : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {property.postalCode}
                      </TableCell>
                      <TableCell align="center">
                        {property.samadhan_org_Id
                          ? property.samadhan_org_Id.samadhanId
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {property.govt_property_id}
                      </TableCell>
                      <TableCell align="center">
                      { (tempPropertyStatus!==null && tempPropertyStatus.govt_property_id===property.govt_property_id)
                                 ?
                                 <span
                                 style={{
                                   color:
                                     tempPropertyStatus.propertyStatus === "MAPPED"
                                       ? "#099AEE"
                                       : tempPropertyStatus.propertyStatus === "UNMAPPED"
                                       ? "#02AA2F"
                                       : tempPropertyStatus.propertyStatus === "FAILED"
                                       ? "#F2134F"
                                       : tempPropertyStatus.propertyStatus === "ABORTED"
                                       ? "#790A28"
                                       : "#F80808",
                                   textTransform: "uppercase",
                                 }}>
                          {`${tempPropertyStatus.propertyStatus}`}
                          </span>
                                 : 
                        <span
                          style={{
                            color:
                              property.propertyStatus === "MAPPED"
                                ? "#099AEE"
                                : property.propertyStatus === "UNMAPPED"
                                ? "#02AA2F"
                                : property.propertyStatus === "FAILED"
                                ? "#F2134F"
                                : property.propertyStatus === "ABORTED"
                                ? "#790A28"
                                : "#F80808",
                            textTransform: "uppercase",
                          }}
                          // style={{  }}
                        >
                          {property.propertyStatus}
                        </span>}
                      </TableCell>
                      <TableCell align="center" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "property_version_list",
                            props.accessList.module
                          ) && (
                            <Link to={`/property-version/${property._id}`}>
                              <VisibilityIcon
                                style={{
                                  color: "3b86ff",
                                }}
                              />
                            </Link>
                          )}
                      </TableCell>
                      <TableCell align="center" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "property_version_list",
                            props.accessList.module
                          ) && (
                            <Link to={`/property-profile/${property._id}`}>
                              <VisibilityIcon
                                style={{
                                  color: "eeefff",
                                }}
                              />
                            </Link>
                          )}
                      </TableCell>

                      {/* Dropdown column start */}
                      <TableCell align="center" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "property_version_list",
                            props.accessList.module
                          ) && (
                            <Dropdown
                              isOpen={
                                dropdownOpen &&
                                property._id === activeAssetDropdown
                              }
                              size="sm"
                              toggle={() => toggle(property._id)}
                              id={property._id}
                            >
                              <DropdownToggle caret>{t("property_list.dropdown")}</DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    handleSingleAssetAdd(
                                      property._id,
                                      property.propertyStatus
                                    );
                                  }}
                                >
                                  {t("property_list.add_asset")}
                                </DropdownItem>

                                <DropdownItem
                                  onClick={() => {
                                    handleSingleAssetDownload(
                                      property._id,
                                      property.propertyStatus
                                    );
                                  }}
                                >
                                  {t("property_list.down_ass")}
                                </DropdownItem>
                                
                                <DropdownItem
                                  onClick={() => {
                                    handleSingleTaxation(
                                      property._id,
                                      property.propertyStatus
                                    );
                                  }}
                                >
                                  {t("property_list.taxation")}
                                </DropdownItem>

                                <DropdownItem
                                  onClick={() => {
                                   handlePropertyActivation(property.govt_property_id)
                                  }}
                                >
                                 { (tempPropertyStatus!==null && tempPropertyStatus.govt_property_id===property.govt_property_id)
                                 ?
                                 tempPropertyStatus.propertyStatus === `DEACTIVATED` ? `Activate`: `Deactivate`
                                 :
                                 property.propertyStatus === `DEACTIVATED` ? `Activate`: `Deactivate` }

                                </DropdownItem>

                              </DropdownMenu>
                            </Dropdown>
                          )}
                        <Modal
                          isOpen={assetAddModalOpen}
                          toggle={() => {
                            setAssetAddModalOpen(!assetAddModalOpen);
                          }}
                          centered={true}
                        >
                          <ModalHeader
                            toggle={() =>
                              setAssetAddModalOpen(!assetAddModalOpen)
                            }
                          >{`Add Asset For Property`}</ModalHeader>
                          <ModalBody className={styles.modalContainer}>
                            <div style={{ margin: 10 }}>
                              <mui.TextField
                                id="outlined-basic"
                                label="No of Cards"
                                variant="outlined"
                                placeholder="No of cards"
                                type="number"
                                name="cards"
                                value={noOfCards}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                            <mui.FormControl
                              variant="outlined"
                              className={selectClasses.formControl}
                            >
                              <div>
                                <mui.InputLabel id="demo-simple-select-outlined-label">
                                  {t("property_list.select_ass_type")}
                                </mui.InputLabel>
                                <mui.Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={assetType}
                                  onChange={handleChange}
                                  label="Select Asset Type"
                                  name="asset_type"
                                >
                                  <mui.MenuItem value={"solid_waste_dustbin"}>
                                    {t("property_list.solid_waste_dust")}
                                  </mui.MenuItem>
                                  <mui.MenuItem value={"wet_waste_dustbin"}>
                                    {t("property_list.wet_waste_dust")}
                                  </mui.MenuItem>
                                </mui.Select>
                              </div>
                            </mui.FormControl>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              variant="contained"
                              color="primary"
                              name="close"
                              onClick={() =>
                                setAssetAddModalOpen(!assetAddModalOpen)
                              }
                              style={{ marginRight: "2%" }}
                            >
                              {t("property_list.cancel")}
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              name="singleAssetSubmit"
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              {t("property_list.add_asset")}
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </TableCell>
                      <TableCell align="center" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "properties_delete",
                            props.accessList.module
                          ) && (
                            <DeleteForeverIcon
                              onClick={() => toggleModal(property)}
                              style={{
                                padding: "none",
                              }}
                            />
                          )}
                      </TableCell>
                      <TableCell align="center" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "properties_update",
                            props.accessList.module
                          ) && (
                            <Link
                              style={{ textDecorationL: "none" }}
                              to={`/edit-property/${property._id}`}
                            >
                              <img src={edit} alt="Edit" />
                            </Link>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {props.propertyList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {t("property_list.no_record_found")}
              </p>
            )}
          </TableContainer>
        </div>
      </div>
      <div>{props.propertyList.length > 0 && setPage()}</div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    propertyList: state.Property.propertyList,
    success: state.Property.success,
    loading: state.Property.loading,
    page: state.Property.page,
    limit: state.Property.limit,
    total: state.Property.total,
    message: state.Property.message,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProperty: (page, limit, orgId, search, status) =>
      dispatch(acitons.getProperty(page, limit, orgId, search, status)),
    manageProperty: (data) => dispatch(acitons.addUpdateDeleteProperty(data)),
    addUpdateDeleteProperty: (data) =>
      dispatch(acitons.addUpdateDeleteProperty(data)),
    downloadProperty: (orgId, search, status) =>
      dispatch(acitons.downloadProperty(orgId, search, status)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(AllProperties);
