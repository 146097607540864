import React, {useEffect, useState} from "react";

//---CSS related imports---//
import styles from "./Survey.module.css";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withTranslation, useTranslation } from "react-i18next";

//-----------------------//

//Iframe to load angular project inside it//
import Iframe from 'react-iframe'
//---------------------------------------//


import "react-datepicker/dist/react-datepicker.css";

const drawerWidth = 252;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));


// FUNCTIONAL COMPONENT STARTS
export function Survey() {

  const [t] = useTranslation("common");

  const [isShown, setIsShown] = useState(false)
  useEffect(()=>{
    setTimeout(function(){ setIsShown(true)}, 5000);
  },[])
  const classes = useStyles();

  const show = isShown?"visible":"hidden"
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: true,
        })}
      >
        <div className={styles.title}>{t("survey.survey")}</div>
        <div style={{ height: "80vh" }}>
          <Iframe
            // url={`https://surveystaging.samadhan.tech/auth/login?token=${localStorage.getItem("token")}&organisationId=${localStorage.getItem("orgId")}`}//staging
            url={`https://survey.samadhan.tech/auth/login?token=${localStorage.getItem("token")}&organisationId=${localStorage.getItem("orgId")}`}//production
            width="100%"
            height="100%"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
            visibility={show}
          />
        </div>
      </main>
    </div>
  );
}

export default withTranslation()(Survey);
