/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { hasAccess } from "../../../../../shared/HasAccess";
import * as mui from "../../../../../shared/mui";
import moment from "moment";
import { useTranslation, withTranslation } from "react-i18next";
import edit from "./edit.png";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import styles from "./Initialisation.module.css";
import download from "../../../../../assets/upload1.png";
import * as actions from "../../../../../store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const useStyles = mui.makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .Mui-focused": {
      borderColor: "#F5F6FA",
    },
    "& .MuiTableCell-root": {
      fontSize: "13px",
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    focused: {
      borderColor: "#F5F6FA",
    },
    input: {
      "&::placeholder": {
        color: "#4D4F5C",
      },
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  table: {
    minWidth: 650,
    borderRadius: 0,
    fontSize: "13px",
  },
  select: {
    minWidth: "8.5vw",
    [theme.breakpoints.down('sm')]: {
      minWidth: "25vw",
    },
    [theme.breakpoints.up('md')]: {
      minWidth: "10vw",
    },
    background: "white",
    color: mui.grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    margin: "5px",
    paddingLeft: "5px",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingRight: "4px",
    fontSize: "13px",
    "&:hover": {
      borderColor: mui.grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: mui.blue[200],
    },
  },
  icon: {
    color: mui.grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
}));

export function Initialisation(props) {
  const borderSelectClasses = mui.useBorderSelectStyles();

  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };
  const classes = useStyles();
  const [t] = useTranslation("common");
  const history = useHistory();
  const [state, setState] = useState({
    orgId: "",
    search: "",
    jobType: "",
    status: "0",
    authType: false,
  });

  const iconComponent = (props) => {
    return (
      <mui.ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };

  const url = useRouteMatch();
  let icon = <mui.SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  useEffect(() => {
    InitializeAPI();
  }, []);

  //
  const InitializeAPI = () => {
    let id = localStorage.getItem("orgId");
    let pageType = url.path.split("/")[2];
    let authType =
      props.accessList.module !== undefined &&
      hasAccess("submoduleId", "job_list", props.accessList.module);
    setState({ ...state, jobType: pageType, authType: authType });
    props.getJobList(1, props.limit, id, pageType, state.search, "", authType);
  };

  // handle change
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "status" && e.target.value === "0") {
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  // edit job
  const editJob = (e, job) => {
    let jobType = state.jobType;
    let title = job.title.replace(/\//g, "&");
    let description = job.description.replace(/\//g, "&");
    let start_date = job.start_date;
    let end_date = job.end_date;
    let categoryName = job.category.name;
    let subcategoryName = job.subcategory.name
      ? job.subcategory.name.replace(/\//g, "&")
      : " ";
    let assigneeId = job.assignee._id;
    let assigneeName = job.assignee.name;
    let jobId = job._id;
    let status = job.status;
    let nfcCard =
      job.number_of_cards === undefined ? "nfc" : job.number_of_cards;
    let isAssessment = job.assessment;
    let isAssetDistribution = job.asset;
    // <Route path="/edit-job/:type/:title/:description/:startDate/:endDate/:categoryId/:subcategoryId/:assignee/:name/:id/:status/:nfc" render={(props) => <Drawer page={<AddNewJob />} />}/>
    history.push(
      `/edit-job/${jobType}/${title}/${description}/${start_date}/${end_date}/${categoryName}/${subcategoryName}/${assigneeId}/${assigneeName}/${jobId}/${status}/${nfcCard}/${isAssessment}/${isAssetDistribution}`
    );

    // history.push(`/edit-job/${state.jobType}/${job.title}/${job.description}/${job.start_date}/${job.end_date}/${job.category.name}/${job.subcategory.name}/${job.assignee._id}/${job.assignee.name}/${job._id}/${job.status}/${"dfd"}`)
  };

  // view job
  // edit job
  const viewJob = (e, job) => {
    if (state.jobType === "installation") {
      history.push(
        `/operations/job-details/${state.jobType}/${job.title}/${job.description}/${job.start_date}/${job.end_date}/${job.category.name}/${job.subcategory.name}/${job.assignee._id}/${job.assignee.name}/${job._id}/${job.status}/${job.number_of_cards}`
      );
    } else {
      history.push(
        `/operations/job-details-init-qc/${state.jobType}/${job.title}/${job.description}/${job.start_date}/${job.end_date}/${job.nfc}/${job.subcategory.name}/${job.assignee._id}/${job.assignee.name}/${job._id}/${job.status}/${job.number_of_cards}`
      );
    }
  };
  // ON SEACH JOB
  const onSearchJob = (e) => {
    let orgId = localStorage.getItem("orgId");
    props.getJobList(
      1,
      props.limit,
      orgId,
      state.jobType,
      state.search,
      state.status === "0" ? "" : state.status,
      state.authType
    );
  };
  const handleChangePage = (event, page) => {
    props.getJobList(
      page,
      props.limit,
      localStorage.getItem("orgId"),
      state.jobType,
      state.search,
      state.status === "0" ? "" : state.status,
      state.authType
    );
  };
  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <mui.Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  // DOWNLAOD EXCEL FILE
  const downloadFile = () => {
    props.downloadExcelFile(
      localStorage.getItem("orgId"),
      state.jobType,
      state.search
    );
  };

  // ENABEL LAODING
  const backDrop = props.loading ? (
    <mui.Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  
  return (
    <div className={styles.main}>
      <mui.CssBaseline />
      {backDrop}
      <div className={styles.title}>
        <Breadcrumbs seperator="›" aria-label="breadcrumb">
          <Typography color="textPrimary">{t("job_creation.operation")}</Typography>
          <Link to="/operations/jobcreation" >
            <Typography color="textPrimary">{t("job_creation.job_creation")}</Typography>
          </Link>
          <Typography color="textSecondary">{t("job_creation.initialisation")}</Typography>
        </Breadcrumbs>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <mui.TextField
                  id="standard-search"
                  size="small"
                  type="search"
                  name="search"
                  value={state.search}
                  onChange={handleChange}
                  variant="outlined"
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    marginRight: "8px",
                  }}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: t("job_reporting.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />

                <div className={styles.dropDownDiv}>
                  <mui.FormControl>
                    <mui.Select
                      disableUnderline
                      labelId="inputLabel"
                      name="status"
                      placeholder="Status"
                      IconComponent={iconComponent}
                      className={classes.select}
                      MenuProps={menuProps}
                      value={state.status}
                      onChange={handleChange}
                      style={{
                        // marginRight: "2%",
                      }}
                    >
                      <mui.MenuItem value={0}>
                        {" "}
                        {t("job_reporting.status")}{" "}
                      </mui.MenuItem>{" "}
                      <mui.MenuItem value={true}>
                        {" "}
                        {t("job_reporting.active")}{" "}
                      </mui.MenuItem>{" "}
                      <mui.MenuItem value={false}>
                        {" "}
                        {t("job_reporting.in_active")}{" "}
                      </mui.MenuItem>{" "}
                    </mui.Select>
                  </mui.FormControl>
                  <mui.Button
                    variant="contained"
                    style={{
                      margin: "5px",
                      backgroundColor: "#4d4f5c",
                      color: "white",
                      borderRadius: "20px",
                      textTransform: "none",
                      width: "35%",
                      outline: "none",
                    }}
                    onClick={onSearchJob}
                  >
                    {t("job_reporting.search_btn")}
                  </mui.Button>
                </div>
              
              </div>
            </div>
          </div>
          <div className={styles.buttonAndFilter}>
            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "job_download",
                props.accessList.module
              ) && (
                <mui.Button
                  variant="contained"
                  color="secondary"
                  style={{
                    textTransform: "none",
                    textAlign: "center",
                    width: "100px",
                    // alignSelf: "right",
                    outline: "none",
                    backgroundColor: "#3B86FF",
                    fontSize: "10px",
                  }}
                  onClick={downloadFile}
                >
                  {t("job_reporting.download")}
                  <img
                    src={download}
                    style={{
                      transform: "rotate(180deg)",
                      marginLeft: "30%",
                    }}
                    alt="download"
                  />
                </mui.Button>
              )}
            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "job_add",
                props.accessList.module
              ) && (
                <Link
                  style={{
                    textDecoration: "none",
                    textAlign: "center",
                    width: "60%",
                    // alignSelf: "right",
                    marginLeft: "2%",
                    outline: "none",
                  }}
                  to={`/add-job/${state.jobType}`}
                >
                  <mui.Button
                    variant="contained"
                    color="secondary"
                    onClick={() => { }}
                    style={{
                      textTransform: "none",
                      textAlign: "center",
                      width: "150px",
                      // alignSelf: "right",
                      marginLeft: "2%",
                      outline: "none",
                    }}
                  >
                    {t("job_reporting.create_new_job")}
                  </mui.Button>
                </Link>
              )}
          </div>
        </div>
        <div className={styles.table}>
          <mui.TableContainer component={mui.Paper}>
            <mui.Table className={classes.table} aria-label="simple table">
              <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <mui.TableRow>
                  <mui.TableCell
                    style={{
                      textAlign: "justify",
                      color: "#A3A6B4",
                      fontSize: "small",
                    }}
                  >
                    {t("job_reporting.job_title")}
                  </mui.TableCell>
                  <mui.TableCell
                    align="center"
                    style={{
                      textAlign: "center",
                      color: "#A3A6B4",
                      fontSize: "small",
                    }}
                  >
                    {t("job_reporting.associate")}
                  </mui.TableCell>
                  <mui.TableCell
                    align="center"
                    style={{
                      textAlign: "center",
                      color: "#A3A6B4",
                      fontSize: "small",
                    }}
                  >
                    {t("job_reporting.start_date")}
                  </mui.TableCell>
                  <mui.TableCell
                    align="center"
                    style={{
                      textAlign: "center",
                      color: "#A3A6B4",
                      fontSize: "small",
                    }}
                  >
                    {t("job_reporting.end_date")}
                  </mui.TableCell>
                  <mui.TableCell
                    align="center"
                    style={{
                      textAlign: "center",
                      color: "#A3A6B4",
                      fontSize: "small",
                    }}
                  >
                    {t("job_reporting.creation_date")}
                  </mui.TableCell>
                  {state.jobType !== "installation" && (
                    <mui.TableCell
                      align="center"
                      style={{
                        textAlign: "center",
                        color: "#A3A6B4",
                        fontSize: "small",
                      }}
                    >
                      {t("job_reporting.nfc_tag_assigned")}
                    </mui.TableCell>
                  )}
                  {state.jobType !== "installation" && (
                    <mui.TableCell
                      align="center"
                      style={{
                        textAlign: "center",
                        color: "#A3A6B4",
                        fontSize: "small",
                      }}
                    >
                      {t("job_reporting.nfc_processed")}
                    </mui.TableCell>
                  )}
                  <mui.TableCell
                    align="center"
                    style={{
                      textAlign: "center",
                      color: "#A3A6B4",
                      fontSize: "small",
                    }}
                  >
                    {t("job_reporting.job_status")}
                  </mui.TableCell>

                  {state.jobType === "installation" && (
                    <mui.TableCell
                      align="center"
                      style={{
                        textAlign: "center",
                        color: "#A3A6B4",
                        fontSize: "small",
                      }}
                    >
                      {t("job_reporting.total_property")}
                    </mui.TableCell>
                  )}
                  {state.jobType === "installation" && (
                    <mui.TableCell
                      align="center"
                      style={{
                        textAlign: "center",
                        color: "#A3A6B4",
                        fontSize: "small",
                      }}
                    >
                      {t("job_reporting.installed_property")}
                    </mui.TableCell>
                  )}
                  {state.jobType === "installation" && (
                    <mui.TableCell
                      align="center"
                      style={{
                        textAlign: "center",
                        color: "#A3A6B4",
                        fontSize: "small",
                      }}
                    >
                      {t("job_reporting.aborted_property")}
                    </mui.TableCell>
                  )}
                  {state.jobType === "installation" && (
                    <mui.TableCell
                      align="center"
                      style={{
                        textAlign: "center",
                        color: "#A3A6B4",
                        fontSize: "small",
                      }}
                    >
                      {t("job_reporting.failed_property")}
                    </mui.TableCell>
                  )}
                  {state.jobType === "installation" && (
                    <mui.TableCell
                      align="center"
                      style={{
                        textAlign: "center",
                        color: "#A3A6B4",
                        fontSize: "small",
                      }}
                    >
                      {t("job_reporting.unmapped_property")}
                    </mui.TableCell>
                  )}
                  {state.jobType === "installation" && (
                    <mui.TableCell
                      align="center"
                      style={{
                        textAlign: "center",
                        color: "#A3A6B4",
                        fontSize: "small",
                      }}
                    >
                      {t("job_reporting.approved_property")}
                    </mui.TableCell>
                  )}

                  {state.jobType === "installation" && (
                    <mui.TableCell
                      align="center"
                      style={{
                        textAlign: "center",
                        color: "#A3A6B4",
                        fontSize: "small",
                      }}
                    >
                      {`JOBS COMPLETED`}
                    </mui.TableCell>
                  )}

                  <mui.TableCell
                    style={{
                      textAlign: "justify",
                      color: "#A3A6B4",
                      fontSize: "small",
                    }}
                  >
                    {`VIEW JOB`}
                  </mui.TableCell>
                  <mui.TableCell
                    style={{
                      textAlign: "justify",
                      color: "#A3A6B4",
                      fontSize: "small",
                    }}
                  >
                    {`EDIT JOB`}
                  </mui.TableCell>
                  <mui.TableCell
                    style={{
                      textAlign: "justify",
                      color: "#A3A6B4",
                      fontSize: "small",
                    }}
                  ></mui.TableCell>

                  <mui.TableCell
                    style={{
                      textAlign: "justify",
                      color: "#A3A6B4",
                      fontSize: "small",
                    }}
                  ></mui.TableCell>
                </mui.TableRow>
              </mui.TableHead>
              <mui.TableBody>
                {props.jobList.length > 0 &&
                  props.jobList.map((job) => (
                    <mui.TableRow key={job._id}>
                      <mui.TableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {job.title}
                      </mui.TableCell>
                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {job.assignee.name}
                      </mui.TableCell>
                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {moment(job.start_date).format("DD/MM/YYYY")}
                      </mui.TableCell>
                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {moment(job.end_date).format("DD/MM/YYYY")}
                      </mui.TableCell>
                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {moment(job.createdAt).format("DD/MM/YYYY")}
                      </mui.TableCell>
                      {state.jobType !== "installation" && (
                        <mui.TableCell
                          align="center"
                          style={{
                            color: "#4D4F5C",
                            fontFamily: "Regular 13px/20px Source Sans Pro",
                          }}
                        >
                          {job.number_of_cards}
                        </mui.TableCell>
                      )}
                      {state.jobType !== "installation" && (
                        <mui.TableCell
                          align="center"
                          style={{
                            color: "#4D4F5C",
                            fontFamily: "Regular 13px/20px Source Sans Pro",
                          }}
                        >
                          {job.nfc_processed}
                        </mui.TableCell>
                      )}
                      <mui.TableCell align="center">
                        <span
                          style={{
                            textAlign: "center",
                            color: job.status
                              ? "#3BB209"
                              : !job.status
                                ? "#707070"
                                : "#F80808",
                          }}
                        >
                          {job.status ? "Active" : "In-Active"}
                        </span>
                      </mui.TableCell>
                      {state.jobType === "installation" && (
                        <mui.TableCell>{job.total_properties}</mui.TableCell>
                      )}
                      {state.jobType === "installation" && (
                        <mui.TableCell>{job.property_mapped}</mui.TableCell>
                      )}
                      {state.jobType === "installation" && (
                        <mui.TableCell>{job.property_aborted}</mui.TableCell>
                      )}
                      {state.jobType === "installation" && (
                        <mui.TableCell>{job.property_failed}</mui.TableCell>
                      )}
                      {state.jobType === "installation" && (
                        <mui.TableCell>{job.property_unmapped}</mui.TableCell>
                      )}
                      {state.jobType === "installation" && (
                        <mui.TableCell>{job.property_approved}</mui.TableCell>
                      )}

                      {state.jobType === "installation" && (
                        <mui.TableCell>{`${parseInt(
                          (job.property_approved / job.total_properties) * 100
                        )}%`}</mui.TableCell>
                      )}

                      <mui.TableCell
                        title="View Job Details"
                        align="justify"
                        padding="checkbox"
                      >
                        <mui.VisibilityIcon
                          onClick={(e) => {
                            viewJob(e, job);
                          }}
                          style={{
                            color: "3b86ff",
                            cursor: "pointer",
                          }}
                        />
                      </mui.TableCell>

                      <mui.TableCell align="center" padding="checkbox">
                        <Link
                          title="Edit Job"
                          style={{
                            textDecorationL: "none",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            onClick={(e) => {
                              editJob(e, job);
                            }}
                            src={edit}
                            alt="Edit"
                          />
                        </Link>
                      </mui.TableCell>
                    </mui.TableRow>
                  ))}
              </mui.TableBody>
            </mui.Table>
            {props.jobList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {" "}
                {t("job_reporting.no_record_found")}
              </p>
            )}
          </mui.TableContainer>
        </div>
      </div>
      <div className={styles.paginationDiv}>
        {props.jobList.length > 0 && setPage()}
      </div>
      {/* </main> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allCategoryList: state.Category.category.allCategoryList,
    subcategoryList: state.Property.subcategoryList,
    // jobList:state.Operation.orgUserList,
    jobList: state.Operation.jobList,
    loading: state.Operation.loading,
    page: state.Operation.page,
    limit: state.Operation.limit,
    total: state.Operation.total,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobList: (page, limit, orgId, jobType, search, status, type) =>
      dispatch(
        actions.getJobList(page, limit, orgId, jobType, search, status, type)
      ),
    downloadExcelFile: (orgId, jobType, search) =>
      dispatch(actions.downloadExcel(orgId, jobType, search)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Initialisation);
