import React, { useEffect, useState } from "react";
import styles from "./Installation.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import InitialisationTable from "../../../../../components/JobCreationTables/InstallationTable/InstallationTable";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link, useHistory } from 'react-router-dom';
import * as mui from "../../../../../shared/mui";
import { useTranslation } from "react-i18next";



const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .Mui-focused": {
			borderColor: "#F5F6FA",
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "30ch",
		},
		"& .MuiPagination-root": {
			margin: theme.spacing(3),
			color: "standard",
		},
		focused: {
			borderColor: "#F5F6FA",
		},
		input: {
			"&::placeholder": {
				color: "#4D4F5C",
			},
		},
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
}));


export default function Language(props) {
	const classes = useStyles();
    const [t] = useTranslation("common");
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	
	useEffect(() => {
  if (loading) {
    setTimeout(() => {
    setLoading(false);
  }, 2000);
  }
}, [loading]);
  

  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading} >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

	let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<Breadcrumbs seperator="›" aria-label="breadcrumb">
					<Typography color="textPrimary">{t("job_creation.operation")}</Typography>
					<Link to="/operations/jobcreation" >
						<Typography color="textPrimary">{t("job_creation.job_creation")}</Typography>
					</Link>
					<Typography color="textSecondary">{t("job_creation.installation")}</Typography>
				</Breadcrumbs>
			</div>
			<div className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div>
							<div className={styles.searchBar}>
								<TextField
									id="standard-search"
									size="small"
									type="search"
									variant="outlined"
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
									}}
									InputProps={{
										startAdornment: icon,
										placeholder: t("job_reporting.search"),
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/>
							</div>
						</div>
						<div className={styles.dropDownDiv}>
							<Button
								variant="contained"
								style={{
									backgroundColor: "#4d4f5c",
									color: "white",
									borderRadius: "20px",
									textTransform: "none",
									width: "35%",
									outline: "none",
									// height: "110%",
								}}
							>
								{t("job_reporting.search")}
							</Button>
						</div>
					</div>
					<div className={styles.buttonAndFilter}>
			
            
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {history.push("/add-job/installation");}} 
							style={{
								textTransform: "none",
								textAlign: "center",
								// width: "60%",
								alignSelf: "right",
								marginLeft: "48%",
								outline: "none",
							}}
						>
							Create new job
						</Button>
					</div>
				</div>
				<div className={styles.table}>
					<InitialisationTable />
				</div>
			</div>
			<Pagination
				count={5}
				shape="rounded"
				color="primary"
				variant="outlined"
				style={{
					marginTop: "2%",
				}}
			/>
		</div>
	);
}
