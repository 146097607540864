import React, { useEffect, useState } from "react";

import styles from "./AuditTable.module.css";
import { useTranslation } from "react-i18next";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import * as mui from "../../../../../../shared/mui";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Pagination from "@material-ui/lab/Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Dropdown from "../../../../../../components/Select/Select";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "30ch",
		},
		"& .MuiPagination-root": {
			margin: theme.spacing(3),
			color: "standard",
		},
		root1: {
			flexGrow: 1,
			maxHeight: "60%",
		},
		table: {
			minWidth: 650,
			borderRadius: 0,
			overflowY: "auto",
			overflowX: "auto",
			maxHeight: 10,
			overflow: "scroll",
		},
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
}));
function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const rows = [
	createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
	createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
	createData("Eclair", 262, 16.0, 24, 6.0),
	createData("Cupcake", 305, 3.7, 67, 4.3),
	createData("Gingerbread", 356, 16.0, 49, 3.9),
	createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
	createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
	createData("Eclair", 262, 16.0, 24, 6.0),
	createData("Cupcake", 305, 3.7, 67, 4.3),
	createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function Language() {
  const [t] = useTranslation("common");
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [modal, setModal] = useState(false);
	const toggleModal = () => setModal(!modal);

	useEffect(() => {
  if (loading) {
    setTimeout(() => {
    setLoading(false);
  }, 2000);
  }
}, [loading]);
  

  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading} >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<Breadcrumbs seperator="›" aria-label="breadcrumb">
					<Typography color="textPrimary">{t("job_reporting.operations")}</Typography>
					{/* <Link to="/payments"> */}
						<Typography color="textPrimary">{t("job_reporting.job_reporting")}</Typography>
					{/* </Link> */}
					<Typography color="textSecondary">{t("job_reporting.audit_table")}</Typography>
				</Breadcrumbs>
				
			</div>
			<div className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div>
							<div className={styles.searchBarDiv}>
								{/* <TextField
									id="standard-search"
									size="small"
									type="search"
									variant="outlined"
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
										marginBottom: "5%",
										width: "60%",
									}}
									InputProps={{
										startAdornment: icon,
										placeholder: "Search..",
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/> */}
								{/* <FormControl
									variant="outlined"
									style={{ marginLeft: "1%", height: "30%" }}
								>
									<InputLabel
										htmlFor="outlined-age-native-simple"
										style={{
											alignText: "center",
										}}
									>
										Filter
									</InputLabel>
									<Select
										native
										value={state.age}
										onChange={handleChange}
										style={{
											maxHeight: "40%",
											marginBottom: "5%",
										}}
										label="Filter"
										inputProps={{
											name: "Filter",
											id: "outlined-age-native-simple",
										}}
									>
										<option aria-label="None" value="" />
										<option value={10}>Ten</option>
										<option value={20}>Twenty</option>
										<option value={30}>Thirty</option>
									</Select>
								</FormControl> */}
								{/* <Dropdown holder="Filter" /> */}
							</div>
						</div>
						<div className={styles.dropDownDiv}>
							{/* <Button
								variant="contained"
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "20px",

									textTransform: "none",
									width: "45%",
									fontWeight: "lighter",
									height: "70%",
									marginBottom: "3%",
									marginLeft: "5%",
									marginRight: "5%",
								}}
							>
								Search
							</Button> */}
							{/* <FormControl variant="outlined">
								<InputLabel
									htmlFor="outlined-age-native-simple"
									style={{ alignText: "center" }}
								>
									Week
								</InputLabel>
								<Select
									native
									value={state.age}
									onChange={handleChange}
									style={{
										maxHeight: "80%",
										marginBottom: "5%",
									}}
									label="Filter"
									inputProps={{
										name: "Filter",
										id: "outlined-age-native-simple",
									}}
								>
									<option aria-label="None" value="" />
									<option value={10}>Ten</option>
									<option value={20}>Twenty</option>
									<option value={30}>Thirty</option>
								</Select>
							</FormControl> */}
							<Dropdown holder="Week" />
						</div>
					</div>
					<div className={styles.buttonAndFilter}>
						<span
							style={{
								textAlign: "center",
								alignSelf: "center",
								fontSize: "medium",
								color: "#43425D",
							}}
						>
							{t("job_reporting.from_date")}
						</span>
						<TextField
							id="date"
							variant="outlined"
							type="date"
							size="small"
							defaultValue={new Date()}
							// className={classes.textField}
							InputLabelProps={{
								shrink: true,
							}}
							style={{
								width: "20%",
							}}
						/>
						<span
							style={{
								textAlign: "center",
								fontSize: "medium",
								alignSelf: "center",
								color: "#43425D",
							}}
						>
							{t("job_reporting.to_date")}
						</span>
						<TextField
							id="date"
							variant="outlined"
							type="date"
							size="small"
							defaultValue={new Date()}
							// className={classes.textField}
							InputLabelProps={{
								shrink: true,
							}}
							style={{
								width: "20%",
							}}
						/>
					</div>
					<div>
						<Button
							variant="contained"
							onClick={toggleModal}
							style={{
								textTransform: "none",
								textAlign: "center",
								// height: "95%",
								marginBottom: "1%",
								backgroundColor: "#3B86FF",
								color: "white",
							}}
						>
						{t("job_reporting.download")}
						</Button>
					</div>
					
				</div>

				<div className={styles.table}>
					<div style={{ overflow: "auto" }}>
						<Table aria-label="simple table">
							<TableHead
								style={{
									backgroundColor: "#F5F6FA",
									position: "sticky",
									top: "0",
								}}
							>
								<TableRow>
									<TableCell
										align="left"
										style={{
											textAlign: "justify",
											color: "#A3A6B4",
										}}
									>
										S.NO
									</TableCell>
									<TableCell
										style={{
											textAlign: "center",
											color: "#A3A6B4",
										}}
									>
										THE NUMBER OF PROPERTIES SENT INTO
										REATTEMPT PER DAY
									</TableCell>
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
										}}
									>
										TOTAL PENDING PROPERTIES WITH REATTEMPT
										TILL NOW
									</TableCell>
								</TableRow>
							</TableHead>
						</Table>
						<div style={{ overflow: "auto", height: "20vw" }}>
							<Table
								aria-label="simple table"
								className={classes.table}
								style={{ tableLayout: "fixed" }}
							>
								<TableBody>
									{rows.map((row) => (
										<TableRow key={row.name}>
											<TableCell
												align="left"
												component="th"
												scope="row"
												style={{
													color: "#4D4F5C",
													fontFamily:
														"Regular 13px/20px Source Sans Pro",
												}}
											>
												1
											</TableCell>
											<TableCell
												align="left"
												component="th"
												scope="row"
												style={{
													color: "#4D4F5C",
													fontFamily:
														"Regular 13px/20px Source Sans Pro",
												}}
											>
												250
											</TableCell>
											<TableCell
												align="left"
												component="th"
												scope="row"
												style={{
													color: "#4D4F5C",
													fontFamily:
														"Regular 13px/20px Source Sans Pro",
												}}
											>
												75
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</div>
				</div>

				<div className={classes.root}></div>
			</div>
			<div className={styles.paginationDiv}>
				<Pagination
					count={5}
					shape="rounded"
					color="primary"
					variant="outlined"
					style={{
						marginTop: "2%",
						// marginLeft: "2%",
					}}
				/>
			</div>
		</div>
	);
}
