/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Stepper.module.css";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue, grey } from "@material-ui/core/colors";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { toast } from "react-toastify";
import * as $ from "jquery";

import * as actions from "../../store/actions/index";
import React, { useEffect, useState } from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-i18next";
import axios from "axios";
import * as myConstClass from "../../constants/constants";
const api_url = myConstClass.apiUrl;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#eaeaf0",
    position: "relative",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  select: {
    minWidth: "20.5vw",
    "@media (min-width: 321px) and (max-width: 374px)": {
      minWidth: "89vw",
    },
    "@media (min-width: 375px) and (max-width: 400px)": {
      minWidth: "85vw",
    },
    "@media (max-width:320px)": {
      minWidth: "98vw",
    },
    "@media (min-width: 426px) and (max-width: 768px)": {
      minWidth: "60vw",
    },
    background: "white",
    color: grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "10px",
    paddingBottom: "10px",
    "&:hover": {
      borderColor: grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: blue[200],
    },
  },
  icon: {
    color: grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
}));

function getSteps(t) {
  return [
    t("profile.basic_details"),
    t("profile.fill_address"),
    t("profile.done"),
  ];
}

export function HorizontalLabelPositionBelowStepper(props) {

  const [t] = useTranslation("common");

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps(t);

  const handleNext = (e) => {
    if (activeStep === 2) {
      handleSubmit(e);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = (e) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const borderSelectClasses = useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const classes = useStyles();

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };

  /// Display country
  const fetchCountry = (type) => {
    if (
      type === "country" &&
      userForm.countryId !== "0" &&
      props.countryList.length > 0
    ) {
      let country = props.countryList.find((x) => x._id === userForm.countryId);
      return <span>{country.country}</span>;
    } else if (
      type === "state" &&
      userForm.stateId !== "0" &&
      props.stateList.length > 0
    ) {
      let state = props.stateList.find((x) => x._id === userForm.stateId);
      return <span>{state.state}</span>;
    } else if (
      type === "district" &&
      userForm.districtId !== "0" &&
      props.districtList.length > 0
    ) {
      let dist = props.districtList.find((x) => x._id === userForm.districtId);
      return <span>{dist.district}</span>;
    } else if (
      type === "blood" &&
      userForm.bloodId !== "0" &&
      props.bloodList.length > 0
    ) {
      let dist = props.bloodList.find((x) => x._id === userForm.bloodId);
      return <span>{dist.bloodGroup}</span>;
    } else if (
      type === "language" &&
      userForm.languageId !== "0" &&
      props.languageList.length > 0
    ) {
      let lang = props.languageList.find((x) => x._id === userForm.languageId);
      return <span>{lang.language}</span>;
    } else {
      return "-";
    }
  };

  const [userForm, setUserForm] = React.useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    postalCode: "",
    emergenyNumber: "",
    phone: "",
    bloodId: "0",
    address: "",
    languageId: "0",
    countryId: "0",
    stateId: "0",
    districtId: "0",
    cityId: "0",
  });

  const errors = {}
  const [currentStatus, setCurrentStatus] = useState(true);
  const [loading, setLoading] = useState(false);

  const [fileSrc, setFile] = useState("");
  // upload  file image
  const upploadProfile = (e, type, i) => {
    if (e.target.files && e.target.files.length > 0) {
      var validExtensions = ["jpeg", "png", "jpg", "PNG", "JPG"];

      var isValid = true;
      for (let img of e.target.files) {
        if (
          $.inArray(
            img.name.substr(img.name.lastIndexOf(".") + 1),
            validExtensions
          ) === -1
        ) {
          e.target.value = "";
          isValid = false;
          toast.error(t("profile.error"));
        }
        break;
      }

      if (isValid) {
        let reader = new FileReader();
        reader.onloadend = (e) => {
          setFile(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        uploadFile(e.target.files[0]);
      }
    } else {
      e.preventDefault();

      return;
    }
  };

  // UPLOAD PROFILE IMAGE
  const uploadFile = (data) => {
    let url = `user/profileImage-upload`;
    var formData = new FormData();
    formData.append("image", data);

    setLoading(true);
    axios
      .post(api_url + url, formData, {
        headers: {
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        toast.success(response.data.message);
        window.location.reload(false);
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err.response.data.message);
      });
  };

  const handleInputs = (event) => {
    const name = event.target.name;
    if (event.target.name === "countryId") {
      props.getStateByCountry(event.target.value);
      setUserForm({ ...userForm, [name]: event.target.value });
    } else if (event.target.name === "stateId") {
      setUserForm({ ...userForm, [name]: event.target.value });
      props.getDistrictByState(event.target.value);
    } else if (event.target.name === "districtId") {
      setUserForm({ ...userForm, [name]: event.target.value });
      props.getCityByDistrict(event.target.value);
    } else if (event.target.name === "cityId") {
      setUserForm({ ...userForm, [name]: event.target.value });
    } else {
      setUserForm({
        ...userForm,
        [event.target.name]: event.target.value,
      });
    }
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    props.updateProfile(userForm);
  };
  const forms = [
    <>
      <div className={styles.row1}>
        <TextField
          id="outlined-basic"
          label={
            <span className={styles.label}>{t("profile.first_name")}</span>
          }
          variant="outlined"
          name="firstName"
          value={userForm.firstName}
          helperText={errors.firstName}
          error={errors.firstName}
          onChange={handleInputs}
          style={{ width: "45%" }}
        />
        <TextField
          id="outlined-basic"
          label={<span className={styles.label}>{t("profile.last_name")}</span>}
          variant="outlined"
          style={{ width: "45%" }}
          name="lastName"
          value={userForm.lastName}
          helperText={errors.lastName}
          error={errors.lastName}
          onChange={handleInputs}
        />
      </div>
      <div className={styles.row1}>
        <TextField
          id="outlined-basic"
          label={<span className={styles.label}>{t("profile.username")}</span>}
          variant="outlined"
          style={{ width: "45%" }}
          name="userName"
          value={userForm.userName}
          helperText={errors.userName}
          error={errors.userName}
          onChange={handleInputs}
        />
        <TextField
          id="outlined-basic"
          label={<span className={styles.label}>{t("profile.email")}</span>}
          variant="outlined"
          style={{ width: "45%" }}
          name="email"
          value={userForm.email}
          helperText={errors.email}
          error={errors.email}
          onChange={handleInputs}
        />
      </div>
      <div className={styles.row1}>
        <TextField
          id="outlined-basic"
          label={<span className={styles.label}>{t("profile.phone")}</span>}
          variant="outlined"
          style={{ width: "45%" }}
          name="phone"
          value={userForm.phone}
          helperText={errors.phone}
          error={errors.phone}
          onChange={handleInputs}
        />
        <TextField
          id="outlined-basic"
          label={<span className={styles.label}>{t("profile.em_phone")}</span>}
          variant="outlined"
          style={{ width: "45%" }}
          name="emergenyNumber"
          value={userForm.emergenyNumber}
          helperText={errors.emergenyNumber}
          error={errors.emergenyNumber}
          onChange={handleInputs}
        />
      </div>
      <div className={styles.row2} style={{}}>
        <FormControl>
          <Select
            disableUnderline
            labelId="inputLabel"
            placeholder={t("profile.blood_group")}
            IconComponent={iconComponent}
            className={classes.select}
            MenuProps={menuProps}
            value={props.bloodList.length > 0 ? userForm.bloodId : "0"}
            name="bloodId"
            onChange={handleInputs}
            style={{
              marginRight: "2%",
            }}
          >
            <MenuItem value={"0"}> {t("profile.blood")} </MenuItem>{" "}
            {props.bloodList.length > 0 &&
              props.bloodList.map((blood, i) => (
                <MenuItem value={blood._id}> {blood.bloodGroup} </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            disableUnderline
            labelId="inputLabel"
            placeholder={t("profile.select_lng")}
            IconComponent={iconComponent}
            className={classes.select}
            MenuProps={menuProps}
            value={props.languageList.length > 0 ? userForm.languageId : "0"}
            name="languageId"
            onChange={handleInputs}
            style={{
              marginRight: "2%",
            }}
          >
            <MenuItem value={"0"}> {t("profile.select_lng")} </MenuItem>{" "}
            {props.languageList.length > 0 &&
              props.languageList.map((lang, i) => (
                <MenuItem value={lang._id}> {lang.language} </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </>,
    <>
      <div className={styles.row1}>
        <TextField
          id="outlined-basic"
          label={<span className={styles.label}>{t("profile.address")}</span>}
          variant="outlined"
          size="normal"
          style={{ width: "100%" }}
          name="address"
          value={userForm.address}
          helperText={errors.address}
          error={errors.address}
          onChange={handleInputs}
        />
      </div>
      <div className={styles.row1}>
        <FormControl
          style={{
            marginRight: "3%",
          }}
        >
          <Select
            disableUnderline
            labelId="inputLabel"
            placeholder={t("profile.select_country")}
            IconComponent={iconComponent}
            className={classes.select}
            MenuProps={menuProps}
            value={props.countryList.length > 0 ? userForm.countryId : "0"}
            name="countryId"
            onChange={handleInputs}
            style={{
              marginRight: "2%",
            }}
          >
            <MenuItem value={"0"}>{t("profile.select_country")}</MenuItem>{" "}
            {props.countryList.length > 0 &&
              props.countryList.map((country, i) => (
                <MenuItem value={country._id}> {country.country} </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            disableUnderline
            labelId="inputLabel"
            placeholder={t("profile.select_state")}
            IconComponent={iconComponent}
            className={classes.select}
            MenuProps={menuProps}
            onChange={handleInputs}
            value={props.stateList.length > 0 ? userForm.stateId : "0"}
            name="stateId"
            style={{
              marginRight: "2%",
            }}
          >
            <MenuItem value={"0"}> {t("profile.select_state")}</MenuItem>{" "}
            {props.stateList.length > 0 &&
              props.stateList.map((state, i) => (
                <MenuItem value={state._id}> {state.state} </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <div className={styles.row1}>
        <FormControl>
          <Select
            disableUnderline
            labelId="inputLabel"
            placeholder={t("profile.district")}
            IconComponent={iconComponent}
            className={classes.select}
            MenuProps={menuProps}
            value={props.districtList.length > 0 ? userForm.districtId : "0"}
            name="districtId"
            onChange={handleInputs}
            style={{
              marginRight: "2%",
            }}
          >
            <MenuItem value={"0"}>{t("profile.district")} </MenuItem>{" "}
            {props.districtList.length > 0 &&
              props.districtList.map((dist, i) => (
                <MenuItem value={dist._id}> {dist.district} </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          id="outlined-basic"
          label={
            <span className={styles.label}>{t("profile.postal_code")}</span>
          }
          variant="outlined"
          style={{ width: "45%" }}
          name="postalCode"
          value={userForm.postalCode}
          helperText={errors.postalCode}
          error={errors.postalCode}
          onChange={handleInputs}
        />
      </div>
    </>,
    <>
      <div className={styles.row1} style={{ justifyContent: "center" }}>
        <span className={styles.lastFormElement}>
          <div className={styles.lastFormElement}>
            <span>{t("profile.review")}!</span>
          </div>

          <div className={styles.review}>
            {/* <span className={styles.lastFormElement}>Review Organisation Details and {id?"update":"submit"}!</span> */}
            <ul className={styles.ul}>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.first_name")}
                </b>
                : {userForm.firstName}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.last_name")}
                </b>
                : {userForm.lastName}{" "}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.username")}
                </b>
                : {userForm.userName}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.email")}
                </b>
                : {userForm.email}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.phone")}{" "}
                </b>
                : {userForm.phone}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.em_phone")}
                </b>
                :{userForm.emergenyNumber}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.blood")}
                </b>
                :{activeStep === 2 && fetchCountry("blood")}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.lng")}
                </b>
                :{activeStep === 2 && fetchCountry("language")}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.address")}
                </b>
                : {userForm.address}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.country")}
                </b>
                : {activeStep === 2 && fetchCountry("country")}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.state")}
                </b>
                : {activeStep === 2 && fetchCountry("state")}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.district")}
                </b>
                : {activeStep === 2 && fetchCountry("district")}
              </li>
              <li className={styles.li}>
                <b style={{ fontWeight: "normal" }} className={styles.b}>
                  {t("profile.postal_code")}
                </b>
                : {userForm.postalCode}
              </li>
            </ul>
          </div>
        </span>
      </div>
    </>,
  ];

  useEffect(() => {
    props.getUserById();
    props.getAllBlood();
    setTimeout(() => {
      props.getLanguageList();
      props.getAllCountry();
    }, 1000);
  }, []);

  useEffect(() => {
    if (props.userData.username !== undefined && currentStatus) {
      setUserForm({
        ...userForm,
        firstName: props.userData.name,
        lastName: props.userData.lastName,
        userName: props.userData.username,
        email: props.userData.email,
        phone: props.userData.phone,

        emergenyNumber: props.userData.emergency_number,
        address: props.userData.address ? props.userData.address : "",
        postalCode: props.userData.postal_code
          ? props.userData.postal_code
          : "",

        bloodId:
          props.userData.bloodGroupId !== undefined
            ? props.userData.bloodGroupId
            : "0",
        languageId:
          props.userData.languageId !== undefined
            ? props.userData.languageId
            : "0",
        countryId:
          props.userData.countryId !== undefined
            ? props.userData.countryId._id
            : "0",
        stateId:
          props.userData.stateId !== undefined
            ? props.userData.stateId._id
            : "0",
        districtId:
          props.userData.districtId !== undefined
            ? props.userData.districtId._id
            : "0",
      });
      if (props.userData.profileImage !== undefined) {
        setFile(props.userData.profileImage);
        localStorage.setItem("image", props.userData.profileImage);
      }

      if (props.userData.countryId !== undefined) {
        props.getStateByCountry(props.userData.countryId._id);
      }

      if (props.userData.stateId !== undefined) {
        props.getDistrictByState(props.userData.stateId._id);
      }

      setCurrentStatus(false);
    }
  });

  // ENABEL LAODING
  const backDrop = props.loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;

  const backDrop1 = loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;
  return (
    <div className={styles.main}>
      {backDrop}
      {backDrop1}
      <div className={styles.title}>
        <span>Profile</span>
      </div>
      <div className={styles.avatarDiv}>
        <div className={styles.avatar}>
          <div className={classes.root}>
            {fileSrc ? (
              <Avatar src={fileSrc} className={classes.large}></Avatar>
            ) : (
              <Avatar files className={classes.large}>
                {" "}
                {localStorage.getItem("firstname").split("")[0].toUpperCase()}
              </Avatar>
            )}
            <span className={styles.dot}>
              <label>
                {" "}
                <input
                  onChange={upploadProfile}
                  style={{ display: "none" }}
                  type="file"
                ></input>{" "}
                <EditRoundedIcon
                  style={{
                    fontSize: "small",
                    color: "white",
                    curson: "pointer",
                  }}
                ></EditRoundedIcon>
              </label>
              {/* <EditRoundedIcon style={{ fontSize: 'small',color:'white',curson:"pointer"}} ><input type='file'></input> </EditRoundedIcon> */}
            </span>
          </div>
        </div>
        <div style={{ textAlign: "center", paddingBottom: "3%" }}>
          <span style={{ fontWeight: "bolder", fontSize: "medium" }}>
            {localStorage.getItem("firstname")}{" "}
            {localStorage.getItem("lastname")}
          </span>
        </div>
      </div>
      <div className={styles.container}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <div className={styles.formDiv}>
            <form className={classes.root} noValidate autoComplete="off">
              {forms[activeStep]}
            </form>
          </div>
          <div>
            {activeStep === steps.length ? null : (
              <div className={styles.stepperButtons}>
                <Button
                  hidden={activeStep === 0}
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  {t("profile.previous")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1
                    ? t("profile.submit")
                    : t("profile.next_step")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {

  return {
    loading: state.Organization.loading,
    error: state.Organization.error,
    message: state.Organization.message,
    userData: state.Organization.userData,
    page: state.Organization.page,
    limit: state.Organization.limit,
    total: state.Organization.total,
    languageList: state.Organization.languageList,
    bloodList: state.Organization.bloodList,
    countryList: state.State.state.countryList,
    stateList: state.District.district.stateList,
    districtList: state.City.city.districtList,
    cityList: state.City.city.allCityList,

    //    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserById: () => dispatch(actions.getUserById()),

    getLanguageList: () => dispatch(actions.getAllLangList()),

    getAllBlood: () => dispatch(actions.getAllBlood()),

    deleteOrg: (page, limit, search) =>
      dispatch(actions.deleteOrg(page, limit, search)),
    updateOrgData: (data) => dispatch(actions.updateOrgData(data)),
    getAllCountry: () => dispatch(actions.getAllCountry()),

    getStateByCountry: (stateId) =>
      dispatch(actions.getStateByCountry(stateId)),
    getDistrictByState: (stateId) =>
      dispatch(actions.getDistrictByState(stateId)),
    getCityByDistrict: (distId) => dispatch(actions.getCityByDistrict(distId)),

    updateProfile: (data) => dispatch(actions.updateProfile(data)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(HorizontalLabelPositionBelowStepper);
