/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./Audit.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import { hasAccess } from "../../../../../shared/HasAccess";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useTranslation } from "react-i18next";
import download from "../../../../../assets/upload1.png";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue, grey } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import * as acitons from "../../../../../store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .Mui-focused": {
      borderColor: "#F5F6FA",
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    focused: {
      borderColor: "#F5F6FA",
    },
    input: {
      "&::placeholder": {
        color: "#4D4F5C",
      },
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  select: {
    minWidth: "8.5vw",
    "@media (min-width: 320px) and (max-width: 375px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 376px) and (max-width: 425px)": {
      minWidth: "25vw",
    },
    background: "white",
    color: grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "13px",
    boxShadow: "0px 2px 3px #0000000D",
    "&:hover": {
      borderColor: grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: blue[200],
    },
  },
  icon: {
    color: grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
  input: {
    color: "#4D4F5C",
    fontSize: "smaller",
    paddingLeft: "4px",
  },
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
  select1: {
    minWidth: "8.5vw",
    "@media (min-width: 320px) and (max-width: 375px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 376px) and (max-width: 425px)": {
      minWidth: "25vw",
    },
    background: "white",
    color: grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "13px",
    boxShadow: "0px 2px 3px #0000000D",
    "&:hover": {
      borderColor: grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: blue[200],
    },
  },
}));

export function Audit(props) {
  const [modal, setModal] = useState(false);
  const [imageModal, setImgModal] = useState(false);
  const borderSelectClasses = useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };
  const [t] = useTranslation("common");
  const classes = useStyles();
  const [state, setState] = useState({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    day: "today",
    status: "DRAFT",
    propertyName: "",
    propertyId: "",
    reason: "",
    maxDate: new Date().toISOString().slice(0, 10),
    imgIndex: "",
    ownerImage: [],
  });

  // handle change
  const handleChange = (e) => {
    if (e.target.value === "last_week") {
      let lastDate = addDays(new Date(), -7).toISOString().slice(0, 10);
      let newDate = new Date().toISOString().slice(0, 10);

      setState({
        ...state,
        [e.target.name]: e.target.value,
        startDate: lastDate,
        endDate: newDate,
      });
      props.getAuditJobList(1, props.limit, lastDate, newDate);
    } else if (e.target.value === "last_month") {
      let lastDate = addDays(new Date(), -31).toISOString().slice(0, 10);
      let newDate = new Date().toISOString().slice(0, 10);
      setState({
        ...state,
        [e.target.name]: e.target.value,
        startDate: lastDate,
        endDate: newDate,
      });
      props.getAuditJobList(1, props.limit, lastDate, newDate);
    } else if (e.target.value === "today") {
      let newDate = new Date().toISOString().slice(0, 10);
      setState({
        ...state,
        [e.target.name]: e.target.value,
        startDate: newDate,
        endDate: newDate,
      });
      props.getAuditJobList(1, props.limit, newDate, newDate);
    } else if (e.target.name === "endDate") {
      // let newDate = new Date().toISOString().slice(0, 10);
      setState({
        ...state,
        [e.target.name]: e.target.value,
        endDate: e.target.value,
      });
      props.getAuditJobList(1, props.limit, state.startDate, e.target.value);
    } else if (e.target.name === "startDate") {
      // let newDate = new Date().toISOString().slice(0, 10);
      setState({ ...state, [e.target.name]: e.target.value });
      props.getAuditJobList(1, props.limit, e.target.value, state.endDate);
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  // add and minus date
  const addDays = (theDate, days) => {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  };

  // open reject modal
  const toggleModal = (e, id, name) => {
    setModal(true);
    setState({ ...state, propertyName: name, propertyId: id, reason: "" });
  };
  // open image modal
  const openImageModal = (e, job) => {
    const temp_job = []
    job.image_array.forEach((owner)=>{
      if(owner!=="")
        temp_job.push(owner)
    })

    setImgModal(!imageModal);
    setState({ ...state, ownerImage: temp_job, imgIndex: 0 });
  };

  // close image modal
  const closeImageModal = () => {
    setImgModal(!imageModal);
  };


  const handleImage = (e, type) => {
    if (type === "next") {
      if(state.imgIndex<state.ownerImage.length-1)
      setState({ ...state, imgIndex: state.imgIndex + 1 });
    } else {
      if(state.imgIndex>0)
      setState({ ...state, imgIndex: state.imgIndex - 1 });
    }
  };

  // close modal
  const toggleModalClose = () => {
    setModal(false);
  };

  // handle input
  const handleInputs = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    // console.log(moment(new Date).format('HH:MM:A'))
    var newDate = new Date().toISOString().slice(0, 10);
    props.getAuditJobList(1, 10, newDate, newDate);
  }, []);

  // handle status
  const handleStatus = (e, id, name) => {
    if (e.target.value === "REATTEMPT") {
      toggleModal(e, id, name);
    } else {
      updateStatus(e, id);
    }
  };
  // handle submit on submit reason
  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.reason.trim() === "") {
      return toast.error("Please provide reason", {});
    }
    state.page = props.page;
    state.limit = props.limit;
    props.updatePropertyStatus("REATTEMPT", state.propertyId, state);
  };
  // update property status
  const updateStatus = (e, id) => {
    e.preventDefault();
    state.page = props.page;
    state.limit = props.limit;
    props.updatePropertyStatus(e.target.value, id, state);
  };
  const handleChangePage = (event, page) => {
    props.getAuditJobList(page, props.limit, state.startDate, state.endDate);
  };
  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "1%",
          // float: "right",
        }}
      />
    );
  };
  useEffect(() => {
    if (props.statusUpdate) {
      toggleModalClose();
    }
  });
  // DOWNLAOD EXCEL FILE
  const downloadFile = () => {
    props.downloadExcelFile(state.startDate, state.endDate);
  };
  // ENABEL LAODING
  const backDrop = props.loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;

const useKeypress = () => {
    useEffect(() => {
      function onKeyup(e) {
        if (e.key === "ArrowLeft") {
          handleImage(e,'prev')
          }
        else if(e.key === "ArrowRight"){
          handleImage(e,'next')
          }
        }
      window.addEventListener('keyup', onKeyup);
      return () => window.removeEventListener('keyup', onKeyup);
    });
  }

  useKeypress('left', () => {
    alert('you pressed escape!')
  });
  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <Breadcrumbs seperator="›" aria-label="breadcrumb">
          <Typography color="textPrimary">{t("job_creation.operation")}</Typography>
          <Link to="/operations/jobcreation" >
            <Typography color="textPrimary">{t("job_creation.job_creation")}</Typography>
          </Link>
          <Typography color="textSecondary">{t("job_creation.aduit")}</Typography>
        </Breadcrumbs>
      </div>
      <div className={styles.header}>
        <div className={styles.header2}>
          <div>
            <FormControl>
              <Select
                disableUnderline
                labelId="inputLabel"
                placeholder={t("job_audit.today")}
                name="day"
                IconComponent={iconComponent}
                className={classes.select}
                MenuProps={menuProps}
                value={state.day}
                onChange={handleChange}
                style={{
                  marginRight: "2%",
                }}
              >
                <MenuItem value={"today"}> {t("job_audit.today")}</MenuItem>{" "}
                <MenuItem value={"last_week"}>
                  {" "}
                  {t("job_audit.last_week")}
                </MenuItem>{" "}
                <MenuItem value={"last_month"}>
                  {" "}
                  {t("job_audit.last_month")}{" "}
                </MenuItem>{" "}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              flexDiretion: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#43425D" }}>{t("job_audit.from_date")}</span>
            <TextField
              id="standard-search"
              size="small"
              type="date"
              name="startDate"
              value={state.startDate}
              onChange={handleChange}
              variant="outlined"
              style={{
                borderColor: "#F5F6FA",
                borderRadius: "4px",
                marginLeft: "2%",
                width: "72%",
              }}
              InputProps={{
                classes: { input: classes.input },
                color: "#4D4F5C",
                focused: classes.focused,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDiretion: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#43425D" }}>{t("job_audit.to_date")}</span>
            <TextField
              id="standard-search"
              size="small"
              type="date"
              variant="outlined"
              name="endDate"
              value={state.endDate}
              onChange={handleChange}
              style={{
                borderColor: "#F5F6FA",
                borderRadius: "4px",
                marginLeft: "2%",

                width: "72%",
              }}
              InputProps={{
                classes: { input: classes.input },
                color: "#4D4F5C",
                focused: classes.focused,
              }}
              inputProps={{
                max: state.maxDate,
              }}
            />

            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                downloadFile(e);
              }}
              style={{
                textTransform: "none",
                textAlign: "center",
                width: "45%",
                alignSelf: "right",
                outline: "none",
                backgroundColor: "#3B86FF",
                fontSize: "12px",
                marginLeft: "3px",
              }}
            >
              {t("job_reporting.download")}
              <img
                src={download}
                alt="download"
                style={{
                  transform: "rotate(180deg)",
                  marginLeft: "11%",
                }}
              />
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.tableDiv}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#F5F6FA" }}>
              <TableRow>
                <TableCell
                  style={{
                    textAlign: "justify",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                >
                  {t("job_audit.s_no")}
                </TableCell>

                <TableCell
                  style={{
                    textAlign: "justify",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                >
                  {" "}
                  {t("job_audit.assignee")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                >
                  {t("job_audit.gov_property_no")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                >
                  {t("job_audit.property_name")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                >
                  {t("job_audit.property_owner")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                >
                  {t("job_audit.smd_id")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                >
                  {t("job_audit.lng")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                >
                  {t("job_audit.lat")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                >
                  {t("job_audit.mobile")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                >
                  {t("job_audit.images")}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    textAlign: "center",
                    color: "#A3A6B4",
                    fontSize: "small",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.auditList.length > 0 &&
                props.auditList.map((job, i) => (
                  <TableRow key={job._id}>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {(props.page - 1) * 10 + (i + 1)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {job.assignee.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {job.govt_property_id}
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {job.ownerName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {job.ownerName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {job.samadhan_org_Id.samadhanId}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {job.longitude}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {job.latitude}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#4D4F5C",
                        fontFamily: "Regular 13px/20px Source Sans Pro",
                      }}
                    >
                      {job.phone ? job.phone : "-"}
                    </TableCell>
                    <TableCell>
                      <img
                        title="Click here to see all images"
                        alt="some pic"
                        onClick={(e) => {
                          openImageModal(e, job);
                        }}
                        style={{
                          width: "80px",
                          height: "80px",
                          cursor: "pointer",
                        }}
                        src={job.image_array[0]}
                      />
                    </TableCell>
                    <TableCell>
                      {props.accessList.module !== undefined &&
                        hasAccess(
                          "submoduleId",
                          "job_audit_approve_reject",
                          props.accessList.module
                        ) && (
                          <span>
                            {job.auditStatus === "PENDING" ||
                              job.auditStatus === "DRAFT" ? (
                              <FormControl>
                                <Select
                                  disableUnderline
                                  labelId="inputLabel"
                                  placeholder={"Approved"}
                                  IconComponent={iconComponent}
                                  className={classes.select1}
                                  MenuProps={menuProps}
                                  value={job.auditStatus}
                                  onChange={(e) => {
                                    handleStatus(e, job._id, job.ownerName);
                                  }}
                                  style={{
                                    marginRight: "2%",
                                  }}
                                >
                                  <MenuItem value={"DRAFT"}> DRAFT </MenuItem>{" "}
                                  <MenuItem value={"APPROVED"}>
                                    {" "}
                                    {"APPROVED"}{" "}
                                  </MenuItem>{" "}
                                  {/* <MenuItem value={'DRAFT'}> DRAFT </MenuItem>{" "} */}
                                  <MenuItem value={"REATTEMPT"}>
                                    {" "}
                                    REATTEMPT{" "}
                                  </MenuItem>{" "}
                                </Select>
                              </FormControl>
                            ) : (
                              job.auditStatus
                            )}
                          </span>
                        )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {props.auditList.length === 0 && (
            <p style={{ textAlign: "center" }}>
              {" "}
              {t("job_reporting.no_record_found")}
            </p>
          )}
        </TableContainer>
      </div>
      <div className={styles.paginationDiv}>
        {props.auditList.length > 0 && setPage()}
      </div>
      <Modal isOpen={modal} toggle={toggleModalClose} centered={true}>
        <ModalHeader toggle={toggleModalClose}>{state.title}</ModalHeader>
        <ModalBody className={styles.modalContainer}>
          <p>
            Please provide the reason for rejecting property{" "}
            <strong>{state.propertyName}</strong>{" "}
          </p>
          <form className={classes.root}>
            <TextField
              className={classes.root}
              variant="outlined"
              label="Reject reason"
              name="reason"
              style={{ width: "80%" }}
              value={state.reason}
              onChange={handleInputs}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleModalClose}
            style={{ marginRight: "2%" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      {/* cursole modal; */}

      <Modal
        backdrop="static"
        isOpen={imageModal}
        toggle={closeImageModal}
        centered={true}
      >
        {/* <ModalHeader toggle={toggleModalClose}>{state.title}</ModalHeader> */}

        <CancelIcon
          title="Close"
          onClick={closeImageModal}
          className={styles.cancel}
        />

        <ModalBody style={{ padding: "0px" }} className={styles.modalBody}>
          <div>
            <img
              style={{ width: "100%", height: "372px" }}
              alt="some pic"
              src={state.ownerImage[state.imgIndex]}
            ></img>
          </div>
        </ModalBody>
        {imageModal && (
          <div>
            <button
              title="Previouse"
              disabled={state.imgIndex === "0"}
              onClick={(e) => {
                handleImage(e, "prev");
              }}
              
              className={
                state.imgIndex === "0"
                  ? styles.carouselControl +
                  " " +
                  styles.left +
                  " " +
                  styles.disabled
                  : styles.carouselControl + " " + styles.left
              }
              data-slide="prev"
            >
              <span>
                <ArrowBackIosIcon
                  className={
                    state.imgIndex === "0"
                      ? styles.arrow + " " + styles.disabled
                      : styles.arrow
                  }
                />
              </span>
            </button>
            <button
              disabled={state.ownerImage.length - 1 === state.imgIndex}
              title="Next"
              onClick={(e) => {
                handleImage(e, "next");
              }}
              className={
                state.ownerImage.length - 1 === state.imgIndex
                  ? styles.carouselControl +
                  " " +
                  styles.right +
                  " " +
                  styles.disabled
                  : styles.carouselControl + " " + styles.right
              }
              data-slide="next"
            >
              <span>
                <ArrowForwardIosIcon
                  className={
                    state.ownerImage.length - 1 === state.imgIndex
                      ? styles.arrow + " " + styles.disabled
                      : styles.arrow
                  }
                />
              </span>
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auditList: state.Operation.jobAuditList,
    loading: state.Operation.loading,
    page: state.Operation.page,
    limit: state.Operation.limit,
    total: state.Operation.total,
    statusUpdate: state.Operation.statusUpdate,
    accessList: state.Organization.accessList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAuditJobList: (page, limit, fromData, endDate) =>
      dispatch(acitons.getAuditJobList(page, limit, fromData, endDate)),
    updatePropertyStatus: (status, id, state) =>
      dispatch(acitons.updatePropertyStatus(status, id, state)),
    downloadExcelFile: (startDate, endDate) =>
      dispatch(acitons.downloadAuditJobList(startDate, endDate)),
  };
};



export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Audit);
