/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as actions from "../../../../store/actions/index";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import styles from "./PropertyVersion.module.css";

import * as mui from "../../../../shared/mui";

const drawerWidth = 252;

const useStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));


// FUNCTIONAL COMPONENT STARTS FROM HERE

export function PeopleProfile(props) {
  const classes = useStyles();
  const { id } = useParams();


  useState(()=>{
  props.getPeopleProfile(id)
  },[])
  // const [t] = useTranslation("common");
  return (
    <div className={classes.root}>
      <mui.CssBaseline />
      <main
        className={mui.clsx(classes.content, {
          [classes.contentShift]: true,
        })}
      >
        <div className={styles.title}>{"User Profile"}</div>
        
        {props.peopleProfile !== null && props.peopleProfile!== undefined ? (<div>
          <mui.Paper elevation={3} style={{ padding: "50px" }}>
            {/* .................CONTENT BOX START.............. */}
            <div
              style={{
                padding: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <mui.Card style={{ width: "50vh", marginRight: "15%",minHeight:"70vh" }}>
                  <mui.CardActionArea>

                   <mui.CardMedia
                      component="img"
                      alt="User Image"
                      height="180"
                      image={props.peopleProfile.profileImage}
                      title="User Image"
                    />

                    <mui.CardContent>
                      <mui.Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        <p><b>UserId:</b>{`${props.peopleProfile._id}`}</p>
                        <p><b>Username: </b>{`${props.peopleProfile.username}`}</p>
                        <p><b>Organisation Name: </b>{`${props.peopleProfile.organisation[0].organisationId.name}`}</p>
                        <p><b>Organisation Id: </b>{`${props.peopleProfile.organisation[0].organisationId._id}`}</p>
                      </mui.Typography>
                    </mui.CardContent>
                  </mui.CardActionArea>
                </mui.Card>

              </div>
            </div>
            {/* ................CONTENT BOX END................... */}
          </mui.Paper>
        </div>):`No Data Found`}
      </main>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    peopleProfile: state.Organization.peopleProfile,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPeopleProfile: (id) => dispatch(actions.getPeopleProfile(id)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(PeopleProfile);
