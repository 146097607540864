/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { hasAccess } from "../../../../shared/HasAccess";
import * as mui from "../../../../shared/mui"
import GetAppIcon from "@material-ui/icons/GetApp";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import styles from "./BlankSamadhanID.module.css";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";

import * as actions from "../../../../store/actions/index";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';


const useStyles = mui.makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  input: {
    color: "#4D4F5C",
    fontSize: "smaller",
  },
}));

export function BlankSamadhanID(props) {
  const [t] = useTranslation("common");

  const classes = useStyles();
  const [state, setState] = React.useState({
    cards: "",
    search: "",
    date: "",
    id: "",
    btnTitle: "Save",
    title: "Add",
    isAdd: false,
    isUpdate: false,
    isDelete: false,
  });
  const [errors, setErros] = useState({});

  /// FETCH STATE LIST HERE
  useEffect(() => {
    props.getBlankSamadhanList(1, props.limit, state.search);
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "0") {
      const name = e.target.name;
      setState({
        ...state,
        [name]: e.target.value,
      });
      setErros({});
    }
  };
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);

  // OPEN ADD COUNTRY MODAL
  const toggleModal = (e) => {
    setModal(!modal);
    setState({
      cards: "",
      btnTitle: t("blank_smd.save"),
      title: t("blank_smd.add_card"),
      isAdd: true,
      isDelete: false,
      isUpdate: false,
    });
    setErros({});
    setCurrentStatus(false);
  };

  // VALIDATE FORM
  const validateForm = () => {
    var valid = true;
    if (state.cards.trim() === "") {
      errors.cards = t("blank_smd.card_error");
      valid = false;
    } else {
      errors.cards = "";
      valid = true;
    }
    setErros((errors) => ({ ...errors }));
    return valid;
  };
  /// handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.isDelete && !validateForm()) {
      return;
    } else {
      setCurrentStatus(true);
      if (state.isAdd) {
        props.addBlankSamadhanId(state);
      } else if (state.isUpdate) {
        props.updateBlankSamadhanId(state);
      } else {
        props.deletelankSamadhanId(state);
      }
    }
  };
  // ON SEACH state
  const onSearchSamadhan = (e) => {
    props.getBlankSamadhanList(
      props.page,
      props.limit,
      state.date,
      state.search
    );
  };

  // dwonload blank samadan
  const downloadExcel = () => {
    props.downloadExcelSmdId("blank_smd", state.date);
  };

  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    }
  });

  const handleChangePage = (event, page) => {
    props.getBlankSamadhanList(page, props.limit, state.date, state.search);
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <mui.Pagination
        onChange={handleChangePage}
        count={total}
        className={classes.root}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  // ENABLE LOADING
  const backDrop = props.loading ? (
    <mui.Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography color="textPrimary">
          SAMADHAN ID
        </Typography>
        <Typography color="textSecondary">BLANK SAMADHAN ID</Typography>
      </Breadcrumbs>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <mui.TextField
                  id="standard-search"
                  size="small"
                  type="date"
                  name="date"
                  variant="outlined"
                  placeholder="DD-MM-YYYY"
                  onChange={handleChange}
                  max={new Date()}
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    marginLeft: "2%",
                    width: "100%",
                  }}
                  InputProps={{
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
                <mui.Button
                  variant="contained"
                  style={{
                    backgroundColor: "#43425D",
                    borderRadius: "17px",
                    color: "white",
                    outline: "none",
                    textTransform: "none",
                    // marginLeft: "1%",
                    marginTop: "5px",
                    marginBottom: "5px",
                    width: "50%",
                  }}
                  onClick={(e) => {
                    onSearchSamadhan(e);
                  }}
                >
                  {t("blank_smd.search_btn")}
                </mui.Button>
              </div>
            </div>
          </div>

          <div className={styles.buttonDiv}>
            <mui.Button
              onClick={downloadExcel}
              variant="contained"
              color="secondary"
              style={{
                textTransform: "none",
                backgroundColor: "#3B86FF",
                textAlign: "center",
                whiteSpace: "nowrap",
                outline: "none",
                marginLeft: "4%",
                marginRight: "3px",
                fontSize: "smaller",
              }}
            >
              {t("job_reporting.download")}
              <GetAppIcon style={{ marginLeft: "20%" }} />
            </mui.Button>
            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "samadhan_blank_id_add",
                props.accessList.module
              ) && (
                <mui.Button
                  variant="contained"
                  color="secondary"
                  style={{
                    display: "flex",
                    borderColor: "#F2134F",
                    width: "22%",
                    outline: "none",
                    textTransform: "none",
                  }}
                  onClick={toggleModal}
                >
                  {t("blank_smd.add")}
                </mui.Button>
              )}
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            {state.isDelete && (
              <p>
                Are you sure you want to delete cards{" "}
                <strong>{state.cards}</strong> ?{" "}
              </p>
            )}
            {!state.isDelete && (
              <form className={classes.root}>
                <mui.TextField
                  className={classes.root}
                  variant="outlined"
                  name="cards"
                  label={t("blank_smd.no_of_cards1")}
                  value={state.cards}
                  onChange={handleChange}
                  helperText={errors.cards}
                  error={errors.cards}
                  style={{ width: "80%" }}
                />
              </form>
            )}
          </ModalBody>
          <ModalFooter>
            <mui.Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              style={{ marginRight: "2%" }}
            >
              {t("blank_smd.cancel")}
            </mui.Button>
            <mui.Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </mui.Button>
          </ModalFooter>
        </Modal>
        <div className={styles.table}>
          <mui.TableContainer component={mui.Paper}>
            <mui.Table className={classes.table} aria-label="simple table">
              <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <mui.TableRow>
                  <mui.TableCell
                    align="left"
                    style={{ textAlign: "left", color: "#A3A6B4" }}
                  >
                    {t("blank_smd.no_of_card")}
                  </mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("blank_smd.date")}
                  </mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                </mui.TableRow>
              </mui.TableHead>
              <mui.TableBody>
                {props.blankSamadhanList.length > 0 &&
                  props.blankSamadhanList.map((blank) => (
                    <mui.TableRow key={blank._id}>
                      <mui.TableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{ color: "#4D4F5C" }}
                      >
                        {blank.number_of_cards}
                      </mui.TableCell>
                      <mui.TableCell align="center"></mui.TableCell>
                      <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                        {moment(blank.createdAt).format("DD-MM-YYYY")}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "samadhan_blank_id_detail_list",
                            props.accessList.module
                          ) && (
                            <Link
                              to={`/samadhanblank-all/${blank._id}/${"blank"}`}
                              title="Click here to View All"
                              style={{
                                textAlign: "center",
                                textDecoration: "none",
                                color: "#007bff",
                                cursor: "pointer",
                                textTransform: "capit",
                              }}
                            >
                              {t("blank_smd.view_all")}
                            </Link>
                          )}
                      </mui.TableCell>
                    </mui.TableRow>
                  ))}
              </mui.TableBody>
            </mui.Table>
            {props.blankSamadhanList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {t("blank_smd.no_record_found")}
              </p>
            )}
          </mui.TableContainer>
        </div>

        <div className={classes.root}></div>
      </div>

      {/* Styling to override material-ui css which isn't possible via css file */}
      <style>
        {`

  
        @media only screen and (max-width: 768px) {
          /* For mobile phones: */
          .MuiTableContainer-root {
            max-width: 370px;
        }
        .OrganisationSamadhanID_searchBar__3MrR6 {
          width: 100%;
      }
      .OrganisationSamadhanID_searchBarDiv__3d9JM {
        flex-wrap:wrap;
      }

      .OrganisationSamadhanID_buttonDiv__3E7xM{
        justify-content: space-between;
        padding: 1rem 0.5rem;
        width:100%;
      }

      
      .download{
        margin-left:0px;
        }

        
        `}
      </style>
      {/*---------------------------------------------  */}
      {props.blankSamadhanList.length > 0 && setPage()}
    </div>
  );
}

BlankSamadhanID.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    loading: state.BlankSamadhan.loading,
    error: state.BlankSamadhan.blankSamadhan.error,
    message: state.BlankSamadhan.blankSamadhan.message,
    blankSamadhanList: state.BlankSamadhan.blankSamadhan.blankSamadhanList,
    page: state.BlankSamadhan.blankSamadhan.page,
    limit: state.BlankSamadhan.blankSamadhan.limit,
    total: state.BlankSamadhan.blankSamadhan.total,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBlankSamadhanId: (data) => dispatch(actions.addBlankSamadhanId(data)),

    getBlankSamadhanList: (page, limit, date, search) =>
      dispatch(actions.getBlankSamadhanList(page, limit, date, search)),
    downloadExcelSmdId: (type, date) =>
      dispatch(actions.downloadExcelSmdId(type, date)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(BlankSamadhanID);
