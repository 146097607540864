/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { blue, grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import * as $ from "jquery";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";
import styles from "./Installation.module.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import DatePicker from "../../../../../../components/DatePicker/DatePicker";
import { Card, CardText } from "reactstrap";
import expand from "./fullscreen.png";
import TextField from "@material-ui/core/TextField";
import back from "./back.png";
import * as acitons from "../../../../../store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation, useTranslation } from "react-i18next";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "21%",
    maxWidth: "21%",
    margin: "2%",
    minHeight: "20%",
    maxHeight: "20%",
  },
  root1: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  large: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  input: {
    color: "#4D4F5C",
    fontSize: "smaller",
  },
  select: {
    minWidth: "8.5vw",
    // minWidth: "7.5vw",
    "@media (min-width: 320px) and (max-width: 375px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 376px) and (max-width: 425px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 426px) and (max-width: 768px)": {
      minWidth: "12vw",
    },
    "@media (min-width: 769px) and (max-width: 1024px)": {
      minWidth: "10vw",
    },
    background: "white",
    color: grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "13px",
    "&:hover": {
      borderColor: grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: blue[200],
    },
  },
  icon: {
    color: grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
}));

export function InstallationCard(props) {
  return (
    <Card
      body
      outline
      color="black"
      className={styles.cards}
      style={{
        borderColor: "white",
        boxShadow: "0px 2px 3px #0000000A",
      }}
    >
      <CardText>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          <div
            className={styles.header}
            style={{
              marginTop: "0%",
              color: props.color,
              top: "0",
              left: "0",
              fontSize: "xx-large",
            }}
          >
            {props.number}
          </div>
          <div
            className={styles.header}
            style={{
              marginTop: "0%",
              color: props.color,
              marginLeft: "-50%",
              fontSize: "large",
            }}
          >
            {props.minutes}
          </div>
        </div>
        <div
          style={{
            color: "#BBBBBB",
            fontSize: "medium",
            bottom: "0",
            left: "0",
            marginTop: "5%",
          }}
        >
          {props.details}
        </div>
      </CardText>
    </Card>
  );
}

export function Initial(props) {
  return (
    <div className={styles.initial_div}
    >
      <div
        className={styles.initial}
        style={{
          backgroundColor: props.color,
        }}
      ></div>
      <span
        style={{
          color: "#4D4F5C",
          whiteSpace: "nowrap",
          fontSize: "x-small",
          textAlign: "center",
        }}
      >
        {props.info}
      </span>
    </div>
  );
}

export function Initialisation(props) {
  const [t] = useTranslation("common");

  const borderSelectClasses = useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    value: "installation",
    name: "hai",
    day: "today",
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    endDate1: new Date().toISOString().split("T")[0],
    graphDate: new Date().toISOString().slice(0, 10),
  });
  const [isZoom, setZoom] = useState(false);

  // handle change job reporting type
  const handleChange = (event) => {
    history.push(`${event.target.value}`);
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  // handle change input type
  // handle inputs
  const handleInputs = (event) => {
    const name = event.target.name;

    const addDays = (theDate, days) => {
      return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
    };
    var startDate = "";
    var endDate = "";
    if (event.target.name === "day" && event.target.value === "yesterday") {
      var newDate = addDays(new Date(), -1).toISOString().slice(0, 10);
      startDate = newDate;
      endDate = newDate;
      setState({
        ...state,
        [name]: event.target.value,
        startDate: newDate,
        endDate: newDate,
      });
    }
    if (event.target.name === "day" && event.target.value === "today") {
      startDate = newDate;
      endDate = newDate;
      setState({
        ...state,
        [name]: event.target.value,
        startDate: newDate,
        endDate: newDate,
      });
    }
    if (event.target.name === "startDate") {
      startDate = event.target.value;
      endDate = state.endDate === "" ? event.target.value : state.endDate;
      setState({
        ...state,
        [name]: event.target.value,
        startDate: startDate,
        endDate: endDate,
      });
    }
    if (event.target.name === "endDate") {
      startDate = state.startDate === "" ? event.target.value : state.startDate;
      endDate = event.target.value;

      setState({
        ...state,
        [name]: event.target.value,
        startDate: startDate,
        endDate: endDate,
      });
    }
    props.getInstaCardList(localStorage.getItem("orgId"), startDate, endDate);
  };

  // on change graph date
  const handleChangeGraphDate = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    props.getGraphData(e.target.value);
  };

  useEffect(() => {
    let today = new Date().toISOString().slice(0, 10);
    props.getInstaCardList(localStorage.getItem("orgId"), today, today);

    setTimeout(() => {
      props.getGraphData(state.graphDate);
    }, 2000);
  }, []);

  // Zoon In an Zoom put page
  const handleZoomInOutSection = () => {
    debugger;
    setZoom(!isZoom);
    isZoom
      ? $("#zoom").removeClass(styles.mt_2)
      : $("#zoom").addClass(styles.mt_2);
  };
  const backDrop = props.loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;
  return (
    <div className={styles.main}>
      {backDrop}
      {!isZoom && (
        <div className={styles.header}>
          <div className={styles.title}>
            <Breadcrumbs seperator="›" aria-label="breadcrumb">
						<Typography color="textPrimary">{t("init_end_day.operation")}</Typography>
						<Typography color="textSecondary">{t("init_end_day.job_reporting")}</Typography>
					</Breadcrumbs>
          </div>
          <div className={styles.dropdownMain}>
            <FormControl>
              <Select
                disableUnderline
                labelId="inputLabel"
                placeholder={"QC"}
                IconComponent={iconComponent}
                className={classes.select}
                MenuProps={menuProps}
                value={state.value}
                onChange={handleChange}
                style={{
                  marginRight: "2%",
                }}
              >
                <MenuItem value={"installation"}>
                  {" "}
                  {t("init_end_day.installation")}
                </MenuItem>{" "}
                <MenuItem value={"/operations/jobinitialisation"}>
                  {" "}
                  {t("init_end_day.initialisation")}{" "}
                </MenuItem>{" "}
                <MenuItem value={"/operations/jobqc"}>
                  {" "}
                  {t("init_end_day.qc")}{" "}
                </MenuItem>{" "}
                <MenuItem value={"/operations/jobaudit"}>
                  {" "}
                  {t("init_end_day.audit")}{" "}
                </MenuItem>{" "}
                <MenuItem value={"/operations/work-history"}>
                  {" "}
                  {t("init_end_day.user_work_history")}
                </MenuItem>{" "}
              </Select>
            </FormControl>
          </div>
        </div>
      )}
      {!isZoom && (
        <div className={styles.header2}>
          <span className={styles.subTitle}>
            {t("init_end_day.installation")}
          </span>
          <div className={styles.dropdown2}>
            <FormControl>
              <Select
                disableUnderline
                labelId="inputLabel"
                placeholder={"Today"}
                IconComponent={iconComponent}
                className={classes.select}
                MenuProps={menuProps}
                value={state.day}
                onChange={handleInputs}
                name="day"
                style={{
                  marginRight: "2%",
                }}
              >
                <MenuItem value={"today"}> {t("init_end_day.today")} </MenuItem>{" "}
                <MenuItem value={"yesterday"}>
                  {" "}
                  {t("init_end_day.yesterday")}{" "}
                </MenuItem>{" "}
              </Select>
            </FormControl>
          </div>
          <div className={styles.date}>
            <span className={styles.label}> {t("init_end_day.from_date")}</span>
            <TextField
              id="standard-search"
              size="small"
              type="date"
              variant="outlined"
              name="startDate"
              value={state.startDate}
              onChange={handleInputs}
              className={styles.date_picker}
              InputProps={{
                classes: { input: classes.input },
                color: "#4D4F5C",
                focused: classes.focused,
              }}
            />
            <span className={styles.label}> {t("init_end_day.to_date")}</span>
            <TextField
              id="standard-search"
              size="small"
              type="date"
              variant="outlined"
              name="endDate"
              value={state.endDate}
              onChange={handleInputs}
              className={styles.date_picker}
              InputProps={{
                classes: { input: classes.input },
                color: "#4D4F5C",
                focused: classes.focused,
              }}
            />
          </div>
        </div>
      )}
      {!isZoom && (
        <div className={styles.grid}>
          <InstallationCard
            number={
              props.instaCardList.assigned_property
                ? props.instaCardList.assigned_property
                : "0"
            }
            details={t("report_insta.num_assign_card")}
            color="#A5A2FB"
          />
          <InstallationCard
            number={
              props.instaCardList.attempted_property
                ? props.instaCardList.attempted_property
                : "0"
            }
            details={t("report_insta.num_attempt_property")}
            color="#BDD800"
          />
          <InstallationCard
            number={
              props.instaCardList.avg_attempt_on_property
                ? props.instaCardList.avg_attempt_on_property
                : "0"
            }
            details={t("report_insta.avg_attempt")}
            color="#FF9D3A"
          />
          <InstallationCard
            number={
              props.instaCardList.failed ? props.instaCardList.failed : "0"
            }
            details={t("report_insta.num_failed")}
            color="#C50000"
          />
          <InstallationCard
            number={
              props.instaCardList.aborted ? props.instaCardList.aborted : "0"
            }
            details={t("report_insta.num_aborted")}
            color="#FE0000"
          />
          <InstallationCard
            number={
              props.instaCardList.sent_back_audit
                ? props.instaCardList.sent_back_audit
                : "0"
            }
            details={t("report_insta.num_audit")}
            color="#43425D"
          />
          <InstallationCard
            number={
              props.instaCardList.newproperty_identified
                ? props.instaCardList.newproperty_identified
                : "0"
            }
            details={t("report_insta.num_new")}
            color="#F2134F"
          />
          <InstallationCard
            number={
              props.instaCardList.avg_time_per_property
                ? props.instaCardList.avg_time_per_property
                : "0"
            }
            minutes="minutes"
            details={t("report_insta.avg_time")}
            color="#5CC600"
          />
          <InstallationCard
            number={
              props.instaCardList.avg_time_between_two_property
                ? props.instaCardList.avg_time_between_two_property
                : "0"
            }
            minutes="minutes"
            details={t("report_insta.avg_time_between")}
            color="#129A8C"
          />
          <InstallationCard
            number={
              props.instaCardList.first_installation_time
                ? props.instaCardList.first_installation_time
                : "0"
            }
            minutes="minutes"
            details={t("report_insta.first_property")}
            color="#B227BC"
          />
        </div>
      )}
      {!isZoom && (
        <div className={styles.final}>
          <Link
            style={{ textDecoration: "none" }}
            to="/operations/insta-report-table"
          >
            <span
              className={styles.label}
              style={{
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "large",
              }}
            >
              {t("init_end_day.view_details")}&nbsp;
            </span>
          </Link>
          <img src={back} alt="back" />
        </div>
      )}

      <div id="zoom" className={styles.finale}>
        <img
          title={
            isZoom ? "Click here to back" : "Click here to view in full page"
          }
          alt={
            isZoom ? "Click here to back" : "Click here to view in full page"
          }
          onClick={handleZoomInOutSection}
          src={expand}
          style={{
            marginLeft: "1%",
            cursor: "pointer",
          }}
        />
        <span
          style={{
            color: "#F2134F",
            fontSize: "large",
          }}
        >
          {t("report_insta.tracking_across")}
        </span>
        <TextField
          id="date"
          variant="filled"
          type="date"
          size="small"
          value={state.graphDate}
          name="graphDate"
          defaultValue={new Date()}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChangeGraphDate}
          style={{ backgroundColor: "#3B86FF", marginRight: "5%" }}
        />
      </div>
      <div className={styles.finalDiv}>
        <div className={styles.trackingDiv}>
          <Initial
            color="#FFCD54"
            info={t("report_insta.avg_time_per_property")}
          />
          <Initial
            color="#F2134F"
            info={t("report_insta.avg_insta_per_property")}
          />
          <Initial color="#56BBFE" info={t("report_insta.insta_per_hour")} />
          <Initial color="#0BD878" info={t("report_insta.active_user")} />
          <Initial color="#C50000" info={t("report_insta.failed_property")} />
          <Initial color="#FE0000" info={t("report_insta.aborted_property")} />
          <Initial color="#43425D" info={t("report_insta.new_property")} />
        </div>
        {props.graphList.length > 0 ? (
          <div className={styles.graphdiv} style={{overflowX:"scroll"}}>
            {/* <ResponsiveContainer
              width={700}
              height="80%"
            > */}
            <LineChart
              // className={styles.chart}
              width={1150}
              height={300}
              data={props.graphList}
              margin={{
                top: 5,
                bottom: 5,
                right: 3,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="Hour" axisLine={false} padding={{ right: 5 }} />
              <YAxis axisLine={false} />
              <Tooltip cursor={false} />
              <Line
                dataKey="Mapped Property"
                stroke="#367ae8"
                activeDot={{ r: 8 }}
              />
              <Line dataKey="Re-Attempt Property" stroke="#a4a6a9" />
              <Line dataKey="Aborted Property" stroke="#fe0000" />
              <Line dataKey="Failed Property" stroke="#c50000" />
              <Line dataKey="New Property" stroke="#43425d" />
              <Line dataKey="Active User" stroke="#13eb0f" />

              <Line dataKey="Total Installed Property" stroke="#56bbfe" />
              </LineChart>
              {/* </ResponsiveContainer> */}
          </div>
        ) : (
          <div style={{ textAlign: "center" }} className={styles.graphdiv}>
            {props.graphList.length < 1 && !props.loading && (
              <Typography variant="h5" gutterBottom>
                {t("report_insta.no_data")}
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    instaCardList: state.Operation.instacardList,
    graphList: state.Operation.graphList,
    loading: state.Operation.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInstaCardList: (orgId, startDate, endDate) =>
      dispatch(acitons.getInstaCardList(orgId, startDate, endDate)),
    getGraphData: (date) => dispatch(acitons.getGraphData(date)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Initialisation);
