import React,  {useState}from "react";
import styles from "./ResetPassword.module.css";
import axios from 'axios';

import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import unnamed from "./images/unnamed@2x.png";
import Logo from "./images/Logo-1@2x.png";
import CancelIcon from "@material-ui/icons/Cancel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory } from 'react-router-dom'
import { toast } from "react-toastify";
import * as myConstClass from '../../../constants/constants';
import { useTranslation } from 'react-i18next';


const api_url = myConstClass.apiUrl;
const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(3),
			width: "40ch",
			display: "grid",
		},
		"& .MuiIcon-colorSecondary": {
			width: "2em",
			height: "2em",
		},
	},
}));

export default function ResetPassword(props) {
	const classes = useStyles();
	const [t] = useTranslation('common');

	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const [state, setState] = useState(
		{
		
			password: "",
			cPassword: "",
		
		},

	);

	// handle change

	const handleChange =(e)=>{
     setState({...state,[e.target.name]:e.target.value.trim()})
	}
	// 
	/// handleSubmit
const	handleSubmit= (e)=>{
	e.preventDefault();
   if(state.password.length<8){
	   return toast.error(t("reset_password.password_error"))
   }
   else if(state.password!==state.cPassword){
	return toast.error(t("reset_password.c_password_error"))

   }
   var data={
	   password:state.password,
	   value:localStorage.getItem('value')
   }
   setLoading(true)
   axios.post(api_url+`user/reset-password`,data,{
	method:"POST",
	headers :{
		'content-type':"Application/json",
		"accept":"Application/json"
	},

   })
   .then(response=>{
	setLoading(false)
	localStorage.removeItem('value')
	
	   toast.success(response.data.message)
	   history.push('/password-reset-successful')
   })
   .catch(err=>{
	
	setLoading(false)
	   toast.error("Someting went wrong")
   })
  
}
	
const backDrop= loading ? (
	<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={loading}>
		<CircularProgress color="inherit" />
	</Backdrop>
) : null;

	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				objectFit: "fit",
				overflow: "hidden",
				position: "absolute",
				top: "0",
				left: "0",
			}}
		>
			{backDrop}
			<img src={unnamed} style={{ width: "100%" }} alt="unnamed"/>
			<div className={styles.div1}>
				<CancelIcon
					style={{
						color: "#F2134F",
						position: "absolute",
						top: "-26",
						right: "-30",
						height: "10%",
						width: "10%",
						cursor: "pointer",
					}}
				/>
				<div className={styles.logoDiv}>
					<img src={Logo} alt="logo" />
				</div>
				<div className={styles.div1text}>{t("reset_password.reset_pasword_title")}</div>
				<div className={styles.textField}>
					<form
						className={classes.root}
						noValidate
						autoComplete="off"
					>
						<TextField
							id="outlined-basic"
							name='password'
							onChange={handleChange}
							value={state.password}
							type="Password"
							label={t("reset_password.new_password")}
							variant="outlined"
						/>
						<TextField
							id="outlined-basic"
							type="Password"
							label={t("reset_password.c_password")}
							variant="outlined"
							fullWidth={true}
							name='cPassword'
							onChange={handleChange}
							value={state.cPassword}
						/>
					</form>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						padding: "5%",
					}}
				>
					
						<Button
							variant="contained"
							color="secondary"
							style={{
								width: "43ch",
								height: "50px",
								outline: "none",
							}}
							onClick={(e) => {handleSubmit(e) }}
						>
						{t("reset_password.reset_my_ac")}
					</Button>
				
				</div>
			</div>
		</div>
	);
}
