/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Visualise.module.css";
import * as mui from "../../../shared/mui";
import Map from "./Maps";
import moment from "moment"
import { apiUrl } from "../../../constants/constants";
import DateFnsUtils from "@date-io/date-fns";
import { Card, CardTitle, CardBody } from "reactstrap";
import { withTranslation, useTranslation } from 'react-i18next';
//axios setup
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../../store/actions/index";

const drawerWidth = 252;
const idOrg = localStorage.getItem("orgId");

const centerOptions = {
  //Dhule
  "5f53d620bf878f2d90d1fa43": { lat: 20.9042, lng: 74.7749 },
  //Dondaicha
  "602e93485ade6828609e34b2": { lat: 21.3245, lng: 74.5564 },
  //Shirpur
  "602e93d05173b743f0f79232": { lat: 21.3496, lng: 74.8797 },
  //Sakri
  "602e93eb5173b743f0f7923d": { lat: 20.9926, lng: 74.3141 },
  //Sindkheda
  "602e93fe5173b743f0f79248": { lat: 21.2726, lng: 74.7455 },
};

const useStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const selectStyles = mui.makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "content-type": "application/json",
    Accept: "Application/json",
    Authorization: `${localStorage.getItem("token")}`,
  },
});

const exportInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    Authorization: `${localStorage.getItem("token")}`,
  },
  responseType: "arraybuffer",
});

let propertyColor = "";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <mui.Box p={3}>
          <Typography>{children}</Typography>
        </mui.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: mui.PropTypes.node,
  index: mui.PropTypes.any.isRequired,
  value: mui.PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}



function addMarkersProperty(map, properties) {
  if (properties !== undefined)
    properties.forEach((property, index) => {
      const marker = new window.google.maps.Marker({
        map,
        position: { lat: property.latitude, lng: property.longitude },
        icon: `http://maps.google.com/mapfiles/ms/icons/${propertyColor}-dot.png`,
      });

      marker.addListener(`click`, () => {
        new window.google.maps.InfoWindow({
          content: `<div id="content">
      <div id="bodyContent">
      <p>govt_id:${property.govt_property_id}</p>
      <p>latitude:${property.latitude}</p>
      <p>longitude: ${property.longitude}</p>
      <a href=https://en.wikipedia.org/w/index.php?title=Uluru>link</a>
      </div> </div> 
                `,
        }).open(map, marker);
      });
    });
}

function addMarkersSurvey(map, responses) {
  if (responses !== undefined)
    responses.forEach &&
      responses.forEach((response, index) => {
       
        const marker = new window.google.maps.Marker({
          map,
          position: { lat: response.latitude, lng: response.longitude },
          icon: `http://maps.google.com/mapfiles/ms/icons/green-dot.png`,
        });

        marker.addListener(`click`, () => {
          new window.google.maps.InfoWindow({
            content: `<div id="content">
      <div id="bodyContent">
      <p>survey_id:${response._id}</p>
      <p>latitude:${response.latitude}</p>
      <p>longitude: ${response.longitude}</p>
      </div> </div> 
                `,
          }).open(map, marker);
        });
      });
}

function addMarkersDustbin(map, properties) {
  console.log(properties)
  if (properties !== undefined)
    properties.forEach((property, index) => {
      // console.log(index)
      if (
        !(property.latitude === undefined || property.longitude === undefined)
      ) {
        const dustbinColor =
          property.asset_type === "wet_waste_dustbin" ? "blue" : "yellow";
        const marker = new window.google.maps.Marker({
          map,
          position: { lat: property.latitude, lng: property.longitude },
          icon: `http://maps.google.com/mapfiles/ms/icons/${dustbinColor}-dot.png`,
        });

        marker.addListener(`click`, () => {
          new window.google.maps.InfoWindow({
            content: `<div id="content">
      <div id="bodyContent">
      <p>_id:${property._id}</p>
      <p>govt_id:${property.govt_property_id}</p>
      <p>latitude:${property.latitude}</p>
      <p>longitude: ${property.longitude}</p>
      <a href=https://en.wikipedia.org/w/index.php?title=Uluru>link</a>
      </div> </div> 
                `,
          }).open(map, marker);
        });
      }
    });
}
// FUNCTIONAL COMPONENT STARTS
export function Visualise(props) {


  const [t] = useTranslation("common");
  const selectClasses = selectStyles();
  const [propertyType, setPropertyType] = React.useState(0);
  const [operationType, setOperationType] = React.useState(0);

  const [selectOpen, setSelectOpen] = React.useState(false);
  const [operationOpen, setOperationOpen] = React.useState(false);

  //  MAP RELATED CONSTANTS
  //  ======================
  const [allProperties, setAllProperties] = useState([[], [], [], []]);

  const perPage = 1000;
  const [page, setPage] = useState(1);
  const [mappedPage, setMappedPage] = useState(1);
  const [approvedPage, setApprovedPage] = useState(1);
  const [rejectedPage, setRejectedPage] = useState(1);
  const [failedPage, setFailedPage] = useState(1);
  const [dustbinPage, setDustbinPage] = useState(1);
  const [totalMappedPages, setTotalMappedPages] = useState(1);
  const [totalApprovedPages, setTotalApprovedPages] = useState(1);
  const [totalRejectedPages, setTotalRejectedPages] = useState(1);
  const [totalFailedPages, setTotalFailedPages] = useState(1);
  const [totalDustbinPages, setTotalDustbinPages] = useState(1);
  const [tabValue, setTabValue] = React.useState(0);
  const [loading, setLoading] = useState(true);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [dustbin, setDustbin] = useState([]);
  const [isDustbinLoading, setDustbinLoading] = useState(true);
  const [showSurvey, setShowSurvey] = useState(true);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [dustbinPercent, setDustbinPercent] = useState(0);

  const [surveyList, setSurveyList] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = React.useState([]);
  const [isDustbinShow, setDustbinShow] = useState(false);
  // const [selectedSurveyName, setSelectedSurveyName] = React.useState([])
  // const [surveyResponse, setSurveyResponse] = useState(false);
  const [showSurveyList, setShowSurveyList] = useState([]);
  const [showSurveyMap, setShowSurveyMap] = useState(false);
  // =========

  

  // ENABLE LOADING
  const backDrop = loading ? (
      <mui.CircularProgress color="inherit" style={{margin:"auto"}} />
  ) : null;


  // HANDLERS
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSelectSurveyChange = (event) => {
    setSelectedSurvey(event.target.value);
  };

  const handleSelectChange = (event) => {
    setPropertyType(event.target.value);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  // Select for Operation
  const handleOperationSelectChange = (event) => {
    setOperationType(event.target.value);
  };

  const handleOperationSelectOpen = () => {
    setOperationOpen(true);
  };

  const handleOperationSelectClose = () => {
    setOperationOpen(false);
  };

  const handleDustbinShow = () => {
    setDustbinPage(1);
    setDustbin([])
    setDustbinShow(true);
  };

  const handleDustbinDate = (date) => {
    setSelectedDate(date)
    setDustbinShow(false)
  };


  // USE-EFFECTS
  // ============
  useEffect(() => {
    setPage(1);
    propertyColor = "green";
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userId") !== "5f53a7e1ca653b1af4a1624d") {
      props.getUserorgList();
    }
    props.getAccessControl();
  }, []);

  useEffect(() => {
    const listApi = `api/survey/view_publish_survey_list`;
    instance
      .request({
        method: "GET",
        url: listApi,
        params: {
          organisationId: localStorage.getItem("orgId"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setSurveyList(response.data.survey);
        } else setSurveyList([]);
      })
      .catch(function (error) {
        console.log(error);
      }); //axios-end
  }, []);

    /// is accessbale
    const hasAccess = (type, name) => {
      if (props.accessList.module !== undefined) {
        if (type === "module") {
          let authObj = props.accessList.module.find(
            (x) => x.moduleId.keyName === name
          );
          if (authObj) {
            if (authObj.mod_enableStatus === "true") {
              return true;
            } else {
              return false;
            }
          }
        } else if (type === "submoduleId") {
          let authObj = props.accessList.module.find((x) =>
            x.submoduleId.find((d) => d.keyName === name)
          );
          if (authObj && authObj.mod_enableStatus === "true") {
            return true;
          } else {
            return false;
          }
        }
      }
    };

  const initialiseApi = () => {

    const range1 =moment(startDate).format("MM/DD/YYYY")
    const range2 = moment(endDate).format("MM/DD/YYYY")

    const visualiseType = operationType === 0 ? "visualise" : "export";
    const listApi = `api/survey/visualise`;
    if (operationType === 0) {
      instance
        .request({
          method: "POST",
          url: listApi,
          params: {
            orgId: localStorage.getItem("orgId"),
          },
          data: {
            option: visualiseType,
            surveyIds: selectedSurvey,
            startDate: range1,
            endDate: range2,
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.data === null) toast.error(response.data.message);
            else {
              setShowSurveyList(response.data.data);
              setShowSurveyMap(true);
            }
          }
        })

        .catch(function (error) {
          console.log(error, "errror");
        }); //axios-end
    } else if (operationType === 1) {
      exportInstance
        .request({
          method: "POST",
          url: listApi,
          params: {
            orgId: localStorage.getItem("orgId"),
          },
          data: {
            option: visualiseType,
            surveyIds: [selectedSurvey],
            startDate: range1,
            endDate: range2,
          },
        })
        .then((response) => {
          var blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(
              blob,
              `Survey_Responses_${new Date().toISOString().slice(0, 10)}.xlsx`
            );
          } else {
            var a = window.document.createElement("a");

            a.href = window.URL.createObjectURL(blob, {
              type: "text/plain",
            });
            a.download = `Survey_Responses_${new Date()
              .toISOString()
              .slice(0, 10)}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }

          toast.success("File downloaded successfully");
        })
        .catch((error) => {
          toast.error("No Record Found");
        }); //axios-end
    }
  };

  useEffect(() => {
    if (propertyType === 0) propertyColor = "green";
    else if (propertyType === 1) propertyColor = "blue";
    else if (propertyType === 2) propertyColor = "orange";
    else propertyColor = "red";
  }, [propertyType]);

  // FOR MAPPED PROPERTIES
  useEffect(() => {
    if (propertyType === 0) {
      let propertyApi = `property/visualise/${mappedPage}/${perPage}`;

      instance
        .request({
          method: "GET",
          url: propertyApi,
          params: {
            orgId: localStorage.getItem("orgId"),
            type: "MAPPED",
          },
        })
        .then(function (response) {
          if (response.status && mappedPage <= totalMappedPages) {
            setAllProperties([
              allProperties[0].concat(response.data.data.docs),
              allProperties[1],
              allProperties[2],
              allProperties[3],
            ]);
            setTotalMappedPages(response.data.data.pages);
            setMappedPage(mappedPage + 1);
            setLoadingPercent(
              `${parseInt((mappedPage / totalMappedPages) * 100)}`
            );
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error("No Record Found");
        }); //axios-end
    }
  }, [page, mappedPage, propertyType]); //useEffect-end

  // FOR APPROVED PROPERTIES
  useEffect(() => {
    if (propertyType === 1) {
      setLoading(true);
      let propertyApi = `property/visualise/${approvedPage}/${perPage}`;

      instance
        .request({
          method: "GET",
          url: propertyApi,
          params: {
            orgId: localStorage.getItem("orgId"),
            type: "APPROVED",
          },
        })
        .then(function (response) {
          if (response.status && approvedPage <= totalApprovedPages) {
            setAllProperties([
              allProperties[0],
              allProperties[1].concat(response.data.data.docs),
              allProperties[2],
              allProperties[3],
            ]);
            setTotalApprovedPages(response.data.data.pages);
            setApprovedPage(approvedPage + 1);
            setLoadingPercent(
              `${parseInt((approvedPage / totalApprovedPages) * 100)}`
            );
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error("No Record Found");
        }); //axios-end
    }
  }, [approvedPage, propertyType]); //useEffect-end

  // FOR ABORTED PROPERTIES
  useEffect(() => {
    if (propertyType === 2) {
      setLoading(true);
      let propertyApi = `property/visualise/${rejectedPage}/${perPage}`;
      instance
        .request({
          method: "GET",
          url: propertyApi,
          params: {
            orgId: localStorage.getItem("orgId"),
            type: "ABORTED",
          },
        })
        .then(function (response) {
          if (response.status && rejectedPage <= totalRejectedPages) {
            setAllProperties([
              allProperties[0],
              allProperties[1],
              allProperties[2].concat(response.data.data.docs),
              allProperties[3],
            ]);
            setTotalRejectedPages(response.data.data.pages);
            setRejectedPage(rejectedPage + 1);
            setLoadingPercent(
              `${parseInt((rejectedPage / totalRejectedPages) * 100)}`
            );
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error("No Record Found");
        }); //axios-end
    }
  }, [rejectedPage, propertyType]); //useEffect-end

  // FOR FAILED PROPERTIES
  useEffect(() => {
    if (propertyType === 3) {
      setLoading(true);
      let propertyApi = `property/visualise/${failedPage}/${perPage}`;
      instance
        .request({
          method: "GET",
          url: propertyApi,
          params: {
            orgId: localStorage.getItem("orgId"),
            type: "FAILED",
          },
        })
        .then(function (response) {
          if (response.status && failedPage <= totalFailedPages) {
            setAllProperties([
              allProperties[0],
              allProperties[1],
              allProperties[2],
              allProperties[3].concat(response.data.data.docs),
            ]);
            setTotalFailedPages(response.data.data.pages);
            setFailedPage(failedPage + 1);
            setLoadingPercent(
              `${parseInt((failedPage / totalFailedPages) * 100)}`
            );
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error("No Record Found");
        }); //axios-end
    }
  }, [failedPage, propertyType]); //useEffect-end

  //FOR DATE PICKER
  useEffect(() => {
    const dbPerPage = 1000;

    if (isDustbinShow && selectedDate) {
      const dateSelected = `${
        selectedDate.getMonth() + 1
      }/${selectedDate.getDate()}/${selectedDate.getFullYear()}`;

      let dustbinApi = `api/survey/asset-visualise/${dustbinPage}/${dbPerPage}?surveyDate=${dateSelected}`;
    
      console.log(dustbin, "dustbin")
      instance
        .request({
          method: "GET",
          url: dustbinApi,
          params: {
            organisationId: localStorage.getItem("orgId"),
          },
        })
        .then(function (response) {
          
          if (response.status === 200 && dustbinPage <= totalDustbinPages) {
            console.log(response.data.data.docs.length,"response data length")
            if (response.data.data.docs.length === 0) {
              // setDustbinLoading(false);
              if(dustbinPage<=1)
                    toast.error("No data for selected date.");          
            } else if(response.data.data.docs.length!==0){
              toast.success(response.data.message)
              if(dustbinPage === 1)
                // setDustbin([])
              setTotalDustbinPages(response.data.data.pages);
              setDustbinPage(dustbinPage + 1);
              setDustbin(dustbin.concat(response.data.data.docs));
              setDustbinPercent(
                `${parseInt((dustbinPage / totalDustbinPages) * 100)}`
              );
            }
          } else if (dustbinPage > totalDustbinPages) {
            console.log(dustbin)
            setDustbinLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error("No Record Found for Dustbin Survey.");
        }); //axios-end
    }
  }, [isDustbinShow,dustbinPage]);

  //CONSTS
  const mapPropsProperty = {
    options: {
      center: centerOptions[`${idOrg}`]
        ? centerOptions[`${idOrg}`]
        : { lat: 19.7515, lng: 75.7139 },
      zoom: 12,
    },
    // options: { center:{lat: 20.9042, lng: 74.7749}, zoom: 12 },
    onMount: addMarkersProperty,
    onMountProps: allProperties[propertyType],
  };

  //CONSTS
  const mapPropsSurvey = {
    options: {
      center: centerOptions[`${idOrg}`]
        ? centerOptions[`${idOrg}`]
        : { lat: 19.7515, lng: 75.7139 },
      zoom: 12,
    },
    onMount: addMarkersSurvey,
    onMountProps: showSurveyList,
  };

  const mapPropsDustbin = {
    options: {
      center: centerOptions[`${idOrg}`]
        ? centerOptions[`${idOrg}`]
        : { lat: 19.7515, lng: 75.7139 },
      zoom: 12,
    },
    onMount: addMarkersDustbin,
    onMountProps: dustbin,
  };
  const classes = useStyles();

  return (
    <div className={styles.main}>
      <mui.CssBaseline />
      <main
        className={mui.clsx(classes.content, {
          [classes.contentShift]: true,
        })}
      >
        <div className={styles.title}>
          <Breadcrumbs seperator="›" aria-label="breadcrumb">
            <Typography color="textPrimary">{t("visualise.title")}</Typography>
          </Breadcrumbs>
        </div>
        <div position="static" className={styles.appbar}>
        <mui.Tabs
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
           { <mui.Tab variant="fullWidth" label={t("visualise.gen_sur_vis")} {...a11yProps(2)} />}
           {<mui.Tab variant="fullWidth" label={t("visualise.dust_vis")} {...a11yProps(1)} />}
           {<mui.Tab variant="fullWidth" label={t("visualise.prop_vis")} {...a11yProps(0)} />}
          </mui.Tabs>
        </div>

        <TabPanel wrapped value={tabValue} index={2}>
          <div style={{ display: "flex" }}>
            {!loading ? (
              <mui.Button
                className={selectClasses.button}
                onClick={handleSelectOpen}
              >
                {t("visualise.sel_prop_typ")}
              </mui.Button>
            ) : (
              ""
            )}
            
              <mui.FormControl className={selectClasses.formControl}>
                <mui.Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={selectOpen}
                  onClose={handleSelectClose}
                  onOpen={handleSelectOpen}
                  value={propertyType}
                  onChange={handleSelectChange}
                >
                  <mui.MenuItem value={0}>Mapped</mui.MenuItem>
                  <mui.MenuItem value={1}>Approved</mui.MenuItem>
                  <mui.MenuItem value={2}>Rejected</mui.MenuItem>
                  <mui.MenuItem value={3}>Failed</mui.MenuItem>
                </mui.Select>
              </mui.FormControl>
            
          </div>
          {tabValue === 2 && backDrop}
          {!loading ? (
            <Map {...mapPropsProperty} />
          ) : (
            `Loading ${
              loadingPercent > 0 && loadingPercent < 100 ? loadingPercent : ""
            } %`
          )}
        </TabPanel>

        <TabPanel wrapped value={tabValue} index={1}>
          {/* <mui.DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
           */}
          <div
            className="dustbin-date"
            style={{ display: "flex", flexDirection: "column", width: "200px" }}
          >
            <mui.MuiPickersUtilsProvider utils={DateFnsUtils}>
              <mui.KeyboardDatePicker
                margin="normal"
                id="start-date"
                label="Start Date"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={(date) => handleDustbinDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </mui.MuiPickersUtilsProvider>

            <mui.Button
              variant="contained"
              color="secondary"
              onClick={()=>{handleDustbinShow()}}
            >
              {t("visualise.submit")}
            </mui.Button>
          </div>
          {!isDustbinLoading ? (
            <Map {...mapPropsDustbin} />
          ) : (isDustbinShow && dustbinPercent>0 && dustbinPercent<100) && (
            `Loading  ${dustbinPercent}%`
          )}
        </TabPanel>

        <TabPanel wrapped value={tabValue} index={0}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card className={styles.cards}>
              <CardBody>
                <CardTitle tag="h5">{t("visualise.select_op")}</CardTitle>
                {showSurvey ? (
                  <mui.FormControl className={selectClasses.formControl}>
                    <mui.Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      open={operationOpen}
                      onClose={handleOperationSelectClose}
                      onOpen={handleOperationSelectOpen}
                      value={operationType}
                      onChange={handleOperationSelectChange}
                    >
                      <mui.MenuItem value={0}>{t("visualise.title")}</mui.MenuItem>
                      <mui.MenuItem value={1}>{t("visualise.export")}</mui.MenuItem>
                    </mui.Select>
                  </mui.FormControl>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
            <Card className={styles.cards}>
              <CardBody>
                <CardTitle tag="h5">{t("visualise.sel_sur")}</CardTitle>
                <mui.FormControl className={classes.formControl}>
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    {surveyList.length !== 0 ? (
                      <mui.FormControl
                        variant="outlined"
                        className={selectClasses.formControl}
                      >
                        <mui.InputLabel id="demo-simple-select-outlined-label">
                          {t("visualise.select")}
                        </mui.InputLabel>
                        <mui.Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={selectedSurvey}
                          onChange={handleSelectSurveyChange}
                          label="Select"
                        >
                          <mui.MenuItem value="">
                            <em>{t("visualise.none")}</em>
                          </mui.MenuItem>
                          {surveyList.map((survey, index) => (
                            <mui.MenuItem value={survey.id} key={index}>
                              {survey.surveyTitle}
                            </mui.MenuItem>
                          ))}
                        </mui.Select>
                      </mui.FormControl>
                    ) : (
                      <p>{t("visualise.no_vis_found")}</p>
                    )}
                  </div>
                </mui.FormControl>
              </CardBody>
            </Card>
            <Card className={styles.cards}>
              <CardBody>
                <CardTitle tag="h5">{t("visualise.sur_sch")}</CardTitle>

                <mui.MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <mui.Grid container justify="space-around">
                    <mui.KeyboardDatePicker
                      margin="normal"
                      id="start-date"
                      label={t("visualise.start_date")}
                      format="MM/dd/yyyy"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />

                    <mui.KeyboardDatePicker
                      margin="normal"
                      id="end-date"
                      label={t("visualise.end_date")}
                      format="MM/dd/yyyy"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </mui.Grid>
                </mui.MuiPickersUtilsProvider>

                <mui.Button
                  variant="contained"
                  color="secondary"
                  onClick={() => initialiseApi()}
                >
                  {t("visualise.submit")}
                </mui.Button>
              </CardBody>
            </Card>
          </div>

          {showSurveyMap ? <Map {...mapPropsSurvey} /> : ""}
        </TabPanel>
      </main>
    </div>
  );
}

const mapStateToPorps = (state) => {
  return {
    allOrgList: state.Organization.allOrgList,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserorgList: (data) => dispatch(actions.getUserOrgList(data)),
    getAccessControl: (data) => dispatch(actions.getAccess(data)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToPorps, mapDispatchToProps)
)(Visualise);
