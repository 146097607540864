/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import  {Link} from "react-router-dom";
import {hasAccess} from '../../../../shared/HasAccess'
import GetAppIcon from "@material-ui/icons/GetApp";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue, grey } from "@material-ui/core/colors";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";

import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,

} from "reactstrap";
import styles from "./Organisation.module.css";

import PropTypes from 'prop-types';

import { withTranslation,useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from '@material-ui/lab/Pagination';
import { connect } from "react-redux";
import { compose } from 'redux';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import MenuItem from "@material-ui/core/MenuItem";

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from '@material-ui/core/IconButton'
import * as actions from "../../../../store/actions/index";


const useStyles = makeStyles((theme) => ({
	root: {

		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "25ch",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
        },
	},
	select: {
		minWidth: "8.5vw",
		"@media (min-width: 320px) and (max-width: 375px)": {
			minWidth: "25vw",
		},
		"@media (min-width: 376px) and (max-width: 425px)": {
			minWidth: "25vw",
		},
		background: "white",
		color: grey[700],
		borderColor: "#D7DAE2",
		borderStyle: "solid",
		borderWidth: "2px",
		borderRadius: "4px",
		paddingLeft: "5px",
		paddingTop: "4px",
		paddingBottom: "4px",
		fontSize: "13px",
		"&:hover": {
			borderColor: grey[400],
		},
		"&:focus": {
			borderRadius: "4px",
			background: "white",
			borderColor: blue[200],
		},
	},
	icon: {
		color: grey[500],
		right: 12,
		position: "absolute",
		userSelect: "none",
		pointerEvents: "none",
	},
	list: {
		paddingTop: 0,
		paddingBottom: 0,
		background: "white",
		color: "#4d4f5c",
		fontSize: "smaller",
		"& li.Mui-selected": {
			fontWeight: 400,
		},
	}
}));

export   function OrganisationList(props) {
	const borderSelectClasses = useBorderSelectStyles();

	const menuProps = {
		classes: {
			list: borderSelectClasses.list,
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left",
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left",
		},
		getContentAnchorEl: null,
	};
	const classes = useStyles();
	const history = useHistory();
	const [t] = useTranslation('common');

	const [currentStatus, setCurrentStatus] = useState(true);
	const [modal, setModal] = useState(false);

	let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

	const [state, setState] = React.useState({
		search: "",
		name:"",
		id: "",
		btnTitle:(t("org_list.delete")),
		title:(t("org_list.delete_org")),
		isDelete:false,
		status:"none"

   });
   const iconComponent = (props) => {
	return (
		<ExpandMoreIcon
			className={props.className + " " + borderSelectClasses.icon}
		/>
	);
};

	const handleChange = (e) => {
		
		if(e.target.value!=='0'){
		const name = e.target.name;
		setState({
			...state,
			[name]: e.target.value,
		});
		
	}
	};

	
	// OPEN DELETE
	const toggleModal =(data)=>{
		
		setModal(!modal)
		 setState({name: data.name, btnTitle:(t("org_list.delete")), search:"", id:data._id,
		 title:(t("org_list.delete_org")), isAdd:true, isDelete:false, isUpdate:false});

		 setCurrentStatus(false)
   
	   }
// ON SEACH state
const onSearchOrg=(e)=>{
	props.getOrgList(props.page, props.limit,  state.search, state.status, )
}

// dwonload module
	   const downloadExcel = () => {
		props.downlaodExcelFile( 'organisation',state.search,  state.status,)
	}

const handleChangePage = (event, page) => {
		props.getOrgList(page, props.limit, state.search,  state.status, )
	}

	useEffect(() => {
		
		if (props.message&&currentStatus) {
			setModal(!modal)
			setCurrentStatus(false)	
		}
	});

	  /// handle submit 
	  const handleSubmit = (e)=>{
		e.preventDefault();
		setCurrentStatus(true)
		props.deleteOrg(state)
			}
		
		
	// send data 
	const sendData =(data)=>{
	props.updateOrgData(data)
	history.push(`/edit-organization/${data._id}`)
	}

	
	
	  
	
		
	// apply pagination
const setPage = () => {
	
	let total =Math.ceil(props.total / props.limit)
	return(
	<Pagination
	className={classes.root}
	onChange={handleChangePage}
	count={total}
	 shape="rounded"
	 color="primary"
	 variant="outlined"
		  style={{
		 marginTop: "2%",
		 float: "right",
		 }}
	  />
	  )

  };
  // ENABEL LAODING
  const  backDrop =props.loading? (
	<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={props.loading}>
		<CircularProgress color="inherit" />
	</Backdrop>
):null

	useEffect(() => {
		props.getOrgList(1, props.limit, state.search, state.status)
	 }, []);
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<span>{t("org_list.title")}</span>
			</div>
			<div className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div>
							<div className={styles.searchBar}>
								{/* <SearchIcon /> */}
								<TextField
									id="standard-search"
									size="small"
									name='search'
									value={state.search}
									type="search"
									variant="outlined"
									onChange={handleChange}
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
										// marginBottom: "5%",
									}}
									InputProps={{
										startAdornment: icon,
										placeholder: (t("org_list.search")),
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/>
							</div>
						</div>
						<div className={styles.dropDownDiv}>
							<FormControl>
								<Select
									disableUnderline
									labelId="inputLabel"
									name="status"
									placeholder="Status"
									IconComponent={iconComponent}
									className={classes.select}
									MenuProps={menuProps}
									value={state.status}
									onChange={handleChange}
									style={{
										marginRight: "2%",
									}}
								>
									<MenuItem value={"none"}> {t("job_reporting.status")} </MenuItem>{" "}
									<MenuItem value={true}> {t("job_reporting.active")} </MenuItem>{" "}
									<MenuItem value={false}> {t("job_reporting.in_active")} </MenuItem>{" "}


								</Select>
							</FormControl>
							<Button
								onClick={onSearchOrg}
								variant="contained"
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "17px",
									textTransform: 'none'
								}}
							>
								{t("org_list.search_btn")}
							</Button>
						</div>
					</div>
					<div className={styles.buttonAndFilter}>
						
					
						<Button
							onClick={downloadExcel}
							variant="contained"
							color="secondary"
							style={{
								textTransform: "none",
								backgroundColor: "#3B86FF",
								textAlign: "center",
								whiteSpace: "nowrap",
								outline: "none",
								marginLeft: "35%",
								width: "26%",
								fontSize: "smaller",
							}}
						>
							{t("job_reporting.download")}
							<GetAppIcon style={{ marginLeft: "20%" }} />
						</Button>
						{props.accessList.module !== undefined && hasAccess('submoduleId', 'org_add', props.accessList.module) && <div>
							<Link style={{ textDecoration: "none", color: "#43425D" }} to='/add-organization'><Button
								variant="contained"
								style={{
									backgroundColor: "#F2134F",
									color: "white",
									borderRadius: "5px",
									fontSize: "15px",
									textTransform: 'capitalize'
								}}
							>
								{t("org_list.add_new_org")}
							</Button>
							</Link>
						</div>}
					</div>
				</div>
				<Modal isOpen={modal} toggle={toggleModal} centered={true}>
					<ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
					<ModalBody className={styles.modalContainer}>
						<p>{t("org_list.delete_alert")}  <strong>{state.name}</strong> ? </p>
						
					</ModalBody>
					<ModalFooter>
						<Button
							variant="contained"
							color="primary"
							onClick={toggleModal}
							style={{ marginRight: "2%" }}
						>
							{t("org_list.cancel")}
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={(e) => { handleSubmit(e) }}
						>
							{state.btnTitle}
						</Button>
					</ModalFooter>
				</Modal>
				<div className={styles.table}>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead style={{ backgroundColor: '#F5F6FA' }}>
								<TableRow>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>{t("org_list.organisation_name")}</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>{t("org_list.sub_doamain")}</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>{t("org_list.state")}</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>{t("org_list.district")}</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>{t("org_list.status")}</TableCell>
									<TableCell align="center"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.orgList.length > 0 && props.orgList.map((org) => (
									<TableRow key={org._id}>
										<TableCell align="left">
											{org.name}
										</TableCell>
										<TableCell align="left">{org.subDomain}</TableCell>
										<TableCell align="left">{org.stateId.state}</TableCell>
										<TableCell align="left">{org.districtId.district}</TableCell>
										<TableCell align="left">{org.status ? "Active" : "In-Active"}</TableCell>
										<TableCell align="justify" padding="checkbox">
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
												<IconButton>
										
													{props.accessList.module !== undefined && hasAccess('submoduleId', 'org_delete', props.accessList.module) && <DeleteForeverIcon
														onClick={() => { toggleModal(org) }}
														style={{
															padding: "none",
															cursor: "pointer",
														}}
													/>}
												</IconButton>
												<IconButton>
									
													{props.accessList.module !== undefined && hasAccess('submoduleId', 'org_update', props.accessList.module) && <EditIcon
														onClick={() => { sendData(org) }}
														style={{
															color: "#f2134f",
															cursor: "pointer",
														}}
													// onClick={toggleModal}
													/>}
												</IconButton>
											</div>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{props.orgList.length === 0 && <p style={{ textAlign: 'center' }}>{t("org_list.no_record_found")}</p>}

					</TableContainer>

				</div>
				<div style={{ paddingTop: '2%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					{props.orgList.length > 0 && setPage()}
				</div>
			</div>
		</div>
	);
}
OrganisationList.propTypes = {
	t: PropTypes.func.isRequired,
  };
const mapStateToProps = (state) => {
	
	return {
		loading: state.Organization.loading,
		error: state.Organization.error,
	   message:state.Organization.message,
	   orgList:state.Organization.orgList,
	   page:state.Organization.page,
	   limit:state.Organization.limit,
	   total:state.Organization.total,
	   accessList: state.Organization.accessList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getOrgList: (page, limit, search, status) =>
			dispatch(actions.getOrgList(page, limit, search, status)),
			deleteOrg: (page, limit, search) =>
			dispatch(actions.deleteOrg(page, limit, search)),
			updateOrgData: (data)=>
			dispatch(actions.updateOrgData(data)),
			downlaodExcelFile :(type, search)=>
			dispatch(actions.downloadExcelFile(type, search))
	};
};


export default compose(withTranslation('common'), connect(mapStateToProps,  mapDispatchToProps))(OrganisationList);
