/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
	import React, { useState,  useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import styles from "./VerifyDocument.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as acitons from '../../../../store/actions/index'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper"
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "30ch",
		},
		"& .MuiPagination-root": {
			margin: theme.spacing(3),
			color: "standard",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
        },
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
	"& .MuiPaginationItem-page.Mui-selected": {
		backgroundColor: "#3B86FF",
		color: "white",
		outline: "none",
	},


}));

export  function VerifyDocument(props) {
	const classes = useStyles();
	const [t] = useTranslation('common');

	const [state, setState] = React.useState({
		age: "",
		search:"",
		checkedModule3: "false",
		checkedModule2: "false",
		document:[],
		selectedModule:[],
		userId:""
	});
	const handleChange = (event) => {
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
	};
	const [modal, setModal] = useState(false);
	const toggleModal = (data) => {
		
		setModal(!modal);
		setState({ ...state, document: data.organisation[0].document, userId: data._id })

	}

	// close modal 
	const closeModal =()=>{
	  state.selectedModule.forEach((mdl, i)=>{
		state.selectedModule[i].mod_enableStatus=false
	  })
	  setState({...state, selectedModule:state.selectedModule})
		setModal(false)
	}
	let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

	useEffect(()=>{
		props.getUserList(1,10, localStorage.getItem("orgId"), state.search)
		setTimeout(()=>{
		props.getOrgModuleList(localStorage.getItem('orgId'))
		})
	},[])

// close modal once doc verified
useEffect(()=>{
	if(props.docVerified){
		closeModal()
	}
})
// on search user
const onSearchUser =()=>{
	props.getUserList(1, props.limit, localStorage.getItem("orgId"), state.search)
}

const handleChangePage = (event, page) => {
	props.getUserList(page, props.limit, localStorage.getItem('orgId'), state.search )
	}
// apply pagination
const setPage = () => {
	
		let total =Math.ceil(props.total / props.limit)
		return(
		<Pagination
		className={classes.root}
		onChange={handleChangePage}
		count={total}
		 shape="rounded"
		 color="primary"
		 variant="outlined"
			  style={{
			 marginTop: "2%",
			 float: "right",
			 }}
		  />
		  )
	
	  };
	    // ENABEL LAODING
  const  backDrop =props.loading? (
	<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={true}>
		<CircularProgress color="inherit" />
	</Backdrop>
):null
  // get checked item
  const checkedOption =(id, i ,type)=>{
		
	if(id!==undefined){
		let arr = state.selectedModule;
		var ch =  arr.find(x=>x.moduleId===id)
		if(ch&&ch.mod_enableStatus){
			return true
		} else {
			return false
		}
	}

  }

  const handleCheckChange = (event, data, idx, type, parentIndex) => {
		
	// find date in selected list 
	let fndData = state.selectedModule.find(x => x.moduleId === data._id)
	if (fndData) {
		if (event.target.checked) {
			state.selectedModule[state.selectedModule.indexOf(fndData)].mod_enableStatus = true
		}
		else {
			state.selectedModule[state.selectedModule.indexOf(fndData)].mod_enableStatus = false

		}
	}
	else {
		state.selectedModule.push({ mod_enableStatus: true, moduleId: data._id })
	}
	setState({
		...state,
		selectedModule: state.selectedModule
	});


};
useEffect(() => {
	
	  var arr=[];
	  props.moduleList.forEach((el, index)=>{
		  
		  let ar ={}
		  ar.moduleId=el._id;
		  ar.mod_enableStatus=false
		  arr.push(ar)
		  if(el.subModule.length>0){
			el.subModule.forEach((sub, i)=>{
				
			  let ar ={}
			  ar.moduleId=sub._id;
			  ar.mod_enableStatus=false
			  arr.push(ar)
			})
		  }
	  })
	  setState({
		  ...state,
		  selectedModule: arr
	  });
   }, [props.moduleList.length]);;
  
/// VERIFY DOCUMENT
 const verfiyDocument =(e)=>{
	 
	 e.preventDefault()
	 state.orgId= localStorage.getItem('orgId');
	 props.verifyDocument(state)

 }

	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<span>{t("verify_document.title")}</span>
			</div>
			<div className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div className={styles.searchBar}>
							<TextField
								id="standard-search"
								size="small"
								type="search"
								variant="outlined"
								name="search"
								value={state.search}
								onChange={handleChange}
								style={{
									borderColor: "#F5F6FA",
									borderRadius: "4px",
								}}
								InputProps={{
									startAdornment: icon,
									placeholder: (t("verify_document.search")),
									classes: { input: classes.input },
									color: "#4D4F5C",
									focused: classes.focused,
								}}
							/>
						</div>
						<div className={styles.dropDownDiv}>
							{/* <Dropdown holder="Filter" /> */}
							<Button
								variant="contained"
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "20px",
									width: "35%",
									textTransform: "none",
									outline: "none",
								}}
								onClick={onSearchUser}
							>
								{t("verify_document.search_btn")}
							</Button>
						</div>
					</div>
				</div>

				<div className={styles.table}>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead style={{ backgroundColor: "#F5F6FA" }}>
								<TableRow>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("verify_document.username")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("verify_document.first_name")}
									</TableCell>
									<TableCell align="left" style={{ color: "#A3A6B4" }}>
										{t("verify_document.last_name")}
									</TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell
										align="center"
										style={{ color: "#A3A6B4" }}
									></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.orgUserList.length > 0 && props.orgUserList.map((user) => (
									<TableRow key={user._id}>
										<TableCell
											align="left"
											style={{ color: "#4D4F5C" }}
										>
											{user.username}
										</TableCell>
										<TableCell
											align="left"
											style={{ color: "#4D4F5C" }}
										>
											{user.name}
										</TableCell>
										<TableCell
											align="left"
											style={{ color: "#4D4F5C" }}
										>
											{user.lastName}
										</TableCell>
										<TableCell align="left"></TableCell>
										<TableCell align="left"></TableCell>
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell align="left">
											<span
												style={{
													cursor: "pointer",
													color: "#3B86FF",
												}}
												onClick={() => { !user.organisation[0].docVerify && toggleModal(user) }}
									
											>
												{user.organisation[0].docVerify ? (t("verify_document.verified")) : (t("verify_document.view"))}
											</span>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{props.orgUserList.length === 0 && <p style={{ textAlign: 'center' }}>{t("property_list.no_record_found")}</p>}


					</TableContainer>
				</div>
				<Modal isOpen={modal} backdrop="static"
					toggle={closeModal}
					centered={true}
					// scrollable={true}
					style={{
						maxHeight: "10vw",
					}}
				>
					<ModalBody className={styles.modalContainer}>
						<div className={styles.innerDiv}>
							{state.document.map(doc => <a target="_blank" href={doc.fileUrl}
								style={{
									wordBreak: "break-all",
									color: "#3b86ff",
									textDecoration: "underline",
									background: "white",
									cursor: "pointer",
								}}
							>
								{doc.fileUrl}
							</a>)}
						</div>
                      
						{props.moduleList.length > 0 && props.moduleList.map((module, idx) => <div style={{ width: "100%", marginLeft: "40px" }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={checkedOption(module._id, idx, "parent")}
										onChange={(e) => handleCheckChange(e, module, idx, 'parent')}
										name="parent"
										color="primary"
										key={module._id}
									/>
								}
								label={module.name}
							/>
							{/* <div style={{marginLeft:"50px"}}> */}
							{module.subModule.length > 0 && module.subModule.map((sub, i) => <div style={{ marginLeft: "80px" }}>  < FormControlLabel
								control={
									<Checkbox style={{ display: "inline-block" }}
										checked={checkedOption(sub._id, idx, "child")}
										onChange={(e) => { handleCheckChange(e, sub, i, 'child', idx) }}
										name="child"
										color="primary"
										display="inline-block"
										key={sub._id}
									/>
								}
								label={sub.name + 'sub'}
					
							/>
				
							</div>
							)}
						</div>)}
					</ModalBody>
					<ModalFooter>
						<Button
							variant="contained"
							onClick={closeModal}
							style={{
								marginRight: "4%",
								backgroundColor: "#43425D",
								color: "white",
								textTransform: "none",
								width: "25%",
							}}
						>
							{t("verify_document.cancel")}
						</Button>
						<Button
							variant="contained"
							style={{
								backgroundColor: "#F2134F",
								color: "white",
								textTransform: "none",
								marginRight: "4%",
								width: "25%",
							}}
							onClick={verfiyDocument}
						>
							{t("verify_document.verify")}
						</Button>
					</ModalFooter>
				</Modal>
				
				<div className={classes.root}></div>
			</div>
			<div className={styles.paginationDiv}>
				{props.orgUserList.length > 0 && setPage()}

			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	
	return {
        loading: state.Users.loading,
		orgUserList:state.Users.orgUsersList,
		moduleList:state.Organization.orgModuleList,
		page:state.Users.page,
		limit:state.Users.limit,
		total:state.Users.total,
		docVerified:state.Users.docVerified,


	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getUserList : (page, limit, orgId, search) =>
			dispatch(acitons.getOrgUsersList(page, limit, orgId, search)),
			getOrgModuleList :(orgid)=>
			dispatch(acitons.getOrgModuleList(orgid)),
			verifyDocument :(data)=>
			dispatch(acitons.verifyDocument(data))
	}
}


export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(VerifyDocument)

