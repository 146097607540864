/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import  { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./Inventory.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SelectSearch from 'react-select';
import GetAppIcon from "@material-ui/icons/GetApp";

import FormControl from "@material-ui/core/FormControl";
import { blue, grey } from "@material-ui/core/colors";
import Pagination from "@material-ui/lab/Pagination";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import * as acitons from '../../../../store/actions/index'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from "react-i18next";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "25ch",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
        },
	},
	table: {
		minWidth: 650,
		borderRadius: 0,
	},
	select: {
		minWidth: "8.5vw",
		"@media (min-width: 320px) and (max-width: 375px)": {
			minWidth: "25vw",
		},
		"@media (min-width: 376px) and (max-width: 425px)": {
			minWidth: "25vw",
		},
		background: "white",
		color: grey[700],
		borderColor: "#D7DAE2",
		borderStyle: "solid",
		borderWidth: "2px",
		borderRadius: "4px",
		paddingLeft: "5px",
		paddingTop: "4px",
		paddingBottom: "4px",
		fontSize: "13px",
		"&:hover": {
			borderColor: grey[400],
		},
		"&:focus": {
			borderRadius: "4px",
			background: "white",
			borderColor: blue[200],
		},
	},
	icon: {
		color: grey[500],
		right: 12,
		position: "absolute",
		userSelect: "none",
		pointerEvents: "none",
	},
	list: {
		paddingTop: 0,
		paddingBottom: 0,
		background: "white",
		color: "#4d4f5c",
		fontSize: "smaller",
		"& li.Mui-selected": {
			fontWeight: 400,
		},
	},
}));



export  function Inventory(props) {

	const classes = useStyles();
	const [t] = useTranslation('common');
	const [state, setState] =useState({
		search:"",
		userId:"0",
	})


	// handle change user
	const handleChangeUser = (e, name) => {
		setState({ ...state, userId: e, })

	}
 // DOWNLAOD EXCEL FILE
 const downlaodInventory = () => {
	props.downlaodInventoryList(state.date)
}


    useEffect(()=>{
		props.getInventoryList(1, 10, state.userId._id?state.userId._id:"", state.search);
		setTimeout(()=>{
			props.getOrgUser(localStorage.getItem('orgId'))
		})
	},[])

// on search inventory
// on search attendacen
const onSearchInventory =()=>{
	props.getInventoryList(1, 10, state.userId._id?state.userId._id:"", state.search);
}

	const handleChangePage = (event, page) => {
		props.getInventoryList(page, 10, state.userId._id?state.userId._id:"", state.search);
	}
	// apply pagination
	const setPage = () => {
		
			let total =Math.ceil(props.total / props.limit)
			return(
			<Pagination
			className={classes.root}
			onChange={handleChangePage}
			count={total}
			 shape="rounded"
			 color="primary"
			 variant="outlined"
				  style={{
				 marginTop: "2%",
				 float: "right",
				 }}
			  />
			  )
		
		  };
		  	    // ENABEL LAODING
  const  backDrop =props.loading? (
	<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={props.loading}>
		<CircularProgress color="inherit" />
	</Backdrop>
):null
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<Breadcrumbs seperator="›" aria-label="breadcrumb">
            <Typography color="textPrimary">{t("inventory.operations")}</Typography>
            <Typography color="textSecondary">{t("inventory.title")}</Typography>
          </Breadcrumbs>
			</div>
			<div className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						{localStorage.getItem('smdRole')==='head'&&<div className={styles.dropDownDiv}>
						<FormControl>
								<SelectSearch
									className={styles.seaarchSelect}
									value={state.userId}
									onChange={(e) => { handleChangeUser(e, "userId") }}
									options={props.orgUserList}
									name={"userId"}
									placeholder={t("inventory.user")}
								></SelectSearch>
		</FormControl>
							<Button
							  onClick={onSearchInventory}
								variant="contained"
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "20px",
									textTransform: "none",
									width: "40%",
									marginLeft: "2%",
								}}
							>
								{t("inventory.search_btn")}
							</Button>
						</div>}
					</div>
					<div className={styles.buttonDiv}>
					<Button
						onClick={downlaodInventory}
							variant="contained"
							color="secondary"
							style={{
								textTransform: "none",
								backgroundColor: "#3B86FF",
								textAlign: "center",
								whiteSpace: "nowrap",
								outline: "none",
								marginLeft: "2%",
								fontSize: "smaller",
							}}
						>
							{t("inventory.download")}
							<GetAppIcon style={{ marginLeft: "20%" }} />
						</Button>	
					<Link style={{textDecoration:"none"}} to='/add-inventory'><Button
							variant="contained"
							color="secondary"
							style={{
								backgroundColor: "#F2134F",
								textTransform: "none",
								width: "100%",
							}}
						>
							{t("inventory.add")}
						</Button>
						</Link>
					</div>
				</div>
				<div className={styles.table}>
					<TableContainer component={Paper}>
						<Table
							className={classes.table}
							aria-label="simple table"
						>
							<TableHead style={{ backgroundColor: "#F5F6FA" }}>
								<TableRow>
									{localStorage.getItem('smdRole')==='head'&&<TableCell
										align="left"
										style={{
											textAlign: "left",
											color: "#A3A6B4",
											whiteSpace: "nowrap",
											fontSize: "13px",
										}}
									>
										{t("inventory.username")}
									</TableCell>}
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
											whiteSpace: "nowrap",
											fontSize: "13px",
										}}
									>
										{t("inventory.card")}
									</TableCell>
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
											whiteSpace: "nowrap",
											fontSize: "13px",
										}}
									>
										{t("inventory.fail_card")}
									</TableCell>
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
											whiteSpace: "nowrap",
											fontSize: "13px",
										}}
									>
										{t("inventory.damage_card")}
									</TableCell>
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
											whiteSpace: "nowrap",
											fontSize: "13px",
										}}
									>
										{t("inventory.screw")}
									</TableCell>
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
											whiteSpace: "nowrap",
											fontSize: "13px",
										}}
									>
										{t("inventory.screw_driver")}
									</TableCell>
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
											whiteSpace: "nowrap",
											fontSize: "13px",
										}}
									>
									{t("inventory.drill_gun")}
									</TableCell>
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
											whiteSpace: "nowrap",
											fontSize: "13px",
										}}
									>
										{t("inventory.wood_plug")}
									</TableCell>
									<TableCell
										align="left"
										style={{
											textAlign: "center",
											color: "#A3A6B4",
											whiteSpace: "nowrap",
											fontSize: "13px",
										}}
									>
										{t("inventory.job_sheet")}
									</TableCell>
									<TableCell align="left"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.inventoryList.length>0&&props.inventoryList.map((inventory) => (
									<TableRow key={inventory._id}>
											{localStorage.getItem('smdRole')==='head'&&<TableCell
											component="th"
											scope="row"
											style={{
												color: "#4D4F5C",
												fontFamily:
													"Regular 13px/20px Source Sans Pro",
											}}
										>
											{inventory.name}
										</TableCell>}
										<TableCell
											align="center"
											style={{
												color: "#4D4F5C",
												fontFamily:
													"Regular 13px/20px Source Sans Pro",
											}}
										>
										{inventory.number_of_freshCards}
										</TableCell>
										<TableCell
											align="center"
											style={{
												color: "#4D4F5C",
												fontFamily:
													"Regular 13px/20px Source Sans Pro",
											}}
										>
											{inventory.number_of_failedCards}
										</TableCell>
										<TableCell
											align="center"
											style={{
												color: "#4D4F5C",
												fontFamily:
													"Regular 13px/20px Source Sans Pro",
											}}
										>
										{inventory.number_of_damagedCards}
										</TableCell>
										<TableCell> 	{inventory.number_of_screws}</TableCell>
										<TableCell> {inventory.number_of_screwDriver}</TableCell>
										<TableCell> {inventory.number_of_drillGun}	</TableCell>
										<TableCell> {inventory.number_of_woodPlugs}</TableCell>
										<TableCell>  {inventory.number_of_jobSheet}</TableCell>
										<TableCell></TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{props.inventoryList.length===0&&<p style={{textAlign:'center'}}>{t("property_list.no_record_found")}</p>}

					</TableContainer>
				</div>
			</div>
			<div className={styles.paginationDiv}>
			{props.inventoryList.length>0&&setPage()}

			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		loading: state.Operation.loading,
		isInventoryAdded: state.Operation.isInventoryAdded,
		inventoryList: state.Operation.inventoryList,
		orgUserList: state.Operation.orgUserList,
		page: state.Operation.page,
		limit: state.Operation.limit,
		total: state.Operation.total,
		accessList: state.Organization.accessList,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		getInventoryList : (page, limit, orgId, userId, date, search) =>
			dispatch(acitons.getInventory(page, limit, orgId, userId, date, search)),
			getAllPackage :( orgId)=>
			dispatch(acitons.getAllPackageList(orgId)),
			getOrgUser: (id) =>
			dispatch(acitons.getOrgUser(id)),

			downlaodInventoryList :( orgId)=>
			dispatch(acitons.downloadInventory(orgId)),
	}
}


export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(Inventory)
