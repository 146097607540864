import axios from "axios";
import * as myConstClass from "../../constants/constants";
import * as actionTypes from "./actionTypes";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

const api_url = myConstClass.apiUrl;
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const authSuccessSignup = () => {
  return {
    type: actionTypes.AUTH_SUCCESS_SIGN_UP,
  };
};

export const authFailSignUp = (error) => {
  return {
    type: actionTypes.AUTH_FAIL_SIGN_UP,
  };
};

export const updateUserSuccess = () => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
  };
};

export const updateUserFail = (error) => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
  };
};
export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("orgId");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  localStorage.removeItem("status");
  localStorage.removeItem("username");
  localStorage.removeItem("email");
  localStorage.removeItem("phone");
  localStorage.removeItem("smdRole");
  localStorage.removeItem("firstname");
  localStorage.removeItem("lastname");
  localStorage.removeItem("image");

  // localStorage.removeItem('orgId');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const updateSingupFlag = () => {
  return {
    type: actionTypes.UPDATE_SIGNUP_FLAG,
  };
};

export const checkAuthTimeout = (store) => (next) => (action) => {
  // alert("workgn")

  if (
    localStorage.getItem("token") &&
    jwt_decode(localStorage.getItem("token")).exp < Date.now() / 1000
  ) {
    // alert("save")
    // next(action);
    //    return dispatch => {
    //         dispatch(logout());
    //     };
  }
  next(action);

  // return dispatch => {
  //     setTimeout(() => {
  //         dispatch(logout());
  //     }, expirationTime * 1000);
  // };
};

export const auth = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      value: username,
      password: password,
    };
    //  let url = 'http://13.126.89.240:8080/user/login';
    axios
      .post(api_url + "user/login", authData, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response) => {
        const organisation = response.data.data.organisation[0]
        const orgID = organisation?organisation.organisationId:null
        const orgName = organisation?organisation.details.name:null
        localStorage.setItem("orgName", orgName);
        localStorage.setItem("orgId", orgID); 
        if (response.data.data.isActive) {
          toast.success("Logged In Successfully", {});
        }
        // const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 10000000);
        localStorage.setItem("token", response.data.token);

        localStorage.setItem("status", response.data.data.isActive);
        localStorage.setItem(
          "orgId",
          response.data.data.organisation.length > 0
            ? response.data.data.organisation[0].organisationId
            : ""
        );

        localStorage.setItem("email", response.data.data.email);
        localStorage.setItem("username", response.data.data.username);
        localStorage.setItem("firstname", response.data.data.name);
        localStorage.setItem("lastname", response.data.data.lastName);
        localStorage.setItem("image", response.data.data.profileImage);

        localStorage.setItem("phone", response.data.data.phone);

        // localStorage.setItem('expirationDate', expirationDate);
        localStorage.setItem("userId", response.data.data._id);
        dispatch(authSuccess(response.data.token, response.data.data._id));
        // dispatch(checkAuthTimeout(response.data.expiresIn));
      })
      .catch((err) => {
        dispatch(
          authFail({
            error: err.response ? err.response.data : null,
          })
        );
      });
  };
};

/// REGISTER USER
export const registerUser = (data) => {
  return (dispatch) => {
    dispatch(authStart());
    var value = {
      name: data.firstName,
      lastName: data.lastName,
      username: data.userName,
      email: data.email,
      phone: data.mobile,
      password: data.password,
      role: "admin",
    };
    axios
      .post(api_url + "user/signup", value, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      })
      .then((response) => {
        dispatch(authSuccessSignup({ message: "Thank You For Signing Up." }));
        toast.success(response.data.message);
        // history.push('/')
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          authFailSignUp({
            error: err.response ? err.response.data : null,
          })
        );
      });
  };
};

// update users
/// REGISTER USER
export const updateUser = (data) => {
  return (dispatch) => {
    dispatch(authStart());
    var value = {
      name: data.firstName,
      lastName: data.lastName,
      username: data.userName,
      email: data.email,
      phone: data.mobile,
      address: data.address,
      postal_code: data.postalcode,
      emergency_number: data.mobile,
      bloodGroupId: "5f30ff9647d10a1f009bebc4",
      userId: localStorage.getItem("userId"),
    };
    axios
      .post(api_url + "user/update", value, {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        localStorage.setItem("status", "true");
        dispatch(updateUserSuccess({ message: response.data.message }));
        localStorage.setItem("lastname", value.lastName);
        localStorage.setItem("firstname", value.name);

        toast.success(response.data.message);
        // history.push('/')
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          updateUserFail({
            error: err.response ? err.response.data : null,
          })
        );
      });
  };
};

// export const auth = (username, password) => {
//     return dispatch => {
//         dispatch(authStart());
//         const authData = {
//             value: username,
//             password: password,
//         };
//         let url = 'http://34.229.234.234:8080/user/signup';
//         axios.post(url, authData, {
//             headers: {
//                 'content-type': 'application/json'
//             }
//         })
//             .then(response => {
//                 console.log(response.data);
//                 // const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 10000000);
//                 localStorage.setItem('token', response.data.token);
//                 // localStorage.setItem('expirationDate', expirationDate);
//                 localStorage.setItem('userId', response.data.data._id);
//                 dispatch(authSuccess(response.data.token, response.data.data._id));
//                 // dispatch(checkAuthTimeout(response.data.expiresIn));
//             })
//             .catch(err => {
//                 dispatch(authFail(err.response.data.error));
//             });
//     };
// };

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const userId = localStorage.getItem("userId");
        dispatch(authSuccess(token, userId));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
