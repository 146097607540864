import axios from "axios";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

import * as actionTypes from "./actionTypes";
import * as myConstClass from "../../constants/constants";

const orgId = localStorage.getItem("orgId");
const api_url = myConstClass.apiUrl;
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};
export const addUpdateDeletePropertySuccess = (data, method) => {
  return {
    type: actionTypes.ADD_UPDATE_PROPERTY_SUCCESS,
    data: data,
    method: method,
  };
};

export const addUpdateDeletePropertyFail = (data, method) => {
  return {
    type: actionTypes.ADD_UPDATE_PROPERTY_FAIL,
    data: data,
    method: method,
  };
};

export const UploadPropertySuccess = (data, method) => {
  return {
    type: actionTypes.UPLOAD_PROPERTY_SUCCESS,
    data: data,
  };
};

export const UploadPropertyFail = (data, method) => {
  return {
    type: actionTypes.UPLOAD_PROPERTY_FAIL,
    data: data,
  };
};

export const getPropertySucces = (data) => {
  return {
    type: actionTypes.GET_PROPERTY_SUCCESS,
    data: data,
  };
};

/// get property version
export const getPropertyVersionSuccess = (data) => {
  return {
    type: actionTypes.GET_PROPERTY_VERSION_SUCCESS,
    data: data,
  };
};

export const getPropertyProfileSuccess = (data) => {
    return {
      type: actionTypes.GET_PROPERTY_PROFILE_SUCCESS,
      data: data,
    };
  };
  
export const getPropertyByJobSuccess = (data) => {
  return {
    type: actionTypes.GET_PROPERTY_BY_JOB_SUCCESS,
    data: data,
  };
};

export const getSubCategoryByCatSuccess = (data) => {
  return {
    type: actionTypes.GET_SUBCAT_BY_CAT_SUCCESS,
    data: data,
  };
};
// downlaod property
export const downlaodPropertySuccess = (data) => {
  return {
    type: actionTypes.DOWNLOAD_PROPERYT_SUCCESS,
  };
};

// add update delete propery action
export const addUpdateDeleteProperty = (value) => {
  let url = value.isAdd
    ? `property/add`
    : value.isUpdate
    ? `property/update`
    : `property/delete?organisationId=${orgId}`;
  var data = {};
  if (value.isAdd || value.isUpdate) {
    // data.latitude=2.4;
    // data.longitude=4;
    data.ownerName = value.ownerName;
    data.organisationId = value.orgId;
    data.phone = value.phone;
    data.ward = value.ward;
    data.zone = value.zone;
    data.streetId = value.street;
    data.address_line_1 = value.address1;
    data.address_line_2 = value.address2;
    data.address_line_3 = value.address3;
    data.propertyStatus = "UNMAPPED";
    data.property_on_atikraman = value.onAtikraman;
    data.property_occupied = value.isOccupied;
    data.property_rented = value.onRented;
    data.samadhan_org_Id = value.smdId;
    //    data.govt_property_id=value.govId;
    data.property_typ = "Gated";
    data.description = value.description;
  }
  if (value.categoryId) {
    data.category = value.categoryId;
  }
  if (value.subcategorId) {
    data.subcategorId = value.subcategorId;
  }
  if (value.isUpdate) {
    data.propertyId = value.id;
  } else {
    data.propertyId = value.id;
  }
  return (dispatch) => {
    dispatch(authStart());

    axios
      .post(api_url + url, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let type = value.isDelete ? "delete" : "add";
        toast.success(response.data.message);
        dispatch(addUpdateDeletePropertySuccess(response.data.message, type));
        if (value.isDelete) {
          dispatch(getProperty(1, 10, value.orgId, "", ""));
        }
      })
      .catch((err) => {
        let type = value.isDelete ? "delete" : "add";

        toast.error(err.response.data.message);
        dispatch(addUpdateDeletePropertyFail(err.response.data.message, type));
      });
  };
};

// UPLAOD BULK PROPERTY
// add update delete propery action
export const uploadProperty = (value) => {
  let url = `property/bulk-prop-add`;
  var formData = new FormData();
  formData.append("file", value.propertyFile);
  formData.append("organisationId", orgId);
  //  formData.append('subcategory', value.categoryId)
  //  formData.append('subcategory', value.subcategorId)

  return (dispatch) => {
    dispatch(authStart());

    axios
      .post(api_url + url, formData, {
        headers: {
          //  'content-type': 'application/json',
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(UploadPropertySuccess(response.data.message));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(UploadPropertyFail(err.response.data.message));
      });
  };
};

/// FETCH propery list LIST

export const getProperty = (page, limit, orgId, search, status) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `organisation/org-propertyList/${page}/${limit}?orgId=${orgId}&search_value=${search}&propertyStatus=${status}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getPropertySucces(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getPropertySucces(data));
      });
  };
};

// get property version list

export const getPropertyVersion = (page, limit, id, orgId, search, status) => {
  const data = {
    propertyId: id,
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(
        api_url +
          `property/property_version_list/${page}/${limit}?orgId=${orgId}&search_value=${search}&propertyStatus=${status}`,
        data,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getPropertyVersionSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getPropertyVersionSuccess(data));
      });
  };
};

export const getPropertyProfile = (id) => {
  return (dispatch) => {
    axios
      .get(api_url + `property/profile?propertyId=${id}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if(response.status === 200 && response.data.code === 200)
            dispatch(getPropertyProfileSuccess(response.data.property));
      });
  };
};

export const getProperytByJobId = (
  page,
  limit,
  orgId,
  search,
  status,
  jobId
) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `job/propertylist_by_jobid/${page}/${limit}?orgId=${orgId}&jobId=${jobId}&&search_value=${search}&status=${status}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
            //  "otp":98988
          },
        }
      )
      .then((response) => {
        dispatch(getPropertyByJobSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, totalDocs: 0 },
        };
        dispatch(getPropertyByJobSuccess(data));
      });
  };
};

//  GET SUB CATEGORY BY ID

export const getSubCategoryByCategory = (catId) => {
  const orgId = localStorage.getItem("orgId");
  return (dispatch) => {
    //   dispatch(authStart());
    axios
      .get(
        api_url +
          `subcategory/getbycategoryId?organisationId=${orgId}&categoryId=${catId}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        response.data.data.forEach((element, i) => {
          response.data.data[i].label = `${
            element.subCategoryName
          } ${`(${element.total_property})`}`;
        });
        dispatch(getSubCategoryByCatSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: [],
        };
        dispatch(getSubCategoryByCatSuccess(data));
      });
  };
};

export const downloadProperty = (orgId, search, status) => {
  return async (dispatch) => {
    dispatch(authStart());
    try {
      const response = await axios.get(
        api_url +
          `property/download-property1?orgId=${orgId}&search_value=${search}&propertyStatus=${status}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      //  .then((response) => {

      var blob = new Blob([response.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      //  let filename= type
      saveAs(
        blob,
        `${"Property"}${new Date().toISOString().slice(0, 10)}.xlsx`
      );
      toast.success("File downloaded successfully");
      dispatch(downlaodPropertySuccess());
      //   });
    } catch (error) {
      dispatch(downlaodPropertySuccess());
      toast.error(error.response.data.message);
    }
  };
};
