/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styles from "./Histories.module.css";
import * as mui from "../../../shared/mui"
import History from "../../mainApp/UserHisotory/UserHisotory"
import HisotoryAnyTime from "../../mainApp/HisotoryAnyTime/HisotoryAnyTime";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { withTranslation, useTranslation } from "react-i18next";


const drawerWidth = 252;

const useStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '50%',
      
    // }
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    }
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <mui.Box p={3}>
          <Typography>{children}</Typography>
        </mui.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: mui.PropTypes.node,
  index: mui.PropTypes.any.isRequired,
  value: mui.PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

// FUNCTIONAL COMPONENT STARTS
export function Histories() {


  //CONSTS

  const [t] = useTranslation("common");
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);

    // HANDLERS
    const handleChange = (event, newValue) => {
      setTabValue(newValue);
      if(newValue === 2)
      localStorage.setItem("endMyDay",true)
      else{
        localStorage.removeItem("endMyDay")
      }
    };

  return (
    <div className={styles.main}>
      <mui.CssBaseline />
      <main
        className={mui.clsx(classes.content, {
          [classes.contentShift]: true,
        })}
      >
        <div className={styles.title}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography color="textPrimary">
              {t("operation.operation")}
        </Typography>
            <Typography color="textSecondary">{t("operation.histories")}</Typography>
          </Breadcrumbs>
        </div>
        <mui.AppBar className={classes.appBar} position="static">
        <mui.Tabs
          value={tabValue}
          onChange={handleChange}
          // indicatorColor="primary"
          // textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
            <mui.Tab variant="fullWidth" label={t("operation.history")} {...a11yProps(0)} />
            <mui.Tab variant="fullWidth" label={t("operation.eod")} {...a11yProps(1)} />
            <mui.Tab variant="fullWidth" label={t("operation.user_hist")} {...a11yProps(2)} />
           
          </mui.Tabs>
        </mui.AppBar>

        <TabPanel value={tabValue} index={0}>
          <HisotoryAnyTime />
        </TabPanel>


        <TabPanel value={tabValue} index={1}>
          <History />
        </TabPanel>
     
        <TabPanel value={tabValue} index={2}>
          <History />
        </TabPanel>

      </main>
    </div>
  );
}

export default withTranslation()(Histories);