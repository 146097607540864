import React, { useEffect, useState } from "react";
import styles from "./TaxCalculator.module.css";
import { useTranslation } from "react-i18next";

import TaxUpload from './TaxUpload'
import * as mui from "../../../../../shared/mui";
//axios setup
import axios from "axios";
import { apiUrl } from "../../../../../constants/constants";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';


const drawerWidth = 252;


const useStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const selectStyles = mui.makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const stepperStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "content-type": "application/json",
    Accept: "Application/json",
    Authorization: `${localStorage.getItem("token")}`,
  },
});

export default function TaxCalculator() {
  const stepperClasses = stepperStyles();
  const [activeStep, setActiveStep] = useState(0);
  // const [t] = useTranslation("common");
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const selectClasses = selectStyles();
  const [upload, setUpload] = useState(false);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [floors, setFloors] = useState(0);
  const [dustbins, setDustbins] = useState(0);
  const [tax, setTax] = useState(0);
  const steps = GetSteps();
  const [t] = useTranslation("common");


  const handleSelectChange = (event) => {
    setUpload(event.target.value);
  };

  function GetSteps() {
    return ["Select Calculator", upload ? "Upload" : "Fill Form"];
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleInputChanges = (e) => {
    const [name, value] = [e.target.name, e.target.value];
    if (name === "length") setLength(value);
    else if (name === "width") setWidth(value);
    else if (name === "floors") setFloors(value);
    else if (name === "dustbins") setDustbins(value);
  };
  const fieldsStyle = { margin: 10 };
  function GetStepContent(step) {
    switch (step) {
      case 0:
        return (
          <mui.FormControl
            variant="outlined"
            className={selectClasses.formControl}
          >
            <div>
              <mui.InputLabel id="demo-simple-select-outlined-label">
                {`Calculation Method`}
              </mui.InputLabel>
              <mui.Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={upload}
                onChange={handleSelectChange}
                label="Calculation Method"
              >
                <mui.MenuItem value={false}>Calculator</mui.MenuItem>
                <mui.MenuItem value={true}>Upload Excel</mui.MenuItem>
              </mui.Select>
            </div>
          </mui.FormControl>
        );

      case 1:
        return (
         upload ?  (<div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TaxUpload/>
          </div>):(
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={fieldsStyle}>
              <mui.TextField
                id="outlined-basic"
                label="Length"
                variant="outlined"
                placeholder="Enter Length"
                type="number"
                name="length"
                value={length}
                onChange={(e) => {
                  handleInputChanges(e);
                }}
              />
            </div>
            <div style={fieldsStyle}>
              <mui.TextField
                id="outlined-basic"
                label="Width"
                variant="outlined"
                placeholder="Enter Width "
                type="number"
                name="width"
                value={width}
                onChange={(e) => {
                  handleInputChanges(e);
                }}
              />
            </div>
            <div style={fieldsStyle}>
              <mui.TextField
                id="outlined-basic"
                label="Floors"
                variant="outlined"
                placeholder="Enter no of floors"
                type="number"
                name="floors"
                value={floors}
                onChange={(e) => {
                  handleInputChanges(e);
                }}
              />
            </div>
            <div style={fieldsStyle}>
              <mui.TextField
                id="outlined-basic"
                label="Dustbins"
                variant="outlined"
                placeholder="Enter no of dustbins"
                type="number"
                name="dustbins"
                value={dustbins}
                onChange={(e) => {
                  handleInputChanges(e);
                }}
              />
            </div>
            <div style={fieldsStyle}>
              <mui.TextField
                id="outlined-read-only-input"
                label="Calculated Tax"
                value={tax}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </div>
            <div style={fieldsStyle}>
              <mui.Button
                variant="contained"
                color="primary"
                onClick={handleCalculate}
              >
                Calculate
              </mui.Button>
            </div>
          </div>)
        
        );
      default:
        return "Unknown step";
    }
  }

  const handleCalculate = () => {
    const listApi = `taxation/calculate?length=${length}&width=${width}&floors=${floors}&numberOfDustbins=${dustbins}`;
    
    instance
      .request({
        method: "GET",
        url: listApi,
      })
      .then(function (response) {

        console.log(response, "status")
        if (response.status === 200 && response.data.status) {
          setTax(response.data.tax)
        }

      })
      .catch(function (error) {
        console.log(error);
      }); //axios-end
  };

  useEffect(() => {
  if (loading) {
    setTimeout(() => {
    setLoading(false);
  }, 2000);
  }
}, [loading]);

  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading}>
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;


  return (
    <div className={classes.root}>
      {backDrop}
      <mui.CssBaseline />
      <main
        className={mui.clsx(classes.content, {
          [classes.contentShift]: true,
        })}
      >
        <div className={styles.title}>
          <Breadcrumbs seperator="›" aria-label="breadcrumb">
            <Typography color="textPrimary">{t("taxation_dash.pay_tax")}</Typography>
          <Link to="/taxation">
            <Typography color="textPrimary">{t("taxation_dash.taxations")}</Typography>
            </Link>
            <Typography color="textSecondary">{t("taxation_dash.tax_calc")}</Typography>
          </Breadcrumbs>
        </div>
        <div>
          <mui.Paper elevation={3} style={{ padding: "50px" }}>
            {/* .................CONTENT BOX START.............. */}
            <div>
              <mui.Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <mui.Step key={label}>
                    <mui.StepLabel>{label}</mui.StepLabel>
                    <mui.StepContent>
                      <mui.Typography>{GetStepContent(index)}</mui.Typography>
                      <div className={stepperClasses.actionsContainer}>
                        <mui.Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={stepperClasses.button}
                        >
                          Back
                        </mui.Button>

                        <mui.Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={stepperClasses.button}
                        >
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </mui.Button>
                      </div>
                    </mui.StepContent>
                  </mui.Step>
                ))}
              </mui.Stepper>
              {activeStep === steps.length && (
                <mui.Button
                  onClick={handleReset}
                  className={stepperClasses.button}
                >
                  Reset
                </mui.Button>
              )}
            </div>
            {/* ................CONTENT BOX END................... */}
          </mui.Paper>
        </div>
      </main>
    </div>
  );
}
