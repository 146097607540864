/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { hasAccess } from "../../../../../shared/HasAccess";

import download from "../../../../../assets/upload1.png";

import styles from "./CategoryList.module.css";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit from "./edit.png";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import * as actions from "../../../../../store/actions/index";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export function CategorList(props) {
  const [t] = useTranslation("common");

  const classes = useStyles();
  const [state, setState] = React.useState({
    category: "",
    search: "",
    id: "",
    btnTitle: "Save",
    title: "Add",
    isAdd: false,
    isUpdate: false,
    isDelete: false,
  });
  const [errors, setErros] = useState({});

  /// FETCH CATEGORY LIST HERE
  useEffect(() => {
    props.getCategoryList(1, props.limit, state.search);
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setErros({});
  };
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);

  let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  // OPEN ADD category MODAL
  const toggleModal = (e) => {
    setModal(!modal);
    setState({
      category: "",
      btnTitle: t("category.save"),
      title: t("category.add_category"),
      isAdd: true,
      isDelete: false,
      isUpdate: false,
    });
    setErros({});
    setCurrentStatus(false);
  };

  // EDIT categoyr
  const editCategory = (data) => {
    setState({
      category: data.categoryName,
      id: data._id,
      btnTitle: t("category.update"),
      title: t("category.update_category"),
      isAdd: false,
      isDelete: false,
      isUpdate: true,
    });
    setErros({});
    setModal(true);
  };

  // DELETE category
  const deleteCategory = (data) => {
    setState({
      category: data.categoryName,
      id: data._id,
      btnTitle: t("category.delete"),
      title: t("category.delete_category"),
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });
    setErros({});
    setModal(true);
  };

  // DOWNLAOD EXCEL FILE
  const downloadFile = () => {
    props.downloadExcelCatSubCate(
      "category",
      localStorage.getItem("orgId"),
      state.search,
      null
    );
  };
  // VAIDAATE FORM
  const validateForm = () => {
    var valid = true;
    if (state.category.trim() === "") {
      errors.category = t("category.category_error");
      valid = false;
    } else {
      errors.category = "";
      valid = true;
    }
    setErros((errors) => ({ ...errors }));
    return valid;
  };
  /// handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.isDelete && !validateForm()) {
      return;
    } else {
      setCurrentStatus(true);
      state.orgId = localStorage.getItem("orgId");
      props.manageCategory(state);
    }
  };
  // ON SEARCH category
  const onSearchCategory = (e) => {
    props.getCategoryList(props.page, props.limit, state.search);
  };

  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    }
  });
  // on change page number
  const handleChangePage = (event, page) => {
    props.getCategoryList(page, props.limit, state.search);
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  // ENABEL LAODING
  const backDrop = props.loading ? (
    <Backdrop style={{ zIndex: 1204 }} className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <Breadcrumbs seperator="›" aria-label="breadcrumb">
            <Typography color="textPrimary">{t("category.properties")}</Typography>
            <Typography color="textSecondary">{t("category.title")}</Typography>
          </Breadcrumbs>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <TextField
                  id="standard-search"
                  size="small"
                  type="search"
                  variant="outlined"
                  name="search"
                  value={state.search}
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    marginBottom: "0%",
                  }}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: t("category.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
            </div>
            <div className={styles.dropDownDiv}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  borderRadius: "20px",

                  textTransform: "none",
                  width: "40%",
                  fontWeight: "lighter",
                  // marginBottom: "3%",
                  height: "90%",
                }}
                onClick={onSearchCategory}
              >
                {t("category.search_btn")}
              </Button>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "category_download",
                props.accessList.module
              ) && (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    textTransform: "none",
                    textAlign: "center",
                    width: "50%",
                    alignSelf: "right",
                    outline: "none",
                    backgroundColor: "#3B86FF",
                    fontSize: "10px",
                  }}
                  onClick={downloadFile}
                >
                  {t("category.download")}
                  <img
                    src={download}
                    style={{
                      transform: "rotate(180deg)",
                      marginLeft: "30%",
                    }}
                    alt="download"
                  />
                </Button>
              )}
            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "category_add",
                props.accessList.module
              ) && (
                <Button
                  onClick={toggleModal}
                  variant="contained"
                  style={{
                    backgroundColor: "#F2134F",
                    color: "white",
                    textTransform: "none",
                    width: "34%",
                  }}
                >
                  {t("category.add")}
                </Button>
              )}
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            {state.isDelete && (
              <p>
                {t("category.delete_msg")} <strong>{state.category}</strong> ?{" "}
              </p>
            )}
            {!state.isDelete && (
              <form className={classes.root}>
                <TextField
                  className={classes.root}
                  variant="outlined"
                  label={t("category.name")}
                  name="category"
                  style={{ width: "80%" }}
                  value={state.category}
                  onChange={handleChange}
                />
                {errors.category && (
                  <small className="error" style={{ color: "red" }}>
                    {errors.category}
                  </small>
                )}
              </form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              style={{ marginRight: "2%" }}
            >
              {t("category.cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </Button>
          </ModalFooter>
        </Modal>
        <div className={styles.table}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <TableRow>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("category.category_name")}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("category.is_active")}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.categoryList.length > 0 &&
                  props.categoryList.map((category) => (
                    <TableRow key={category.name}>
                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {category.categoryName}
                      </TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="center">
                        <span
                          style={{
                            color: category.status ? "#1CB10B" : "#F80808",
                          }}
                        >
                          {category.status ? "Yes" : "No"}
                        </span>
                      </TableCell>
                      <TableCell align="justify" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "category_delete",
                            props.accessList.module
                          ) && (
                            <DeleteForeverIcon
                              onClick={() => {
                                deleteCategory(category);
                              }}
                              style={{
                                padding: "none",
                              }}
                            />
                          )}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          editCategory(category);
                        }}
                        align="left"
                        padding="checkbox"
                      >
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "category_update",
                            props.accessList.module
                          ) && <img src={edit} alt="Edit" />}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {props.categoryList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {t("category.no_record_found")}
              </p>
            )}
          </TableContainer>
        </div>

        <div className={classes.root}></div>
      </div>
      {props.categoryList.length > 0 && setPage()}
    </div>
  );
}

CategorList.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    loading: state.Category.loading,
    error: state.Category.category.error,
    message: state.Category.category.message,
    categoryList: state.Category.category.categoryList,
    page: state.Category.category.page,
    limit: state.Category.category.limit,
    total: state.Category.category.total,
    accessList: state.Organization.accessList,

    //    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    manageCategory: (data) => dispatch(actions.addUpdateDeleteCategory(data)),
    getCategoryList: (page, limit, search) =>
      dispatch(actions.getCategoryList(page, limit, search)),
    downloadExcelCatSubCate: (type, orgId, search) =>
      dispatch(actions.downloadExcelCatSubCate(type, orgId, search)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(CategorList);
