import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import history from "./history";
import { config as i18nextConfig } from "./localization";

import authReducer from "./store/reducers/auth";
import {
  Country,
  State,
  City,
  Language,
  Module,
  Category,
  SubCategory,
  District,
  Blood,
} from "./store/reducers/master";

import payments from "./store/reducers/payments"
import taxation from "./store/reducers/taxation"

import { BlankSamadhan, OrgSamadhan } from "./store/reducers/samadhanId";

import Organization from "./store/reducers/organization";
import Operation from "./store/reducers/operation";

import Property from "./store/reducers/property";

import Users from "./store/reducers/users";

import { checkAuthTimeout } from "./store/actions/auth";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'


Bugsnag.start({
  apiKey: '641e3173214f7ab16ba93ca940c619d9',
  plugins: [new BugsnagPluginReact()]
})

i18n.init(i18nextConfig);


const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

// const composeEnhancers = compose
// (process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
// : null || compose);
const composeEnhancers = compose(null||compose)

const rootReducer = combineReducers({
  auth: authReducer,
  Country: Country,
  State: State,
  City: City,
  Language: Language,
  Module: Module,
  BlankSamadhan: BlankSamadhan,
  Organization: Organization,
  OrgSamadhan: OrgSamadhan,
  Payments:payments,
  Taxation:taxation,
  Category: Category,
  SubCategory: SubCategory,
  District: District,
  Blood: Blood,
  Property: Property,
  Operation: Operation,
  Users: Users,
});
// Bugsnag.notify(new Error('Test error'))
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(checkAuthTimeout, thunk))
);

ReactDOM.render(
  // <React.StrictMode>
  
  <Provider store={store}>
    <BrowserRouter history={history}>
      <I18nextProvider i18n={i18n}>
      <ErrorBoundary>
        
        <App />
        </ErrorBoundary>
      </I18nextProvider>
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// Bugsnag.notify(new Error('Test error'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
