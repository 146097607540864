/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import GetAppIcon from "@material-ui/icons/GetApp";

import {hasAccess} from '../../../../shared/HasAccess'

import styles from "./Team.module.css";
import { makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import { connect } from "react-redux";
import { compose } from 'redux';
import { withTranslation,useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit from "./edit.png";
import * as actions from "../../../../store/actions/index";


const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
		borderRadius: 0,
	},
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "30ch",
		},
		"& .MuiPagination-root": {
			margin: theme.spacing(3),
			color: "standard",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
        },
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
}));


export  function Language(props) {
	const classes = useStyles();
	const [t] = useTranslation('common');

	const [state, setState] = React.useState({
		 team: "",
		
		 search: "",
		 id: "",
		 btnTitle:"Save",
		 title:"Add",
		 isAdd:false,
		 isUpdate:false,
		 isDelete:false,


	});
	const [errors , setErros]= useState({})


	/// FETCH Role LIST HERE
	useEffect(() => {
		
	  props.getTeamList(1, props.limit, state.search)
	  }, []);


	const handleChange = (event) => {
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
		setErros({})
	};
	const [modal, setModal] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(true);

	let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

	// OPEN ADD COUNTRY MODAL
	const toggleModal =(e)=>{
		
	 setModal(!modal)
      setState({...state,team:"", btnTitle:'Save',
	  title:'Add Team', isAdd:true, isDelete:false, isUpdate:false});
	  setErros({})
	  setCurrentStatus(false)

	}

   // EDIT team
   const editTeam =(data)=>{
	 	setState({...state,team:data.name,  id:data._id,   btnTitle:'Update',
	title:'Update Team',isAdd:false, isDelete:false, isUpdate:true});
	setErros({})
	setModal(true)
   }

   // DELETE team
    const deleteTeam =(data)=>{
		setState({...state,team:data.name,id:data._id,  btnTitle:"Delete",
		title:"Delete Team", isAdd:false, isDelete:true, isUpdate:false});
		setErros({})
		setModal(true)
	}

	   // dwonload module
	   const downloadExcel = () => {
		props.downlaodExcelFile( 'team',state.search)
	}

// VAIDAATE FORM
  const  validateForm =()=>{
	  
	  var valid =true
	if(state.team.trim()===''){
		errors.team='Team name is required';  
		valid=false;
	  }
	
	  else{
		 errors.team=''; 
		 valid=true;
	 }
	 setErros(errors => ({ ...errors, }));
	 return valid
  }
  /// handle submit 
  const handleSubmit = (e)=>{
	
	e.preventDefault();
	if(!state.isDelete&&!validateForm()){
	 return;
   }
   else{
	  setCurrentStatus(true)
	  state.type='SYSTEM_GENERATED'
	  state.teamname= state.team
	   props.addUpdateTeam(state)
	
	
}


  
}
// ON SEACH COMPANY
const onSearchTeam=(e)=>{
	props.getTeamList(props.page, props.limit, state.search)
}

useEffect(() => {
	
	if (props.isTeamAdded&&currentStatus) {
		setModal(!modal)
		setCurrentStatus(false)
	
		
	} else {
		// if(props.error){
		// 	setpassword_ErMsg(props.error)
		// 	setdisplaytext('showBlock')
		// }
		
	}
});

const handleChangePage =(event, page)=>{
;
props.getTeamList(page, props.limit, state.search)
}

// apply pagination
const setPage = () => {
	
	let total =Math.ceil(props.total / props.limit)
	return(
	<Pagination
	className={classes.root}
	onChange={handleChangePage}
	count={total}
	 shape="rounded"
	 color="primary"
	 variant="outlined"
		  style={{
		 marginTop: "2%",
		 float: "right",
		 }}
	  />
	  )

  };

  // ENABEL LAODING
  const  backDrop =props.loading? (
	<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={props.loading}>
		<CircularProgress color="inherit" />
	</Backdrop>
):null


	return (
		
		<div className={styles.main}>
			{backDrop}

			<div className={styles.title}>
				<span style={{ fontWeight: "lighter" }}>{(t("module.master"))} /</span>
				<span style={{ fontWeight: "lighter", color: "#43425D" }}>
					&nbsp;Team
				</span>
			</div>
			<div  className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div>
							<div className={styles.searchBar}>
								<TextField
									id="standard-search"
									size="small"
									name='search'
									value={state.search}
									type="search"
									variant="outlined"
									onChange={handleChange}
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
										// marginBottom: "5%",
									}}
									InputProps={{
										startAdornment: icon,
										placeholder: (t("lang.search")),
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/>
							</div>
						</div>
						<div className={styles.dropDownDiv}>
							<Button
								variant="contained"
							
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "17px",
									textTransform: "none",
									width: "45%",
									fontWeight: "lighter",
									// marginBottom: "3%",
									height: "90%",
									outline: "none",
								}}
								onClick={(e)=>{onSearchTeam(e)}}
							>

								{(t("lang.search"))}
							</Button>
						</div>
					</div>
					<div className={styles.buttonAndFilter}>
						{/* <FormControl variant="outlined">
							<InputLabel
								htmlFor="outlined-age-native-simple"
								style={{ alignText: "center" }}
							>
								Filter
							</InputLabel>
							<Select
								native
								value={state.age}
								onChange={handleChange}
								style={{
									width: "120%",
									maxHeight: "80%",
									marginBottom: "5%",
								}}
								label="Filter"
								inputProps={{
									name: "Filter",
									id: "outlined-age-native-simple",
								}}
							>
								<option aria-label="None" value="" />
								<option value={10}>Ten</option>
								<option value={20}>Twenty</option>
								<option value={30}>Thirty</option>
							</Select>
						</FormControl> */}
									<Button
						onClick={downloadExcel}
							variant="contained"
							color="secondary"
							style={{
								textTransform: "none",
								backgroundColor: "#3B86FF",
								textAlign: "center",
								whiteSpace: "nowrap",
								outline: "none",
								marginLeft: "49%",
								fontSize: "smaller",
							}}
						>
						{t("job_reporting.download")}
							<GetAppIcon style={{ marginLeft: "20%" }} />
						</Button>
						{props.accessList.module!==undefined&&hasAccess('submoduleId', 'team_admin_add',props.accessList.module) &&<Button
							variant="contained"
							color="secondary"
							onClick={toggleModal}
							style={{
								textTransform: "none",
								textAlign: "center",
								width: "25%",
								// height: "95%",
								// marginBottom: "3.2%",
								outline: "none",
								// marginLeft: "50%",
							}}
						>
						Add
						</Button>}
					</div>
				</div>
				<Modal isOpen={modal} toggle={toggleModal} centered={true}>
					<ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
					<ModalBody className={styles.modalContainer}>
					{state.isDelete&&<p>Are you sure you want to delete team <strong>{state.team}</strong> ? </p>}
						{!state.isDelete&&<form className={classes.root}>
							<TextField
							 helperText={errors.role}
							 error={errors.role}
								className={classes.root}
								variant="outlined"
								label={'Team '}
								name ='team'
								style={{ width: "80%" }}
								value={state.team}
								onChange ={handleChange}
							/>

							
							
								
						</form>}
					</ModalBody>
					<ModalFooter>
						<Button
							variant="contained"
							color="primary"
							onClick={toggleModal}
							style={{ marginRight: "2%" }}
						>
							{(t("lang.cancel"))}
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={(e) => { handleSubmit(e) }}
						>
							{state.btnTitle}
						</Button>
					</ModalFooter>
				</Modal>
				<div className={styles.table}>
				<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead style={{ backgroundColor: "#F5F6FA" }}>
					<TableRow>
						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
							TEAM
						</TableCell>
						{/* <TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
									Status
						</TableCell> */}
						{/* <TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
					      {(t("lang.lang_native"))}
						</TableCell> */}
						{/* <TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
						{(t("lang.lnag_s_char"))}
						</TableCell> */}
						
							<TableCell></TableCell>
							<TableCell></TableCell>
						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
							{(t("lang.status"))}
						</TableCell>
					
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>

						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.teamList.length>0&& props.teamList.map((team) => (
						<TableRow key={team._id}>
							<TableCell
								align="left"
								component="th"
								scope="row"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
							{team.name}
							</TableCell>
							<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
							<TableCell
								align="left"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
						{team.status?("Active"):"InActive"}
							</TableCell>
						
						
							<TableCell
							></TableCell>
							<TableCell></TableCell>
					
				
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
					
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="justify" padding="checkbox">
							{props.accessList.module!==undefined&&hasAccess('submoduleId', 'team_delete',props.accessList.module) &&<DeleteForeverIcon
								onClick ={()=>{deleteTeam(team)}}
									style={{
										padding: "none",
										cursor: "pointer",
										color: "#43425D",
									}}
								/>}
							</TableCell>
							<TableCell align="left" padding="checkbox">
							{props.accessList.module!==undefined&&hasAccess('submoduleId', 'team_update',props.accessList.module) &&<img
									src={edit}
									alt="Edit"
									style={{
										cursor: "pointer",
									}}
									onClick={()=>editTeam(team)}
								/>}
							</TableCell>
				
						</TableRow>
					))}
				</TableBody>
			 
			</Table>
			{props.teamList.length===0&&<p style={{textAlign:'center'}}>{(t("lang.no_record_found"))}</p>}
		</TableContainer>

				</div>

				<div className={classes.root}></div>
			</div>
			{props.teamList.length>0&&setPage()}
			
		</div>
	);
}

Language.propTypes = {
	t: PropTypes.func.isRequired,
  };
const mapStateToProps = (state) => {
	
	return {
		loading: state.Organization.loading,
	   message:state.Language.language.message,
	   teamList:state.Organization.teamList,
	   isTeamAdded:state.Organization.isTeamAdded,
	   page:state.Organization.page,
	   limit:state.Organization.limit,
	   total:state.Organization.total,
	   accessList: state.Organization.accessList,

	//    isAuthenticated: state.auth.token !== null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addUpdateTeam: (data) =>
			dispatch(actions.addTeam(data)),
	    	
			getTeamList: (page, limit, search) =>
			dispatch(actions.getTeam(page, limit, search)),
			downlaodExcelFile :(type, search)=>
			dispatch(actions.downloadExcelFile(type, search))
		// onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
	};
};

export default compose(withTranslation('common'), connect(mapStateToProps,  mapDispatchToProps))(Language);