import React from 'react'
import { Container, Row } from 'reactstrap'
import qr from "../components/qr.jpeg"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <section className="s2" style={{ backgroundImage: `url(${qr})`, backgroundSize:"cover"}}>
    <Container style={{padding:"2.5rem"}}>
      <Row className="flex-align">
          <div style={{display:"flex",flexDirection:"column",alignContent:"flex-start"}}>
          <h1>
            3 Step Approach
          </h1>
          <div style={{display:"flex", maxWidth:"100%", alignItems:"flex-start"}}>
            Simple and Effective 3 step approach that provides scalable solution 
            with ease of access.
            </div>
          <ol className="s2__list">
            <li>Mapping</li>
            <li>Training</li>
            <li>Data <span style={{color:"#f50057"}}>CARE</span></li>
          </ol>
          </div>
      </Row>
    </Container>
    <style>{`
        .s2 {
            font-size: 16px;
            margin-top: 4em;
        }
        .s2__mob-pic {
            margin-bottom: 4em;
        }
        @media (min-width: 768px) {
            .s2__mob-pic {
            margin-bottom: 0;
            }
        }
        
        .s2__mob-pic img {
            max-width: 100%;
        }
        .s2__des h1 {
            font-size: 2.6em;
            font-weight: 100;
            letter-spacing: 2.5px;
        }
        .s2__des .bold{
            font-weight: 700;
        }
        .s2__des p {
            font-weight: 100;
            font-size: .95em;
            line-height: 1.8;
        }
        .s2__des ul {
            font-weight: 100;
            font-size: .9em;
            letter-spacing: 1.5px;
            list-style: none;
            padding: 0;
        }
         .s2__list > li {
           color: #555;
            padding: .5em 0;
        }
        .s2__list i {
            margin-right: 1em;
        }
        @media (min-width: 768px) {
            .flex-align {
                justify-content: center;
                align-items: center;
            }
        }
    
    `}</style>
  </section>
)
