/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { hasAccess } from "../../../shared/HasAccess";

import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./UserList.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue, grey } from "@material-ui/core/colors";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./UserList.module.css";
import * as acitons from "../../../store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
// import AntSwitch from '../../../components/AntSwitch/AntSwitch'
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);
const mobileValid = /^[0]?[789]\d{9}$/; // MATCH MOBILE NUMBER
// VALIDATE FORM HERE
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    // minWidth: 400,
    margin: "2%",
  },
  root1: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  select: {
    minWidth: "9.5vw",
    background: "white",
    color: grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    "&:hover": {
      borderColor: grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: blue[200],
    },
  },
  icon: {
    color: grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
  input: {
    "&::placeholder": {
      color: "#4D4F5C",
      fontSize: "smaller",
    },
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[200],
  },
}));
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[200],
  },
  checked: {},
}))(Switch);

export function Dropdown(props1) {
  const [val, setVal] = React.useState(0);

  const handleChange = (event) => {
    setVal(event.target.value);
  };

  const borderSelectClasses = useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const classes = useStyles();

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };

  return (
    <FormControl>
      <Select
        disableUnderline
        labelId="inputLabel"
        placeholder={props1.holder}
        IconComponent={iconComponent}
        className={classes.select}
        MenuProps={menuProps}
        value={val}
        onChange={handleChange}
        style={{
          marginRight: "2%",
        }}
      >
        <MenuItem value={0}> {props1.holder} </MenuItem>{" "}
        <MenuItem value={1}> One </MenuItem>{" "}
        <MenuItem value={2}> Two </MenuItem>{" "}
        <MenuItem value={3}> Three </MenuItem>{" "}
      </Select>
    </FormControl>
  );
}

export function UserList(props) {
  const classes = useStyles();

  const [modal, setModal] = React.useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);
  const [t] = useTranslation("common");

  const [state, setState] = React.useState({
    module1: true,
    submodule1: false,
    submodule2: false,
    module2: false,
    module3: false,
    search: "",
    value: "",
  });
  //
  const toggleModal = () => {
    setModal(!modal);
    setState({ ...state, value: "" });
    setCurrentStatus(false);
  };

  useEffect(() => {
    props.getAllUserList(1, props.limit, state.search);
  }, []);

  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    } else {
    }
  });
  // handle change
  const handleChange = (e, type) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (e.target.value.length > 0 && type === "search") {
      props.getAllUserList(1, 10, e.target.value, state.status);
    }
  };

  let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  const handleChangePage = (event, page) => {
    props.getAllUserList(page, props.limit, state.search, state.status);
  };
  // on search user
  const onSearchUser = (e) => {
    e.preventDefault();
    props.getAllUserList(1, props.limit, state.search);
  };

  // UPDATE SATUS
  const updateStatus = (e, userId, status) => {
    e.preventDefault();
    props.updateStatus(userId, status);
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  // inviate user
  const inviteUser = (e) => {
    e.preventDefault();

    if (state.value.trim() === "") {
      return toast.error(t("user_list.invite_error"));
    } else if (
      !validEmailRegex.test(state.value) &&
      !mobileValid.test(state.value)
    ) {
      return toast.error(t("user_list.invite_error"));
    }
    setCurrentStatus(true);
    props.inviteUser(state);
  };
  // ENABEL LAODING
  const backDrop = props.loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;
  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.titleAndSearch}>
        <div className={styles.title}>{t("user_list.user_list")}</div>
        <form onSubmit={onSearchUser} className={styles.searchAndButton}>
          <div className={styles.searchBar}>
            <TextField
              id="standard-search"
              size="small"
              type="search"
              name="search"
              value={state.search}
              onChange={(e) => {
                handleChange(e, "search");
              }}
              style={{
                backgroundColor: "white",
                color: "#4D4F5C",
                paddingTop: "1%",
                paddingBottom: "1%",
              }}
              InputProps={{
                disableUnderline: true,
                startAdornment: icon,
                placeholder: t("user_list.search_name"),
                classes: { input: classes.input },
                color: "#4D4F5C",
                focused: classes.focused,
              }}
            />
          </div>
          <div>
            <Button
              variant="contained"
              type="submit"
              style={{
                backgroundColor: "#43425D",
                borderRadius: "17px",
                color: "white",
                height: "50%",
                textTransform: "none",
                outline: "none",
              }}
            // onClick={onSearchUser}
            >
              {t("user_list.search_btn")}
            </Button>
          </div>
          {props.accessList.module !== undefined &&
            hasAccess(
              "submoduleId",
              "master_invite_user",
              props.accessList.module
            ) && (
              <Button
                variant="contained"
                onClick={toggleModal}
                style={{
                  backgroundColor: "#0CD241",
                  color: "white",
                  height: "45px",
                  width: "auto",
                  alignSelf: "left",
                  textTransform: "none",
                  outline: "none",
                  whiteSpace: "nowrap",
                }}
              >
                {t("user_list.invite_user")}
              </Button>
            )}
        </form>
        <Modal
          backdrop="static"
          isOpen={modal}
          toggle={toggleModal}
          centered={true}
          scrollable={true}
          style={{
            maxHeight: "10vw",
          }}
        >
          <ModalHeader
            style={{
              font: "Source Sans Pro",
              color: "#43425D",
              fontWeight: "normal",
              fontSize: "28px",
            }}
          >
            {t("user_list.invite_user")}
          </ModalHeader>
          <ModalBody className={styles.modalContainer}>
            <form
              onSubmit={inviteUser}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <div style={{ flex: "1", padding: "0.5vw" }}>
                  <TextField
                    id="standard-search1"
                    size="small"
                    variant="outlined"
                    name="value"
                    value={state.value}
                    onChange={handleChange}
                    style={{
                      borderColor: "#F5F6FA",
                      borderRadius: "4px",
                      minWidth: "10.5vw",
                    }}
                    InputProps={{
                      // startAdornment: icon,
                      placeholder: t("user_list.email_or_phone"),
                      classes: { input: classes.input },
                      color: "#4D4F5C",
                      focused: classes.focused,
                    }}
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              style={{
                backgroundColor: "#43425D",
                textTransform: "none",
                width: "20%",
                marginRight: "5%",
              }}
            >
              {t("user_list.cancel")}
            </Button>
            <Button
              onClick={inviteUser}
              type="submit"
              variant="contained"
              color="secondary"
              style={{
                textTransform: "none",
                width: "20%",
              }}
            >
              {t("user_list.save")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div className={styles.grid}>
        {props.userList.length > 0 &&
          props.userList.map((users) => (
            <Card className={classes.root}>
              <CardContent className={styles.card}>
                <div className={styles.detailsDiv}>
                  <div className={styles.avatarDiv}>
                    <div className={classes.root1}>
                      <Avatar className={classes.large} />
                    </div>
                  </div>
                  <div className={styles.textdetailsDiv}>
                    <div style={{ fontWeight: "bolder" }}>
                      {users.name ? users.name : "-"}
                    </div>
                    <div style={{ fontWeight: "lighter" }}>
                      {users.email ? users.email : "-"}
                    </div>
                    <div style={{ fontWeight: "lighter" }}>
                      {users.phone ? users.phone : "-"}
                    </div>
                  </div>
                </div>
                <div className={styles.switchDiv}>
                  <FormGroup>
                    <Typography component="div">
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <AntSwitch
                            checked={users.isBlock}
                            onChange={(e) => {
                              updateStatus(e, users._id, !users.isBlock);
                            }}
                            name="checkedC"
                          />
                        </Grid>
                      </Grid>
                    </Typography>
                  </FormGroup>
                </div>
              </CardContent>
            </Card>
          ))}
      </div>
      {props.userList.length === 0 && !props.loading && (
        <div className={styles.noRecord}>
          <p style={{ textAlign: "center" }}>
            {" "}
            {t("user_list.no_record_found")}
          </p>
        </div>
      )}

      <div className={styles.paginationDiv}>
        {props.userList.length > 0 && (
          <span className={styles.totalCount}>
            Total number of records:{props.total}
          </span>
        )}
        {props.userList.length > 0 && setPage()}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userList: state.Users.usersList,
    message: state.Users.message,
    loading: state.Users.loading,
    page: state.Users.page,
    limit: state.Users.limit,
    total: state.Users.total,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUserList: (page, limit, search) =>
      dispatch(acitons.getUserList(page, limit, search)),

    inviteUser: (data) => dispatch(acitons.inviteUser(data)),
    updateStatus: (userId, status) =>
      dispatch(acitons.userUpdateStatus(userId, status)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(UserList);
