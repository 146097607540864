/* eslint-disable no-duplicate-case */
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  authRedirectPath: "/dashboard",
  isSignup: false,
  userUpdate: false,
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    isSignup: false,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    error: null,
    loading: false,
  });
};
const authSuccessSignUp = (state, action) => {
  return updateObject(state, {
    isSignup: true,
    loading: false,
  });
};
const authFailSignUp = (state, action) => {
  return updateObject(state, {
    isSignup: false,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error.error.message,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    userId: null,
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {
    authRedirectPath: action.path,
  });
};

//update sign up flag
const updateSignupFlag = (state, action) => {
  return updateObject(state, {
    isSignup: false,
    loading: false,
  });
};

//update users
const updateUserSuccess = (state, action) => {
  return updateObject(state, {
    userUpdate: true,
    loading: false,
  });
};

const updateUserFail = (state, action) => {
  return updateObject(state, {
    userUpdate: false,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    // case actionTypes.AUTH_LOGOUT:
    case actionTypes.AUTH_SUCCESS_SIGN_UP:
      return authSuccessSignUp(state, action);
    case actionTypes.AUTH_FAIL_SIGN_UP:
      return authFailSignUp(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);

    case actionTypes.UPDATE_SIGNUP_FLAG:
      return updateSignupFlag(state, action);

    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);

    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);

    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
