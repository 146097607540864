import React, { useState } from "react";
import styles from "./makePayments.module.css";
import * as mui from "../../../../../shared/mui";
import axios from "axios";
import { apiUrl } from "../../../../../constants/constants";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const drawerWidth = 252;
const useStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const selectStyles = mui.makeStyles((theme) => ({
  formControl: {
    margin: 10,
    minWidth: 120,
    maxWidth: 210,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// Main Component Starts Here
export default function MakePayments(props) {
  const classes = useStyles();
  const selectClasses = selectStyles();

  const fieldsStyle = { margin: 10 };
  const instance = axios.create({
    baseURL: apiUrl,
    headers: {
      "content-type": "application/json",
      Accept: "Application/json",
      Authorization: `${localStorage.getItem("token")}`,
    },
  });

  //   State Variables
  const [ownerData, setOwnerData] = useState({
    organisationId: localStorage.getItem("orgId"),
    propertyId: props?.location?.state?.propertyId,
    ownerName: "",
    email: "",
    phone: "",
    amountPaid: 0,
    paymentMode: "Cash",
    category: "All",
    collectedBy: "Collector",
    send_payment_link: false,
  });

  const [cashfreeData, setCashfreeData] = useState(null);

  let history = useHistory();

  const handleInputChanges = (e) => {
    const { name, value } = e.target;

    if (name === `paymentMode` && value !== `Cashfree`) {
      setOwnerData({
        ...ownerData,
        paymentMode: value,
        send_payment_link: false,
      });
    } else if (name === `send_payment_link`) {
      setOwnerData({
        ...ownerData,
        send_payment_link: !ownerData.send_payment_link,
      });
    } else {
      setOwnerData({
        ...ownerData,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    const taxPayApi = `payment/initiate-payment`;
    instance
      .request({
        method: "POST",
        url: taxPayApi,
        params: {
          orgId: localStorage.getItem("orgId"),
        },
        data: ownerData,
      })
      .then(function (response) {
        if (response.status === 200 && response.data.status) {
          if (ownerData.paymentMode === `Cashfree`) {
            if (ownerData.send_payment_link) alert("Link sent...");
            else {
              setCashfreeData({
                appId: `56907ac02815950ff69db77f270965`,
                orderId: response.data.payment.orderId,
                orderAmount: ownerData.amountPaid,
                orderCurrency: "INR",
                orderNote: `Note`,
                customerName: ownerData.ownerName,
                customerPhone: ownerData.phone,
                customerEmail: ownerData.email,
                returnUrl: `https://testapi.samadhan.tech/payment/success`,
                notifyUrl: `https://testapi.samadhan.tech/payment/collect-payment`,
                paymentToken: response.data.signature,
                vendorSplit: response.data.vendorSplit,
              });
            }
          } else {
            toast.success(response.data.message);
            const collectPaymentData = {};
            collectPaymentData["orderId"] = response.data.payment.orderId;
            collectPaymentData["referenceId"] =
              response.data.payment.transactionId;
            collectPaymentData["txMsg"] = `Transaction Successful`;
            collectPaymentData["txTime"] = new Date(
              response.data.payment.createdAt
            ).valueOf();
            collectPaymentData[`signature`] = response.data.signature;
            collectPaymentData["orderAmount"] = ownerData.amountPaid;
            collectPaymentData[`txStatus`] = `RECEIVED`;
            collectPaymentData[`paymentMode`] =
              response.data.payment.paymentMode;
            collectPaymentData[`userId`] = localStorage.getItem(`userId`);

            collectPayment(collectPaymentData);
          }
        } else {
          toast.error(response.data.message);
        }
      })

      .catch(function (error) {
        console.log(error, "errror");
      }); //axios-end
  };

  const collectPayment = (paymentData) => {
    const collectPaymentApi = `payment/collect-payment`;
    instance
      .request({
        method: "POST",
        url: collectPaymentApi,
        params: {
          orgId: localStorage.getItem("orgId"),
        },
        data: paymentData,
      })
      .then(function (response) {
        if (response.status === 200 && response.data.status) {
          toast.success(response.data.message);
          setTimeout(history.push("/tax_history"), 3000);
        } else {
          toast.error(response.data.message);
        }
      })

      .catch(function (error) {
        console.log(error, "errror");
      }); //axios-end
  };

  const handleRedirect = (event) => {
    document.getElementById("redirectForm").submit();
    setTimeout(history.push("/tax_history"), 3000);
  };

  return (
    <div className={classes.root}>
      <mui.CssBaseline />
      <main
        className={mui.clsx(classes.content, {
          [classes.contentShift]: true,
        })}
      >
        <div className={styles.title}>{"Make Payments"}</div>
        <div>
          <mui.Paper elevation={3} style={{ padding: "50px", margin: "1rem" }}>
            {/* Form Starts */}

            <div
              style={{
                padding: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={fieldsStyle}>
                <mui.TextField
                  id="outlined-basic"
                  label="Enter Name"
                  variant="outlined"
                  placeholder="Enter Name"
                  type="text"
                  name="ownerName"
                  value={ownerData.ownerName}
                  onChange={(e) => {
                    handleInputChanges(e);
                  }}
                />
              </div>
              <div style={fieldsStyle}>
                <mui.TextField
                  id="outlined-basic"
                  label="Enter Email"
                  variant="outlined"
                  placeholder="Enter Email"
                  type="email"
                  name="email"
                  value={ownerData.email}
                  onChange={(e) => {
                    handleInputChanges(e);
                  }}
                />
              </div>
              <div style={fieldsStyle}>
                <mui.TextField
                  id="outlined-basic"
                  label="Enter Phone No."
                  variant="outlined"
                  placeholder="Enter phone no."
                  type="number"
                  name="phone"
                  value={ownerData.phone}
                  onChange={(e) => {
                    handleInputChanges(e);
                  }}
                />
              </div>
              <div style={fieldsStyle}>
                <mui.TextField
                  id="outlined-basic"
                  label="Enter amount"
                  variant="outlined"
                  placeholder="Enter amount"
                  type="number"
                  name="amountPaid"
                  value={ownerData.amountPaid}
                  onChange={(e) => {
                    handleInputChanges(e);
                  }}
                />
              </div>
              <mui.FormControl
                variant="outlined"
                className={selectClasses.formControl}
              >
                <mui.InputLabel id="demo-simple-select-outlined-label">
                  Payment Mode
                </mui.InputLabel>
                <mui.Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="paymentMode"
                  value={ownerData.paymentMode}
                  onChange={(e) => handleInputChanges(e)}
                  label="payment mode"
                >
                  <mui.MenuItem value={`Cash`}>CASH</mui.MenuItem>
                  <mui.MenuItem value={`Cashfree`}>CASHFREE</mui.MenuItem>
                </mui.Select>
              </mui.FormControl>

              {ownerData.paymentMode === "Cashfree" && (
                <mui.FormControl
                  variant="outlined"
                  className={selectClasses.formControl}
                >
                  Send Payment Link
                  <mui.Switch
                    checked={ownerData.send_payment_link}
                    onChange={handleInputChanges}
                    name="send_payment_link"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </mui.FormControl>
              )}

              <div style={fieldsStyle}>
                {cashfreeData === null && (
                  <mui.Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </mui.Button>
                )}

                {cashfreeData !== null && (
                  <form
                    id="redirectForm"
                    method="post"
                    // action="https://test.cashfree.com/billpay/checkout/post/submit" staging
                    action="https://www.cashfree.com/checkout/post/submit" //production
                    target="_blank"
                  >
                    <input
                      type="hidden"
                      name="appId"
                      // value="56907ac02815950ff69db77f270965"Testing
                      value="902904b50dc96020e2191816d09209" //Production
                    />
                    <input
                      type="hidden"
                      name="orderId"
                      autocomplete="off"
                      value={cashfreeData.orderId}
                    />
                    <input
                      type="hidden"
                      name="orderAmount"
                      autocomplete="off"
                      value={cashfreeData.orderAmount}
                    />
                    <input
                      type="hidden"
                      name="orderCurrency"
                      autocomplete="off"
                      value="INR"
                    />
                    <input
                      type="hidden"
                      name="orderNote"
                      autocomplete="off"
                      value="Note"
                    />
                    <input
                      type="hidden"
                      name="customerName"
                      autocomplete="off"
                      value={cashfreeData.customerName}
                    />
                    <input
                      type="hidden"
                      name="customerEmail"
                      autocomplete="off"
                      value={cashfreeData.customerEmail}
                    />
                    <input
                      type="hidden"
                      name="customerPhone"
                      autocomplete="off"
                      value={cashfreeData.customerPhone}
                    />
                    <input
                      type="hidden"
                      name="returnUrl"
                      autocomplete="off"
                      value="https://testapi.samadhan.tech/payment/success"
                    />
                    <input
                      type="hidden"
                      name="notifyUrl"
                      autocomplete="off"
                      value="https://testapi.samadhan.tech/payment/collect-payment"
                    />

                    <input
                      type="hidden"
                      name="vendorSplit"
                      autocomplete="off"
                      value={cashfreeData.vendorSplit}
                    />

                    <input
                      type="hidden"
                      name="signature"
                      autocomplete="off"
                      value={cashfreeData.paymentToken}
                    />

                    <mui.Button
                      variant="contained"
                      color="primary"
                      value="pay"
                      type="submit"
                      onClick={(event) => {
                        handleRedirect(event);
                      }}
                    >
                      Pay
                    </mui.Button>
                  </form>
                )}
              </div>
            </div>
          </mui.Paper>
        </div>
      </main>
    </div>
  );
}
