import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import brandLogo from "../../images/brand_logo.png"
import "../Navbar/Navbar.css"
import { Link } from "react-router-dom";

export default function SamadhanNav() {

  const openNav = () =>{
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }
  const closeNav = ()=>{
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }

  return (
    <Navbar className = "samadhanNav" sticky="top" bg="light" variant="light" style={{ height:"70px" }}>
      <Navbar.Brand href="#home">
        <img className = "brandLogo" src={brandLogo} height="50%" width="30%" alt="Brand Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        style={{ justifyContent: "flex-end", alignItems: "flex-end" }}
      >
      
        <div id="mySidebar" class="sidebar">
          <div class="closebtn" onClick={()=>closeNav()} >&times;</div>
          <Nav.Link>
            <Link to="/" style={{ textDecoration: "none", color:"#f50057" }}>
              <span class="links">Home</span>
            </Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/login" style={{ textDecoration: "none", color:"#f50057" }}>
              <span class="links">Login</span>
            </Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/signup" style={{ textDecoration: "none", color:"#f50057"}}>
            <span class="links">SignUp</span>
            </Link>
          </Nav.Link>

          <Nav.Link>
            <Link to="/privacy" style={{ textDecoration: "none", color:"#f50057"}}>
            <span class="links">Policy</span>
            </Link>
          </Nav.Link>
        </div>

        <div id="main">
          <button class="openbtn" onClick={()=>openNav()}>&#9776;</button>
        </div>

      </Navbar.Collapse>
    </Navbar>
  );
}
