import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";


const initialState = {
  orgList: [],
  stateList: [],
  cityList: [],
  moduleList: [],
  orgModuleList: [],
  allOrgList: [],
  teamList: [],
  userData: {},
  data: [],
  page: 1,
  limit: 10,
  total: "",
  message: null,

  totalMember: 0,

  error: null,
  authRedirectPath: "/dashboard",
  loading: false,
  success: false,
  isUserInvited: false,
  isRoleAdded: false,
  allOrgRoleList: [],
  orgRoleList: [],
  allOrgTeamList: [],
  accessList: [],
  roleList: [],
  isTeamAdded: false,
  isContractorUpdate: false,
  verifyDocList: [],
  docVerified: false,
  orgData: {},
  pendingDocList: [],
  memberList: [],
  languageList: [],
  bloodList: [],
  isRoleChange: false,
  grapList: [],
  userStatus: {},
  verify_docs: 0,
  pending_invitation:0
};

const authStart = (state, action) => {
  // var country=state.country;
  // country.loading=true
  return updateObject(state, {
    // country: country,
    loading: true,
    message: null,
  });
};

const addOrgSuccess = (state, action) => {
  let message = action.act_type === "add" ? null : "success";
  return updateObject(state, {
    message: message,
    error: null,
    loading: false,
    success: true,
  });
};

const addOrgFail = (state, action) => {
  return updateObject(state, {
    message: state.message,
    error: null,
    loading: false,
  });
};

const getOrgSuccess = (state, action) => {
  return updateObject(state, {
    orgList: action.data.data.docs,
    page: action.data.data.page,
    limit: action.data.data.limit,
    total: action.data.data.total,
    data: [],
    loading: false,
    message: null,
    success: false,
  });
};

const getOrgFail = (state, action) => {
  return updateObject(state, {
    orgList: [],
    loading: false,
    success: false,
  });
};

const getAllStateSuccess = (state, action) => {
  return updateObject(state, {
    stateList: action.data.data,
    loading: false,
  });
};

// get verify doc list
const getVerifyDocSuccess = (state, action) => {
  return updateObject(state, {
    verifyDocList: action.data.data.docs,
    page: action.data.data.page,
    limit: action.data.data.limit,
    verify_docs: action.data.data.totalDocs,
    data: [],
    docVerified: false,
    loading: false,
    message: null,
    success: false,
  });
};

const getAllStateFail = (state, action) => {
  return updateObject(state, {
    stateList: [],
      loading: false,
  });
};

const getAllModuleSuccess = (state, action) => {
  return updateObject(state, {
    moduleList: action.data.data,
    loading: false,
  });
};

const getAllModuleFail = (state, action) => {
  return updateObject(state, {
    moduleList: [],
    loading: false,
  });
};

// get all org succes

const getUserOrgSuccess = (state, action) => {
  let orgId = localStorage.getItem("orgId");
  var orgData = {};
  if (!orgId) {
    if (action.data.data.docs.length > 0) {
      localStorage.setItem("orgId", action.data.data.docs[0].orgId);
    }
  } else {
    if (orgId) {
      let orgName = action.data.data.docs.find((x) => x.orgId === orgId);
      localStorage.setItem("orgId", orgId);
      if(orgName?.OrgName !== undefined) localStorage.setItem("orgName", orgName.orgName)
    } else {
      localStorage.setItem("orgId", action.data.data.docs[0].orgId);
      localStorage.setItem("orgName", action.data.data.docs[0].orgName);
    }

    orgData =
      action.data.data.docs.length > 0 &&
      action.data.data.docs.find(
        (x) => x.orgId === localStorage.getItem("orgId")
      );
  }

  return updateObject(state, {
    allOrgList: action.data.data.docs,
    orgData: orgData,
    //  page:action.data.data.page,
    //  limit:action.data.data.limit,
    //  total:action.data.data.total,
    //  data:[],
    loading: false,
    //  message:null,
    //  success:false,
  });
};
/// set org data
const setOrgData = (state, action) => {
  return updateObject(state, {
    data: action.data,
    loading: false,
  });
};

const getTeamSuccess = (state, action) => {
  return updateObject(state, {
    teamList: action.data.data.docs,
    page: action.data.data.page,
    limit: action.data.data.limit,
    total: action.data.data.total,
    data: [],
    loading: false,
    message: null,
    success: false,
    isUserInvited: false,
    docVerified: false,
    isTeamAdded: false,
  });
};
const addTeamSuccess = (state, action) => {
  return updateObject(state, {
    message: "success",
    error: null,
    // loading: true,
    isTeamAdded: true,
  });
};

const addTeamFail = (state, action) => {
  return updateObject(state, {
    message: null,
    error: null,
    loading: false,
    isTeamAdded: false,
  });
};
/// get org moduel success
const getOrgModuleSuccess = (state, action) => {
  return updateObject(state, {
    orgModuleList: action.data.data.module,
    loading: false,
  });
};

const inviteOrgUsersSuccess = (state, action) => {
  let message = "success";
  let success = true;
  return updateObject(state, {
    message: message,
    error: null,
    // loading: false,
    success: success,
    isUserInvited: true,
  });
};

const inviateOrgUsersFail = (state, action) => {
  let message = null;
  return updateObject(state, {
    message: message,
    error: null,
    // success:success,
    loading: false,
    isUserInvited: false,
  });
};

const updateContractorSuccess = (state, action) => {
  let message = "success";
  let success = true;
  return updateObject(state, {
    message: message,
    error: null,
    // loading: false,
    success: success,
    isContractorUpdate: true,
    loading: false,
  });
};
const updateContractorFail = (state, action) => {
  let message = "success";
  let success = true;
  return updateObject(state, {
    message: message,
    error: null,
    success: success,
    isContractorUpdate: false,
    loading: false,
  });
};

// ORGAINSATION ROLE ACTION
const addOrgRoleSuccess = (state, action) => {
  let message = null;
  return updateObject(state, {
    message: message,
    error: null,
    // success:success,
    loading: false,
    isRoleAdded: true,
  });
};

const addOrgRoleFail = (state, action) => {
  let message = null;
  return updateObject(state, {
    message: message,
    error: null,
    loading: false,
    isRoleAdded: false,
  });
};

// GET ALL TEAM LIST
const getAllOrgTeamSuccess = (state, action) => {
  return updateObject(state, {
    allOrgTeamList: action.data.data,
    // loading: false,
  });
};

const getAccessSuccess = (state, action) => {
  return updateObject(state, {
    accessList: action.data.data,
    loading: false,
  });
};

// GET ALL ORG LIST
const getAllOrgrRoleSuccess = (state, action) => {
  return updateObject(state, {
    allOrgRoleList: action.data.data,
    loading: false,
  });
};

// change role
const changeRoleSuccess = (state, action) => {
  return updateObject(state, {
    isRoleChange: true,
    loading: false,
  });
};

const changeRoleFail = (state, action) => {
  return updateObject(state, {
    isRoleChange: false,
    loading: false,
  });
};

// GET ALL ORG LIST
const getRoleSuccess = (state, action) => {
  return updateObject(state, {
    roleList: action.data.data.docs,
    page: action.data.data.page,
    limit: action.data.data.limit,
    total: action.data.data.total,
    data: [],
    loading: false,
    message: null,
    success: false,
    isRoleAdded: false,
  });
};

const addUpdateRoleSuccess = (state, action) => {
  return updateObject(state, {
    //   loading: false,
    message: null,
    success: false,
    isRoleAdded: true,
  });
};
const verifyDocSuccess = (state, action) => {
  return updateObject(state, {
    docVerified: true,
  });
};

const verifyDocFail = (state, action) => {
  return updateObject(state, {
    docVerified: false,
  });
};

// get user  work history
const getPendingDocSuccess = (states, action) => {
  return updateObject(states, {
    pendingDocList: action.data.data.docs,
    page: action.data.data.page,
    limit: action.data.data.limit,
    pending_invitation: action.data.data.totalDocs,
    // success:false,
    loading: false,
  });
};

/// get member list
// get user  work history
const getMemberSuccess = (states, action) => {
  return updateObject(states, {
    memberList: action.data.data.docs,
    page: action.data.data.page,
    limit: action.data.data.limit,
    total: action.data.data.totalDocs,
    totalMember: action.data.data.totalDocs,
    isContractorUpdate: false,
    loading: false,
  });
};


const changeUserActive = (state, payload) => {

  return {
    ...state,memberList:payload.data
  }
};
const addUpdateRoleFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: null,
    success: false,
    isRoleAdded: false,
  });
};

const getUserByIdSuccess = (state, action) => {
  return updateObject(state, {
    userData: action.data.data,
    loading: false,
    message: null,
    success: false,
    isRoleAdded: false,
  });
};

const getAllLngSuccess = (state, action) => {
  return updateObject(state, {
    languageList: action.data.data,
    // loading: false,
  });
};

const getAllBldSuccess = (state, action) => {
  return updateObject(state, {
    bloodList: action.data.data,
    // loading: false,
  });
};

const updateProfileSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

const updateProfileFail = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

const downloadExcelFileSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

const getPeopleProfileSuccess = (state, action) => {
  return updateObject(state, {peopleProfile:action.data});
};

export const Organization = (state = initialState, action) => {


  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    
    case actionTypes.GET_PEOPLE_PROFILE_SUCCESS:
      return getPeopleProfileSuccess(state,action)

    case actionTypes.ADD_ORG_SUCCESS:
      return addOrgSuccess(state, action);

    case actionTypes.ADD_ORG_FAIL:
      return addOrgFail(state, action);

    case actionTypes.GET_ORG_SUCCESS:
      return getOrgSuccess(state, action);

    case actionTypes.GET_ORG_FAIL:
      return getOrgFail(state, action);

    case actionTypes.GET_ALL_STATE_SUCCESS:
      return getAllStateSuccess(state, action);

    case actionTypes.GET_ALL_STATE_FAIL:
      return getAllStateFail(state, action);

    case actionTypes.GET_ALL_MODULE_SUCCESS:
      return getAllModuleSuccess(state, action);

    case actionTypes.UPDATE_ORG:
      return setOrgData(state, action);

    case actionTypes.GET_ALL_MODULE_FAIL:
      return getAllModuleFail(state, action);

    case actionTypes.GET_USER_ORG_SUCCESS:
      return getUserOrgSuccess(state, action);
    case actionTypes.GET_TEAM_SUCCESS:
      return getTeamSuccess(state, action);

    case actionTypes.ADD_TEAM_FAIL:
      return addTeamFail(state, action);

    case actionTypes.GET_ORG_MODULE_SUCCESS:
      return getOrgModuleSuccess(state, action);

    case actionTypes.ADD_TEAM_SUCCESS:
      return addTeamSuccess(state, action);

    case actionTypes.INVITE_ORG_USERS_SUCCESS:
      return inviteOrgUsersSuccess(state, action);

    case actionTypes.INVITE_ORG_USERS_FAIL:
      return inviateOrgUsersFail(state, action);

    case actionTypes.ADD_ORG_ROLE_SUCCESS:
      return addOrgRoleSuccess(state, action);

    case actionTypes.ADD_ORG_ROLE_FAIL:
      return addOrgRoleFail(state, action);

    case actionTypes.GET_ALL_ORG_TEAM_SUCCESS:
      return getAllOrgTeamSuccess(state, action);

    case actionTypes.GET_ALL_ORG_ROLE_SUCCESS:
      return getAllOrgrRoleSuccess(state, action);

    case actionTypes.GET_ACCESS_SUCCESS:
      return getAccessSuccess(state, action);

    case actionTypes.ADD_UPDATE_ROLE_SUCCESS:
      return addUpdateRoleSuccess(state, action);

    case actionTypes.ADD_UPDATE_ROLE_FAIL:
      return addUpdateRoleFail(state, action);

    case actionTypes.GET_VERIFY_DOC_SUCCESS:
      return getVerifyDocSuccess(state, action);

    case actionTypes.CHANGE_USER_ACTIVE:
      return changeUserActive(state,action)
      
    case actionTypes.GET_ROLE_SUCCESS:
      return getRoleSuccess(state, action);

    case actionTypes.GET_USER_BY_ID_SUCCESS:
      return getUserByIdSuccess(state, action);

    case actionTypes.VERIFY_DOCUMENT_SUCCESS:
      return verifyDocSuccess(state, action);

    case actionTypes.VERIFY_DOCUMENT_FAIL:
      return verifyDocFail(state, action);

    case actionTypes.GET_PENDING_DOC_SUCCESS:
      return getPendingDocSuccess(state, action);

    case actionTypes.GET_MEMBER_SUCCESS:
      return getMemberSuccess(state, action);

    case actionTypes.GET_ALL_LNG_SUCCESS:
      return getAllLngSuccess(state, action);

    case actionTypes.GET_ALL_BLOOD_SUCCESS:
      return getAllBldSuccess(state, action);

    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return updateProfileSuccess(state, action);

    case actionTypes.UPDATE_PROFILE_FAIL:
      return updateProfileFail(state, action);

    case actionTypes.CHANGE_ROLE_SUCCESS:
      return changeRoleSuccess(state, action);

    case actionTypes.CHANGE_ROLE_FAIL:
      return changeRoleFail(state, action);

    case actionTypes.DOWNLOAD_ORG_DETAILS:
      return downloadExcelFileSuccess(state, action);

    case actionTypes.UPDATE_CONTRACTOR_SUCCESS:
      return updateContractorSuccess(state, action);

    case actionTypes.UPDATE_CONTRACTOR_FAIL:
      return updateContractorFail(state, action);

    case actionTypes.AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

//
export default Organization;
