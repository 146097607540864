/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

//---CSS related imports---//
import styles from "./SurveyReport.module.css";
//-----------------------//

//---single import for all materialui related imports---//
import * as mui from "../../shared/mui";
//----------------------------------------------------//

//---axios and baseurl import---//
import axios from "axios";
import { apiUrl } from "../../constants/constants";
//----------------------------//


import { Card, CardTitle, CardBody } from "reactstrap";
import { withTranslation, useTranslation } from "react-i18next";


const drawerWidth = 252;
const stepperStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const useStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "content-type": "application/json",
    Accept: "Application/json",
    Authorization: `${localStorage.getItem("token")}`,
  },
});

const columns = [
  {
    id: "recordedBy",
    label: "Recorded By",
    minWidth: 100,
  },
];

const StyledTableCell = mui.withStyles((theme) => ({
  head: {
    backgroundColor: "#ab003c",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 10,
  },
}))(mui.TableCell);

const StyledTableRow = mui.withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(mui.TableRow);

const useTableStyles = mui.makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 800,
  },
  table: {
    minWidth: 400,
  },
});

let rows = [];

const useStyles2 = mui.makeStyles({
  table: {
    minWidth: 500,
  },
});

export function SurveyReport() {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const stepperClasses = stepperStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const [surveyList, setSurveyList] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = React.useState(null);

  const [loadingPercent, setLoadingPercent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  const [isTableCreated, setTableCreated] = useState(false);
  const steps = GetSteps();
  const perPage = 1000;

  const TableClasses = useTableStyles();
  const [tablePage, setTablePage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalResult, setTotalResult] = React.useState(0);

  const classes = useStyles2();
  const handleChangePage = (event, newPage) => {
    setTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setTablePage(0);
  };

  const SurveyCard = () =>
    surveyList !== undefined ? (
      <Card>
        <CardBody>
          <CardTitle tag="h5">{t("survey_report.survey_list")}</CardTitle>
          <mui.FormControl className={classes.formControl}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              {surveyList.length !== 0 ? (
                <mui.FormControl
                  variant="outlined"
                  className={selectClasses.formControl}
                >
                  <mui.InputLabel id="demo-simple-select-outlined-label">
                    {t("survey_report.survey")}
                  </mui.InputLabel>
                  <mui.Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedSurvey}
                    onChange={handleSelectChange}
                    label="Survey"
                  >
                    <mui.MenuItem value="">
                      <em>None</em>
                    </mui.MenuItem>
                    {surveyList.map((survey, index) => (
                      <mui.MenuItem value={survey.id} key={index}>
                        {survey.surveyTitle}
                      </mui.MenuItem>
                    ))}
                  </mui.Select>
                </mui.FormControl>
              ) : (
                <p>{t("survey_report.no_survey")}</p>
              )}
            </div>
          </mui.FormControl>
        </CardBody>
      </Card>
    ) : null;

  const TableDisplay = () => (
    <mui.Paper className={TableClasses.root}>
      <mui.TableContainer className={TableClasses.container}>
        <mui.Table stickyHeader aria-label="sticky table">
          <mui.TableHead>
            <StyledTableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: "400px", maxWidth: "500px" }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </mui.TableHead>
          <mui.TableBody>
            {rows.length === totalResult && !isTableCreated
              ? rows
                  .slice(
                    tablePage * rowsPerPage,
                    tablePage * rowsPerPage + rowsPerPage
                  )
                  .map((row) => {
                    return (
                      <mui.TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <mui.TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </mui.TableCell>
                          );
                        })}
                      </mui.TableRow>
                    );
                  })
              : `No Data ${rows.length}`}
          </mui.TableBody>
        </mui.Table>
      </mui.TableContainer>

      <mui.TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={tablePage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </mui.Paper>
  );

  function GetSteps() {
    return [`${t("survey_report.select_survey")}`, `${t("survey_report.view_survey_response")}`];
  }

  function GetStepContent(step) {
    switch (step) {
      case 0:
        return <SurveyCard />;
      case 1:
        return rows.length === totalResult && !isTableCreated ? (
          <TableDisplay />
        ) : (
          `Loading ${
            loadingPercent > 0 && loadingPercent < 100
              ? `${loadingPercent}%`
              : ""
          }`
        );

      default:
        return "Unknown step";
    }
  }

  const selectStyles = mui.makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(5),
    },
  }));

  // const classes = useStyles();
  const selectClasses = selectStyles();

  const handleSelectChange = (event) => {
    setSelectedSurvey(event.target.value);
  };

  useEffect(() => {
    const listApi = `api/survey/view_publish_survey_list`;
    setLoading(true);

    instance
      .request({
        method: "GET",
        url: listApi,
        params: {
          organisationId: localStorage.getItem("orgId"),
        },
      })
      .then(function (response) {
        setLoading(false);

        if (response.status === 200) {
          setSurveyList(response.data.survey);
        } else setSurveyList([]);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      }); //axios-end
  }, []);

  useEffect(async () => {
    const listApi = `api/survey/view_response_list/${currentPage}/${perPage}`;
    if (selectedSurvey !== undefined && currentPage <= totalPages) {
      await instance
        .request({
          method: "POST",
          url: listApi,
          params: {
            orgId: localStorage.getItem("orgId"),
          },
          data: {
            survey_id: selectedSurvey,
          },
        })
        .then(function (response) {
          if (totalPages !== response.data.response.pages) {
            setTotalPages(response.data.response.pages);
          }
          if (totalResult !== response.data.response.total)
            setTotalResult(response.data.response.total);
          else setIsLoading(false);

          let index = 0;
          for (index = 0; index < response.data.response.docs.length; index++) {
            const responses = response.data.response.docs[index];
            const obj = {
              recordedBy: `${responses.name} (${responses.email})`,
            };
            for (let j = 0; j < responses.surveyQuestions.length; j++) {
              const question = responses.surveyQuestions[j];
              if (index === 0) {
                columns.push({
                  id: `question_${j}`,
                  label: question.questionTitle,
                  minWidth: 100,
                });
              }
              obj[`question_${j}`] = question.answer;
            }
            rows.push(obj);
          }
          setCurrentPage(currentPage + 1);
          setLoadingPercent(parseInt((currentPage / totalPages) * 100));
        })

        .catch(function (error) {
          console.log(error);
        }); //axios-end
    } else if (currentPage > totalPages) {
      setIsLoading(false);
    }
  }, [selectedSurvey, currentPage]);

  useEffect(() => {
    rows = [];
    setCurrentPage(1);
    setTotalPages(1);
    setIsLoading(true);
    setTableCreated(false);
    setTotalResult(0);
  }, [selectedSurvey]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading}>
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  const mainClasses = useStyles();
  return (
    <div className={mainClasses.root}>
      {backDrop}
      <mui.CssBaseline />
      <main
        className={mui.clsx(mainClasses.content, {
          [classes.contentShift]: true,
        })}
      >
        <div className={styles.title}>{t("survey_report.survey_report")}</div>
        <div className="mainContainer"
     
        >
          <mui.Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <mui.Step key={label}>
                <mui.StepLabel>{label}</mui.StepLabel>
                <mui.StepContent>
                  <mui.Typography>{GetStepContent(index)}</mui.Typography>
                  <div className={stepperClasses.actionsContainer}>
                    <mui.Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={stepperClasses.button}
                    >
                      {t("survey_report.back")}
                    </mui.Button>
                    {rows.length === totalResult && !isLoading ? (
                      <mui.Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={stepperClasses.button}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </mui.Button>
                    ) : (
                      `${selectedSurvey === null ? "" :
                        (loadingPercent > 0 && loadingPercent < 100)
                          ? `Loading ${loadingPercent}% `
                          : !isLoading ? "Loading...%" : "Fetching Data.."
                      }`
                    )}
                  </div>
                </mui.StepContent>
              </mui.Step>
            ))}
          </mui.Stepper>
          {activeStep === steps.length && (
            <mui.Button onClick={handleReset} className={stepperClasses.button}>
              Reset
            </mui.Button>
          )}
        </div>
      </main>

      <style>
        {`

            .mainContainer{
              max-width: calc(90vw - 252px);max-height: 100vh;overflow-wrap: anywhere;
            }
            
            .card{
              width: 100%; margin: 5px;
            }
        @media only screen and (max-width: 768px) {
          /* For mobile phones: */
          
          .makeStyles-content-40 {
            padding: 2px; 
            flex-grow: 1;
        }
          .mainContainer{
            max-width:330px;
          }

          .MuiStepper-root {
            padding: 2px;
        }

        }

        
        `}
      </style>

    </div>
  );
}

export default withTranslation()(SurveyReport);
