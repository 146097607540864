/* eslint-disable react-hooks/exhaustive-deps */
// import React from "react";
import React, { useEffect } from "react";
import {Link, useHistory} from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "./Initialisation.module.css";
import FormControl from "@material-ui/core/FormControl";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useBorderSelectStyles } from "@mui-treasury/styles/select/border";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { blue, grey } from "@material-ui/core/colors";
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Select from "@material-ui/core/Select";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import back from "./back.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import * as acitons from '../../../../../store/actions/index'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from "react-i18next";
const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 5,
		borderRadius: 5,
		paddingTop: "2%",
	},
	colorPrimary: {
		backgroundColor: "#DFDFDF",
	},
	bar: {
		borderRadius: 5,
		backgroundColor: (props) => props.color,
		padding: "2%",
	},
}))(LinearProgress);
const useStyles = makeStyles({
	root: {
		maxWidth: "100%",
		minWidth: "100%",
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: "12",
	},
	input: {
		color: "#4D4F5C",
		fontSize: "smaller",
	},
	select: {
		minWidth: "8.5vw",
		// minWidth: "7.5vw",
		"@media (min-width: 320px) and (max-width: 375px)": {
			minWidth: "25vw",
		},
		"@media (min-width: 376px) and (max-width: 425px)": {
			minWidth: "25vw",
		},
		"@media (min-width: 426px) and (max-width: 768px)": {
			minWidth: "12vw",
		},
		"@media (min-width: 769px) and (max-width: 1024px)": {
			minWidth: "10vw",
		},
		background: "white",
		color: grey[700],
		borderColor: "#D7DAE2",
		borderStyle: "solid",
		borderWidth: "2px",
		borderRadius: "4px",
		paddingLeft: "5px",
		paddingTop: "2px",
		paddingBottom: "2px",
		fontSize: "13px",
		"&:hover": {
			borderColor: grey[400],
		},
		"&:focus": {
			borderRadius: "4px",
			background: "white",
			borderColor: blue[200],
		},
	},
	icon: {
		color: grey[500],
		right: 12,
		position: "absolute",
		userSelect: "none",
		pointerEvents: "none",
	},
	list: {
		paddingTop: 0,
		paddingBottom: 0,
		background: "white",
		color: "#4d4f5c",
		fontSize: "smaller",
		"& li.Mui-selected": {
			fontWeight: 400,
		},
	},
});

export  function Initialisation(props) {
	const [t] = useTranslation('common');

	const borderSelectClasses = useBorderSelectStyles();
	const menuProps = {
		classes: {
			list: borderSelectClasses.list,
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left",
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left",
		},
		getContentAnchorEl: null,
	};


	const iconComponent = (props) => {
		return (
			<ExpandMoreIcon
				className={props.className + " " + borderSelectClasses.icon}
			/>
		);
	};
	const classes = useStyles();
	const  history  = useHistory()

	const [state, setState] = React.useState({
		day:"today",
		startDate: new Date().toISOString().slice(0, 10),
		endDate: new Date().toISOString().slice(0, 10),
		endDate1:new Date().toISOString().split('T')[0],
		orgId:"",
		value:"initialisation",
	});
	const today =new Date().toISOString().split('T')[0];

	const handleChange = (event) => {
		history.push(`${event.target.value}`)
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
	};
// handle inputs
	const handleInputs = (event) => {
		
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
		const addDays=(theDate, days)=> {
			return new Date(theDate.getTime() + days*24*60*60*1000);
		}
		var startDate =''; var endDate='';
		if(event.target.name==='day' &&event.target.value==='yesterday'){
		var newDate = addDays(new Date(), -1).toISOString().slice(0, 10);
		 startDate=newDate;
		 endDate=newDate;
		 }
		if (event.target.name === 'day' && event.target.value === 'today') {
			newDate = new Date().toISOString().slice(0, 10);
			startDate = newDate;
			endDate = newDate;
		}
		if (event.target.name ==='startDate') {
			newDate =event.target.value;
			startDate = event.target.value;
			endDate = state.endDate===''?event.target.value:state.endDate
		}
		if (event.target.name ==='endDate') {
			newDate =event.target.value;
			startDate =state.startDate===''?event.target.value:state.startDate
			endDate =event.target.value;
		}
		props.getInitCardList(localStorage.getItem('orgId'), startDate, endDate)
	};
	const normalise = (value) => value / 10;

	useEffect(() => {
		let today=new Date().toISOString().slice(0, 10);
	props.getInitCardList(localStorage.getItem('orgId'), today, today,)
	
}, [])

	const backDrop = props.loading ? (
		<Backdrop style={{ zIndex: 1204 }} className={classes.backdrop} open={props.loading}>
			<CircularProgress color="inherit" />
		</Backdrop>
	) : null
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.header}>
				<div className={styles.title}>
					<Breadcrumbs seperator="›" aria-label="breadcrumb">
						<Typography color="textPrimary">{t("init_end_day.operation")}</Typography>
						<Typography color="textSecondary">{t("init_end_day.job_reporting")}</Typography>
					</Breadcrumbs>
				</div>
				<div className={styles.dropdownMain}>
					<FormControl>
						<Select
							disableUnderline
							labelId="inputLabel"
							placeholder={'QC'}
							IconComponent={iconComponent}
							className={classes.select}
							MenuProps={menuProps}
							value={state.value}
							onChange={handleChange}
							style={{
								marginRight: "2%",
							}}
						>
							<MenuItem value={'initialisation'}> {t("init_end_day.initialisation")} </MenuItem>{" "}
							<MenuItem value={'/operations/jobinstallation'} >  {t("init_end_day.installation")}</MenuItem>{" "}
							<MenuItem value={'/operations/jobqc'}>  {t("init_end_day.qc")}</MenuItem>{" "}
							<MenuItem value={'/operations/jobaudit'}>  {t("init_end_day.audit")} </MenuItem>{" "}
							<MenuItem value={'/operations/work-history'} >  {t("init_end_day.user_work_history")}</MenuItem>{" "}


						</Select>
					</FormControl>
				</div>
			</div>
			<div className={styles.header2}>
				<span className={styles.subTitle}>{t("init_end_day.initialisation")}</span>
				<div className={styles.dropdown2}>
					<FormControl>
						<Select
							disableUnderline
							labelId="inputLabel"
							placeholder={'Today'}
							IconComponent={iconComponent}
							className={classes.select}
							MenuProps={menuProps}
							value={state.day}
							onChange={handleInputs}
							name='day'
							style={{
								marginRight: "2%",
							}}
						>
							<MenuItem value={'today'}> {t("init_end_day.today")} </MenuItem>{" "}
							<MenuItem value={'yesterday'}> {t("init_end_day.yesterday")} </MenuItem>{" "}
		
						</Select>
					</FormControl>
				</div>
				<div className={styles.date}>
					<span className={styles.label}> {t("init_end_day.from_date")} </span>
					<TextField
						id="standard-search"
						size="small"
						type="date"
						variant="outlined"
						name="startDate"
						value={state.startDate}
						onChange={handleInputs}
						className={styles.date_picker}
						// inputProps={{
						// 	max: today
						// 	 }}
						InputProps={{
							classes: { input: classes.input, max: today },
							color: "#4D4F5C",
							focused: classes.focused,
							
						}}
					
					/>
					<span className={styles.label}> {t("init_end_day.to_date")} </span>
					<TextField
						id="standard-search"
						size="small"
						type="date"
						variant="outlined"
						name="endDate"
						value={state.endDate}
						onChange={handleInputs}
						className={styles.date_picker}
						// inputProps={{
						// 	max: state.endDate1
						// 	 }}
						InputProps={{
							max: state.endDate1,
							classes: { input: classes.input },
							color: "#4D4F5C",
							focused: classes.focused,
							
						}}
					/>
				</div>
			</div>
			<div className={styles.cards}>
				<Card className={styles.card}>
					<CardContent>
						<Typography
							className={classes.title}
							style={{
								color: "#BBBBBB",
								textAlign: "center",
							}}
							gutterBottom
						>
							{t("init_end_day.num_job_created")}
						</Typography>
						<Typography
							className={classes.pos}
							style={{
								color: "#03E565",
								textAlign: "center",
								fontSize: "x-large",
								fontWeight: "bold",
								paddingBottom: "5%",
							}}
						>
							{props.initCardList.total_job_created}
						</Typography>
						<BorderLinearProgress
							variant="determinate"
							value={normalise(500)}
							color="#03E565"
							style={{
								backgroundColor: "#DFDFDF",
							}}
						/>
					</CardContent>
				</Card>
				<Card className={styles.card}>
					<CardContent>
						<Typography
							className={classes.title}
							style={{
								color: "#BBBBBB",
								textAlign: "center",
							}}
							gutterBottom
						>
							{t("init_end_day.num_card_init")}
						</Typography>
						<Typography
							className={classes.pos}
							style={{
								color: "#56BBFE",
								textAlign: "center",
								fontSize: "x-large",
								fontWeight: "bold",
								paddingBottom: "5%",
							}}
						>
							{props.initCardList.total_card_initialised}
						</Typography>
						<BorderLinearProgress
							variant="determinate"
							value={normalise(247)}
							color="#56BBFE"
							style={{
								backgroundColor: "#DFDFDF",
							}}
						/>
					</CardContent>
				</Card>
				<Card className={styles.card}>
					<CardContent>
						<Typography
							className={classes.title}
							style={{
								color: "#BBBBBB",
								textAlign: "center",
							}}
							gutterBottom
						>
							{t("init_end_day.num_card_qc")}
						</Typography>
						<Typography
							className={classes.pos}
							style={{
								color: "#FF6060",
								textAlign: "center",
								fontSize: "x-large",
								fontWeight: "bold",
								paddingBottom: "5%",
							}}
						>
							{props.initCardList.total_card_quarantine}
						</Typography>
						<BorderLinearProgress
							variant="determinate"
							value={normalise(100)}
							color="#FF6060"
							style={{
								backgroundColor: "#DFDFDF",
							}}
						/>
					</CardContent>
				</Card>
			</div>
			<div className={styles.final}>
				<Link style={{ textDecoration: "none" }}
			
					//  to ='/operations/init-report-table'>
					to='/operations/init-end-day'>
					<span
						className={styles.label}
						style={{
							fontWeight: "bold",
							cursor: "pointer",
							fontSize: "medium",
						}}
					>
						{t("init_end_day.view_details")} &nbsp;
				</span></Link>
				<img src={back} alt="back" />
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		
		initCardList:state.Operation.initCardList,
		loading: state.Operation.loading,
		


	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getInitCardList: (orgId, startDate, endDate) =>
			dispatch(acitons.getInitCardList(orgId, startDate, endDate)),
			// downloadExcelFile :(orgId, jobType)=>
			// dispatch(acitons.downloadExcel(orgId, jobType)),
		// getSubCategoryByCategory: (id) =>
		// 	dispatch(acitons.getSubCategoryByCategory(id)),
		// getOrgUser: (id) =>
		// 	dispatch(acitons.getOrgUser(id)),
		// addJob: (data) =>
		// 	dispatch(acitons.addJob(data))
	}
}


export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(Initialisation)