import React from "react";
import styles from "./moreDetails.module.css";
import * as mui from "../../../../../shared/mui"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';


const drawerWidth = 252;
const useStyles = mui.makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const token = localStorage.getItem("token")
console.log(token, "token")



// Main Component Starts Here
export default function MoreDetails(props) {
    const [t] = useTranslation("common");

    const details = props.location.state;
    
    console.log('details', details)
    
    const { ownerName, govtPropertyId, paidAmount, propertyId, remainingAmount, samadhanId, totalAmount } = details;

    const classes = useStyles();
    //   State Variables

    // let history = useHistory();


    return (
        <div className={classes.root}>
            <mui.CssBaseline />
            <main
                className={mui.clsx(classes.content, {
                    [classes.contentShift]: true,
                })}
            >
                <div className={styles.title}>
                    <Breadcrumbs seperator="›" aria-label="breadcrumb">
                        <Typography color="textPrimary">{t("tax_more_det.pay_tax")}</Typography>
                        <Typography color="textPrimary">{t("tax_more_det.taxations")}</Typography>
                        <Link to="/payments">
                            <Typography color="textPrimary">{t("tax_more_det.tax_hist")}</Typography>
                        </Link>
                        <Typography color="textSecondary">{t("tax_more_det.title")}</Typography>
                    </Breadcrumbs>
                </div>
                <div>
                    <mui.Paper elevation={3} style={{ padding: "30px", margin: "1rem" }}>

                        {/* Form Starts */}
                        <mui.Paper style={{ padding: "15px", margin: "0.5rem" }}>
                            <mui.Typography>{t("tax_more_det.owner_name")} : <span style={{fontWeight: '700'}}>{ownerName}</span></mui.Typography>
                            <mui.Typography>{t("tax_more_det.govt_prop_id")} : <span style={{fontWeight: '700'}}>{govtPropertyId}</span></mui.Typography>
                            <mui.Typography>{t("tax_more_det.prop_id")} : <span style={{fontWeight: '700'}}>{propertyId}</span></mui.Typography>
                            <mui.Typography>{t("tax_more_det.paid_amt")} : <span style={{fontWeight: '700'}}>{remainingAmount}</span></mui.Typography>
                            <mui.Typography>{t("tax_more_det.rem_amt")} : <span style={{ fontWeight: '700' }}>{paidAmount}</span></mui.Typography>
                            <mui.Typography>{t("tax_more_det.smd_id")} : <span style={{fontWeight: '700'}}>{samadhanId}</span></mui.Typography>
                            <mui.Typography>{t("tax_more_det.tot_amt")} : <span style={{fontWeight: '700'}}>{totalAmount}</span></mui.Typography>
                        </mui.Paper>
                    </mui.Paper>
                </div>


            </main>
        </div>
    );
}
