/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./State.module.css";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import GetAppIcon from "@material-ui/icons/GetApp";

import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit from "./edit.png";
import * as actions from "../../../../store/actions/index";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export function State(props) {
  const [t] = useTranslation("common");

  const classes = useStyles();
  const [state, setState] = React.useState({
    countryId: "",
    state: "",
    search: "",
    code: "",
    id: "",
    btnTitle: "Save",
    title: "Add State",
    isAdd: false,
    isUpdate: false,
    isDelete: false,
  });
  const [errors, setErros] = useState({});
  /// has accessbile
  const hasAccess = (type, name) => {
    if (props.accessList.module !== undefined) {
      if (type === "module") {
        let authObj = props.accessList.module.find(
          (x) => x.moduleId.keyName === name
        );
        if (authObj) {
          if (authObj.mod_enableStatus === "true") {
            return true;
          } else {
            return false;
          }
        }
      } else if (type === "submoduleId") {
        let authObj = props.accessList.module.find((x) =>
          x.submoduleId.find((d) => d.keyName === name)
        );
        if (authObj && authObj.mod_enableStatus === "true") {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  /// FETCH STATE LIST HERE
  useEffect(() => {
    props.getStateList(1, props.limit, state.search, state.countryId);
    setTimeout(() => {
      props.getAllCountry();
    }, 1000);
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "0") {
      const name = e.target.name;
      setState({
        ...state,
        [name]: e.target.value,
      });
      setErros({});
    }
  };
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);

  let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  // OPEN ADD COUNTRY MODAL
  const toggleModal = (e) => {
    setModal(!modal);
    setState({
      countryId: "",
      state: "",
      btnTitle: t("state.save"),
      code: "",
      title: t("state.add_new_state"),
      isAdd: true,
      isDelete: false,
      isUpdate: false,
    });
    setErros({});
    setCurrentStatus(false);
  };

  // EDIT STATE
  const editState = (data) => {
    setState({
      countryId: data.countryId._id,
      state: data.state,
      id: data._id,
      btnTitle: t("state.update"),
      code: data.stateCode,
      title: t("state.update_state"),
      isAdd: false,
      isDelete: false,
      isUpdate: true,
    });
    setErros({});
    setModal(true);
  };

  // DELETE COUNTYR
  const deleteState = (data) => {
    setState({
      state: data.state,
      id: data._id,
      btnTitle: t("state.delete"),
      title: t("state.delete_state"),
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });
    setErros({});
    setModal(true);
  };
  // VAIDAATE FORM
  const validateForm = () => {
    var valid = true;
    if (state.countryId.trim() === "") {
      errors.countryId = t("state.country_error");
      errors.state = "";
      errors.code = "";
      valid = false;
    } else if (state.state.trim() === "") {
      errors.state = t("state.state_error");
      errors.countryId = "";
      errors.code = "";
      valid = false;
    } else if (state.code.trim() === "") {
      errors.code = t("state.code_error");
      errors.countryId = "";
      errors.state = "";
      valid = false;
    } else {
      errors.countryId = "";
      errors.state = "";
      errors.code = "";
      valid = true;
    }
    setErros((errors) => ({ ...errors }));
    return valid;
  };

  // dwonload state
  const downloadExcel = () => {
    props.downlaodExcelFile("state", state.search);
  };
  /// handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.isDelete && !validateForm()) {
      return;
    } else {
      setCurrentStatus(true);
      if (state.isAdd) {
        props.addState(state);
      } else if (state.isUpdate) {
        props.updateState(state);
      } else {
        props.deleteState(state);
      }
    }
  };
  // ON SEACH state
  const onSearchState = (e) => {
    props.getStateList(props.page, props.limit, state.search, state.countryId);
  };

  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    } else {
      // if(props.error){
      // 	setpassword_ErMsg(props.error)
      // 	setdisplaytext('showBlock')
      // }
    }
  });

  const handleChangePage = (event, page) => {
    props.getStateList(page, props.limit, state.search, state.countryId);
  };

  //Prevent to eneter alphabets
  const onKeyPress = (event) => {
    const pattern = /[0-9-+]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  // ENABEL LAODING
  const backDrop = (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <span style={{ fontWeight: "lighter" }}>{t("module.master")} /</span>
        <span style={{ fontWeight: "lighter", color: "#43425D" }}>
          &nbsp;{t("state.title")}
        </span>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <TextField
                  id="standard-search"
                  size="small"
                  name="search"
                  value={state.search}
                  type="search"
                  variant="outlined"
                  onChange={handleChange}
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    // marginBottom: "5%",
                  }}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: t("state.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
            </div>
            <div className={styles.dropDownDiv}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  borderRadius: "17px",
                  textTransform: "none",
                  width: "45%",
                  fontWeight: "lighter",
                  // marginBottom: "3%",
                  height: "90%",
                  outline: "none",
                }}
                onClick={(e) => {
                  onSearchState(e);
                }}
              >
                {t("state.search")}
              </Button>
            </div>
          </div>
          <div className={styles.buttonAndFilter}>
            {/* <FormControl variant="outlined">
							<InputLabel
								htmlFor="outlined-age-native-simple"
								style={{ alignText: "center" }}
							>
								Filter
							</InputLabel>
							<Select
								native
								value={state.age}
								onChange={handleChange}
								style={{
									width: "120%",
									maxHeight: "80%",
									marginBottom: "5%",
								}}
								label="Filter"
								inputProps={{
									name: "Filter",
									id: "outlined-age-native-simple",
								}}
							>
								<option aria-label="None" value="" />
								<option value={10}>Ten</option>
								<option value={20}>Twenty</option>
								<option value={30}>Thirty</option>
							</Select>
						</FormControl> */}
            <Button
              onClick={downloadExcel}
              variant="contained"
              color="secondary"
              style={{
                textTransform: "none",
                backgroundColor: "#3B86FF",
                textAlign: "center",
                whiteSpace: "nowrap",
                outline: "none",
                marginLeft: "46%",
                fontSize: "smaller",
              }}
            >
              {t("job_reporting.download")}
              <GetAppIcon style={{ marginLeft: "20%" }} />
            </Button>
            {props.accessList.module !== undefined &&
              hasAccess("submoduleId", "states_add") && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={toggleModal}
                  style={{
                    textTransform: "none",
                    textAlign: "center",
                    // width: "45%",
                    // height: "95%",
                    // marginBottom: "3.2%",
                    outline: "none",
                    // marginLeft: "53%",
                  }}
                >
                  {t("state.add_new_state")}
                </Button>
              )}
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            {state.isDelete && (
              <p>
                {t("state.delete_msg")} <strong>{state.state}</strong> ?{" "}
              </p>
            )}
            {!state.isDelete && (
              <form className={classes.root}>
                <FormControl style={{ marginLeft: "17px" }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-age-native-simple"
                    style={{ alignText: "center" }}
                  >
                    {t("state.country_name1")}
                  </InputLabel>
                  <Select
                    native
                    value={state.countryId}
                    onChange={handleChange}
                    style={{
                      maxHeight: "80%",
                      marginBottom: "5%",
                      width: "80%",
                    }}
                    label="Filter"
                    inputProps={{
                      name: "countryId",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option aria-label="None" value={"0"}>
                      -{t("state.select_country")}-
                    </option>
                    {props.countryList.length > 0 &&
                      props.countryList.map((country, i) => (
                        <option aria-label="None" value={country._id}>
                          {country.country}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                {errors.countryId && (
                  <small className="error" style={{ color: "red" }}>
                    {errors.countryId}
                  </small>
                )}
                <TextField
                  className={classes.root}
                  variant="outlined"
                  name="state"
                  label={t("state.state1")}
                  value={state.state}
                  onChange={handleChange}
                  style={{ width: "80%" }}
                />
                {errors.state && (
                  <small className="error" style={{ color: "red" }}>
                    {errors.state}
                  </small>
                )}
                <TextField
                  className={classes.root}
                  variant="outlined"
                  name="code"
                  label={t("state.code")}
                  value={state.code}
                  onChange={handleChange}
                  onKeyPress={onKeyPress}
                  style={{ width: "80%" }}
                />
                {errors.code && (
                  <small className="error" style={{ color: "red" }}>
                    {errors.code}
                  </small>
                )}
              </form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              style={{ marginRight: "2%" }}
            >
              {t("state.cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </Button>
          </ModalFooter>
        </Modal>
        <div className={styles.table}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("state.country_name")}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("state.state")}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("state.state_code")}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("state.status")}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>

                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.stateList.length > 0 &&
                  props.stateList.map((state) => (
                    <TableRow key={state._id}>
                      <TableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {state.countryId.country}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {state.state}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {state.stateCode}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {state.status ? "Active" : "In-Active"}
                      </TableCell>

                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>

                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="justify" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess("submoduleId", "states_delete") && (
                            <DeleteForeverIcon
                              onClick={() => {
                                deleteState(state);
                              }}
                              style={{
                                padding: "none",
                                cursor: "pointer",
                                color: "#43425D",
                              }}
                            />
                          )}
                      </TableCell>
                      <TableCell align="left" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess("submoduleId", "states_update") && (
                            <img
                              src={edit}
                              alt="Edit"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => editState(state)}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {props.stateList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {t("state.no_record_found")}
              </p>
            )}
          </TableContainer>
        </div>

        <div className={classes.root}></div>
      </div>
      {props.stateList.length > 0 && setPage()}
    </div>
  );
}

State.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    loading: state.State.loading,
    error: state.State.state.error,
    message: state.State.state.message,
    stateList: state.State.state.stateList,
    countryList: state.State.state.countryList,
    page: state.State.state.page,
    limit: state.State.state.limit,
    total: state.State.state.total,
    accessList: state.Organization.accessList,
    //    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addState: (data) => dispatch(actions.addState(data)),
    updateState: (data) => dispatch(actions.updateState(data)),
    deleteState: (data) => dispatch(actions.deleteState(data)),
    getStateList: (page, limit, search, countryId) =>
      dispatch(actions.getStateList(page, limit, search, countryId)),
    getAllCountry: () => dispatch(actions.getAllCountry()),
    downlaodExcelFile: (type, search) =>
      dispatch(actions.downloadExcelMaster(type, search)),

    // onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(State);
