export const AUTH_START = "AUTH_START";
export const FETCH_USER_START = "FETCH_USER_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SUCCESS_SIGN_UP = "AUTH_SUCCESS_SIGN_UP";
export const AUTH_FAIL_SIGN_UP = "AUTH_FAIL_SIGN_UP";
// COUNTRY ACTION TYPE
export const ADD_UPDATE_COUNTRY_SUCCESS = "ADD_UPDATE_COUNTRY_SUCCESS";
export const ADD_UPDATE_COUNTRY_FAIL = "ADD_UPDATE_COUNTRY_FAIL";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAIL = "GET_COUNTRY_FAIL";
export const GET_ALL_COUNTRY_SUCCESS = "GET__ALL_COUNTRY_SUCCESS";
export const GET_ALL_COUNTRY_FAIL = "GET_ALL_COUNTRY_FAIL";
// STATE ACTIONN TYPE
export const ADD_UPDATE_STATE_SUCCESS = "ADD_UPDATE_STATE_SUCCESS";
export const ADD_UPDATE_STATE_FAIL = "ADD_UPDATE_STATE_FAIL";
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_FAIL = "GET_COUNTRY_FAIL";

/// CIYT ACTION TYPE
export const ADD_UPDATE_CITY_SUCCESS = "ADD_UPDATE_CITY_SUCCESS";
export const ADD_UPDATE_CITY_FAIL = "ADD_UPDATE_CITY_FAIL";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_FAIL = "GET_CITY_FAIL";
export const GET_STATE_BY_COUNTRY_FAIL = " GET_STATE_BY_COUNTRY_FAIL ";
export const GET_STATE_BY_COUNTRY_SUCCESS = " GET_STATE_BY_COUNTRY_SUCCESS ";

/// language action type
export const ADD_UPDATE_LANGUAGE_SUCCESS = "ADD_UPDATE_LANGUAGE_SUCCESS";
export const ADD_UPDATE_LANGUAGE_FAIL = "ADD_UPDATE_LANGUAGE_FAIL";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_FAIL = "GET_LANGUAGE_FAIL";

// module action

export const ADD_UPDATE_MODULE_SUCCESS = "ADD_UPDATE_MODULE_SUCCESS";
export const ADD_UPDATE_MODULE_FAIL = "ADD_UPDATE_MODULE_FAIL";
export const DELETE_MODULE_SUCCESS = "DELETE_MODULE_SUCCESS";
export const GET_MODULE_SUCCESS = " GET_MODULE_SUCCESS";
export const GET_MODULE_FAIL = "GET_MODULE_FAIL";
export const GET_PARENT_MODULE_SUCCESS = "GET_PARENT_MODULE_SUCCESS";
export const GET_PARENT_MODULE_FAIL = "GET_PARENT_MODULE_FAIL";

// ACTION BLANK SAMADHAN ID
export const ADD_BLANK_SMD_SUCCESS = "ADD_BLANK_SMD_SUCCESS";
export const ADD_BLANK_SMD_FAIL = "ADD_BLANK_SMD_FAIL";
export const GET_BLANK_SMD_SUCCESS = "GET_BLANK_SMD_SUCCESS";
export const GET_BLANK_SMD_FAIL = "GET_BLANK_SMD_FAIL";

export const GET_SMD_VIEW_ALL_SUCCESS = " GET_SMD_VIEW_ALL_SUCCESS";
export const GET_SMD_VIEW_FAIL = "GET_SMD_VIEW_FAIL";

// ACITON  ADD ORG
export const ADD_ORG_SUCCESS = "ADD_ORG_SUCCESS";
export const ADD_ORG_FAIL = "ADD_ORG_FAIL";
export const GET_ORG_SUCCESS = "GET_ORG_SUCCESS";
export const GET_ORG_FAIL = "GET_ORG_FAIL";

export const GET_ALL_STATE_SUCCESS = "GET_ALL_STATE_SUCCESS";
export const GET_ALL_STATE_FAIL = "GET_ALL_STATE_SUCCESS";
export const GET_ALL_MODULE_SUCCESS = "GET_ALL_MODULE_SUCCESS";
export const GET_ALL_MODULE_FAIL = "GET_ALL_MODULE_FAIL";

// ACTION ORGANISAION SAMADHAN ID
export const ADD_ORG_SMD_SUCCESS = "ADD_ORG_SMD_SUCCESS";
export const ADD_ORG_SMD_FAIL = "ADD_ORG_SMD_FAIL";
export const GET_ORG_SMD_SUCCESS = "GET_ORG_SMD_SUCCESS";
export const UPDATE_ORG = "UPDATE_ORG";
export const GET_All_ORG_SUCCESS = "GET_All_ORG_SUCCESS";

// CATEGORY ACTION TYPE

export const ADD_UPDATE_CATEGORY_SUCCESS = "ADD_UPDATE_CATEGORY_SUCCESS";
export const ADD_UPDATE_CATEGORY_FAIL = "ADD_UPDATE_CATEGORY_FAIL";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";
export const GET_ALL_CAT_SUCCESS = "GET_ALL_CAT_SUCCESS";

// DISTRICT ACTION TYPE

export const ADD_UPDATE_DISTRICT_SUCCESS = "ADD_UPDATE_DISTRICT_SUCCESS";
export const ADD_UPDATE_DISTRICT_FAIL = "ADD_UPDATE_DISTRICT_FAIL";
export const DELETE_DISTRICT_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const GET_DISTRICT_SUCCESS = "GET_DISTRICT_SUCCESS";
export const GET_DISTRICT_FAIL = " GET_DISTRICT_FAIL";
export const GET_ALL_DISTRICT_SUCCESS = "GET_ALL_DISTRICT_SUCCESS";
export const GET_DISTRICT_BY_STATE_SUCCESS = "GET_DISTRICT_BY_STATE_SUCCESS";

// SUB CATEGORY ACTION TYPE
export const ADD_UPDATE_SUBCAT_SUCCESS = "ADD_UPDATE_SUBCAT_SUCCESS";
export const ADD_UPDATE_SUBCAT_FAIL = "ADD_UPDATE_SUBCAT_FAIL";
export const DELETE_SUBCATE_SUCCESS = "DELETE_SUBCAT_SUCCESS";
export const GET_SUBCAT_SUCCESS = "GET_SUBCATE_SUCCESS";
export const GET_SUBCAT_FAIL = "GET_SUBCATE_FAIL";

// GE USER ALL ORG

export const GET_USER_ORG_SUCCESS = "GET_USER_ORG_SUCCESS";

export const GET_CITY_BY_DIST_SUCCESS = "GET_CITY_BY_DIST_SUCCESS";

// BLOOD GROUP ACTION
export const ADD_UPDATE_BLOOD_SUCCESS = "ADD_UPDATE_BLOOD_SUCCESS";
export const ADD_UPDATE_BLOOD_FAIL = "ADD_UPDATE_BLOOD_FAIL";
export const DELETE_BLOOD_SUCCESS = "DELETE_BLOOD_SUCCESS";
export const GET_BLOOD_SUCCESS = "GET_BLOOD_SUCCESS";
export const GET_BLOOD_FAIL = "GET_BLOOD_FAIL";

export const GET_SUBCAT_BY_CAT_SUCCESS = "GET_SUBCAT_BY_CAT_SUCCESS";

// property action
export const ADD_UPDATE_PROPERTY_SUCCESS = "ADD_UPDATE_PROPERTY_SUCCESS";
export const GET_PROPERTY_PROFILE = `GET_PROPERTY_PROFILE`;
export const ADD_UPDATE_PROPERTY_FAIL = "ADD_UPDATE_PROPERTY_FAIL";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const GET_PROPERTY_SUCCESS = "GET_PROPERTY_SUCCESS";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

// upload property
export const UPLOAD_PROPERTY_SUCCESS = "UPLOAD_PROPERTY_SUCCESS";
export const UPLOAD_PROPERTY_FAIL = "UPLOAD_PROPERTY_FAIL";

// ADD JOB
export const ADD_JOB_SUCCESS = " ADD_JOB_SUCCESS";
export const ADD_JOB_FAIL = " ADD_JOB_FAIL";

export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
// FILE DOWNLAOD
export const FILE_DOWNLOAD_SUCCESS = "FILE_DOWNLOAD_SUCCESS";
export const FILE_DOWNLOAD_FAIL = "FILE_DOWNLOAD_FAIL";

// USERS
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const INVITE_USERS_SUCCESS = "INVITE_USERS_SUCCESS";
export const INVITE_USERS_FAIL = "INVITE_USERS_FAIL";

// get org user list // just USER and USERS differernt in
export const GET_ORG_USER_SUCCESS = "GET_ORG_USER_SUCCESS";

/// get org users list
export const GET_ORG_USERS_SUCCESS = "GET_ORG_USERS_SUCCESS";

export const GET_INIT_CARD_SUCCESS = "GET_INIT_CARD_SUCCESS";

//
export const UPDATE_SIGNUP_FLAG = "UPDATE_SIGNUP_FLAG";

// ADD TEAM
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS";
export const ADD_TEAM_FAIL = "ADD_TEAM_FAIL";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";

// FILE DOWNLAOD CATEGORY AND SUBCATEORY

export const DOWNLOAD_CAT_SUCCESS = "DOWNLOAD_CAT_SUCCESS";
export const DOWNLOAD_SUB_SUCCESS = "DOWNLOAD_SUB_SUCCESS";

// DOWNLAOD PROPERTY SUCCESS
export const DOWNLOAD_PROPERYT_SUCCESS = "DOWNLOAD_PROPERYT_SUCCESS";

// Pacakge Action Type
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS";
export const ADD_PACKAGE_FAIL = "ADD_PACKAGE_FAIL";
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS";
export const GET_ALL_PACKAGE_SUCCESS = "GET_ALL_PACKAGE_SUCCESS";

// Inventory Action type
export const ADD_INVENTORY_SUCCESS = "ADD_INVENTORY_SUCCESS";
export const ADD_INVENTORY_FAIL = "ADD_INVENTORY_FAIL";
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS";
// get org module success
export const GET_ORG_MODULE_SUCCESS = "GET_ORG_MODULE_SUCCESS";

// verfiy document fail and success
export const VERIFY_DOCUMENT_SUCCESS = "VERIFY_DOCUMENT_SUCCESS";
export const VERIFY_DOCUMENT_FAIL = "VERIFY_DOCUMENT_FAIL";

export const DOWNLOAD_BLANK_SMD_SUCCESS = "DOWNLOAD_BLANK_SMD_SUCCESS";

export const DOWNLOAD_ORG_SMD_SUCCESS = "DOWNLOAD_ORG_SMD_SUCCESS";

// INVIATE ORG USER
export const INVITE_ORG_USERS_SUCCESS = "INVITE_ORG_USERS_SUCCESS";
export const INVITE_ORG_USERS_FAIL = "INVITE_ORG_USERS_FAIL";

//Get Org People Profile
export const GET_PEOPLE_PROFILE_SUCCESS = "GET_PEOPLE_PROFILE_SUCCESS";
/// update user
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
// get audio job
export const GET_AUDIO_JOB_SUCCESS = "GET_AUDIO_JOB_SUCCESS";
// add role action type
export const ADD_ORG_ROLE_SUCCESS = "ADD_ORG_ROLE_SUCCESS";
export const ADD_ORG_ROLE_FAIL = "ADD_ORG_ROLE_FAIL";

// UPDATE PROPERTY STATUS
export const UPDATE_PROPERTY_STATUS_FAIL = "UPDATE_PROPERTY_STATUS_FAIL";
export const UPDATE_PROPERTY_STATUS_SUCCESS = "UPDATE_PROPERTY_STATUS_SUCCESS";

// GET ALL TEAM ACTION TYEP
export const GET_ALL_ORG_TEAM_SUCCESS = "GET_ALL_ORG_TEAM_SUCCESS";

// GET ALL TEAM ACTION TYEP
export const GET_ALL_ORG_ROLE_SUCCESS = "GET_ALL_ORG_ROLE_SUCCESS";

// Attendace action type
export const GET_ATTENDANCE_SUCCESS = "GET_ATTENDANCE_SUCCESS";

// get access sucess
export const GET_ACCESS_SUCCESS = "GET_ACCESS_SUCCESS";

// GET PENDING PROPERTY SUC

export const GET_PENDING_PROPERTY_SUCCES = "GET_PENDING_PROPERTY_SUCCES";

export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
// ROLE SECTION
export const ADD_UPDATE_ROLE_SUCCESS = "ADD_UPDATE_ROLE_SUCCESS";
export const ADD_UPDATE_ROLE_FAIL = "ADD_UPDATE_ROLE_FAIL";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";

// GET QC CARD  TYPE
export const GET_QC_CARD_SUCCESS = "GET_QC_CARD_SUCCESS";

// GET audit CARD  TYPE
export const GET_AUDIT_CARD_SUCCESS = "GET_AUDIT_CARD_SUCCESS";

// GET INSTALLATION CARD TYPE
export const GET_INSTA_CARD_SUCCESS = "GET_INSTA_CARD_SUCCESS";

// GET VERIFY DOC SUCCESS
export const GET_VERIFY_DOC_SUCCESS = "GET_VERIFY_DOC_SUCCESS";
// get property  by job id
export const GET_PROPERTY_BY_JOB_SUCCESS = "GET_PROPERTY_BY_JOB_SUCCESS";

export const GET_INIT_TABLE_SUCCESS = "GET_INIT_TABLE_SUCCESS";

// get qc table
export const GET_QC_TABLE_SUCCESS = "GET_QC_TABLE_SUCCESS";
export const GET_JOB_DETAILS_SUCCESS = "GET_JOB_DETAILS_SUCCESS";

// get user by id
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";

// get user work hisotory
export const GET_USER_HISOTORY_SUCCESS = "GET_USER_HISOTORY_SUCCESS";

export const GET_PENDING_DOC_SUCCESS = "GET_PENDING_DOC_SUCCESS";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const GET_PROPERTY_VERSION_SUCCESS = "GET_PROPERTY_VERSION_SUCCESS";
export const GET_PROPERTY_PROFILE_SUCCESS = "GET_PROPERTY_PROFILE_SUCCESS";

// get all lng
export const GET_ALL_BLOOD_SUCCESS = "GET_ALL_BLOOD_SUCCESS";
export const GET_ALL_LNG_SUCCESS = "GET_ALL_LNG_SUCCESS";

// UPDATE PROFILE
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

// dWonaldo attendace
export const DOWNLOAD_ATTENDANCE_SUCCESS = "DOWNLOAD_ATTENDANCE_SUCCESS";
export const DOWNLOAD_WORK_HISTORY_SUCCESS = "DOWNLOAD_WORK_HISTORY_SUCCESS";

export const DOWNLOAD_PKG_SUCCESS = "DOWNLOAD_PKG_SUCCESS";

export const DOWNLOAD_INVENTORY_SUCCESS = "DOWNLOAD_INVENTORY_SUCCESS";
export const DOWNLOAD_MASTER_EXCEL_SUCCESS = "DOWNLOAD_MASTER_EXCEL_SUCCESS";

export const DOWNLOAD_STATE_SUCCESS = "DOWNLOAD_STATE_SUCCESS";
export const DOWNLOAD_CITY_SUCCESS = "DOWNLOAD_CITY_SUCCESS";
export const DOWNLOAD_DISTRICT_SUCCESS = "DOWNLOAD_DISTRICT_SUCCESS";
export const DOWNLOAD_LANGUAGE_SUCCESS = "DOWNLOAD_LANGUAGE_SUCCESS";
export const DOWNLOAD_BLOOD_SUCCESS = "DOWNLOAD_BLOOD_SUCCESS";
export const DOWNLOAD_MODULE_SUCCESS = "DOWNLOAD_MODULE_SUCCESS";

export const DOWNLOAD_ROLE_SUCCESS = "DOWNLOAD_ROLE_SUCCESS";

export const DOWNLOAD_ORG_SUCCESS = "DOWNLOAD_ORG_SUCCESS";

export const DOWNLOAD_ORG_DETAILS = "DOWNLOAD_ORG_DETAILS";

//Change User Active
export const CHANGE_USER_ACTIVE = "CHANGE_USER_ACTIVE";
// change role
export const CHANGE_ROLE_SUCCESS = "CHANGE_ROLE_SUCCESS";
export const CHANGE_ROLE_FAIL = "CHANGE_ROLE_FAIL";

// GET QC END DAY REPORT ACTION TYPE
export const GET_INIT_DAY_END_REPORT_SUCCESS =
  "GET_INIT_DAY_END_REPORT_SUCCESS";
export const GET_QC_DAY_END_REPORT_SUCCESS = "GET_QC_DAY_END_REPORT_SUCCESS";

// ACTION TYPE FOR REPORT DOWNLAOD SUCCES
export const REPORT_DOWNLOAD_SUCCESS = "REPORT_DOWNLOAD_SUCCESS,";
export const GET_INSTA_DAY_END_REPORT_SUCCESS =
  "GET_INSTA_DAY_END_REPORT_SUCCESS";

// update controcator name
export const UPDATE_CONTRACTOR_SUCCESS = "UPDATE_CONTRACTOR_SUCCESS";
export const UPDATE_CONTRACTOR_FAIL = "UPDATE_CONTRACTOR_FAIL";

// get grap  action typ
export const GET_GRAPH_DATA_SUCCESS = "GET_GRAPH_DATA_SUCCESS";

export const DOWNLOAD_AUDIT_SUCCESS = "DOWNLOAD_AUDIT_SUCCESS";


//PAYMENTS MODULE
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS"
export const DOWNLOAD_PAYMENTS_SUCCESS = "DOWNLOAD_PAYMENTS_SUCCESS"

//TAXATION MODULE
export const GET_TAXATION_SUCCESS = "GET_TAXATION_SUCCESS"
export const DOWNLOAD_TAXATION_SUCCESS = "DOWNLOAD_TAXATION_SUCCESS"
