import axios from 'axios';
import * as myConstClass from '../../constants/constants';
import {  toast } from 'react-toastify';

import * as actionTypes from './actionTypes';
import { saveAs } from 'file-saver';

const api_url = myConstClass.apiUrl;
const orgId = localStorage.getItem('orgId')

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};



export const addOrgSuccess = (data,type) => {
  

    return {
        type: actionTypes.ADD_ORG_SUCCESS,
        data: data,
        act_type:type
        };
};
export const addOrgFail = (data) => {
    
    return {
        type: actionTypes.ADD_ORG_FAIL,
        data: data,
        };
};

export const getOrgSuccess = (data) => {
   
    return {
        type: actionTypes.GET_ORG_SUCCESS,
        data: data
    };
};


export const fetOrgFail = (error) => {
    
    return {
        type: actionTypes.GET_ORG_FAIL,
        error: error
    };
};

export const getAllOrgSuccess = (data) => {
   
    return {
        type: actionTypes.GET_USER_ORG_SUCCESS,
        data: data
    };
};

export const getAllStateSuccess = (data) => {
   
    return {
        type: actionTypes.GET_ALL_STATE_SUCCESS,
        data: data
    };
};

export const fetchAllStateFail = (error) => {
   
    return {
        type: actionTypes.GET_ALL_STATE_FAIL,
        error: error
    };
};

export const getAllModuleSuccess = (data) => {
   
    return {
        type: actionTypes.GET_ALL_MODULE_SUCCESS,
        data: data
    };
};

export const getAllModuleFail = (error) => {
   
    return {
        type: actionTypes.GET_ALL_MODULE_FAIL,
        error: error
    };
};

export const getPeopleProfileSuccess = (data) => {
    return {
      type: actionTypes.GET_PEOPLE_PROFILE_SUCCESS,
      data: data,
    };
  };

export const getPeopleProfile = (id) => {
    return (dispatch) => {
      axios
        .get(api_url + `user/getUserProfile/${id}`, {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if(response.status===200 && response.data.code === 200)
              dispatch(getPeopleProfileSuccess(response.data.data[0]));
        });
    };
  };
  

// update org 
 export const setOrgData =(data)=>{
    return{
        data:data,
        type:actionTypes.UPDATE_ORG
    }
 }
 export const getUserOrgSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_ORG_SUCCESS,
        data: data
    };
}

// add team action 
export const addTeamSuccess = (data) => {
   
    return {
        type: actionTypes.ADD_TEAM_SUCCESS,
        data: data
    };
}

// add team action 
export const addTeamFail = (data) => {
   
    return {
        type: actionTypes.ADD_TEAM_FAIL,
        data: data
    };
}


export const getTeamSuccess = (data) => {
   
    return {
        type: actionTypes.GET_TEAM_SUCCESS,
        data: data
    };
}


// UPDATE CONTRACTOR NAME SUCCESS
export const updateContractorSuccess = (data) => {
     
    return {
        type: actionTypes.UPDATE_CONTRACTOR_SUCCESS,
        data: data
    };
};

export const updateContractorFail = (data) => {
 
    return {
        type: actionTypes.UPDATE_CONTRACTOR_FAIL,
       
    };

}
/// add org action
export const addOrg = (value) => {
    
    return dispatch => {
        dispatch(authStart());
        const data = {
            name: value.name,
            prefix: value.prefix,
            subDomain: value.subDomain,
            stateId: value.stateId,
            postalCodes: value.postalCode,
            cityId:value.cityId,
            countryId:value.countryId,
            districtId:value.districtId,
            email: value.email,
            module:value.module,}
            axios.post(api_url+'organisation/add', data, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                
          toast.success(response.data.message)   
           dispatch(addOrgSuccess(response.data.message,'add'));
           //dispatch(getO(1, 10, " "));


            })
            .catch(err => {
               
                toast.error(err.response.data.message)   ;
                dispatch(addOrgFail({
                    error: err.response,
                }));
            });
    };
};

// update org
export const updateOrg = (value) => {
    
    return dispatch => {
        dispatch(authStart());
        const data = {
            name: value.name,
            organisationId:value.id,
            prefix: value.prefix,
            subDomain: value.subDomain,
            stateId: value.stateId,
            postalCodes: value.postalCode,
            cityId:value.cityId,
            countryId:value.countryId,
            districtId:value.districtId,
            // email: value.email,
            module:value.module,
           
        }
            axios.post(api_url+'organisation/update', data, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                
          toast.success(response.data.message)   
           dispatch(addOrgSuccess(response.data.message,'add'));
           //dispatch(getO(1, 10, " "));


            })
            .catch(err => {
                
                toast.error(err.response.data.message)   ;
                dispatch(addOrgFail({
                    error: err.response,
                }));
            });
    };
};
//delete org
export const deleteOrg = (value) => {
    
    return dispatch => {
        dispatch(authStart());
        const data = {
       organisationId:value.id,
           }
            axios.post(api_url+'organisation/delete', data, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                
          toast.success(response.data.message,)   
           dispatch(addOrgSuccess(response.data.message,'delete' ));
           dispatch(getOrgList(1, 10, " "));


            })
            .catch(err => {
                
                toast.error(err.response.data.message)   ;
                dispatch(addOrgFail({
                    error: err.response,
                }));
            });
    };
};


// get org list

export const getOrgList = (page, limit, search, status) => {
    
    return dispatch => {
        
        dispatch(authStart());
         axios.get(api_url+`organisation/master_getall/${page}/${limit}?name=${search}&status=${status==='none'?'':(status)}`, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                  
           dispatch(getOrgSuccess(response.data));
            })
            .catch(err => {
                
                dispatch(fetOrgFail({
                    error: err.response
                }));
            });
    };
};
// get alll state

export const getAllState = (page, limit, search, ) => {
    
    return dispatch => {
        
      ///  dispatch(authStart());
         axios.get(api_url+`state/user_getall`, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                  
           dispatch(getAllStateSuccess(response.data));
            })
            .catch(err => {
                
                dispatch(fetchAllStateFail({
                    error: err.response
                }));
            });
    };
};

// GET all module
export const getAllModule = (type) => {
    let url = type==='add_org'?`module/user_getall`:`role/role_admin_all_module`
    return dispatch => {
        
      ///  dispatch(authStart());
         axios.get(api_url+url, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                  
           dispatch(getAllModuleSuccess(response.data));
            })
            .catch(err => {
                
                dispatch(getAllModuleFail({
                    error: err.response
                }));
            });
    };
};
//  UPDATE ORG DATA
export const  updateOrgData =(data)=>{
   return dispatch =>{
       dispatch(setOrgData(data))
   }
}

// get user by id
export const getUserByIdSuccess =(data)=>{
    return{
        type:actionTypes.GET_USER_BY_ID_SUCCESS,
        data:data,
    }
}

// GET ALL USER ORGANIZAITON LIST
export const  getUserOrgList=()=>{
   return dispatch=>{
    // dispatch(authStart());
    axios.get(api_url+`user/user-orglist/${1}/${10}`, {
           headers: {
          'content-type': 'application/json',
          'Accept':"Application/json",
          "Authorization":`${localStorage.getItem('token')}`
           }
       })
       .then(response => {
         response.data.data.docs.forEach(element => {
             localStorage.setItem(element.orgName,element.image)
         })
      dispatch(getUserOrgSuccess(response.data));
       })
       .catch(err => {
           if(err.response.data.message==="token expired"){
               alert(err.response.data.message + " login again.")
                localStorage.removeItem('token')
                window.location.href = "/"
           }
           let data={
               data:{docs:[], page:1,
                   limit:10,
                   total:0},
              
           }
           dispatch(getUserOrgSuccess(data));;
       });
   }
}

/// add team

// update org
export const addTeam = (value) => {
    
    const orgId =localStorage.getItem('orgId');
    let url = ""
    if(orgId){
        url =value.isAdd?`team/organisation-add`:(value.isUpdate?`team/update`:`team/delete`) ;

    }
    else{
        url =value.isAdd?`team/admin-add`:(value.isUpdate?`team/update`:`team/delete`) ;
    }
    var data={}
    if (value.isAdd || value.isUpdate) {
        data.name = value.teamname;
        data.description = value.description;
       data.type = value.type

    }
 if(value.isUpdate||value.isDelete){
    data.teamId=value.id
    }
  
    if(orgId){
        data.organisationId=value.orgId
    }
    
    return dispatch => {
        dispatch(authStart());
       
            axios.post(api_url+url, data, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                
          toast.success(response.data.message)   
           dispatch(addTeamSuccess(response.data.message,'add'));
           dispatch(getTeam(1, 10, " "));


            })
            .catch(err => {
                
                toast.error(err.response.data.message)   ;
                dispatch(addTeamFail({
                    error: err.response,
                }));
            });
    };
};

// get team list
export const  getTeam=(page, limit, search)=>{
    
    const orgId=  localStorage.getItem('orgId')
    if(orgId){
    var url  = `team/organisation-list/${page}/${limit}?organisationId=${orgId}&name=${search}`
    }
    else{
         url = `team/admin-list/${page}/${limit}?name=${search}`
    }
    return dispatch=>{
     dispatch(authStart());
     axios.get(api_url+url, {
            headers: {
           'content-type': 'application/json',
           'Accept':"Application/json",
           "Authorization":`${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            
       dispatch(getTeamSuccess(response.data));
        })
        .catch(err => {
            
            let data={
                data:{docs:[], page:1,
                    limit:10,
                    total:0},
               
            }
            dispatch(getTeamSuccess(data));;
        });
    }
 }

// GET all module
export const getOrgModuleList = (orgId) => {
    
    let url =`organisation/org-enabledModuleList?organisationId=${orgId}`
    return dispatch => {
        
      ///  dispatch(authStart());
         axios.get(api_url+url, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                  
           dispatch(getOrgModuleSuccess(response.data));
            })
            .catch(err => {
                
                let data={
                    data:{module:[], },
                   
                }
                dispatch(getOrgModuleSuccess(data));
            });
    };
};

// get org moduel success
export const getOrgModuleSuccess= (data)=>{
    return{
        type:actionTypes.GET_ORG_MODULE_SUCCESS,
        data:data,
    }

}


// invite users 
export const inviteOrgUser = (value, tempValue) => {
    
    let url ="organisation/invite-user" ;
     var data={
        orgId:localStorage.getItem('orgId'),
        value:tempValue,
        // module:value.selectedModule
        orgRoleId:value.role,
        teamId:value.selectedTeams


     }
   
      return dispatch => {
        //   dispatch(authStart());
          
              axios.post(api_url+ url, data, {
                  headers: {
                 'content-type': 'application/json',
                 'Accept':"Application/json",
                 "Authorization":`${localStorage.getItem('token')}`
                  }
              })
              .then(response => {
                  
                
            toast.success(response.data.message)   
             dispatch(inviteUserSuccess(response.data.message, ));
            // dispatch(getTeam(1, 10, " "));
            // dispatch(getUserList(1, 10, ""));

            
  
  
              })
              .catch(err => {

                  toast.error(err.response.data.message)   ;
                  dispatch(inviteUserFail(err.response.data.message, ));
              });
      };
  };

  export const inviteUserSuccess = (data) => {
     
    return {
        type: actionTypes.INVITE_ORG_USERS_SUCCESS,
        data: data
    };
};

export const inviteUserFail = (data) => {
 
    return {
        type: actionTypes.INVITE_ORG_USERS_FAIL,
       
    };
};


// ADD ROLE ORGANISATION
export const addRoleOrg = (value) => {

    let url ="organisation/role/add" ;
     var data={
        orgId:localStorage.getItem('orgId'),
        value:value.value,
         module:value.selectedModule,
        type:value.type,
     


     }
   
      return dispatch => {
          dispatch(authStart());
          
              axios.post(api_url+ url, data, {
                  headers: {
                 'content-type': 'application/json',
                 'Accept':"Application/json",
                 "Authorization":`${localStorage.getItem('token')}`
                  }
              })
              .then(response => {
                  
                
            toast.success(response.data.message)   
             dispatch(addOrgRoleSuccess(response.data.message, ));
             dispatch(getTeam(1, 10, " "));
            // dispatch(getUserList(1, 10, ""));

            
  
  
              })
              .catch(err => {

                  toast.error(err.response.data.message)   ;
                  dispatch(addOrgRoleFail(err.response.data.message, ));
              });
      };
  };

// ADD ROLE ACTION TYPE
export const addOrgRoleSuccess = (data) => {
     
    return {
        type: actionTypes.ADD_ORG_ROLE_SUCCESS,
        data: data
    };
};

export const addOrgRoleFail = (data) => {
 
    return {
        type: actionTypes.ADD_ORG_ROLE_FAIL,
       
    };
};
//  TEAM LIS WITHOUT LAZYLAODING 

export const getAllOrgTeamList = () => {
const orgId =localStorage.getItem('orgId')
    let url =`team/organisation-getAllTeam?organisationId=${orgId}`
    return dispatch => {
        
      ///  dispatch(authStart());
         axios.get(api_url+url, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                  
           dispatch(getOrgAllTeamSucess(response.data));
            })
            .catch(err => {
                
                let data={
                    data:[]
                   
                }
                dispatch(getOrgAllTeamSucess(data));
            });
    };
};

// ACTTION TYPE FOR GET ALL  ORG TEAM
export const getOrgAllTeamSucess =(data)=>{
    return{
        type:actionTypes.GET_ALL_ORG_TEAM_SUCCESS,
        data:data,
    }
}


export const getAllOrgRole = () => {
    const orgId =localStorage.getItem('orgId')

    let url =`role/roleByOrgId?organisationId=${orgId} `
    return dispatch => {
        
      ///  dispatch(authStart());
         axios.get(api_url+url, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
              
           dispatch(getOrgAllRoleSucess(response.data));
            })
            .catch(err => {
                
                let data={
                    data:[]
                   
                }
                dispatch(getOrgAllRoleSucess(data));
            });
    };
};

// ACTTION TYPE FOR GET ALL  ORG role
export const getOrgAllRoleSucess =(data)=>{
    return{
        type:actionTypes.GET_ALL_ORG_ROLE_SUCCESS,
        data:data,
    }
}


// GET MOUDLE AN SUBMODULE LIST

export const getAccess=()=>{
    const orgId =localStorage.getItem('orgId')
    return dispatch => {
        
        ///  dispatch(authStart());
           axios.get(api_url+`user/moduleList-basedOn-RoleId?organisationId=${orgId}`, {
                  headers: {
                 'content-type': 'application/json',
                 'Accept':"Application/json",
                 "Authorization":`${localStorage.getItem('token')}`
                  }
              })
              .then(response => {
                  
            localStorage.setItem('smdRole',response.data.data.role)
             dispatch(getAccessSuccss(response.data));
              })
              .catch(err => {
                  
                  let data={
                      data:[]
                     
                  }
                  dispatch(getAccessSuccss(data));
              });
      };

}

export const getAccessSuccss =(data)=>{
    return{
        type:actionTypes.GET_ACCESS_SUCCESS,
        data:data,
    }
}



// ROLE SECTION
/// add update delete category
export const addUpdateDeleteRole = (value) => {
    
     const orgid = localStorage.getItem('orgId')
    let url = ""
     if(orgid){
        url =value.isAdd?`role/AddRole-organisation`:(value.isUpdate?`role/UpdateRole-organisation`:`role/Role-delete`) ;
     }
     else{
        url =value.isAdd?`role/AddRole-admin`:(value.isUpdate?`role/UpdateRole-admin`:`role/Role-delete`) ;
     }
     var data={}
     if(orgid){
        data.organisationId=localStorage.getItem('orgId')
     }
    

     
     if(value.isAdd||value.isUpdate){
       data.role=value.role;
       data.module=value.module
     
       }
       if(value.isUpdate){
          data.roleId=value.id;
        
        
       }
       else{
        data.roleId=value.id;
       }
      return dispatch => {
          dispatch(authStart());
          
              axios.post(api_url+ url, data, {
                  headers: {
                 'content-type': 'application/json',
                 'Accept':"Application/json",
                 "Authorization":`${localStorage.getItem('token')}`
                  }
              })
              .then(response => { 
                   
            toast.success(response.data.message)   
             dispatch(addUpdateRoleSuccess(response.data.message));
              dispatch(getRoleList(1, 10, " "));
  
  
              })
              .catch(err => {
                  
                  console.log(err,'err')
                  toast.error(err.response.data.message);
                  dispatch(addUpdateRoleFail({
                      error: err.response,
                  }));
              });
      };
  };

  // category action 

export const addUpdateRoleSuccess =(data)=>{
    
    return {
     type:actionTypes.ADD_UPDATE_ROLE_SUCCESS,
        data:data,
    }
}


export const addUpdateRoleFail =(data)=>{
    
    return {
        type: actionTypes.ADD_UPDATE_ROLE_FAIL,
        data: data,
        };


    }


    
// GET ROLE LISET

export const getRoleList = (page, limit,  search) => {

    const orgId =localStorage.getItem('orgId')
    const role =localStorage.getItem('smdRole')

    let url = ""
    if(role==='admin'){
    url  =`role/RoleList-admin/${page}/${limit}?role=${search}`
    }
    else{
    url=`role/RoleList-organisation/${page}/${limit}?organisationId=${orgId}&role=${search}`

    }
    

    return dispatch => {
        
        dispatch(authStart());
         axios.get(api_url+url, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                
           dispatch(getRoleSuccess(response.data));
            })
            .catch(err => {
                
                let data={
                    data:{docs:[], page:page,
                        limit:limit,
                        total:0},
                   
                }
                dispatch(getRoleSuccess(data));;
            });
    };
};

export const getRoleSuccess = (data) => {
   
    return {
        type: actionTypes.GET_ROLE_SUCCESS,
        data: data
    };
};

  // verify  user document
  export const verifyDocument = (value) => {
    
    let url ="organisation/approve-doc" ;
     var data={
        userId:value.userId,
        orgId:value.orgId,
        module:value.selectedModule
     }
   
      return dispatch => {
          dispatch(authStart());
          
              axios.post(api_url+ url, data, {
                  headers: {
                 'content-type': 'application/json',
                 'Accept':"Application/json",
                 "Authorization":`${localStorage.getItem('token')}`
                  }
              })
              .then(response => {
                  
                
            toast.success(response.data.message)   
             dispatch(verifyDocumentSuccess(response.data.message, ));
             dispatch(getTeam(1, 10, " "));

            
  
  
              })
              .catch(err => {

                  toast.error(err.response.data.message)   ;
                  dispatch(verifyDocumentFail(err.response.data.message, ));
              });
      };
  };

  // verify document success fail
  export  const verifyDocumentSuccess =(data)=>{
      return{
          type:actionTypes.VERIFY_DOCUMENT_SUCCESS,

      }
  }

  export  const verifyDocumentFail =(data)=>{
    return{
        type:actionTypes.VERIFY_DOCUMENT_FAIL,
        
    }
}


export const getVerifyDocList = (page, limit, orgId, search, ) => {
      
    return dispatch => {
        
        dispatch(authStart());
         axios.get(api_url+`organisation/user-DocVerifyList/${page}/${limit}?orgId=${orgId}&search_value=${search}`, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`,
              //  "otp":98988
                }
            })
            .then(response => {
                
           dispatch(getVerifyDocSuuccss(response.data));
            })
            .catch(err => {
                
                let data={
                    data:{docs:[], page:page,
                        limit:limit,
                        totalDocs:0},
                   
                }
                dispatch(getVerifyDocSuuccss(data));;
            });
    };
};

// GET USER PENDING DOC LIST

export const getUserPenddingDoclist = (page, limit, orgId, search, ) => {
      
    return dispatch => {
        
        dispatch(authStart());
         axios.get(api_url+`user/userlist_doc_pending/${page}/${limit}?organisationId=${orgId}&search_value=${search}`, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`,
                }
            })
            .then(response => {
           dispatch(getPendingDocSuccess(response.data));
            })
            .catch(err => {
                
                let data={
                    data:{docs:[], page:page,
                        limit:limit,
                        pending_invitation:0},
                   
                }
                dispatch(getPendingDocSuccess(data));;
            });
    };
};

// get user verified list


export const getMemberList = (page, limit, orgId, search, ) => {
      
    return dispatch => {
        
        dispatch(authStart());
         axios.get(api_url+`user/userlist_doc_approved/${page}/${limit}?organisationId=${orgId}&search_value=${search}`, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`,
                }
            })
            .then(response => {
           dispatch(getMemberSuccess(response.data));
            })
            .catch(err => {
                
                let data={
                    data:{docs:[], page:page,
                        limit:limit,
                        pending_invitation:0},
                   
                }
                dispatch(getMemberSuccess(data));;
            });
    };
};
// GET VERIFY DOC SUCCESS
export const getVerifyDocSuuccss = (data) => {
   
    return {
        type: actionTypes.GET_VERIFY_DOC_SUCCESS,
        data: data
    };
};


// GET pending doc success
export const getPendingDocSuccess = (data) => {
   
    return {
        type: actionTypes.GET_PENDING_DOC_SUCCESS,
        data: data
    };
};


// get memeber list
// GET pending doc success
export const getMemberSuccess = (data) => {
   
    return {
        type: actionTypes.GET_MEMBER_SUCCESS,
        data: data
    };
};

// get user by  id

export const getUserById = (page, limit, orgId, search, ) => {
      const userId = localStorage.getItem('userId')
    return dispatch => {
        
        dispatch(authStart());
         axios.get(api_url+`user/user_getbyId/?userId=${userId}`, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`,
              //  "otp":98988
                }
            })
            .then(response => {
                
           dispatch(getUserByIdSuccess(response.data));
            })
            .catch(err => {
                
                let data={
                    data:{docs:[], page:page,
                        limit:limit,
                        total:0},
                   
                }
                dispatch(getUserByIdSuccess(data));;
            });
    };
};


/// get all language
export const getAllLangList = () => {
    
    return dispatch => {
        
        // dispatch(authStart());
         axios.get(api_url+`language/user_getall`, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                
           dispatch(getAllLngSuccess(response.data));
            })
            .catch(err => {
                
                let data={
                    data:[]
                   
                }
                dispatch(getAllLngSuccess(data));;
            });
    };
};


export const getAllBlood = () => {
    
    return dispatch => {
        
        // dispatch(authStart());
         axios.get(api_url+`bloodGroup/user_getall`, {
                headers: {
               'content-type': 'application/json',
               'Accept':"Application/json",
               "Authorization":`${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                
           dispatch(getAllbldSuccess(response.data));
            })
            .catch(err => {
                
                let data={
                    data:[],
                   
                }
                dispatch(getAllbldSuccess(data));;
            });
    };
};

export const getAllLngSuccess =(data)=>{
    return{
        type:actionTypes.GET_ALL_LNG_SUCCESS,
        data:data,
    }
}

export const getAllbldSuccess =(data)=>{
    return{
        type:actionTypes.GET_ALL_BLOOD_SUCCESS,
        data:data,
    }
}

//  var arry1 =[]/// reold id 
// data.forEach((element,i) => {
//     let module = arry1.find(x=>x.id==arry1.module._id)
//     if(module){
//         data.array[i].mod_enablesaus=true  
//     }
//     else(module){
//         data.array[i].mod_enablesaus=false  
//     }
//     if(arry.module.submdoueId.length>0){
//         element.submoduleId.forEach((el, index)=>{
//             let submodule = arry1.module.submdoueId.find(x=>x==el.id)
//             if(submodule){
//                 data[i].submdoueId[index].mod_enablesaus=true
//             }
//             else{
//                 data[i].submdoueId[index].mod_enablesaus=false
//             }
//         })
//     }
    
// });

export const updateProfile = (data) => {
    
    return dispatch => {
        
        dispatch(authStart());
        var value={
            name: data.firstName,
            lastName: data.lastName,
            username:data.userName,
            email:data.email,
            phone:data.phone,
            address:data.address,
            postal_code:data.postalCode,
            emergency_number:data.emergenyNumber,
            

            // city:data
            
            userId:localStorage.getItem('userId')

        };
        if(data.countryId!=='0'){
            value.countryId=data.countryId
        
        }
        if(data.bloodId!=='0'){
            value.bloodGroupId=data.bloodId
        }
        if(data.districtId!=='0'){
            value.districtId=data.districtId
        }

        if(data.languageId!=='0'){
            value.languageId=data.languageId
        }
        if(data.stateId!=='0'){
            value.stateId=data.stateId
        }
        axios.post(api_url+'user/update', value, {
                headers: {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    "Authorization":`${localStorage.getItem('token')}`

                }
            })
            .then(response => {
                
                localStorage.setItem('status', 'true')
                dispatch(updateProfileSuccess({message:response.data.message}));
                localStorage.setItem('lastname', value.lastName)
                localStorage.setItem('firstname', value.name)

                toast.success(response.data.message)   ;
                // history.push('/')
                
            })
            .catch(err => {
                
                toast.error(err.response.data.message)   ;
                dispatch(updateProfileFail({
                    error: err.response ? err.response.data : null
                }));
            });
    };
};


export const updateProfileSuccess = () => {
    
    return {
        type: actionTypes.UPDATE_PROFILE_SUCCESS,
        
    };
};

export const updateProfileFail = (error) => {
    
    console.log(error);
    return {
        type: actionTypes.UPDATE_PROFILE_FAIL,
      
    };
};


//CHANGE USER ACTIVATION

export const changeUserActive = (memberList,user) => {

    let url = "organisation/org-UserStatus";
    var data = {
        orgId: localStorage.getItem('orgId'),
        userId: user._id,
    }
    return dispatch => {
        // dispatch(authStart());

        axios.post(api_url + url, data, {
            headers: {
                'content-type': 'application/json',
                'Accept': "Application/json",
                "Authorization": `${localStorage.getItem('token')}`
            }
        })
           .then(response => {
            const updatedMembers = []
            memberList.forEach((member)=>{
                if(user===member)
                {
                    const updateMember = {...user, organisation:{...user.organisation, userOrgStatus: !user.organisation.userOrgStatus}}
                    updatedMembers.push(updateMember)
                }
                else
                updatedMembers.push(member)
            })
            toast.success(response.data.message);
            
            //   toast.success(response.data.message)
             dispatch(userStatusSuccess(updatedMembers));
            })
            .catch(err => {
                
                toast.error(err.response.data.message);
            });
     };
};


// CHANGE USER ROLE

export const changeUserRole = (value) => {

    let url = "role/assign-role";
    var data = {
        organisationId: localStorage.getItem('orgId'),
        userId: value.userId,
        roleId:value.selectedRole,
    }

    return dispatch => {
        dispatch(authStart());

        axios.post(api_url + url, data, {
            headers: {
                'content-type': 'application/json',
                'Accept': "Application/json",
                "Authorization": `${localStorage.getItem('token')}`
            }
        })
           .then(response => {
               
               dispatch(getMemberList(1, 10,  localStorage.getItem('orgId') ,""));

              toast.success(response.data.message)
                dispatch(changeRoleSuccess(response.data.message,));
                // dispatch(getUserList(1, 10, ""));
            })
            .catch(err => {

                toast.error(err.response.data.message);
                dispatch(changeRoleFail(err.response.data.message,));
            });
    };
};


export const changeRoleSuccess = () => {
    
    return {
        type: actionTypes.CHANGE_ROLE_SUCCESS,
        
    };
};

export const userStatusSuccess = (userData) =>{
    return {
        type: actionTypes.CHANGE_USER_ACTIVE,
        data: userData
    }
}
export const changeRoleFail = (error) => {
    
    console.log(error);
    return {
        type: actionTypes.CHANGE_ROLE_FAIL,
      
    };
};


export const downloadExcelFile = (type, search)=>{
    
    let url = type='organisation' ? `organisation/download-organisation_list`:
    type==='role' ? `role/download-role_list_admin?role=${search}`:
    type==='org_role' ?`role/download-role_list_organisation?organisationId=${orgId}`:
    type==='team' ?`team/download-team_list_admin?team=${search}`:
    type==='org_team' ?`team/download-team_list_organisation?organisationId=${orgId}`:

    `/dfd`
    return  dispatch=>{
        dispatch(authStart());
  
           axios.get(api_url+url,
           { responseType: 'arraybuffer',
           headers:{
               "Authorization":`${localStorage.getItem('token')}`,
           }
        }
           
            )
             .then((response) => {
                 
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
               //  let filename= type 
                saveAs(blob, `${type}${new Date().toISOString().slice(0,10)}.xlsx`);
                 toast.success("File downloaded successfully")
                 dispatch(downloadOrgFileSuccess(type))
              })      
    .catch(error=>{
        dispatch(downloadOrgFileSuccess(type))  ;
        toast.error('No Record Found') 

    }) 
    }
}

export const downloadOrgFileSuccess =(masterType)=>{
    return{
       
       type: actionTypes.DOWNLOAD_ORG_DETAILS
    }
}



// UPDATE USER CONTRACTOR NAME

export const updateContractorName = (value) => {
    
    let url ="organisation/ordAdmin-edit-user";
     var data={
        organisationId:localStorage.getItem('orgId'),
        contractor_name:value.contractor,
          userId:value.userId

   }
   
      return dispatch => {
              dispatch(authStart());
                     axios.post(api_url+ url, data, {
                  headers: {
                 'content-type': 'application/json',
                 'Accept':"Application/json",
                 "Authorization":`${localStorage.getItem('token')}`
                  }
              })
              .then(response => {
                  
                
            toast.success(response.data.message)   
             dispatch(updateContractorSuccess(response.data.message, ));
            // dispatch(getTeam(1, 10, " "));
            // dispatch(getUserList(1, 10, ""));

            
  
  
              })
              .catch(err => {

                  toast.error(err.response.data.message)   ;
                  dispatch(updateContractorFail(err.response.data.message, ));
              });
      };
  };