

//  export  const apiUrl="https://testapi.samadhan.tech/" //test server shethink ssl

 export const apiUrl = "https://api.samadhan.tech/" //Production Url 8000
//  export const apiUrl = "http://localhost:8000/" //Production Url
                                                            
//  export  const propertyTemplateUrl="http://3.7.206.175:8000/uploads/excel/upload_property-sample.xlsx"// server api url
 export  const propertyTemplateUrl="http://65.0.9.210:8000/uploads/excel/upload_property-sample.xlsx"// server api url

 //http://52.66.176.201/