import React ,{useState,useEffect} from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory,	Link} from "react-router-dom";
import styles from "./ForgotPassword.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
// import unnamed from "./images/unnamed@2x.png";
import Logo from "./images/Logo@2x.jpg";
import CancelIcon from "@material-ui/icons/Cancel";
import * as myConstClass from '../../../constants/constants';
import { useTranslation } from 'react-i18next';

const api_url = myConstClass.apiUrl;
const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
			width: "40ch",
		},
		"& .MuiIcon-colorSecondary": {
			width: "2em",
			height: "2em",
		},
	},
}));

export default function ForgotPassword(props) {
	const classes = useStyles();
	const [username, setUsername]=useState('')
	const [t] = useTranslation('common');

	const histories=useHistory()
	const [loading, setLoading] = useState(false);



	// handle change
	const handleChange =(e)=>{
		setUsername(e.target.value)
	}


	const [entry] = performance.getEntriesByType("navigation");

	if (entry["type"] === "back_forward")
		window.location.reload()
	//To Prevent Going Back
	useEffect(()=>{
		window.history.pushState(null, null, window.location.href);
		window.onpopstate = function(event) {
		  window.history.go(1);
		};
	  
    })
	
	useEffect(()=>{
		localStorage.removeItem('otp')
		localStorage.removeItem('value',)


	},[])
	// send OTP 
	const sendOTP =(e)=>{
		e.preventDefault()
		if(username===''){
			return toast.error(t("forgot_password.error"))
		}
		const otp= (Math.floor(1000 + Math.random() * 9000));

		var data={
			value:username,
			// otp:otp,
		}
		setLoading(true)
		axios.post(api_url+`user/forgot-password`,data,{
			method:"POST",
			headers :{
				'content-type':"Application/json",
				"accept":"Application/json",
				'otp': otp
			},
		
		   })
		   .then(response=>{
			
			localStorage.setItem('otp', otp)
			localStorage.setItem('value', username)

			   toast.success(response.data.message)
			   histories.push('/otp')
			   setLoading(false)
		   })
		   .catch(err=>{
			toast.error(err.response.data.message)
			localStorage.removeItem('otp')

			   setLoading(false)
		   })
		 
		
	}

	
	const backDrop= loading ? (
		<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={loading}>
			<CircularProgress color="inherit" />
		</Backdrop>
	) : null;


	return (
		<div
		
			style={{
				width: "100vw",
				height: "100vh",
				objectFit: "fit",
				overflow: "hidden",
				position: "absolute",
				top: "0",
				left: "0",
			}}
			className={styles.background}
		>
			{backDrop}
			<div className={styles.layer}></div>
			<div className={styles.div1}>
				<Link to="/"><CancelIcon
					style={{
						color: "#F2134F",
						position: "absolute",
						top: "-17",
						right: "-17",
						height: "10%",
						width: "10%",
						cursor: "pointer",
					}}
				/>
				</Link>
				<div className={styles.logoDiv}>
					<img src={Logo} alt="logo" />
				</div>
				<div className={styles.div1text}>{t("forgot_password.forgot_title")}</div>
				<div className={styles.description}>
					<span>{t("forgot_password.forgot_welcome1")}</span>
					<br></br>
				</div>
				<div className={styles.textField}>
					<form
						className={classes.root}
						noValidate
						autoComplete="off"
					>
						<TextField
							id="outlined-basic"
							label={t("forgot_password.email_and_phone")}
							variant="outlined"
							style={{
								font: "SF Pro Text",
							}}
							name="username"
							value={username}
							 onChange={handleChange}
						/>
					</form>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						padding: "3%",
					}}
				>
			   <Button
						variant="contained"
						color="secondary"
						style={{
							width: "43ch",
							textTransform: "none",
							height: "4em",
							font: "Regular SF Pro Text",
							outline: "none",
						}}
						onClick={(e) => {sendOTP(e)}}
					>
						{t("forgot_password.get_otp")}
					</Button>
					
				</div>
				<div style={{ paddingLeft: "14%", paddingTop: "5%" }}>
				{t("login.dont_have_ac")}
					<Link  style={{textDecoration:"none",color: "red"}} to="/signup">
					{t("login.sign_up")}
					</Link>
				</div>
			</div>
		</div>
	);
}