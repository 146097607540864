import axios from "axios";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

import * as actionTypes from "./actionTypes";
import * as myConstClass from "../../constants/constants";

// const orgId = localStorage.getItem("orgId");
const api_url = myConstClass.apiUrl;

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};


export const getPaymentsSuccess = (data) => {
  return {
    type: actionTypes.GET_PAYMENTS_SUCCESS,
    data: data,
  };
};


// downlaod property
export const downloadPaymentsSuccess = (data) => {
  return {
    type: actionTypes.DOWNLOAD_PAYMENTS_SUCCESS,
  };
};



/// FETCH payments list LIST

export const getPayments = (page, limit, orgId, search, status, mode, startPaymentDate, endPaymentDate, collectorName, amountPaid, totalAmount) => {
  return (dispatch) => {
    dispatch(authStart());
    const instance = axios.create({
      baseURL: api_url,
      headers: {
        "content-type": "application/json",
        Accept: "Application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    });
    instance
      .post(`payment/list/${page}/${limit}?`,
        {
          organisationId: orgId,
          ownerName: search,
          collectorName: collectorName,
          //gtLtTotal,
          paymentMode: mode,
          paymentStatus: status,
          // startDate: startPaymentDate,
          // endDate: endPaymentDate,
          amountPaid: amountPaid,
          totalAmount: totalAmount,
          //gtLtPaid
        })
      .then((response) => {

        if(response.status===200 && response.data.status){
        toast.success(response.data.message)
        dispatch(getPaymentsSuccess(response.data.payments));
        }
        else{
          let data = {
            data: { docs: [], page: page, limit: limit, total: 0 },
          };
          dispatch(getPaymentsSuccess(data));
          toast.error(response.data.message)
        }
        // dispatch(getPaymentsSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getPaymentsSuccess(data));
      });
  };
};



export const downloadPayments = (orgId, search, status) => {
  return async (dispatch) => {
    dispatch(authStart());
    try {
      const response = await axios.get(
        api_url +
          `property/download-property1?orgId=${orgId}&search_value=${search}&propertyStatus=${status}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      //  .then((response) => {

      var blob = new Blob([response.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      //  let filename= type
      saveAs(
        blob,
        `${"Property"}${new Date().toISOString().slice(0, 10)}.xlsx`
      );
      toast.success("File downloaded successfully");
      dispatch(downloadPaymentsSuccess());
      //   });
    } catch (error) {
      dispatch(downloadPaymentsSuccess());
      toast.error(error.response.data.message);
    }
  };
};
