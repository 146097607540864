/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import  { useHistory} from "react-router-dom";

import * as $ from 'jquery';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./UploadDoc.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { toast } from 'react-toastify';
import * as acitons  from '../../../../../store/actions/index'
import {compose} from 'redux'
import {connect} from 'react-redux'
import { withTranslation } from "react-i18next";
import axios from 'axios';
import * as myConstClass from '../../../../../constants/constants';

const api_url = myConstClass.apiUrl;
const useStyles = makeStyles((theme) => ({}));

export  function UploadProperty(props) {
	const [state, setState] = React.useState({
		categoryId:"0",
		subcategoryId:"0",
		propertyFile:[],
		fileName:"",
		fileType:"",
	});
	const history =useHistory()

	const [loading, setLoading] =useState(false)


	const classes = useStyles();

	useEffect(()=>{
		
	//	props.getAllCategory()
	},[])
	const handleChange = (event) => {
		
		const name = event.target.name;
		setState({
			...state,
			[name]: event.target.value,
		});
		if(event.target.name==='categoryId'){
			props.getSubCategoryByCategory(event.target.value)
		}
	};

	// handle submit form
	const handleSubmit =(e)=>{
		
		e.preventDefault();
		if(state.propertyFile.length===0){
			return toast.error('Please  select document')
		}
		
		if(state.fileType.trim()===''){
			return toast.error('Please Provide fine name')
		}
		
		else{
			setLoading(true)
			let formData = new FormData ();
			formData.append('file', state.propertyFile);
			formData.append('orgId', localStorage.getItem('orgId'))
			formData.append('title', state.fileType)

			axios.post(api_url+`user/upload-doc-web`,formData,{
					method:"POST",
					headers :{
						// 'content-type':"Application/json",
						"accept":"Application/json",
						"Authorization" :localStorage.getItem("token")
					},
				
				   })
				   .then(res=>{
					 toast.success(res.data.message)
					 setState({...state, fileType:"", propertyFile:[], fileName:""})
					 setLoading(false)

				   })
				   .catch(er=>{
					toast.error(er.response.data.message)
					setLoading(false)
				   })
		}
	}

	// upload audio file
const uploadFile=(e, type, i)=>{
    
    if (e.target.files && e.target.files.length > 0 ) {
        // var fileName = e.target.files[0].name;
       var validExtensions = ['png', 'jpeg', 'jpg','pdf','PNG'];
       
    //    var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
       var isValid = true;
       for(let img of e.target.files){
           
        if($.inArray(img.name.substr(img.name.lastIndexOf('.') + 1), validExtensions) === -1){
            e.target.value = "";
            isValid = false;
            toast.error("Invalid file format, only PNG, JPG, PDF format allowed")
              
        }
         break;
         }
       
    if(isValid){
    
    //   var fileName = e.target.files[0].name;
    //   var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
        let reader = new FileReader();
      setState({...state,
		  propertyFile:(e.target.files[0]),
            fileName:e.target.files[0].name
         
          })

            reader.onloadend = () => {
              
              
            }
          reader.readAsDataURL(e.target.files[0]);
       }
    
    
      }  
      else{
         
          e.preventDefault()
       
          return 
      } 
}

useEffect(()=>{
		
	if(props.success){
	history.push('/property-list')
	}
	})
	    // ENABEL LAODING
		const  backDrop =loading? (
			<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		):null
	return (
		<div className={styles.main}>
			{backDrop}
			<div className={styles.title}>
				<span style={{ fontWeight: "lighter" }}>Upload Document</span>
				<span style={{ color: "#a9a9a9", fontWeight: "lighter" }}>
					
				</span>
			</div>
			<div className={styles.formDiv}>
				<form noValidate autoComplete="off">
					<div className={styles.innerDiv}>
						<label
					 
							variant="contained"
							style={{
								backgroundColor: "#3B86FF",
								color: "white",
								textTransform: "none",
								fontWeight: "lighter",
								outline: "none",
								// width: "30%",
								alignSelf: "center",
								// marginBottom: "10%",
								padding: "9px 29px",
								cursor: "pointer",
								/* padding-left: 57px; */
								boxShadow: " 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
								borderRadius: "3px"
							}}
						>

							<input type="file" onChange={uploadFile} style={{ display: "none" }} />Choose New File
							</label>
						<a style={{
							textAlign: "center",
							color: "#007bff",
							textDecoration: "underline",
							// marginBottom: "10%"	
						}}>{state.fileName}</a>
						<input placeholder="Ex:Aadhar" name="fileType" value={state.fileType} onChange={handleChange} style={{ width: "30%", padding: "6px" }} ></input>
					</div>
				
			
					<div className={styles.buttonDiv}>
						<div className={styles.buttons}>
							<Button
								variant="contained"
								style={{
									backgroundColor: "#F2134F",
									color: "white",
									textTransform: "none",
									fontWeight: "lighter",
									outline: "none",
									// width: "80%",
								}}
								onClick={handleSubmit}
							>
								Submit
						</Button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}


const mapStateToProps =(state)=>{
	return{
	allCategoryList:state.Category.category.allCategoryList,
	subcategoryList:state.Property.subcategoryList,
	success:state.Property.success,
	loading:state.Property.loading,
	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
	 getAllCategory:()=>
	 dispatch(acitons.getAllCategory()),
	 getSubCategoryByCategory:(id)=>
	 dispatch(acitons.getSubCategoryByCategory(id)),
	 uploadBulkProperty :(data)=>
	 dispatch(acitons.uploadProperty(data))
	}
}


export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(UploadProperty)