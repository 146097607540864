/* eslint-disable no-mixed-operators */
import * as actionTypes from "../actions/actionTypes";
import {
    updateObject
} from "../../shared/utility";

const initialState = {
    country: {
        countryList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: true,
        error: null,
        authRedirectPath: "/dashboard",
    },
    state: {
        stateList: [],
        countryList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: true,
        error: null,
        authRedirectPath: "/dashboard",
    },

    city: {
        stateList: [],
        cityList: [],
        districtList: [],
        allCityList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: true,
        error: null,

        authRedirectPath: "/dashboard",
    },
    district: {
        stateList: [],
        districtList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: true,
        error: null,
        authRedirectPath: "/dashboard",
    },
    language: {
        languageList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: true,
        error: null,
        authRedirectPath: "/dashboard",
    },
    module: {
        moduleList: [],
        parentModuleList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: true,
        error: null,
        authRedirectPath: "/dashboard",
    },

    category: {
        categoryList: [],
        allCategoryList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: true,
        error: null,
        authRedirectPath: "/dashboard",
    },
    subcategory: {
        categoryList: [],
        subCategoryList: [],
        propertyList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: false,
        error: null,
        success: false,
        authRedirectPath: "/dashboard",
    },
    blood: {
        bloodGroupList: [],
        page: 1,
        limit: 10,
        total: '',
        message: null,
        loading: true,
        error: null,
        authRedirectPath: "/dashboard",
    },
    loading: false,

};

const authStart = (states, action) => {

    if (states.country !== undefined && states.city !== undefined && states.state !== undefined && (states.module !== undefined) || states.language !== undefined &&
        states.category !== undefined && states.subcategory !== undefined && states.subcategory !== undefined && states.blood !== undefined && states.district !== undefined) {
        states.country.message = null;
        states.city.message = null;
        states.state.message = null;
        states.module.message = null;
        states.language.message = null;
        states.category.message = null;
        states.subcategory.message = null;
        states.district.message = null;
        states.blood.message = null;

        return updateObject(states, {
            states: states,
            loading: true
        });
    }
    else {
        return updateObject(states, {
            states: states,
            loading: true
        });
    }
};

const addUpdateCountrySuccess = (state, action) => {

    let country = state.country;
    country.loading = false;
    country.message = "success"
    country.message = action.data;
    return updateObject(state, {
        country: country,
        error: null,
        loading: true,
    });
};


const addUpdateCountryFail = (state, action) => {
    let country = state.country;
    country.loading = true;
    country.message = state.message;
    return updateObject(state, {
        country: country,
        error: null,
        loading: false,
    });
};

const countryFetchSuccess = (state, action) => {

    var country = state.country;
    country.countryList = action.data.data.docs;
    country.page = action.data.data.page;
    country.limit = action.data.data.limit;
    country.total = action.data.data.total

    return updateObject(state, {
        country: country,
        loading: false,
    });
};
const authFail = (state, action) => {

    return updateObject(state, {
        error: action.error.error.message,
        loading: false,
    });
};

const fetchCountryFail = (state, action) => {

    var country = state.country;
    country.countryList = [];
    return updateObject(state, {
        country: country,
        loading: false,
    });
};

// STATE REDUCER ACTION


const addUpdateStateSuccess = (states, action) => {
    let state = states.state;
    state.loading = true;
    state.message = action.data;
    return updateObject(states, {
        state: state,
        error: null,
        // loading: true,
    });
};

const fetchStateSuccess = (states, action) => {

    var state = states.state;
    state.stateList = action.data.data.docs;
    state.page = action.data.data.page;
    state.limit = action.data.data.limit;
    state.total = action.data.data.total

    return updateObject(states, {
        state: state,
        loading: false,
    });
};


const fetchStateFail = (states, action) => {

    var state = states.state;
    state.stateList = [];
    return updateObject(states, {
        state: state,
        loading: false,
    });
};


const addUpdateStateFail = (state, action) => {
    state.loading = true;
    return updateObject(state, {
        state: state,
        error: null,
        loading: false,
    });
};


const getAllCountrySuccess = (states, action) => {

    var state = states.state;
    state.countryList = action.data.data;
    return updateObject(states, {
        state: state,
        error: null,
        loading: false,
    });
};

const getAllCountryFail = (state, action) => {
    state.countryList = []
    return updateObject(state, {
        state: state,
        error: null,
        loading: false,
    });
};

// CITY REDUCER TYPE
const addUpdateCitySuccess = (state, action) => {
    let city = state.city;
    city.loading = true;
    city.message = action.data;
    return updateObject(state, {
        city: city,
        error: null,
        // loading: false,
    });
};

const fetchcCitySuccess = (state, action) => {

    var city = state.city;
    city.cityList = action.data.data.docs;
    city.page = action.data.data.page;
    city.limit = action.data.data.limit;
    city.total = action.data.data.total

    return updateObject(state, {
        city: city,
        loading: false,
    });
};


const fetchcCityfail = (state, action) => {

    var city = state.city;
    city.cityList = [];
    return updateObject(state, {
        city: city,
        loading: false,
    });
};


const addUpdateCityFail = (state, action) => {

    var city = state.city;
    // city.loading=true;
    city.message = state.message;
    return updateObject(state, {
        city: city,
        error: null,
        loading: false,
    });
};


const fetchStateByCountrySuccess = (state, action) => {

    var district = state.district;
    district.stateList = action.data.data;
    return updateObject(state, {
        district: district,
        error: null,
        // loading: false,
    });
};

const fetchStateByCountryFail = (state, action) => {
    var district = state.district;
    district.stateList = []
    return updateObject(state, {
        district: district,
        error: null,
        // loading: false,
    });
};

// Language reducer type
const addUpdateLanguageSuccess = (state, action) => {

    let language = state.language;
    language.loading = false;
    language.message = "success"
    language.message = action.data;
    return updateObject(state, {
        language: language,
        error: null,
        // loading: false,
    });
};


const addUpdateLanguageFail = (state, action) => {
    let language = state.language;
    language.loading = true;
    language.message = state.message;
    return updateObject(state, {
        language: language,
        error: null,
        loading: false,
    });
};

const languageFetchSuccess = (state, action) => {

    var language = state.language;
    language.languageList = action.data.data.docs;
    language.page = action.data.data.page;
    language.limit = action.data.data.limit;
    language.total = action.data.data.total

    return updateObject(state, {
        language: language,
        loading: false,
    });
};

const fetchLanguageFail = (state, action) => {

    var language = state.language;
    language.languageList = [];
    return updateObject(state, {
        language: language,
        loading: false,
    });
};



// Module reducer type
const addUpdateModuleSuccess = (state, action) => {

    let module = state.module;
    module.loading = false;
    module.message = "success"
    module.message = action.data;
    return updateObject(state, {
        module: module,
        error: null,
        // loading: false,
    });
};


const addUpdateModuleFail = (state, action) => {
    let module = state.module;
    module.loading = true;
    module.message = state.message;
    return updateObject(state, {
        module: module,
        error: null,
        loading: false,
    });
};

const moduleFetchSuccess = (state, action) => {

    var module = state.module;
    module.moduleList = action.data.data.docs;
    module.page = action.data.data.page;
    module.limit = action.data.data.limit;
    module.total = action.data.data.total

    return updateObject(state, {
        module: module,
        loading: false,
    });
};

const fetchModuleFail = (state, action) => {

    var module = state.module;
    module.moduleList = [];
    return updateObject(state, {
        module: module,
        loading: false,
    });
};


const parentModuleFetchSuccess = (state, action) => {

    var module = state.module;
    module.parentModuleList = action.data.data;
    module.loading = false;
    return updateObject(state, {
        module: module,
        loading: false,
    });
};

const fetchParnetModuleFail = (state, action) => {

    var module = state.module;
    module.parentModuleList = [];
    return updateObject(state, {
        module: module,
        loading: false,
    });
};



/// category reducer type

const addUpdateCatSuccess = (state, action) => {

    let category = state.category;
    category.loading = false;
    category.message = "success"
    category.message = action.data;
    return updateObject(state, {
        category: category,
        error: null,
        // loading: false,
    });
};


const addUpdateCatFail = (state, action) => {
    let category = state.category;
    category.loading = true;
    category.message = state.message;
    return updateObject(state, {
        category: category,
        error: null,
        loading: false,
    });
};

const getCatSuccess = (state, action) => {

    var category = state.category;
    category.categoryList = action.data.data.docs;
    category.page = action.data.data.page;
    category.limit = action.data.data.limit;
    category.total = action.data.data.total

    return updateObject(state, {
        category: category,
        loading: false,
    });
};


// sub category reducer type
const addUpdateSubCateSuccess = (state, action) => {

    let message = action.method === 'add' ? null : 'success';
    let success = action.method = 'add' ? true : false
    let subcategory = state.subcategory;
    subcategory.loading = false;
    subcategory.message = message
    subcategory.success = success
    return updateObject(state, {
        subcategory: subcategory,
        error: null,
        //  loading: false,
    });
};


const addUpdateSubCatFail = (state, action) => {

    let success = false
    let subcategory = state.subcategory;
    subcategory.loading = false;
    subcategory.success = success
    return updateObject(state, {
        subcategory: subcategory,
        error: null,
        loading: false,
    });
};

const getSubCatSuccess = (state, action) => {

    var subcategory = state.subcategory;
    subcategory.subCategoryList = action.data.data.docs;
    subcategory.propertyList = []
    subcategory.page = action.data.data.page;
    subcategory.limit = action.data.data.limit;
    subcategory.total = action.data.data.total
    subcategory.success = null
    // subcategory.success=false


    return updateObject(state, {
        subcategory: subcategory,
        loading: false,
    });
};

const getPendingPropertySuccess = (state, action) => {

    var subcategory = state.subcategory;
    subcategory.propertyList = action.data.data.docs;

    subcategory.page = action.data.data.page;
    subcategory.limit = action.data.data.limit;
    subcategory.total = action.data.data.total
    subcategory.success = null
    // subcategory.success=false


    return updateObject(state, {
        subcategory: subcategory,
        loading: false,
    });
};

// district reducer type
const addUpdateDistrictSuccess = (state, action) => {

    let district = state.district;
    district.loading = false;
    district.message = "success"
    district.message = action.data;
    return updateObject(state, {
        district: district,
        error: null,
        // loading: false,
    });
};


const addUpdateDistrictFail = (state, action) => {
    let district = state.district;
    district.loading = true;
    district.message = state.message;
    return updateObject(state, {
        district: district,
        error: null,
        loading: false,
    });
};

const getDistrictSuccess = (state, action) => {

    var district = state.district;
    district.districtList = action.data.data.docs;
    district.page = action.data.data.page;
    district.limit = action.data.data.limit;
    district.total = action.data.data.total

    return updateObject(state, {
        district: district,
        loading: false,
    });
};

// GET DISTRICT BY STATE REDUERCT 

const getDistrictByStateSuccess = (state, action) => {

    var city = state.city;
    city.districtList = action.data.data;
    return updateObject(state, {
        city: city,
        // loading: false,
    });
};


// GET city BY district 

const getCityByDistSuccess = (state, action) => {

    var city = state.city;
    city.allCityList = action.data.data;
    return updateObject(state, {
        city: city,
        // loading: false,
    });
};

// blood reducer type
const addUpdateBloodSuccess = (state, action) => {

    let blood = state.blood;
    blood.loading = false;
    blood.message = "success"
    blood.message = action.data;
    return updateObject(state, {
        blood: blood,
        error: null,
        // loading: false,
    });
};


const addUpdateBloodFail = (state, action) => {
    let blood = state.blood;
    blood.loading = true;
    blood.message = state.message;
    return updateObject(state, {
        blood: blood,
        error: null,
        loading: false,
    });
};

const getBloodSuccess = (state, action) => {

    var blood = state.blood;
    blood.bloodGroupList = action.data.data.docs;
    blood.page = action.data.data.page;
    blood.limit = action.data.data.limit;
    blood.total = action.data.data.total
    return updateObject(state, {
        blood: blood,
        loading: false,
    });
};

const getAllCatSuccess = (state, action) => {

    var category = state.category;
    // subcategory.
    category.allCategoryList = action.data.data;
    return updateObject(state, {
        category: category,
        error: null,
        loading: false,
    });
};
// downlaod cat -sub category success
const downloadCatSuccss = (state, action) => {

    state.loading = false;
    return updateObject(state, {
        error: null,
        loading: false,
        // state:state,
    });
};

const downloadSubSuccss = (state, action) => {


    return updateObject(state, {
        error: null,
        loading: false,
        // state:state,
    });
};

const downloadMasterFileSuccess = (state, action) => {


    return updateObject(state, {
        error: null,
        loading: false,
        // state:state,
    });
};


// COUNTRY reducer
export const Country = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.ADD_UPDATE_COUNTRY_SUCCESS:
            return addUpdateCountrySuccess(state, action);
        case actionTypes.ADD_UPDATE_COUNTRY_FAIL:
            return addUpdateCountryFail(state, action);
        case actionTypes.GET_COUNTRY_SUCCESS:
            return countryFetchSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.GET_COUNTRY_FAIL:
            return fetchCountryFail(state, action);
            
            case actionTypes.DOWNLOAD_MASTER_EXCEL_SUCCESS:
                return downloadMasterFileSuccess(state, action);
        default:
            return state;
    }
};
/// STATE REDUCER
export const State = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.ADD_UPDATE_STATE_SUCCESS:
            return addUpdateStateSuccess(state, action);
        case actionTypes.ADD_UPDATE_STATE_FAIL:
            return addUpdateStateFail(state, action);
        case actionTypes.GET_STATE_SUCCESS:
            return fetchStateSuccess(state, action);
        case actionTypes.GET_ALL_COUNTRY_SUCCESS:
            return getAllCountrySuccess(state, action);
        case actionTypes.GET_ALL_COUNTRY_FAIL:
            return getAllCountryFail(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.GET_COUNTRY_FAIL:
            return fetchStateFail(state, action);

            case actionTypes.DOWNLOAD_STATE_SUCCESS:
                return downloadMasterFileSuccess(state, action);

        case actionTypes.AUTH_LOGOUT:

            return initialState;
        default:
            return state;
    }
};


/// CITY REDUCER

export const City = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);

        case actionTypes.ADD_UPDATE_CITY_SUCCESS:
            return addUpdateCitySuccess(state, action);

        case actionTypes.ADD_UPDATE_CITY_FAIL:
            return addUpdateCityFail(state, action);

        case actionTypes.GET_CITY_BY_DIST_SUCCESS:
            return getCityByDistSuccess(state, action);

        // case actionTypes.GET_STATE_BY_COUNTRY_SUCCESS:
        //     return fetchStateByCountrySuccess(state, action);

        // case actionTypes.GET_STATE_BY_COUNTRY_FAIL:
        //     return fetchStateByCountryFail(state, action);

        case actionTypes.GET_DISTRICT_BY_STATE_SUCCESS:
            return getDistrictByStateSuccess(state, action);

        case actionTypes.AUTH_FAIL:
            return authFail(state, action);

        case actionTypes.GET_CITY_FAIL:
            return fetchcCityfail(state, action);

        case actionTypes.GET_CITY_SUCCESS:
            return fetchcCitySuccess(state, action);

            case actionTypes.DOWNLOAD_CITY_SUCCESS:
                return downloadMasterFileSuccess(state, action);
        default:
            return state;
    }
};

//  language reducer
export const Language = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);

        case actionTypes.ADD_UPDATE_LANGUAGE_SUCCESS:
            return addUpdateLanguageSuccess(state, action);

        case actionTypes.ADD_UPDATE_LANGUAGE_FAIL:
            return addUpdateLanguageFail(state, action);

        case actionTypes.GET_LANGUAGE_SUCCESS:
            return languageFetchSuccess(state, action);

        case actionTypes.GET_LANGUAGE_FAIL:
            return fetchLanguageFail(state, action);

            case actionTypes.DOWNLOAD_LANGUAGE_SUCCESS:
                return downloadMasterFileSuccess(state, action);
        default:
            return state;
    }
};


//  module reducer
export const Module = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);

        case actionTypes.ADD_UPDATE_MODULE_SUCCESS:
            return addUpdateModuleSuccess(state, action);

        case actionTypes.ADD_UPDATE_MODULE_FAIL:
            return addUpdateModuleFail(state, action);

        case actionTypes.GET_MODULE_SUCCESS:
            return moduleFetchSuccess(state, action);

        case actionTypes.GET_MODULE_FAIL:
            return fetchModuleFail(state, action);

        case actionTypes.GET_PARENT_MODULE_SUCCESS:
            return parentModuleFetchSuccess(state, action);

        case actionTypes.GET_PARENT_MODULE_FAIL:
            return fetchParnetModuleFail(state, action);

                case actionTypes.DOWNLOAD_MODULE_SUCCESS:
                    return downloadMasterFileSuccess(state, action);
        default:
            return state;
    }
};


// category reducer
export const Category = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);

        case actionTypes.ADD_UPDATE_CATEGORY_SUCCESS:
            return addUpdateCatSuccess(state, action);

        case actionTypes.ADD_UPDATE_CATEGORY_FAIL:
            return addUpdateCatFail(state, action);

        case actionTypes.GET_CATEGORY_SUCCESS:
            return getCatSuccess(state, action);

        case actionTypes.DOWNLOAD_CAT_SUCCESS:
            return downloadCatSuccss(state, action);

        case actionTypes.GET_ALL_CAT_SUCCESS:
            return getAllCatSuccess(state, action);
        default:
            return state;
    }
};

export const SubCategory = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);

        case actionTypes.ADD_UPDATE_SUBCAT_SUCCESS:
            return addUpdateSubCateSuccess(state, action);

        case actionTypes.ADD_UPDATE_SUBCAT_FAIL:
            return addUpdateSubCatFail(state, action);

        case actionTypes.GET_SUBCAT_SUCCESS:
            return getSubCatSuccess(state, action);

        case actionTypes.DOWNLOAD_SUB_SUCCESS:
            return downloadSubSuccss(state, action);
          case actionTypes.GET_PENDING_PROPERTY_SUCCES:
            return getPendingPropertySuccess(state, action);


        default:
            return state;
    }
};
//


// DISTRICT REDUCER

export const District = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);

        case actionTypes.ADD_UPDATE_DISTRICT_SUCCESS:
            return addUpdateDistrictSuccess(state, action);

        case actionTypes.ADD_UPDATE_DISTRICT_FAIL:
            return addUpdateDistrictFail(state, action);

        case actionTypes.GET_DISTRICT_SUCCESS:
            return getDistrictSuccess(state, action);

        case actionTypes.GET_STATE_BY_COUNTRY_SUCCESS:
            return fetchStateByCountrySuccess(state, action);

        case actionTypes.GET_STATE_BY_COUNTRY_FAIL:
            return fetchStateByCountryFail(state, action);
            case actionTypes.DOWNLOAD_DISTRICT_SUCCESS:
                return downloadMasterFileSuccess(state, action);
        default:
            return state;
    }
};

/// BLOOD GRUOP REDUCER
export const Blood = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);

        case actionTypes.ADD_UPDATE_BLOOD_SUCCESS:
            return addUpdateBloodSuccess(state, action);

        case actionTypes.ADD_UPDATE_BLOOD_FAIL:
            return addUpdateBloodFail(state, action);

        case actionTypes.GET_BLOOD_SUCCESS:
            return getBloodSuccess(state, action);

            case actionTypes.DOWNLOAD_BLOOD_SUCCESS:
                return downloadMasterFileSuccess(state, action);

    

        default:
            return state;
    }
};


// export default reducer;