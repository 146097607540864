/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import * as mui from "../../../../../shared/mui";
import { useTranslation, withTranslation } from "react-i18next";
import styles from "./EndOfDay.module.css";
import * as actions from "../../../../../store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";
import { apiUrl } from "../../../../../constants/constants";
import axios from "axios";
import { toast } from "react-toastify";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = mui.makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .Mui-focused": {
      borderColor: "#F5F6FA",
    },
    "& .MuiTableCell-root": {
      fontSize: "13px",
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    focused: {
      borderColor: "#F5F6FA",
    },
    input: {
      "&::placeholder": {
        color: "#4D4F5C",
      },
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  table: {
    minWidth: 650,
    borderRadius: 0,
    fontSize: "13px",
  },
  select: {
    minWidth: "8.5vw",
    ["@media (min-width: 320px) and (max-width: 375px)"]: {
      minWidth: "25vw",
    },
    ["@media (min-width: 376px) and (max-width: 425px)"]: {
      minWidth: "25vw",
    },
    background: "white",
    color: mui.grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "4px",
    paddingBottom: "4px",
    fontSize: "13px",
    "&:hover": {
      borderColor: mui.grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: mui.blue[200],
    },
  },
  icon: {
    color: mui.grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
}));

export function EndOfDay(props) {
  const borderSelectClasses = mui.useBorderSelectStyles();

  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };
  const classes = useStyles();
  const [t] = useTranslation("common");
  const [state, setState] = useState({
    orgId: "",
    search: "",
    jobType: "",
    status: "0",
    authType: false,
  });
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState("");
  const [tableData, setTableData] = useState([])

  const instance = axios.create({
    baseURL: apiUrl,
    headers: {
      "content-type": "application/json",
      Accept: "Application/json",
      Authorization: `${localStorage.getItem("token")}`,
    },
  });

  const columns = [
    "S.NO",
    "NAME",
    "PHONE",
    "STATUS",
    "RECONCILED AMOUNT",
    "TOTAL AMOUNT",
    "ACTIONS"
  ];
  const iconComponent = (props) => {
    return (
      <mui.ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };


  const payModes = ["Cash", "Online"];

  let icon = <mui.SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  useEffect(() => {
    handleSingleTaxation()

  }, []);


  const handleSingleTaxation = () => {
    const reconcillationApi = apiUrl + `payment/user-reconciliation`
    setLoading(true)
    instance
      .request({
        method: "GET",
        url: reconcillationApi,
        data: {
          organisationId: localStorage.getItem("orgId"),
        },
      })
      .then(function (response) {
        if (response.status === 200 && response.data.code === 200) {
          let data = {
            name: response.data.userReconcilation.senderId.name,
            phone: response.data.userReconcilation.senderId.phone,
            senderId: response.data.userReconcilation.senderId._id,
            status: response.data.userReconcilation.status,
            reconciledAmount: response.data.userReconcilation.reconciledAmount,
            totalAmount: response.data.userReconcilation.totalAmount,
          }
                
          setTableData(tableData.concat([data]))
          tableData.push(data)

          response.data.requestedReconciliation.forEach(element => {
            let data = {
              name: element.senderId.name,
              phone: element.senderId.phone,
              senderId: element.senderId._id,
              status: element.status,
              reconciledAmount: element.reconciledAmount,
              totalAmount: element.totalAmount,
            }
            // tableData.push(data)
            setTableData(tableData.concat([data]))
          });
          // setTableData(tableData)
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        setLoading(false)
      })
    
      .catch(function (error) {
        setLoading(false)
              
        console.log(error, "errror");
      }); //axios-end
  };

  //Change dropdown active
  const toggle = (id) => {
    setActiveDropdownIndex(id);
    setDropdownOpen(!dropdownOpen);
  };

  // handle change
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "status" && e.target.value === "0") {
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  // ON SEACH JOB
  const onSearchJob = (e) => {
    let orgId = localStorage.getItem("orgId");
    props.getJobList(
      1,
      props.limit,
      orgId,
      state.jobType,
      state.search,
      state.status === "0" ? "" : state.status,
      state.authType
    );
  };
  const handleChangePage = (event, page) => {
    props.getJobList(
      page,
      props.limit,
      localStorage.getItem("orgId"),
      state.jobType,
      state.search,
      state.status === "0" ? "" : state.status,
      state.authType
    );
  };
  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <mui.Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };



  const handleAction = (event)=>{
    const name = event.target.name
    const actionApi = "payment/update-reconciliation"
    let status = ""
    if(name==="accept"){
      status = "RECONCILIATED"
      
    }
    else if(name==="reject"){
      status = "REJECTED"
    }

    instance
    .request({
      method: "POST",
      url: actionApi,
      data: {
        status
      },
    })
    .then(function (response) {
      if (response.status === 200 && response.data.code === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    })

    .catch(function (error) {
      console.log(error, "errror");
    }); //axios-end

  }

  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading}>
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <Breadcrumbs seperator="›" aria-label="breadcrumb">
          <Typography color="textPrimary">{t("payment_dash.pay_tax")}</Typography>
          <Link to="/payments">
            <Typography color="textPrimary">{t("payment_dash.payments")}</Typography>
          </Link>
          <Typography color="textSecondary">{t("payment_dash.eod")}</Typography>
        </Breadcrumbs>
      </div>
      {/* Heading BLock END */}

      {/* ---Table Block---*/}
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <mui.TextField
                  id="standard-search"
                  size="small"
                  type="search"
                  name="search"
                  value={state.search}
                  onChange={handleChange}
                  variant="outlined"
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    marginRight: "8px",
                  }}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: t("job_reporting.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
                {/*--------SELECT FOR PAYMENT MODE-------------*/}
                <mui.FormControl>
                  <mui.Select
                    disableUnderline
                    labelId="inputLabel"
                    name="status"
                    placeholder="Status"
                    IconComponent={iconComponent}
                    className={classes.select}
                    MenuProps={menuProps}
                    value={state.status}
                    onChange={handleChange}
                    style={{
                      marginRight: "2%",
                    }}
                  >
                    <mui.MenuItem value={0}>
                      {" "}
                      {t("job_reporting.status")}{" "}
                    </mui.MenuItem>{" "}
                    {payModes.map((mode, index) => (
                      <mui.MenuItem value={index}> {mode} </mui.MenuItem>
                    ))}
                  </mui.Select>
                </mui.FormControl>
                {/* -------SELECT FOR PAYMENT MODE END------- */}
              </div>
            </div>
            <div className={styles.dropDownDiv}>
              <mui.Button
                variant="contained"
                style={{
                  backgroundColor: "#4d4f5c",
                  color: "white",
                  borderRadius: "20px",
                  textTransform: "none",
                  width: "35%",
                  outline: "none",
                }}
                onClick={onSearchJob}
              >
                {t("job_reporting.search_btn")}
              </mui.Button>
            </div>
          </div>

          {/* ALERT BUTTON */}
          <div className={styles.buttonAndFilter}>
            {props.accessList.module !== undefined && (
              <mui.Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  alert("Message Sent");
                }}
                style={{
                  textTransform: "none",
                  textAlign: "center",
                  // width: "60%",
                  alignSelf: "center",
                  marginLeft: "2%",
                  outline: "none",
                }}
              >
                {`Alert`}
              </mui.Button>
            )}
          </div>
          {/* ALERT BUTTON END */}
        </div>

        {/*-----Table-----*/}
        <div className={styles.table}>
          <mui.TableContainer style={{ height: "90%" }} component={mui.Paper}>
            <mui.Table className={classes.table} aria-label="simple table">
              <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <mui.TableRow>
                  {columns.map((field) => (
                    <mui.TableCell
                      style={{
                        textAlign: "center",
                        color: "#A3A6B4",
                        fontSize: "small",
                      }}
                    >
                      {field}
                    </mui.TableCell>
                  ))}
                </mui.TableRow>
              </mui.TableHead>
              <mui.TableBody>
                {tableData.length > 0 &&
                  tableData.map((row, index) => (
                    <mui.TableRow key={index}>
                      <mui.TableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {index + 1}
                      </mui.TableCell>
                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {row.name}
                      </mui.TableCell>

                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {row.phone}
                      </mui.TableCell>

                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {row.status}
                      </mui.TableCell>

                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {row.reconciledAmount}
                      </mui.TableCell>

                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {row.totalAmount}
                      </mui.TableCell>


                      {/* Dropdown column start */}
                      <mui.TableCell
                        align="center"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        <mui.Dropdown
                          isOpen={dropdownOpen && index === activeDropdownIndex}
                          size="sm"
                          toggle={() => toggle(index)}
                          id={index}
                        >
                          <mui.DropdownToggle caret>
                            Dropdown
                          </mui.DropdownToggle>
                          <mui.DropdownMenu>
                            <mui.DropdownItem
                              name="accept"
                              onClick={(event) => {
                                handleAction(event)
                              }}
                            >
                              ACCEPT
                            </mui.DropdownItem>
                            <mui.DropdownItem
                              name="reject"
                              onClick={(event) => {
                                handleAction(event)
                              }}
                            >
                              REJECT
                            </mui.DropdownItem>
                          </mui.DropdownMenu>
                        </mui.Dropdown>
                      </mui.TableCell>
                    </mui.TableRow>
                  ))}
              </mui.TableBody>
            </mui.Table>
            {tableData.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {" "}
                {t("job_reporting.no_record_found")}
              </p>
            )}
          </mui.TableContainer>
        </div>
        {/*----Table End--- */}
      </div>
      {/* ---Table Block End--- */}

      {/* ----Pagination---- */}
      <div className={styles.paginationDiv}>
        {tableData.length > 0 && setPage()}
      </div>
      {/* ----Pagination Block End---- */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allCategoryList: state.Category.category.allCategoryList,
    subcategoryList: state.Property.subcategoryList,
    jobList: state.Operation.jobList,
    loading: state.Operation.loading,
    page: state.Operation.page,
    limit: state.Operation.limit,
    total: state.Operation.total,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobList: (page, limit, orgId, jobType, search, status, type) =>
      dispatch(
        actions.getJobList(page, limit, orgId, jobType, search, status, type)
      ),
    downloadExcelFile: (orgId, jobType, search) =>
      dispatch(actions.downloadExcel(orgId, jobType, search)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(EndOfDay);
