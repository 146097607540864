import React, { useEffect, useState } from "react";
import styles from "./taxation.module.css";

import { Link } from "react-router-dom";
import JobCreationCard from "../../../../components/JobCreationCard/JobCreationCard";

import first from "./investor.png";
import second from "./maintenance.png";
import { useTranslation } from 'react-i18next';

import * as mui from "../../../../shared/mui";
import Card from "../../../../components/Card/Card";
import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl } from './../../../../constants/constants';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const drawerWidth = 252;

const useStyles = mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "content-type": "application/json",
    Accept: "Application/json",
    Authorization: `${localStorage.getItem("token")}`,
  },
});

export default function Payments() {
  const [t] = useTranslation("common");
  const classes = useStyles();
  const [taxDetails, setTaxDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const showTaxData = (e) => {

    let orgId = localStorage.getItem("orgId")
    
    const taxApi = apiUrl + `taxation/dashboard?organisationId=${orgId}`;
      setLoading(true)

    instance.request({
      method: 'GET',
      url: taxApi,
    })
    .then(function (response) {
      if(response.status === 200 && response.data.code === 200) {
         setTaxDetails(response.data);
         toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      setLoading(false)
    })
      .catch(function (error) {
      setLoading(false)
      console.log('error', error);
    })
  }

  useEffect(() => {
    showTaxData();
  }, []);


  // ENABLE LOADING
  const backDrop = loading ? (
    <mui.Backdrop style={{ zIndex: 1204 }} open={loading}>
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;


  return (
    <>
      <div className={styles.main}>
        {backDrop}
        <mui.CssBaseline />
        <main
          className={mui.clsx(classes.content, {
            [classes.contentShift]: true,
          })}
        >
          <div className={styles.title}>
            <Breadcrumbs seperator="›" aria-label="breadcrumb">
              <Typography color="textPrimary">{t("taxation_dash.pay_tax")}</Typography>
            <Typography color="textSecondary">{t("taxation_dash.taxations")}</Typography>
          </Breadcrumbs>
          </div>
          <div className={styles.grid}>
            <Link
              style={{ textDecoration: "none", margin: "2%" }}
              to="/tax_history"
            >
              <JobCreationCard
                img={first}
                color={"#58D8FE"}
                details={t("taxation_dash.hist")}
              />
            </Link>
            <Link
              style={{ textDecoration: "none", margin: "2%" }}
              to="/tax_calculator"
            >
              {" "}
              <JobCreationCard
                img={second}
                color={"#6BE4A7"}
                details={t("taxation_dash.tax_calc")}
              />
            </Link>
          </div>

          <div className={styles.title}>{t("taxation_dash.tax_dash")}</div>
          {taxDetails.taxation && taxDetails.taxation.length > 0 &&
            taxDetails.taxation.map((tax) => (
              <div className={styles.grid}>
                <Card title={t("taxation_dash.total_tax")} number={`₹${tax.totalAmount}`} />
                <Card title={t("taxation_dash.tax_coll")} number={`₹${tax.paidAmount}`} />
                <Card title={t("taxation_dash.tax_pen")} number={`₹${tax.remainingAmount}`} />
              </div>
            ))}
        </main>
      </div>
    </>
  );
}
