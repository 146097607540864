/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {useParams } from 'react-router-dom'
import styles from './SamadhanIDViewAll.module.css'
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import axios from 'axios';
import Pagination from "@material-ui/lab/Pagination";


import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import * as myConstClass from '../../../../constants/constants';

const api_url = myConstClass.apiUrl;
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        "& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
        },
    },
    input: {
		color: "#4D4F5C",
		fontSize: "smaller",
	},
}));

export default function OrganisationSamadhanID() {
    
    const {id, type} = useParams();

    
    const classes = useStyles();
    
    const [state, setState]=useState({
       samadhanList:[],
       page:'',
       limit:10,
       total:"",
       search:"",
       date:"",
       title:'',

    });
    const [loading, setLoading]=useState(false)
    let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

    //
    useEffect(()=>{
        
      
      getSamadhanDetails(1)
       setLoading(true)
    },[])


    const handleChange = (e) => {
		

		const name = e.target.name;
		setState({
			...state,
			[name]: e.target.value,
		});
		
	
    };
    // on search
    const searachSamadhan =(e)=>{
        getSamadhanDetails(1)
    } 
    // GET SAMADHAN DETAILS
   const getSamadhanDetails=(page)=> {
       const orgId = localStorage.getItem('orgId')
    setLoading(true)
    let url = type==='org'?`samadhan_org/detail_list/${page}/${state.limit}?samadhan_org_id=${id}&samadhanId=${state.search}&date=${state.date}&orgId=${orgId}`:
    `samadhanId/detail_list/${page}/${state.limit}?samadhanId=${id}&samadhanCode=${state.search}&date=${state.date}&orgId=${orgId}`
        axios.get(api_url+url,{
            method:"GET",
            headers :{
                'content-type':"Application/json",
                "accept":"Application/json",
                "Authorization": localStorage.getItem('token')
            },
        
           })
           .then(response=>{
             
            setState({
                ...state,
                samadhanList: response.data.data.docs,
                total:response.data.data.total,
                page:page,
            });
            setLoading(false)
           })
           .catch(error=>{
               
               setState({...state, samadhanList:[]})
               setLoading(false)
           })
         
    }


    const handleChangePage =(event, page)=>{
        ;
        getSamadhanDetails(page)
        }
          // ENABEL LAODING
  const  backDrop =loading?(
	<Backdrop style={{zIndex:1204}} className={classes.backdrop} open={loading}>
		<CircularProgress color="inherit" />
	</Backdrop>
):null
          // apply pagination
const setPage = () => {
	
	let total =Math.ceil(state.total / state.limit)
	return(
	<Pagination
    className={classes.root}
	onChange={handleChangePage}
	count={total}
	 shape="rounded"
	 color="primary"
	 variant="outlined"
		  style={{
		 float: "right",
		 marginTop:"2%",
		 }}
	  />
	  )

  };
    return (
        <div className={styles.main}>
            
            {backDrop}
            <div className={styles.title}>
                <span style={{ fontWeight: 'bolder' }}>Samadhan ID /</span>
                <span style={{ fontWeight: 'bolder'}}> {type==='org' ?"Organisation Samadhan ID":"Blank Samadhan ID"} /</span>
                <span style={{ fontWeight: 'lighter', color: 'gray' }}>View All</span>
            </div>
            <div className={styles.tableDiv}>
           
                <div className={styles.searchBarDiv}>
                    <div>
                        <div className={styles.searchBar}>
                            <TextField
									id="standard-search"
									size="small"
									name='search'
									value={state.search}
									type="search"
									variant="outlined"
									onChange={handleChange}
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
										marginRight:"5%",
									}}
									InputProps={{
										startAdornment: icon,
										placeholder: "Search..",
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/>
								<TextField
									id="date"
									variant="outlined"
									name="date"
									type="date"
									size="small"
									placeholder="DD-MM-YYYY"
									onChange ={handleChange}
									max={new Date()}
									defaultValue={new Date()}
									className={classes.textField}
									InputLabelProps={{
										shrink: true,
										placeholder:"DD-MM-YYYY",
									}}
								/>
                        </div>
                    </div>
                    <div className={styles.dropDownDiv}>
                        <Button onClick={(e)=>{searachSamadhan(e)}} variant="contained" style={{ backgroundColor: '#21034B', color: 'white', borderRadius: '20px' }}>
                            Search
                        </Button>
                    </div>
                   
                </div>
                <div className={styles.table}>
                <TableContainer component={Paper}>
				<Table className={classes.table} aria-label="simple table">
				<TableHead style={{ backgroundColor: "#F5F6FA" }}>
					<TableRow>
						<TableCell
							align="left"
							style={{ textAlign: "left", color: "#A3A6B4" }}
						>
							{type==='org'?'ORGANISATION SAMADHAN CARD':"SAMADAHN  CARD"}
						</TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center" style={{ color: "#A3A6B4" }}>
							DATE
						</TableCell>
						<TableCell align="center"></TableCell>
                        <TableCell align="center" style={{ color: "#A3A6B4" }}>
							STATUS
						</TableCell>
                        

					</TableRow>
				</TableHead>
				<TableBody>
					{state.samadhanList.length>0&&state.samadhanList.map((blank) => (
						<TableRow key={blank._id}>
                            <TableCell
							align="left"
							style={{ color: "#4D4F5C" }}
						>
							{type==='org'?(blank.samadhanId):(blank.samadhanCode)}
						</TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"style={{ color: "#4D4F5C" }}>
                        {moment(blank.createdAt).format('DD-MM-YYYY')}
						</TableCell>
						<TableCell align="center"></TableCell>
                        <TableCell align="center" style={{ color: "#4D4F5C" }}>
                        {blank.status?"Active":"In-Active"}
						</TableCell>
							
						</TableRow>
					))}
				</TableBody>
			</Table>
			{state.samadhanList===0&&<p style={{textAlign:'center'}}>No Record Found!</p>}
		</TableContainer>
                </div>
            </div>
            {state.samadhanList.length>0&&setPage()}

        </div>
    )
}
