/* eslint-disable camelcase */
import common_hi from './hi/common.json';
import common_en from './en/common.json';
import common_mr from './mr/common.json';

export default {
  en: {
    common: common_en,
  },
  hi: {
    common: common_hi,
  },
  mr: {
    common: common_mr,
  },
};
