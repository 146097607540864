import React, { useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import * as acitons from "../../../../../store/actions/index";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as mui from "../../../../../shared/mui";
import { toast } from "react-toastify";
import axios from "axios";
import * as myConstClass from "../../../../../constants/constants";
import MyTemplate from "../../../../../shared/Taxation.xlsx";

const api_url = myConstClass.apiUrl;
function TaxUpload() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (files) => {
    if (files.length === 0) {
      return toast.error("Please  select document");
    }

    if (files[0].name.trim() === "") {
      return toast.error("Please Provide fine name");
    } else {
      let formData = new FormData();
      formData.append("excel", files[0]);
      formData.append("organisationId", localStorage.getItem("orgId"));
      // formData.append('title', files[0].name)

      axios
        .post(api_url + `taxation/upload-sheet`, formData, {
          method: "POST",
          headers: {
            "content-type": "multipart/form-data",
            accept: "Application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data.code === 200) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((er) => {
          toast.error(er.response.data.message);
        });
    }
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDownload=()=>{
    const link = document.createElement("a");
    link.href = MyTemplate;
    link.setAttribute("download", "file.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
  // render() {
  return (
    <div>
        <mui.Button
          variant="contained"
          color="default"
          onClick={()=>handleDownload()}
          style={{ margin: "10px" }}
        >
          Download Template
        </mui.Button>

      <mui.Button variant="contained" color="secondary" onClick={handleOpen}>
        Upload Excel Sheet
      </mui.Button>
      <DropzoneDialog
        open={open}
        onSave={(files) => {
          handleSave(files);
          setOpen(false);
        }}
        acceptedFiles={[
          ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        ]}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={handleClose}
      />
    </div>
  );
  // }
}

const mapStateToProps = (state) => {
  return {
    propertyList: state.Property.propertyList,
    success: state.Property.success,
    loading: state.Property.loading,
    page: state.Property.page,
    limit: state.Property.limit,
    total: state.Property.total,
    message: state.Property.message,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProperty: (page, limit, orgId, search, status) =>
      dispatch(acitons.getProperty(page, limit, orgId, search, status)),
    manageProperty: (data) => dispatch(acitons.addUpdateDeleteProperty(data)),
    addUpdateDeleteProperty: (data) =>
      dispatch(acitons.addUpdateDeleteProperty(data)),
    downloadProperty: (orgId, search, status) =>
      dispatch(acitons.downloadProperty(orgId, search, status)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(TaxUpload);
