/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from "react";
import {hasAccess} from '../../../../shared/HasAccess'

import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,

} from "reactstrap";
import styles from "./City.module.css";
import InputLabel from "@material-ui/core/InputLabel";
import GetAppIcon from "@material-ui/icons/GetApp";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import { connect } from "react-redux";
import { compose } from 'redux';
import { withTranslation,useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit from "./edit.png";

import * as actions from "../../../../store/actions/index";



const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
		borderRadius: 0,
	},
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "30ch",
		},
		"& .MuiPagination-root": {
			margin: theme.spacing(3),
			color: "standard",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#3B86FF",
            color: "white",
        },
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
}));


export  function City(props) {
	const classes = useStyles();
	const [t] = useTranslation('common');

	const [state, setState] = React.useState({
		 countryId: "",
		 stateId: "",
		 districtId:"",
		 city:"",
		 postalCode:'',
		 search: "",
		  id: "",
		 btnTitle:"Save",
		 title:"Add City",
		 isAdd:false,
		 isUpdate:false,
		 isDelete:false,

	});
	const [errors , setErros]= useState({})


	/// FETCH STATE LIST HERE
	useEffect(() => {
		
		props.getCityList(1, props.limit, state.search, state.countryId, state.stateId)
		setTimeout(()=>{
		props.getAllCountry()
	// props.getStateByCountry()
			},1000)
	  }, []);


	const handleChange = (e) => {
		
		if(e.target.value!=='0'){
		const name = e.target.name;
		setState({
			...state,
			[name]: e.target.value,
		});
		setErros({})

		if(e.target.name==='countryId'){
	     props.getStateByCountry(e.target.value)
		}
		else if(e.target.name==='stateId'){
			props.getDistrictByState(e.target.value)
		   }
	}
	};
	const [modal, setModal] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(true);

	let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

	// OPEN ADD COUNTRY MODAL
	const toggleModal =(e)=>{
		
	 setModal(!modal)
      setState({...state,countryId:"", stateId:"",btnTitle:(t("city.save")), city:"", postalCode:'', districtId:"",
	  title:(t("city.add_city")), isAdd:true, isDelete:false, isUpdate:false});
	  setErros({})
	  setCurrentStatus(false)

	}

   // EDIT STATE
   const editCity =(data)=>{
	 
	setState({countryId:data.countryId._id, stateId:data.stateId._id, postalCode:data.postalCode, districtId:data.districtId._id, city:data.city, id:data._id,  btnTitle:(t("city.update")), code:data.stateCode,
	title:(t("city.update_city")),isAdd:false, isDelete:false, isUpdate:true});
	setErros({})
	setModal(true)
	props.getStateByCountry(data.countryId._id);
	props.getDistrictByState(data.stateId._id)

   }

   // DELETE COUNTYR
    const deleteCity =(data)=>{
		setState({city:data.city,id:data._id,  btnTitle:(t("city.delete")),
		title:(t("city.delete_city")),isAdd:false, isDelete:true, isUpdate:false});
		setErros({})
		setModal(true)
	}
// VAIDAATE FORM
  const  validateForm =()=>{
	  
	  var valid =true
	if(state.countryId.trim()===''){
		errors.countryId=(t("city.country_error"));  errors.stateId='';  errors.districtId='';   errors.city="";   errors.postalCode="";
		valid=false;
	  }
	  else if(state.stateId.trim()===''){
		 errors.stateId=(t("city.state_error")); errors.countryId=''; errors.city="";  errors.districtId='';   errors.postalCode="";
		 valid=false;
	 }
	 else if(state.districtId.trim()===''){
		errors.districtId=(t("city.district_error")); errors.countryId=''; errors.city="";   errors.postalCode="";
		valid=false;
	}
	 else if(state.city.trim()===''){
		errors.city=(t("city.city_error")); errors.countryId=''; errors.stateId='';  errors.districtId='';  errors.postalCode="";
		valid=false;
	}
	else if(state.postalCode.trim()===''){
		errors.postalCode=(t("city.code_error")); errors.countryId=''; errors.stateId='';  errors.districtId=''; 
		valid=false;
	}
	 else{
		 errors.countryId=''; errors.stateId='';  errors.city='';  errors.districtId='';  errors.postalCode="";
		 valid=true;
	 }
	 setErros(errors => ({ ...errors, }));
	 return valid
  }
  /// handle submit 
  const handleSubmit = (e)=>{
	
	e.preventDefault();
	if(!state.isDelete&&!validateForm()){
	 return;
   }
   else{
	  setCurrentStatus(true)
	   if(state.isAdd){
		
		props.addCity(state)
	   }
	   else if(state.isUpdate){
		props.updateCity(state)
		}
		else{
	    props.deleteCity(state)

		}
	
	
}


  
}
   // dwonload  city
   const downloadExcel = () => {
	props.downlaodExcelFile( 'city',state.search)
}

// ON SEACH state
const onSearchState=(e)=>{
	props.getCityList(props.page, props.limit, state.search, state.countryId, state.stateId,)
}

useEffect(() => {

	
	if (props.message&&currentStatus) {
		setModal(!modal)
		setCurrentStatus(false)
	
		
	} else {
		// if(props.error){
		// 	setpassword_ErMsg(props.error)
		// 	setdisplaytext('showBlock')
		// }
		
	}
});

const handleChangePage =(event, page)=>{
;
props.getCityList(page, props.limit, state.search, state.countryId, state.stateId)
}

// apply pagination
const setPage = () => {
	
	let total =Math.ceil(props.total / props.limit)
	return(
	<Pagination
	className={classes.root}
	onChange={handleChangePage}
	count={total}
	 shape="rounded"
	 color="primary"
	 variant="outlined"
		  style={{
		 marginTop: "2%",
		 float: "right",
		 }}
	  />
	  )

  };

  // ENABEL LAODING
  const  backDrop =props.loading?(
	<Backdrop style={{zIndex:1205}} className={classes.backdrop} open={props.loading}>
		<CircularProgress color="inherit" />
	</Backdrop>
):null


	return (
		
		<div className={styles.main}>
           {backDrop}
			<div className={styles.title}>
				<span style={{ fontWeight: "lighter" }}>{t("module.master")} /</span>
				<span style={{ fontWeight: "lighter", color: "#43425D" }}>
					&nbsp;{t("city.title")} 
				</span>
			</div>
			<div  className={styles.tableDiv}>
				<div className={styles.searchBarDiv}>
					<div className={styles.searchAndDrop}>
						<div>
							<div className={styles.searchBar}>
								<TextField
									id="standard-search"
									size="small"
									name='search'
									value={state.search}
									type="search"
									variant="outlined"
									onChange={handleChange}
									style={{
										borderColor: "#F5F6FA",
										borderRadius: "4px",
										// marginBottom: "5%",
									}}
									InputProps={{
										startAdornment: icon,
										placeholder: (t("city.search")),
										classes: { input: classes.input },
										color: "#4D4F5C",
										focused: classes.focused,
									}}
								/>
							</div>
						</div>
						<div className={styles.dropDownDiv}>
							<Button
								variant="contained"
							
								style={{
									backgroundColor: "#43425D",
									color: "white",
									borderRadius: "17px",
									textTransform: "none",
									width: "45%",
									fontWeight: "lighter",
									// marginBottom: "3%",
									height: "90%",
									outline: "none",
								}}
								onClick={(e)=>{onSearchState(e)}}
							>

								{t("city.search")} 
							</Button>
						</div>
					</div>
					<div className={styles.buttonAndFilter}>
						{/* <FormControl variant="outlined">
							<InputLabel
								htmlFor="outlined-age-native-simple"
								style={{ alignText: "center" }}
							>
								Filter
							</InputLabel>
							<Select
								native
								value={state.age}
								onChange={handleChange}
								style={{
									width: "120%",
									maxHeight: "80%",
									marginBottom: "5%",
								}}
								label="Filter"
								inputProps={{
									name: "Filter",
									id: "outlined-age-native-simple",
								}}
							>
								<option aria-label="None" value="" />
								<option value={10}>Ten</option>
								<option value={20}>Twenty</option>
								<option value={30}>Thirty</option>
							</Select>
						</FormControl> */}
						<Button
						onClick={downloadExcel}
							variant="contained"
							color="secondary"
							style={{
								textTransform: "none",
								backgroundColor: "#3B86FF",
								textAlign: "center",
								whiteSpace: "nowrap",
								outline: "none",
								marginLeft: "56%",
								fontSize: "smaller",
							}}
						>
						{t("job_reporting.download")}
							<GetAppIcon style={{ marginLeft: "20%" }} />
						</Button>
							{props.accessList.module!==undefined&&hasAccess('submoduleId', 'cities_add',props.accessList.module) &&<Button
							variant="contained"
							color="secondary"
							onClick={toggleModal}
							style={{
								textTransform: "none",
								textAlign: "center",
								// width: "45%",
								// height: "95%",
								// marginBottom: "3.2%",
								outline: "none",
								// marginLeft: "50%",
							}}
						>
							{t("city.add_city")} 
						</Button>}
					</div>
				</div>
				<Modal isOpen={modal} toggle={toggleModal}scrollable={true}  centered={true}>
					<ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
					<ModalBody className={styles.modalContainer}>
					{state.isDelete&&<p>{t("city.delete_msg")}  <strong>{state.city}</strong> ? </p>}
						{!state.isDelete&&<form className={classes.root}>
						<FormControl style={{marginLeft:"17px"}} variant="outlined">
								<InputLabel
									htmlFor="outlined-age-native-simple"
									style={{ alignText: "center" }}
								>
									{t("city.country")} 
								</InputLabel>
								<Select
									native
									value={state.countryId}
									onChange={handleChange}
									style={{
										maxHeight: "80%",
										marginBottom: "5%",
										width:"80%"
									}}
									label="Filter"
									inputProps={{
										name: "countryId",
										id: "outlined-age-native-simple",
									}}
								>
									<option aria-label="None" value={'0'} >-{t("city.select_country")} -</option>
									{props.countryList.length>0&&props.countryList.map((country, i)=><option aria-label="None" value={country._id} >{country.country}</option>)}</Select>
							</FormControl>
							{errors.countryId && (
										<small
											className="error"
											style={{ color: "red" }}
										>
											{errors.countryId}
										</small>
									)}
									<FormControl style={{marginLeft:"17px"}} variant="outlined">
								<InputLabel
									htmlFor="outlined-age-native-simple"
									style={{ alignText: "center" }}
								>
									{t("city.state")} 
								</InputLabel>
								<Select
									native
									value={state.stateId}
									onChange={handleChange}
									style={{
										maxHeight: "80%",
										marginBottom: "5%",
										width:"80%"
									}}
									label="Filter"
									inputProps={{
										name: "stateId",
										id: "outlined-age-native-simple",
									}}
								>
									<option aria-label="None" value={'0'} >-{t("city.select_state")} -</option>
									{props.stateList.length>0&&props.stateList.map((state, i)=><option aria-label="None" value={state._id} >{state.state}</option>)}</Select>
							</FormControl>
							{errors.stateId && (
										<small
											className="error"
											style={{ color: "red" }}
										>
											{errors.stateId}
										</small>
									)}
								

								<FormControl style={{marginLeft:"17px"}} variant="outlined">
								<InputLabel
									htmlFor="outlined-age-native-simple"
									style={{ alignText: "center" }}
								>
									{t("city.district")} 
								</InputLabel>
								<Select
									native
									value={state.districtId}
									onChange={handleChange}
									style={{
										maxHeight: "80%",
										marginBottom: "5%",
										width:"80%"
									}}
									label="Filter"
									inputProps={{
										name: "districtId",
										id: "outlined-age-native-simple",
									}}
								>
									<option aria-label="None" value={'0'} >-{t("city.select_district")} -</option>
									{props.districtList.length>0&&props.districtList.map((dist, i)=><option aria-label="None" value={dist._id} >{dist.district}</option>)}</Select>
							</FormControl>
							{errors.districtId && (
										<small
											className="error"
											style={{ color: "red" }}
										>
											{errors.districtId}
										</small>
									)}
								
							<TextField
								className={classes.root}
								variant="outlined"
                                name="city"
								label={t("city.city1")} 
								value={state.city}
								onChange ={handleChange}
								style={{ width: "80%" }}
			
							/>
							{errors.city && (
								<small
									className="error"
									style={{ color: "red" }}
								>
									{errors.city}
								</small>
							)}

                               <TextField
								className={classes.root}
								variant="outlined"
                                name="postalCode"
								label={t("city.postal_code")} 
								value={state.postalCode}
								onChange ={handleChange}
								style={{ width: "80%" }}
			
							/>
							{errors.postalCode && (
								<small
									className="error"
									style={{ color: "red" }}
								>
									{errors.postalCode}
								</small>
							)}

						</form>}
					</ModalBody>
					<ModalFooter>
						<Button
							variant="contained"
							color="primary"
							onClick={toggleModal}
							style={{ marginRight: "2%" }}
						>
							{t("city.cancel")} 
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={(e) => { handleSubmit(e) }}
						>
							{state.btnTitle}
						</Button>
					</ModalFooter>
				</Modal>
				<div className={styles.table}>
				<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead style={{ backgroundColor: "#F5F6FA" }}>
					<TableRow>
						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
							{t("city.country_name")} 
						</TableCell>
						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
							{t("city.state")}  
						</TableCell>
						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
							{t("city.district")}  
						</TableCell>
						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
							{t("city.city")} 
						</TableCell>

						<TableCell
							align="left"
							style={{ textAlign: "justify", color: "#A3A6B4" }}
						>
							{t("city.status")} 
						</TableCell>
						<TableCell align="center"></TableCell>

						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
					
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.cityList.length>0&& props.cityList.map((city) => (
						<TableRow key={city._id}>
							<TableCell
								align="left"
								component="th"
								scope="row"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
							{city.countryId===undefined?"":(city.countryId.country)}
							</TableCell>
							<TableCell
								align="left"
								component="th"
								scope="row"
								style={{
									color: "#4D4F5C",
									fontFamily:
								"Regular 13px/20px Source Sans Pro",
								}}
							>
							{city.stateId.state}
							</TableCell>
							<TableCell
								align="left"
								component="th"
								scope="row"
								style={{
									color: "#4D4F5C",
									fontFamily:
								"Regular 13px/20px Source Sans Pro",
								}}
							>
							{city.districtId===undefined?"":(city.districtId.district)}
							</TableCell>
							<TableCell
								align="left"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
								{city.city}
							</TableCell>
							<TableCell
								align="left"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
								{city.status?"Active":"In-Active"}
							</TableCell>
							<TableCell
								align="left"
								style={{
									color: "#4D4F5C",
									fontFamily:
										"Regular 13px/20px Source Sans Pro",
								}}
							>
								{state.stateCode}
							</TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
					
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
							
							<TableCell align="justify" padding="checkbox">
							{props.accessList.module!==undefined&&hasAccess('submoduleId', 'cities_delete',props.accessList.module) &&<DeleteForeverIcon
								onClick ={()=>{deleteCity(city)}}
									style={{
										padding: "none",
										cursor: "pointer",
										color: "#43425D",
									}}
								/>}
							</TableCell>
							<TableCell align="left" padding="checkbox">
							{props.accessList.module!==undefined&&hasAccess('submoduleId', 'cities_update',props.accessList.module) &&<img
									src={edit}
									alt="Edit"
									style={{
										cursor: "pointer",
									}}
									onClick={()=>editCity(city)}
								/>}
							</TableCell>
				
						</TableRow>
					))}
				</TableBody>
			 
			</Table>
			{props.cityList.length===0&&<p style={{textAlign:'center'}}>{t("city.no_record_found")} </p>}
		</TableContainer>

				</div>

				<div className={classes.root}></div>
			</div>
			{props.cityList.length>0&&setPage()}
			
		</div>
	);
}

City.propTypes = {
	t: PropTypes.func.isRequired,
  };
const mapStateToProps = (state) => {
	
	return {
		loading: state.City.loading,
		error: state.City.city.error,
	   message:state.City.city.message,
	   cityList:state.City.city.cityList,
	   districtList:state.City.city.districtList,
	   stateList:state.District.district.stateList,
	   countryList:state.State.state.countryList,
	   page:state.City.city.page,
	   limit:state.City.city.limit,
	   total:state.City.city.total,
	   accessList: state.Organization.accessList,

	//    isAuthenticated: state.auth.token !== null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addCity: (data) =>
			dispatch(actions.addCity(data)),
	     updateCity: (data) =>
			dispatch(actions.updateCity(data)),
	 deleteCity: (data) =>
			dispatch(actions.deleteCity(data)),	
		getCityList: (page, limit, search, countryId, stateId) =>
			dispatch(actions.getCityList(page, limit, search, countryId,stateId)),
	getAllCountry: () =>
			dispatch(actions.getAllCountry()),

	 getStateByCountry: (countryId) =>
			dispatch(actions.getStateByCountry(countryId)),

			getDistrictByState: (stateId) =>
			dispatch(actions.getDistrictByState(stateId)),

			downlaodExcelFile:  ( type, search) =>
			dispatch(actions.downloadExcelMaster(type, search)),
			
		// onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
	};
};

export default compose(withTranslation('common'), connect(mapStateToProps,  mapDispatchToProps))(City);