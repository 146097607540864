/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as mui from "../../../../shared/mui"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./OrganisationSamadhanID.module.css";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import { hasAccess } from "../../../../shared/HasAccess";


import moment from "moment";

import * as actions from "../../../../store/actions/index";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = mui.makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  select: {
    minWidth: "10.5vw",
    background: "white",
    color: mui.grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    "&:hover": {
      borderColor: mui.grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: mui.blue[200],
    },
  },
  icon: {
    color: mui.grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
  input: {
    color: "#4D4F5C",
    fontSize: "smaller",
  },
}));


export function BlankSamadhanID(props) {
  const borderSelectClasses = mui.useBorderSelectStyles();
  const menuProps = {
    classes: {
      list: borderSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const classes = useStyles();
  const [t] = useTranslation("common");

  const [state, setState] = React.useState({
    cards: "",
    search: "",
    orgId: "0",
    date: "",
    id: "",
    btnTitle: "Save",
    title: "Add",
    isAdd: false,
    isUpdate: false,
    isDelete: false,
  });
  const [errors, setErros] = useState({});

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  /// FETCH STATE LIST HERE
  useEffect(() => {
    props.getOrgSamadhanList(
      1,
      props.limit,
      state.date,
      state.orgId === "0" ? "" : state.orgId
    );
    setTimeout(() => {
      props.getAllOrganisation();
    });
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "0") {
      const name = e.target.name;
      setState({
        ...state,
        [name]: e.target.value,
      });
      setErros({});
    }
  };
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);

  // const toggleModal = () => setModal(!modal);
  const [check, setCheck] = useState(false);
  let icon = <mui.SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  const iconComponent = (props) => {
    return (
      <mui.ExpandMoreIcon
        className={props.className + " " + borderSelectClasses.icon}
      />
    );
  };
  // OPEN ADD COUNTRY MODAL
  const toggleModal = (e) => {
    setModal(!modal);
    setState({
      cards: "",
      btnTitle: t("org_smd.save"),
      title: t("org_smd.add_card"),
      isAdd: true,
      isDelete: false,
      isUpdate: false,
      orgId: "",
      search: "",
      id: "",
    });
    setErros({});
    setCurrentStatus(false);
  };

  // EDIT  CARd
  const editCard = (data) => {
    setState({
      cards: data.number_of_cards,
      id: data._id,
      btnTitle: "Update",
      title: "Update Cards",
      isAdd: false,
      isDelete: false,
      isUpdate: true,
    });
    setErros({});
    setModal(true);
  };

  // DELETE  CARD
  const deleteCard = (data) => {
    setState({
      cards: data.number_of_cards,
      id: data._id,
      btnTitle: "Delete",
      title: "Delete Cards",
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });
    setErros({});
    setModal(true);
  };
  // VAIDAATE FORM
  const validateForm = () => {
    var valid = true;
    if (state.cards.trim() === "") {
      errors.cards = t("org_smd.card_error");
      errors.orgId = "";
      valid = false;
    } else if (state.orgId.trim() === "") {
      errors.orgId = t("org_smd.org_error");
      errors.cards = "";
      valid = false;
    } else {
      errors.cards = "";
      errors.orgId = "";
      valid = true;
    }
    setErros((errors) => ({ ...errors }));
    return valid;
  };
  /// handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.isDelete && !validateForm()) {
      return;
    } else {
      setCurrentStatus(true);
      if (state.isAdd) {
        props.addOrgSamadhan(state);
      }
    }
  };
  // ON SEACH state
  const onSearchSamadhan = (e) => {
    props.getOrgSamadhanList(
      props.page,
      props.limit,
      state.date,
      state.orgId === "0" ? "" : state.orgId
    );
  };

  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    }
  });

  const handleChangePage = (event, page) => {
    props.getOrgSamadhanList(
      page,
      props.limit,
      state.date,
      state.orgId === "0" ? "" : state.orgId
    );
  };

  // dwonload blank samadan
  const downloadExcel = () => {
    props.downloadExcelSmdId("org_smd", state.date);
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <mui.Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="standard"
        variant="outlined"
        style={{
          marginTop: "2%",
          //  marginLeft: "78%",
          float: "right",
        }}
      />
    );
  };

  // ENABEL LAODING
  const backDrop = props.loading ? (
    <mui.Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={props.loading}
    >
      <mui.CircularProgress color="inherit" />
    </mui.Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography color="textPrimary">{t("blank_smd.smd_id")} </Typography>
          <Typography color="textSecondary">{t("org_smd.title")}</Typography>
        </Breadcrumbs>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <mui.TextField
                  id="standard-search"
                  size="small"
                  type="date"
                  name="date"
                  placeholder="DD-MM-YYYY"
                  onChange={handleChange}
                  max={new Date()}
                  variant="outlined"
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    // width: "65%",
                  }}
                  InputProps={{
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
              <mui.Button
                variant="contained"
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  borderRadius: "20px",
                  textTransform: "none",
                  marginLeft: "3%",
                  marginTop: "3px",
                  marginBottom: "3px",
                  width: "109px",
                }}
                onClick={(e) => {
                  onSearchSamadhan(e);
                }}
              >
                {t("org_smd.search_btn")}
              </mui.Button>
            </div>
          </div>
          <div className={styles.buttonDiv}>
            <mui.Button
              onClick={downloadExcel}
              variant="contained"
              color="secondary"
              className={
                props.accessList.module !== undefined &&
                  hasAccess(
                    "submoduleId",
                    "samadhan_org_add",
                    props.accessList.module
                  )
                  ? styles.download
                  : styles.download1
              }
            >
              {t("job_reporting.download")}
              <GetAppIcon style={{ marginLeft: "20%" }} />
            </mui.Button>
            {props.accessList.module !== undefined &&
              hasAccess(
                "submoduleId",
                "samadhan_org_add",
                props.accessList.module
              ) && (
                <mui.Button
                  variant="contained"
                  color="secondary"
                  style={{
                    backgroundColor: "#F2134F",
                    textTransform: "none",
                    width: "25%",
                    marginLeft: "2%",
                  }}
                  onClick={toggleModal}
                >
                  {t("org_smd.add")}
                </mui.Button>
              )}
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            {state.isDelete && (
              <p>
                Are you sure you want to delete cards{" "}
                <strong>{state.cards}</strong> ?{" "}
              </p>
            )}
            {!state.isDelete && (
              <form className={classes.root}>
                <mui.TextField
                  className={classes.root}
                  variant="outlined"
                  name="cards"
                  label={t("org_smd.no_of_cards1")}
                  value={state.cards}
                  onChange={handleChange}
                  helperText={errors.cards}
                  error={errors.cards}
                  style={{ width: "80%" }}
                />

                <mui.FormControl error={errors.orgId} variant="outlined">
                  <mui.InputLabel
                    htmlFor="outlined-age-native-simple"
                    style={{ alignText: "center" }}
                  >
                    {t("org_smd.organisation")}
                  </mui.InputLabel>
                  <mui.Select
                    native
                    value={state.orgId}
                    onChange={handleChange}
                    style={{
                      maxHeight: "80%",
                      marginBottom: "5%",
                      width: "80%",
                    }}
                    label="Filter"
                    inputProps={{
                      name: "orgId",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option aria-label="None" value={"0"}>
                      -{t("org_smd.select_org")}-
                    </option>
                    {props.orgList.length > 0 &&
                      props.orgList.map((org, i) => (
                        <option aria-label="None" value={org._id}>
                          {org.name}
                        </option>
                      ))}
                  </mui.Select>
                </mui.FormControl>
                <mui.FormHelperText style={{ color: "red" }}>
                  {errors.orgId}
                </mui.FormHelperText>
              </form>
            )}
          </ModalBody>
          <ModalFooter>
            <mui.Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              style={{ marginRight: "2%" }}
            >
              {t("org_smd.cancel")}
            </mui.Button>
            <mui.Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </mui.Button>
          </ModalFooter>
        </Modal>
        <div className={styles.table}>
          <mui.TableContainer component={mui.Paper}>
            <mui.Table className={classes.table} aria-label="simple table">
              <mui.TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <mui.TableRow>
                  <mui.TableCell
                    align="left"
                    style={{ textAlign: "left", color: "#A3A6B4" }}
                  >
                    {t("org_smd.no_of_card")}
                  </mui.TableCell>
                  <mui.TableCell
                    align="left"
                    style={{ textAlign: "left", color: "#A3A6B4" }}
                  >
                    {t("org_smd.organisation1")}
                  </mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                  <mui.TableCell align="center" style={{ color: "#A3A6B4" }}>
                    {t("org_smd.date")}
                  </mui.TableCell>
                  <mui.TableCell align="center"></mui.TableCell>
                </mui.TableRow>
              </mui.TableHead>
              <mui.TableBody>
                {props.orgSamadhanList.length > 0 &&
                  props.orgSamadhanList.map((orgSMD) => (
                    <mui.TableRow key={orgSMD._id}>
                      <mui.TableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{ color: "#4D4F5C" }}
                      >
                        {orgSMD.number_of_cards}
                      </mui.TableCell>
                      <mui.TableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{ color: "#4D4F5C" }}
                      >
                        {orgSMD.orgId.name}
                      </mui.TableCell>
                      <mui.TableCell align="center"></mui.TableCell>
                      <mui.TableCell align="center" style={{ color: "#4D4F5C" }}>
                        {moment(orgSMD.createdAt).format("DD-MM-YYYY")}
                      </mui.TableCell>
                      <mui.TableCell align="center">
                        {props.accessList.module !== undefined &&
                          hasAccess(
                            "submoduleId",
                            "samadhan_detail_list",
                            props.accessList.module
                          ) && (
                            <Link
                              to={`/samadhanblank-all/${orgSMD._id}/${"org"}`}
                              title="Click here to View All"
                              style={{
                                textAlign: "center",
                                textDecoration: "none",
                                color: "#007bff",
                                cursor: "pointer",
                                textTransform: "uppercase",
                              }}
                            >
                              {t("org_smd.view_all")}
                            </Link>
                          )}
                      </mui.TableCell>
                    </mui.TableRow>
                  ))}
              </mui.TableBody>
            </mui.Table>
            {props.orgSamadhanList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {t("org_smd.no_record_found")}
              </p>
            )}
          </mui.TableContainer>
        </div>

        <div className={classes.root}></div>
      </div>
      {props.orgSamadhanList.length > 0 && setPage()}

      {/* Internal Styling to override materail-ui css */}
      <style>
        {`

  
        @media only screen and (max-width: 768px) {
          /* For mobile phones: */
          .MuiTableContainer-root {
            max-width: 370px;
        }
        .OrganisationSamadhanID_searchBar__3MrR6 {
          width: 100%;
      }
      .OrganisationSamadhanID_searchBarDiv__3d9JM {
        flex-wrap:wrap;
      }

      .OrganisationSamadhanID_buttonDiv__3E7xM{
        justify-content: space-between;
        padding: 1rem 0.5rem;
        width:100%;
      }

      
      .download{
        margin-left:0px;
        }

        
        `}
      </style>
      {/* ------------------------------------------ */}

    </div>
  );
}

BlankSamadhanID.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    loading: state.OrgSamadhan.loading,
    error: state.OrgSamadhan.orgSamadhan.error,
    message: state.OrgSamadhan.orgSamadhan.message,
    orgSamadhanList: state.OrgSamadhan.orgSamadhan.orgSamadhanList,
    page: state.OrgSamadhan.orgSamadhan.page,
    limit: state.OrgSamadhan.orgSamadhan.limit,
    total: state.OrgSamadhan.orgSamadhan.total,
    orgList: state.OrgSamadhan.orgSamadhan.orgList,
    accessList: state.Organization.accessList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addOrgSamadhan: (data) => dispatch(actions.addOrgSamadhaId(data)),
    getAllOrganisation: () => dispatch(actions.getAllOrganisation()),
    getOrgSamadhanList: (page, limit, date, orgId) =>
      dispatch(actions.getOrgSamadhanist(page, limit, date, orgId)),
    downloadExcelSmdId: (type, date) =>
      dispatch(actions.downloadExcelSmdId(type, date)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(BlankSamadhanID);
