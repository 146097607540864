import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";


const initialState = {
  paymentsList: [],
  page: 1,
  limit: 10,
  total: "",
  loading: false,
};
const authStart = (states, action) => {
  return{
      ...states,
     loading:true
  }
};


const getPaymentsSuccess = (states, action) => {

  return updateObject(states, {
    paymentsList: action.data.docs,
    page: action.data.page,
    limit: action.data.limit,
    total: action.data.pages,
    loading: false,
  });
};



// payments reducer
export const Payments = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);

    case actionTypes.GET_PAYMENTS_SUCCESS:
      return getPaymentsSuccess(state,action)
    
    default:
        return state;
    }
}
export default Payments;