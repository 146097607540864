/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import GetAppIcon from "@material-ui/icons/GetApp";

import styles from "./Country.module.css";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";

import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import edit from "./edit.png";

import * as actions from "../../../../store/actions/index";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "30ch",
    },
    "& .MuiPagination-root": {
      margin: theme.spacing(3),
      color: "standard",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export function Country(props) {
  const classes = useStyles();
  const [t] = useTranslation("common");

  const [state, setState] = React.useState({
    country: "",
    code: "",
    search: "",
    id: "",
    btnTitle: "Save",
    title: "Add Country",
    isAdd: false,
    isUpdate: false,
    isDelete: false,
  });
  /// hass accessbale
  const hasAccess = (type, name) => {
    if (props.accessList.module !== undefined) {
      if (type === "module") {
        let authObj = props.accessList.module.find(
          (x) => x.moduleId.keyName === name
        );
        if (authObj) {
          if (authObj.mod_enableStatus === "true") {
            return true;
          } else {
            return false;
          }
        }
      } else if (type === "submoduleId") {
        let authObj = props.accessList.module.find((x) =>
          x.submoduleId.find((d) => d.keyName === name)
        );
        if (authObj && authObj.mod_enableStatus === "true") {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const [errors, setErros] = useState({});

  /// FETCH COUNTRY LIST HERE
  useEffect(() => {
    props.getCountryList(1, props.limit, state.search);
  }, []);
  //Prevent to eneter alphabets
  const onKeyPress = (event) => {
    const pattern = /[0-9-+]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setErros({});
  };
  const [modal, setModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);

  let icon = <SearchIcon style={{ color: "#BCBCCB", alignSelf: "left" }} />;

  // OPEN ADD COUNTRY MODAL
  const toggleModal = (e) => {
    setModal(!modal);
    setState({
      country: "",
      code: "",
      btnTitle: t("country.save"),
      title: t("country.add_country"),
      isAdd: true,
      isDelete: false,
      isUpdate: false,
    });
    setErros({});
    setCurrentStatus(false);
  };

  // EDIT COUNTRY
  const editCountry = (data) => {
    setState({
      country: data.country,
      code: data.countryCode,
      id: data._id,
      btnTitle: t("country.update"),
      title: t("country.update_country"),
      isAdd: false,
      isDelete: false,
      isUpdate: true,
    });
    setErros({});
    setModal(true);
  };

  // DELETE COUNTYR
  const deleteCountry = (data) => {
    setState({
      country: data.country,
      id: data._id,
      btnTitle: t("country.delete"),
      title: t("country.delete_country"),
      isAdd: false,
      isDelete: true,
      isUpdate: false,
    });
    setErros({});
    setModal(true);
  };
  // VAIDAATE FORM
  const validateForm = () => {
    var valid = true;
    if (state.country.trim() === "") {
      errors.country = t("country.country_error");
      errors.code = "";
      valid = false;
    } else if (state.code.trim() === "") {
      errors.code = t("country.code_error");
      errors.country = "";
      valid = false;
    } else {
      errors.code = "";
      errors.country = "";
      valid = true;
    }
    setErros((errors) => ({ ...errors }));
    return valid;
  };
  /// handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.isDelete && !validateForm()) {
      return;
    } else {
      setCurrentStatus(true);
      if (state.isAdd) {
        props.addCountry(state);
      } else if (state.isUpdate) {
        props.updateCountry(state);
      } else {
        props.deleteCountry(state);
      }
    }
  };
  // ON SEACH COMPANY
  const onSearchCountry = (e) => {
    props.getCountryList(props.page, props.limit, state.search);
  };

  useEffect(() => {
    if (props.message && currentStatus) {
      setModal(!modal);
      setCurrentStatus(false);
    } else {
      // if(props.error){
      // 	setpassword_ErMsg(props.error)
      // 	setdisplaytext('showBlock')
      // }
    }
  });

  const handleChangePage = (event, page) => {
    props.getCountryList(page, props.limit, state.search);
  };

  // apply pagination
  const setPage = () => {
    let total = Math.ceil(props.total / props.limit);
    return (
      <Pagination
        className={classes.root}
        onChange={handleChangePage}
        count={total}
        shape="rounded"
        color="primary"
        variant="outlined"
        style={{
          marginTop: "2%",
          float: "right",
        }}
      />
    );
  };

  // download coountry
  const downloadExcel = () => {
    props.downlaodExcelFile("country", state.search);
  };

  // ENABLE LOADING
  const backDrop = props.loading ? (
    <Backdrop style={{ zIndex: 1204 }} className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;

  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <span style={{ fontWeight: "lighter" }}>{t("module.master")} /</span>
        <span style={{ fontWeight: "lighter", color: "#43425D" }}>
          &nbsp;{t("country.title")}
        </span>
      </div>
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <TextField
                  id="standard-search"
                  size="small"
                  name="search"
                  value={state.search}
                  type="search"
                  variant="outlined"
                  onChange={handleChange}
                  style={{
                    borderColor: "#F5F6FA",
                    borderRadius: "4px",
                    // marginBottom: "5%",
                  }}
                  InputProps={{
                    startAdornment: icon,
                    placeholder: t("country.search"),
                    classes: { input: classes.input },
                    color: "#4D4F5C",
                    focused: classes.focused,
                  }}
                />
              </div>
            </div>
            <div className={styles.dropDownDiv}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#43425D",
                  color: "white",
                  borderRadius: "17px",
                  textTransform: "none",
                  width: "45%",
                  fontWeight: "lighter",
                  // marginBottom: "3%",
                  height: "90%",
                  outline: "none",
                }}
                onClick={(e) => {
                  onSearchCountry(e);
                }}
              >
                {t("country.search")}
              </Button>
            </div>
          </div>
          <div className={styles.buttonAndFilter}>
            <Button
              onClick={downloadExcel}
              variant="contained"
              color="secondary"
              style={{
                textTransform: "none",
                backgroundColor: "#3B86FF",
                textAlign: "center",
                whiteSpace: "nowrap",
                outline: "none",
                marginLeft: "46%",
                fontSize: "smaller",
              }}
            >
              {t("job_reporting.download")}
              <GetAppIcon style={{ marginLeft: "20%" }} />
            </Button>
            {/* <FormControl variant="outlined">
							<InputLabel
								htmlFor="outlined-age-native-simple"
								style={{ alignText: "center" }}
							>
								Filter
							</InputLabel>
							<Select
								native
								value={state.age}
								onChange={handleChange}
								style={{
									width: "120%",
									maxHeight: "80%",
									marginBottom: "5%",
								}}
								label="Filter"
								inputProps={{
									name: "Filter",
									id: "outlined-age-native-simple",
								}}
							>
								<option aria-label="None" value="" />
								<option value={10}>Ten</option>
								<option value={20}>Twenty</option>
								<option value={30}>Thirty</option>
							</Select>
						</FormControl> */}
            {props.accessList.module !== undefined &&
              hasAccess("submoduleId", "countries_add") && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={toggleModal}
                  style={{
                    textTransform: "none",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    outline: "none",
                    // marginLeft: "60%"
                  }}
                >
                  {t("country.add_new_country")}
                </Button>
              )}
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={toggleModal}>{state.title}</ModalHeader>
          <ModalBody className={styles.modalContainer}>
            {state.isDelete && (
              <p>
                {t("country.delete_msg")}
                <strong>{state.country}</strong> ?{" "}
              </p>
            )}
            {!state.isDelete && (
              <form className={classes.root}>
                <TextField
                  className={classes.root}
                  variant="outlined"
                  label={t("country.country_name1")}
                  name="country"
                  style={{ width: "80%" }}
                  value={state.country}
                  onChange={handleChange}
                />
                {errors.country && (
                  <small className="error" style={{ color: "red" }}>
                    {errors.country}
                  </small>
                )}
                <TextField
                  className={classes.root}
                  variant="outlined"
                  name="code"
                  label={t("country.code")}
                  value={state.code}
                  onChange={handleChange}
                  onKeyPress={onKeyPress}
                  style={{ width: "80%" }}
                />
                {errors.code && (
                  <small className="error" style={{ color: "red" }}>
                    {errors.code}
                  </small>
                )}
              </form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              style={{ marginRight: "2%" }}
            >
              {t("country.cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {state.btnTitle}
            </Button>
          </ModalFooter>
        </Modal>
        <div className={styles.table}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("country.country_name")}
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("country.country_code")}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ textAlign: "justify", color: "#A3A6B4" }}
                  >
                    {t("country.status")}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>

                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.countryList.length > 0 &&
                  props.countryList.map((country) => (
                    <TableRow key={country.name}>
                      <TableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {country.country}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {country.countryCode}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          color: "#4D4F5C",
                          fontFamily: "Regular 13px/20px Source Sans Pro",
                        }}
                      >
                        {country.status ? "Active" : "In-Active"}
                      </TableCell>

                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>

                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="justify" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess("submoduleId", "countries_delete") && (
                            <DeleteForeverIcon
                              onClick={() => {
                                deleteCountry(country);
                              }}
                              style={{
                                padding: "none",
                                cursor: "pointer",
                                color: "#43425D",
                              }}
                            />
                          )}
                      </TableCell>
                      <TableCell align="left" padding="checkbox">
                        {props.accessList.module !== undefined &&
                          hasAccess("submoduleId", "countries_update") && (
                            <img
                              src={edit}
                              alt="Edit"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => editCountry(country)}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {props.countryList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {t("country.no_record_found")}
              </p>
            )}
          </TableContainer>
        </div>

        <div className={classes.root}></div>
      </div>
      {props.countryList.length > 0 && setPage()}
    </div>
  );
}

Country.propTypes = {
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    loading: state.Country.loading,
    error: state.Country.country.error,
    message: state.Country.country.message,
    countryList: state.Country.country.countryList,
    page: state.Country.country.page,
    limit: state.Country.country.limit,
    total: state.Country.country.total,
    accessList: state.Organization.accessList,
    //    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCountry: (data) => dispatch(actions.addCountry(data)),
    updateCountry: (data) => dispatch(actions.updateCountry(data)),
    deleteCountry: (data) => dispatch(actions.deleteCountry(data)),
    getCountryList: (page, limit, search) =>
      dispatch(actions.getCountryList(page, limit, search)),

    downlaodExcelFile: (type, search) =>
      dispatch(actions.downloadExcelMaster(type, search)),
    // onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Country);
