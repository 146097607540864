import axios from "axios";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

import * as actionTypes from "./actionTypes";
import * as myConstClass from "../../constants/constants";

// const orgId = localStorage.getItem("orgId");
const api_url = myConstClass.apiUrl;

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const getTaxationSuccess = (data) => {
  return {
    type: actionTypes.GET_TAXATION_SUCCESS,
    data: data,
  };
};

// downlaod property
export const downloadTaxationSuccess = (data) => {
  return {
    type: actionTypes.DOWNLOAD_TAXATION_SUCCESS,
  };
};

/// FETCH payments list LIST

export const getTaxation = (
  page,
  limit,
  orgId,
  search,
  samadhanId,
  propertyId,
  govtPropertyId,
  paidAmount,
  totalAmount,
  gtLtPaid,
  gtLtTotal
) => {
  return (dispatch) => {
    dispatch(authStart());
    const instance = axios.create({
      baseURL: api_url,
      headers: {
        "content-type": "application/json",
        Accept: "Application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    });
    instance
      .post(
        `taxation/list/${page}/${limit}?`,
       
        // api_url +`taxation/list/${page}/${limit}?taxationStatus=UNPAID&startDuration=2021&endDuration=2023&search=`,
        {
          organisationId: orgId,
          startDuration: "2021",
          endDuration: "2023",
          search: search,
          samadhanId: samadhanId,
          propertyId: propertyId,
          govtPropertyId: govtPropertyId,
          paidAmount: paidAmount,
          totalAmount: totalAmount,
          gtLtPaid: gtLtPaid,
          gtLtTotal: gtLtTotal,
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status) {
          toast.success(response.data.message);
          dispatch(getTaxationSuccess(response.data.taxations));
        } else {
          let data = {
            data: { docs: [], page: page, limit: limit, total: 0 },
          };
          dispatch(getTaxationSuccess(data));
          toast.error(response.data.message);
        }
        // dispatch(getPaymentsSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getTaxationSuccess(data));
      });
  };
};

export const downloadTaxation = (orgId, search, status) => {
  return async (dispatch) => {
    dispatch(authStart());
    try {
      const response = await axios.get(
        api_url +
          `property/download-property1?orgId=${orgId}&search_value=${search}&propertyStatus=${status}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      //  .then((response) => {

      var blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      //  let filename= type
      saveAs(
        blob,
        `${"Property"}${new Date().toISOString().slice(0, 10)}.xlsx`
      );
      toast.success("File downloaded successfully");
      dispatch(downloadTaxationSuccess());
      //   });
    } catch (error) {
      dispatch(downloadTaxationSuccess());
      toast.error(error.response.data.message);
    }
  };
};
