/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SelectSearch from "react-select";
import axios from "axios";
import styles from "./HisotoryAnyTime.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { useTranslation, withTranslation } from "react-i18next";
import * as acitons from "../../../store/actions/index";
import { compose } from "redux";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import { blue, grey } from "@material-ui/core/colors";
import * as myConstClass from "../../../constants/constants";
const api_url = myConstClass.apiUrl;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3B86FF",
      color: "white",
    },
    
  },
  select: {
    minWidth: "16.5vw",
    "@media (min-width: 320px) and (max-width: 375px)": {
      minWidth: "25vw",
    },
    "@media (min-width: 376px) and (max-width: 425px)": {
      minWidth: "25vw",
    },
    background: "white",
    color: grey[700],
    borderColor: "#D7DAE2",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: "13px",
    "&:hover": {
      borderColor: grey[400],
    },
    "&:focus": {
      borderRadius: "4px",
      background: "white",
      borderColor: blue[200],
    },
  },
  icon: {
    color: grey[500],
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    color: "#4d4f5c",
    fontSize: "smaller",
    "& li.Mui-selected": {
      fontWeight: 400,
    },
  },
  input: {
    color: "#4D4F5C",
    fontSize: "smaller",
  },
  table: {
    minWidth: 650,
    borderRadius: 0,
  },
}));

export function Attendance(props) {
  const classes = useStyles();
  const [t] = useTranslation("common");

  const [state, setState] = React.useState({
    date: new Date().toISOString().slice(0, 10),
    userId: "0",
    workLogList: [],
    total: "",
    limit: "10",
    page: "1",
  });
  const [loading, setLoading] = useState(false);

  // handle change user
  const handleChangeUser = (e, name) => {
    setState({ ...state, userId: e });
    setLoading(true);
    getUserWorkLog(e._id, new Date().toISOString(), e);
  };

  useEffect(() => {
    setTimeout(() => {
      props.getOrgUser(localStorage.getItem("orgId"));
    }, 1000);

    getUsersWorkLog();
  }, []);
  /// handle  change page
  const getUserWorkLog = (userId, endDate, obj) => {
    axios
      .get(
        api_url +
          `attendance/today-worklog-particular_user?organisationId=${localStorage.getItem(
            "orgId"
          )}&end_dateTime=${endDate}&userId=${userId}`,
        {
          method: "GET",
          headers: {
            "content-type": "Application/json",
            accept: "Application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setState({ ...state, workLogList: response.data.data, userId: obj });
        setLoading(false);
      })
      .catch((er) => {
        setState({ ...state, workLogList: [], userId: obj });

        //toast.error(er.response.data.message)
        setLoading(false);
      });
  };

  const getUsersWorkLog = () => {
          setLoading(true);
    axios
      .get(
        api_url +
          `attendance/history?organisationId=${localStorage.getItem("orgId")}`,
        {
          method: "GET",
          headers: {
            "content-type": "Application/json",
            accept: "Application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status) {
          setState({ ...state, usersLogList: response.data.data });
          setLoading(false);
        }
      })
      .catch((er) => {
        setState({ ...state, usersLogList: [] });

        // toast.error(er.response.data.message)
        setLoading(false);
      });
  };

  // ENABLE LAODING
  const backDrop = loading ? (
    <Backdrop
      style={{ zIndex: 1204 }}
      className={classes.backdrop}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;
  return (
    <div className={styles.main}>
      {backDrop}
      <div className={styles.title}>
        <span style={{ fontWeight: "light" }}>{t("operation.history")}</span>
      </div>
      
      <div className={styles.tableDiv}>
        <div className={styles.searchBarDiv}>
          <div className={styles.searchAndDrop}>
            <div>
              <div className={styles.searchBar}>
                <FormControl>
                  <SelectSearch
                    className={styles.seaarchSelect}
                    value={state.userId}
                    onChange={(e) => {
                      handleChangeUser(e, "userId");
                    }}
                    options={props.orgUserList}
                    name={"userId"}
                    placeholder={t("user_history.user")}
                  ></SelectSearch>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div >
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ backgroundColor: "#F5F6FA" }}>
                <TableRow>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                   {t("user_history.user")}
                  </TableCell>

                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("user_history.attempted_property")}
                  </TableCell>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("user_history.mapped_property")}
                  </TableCell>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("user_history.failed_property")}
                  </TableCell>
                  <TableCell align="left" style={{ color: "#A3A6B4" }}>
                    {t("user_history.aborted_property")}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ color: "#A3A6B4" }}
                  ></TableCell>
                  <TableCell
                    align="left"
                    style={{ color: "#A3A6B4" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state &&
                  state.usersLogList &&
                  state.usersLogList.length > 0 &&
                  state.usersLogList.map((history) => (
                    <TableRow key={history._id}>
                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {history.user}
                      </TableCell>

                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {history.total_properties_attempted}
                      </TableCell>

                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {history.total_properties_mapped}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {history.total_properties_failed}{" "}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {history.total_properties_aborted}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#4D4F5C" }}>
                        {moment(history.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {state && state.userLogList && state.userLogList.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {" "}
                {t("job_reporting.no_record_found")}
              </p>
            )}
            </TableContainer>
          </div>
        </div>
        
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    userHistoryList: state.Operation.userHistoryList,
    loading: state.Operation.loading,
    page: state.Operation.page,
    limit: state.Operation.limit,
    total: state.Operation.total,
    orgUserList: state.Operation.orgUserList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserHistoryList: (page, limit, userId, date) =>
      dispatch(acitons.getUserHisotoryList(page, limit, userId, date)),
    getOrgUser: (id) => dispatch(acitons.getOrgUser(id)),

    downloadWorkHistory: (userid, search) =>
      dispatch(acitons.downloadWorkHistory(userid, search)),
  };
};

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, mapDispatchToProps)
)(Attendance);
