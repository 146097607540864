import axios from "axios";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

import * as actionTypes from "./actionTypes";
import * as myConstClass from "../../constants/constants";
const orgId = localStorage.getItem("orgId");
const role = localStorage.getItem("smdRole");

const api_url = myConstClass.apiUrl;
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};
export const addJobSuccess = (data, method) => {
  return {
    type: actionTypes.ADD_JOB_SUCCESS,
    data: data,
  };
};

export const addJobFail = (data, method) => {
  return {
    type: actionTypes.ADD_JOB_FAIL,
    data: data,
  };
};

export const getJobListSuccess = (data) => {
  return {
    type: actionTypes.GET_JOB_SUCCESS,
    data: data,
  };
};

export const getAuditJobSuccess = (data) => {
  return {
    type: actionTypes.GET_AUDIO_JOB_SUCCESS,
    data: data,
  };
};

// get attendance action type
export const getAttendanceSuccess = (data) => {
  return {
    type: actionTypes.GET_ATTENDANCE_SUCCESS,
    data: data,
  };
};

// get user hisotry
export const getUserHistorySuccess = (data) => {
  return {
    type: actionTypes.GET_USER_HISOTORY_SUCCESS,
    data: data,
  };
};

export const getOrgUserSuccess = (data) => {
  return {
    type: actionTypes.GET_ORG_USER_SUCCESS,
    data: data,
  };
};

export const UpdatePropertyStausSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PROPERTY_STATUS_SUCCESS,
    data: data,
  };
};

export const updatePropertyStatusFail = (data) => {
  return {
    type: actionTypes.UPDATE_PROPERTY_STATUS_FAIL,
    data: data,
  };
};

// file downlaod success
export const fileDownloadSuccess = () => {
  return {
    type: actionTypes.FILE_DOWNLOAD_SUCCESS,
  };
};

export const fileDownloadFail = () => {
  return {
    type: actionTypes.FILE_DOWNLOAD_FAIL,
  };
};

// get init card success
export const getInitCardSuccss = (data) => {
  return {
    type: actionTypes.GET_INIT_CARD_SUCCESS,
    data: data,
  };
};

// get init table success
export const getInitTableSuccess = (data) => {
  return {
    type: actionTypes.GET_INIT_TABLE_SUCCESS,
    data: data,
  };
};
// get qc table success
// get init table success
export const getQcTableSuccess = (data) => {
  return {
    type: actionTypes.GET_QC_TABLE_SUCCESS,
    data: data,
  };
};

// get  qc card sucess
export const getQcCardSuccss = (data) => {
  return {
    type: actionTypes.GET_QC_CARD_SUCCESS,
    data: data,
  };
};

// get   insta card sucess
export const getAuditCardSuccss = (data) => {
  return {
    type: actionTypes.GET_AUDIT_CARD_SUCCESS,
    data: data,
  };
};

// get card details success
export const getJobDetailSuccess = (data) => {
  return {
    type: actionTypes.GET_JOB_DETAILS_SUCCESS,
    data: data,
  };
};

// get  inta card sucess
export const getInstaCardSuccss = (data) => {
  return {
    type: actionTypes.GET_INSTA_CARD_SUCCESS,
    data: data,
  };
};

// Dwonaldo attendace
// file downlaod success
export const AttendanceDwonloadSuccess = () => {
  return {
    type: actionTypes.DOWNLOAD_ATTENDANCE_SUCCESS,
  };
};

export const downloadPkgSuccess = () => {
  return {
    type: actionTypes.DOWNLOAD_PKG_SUCCESS,
  };
};

export const downloadWorkHistorySuccess = () => {
  return {
    type: actionTypes.DOWNLOAD_WORK_HISTORY_SUCCESS,
  };
};

// downlaod package

export const downloadInventorySuccess = () => {
  return {
    type: actionTypes.DOWNLOAD_INVENTORY_SUCCESS,
  };
};
// get  qc day end report
export const getQcDayEndReportSuccess = (data) => {
  return {
    type: actionTypes.GET_QC_DAY_END_REPORT_SUCCESS,
    data: data,
  };
};

// GET  INIT DAY END REPORT
export const getInitDayEndReportSuccess = (data) => {
  return {
    type: actionTypes.GET_INIT_DAY_END_REPORT_SUCCESS,
    data: data,
  };
};

// GET  INTALL DAY END REPORT
export const getInstaDayEndReportSuccess = (data) => {
  return {
    type: actionTypes.GET_INSTA_DAY_END_REPORT_SUCCESS,
    data: data,
  };
};

// graph data
export const getGraphDataSuccess = (data) => {
  return {
    type: actionTypes.GET_GRAPH_DATA_SUCCESS,
    data: data,
  };
};
// DOWNLAOD REPROT
export const reportDwonloadSuccess = () => {
  return {
    type: actionTypes.REPORT_DOWNLOAD_SUCCESS,
  };
};

// add update delete propery action
export const addJob = (value) => {
  var url = value.isAdd ? `job/add` : `job/edit-job`;
  var data = {};
  data.title = value.title;
  data.description = value.description;
  data.start_date = value.startDate;
  data.end_date = value.endDate;
  data.assignee = value.assigneeId;
  data.job_type = value.jobType;
  data.organisationId = value.orgId;
  data.asset = value.isAssetDistribution;
  data.assessment = value.isAssessment;

  if (value.jobType === "qc" || value.jobType === "initialization") {
    data.number_of_cards = value.nfc;
  }
  if (value.jobType === "installation" && value.isAdd) {
    data.categoryId = value.categoryId;
    data.subcategoryId = value.subcategoryId;
  }

  if (value.isEdit) {
    data.status = value.status;
    data.jobId = value.id;
  }

  return (dispatch) => {
    dispatch(authStart());

    axios
      .post(api_url + url, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addJobSuccess(response.data.message));
        if (value.isDelete) {
          //  dispatch(getProperty(1, 10, value.orgId, " ", " "));
        }
      })
      .catch((err) => {
        // let type = value.isDelete?'delete':'add'

        toast.error(err.response.data.message);
        dispatch(addJobFail(err.response.data.message));
      });
  };
};

/// FETCH propery list LIST
export const getJobList = (
  page,
  limit,
  orgId,
  jobType,
  search,
  status,
  authType
) => {
  var url = authType
    ? `job/org-user-joblist-new/${page}/${limit}?organisationId=${orgId}&job_type=${jobType}&search_value=${search}&status=${status}`
    : `job/job_list_of_particular_user/${page}/${limit}?organisationId=${orgId}&job_type=${jobType}&search_value=${search}&status=${status}`;
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(api_url + url, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getJobListSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, totalDocs: 0 },
        };
        dispatch(getJobListSuccess(data));
      });
  };
};

export const getOrgUser = (orgId) => {
  return (dispatch) => {
    //   dispatch(authStart());
    axios
      .get(api_url + `user/org-userlist-dropdown?organisationId=${orgId}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        response.data.data.forEach((element, i) => {
          let ar = {
            _id: element._id,
            label: element.name + "_" + element.phone,
          };
          response.data.data[i] = ar;
        });
        dispatch(getOrgUserSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: [],
        };
        dispatch(getJobListSuccess(data));
      });
  };
};
// downlaod excel file
export const downloadExcel = (orgId, type, search) => {
  return (dispatch) => {
    dispatch(authStart());

    axios
      .get(
        api_url +
          `job/download-job?organisationId=${orgId}&job_type=${type}&search_value=${search}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        //  let filename= type
        saveAs(
          blob,
          `${type.toUpperCase()}${new Date().toISOString().slice(0, 10)}.xlsx`
        );
        toast.success("File downloaded successfully");
        dispatch(fileDownloadSuccess());
      })
      .catch((error) => {
        dispatch(fileDownloadSuccess());
        toast.error("No Record Found");
      });
  };
};

// GET JOB REPROTING INIT CARD LSIT
export const getInitCardList = (orgId, startDate, endDate) => {
  var data = {
    organisationId: orgId,
    from_date: startDate,
    to_date: endDate,
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(api_url + `report/initialisation-job_reporting`, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getInitCardSuccss(response.data));
      })
      .catch((err) => {
        let data = {
          total_job_created: "0",
          total_card_initialised: "0",
          total_card_quarantine: "0",
        };
        dispatch(getInitCardSuccss(data));
      });
  };
};

// GET JOB REPROTING INIT CARD LSIT
export const getInitTableList = (page, limit, search, startDate, endDate) => {
  const orgId = localStorage.getItem("orgId");
  var data = {
    organisationId: orgId,
    from_date: startDate,
    to_date: endDate,
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(
        api_url +
          `report/initializatoin-datatable/${page}/${limit}?search_value=${search}`,
        data,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getInitTableSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, totalDocs: 0 },
        };
        dispatch(getInitTableSuccess(data));
      });
  };
};

// get qc table list
export const getQcTableList = (page, limit, search, startDate, endDate) => {
  const orgId = localStorage.getItem("orgId");
  var data = {
    organisationId: orgId,
    from_date: startDate,
    to_date: endDate,
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(
        api_url + `report/qc-datatable/${page}/${limit}?search_value=${search}`,
        data,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getQcTableSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, totalDocs: 0 },
        };
        dispatch(getQcTableSuccess(data));
      });
  };
};

// GET QC JOB JOB REPORTING

export const getQcCardList = (orgId, startDate, endDate) => {
  var data = {
    organisationId: orgId,
    from_date: startDate,
    to_date: endDate,
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(api_url + `report/qc-job_reporting`, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getQcCardSuccss(response.data));
      })
      .catch((err) => {
        let data = {
          data: {
            initialisation_needed: "0",
            installation_needed: "0",
            job_count: "0",
            qc_completed: "0",
          },
        };
        dispatch(getQcCardSuccss(data));
      });
  };
};

export const getAuditCardList = (orgId, startDate, endDate) => {
  var data = {
    organisationId: orgId,
    from_date: startDate,
    to_date: endDate,
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(api_url + `report/audit-job-report`, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getAuditCardSuccss(response.data));
      })
      .catch((err) => {
        let data = {
          property_sent_for_reattempt: "0",
          reattempt_pending: "0",
        };
        dispatch(getAuditCardSuccss(data));
      });
  };
};

export const getInstaCardList = (orgId, startDate, endDate) => {
  var data = {
    organisationId: orgId,
    from_date: startDate,
    to_date: endDate,
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(api_url + `report/installation-job_reporting`, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getInstaCardSuccss(response.data));
      })
      .catch((err) => {
        let data = { data: [{}] };
        dispatch(getInstaCardSuccss(data));
      });
  };
};
// Pacakge section //
// Add Package
export const addPackage = (value) => {
  const url = value.isAdd
    ? `packages/add`
    : value.isUpdate
    ? `packages/package_update`
    : `packages/package_delete?organisationId=${orgId}`;
  return (dispatch) => {
    dispatch(authStart());
    var data = {};
    if (value.isAdd || value.isUpdate) {
      data.packageName = value.packageName;
      data.organisationId = value.orgId;
      data.number_of_freshCards = value.freshCard;
      data.number_of_failed = value.failCard;
      data.number_of_damagedCards = value.damageCard;
      data.number_of_screws = value.screw;
      data.number_of_screwDriver = value.screwDriver;
      data.number_of_drillGun = value.drillGun;
      data.number_of_woodPlugs = value.woodPlug;
      data.number_of_jobSheet = value.jobSheet;
      data.other_name = value.other;
      data.other_quantity = value.quantity;
    }
    if (value.isUpdate || value.isDelete) {
      data.packageId = value.id;
    }

    axios
      .post(api_url + url, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addPackageSuccess(value.isDelete));
        value.isDelete &&
          dispatch(getPackageList(1, 10, localStorage.getItem("orgId"), ""));
      })
      .catch((err) => {
        toast.error(err.response.data.message);

        dispatch(addPackageFail(value.isDelete));
      });
  };
};

// Action type for Add Package
export const addPackageSuccess = (data) => {
  return {
    type: actionTypes.ADD_PACKAGE_SUCCESS,
    data: data,
  };
};

// Add Pacakge Fail
export const addPackageFail = (data) => {
  return {
    type: actionTypes.ADD_PACKAGE_FAIL,
    data: data,
  };
};

// Get Pacakge List//
// Add Pacakge Fail
export const getPackageSuccess = (data) => {
  return {
    type: actionTypes.GET_PACKAGE_SUCCESS,
    data: data,
  };
};

// Get Package List With LazyLoading//
export const getPackageList = (page, limit, orgId, search) => {
  let url =
    role === "head"
      ? `packages/list/${page}/${limit}?organisationId=${orgId}&packageName=${search}`
      : `packages/list-user/${page}/${limit}?organisationId=${orgId}&packageName=${search}`;
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(api_url + url, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getPackageSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getPackageSuccess(data));
      });
  };
};

// GET ALL PACKAGE LIST FOR DROP DOWN
export const getAllPackageList = (orgId) => {
  return (dispatch) => {
    //   dispatch(authStart());
    axios
      .get(api_url + `packages/package-dropdown?organisationId=${orgId}`, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getAllPkgSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: [],
        };
        dispatch(getAllPkgSuccess(data));
      });
  };
};
/// GET ALL PACKAGE LIST SUCCES ACTION TYPE
export const getAllPkgSuccess = (data) => {
  return {
    type: actionTypes.GET_ALL_PACKAGE_SUCCESS,
    data: data,
  };
};

// Inventory section
// Add Inventory
export const addInventory = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    var data = {
      receiver_userId: value.userId,
      //   packageName :value.packageName,
      organisationId: value.orgId,
      number_of_freshCards: value.freshCard,
      number_of_failedCards: value.failCard,
      number_of_damagedCards: value.damageCard,
      number_of_screws: value.screw,
      number_of_screwDriver: value.screwDriver,
      number_of_drillGun: value.drillGun,
      number_of_woodPlugs: value.woodPlug,
      number_of_jobSheet: value.jobSheet,
      other_name: value.other,
      other_quantity: value.quantity,
    };
    let url = `inventory/admin-add`;
    axios
      .post(api_url + url, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addInventorySuccess(response.data.me));
      })
      .catch((err) => {
        toast.error(err.response.data.message);

        dispatch(addInventoryFail(err.response.data.message));
      });
  };
};

export const addInventorySuccess = (data) => {
  return {
    type: actionTypes.ADD_INVENTORY_SUCCESS,
    data: data,
  };
};

// Add Inventory Fail
export const addInventoryFail = (data) => {
  return {
    type: actionTypes.ADD_INVENTORY_FAIL,
    data: data,
  };
};

// GET ALL PACKAGE LIST FOR DROP DOWN
export const getInventory = (page, limit, userId, search, date) => {
  let url =
    role === "head"
      ? `inventory/inventorylist-Organisation/${page}/${limit}?organisationId=${orgId}&search_value=${userId}`
      : `inventory/inventorylist-user?organisationId=${orgId}`;
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(api_url + url, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (role !== "head") {
          let data = {
            data: {
              docs: [response.data.balance_inventory],
            },
          };
          response.data = data;
        }
        dispatch(getInventorySuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, totalDocs: 0 },
        };
        dispatch(getInventorySuccess(data));
      });
  };
};
export const getInventorySuccess = (data) => {
  return {
    type: actionTypes.GET_INVENTORY_SUCCESS,
    data: data,
  };
};

// GET AUDIO JOB LIST
export const getAuditJobList = (page, limit, startDate, endDate) => {
  const orgId = localStorage.getItem("orgId");
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `job/audit-joblist/${page}/${limit}?organisationId=${orgId}&from_date=${startDate}&to_date=${endDate}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getAuditJobSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getAuditJobSuccess(data));
      });
  };
};

// update property status
export const updatePropertyStatus = (status, id, state) => {
  const orgId = localStorage.getItem("orgId");
  const data = {
    propertyId: id,
    audit_status: status,
    organisationId: orgId,
  };
  if (status === "REATTEMPT") {
    data.reason = state.reason;
  }
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(api_url + `job/audit-approve-reject`, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(
          getAuditJobList(
            state.page,
            state.limit,
            state.startDate,
            state.endDate
          )
        );
        dispatch(UpdatePropertyStausSuccess(response.data));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(updatePropertyStatusFail(data));
      });
  };
};

// GET ATTEDANCE LIST
export const getAttendanceList = (page, limit, userId, date) => {
  const orgId = localStorage.getItem("orgId");
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `attendance/attendancelist-new/${page}/${limit}?organisationId=${orgId}&date=${date}&search_value=${userId}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getAttendanceSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, totalDocs: 0 },
        };
        dispatch(getAttendanceSuccess(data));
      });
  };
};

// get QC and init job details
export const getJobDetails = (page, limit, orgId, search, status, jobId) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `job/Cardlist_by_jobid/${page}/${limit}?orgId=${orgId}&jobId=${jobId}&&search_value=${search}&status=${status}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
            //  "otp":98988
          },
        }
      )
      .then((response) => {
        dispatch(getJobDetailSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, totalDocs: 0 },
        };
        dispatch(getJobDetailSuccess(data));
      });
  };
};

// get user history
export const getUserHisotoryList = (page, limit, userId, date, authType) => {
  const orgId = localStorage.getItem("orgId");

  const url = authType
    ? `attendance/User_WorkHistory-head/${page}/${limit}?organisationId=${orgId}&date=${date}&search_value=${userId}`
    : `attendance/User_WorkHistory/${page}/${limit}?organisationId=${orgId}&date=${date}&search_value=${userId}`;
  return (dispatch) => {

    dispatch(authStart());
    axios
      .get(api_url + url, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getUserHistorySuccess(response.data));

      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getUserHistorySuccess(data));
      });
  };
};

// DWONLOAD EXCEL
export const downlaodAttendance = (userId, date) => {
  const orgId = localStorage.getItem("orgId");
  return (dispatch) => {
    dispatch(authStart());

    axios
      .get(
        api_url +
          `attendance/download-attendance?organisationId=${orgId}&search_value=${userId}&date=${date}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        //  let filename= type
        saveAs(blob, `Package ${new Date().toISOString().slice(0, 10)}.xlsx`);
        toast.success("File downloaded successfully");
        dispatch(AttendanceDwonloadSuccess());
      })
      .catch((erro) => {
        //  var er =erro.json()
        dispatch(AttendanceDwonloadSuccess());

        toast.error("No Record Found");
      });
  };
};

// get download work history

export const downloadWorkHistory = (userId, date) => {
  const orgId = localStorage.getItem("orgId");

  const url =
    localStorage.getItem("smdRole") === "head"
      ? `attendance/download-workhistory-head?organisationId=${orgId}&date=${date}&search_value=${userId}`
      : `attendance/download-workhistory-user?organisationId=${orgId}&date=${date}&search_value=${userId}`;
  return (dispatch) => {
    dispatch(authStart());

    axios
      .get(api_url + url, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        //  let filename= type
        saveAs(blob, `${new Date().toISOString().slice(0, 10)}.xlsx`);
        toast.success("File downloaded successfully");
        dispatch(downloadWorkHistorySuccess());
      })
      .catch((error) => {
        dispatch(downloadWorkHistorySuccess());
        toast.error("No Record Found");
      });
  };
};

// Dwonlaod Package
export const downloadPackage = (search) => {
  const orgId = localStorage.getItem("orgId");

  const url = `packages/download-package?organisationId=${orgId}`;
  return (dispatch) => {
    dispatch(authStart());

    axios
      .get(api_url + url, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        //  let filename= type
        saveAs(blob, `Package ${new Date().toISOString().slice(0, 10)}.xlsx`);
        toast.success("File downloaded successfully");
        dispatch(downloadPkgSuccess());
      })
      .catch((er) => {
        toast.error(er.response.data.message);

        dispatch(downloadPkgSuccess());
      });
  };
};

// DOWNLAOD INVENTORY

export const downloadInventory = (search) => {
  const orgId = localStorage.getItem("orgId");

  const url = `inventory/download-inventory-organisation?organisationId=${orgId}`;
  return (dispatch) => {
    dispatch(authStart());

    axios
      .get(api_url + url, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        //  let filename= type
        saveAs(blob, `Inventory ${new Date().toISOString().slice(0, 10)}.xlsx`);
        toast.success("File downloaded successfully");
        dispatch(downloadInventorySuccess());
      })
      .catch((er) => {
        toast.error(er.response.data.message);

        dispatch(downloadInventorySuccess());
      });
  };
};

// GET QC  END DAY REPORT
// get qc table list
export const getQcEndDayReport = (page, limit, startDate, endDate) => {
  const orgId = localStorage.getItem("orgId");
  var data = {
    organisationId: orgId,
    from_date: startDate,
    to_date: endDate,
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(api_url + `report/qc-day-end-report/${page}/${limit}`, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getQcDayEndReportSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, totalDocs: 0 },
        };
        dispatch(getQcDayEndReportSuccess(data));
      });
  };
};

// GET INIT DAY END REPORT
export const getInitEndDayReport = (page, limit, startDate, endDate) => {
  // const orgId =localStorage.getItem('orgId')
  var data = {
    organisationId: orgId,
    from_date: startDate,
    to_date: endDate,
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(
        api_url + `report/initialisation-day-end-report/${page}/${limit}`,
        data,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getInitDayEndReportSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, totalDocs: 0 },
        };
        dispatch(getQcDayEndReportSuccess(data));
      });
  };
};

// GET INSTALLATION DAY END REPORT
export const getInstaEndDayReport = (page, limit, startDate, endDate) => {
  // const orgId =localStorage.getItem('orgId')
  var data = {
    organisationId: orgId,
    from_date: startDate,
    to_date: endDate,
  };
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(
        api_url + `report/installation-day-end-report/${page}/${limit}`,
        data,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getInstaDayEndReportSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, totalDocs: 0 },
        };
        dispatch(getInstaDayEndReportSuccess(data));
      });
  };
};
export const downloadReport = (type, startDate, endDate) => {
  const url =
    type === "qc_end_day_report"
      ? `report/download-qc-day-end-report?organisationId=${orgId}&from_date=${startDate}&to_date=${endDate}`
      : (type = "insta_end_day_report"
          ? `report/download-installation-day-end-report?organisationId=${orgId}&from_date=${startDate}&to_date=${endDate}`
          : `report/download-initialisation-day-end-report?organisationId=${orgId}&from_date=${startDate}&to_date=${endDate}`);
  return (dispatch) => {
    dispatch(authStart());

    axios
      .get(api_url + url, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        //  let filename= type
        saveAs(
          blob,
          `${type.toUpperCase()}${new Date().toISOString().slice(0, 10)}.xlsx`
        );
        toast.success("File downloaded successfully");
        dispatch(reportDwonloadSuccess());
      })

      .catch((error) => {
        dispatch(reportDwonloadSuccess());

        toast.error("No Record Found");
      });
    //  .catch (error){
    //
  };
};

// download  aborted, faield, attemted  job

export const downloadInstalAllReport = (startDate, endDate) => {
  const orgId = localStorage.getItem("orgId");
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  return async (dispatch) => {
    const url1 = `report/download-installation-mapped-day-end-report?organisationId=${orgId}&from_date=${startDate}&to_date=${endDate}`;
    const url2 = `report/download-installation-failed-day-end-report?organisationId=${orgId}&from_date=${startDate}&to_date=${endDate}`;
    const url3 = `report/download-installation-aborted-day-end-report?organisationId=${orgId}&from_date=${startDate}&to_date=${endDate}`;
    dispatch(authStart());
    try {
      const mappped = await axios.get(api_url + url1, {
        responseType: "arraybuffer",
        headers: headers,
      });

      var blob = new Blob([mappped.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(
        blob,
        `Mapped Property ${new Date().toISOString().slice(0, 10)}.xlsx`
      );
      toast.success("File downloaded successfully for mapped property");
    } catch (error) {
      toast.error("No Record for mapped property");
    }

    // downlado failed property
    try {
      const failed = await axios.get(api_url + url2, {
        responseType: "arraybuffer",
        headers: headers,
      });

      blob = new Blob([failed.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(
        blob,
        `Failed Property ${new Date().toISOString().slice(0, 10)}.xlsx`
      );
      toast.success("File downloaded successfully for Failed property");
    } catch (error) {
      toast.error("No Record for Failed property");
    }

    // download aborted property
    try {
      const aborted = await axios.get(api_url + url3, {
        responseType: "arraybuffer",
        headers: headers,
      });

      blob = new Blob([aborted.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(
        blob,
        `Aborted Property ${new Date().toISOString().slice(0, 10)}.xlsx`
      );
      toast.success("File downloaded successfully for Aborted property");
      dispatch(reportDwonloadSuccess("insta_end_day_repor"));
    } catch (error) {
      toast.error("No Record for Aborted property");
      dispatch(reportDwonloadSuccess("insta_end_day_repor"));
    }
    // axios.get(api_url + url,
    //     {
    //         responseType: 'arraybuffer',
    //         headers: {
    //             "Authorization": `${localStorage.getItem('token')}`,
    //         }
    //     }
    // )
    //     .then((response) => {

    //         var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //         saveAs(blob, `Inventory ${new Date().toISOString().slice(0, 10)}.xlsx`);
    //         toast.success("File downloaded successfully")
    //         dispatch(downloadInventorySuccess())
    //     })
    //     .catch(er => {

    //         toast.error(er.response.data.message)

    //         dispatch(downloadInventorySuccess())

    //     })
  };
};

// get graph data
export const getGraphData = (date) => {
  return async (dispatch) => {
    dispatch(authStart());
    try {
      const response = await axios.post(
        api_url +
          `report/installation-report-graph?organisationId=${orgId}&date=${date}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      var obj = [];
      response.data.data.forEach((el, i) => {
        var d = {};
        response.data.data[i]["Mapped Property"] = el.Mapped_property;
        response.data.data[i]["Re-Attempt Property"] = el.Reattempt_property;
        response.data.data[i]["Total Installed Property"] =
          el.TOTAL_installation;
        response.data.data[i]["Hour"] =
          el.hour.toString().length > 1
            ? +el.hour + ":00" + el.am_pm
            : "0" + el.hour + ":00" + el.am_pm;
        response.data.data[i]["Aborted Property"] = el.abort_property;
        response.data.data[i]["Failed Property"] = el.failed_property;
        response.data.data[i]["New Property"] = el.new_property;
        response.data.data[i]["Active User"] = el.active_user;

        obj.push(d);
      });

      dispatch(getGraphDataSuccess(response.data));
    } catch (error) {
      let data = {
        data: [],
      };
      dispatch(getGraphDataSuccess(data));
    }
  };
};

// DOWNLADO AUDIT JOB  LIST
export const downloadAuditJobList = (startDate, endDate) => {
  const orgId = localStorage.getItem("orgId");
  const headers = {
    Authorization: `${localStorage.getItem("token")}`,
  };
  return async (dispatch) => {
    const url = `job/download-audit-joblist?organisationId=${orgId}&from_date=${startDate}&to_date=${endDate}`;
    dispatch(authStart());
    try {
      const mappped = await axios.get(api_url + url, {
        responseType: "arraybuffer",
        headers: headers,
      });

      var blob = new Blob([mappped.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(
        blob,
        `Audit Job list ${new Date().toISOString().slice(0, 10)}.xlsx`
      );
      toast.success("File downloaded successfully");
      dispatch(auditDownloadSuccess());
    } catch (error) {
      toast.error("No Record Found");
      dispatch(auditDownloadSuccess());
    }
  };
};

export const auditDownloadSuccess = () => {
  return {
    type: actionTypes.DOWNLOAD_AUDIT_SUCCESS,
  };
};
