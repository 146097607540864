import axios from "axios";
import * as myConstClass from "../../constants/constants";
import { toast } from "react-toastify";

import * as actionTypes from "./actionTypes";
import { saveAs } from "file-saver";

const api_url = myConstClass.apiUrl;
const orgId = localStorage.getItem("orgId");

const role = localStorage.getItem("smdRole");

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const addBlankSamadhanIdSuccess = (data) => {
  return {
    type: actionTypes.ADD_BLANK_SMD_SUCCESS,
    data: data,
  };
};
export const addBlankSamadhanIdFail = (data) => {
  return {
    type: actionTypes.ADD_BLANK_SMD_FAIL,
    data: data,
  };
};

export const getBlankSamadhanIdSuccess = (data) => {
  return {
    type: actionTypes.GET_BLANK_SMD_SUCCESS,
    data: data,
  };
};

export const fetchBlankSamadhanIdFail = (error) => {
  return {
    type: actionTypes.GET_BLANK_SMD_FAIL,
    error: error,
  };
};

export const getSamadhanViewAllSuccess = (data) => {
  return {
    type: actionTypes.GET_SMD_VIEW_ALL_SUCCESS,
    data: data,
  };
};

export const getSamadhanViewAllFail = (error) => {
  return {
    type: actionTypes.GET_SMD_VIEW_FAIL,
    error: error,
  };
};

export const getAllOrgSuccess = (data) => {
  return {
    type: actionTypes.GET_All_ORG_SUCCESS,
    data: data,
  };
};

export const addOrgSamadhanIdSuccess = (data) => {
  return {
    type: actionTypes.ADD_ORG_SMD_SUCCESS,
    data: data,
  };
};
export const addOrgSamadhanIdFail = (data) => {
  return {
    type: actionTypes.ADD_ORG_SMD_FAIL,
    data: data,
  };
};

export const getOrgSamadhanSuccess = (data) => {
  return {
    type: actionTypes.GET_ORG_SMD_SUCCESS,
    data: data,
  };
};

/// ALL BLANK SAMADHAN ID ACTION

// add  BLANK SAMADHAN ID
export const addBlankSamadhanId = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      number_of_cards: value.cards,
    };
    axios
      .post(api_url + `samadhanId/add?organisationId=${orgId}`, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addBlankSamadhanIdSuccess(response.data.message));
        dispatch(getBlankSamadhanList(1, 10, ""));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addBlankSamadhanIdFail({
            error: err.response,
          })
        );
      });
  };
};

// file downlaod blank and org
export const DownloadSmdIDSuccess = (data) => {
  //  let type = data=='category'?
  return {
    type:
      data === "blank_smd"
        ? actionTypes.DOWNLOAD_BLANK_SMD_SUCCESS
        : actionTypes.DOWNLOAD_ORG_SMD_SUCCESS,
    page: data,
  };
};

// GET BLANK SAMADHAN ID

export const getBlankSamadhanList = (page, limit, date) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        api_url +
          `samadhanId/list/${page}/${limit}?date=${date}&organisationId=${orgId}`,
        {
          headers: {
            "content-type": "application/json",
            Accept: "Application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        dispatch(getBlankSamadhanIdSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          fetchBlankSamadhanIdFail({
            error: err.response,
          })
        );
      });
  };
};

// GET VIEW ALL SAMADHAN ID
export const getViewAllSamdhanList = (page, limit, id, code, date) => {
  let url = `samadhanId/detail_list/${limit}/${page}?samadhanId=${id}&samadhanCode=${code}&date=${date}&organisationId=${orgId}`;
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(api_url + url, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getSamadhanViewAllSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          getSamadhanViewAllFail({
            error: err.response,
          })
        );
      });
  };
};

// ORGNISATION SAMADHAN ID ACTION
export const addOrgSamadhaId = (value) => {
  return (dispatch) => {
    dispatch(authStart());
    const data = {
      number_of_cards: value.cards,
      orgId: value.orgId,
    };
    axios
      .post(api_url + `samadhan_org/add?organisationId=${orgId}`, data, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        dispatch(addOrgSamadhanIdSuccess(response.data.message));
        dispatch(getOrgSamadhanist(1, 10, "", ""));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch(
          addOrgSamadhanIdFail({
            error: err.response,
          })
        );
      });
  };
};

// GET BLANK SAMADHAN ID

export const getOrgSamadhanist = (page, limit, date, organisationId) => {
  var url =
    role === "admin"
      ? `samadhan_org/list/${page}/${limit}?date=${date}&orgId=${organisationId}`
      : `samadhan_org/list/${page}/${limit}?date=${date}&orgId=${orgId}`;

  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(api_url + url, {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getOrgSamadhanSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [], page: page, limit: limit, total: 0 },
        };
        dispatch(getOrgSamadhanSuccess(data));
      });
  };
};

/// GET ALL ORGANISATION

export const getAllOrganisation = () => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(api_url + "organisation/user_getall", {
        headers: {
          "content-type": "application/json",
          Accept: "Application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        dispatch(getAllOrgSuccess(response.data));
      })
      .catch((err) => {
        let data = {
          data: { docs: [] },
        };
        dispatch(getAllOrgSuccess(data));
      });
  };
};
// downaod api for samadhan blannk
export const downloadExcelSmdId = (type, date) => {
  return (dispatch) => {
    let url =
      type === "blank_smd"
        ? `samadhanId/download-samadhanblank?date=${date}`
        : `samadhan_org/download-samadhan_org_id?orgId=${orgId}&date=${date}`;
    dispatch(authStart());
    try {
      axios
        .get(api_url + url, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          var blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(
            blob,
            `${type.toUpperCase()}${new Date().toISOString().slice(0, 10)}.xlsx`
          );
          toast.success("File downloaded successfully");
          dispatch(DownloadSmdIDSuccess(type));
        });
    } catch (error) {
      dispatch(DownloadSmdIDSuccess(type));

      toast.error(error.response.data.message);
    }
  };
};
