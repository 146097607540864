import React from "react";
import styles from "./success.module.css";
// import { useTranslation } from "react-i18next";
import successImg from "../Success/successImg.gif"
import brandLogo from "../Success/brand_logo.png"

export default function Success() {
  // const [t] = useTranslation("common");

  return (
    <div className  = {styles.main}>

        <img className = "brandLogo" src={brandLogo} alt="Brand Logo" />

        <div className={styles.success}>
          <img src={successImg} height = "300" width ="300" alt="success"/>
        </div>

        <div className={styles.title}>{"Taxation Successful"}</div>
    </div>
  );
}
